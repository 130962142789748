<template>
    
    <div :class="['wang-editor-ui',theme, isFull ? fullClass : '',isLoad?'showEditor':'hiddenEditor']">
        <div v-if="toolLocation === 'top'" ref="editorMenu"  :class="['editor-toolba',toolLocation]">
            <div class="editor-submit" v-if="customButton.length">
                <!--<span v-for="button in customButton" :key="button.key" @click="button.on_click(getHTML(),button)">保存</span>-->
                <Buttons type='blue' :icon='button.icon || ""' plain v-for="button in customButton" :key="button.key" @click="button.on_click(getHTML(),button)">保存</Buttons>
            </div>
        </div>
        <div class="editor-plate" ref="editorContent">

        </div>
        <div v-if="toolLocation === 'bottom'" ref="editorMenu"  :class="['editor-toolba',toolLocation]">
            <div class="editor-submit" v-if="customButton.length">
                <Buttons type='blue' :icon='button.icon || ""' plain v-for="button in customButton" :key="button.key" @click="button.on_click(getHTML(),button)">保存</Buttons>
            </div>
        </div>
    </div>
</template>

<script>
import xss from "xss";
import Buttons from '@/components/course/Buttons.vue';
import params from './params';
import server from '@/service/server';

const VUE_STYLE_FILE_ID = {
    lignt : 'wangEditor_lignt__',
    dark : 'wangEditor_dark__',
    white : 'wangEditor_white__',
}

export default {
    name : '_wangEditor',
    data (){
        return {
            editor : null,
            styles : {
                toolbar_bg : '#000'
            },
            fullClass: "full",
            defaultWhiteList: {
                a: ["target", "href", "title"],
                abbr: ["title"],
                address: [],
                area: ["shape", "coords", "href", "alt"],
                article: [],
                aside: [],
                audio: ["autoplay", "controls", "loop", "preload", "src"],
                b: [],
                bdi: ["dir"],
                bdo: ["dir"],
                big: [],
                blockquote: ["cite"],
                br: [],
                caption: [],
                center: [],
                cite: [],
                code: [],
                col: ["align", "valign", "span", "width"],
                colgroup: ["align", "valign", "span", "width"],
                dd: [],
                del: ["datetime"],
                details: ["open"],
                div: ["style"],
                dl: [],
                dt: [],
                em: [],
                font: ["color", "size", "face"],
                footer: [],
                h1: [],
                h2: [],
                h3: [],
                h4: [],
                h5: [],
                h6: [],
                header: [],
                hr: [],
                i: [],
                img: ["src", "alt", "title", "width", "height", "style"],
                ins: ["datetime"],
                li: [],
                mark: [],
                nav: [],
                ol: [],
                p: ["style"],
                pre: [],
                s: [],
                section: [],
                small: [],
                span: [],
                sub: [],
                sup: [],
                strong: [],
                table: ["width", "border", "align", "valign","style"],
                tbody: ["align", "valign"],
                td: ["width", "rowspan", "colspan", "align", "valign","style"],
                tfoot: ["align", "valign"],
                th: ["width", "rowspan", "colspan", "align", "valign"],
                thead: ["align", "valign"],
                tr: ["rowspan", "align", "valign"],
                tt: [],
                u: [],
                ul: [],
                video: ["autoplay", "controls", "loop", "preload", "src", "height", "width"]
            },
            isLoad : false,
        }
    },
    components : {
        Buttons
    },
    props : {
        menus : {
            type : Array,
            default : ()=>{return [
                'bold',
                'italic',
                'underline',
                'justify',
                'indent',
                'image'
            ]}
        },
        theme : {
            type : String,//主题 /默认浅色系
            default : 'lignt',
        },
        isFull: {
            type: Boolean,//是否全屏显示编辑区域
            default: false
        },
        toolLocation : {
            type : String,
            default : 'top',//默认菜单栏在上方 
        },
        customButton : {
            type : Array,
            default : ()=>{return []}
        },
        placeholder : {
            type : String,
            default : '请输入正文',
        },
        defaultHTML: {
            type: String,
            default: "",
        }
    },
    mounted (){
        const {$refs} = this;
        const wangEditor = this.wangEditor;
        const editor = new wangEditor($refs.editorMenu,$refs.editorContent);
        this.editor = editor;
        this.editorInit();
        if(this.defaultHTML) this.setHTML(this.defaultHTML);
        this.$nextTick(()=>{
            this.initEditor();
        })
    },
    methods : {
        initEditor (){
            const {theme} = this;
            this.delStyle();
            let _link = document.createElement('link'),that = this,
            head = document.querySelector('head');
            _link.rel="stylesheet";
            _link.href=window.$PATHNAME + `${process.env.BASE_URL}css/wangEditor/${theme}.css`;
            _link.id = VUE_STYLE_FILE_ID[theme];
            head.appendChild(_link);
            _link.onload = function (){
                console.log('加载成功');
                that.isLoad = true;
            }
            _link.onerror = function (){
                console.log('加载失败');
                that.isLoad = false;
            }
        },
        delStyle (){
            let head = document.querySelector('head');
            for(const item in VUE_STYLE_FILE_ID){
                let _di = '#' + VUE_STYLE_FILE_ID[item]
                let elem = document.querySelector(_di);
                if(elem){
                    head.removeChild(elem);
                }
            }
        },
        editorInit (){
            const {editor} = this;
            editor.config.menus = this.menus;
            editor.config.placeholder = this.placeholder;
            editor.config.uploadImgServer = server.upload + "?type=image";
            editor.config.uploadImgMaxSize = 2 * 1024 * 1024;
            editor.config.showMenuTooltips = false
            editor.config.uploadImgHooks = {
                before: function (xhr, editor, files) {
                    xhr.setRequestHeader("Authorization", "JWT " + window.localStorage.getItem("token"));
                },
                success: function (xhr, editor, result) {
                    // 图片上传并返回结果，图片插入成功之后触发
                    // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，result 是服务器端返回的结果
                },
                fail: function (xhr, editor, result) {
                    // 图片上传并返回结果，但图片插入错误时触发
                    // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，result 是服务器端返回的结果
                },
                error: function (xhr, editor) {
                    // 图片上传出错时触发
                    // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象
                },
                timeout: function (xhr, editor) {
                    // 图片上传超时时触发
                    // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象
                },
                // 如果服务器端返回的不是 {errno:0, data: [...]} 这种格式，可使用该配置
                // （但是，服务器端返回的必须是一个 JSON 格式字符串！！！否则会报错）
                customInsert: function (insertImg, result, editor) {
                    // 图片上传并返回结果，自定义插入图片的事件（而不是编辑器自动插入图片！！！）
                    // insertImg 是插入图片的函数，editor 是编辑器对象，result 是服务器端返回的结果
                    // 举例：假如上传图片成功后，服务器端返回的是 {url:'....'} 这种格式，即可这样插入图片：
                    if(result.status) {
                        let url = result.data[0]['url'];
                        insertImg(url)
                    }
                    // result 必须是一个 JSON 格式字符串！！！否则报错
                },
            }
            editor.config.onchange = newHtml=>{this.$emit('editorChange',newHtml)}
            editor.create();
            this.editor = editor;
        },
        destroyEditor (){
            this.editor.destroy();
            this.editor = null;
        },
        getJSON (){//获取JSON数据
            const {editor} = this;
            return editor.txt.getJSON();
        },
        getHTML (){//获取HTML数据
            const {editor} = this;
            const newXss = new xss.FilterXSS({
                whiteList: this.defaultWhiteList
            });
            return newXss.process(editor.txt.html());
        },
        setJSON (data){//设置JSON数据
            const {editor} = this;
            editor.txt.setJSON(data);
        },
        setHTML (newHtml){
            const {editor} = this;
            if(!editor)return;
            editor.txt.html(newHtml);
            // editor.txt.html('<p>张利<img src="http://192.168.10.160:8000/xs/uploads/813cf8372690eea5b44fb6e02b17446d_105.png" style="background-color:var(--text_bg); max-width:100%;" /></p>');
        },
        getTEXT(){
            const {editor} = this;
            return editor.txt.text();
        },
        clear (){
            const {editor} = this;
            editor.txt.clear();
        },
        handleSubmit (){
            this.$emit('toSubmit',this.getHTML());
            this.clear();
        },
        handleDiable(){
            const {editor} = this;
            editor.disable();
        },
        handleAbled(){
            const {editor} = this;
            editor.enable();
        }
    },
    unmounted (){
        params.delStyle();
    },
}
</script>
<style scoped lang='less' >
    ::v-deep  .el-button--small {
        min-height: 30px;
        padding: 0px 15px;
        display: flex;
        align-items: center;
    }
    .wang-editor-ui{
        background-color: var(--context_bg);
        border-radius: var(--menu_radius);
        padding:5px 0;
        display: flex;
        flex-direction: column;
        opacity: 0;
        &.full {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
        }
    }
    .wang-editor-ui.showEditor{
        opacity: 1;
    }
    .wang-editor-ui.hiddenEditor{
        opacity: 0;
    }

    .editor-plate{
        height:100%;
        flex:1 1 auto;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .editor-toolba{
        position: relative;
        height:30px;
        flex:0 0 auto;
        padding:0 5px;
        .editor-submit{
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translate(0, -50%);
            font-size: 16px;
            height: 90%;
            display: flex;
            align-items: center;
            cursor: pointer;
            z-index: 10002;
            >span{
                color: #FFFFFF;
                background: #2974EA;
                box-shadow: 0px 1px 4px 0px rgba(3, 71, 144, 0.39);
                padding: 0 20px;
                margin-left: 10px;
                border-radius: 4px;
                height: 100%;
                cursor: pointer;
                display: flex;
                align-items: center;
                &:first-child{
                    margin-left: 0;
                }
            }
        }
        &.top{
            margin-bottom:5px;
        }
        &.bottom{
            margin-top:5px;
        }
    }
    /deep/ .w-e-toolbar{
        background-color : var(--toolbar_bg);
        padding : var(--toolbar_padding);
    }

    /deep/ .w-e-menu{
        border-radius: var(--menu_radius);
        margin-right:var(--toolbar_right) ;
        width:var(--toolbar_width) !important;
        height:var(--toolbar_height) !important;
        font-size: 13px;
        background-color: var(--menu_bg);
        
    }

    /deep/ .w-e-text-container{
        background-color: var(--text_bg);
        word-break: break-all;
        text-align: left;
        border-radius: 10px;
    }

    /deep/ .w-e-menu:hover{
        background-color : var(--menu_hover_bg);
        
    }

    
    // /deep/ .w-e-active{
    //     background-color: var(--menu_hover_bg);
    // }

    /deep/ .w-e-toolbar .w-e-menu i{
        color: var(--menu_color);
    }

    /deep/ .w-e-menu:hover i{
        color : var(--menu_hover_color);
    }
    /deep/ .w-e-toolbar .w-e-panel-container {
        i{
            color : #999;
        }
        i:hover {
            color : #333;
        }
    }

    /deep/ .editor-toolba.bottom {

        .w-e-droplist,
        .w-e-panel-container {
            margin-top: 0;
            margin-bottom: 40px;
            top: unset;
            bottom: 0;
        }
    }
</style>