<template>
    <div class="answer-loop-ui">
        <!-- 评价首屏获取评价数据 -->
        <!-- 评价详情界面： 1. 显示评价数据
            2. 显示回复数据，开发scroll功能
            3. 显示回复的回复 -->
        <AnswerLoop 
        @handleRelease='(data,parent)=>options.release(data,parent)' 
        @handleDelete='(data,parent)=>options.delete(data,parent)' 
        @handleUpdate='(data,parent)=>options.update(data,parent)' 
        @loadData='(data,back)=>$emit("load",data,back)' 
        @toClick="(data, callback)=>$emit('toClick', data, callback)"
        :isDelete='options.closeDelete'
        :isUpdate='options.closeUpdate'
        :parentid="parentid"
        :showReplyCount="true"
        :isHidden='true' 
        :showChildBelow='options.showChildBelow'
        :showPaging='true'
        :resource = 'options.uploadAction'
        :isShowReply='options.showReply'
        :uploadData="uploadData"
        @toReply='isReply=>handleReply(items,isReply)'
        :floor="floor" :getFloorData="getFloorData" :getFloorCount="getFloorCount"
        :uploadFloorData="uploadFloorData"></AnswerLoop>
    </div>
</template>

<script>
import AnswerLoop from './AnswerLoop.vue';
export default {
    name : 'answer',
    data (){
        return {
            replyObj : {},
        }
    },
    props : {
        options : {
            type : Object,
            default : ()=>{return {
                delete : function(){},
                release : function(){},
                update : function(){},
                closeDelete : true,
                closeRelease : true,
                closeUpdate : true,
                showReply : true,
                showChildBelow: false
            }}
        },
        floor: {
            type: Number,
            default: 0,
        },
        getFloorData: {
            type: Function,
            default() {
                return () => {};
            },
        },
        getFloorCount: {
            type: Function,
            default() {
                return () => {};
            },
        },
        uploadFloorData: {
            type: Function,
            default() {
                return () => {};
            },
        },
        parentid: {
            type: [Number,String],
            default: 0,
        },
        uploadData : {//文件上传地址
            type : Object,
            default : ()=>{return {
                url : '',//地址
                data : {},//参数
            }},
        }
    },
    watch : {
    },
    mounted (){
    },
    components : {
        AnswerLoop ,
    },
    methods : {
        handleReply (data,isReply){
            if(isReply){this.replyObj ={...data};}
            else {this.replyObj ={};}
        },
        formatData (data){
        },
    },
}
</script>

<style scoped lang='less'>
    .answer-four{
        padding: 0 10px 0 60px;
        &:first-child{
            padding-top:5px;
        }
        &:last-child{
            padding-bottom:5px;
        }
    }
    
</style>