<template>
    <div class="news-ui">
        <div class="news-items">
            <div class="news-shade" v-for="(array,idx) in data" :key="idx">
                <div class="news-item" @click="$emit('toClick',item)" v-for="item in array" :key="item.key">
                    <div class="news-date">
                        <span class="news-date-day">{{item.day}}</span>
                        <span class="news-date-year">{{item.time}}</span>
                    </div>
                    <span class="news-item-title" :title="item.title">{{item.title}}</span>
                </div>
            </div>
            <NoData v-if="!data.length"></NoData>
        </div>
    </div>
</template>

<script>
import NoData from "@/components/common/nodata.vue";
export default {
    data (){
        return {
            data : [],
        }
    },
    watch : {
        'newsData' : 'monitorNewsData'
    },
    props : {
        line : {
            type : Number,
            default : 2,
        },
        newsData : {
            type : Array,
            default : ()=>{return[]},
        },
    },
    components : {
        NoData
    },
    mounted (){
        this.formatData(this.newsData);
    },
    methods : {
        monitorNewsData (newsData){
            this.formatData(newsData);
        },
        formatData (newsData){
            const {line} = this;
            let array = [],cache = [];
            newsData.map((item,idx)=>{
                if(idx%line){
                    cache.push(item); 
                }else{
                    cache = [];
                    cache.push(item);
                    array.push(cache);
                }
            })
            this.data = array;
        },
    },
}
</script>

<style scoped lang="less">
    .news-ui{
        width:100%;
        //box-shadow: 0px 3px 10px 0px rgba(245, 245, 245, 1);
        //padding-bottom:10px;
        .news-items{
            padding: 0px 22px;
            .news-shade{
                display: flex;
                .news-item{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    background-color: #F5F5F5;
                    padding:10px;
                    flex: 1 1 50%;
                    border-radius: 4px;
                    cursor: pointer;
                    &:hover{
                      background-color: aliceblue;  
                    }
                    &:nth-child(1){
                        margin:10px 5px 0 10px;
                    }
                    &:nth-child(2){
                        margin:10px 10px 0 5px;
                    }
                    .news-date{
                        display: flex;
                        flex-direction: column;
                        // margin-right: 20px;
                        // color:#BFBFBF;
                        color: goldenrod;
                        width: 100px;
                        align-items: center;
                        .news-date-day{
                            font-size: 20px;
                            font-weight: bold;
                            margin-bottom:5px;
                            text-align:center;
                        }
                        .news-date-year{
                            padding-top:5px;
                            border-top:1px solid #BFBFBF;
                        }
                    }
                    .news-item-title{
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666666;
                        font-size: 16px;
                        width: 400px; 
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        // width: -webkit-fill-available;
                    }
                }
               
            }
            .news-no-data{
                background-color: #F5F5F5;
                height:77px;
                line-height: 77px;
                color:#BFBFBF;
                text-align: center;
            }
          
        }
    }
</style>