<template>
    <el-icon>
        <component :is="name"></component>
    </el-icon>
</template>

<script>
    export default {
        props : {
            name : {
                type : String,
                default : '',
            }
        },
        data (){
            return {
                
            }
        }
    }
</script>