<template>
<div class="main_view"  v-if="pageType == 1" >
   <div class="box_type">
        <searchTemplate  
            :BoxType='types1'
            :boxData="standardList"
            v-model:data="fromData"
        >
        </searchTemplate>
   </div>

   <div class="shadow_type" v-for="item in shawdowList" :key="item" >
        <searchTemplate  
            :ShadowType="'shadowItem'"
            :shadowData="item"
            v-model:data="fromData"
        >
        </searchTemplate>
   </div>

</div>
<div class="main_view" v-if="pageType == 2">
    <div class="box_type">
        <searchTemplate  
            :BoxType='types1'
            :boxData="softWareType"
            v-model:data="fromData"
        >
        </searchTemplate>
    </div>
</div>
<div class="TableContent">
    <Table :columnData='columnData' :tableData='tableData' :params='tableParams' :loading='loadState' class="table_block" >
    </Table>
   </div>
    
</template>
<script>
import searchTemplate from './searchTemplate.vue'
import Table from '@/components/course/Table.vue';
import Buttons from '@/components/course/Buttons.vue';
import PageItems from '@/components/course/PageItems.vue';
import server from '@/service/server';
import axiosTool from '@/utils/axios_tool';
import checker from '@/utils/checker';
import { ElMessage } from 'element-plus';
export default {
    props:{
        pageType:{
            type:Number,
            default:''
        }
    },
    name:"queryView",
    components:{
        searchTemplate,
        Table,
        Buttons,
        PageItems,
      
    },

    data(){
      return{
            loadState:false,
            queryData:{
                classifyid:1,
                statusid:1,
                dateid:1,
                majorid:1,
            },
            types1:'box_border',
            types2:'shadowItem',
            fromData : {},
            columnData:[
                {key:'autoid',label:'序号',align:'center'},
                {key : 'name',label : '资料名称',align:'center'},
                {key : 'filename',label : '文件名',align:'center'},
                {key : 'status',label : '状态',align:'center'},
                {key : 'createtime',label : '发布日期',align:'center'},
                {key : 'action',style:{width:80},label : '查看详情',options:(result)=>{
                    return [
                        // {key:'1',label:'详情',on_click:this.handleClick(result,'查看详情')}
                         {key : '1' ,label:'详情',type:'element',on_click:(result)=>this.handleClick(result)},
                    ]
                }}
            ],
            tableData:[],
            tableParams:{
                pageChange: this.pageChange,
                selectChange : this.selectChange,
                total: 0,
                pageSize: 8,
                per_page:8,
                currentPage: 1,
                page:1,

            },
            shawdowList:[
                {
                    label:'标准状态',
                },
                { 
                    label:'发布日期',
                }
            ],
           
            standardList:[],
            standardType:[],
            softWareType:[]
        }
    },
    created(){
        this.axiosTool = new axiosTool(this,[
            {
                key:'get_standard',title:'获取分类标准',type:'get',url:server.fileresource,params:{
                    getreq:{
                        file_classify:'true',
                    }
                }
            },
            {
                key:'get_softWare',title:'获取软件类型',type:'get',url:server.fileresource,params:{
                    getreq:{
                        software_classify:'true',
                    }
                }
            },
            {
                key:'get_standard_status',title:'获取标准状态',type:'get',url:server.fileresource,params:{
                    getreq:{
                        file_status:'true',
                    }
                }
            },
            {
                key:'get_publish_date',title:'获取发布日期',type:'get',url:server.fileresource,params:{
                    getreq:{
                        publish_date:'true',
                    }
                }
            },
            {
                key:'get_all',title:'获取全部内容',type:'get',url:server.fileresource,params:{
                    template:'tableParams:page|per_page',
                    getreq:{
                        _order_column:'createtime',_order_style:'desc',state:1,
                    }
                }
            },
            {
                key:'get_all_count',title:'获取全部内容的数量',type:'get',url:server.fileresource,params:{
                    template:'tableParams:page|per_page',
                    getreq:{
                        _order_column:'createtime',_order_style:'desc',count:'true',state:1
                    }
                }
            },
            {
                key:'get_standard',title:'获取标准规范',type:'get',url:server.fileresource,params:{
                    template:'queryData:parentid|classifyid|dateid|statusid|page|per_page,tableParams:page|per_page',
                    getreq:{
                        _order_column:'createtime',_order_style:'desc',state:1
                    }
                }
            },
            {
                key:'get_standard_count',title:'获取标准规范的数量',type:'get',url:server.fileresource,params:{
                    template:'queryData:parentid|classifyid|dateid|statusid',
                    getreq:{
                        _order_column:'createtime',_order_style:'desc',count:'true',state:1
                    }
                }
            },
            {
                key:'get_software',title:'获取计算软件',type:'get',url:server.fileresource,params:{
                    template:'queryData:parentid|majorid|page|per_page,tableParams:page|per_page',
                    getreq:{
                        _order_column:'createtime',_order_style:'desc',state:1
                    }
                }
            },
            {
                key:'get_software_count',title:'获取计算软件数量',type:'get',url:server.fileresource,params:{
                    template:'queryData:parentid|majorid',
                    getreq:{
                        _order_column:'createtime',_order_style:'desc',count:'true',state:1
                    }
                }
            }


        ])
    },
    mounted(){
        this.init() // 初始化数据
        this.initQueryHeader() // 更新查询标头
    },
    methods:{
        init(){
           let {pageType} = this;
           switch(Number(pageType)){
               case 3:
                this.handleAll();
               break;
               case 2:
                this.handleSoftWare();
               break;
               case 1:
                this.get_standard();
               break;
           }
        },
        initQueryHeader(){
            this.axiosTool._axios().then((data)=>{
                const [standard,softWare,standard_status,publish_date] = data ;
                standard.data.map(item=>{
                    item.type = 'classifyid';
                })
                softWare.data.map(item=>{
                    item.type = 'majorid';
                }),
                standard_status.data.map(item=>{
                    item.type = 'statusid';
                })
                publish_date.data.map(item=>{
                    item.type = 'dateid';
                })
                this.softWareType=softWare.data;
                this.standardList = standard.data;
                this.shawdowList.map(item=>{
                    if(item.label =='标准状态'){
                        item.data = standard_status.data;
                    }else if(item.label == '发布日期'){
                        item.data = publish_date.data;
                    }
                })
            })
        },
        handleClick(res){
            let {isLogin} = this
            if(isLogin){
                // console.log(res)
                if(!!res.filepath){
                    window.open(res.filepath)
                }
            }else{
                ElMessage.warning('未登录，请先登录')
            }
        },
        pageTypeMonitor(data){
        this.tableData = [];
              this.queryData={
                classifyid:1,
                statusid:1,
                dateid:1,
                majorid:1,
            };
            this.reloaData(data)
            
        },
        reloaData(data){
            this.loadState = true;    ////////////////////////////////开启加载loading
             if(!!data){
                this.queryData.parentid = data;
                switch(Number(data)){
                case 3:
                    this.handleAll();
                break;
                case 2:
                    this.handleSoftWare();
                break;
                case 1:
                    this.handleStandard();
                break;
                }
                
             }
            
              
        },
        handleAll(){
            this.axiosTool.get_all().then(res=>{
            if(res.status&&res.data&&res.result){
                //  console.log(res.data)
                res.data.result.map(item=>{
                    if(item.state==1){
                        item.status = '已发布'
                    }
                })
                this.handleData(res.data.result)
                this.axiosTool.get_all_count().then(result=>{
                    if(result.status && result.data){
                        this.handleCount(result.data);
                    }
                })

            }
           
            })
             setTimeout(()=>{
                  this.loadState = false;  /////////////////////////////////////////////////关闭加载loading// 
             },500)
         },
        handleStandard(){
            this.axiosTool.get_standard().then(res=>{
                if(res.status&&res.data&&res.result){
                    this.handleData(res.data.result);
                    this.axiosTool.get_standard_count().then(result=>{
                    if(result.status && result.data){
                        this.handleCount(result.data);
                    }
                })
                }
            })
             setTimeout(()=>{
                  this.loadState = false;
             },500)
        },
        handleSoftWare(){
            this.axiosTool.get_software().then(res=>{
                if(res.status&&res.data&&res.result){
                    this.handleData(res.data.result)
                    this.axiosTool.get_software_count().then(result=>{
                        if(res.result && res.data){
                            this.handleCount(result.data);
                        }
                    })
                }
            })
            setTimeout(()=>{
                  this.loadState = false;
             },500)
        },
        handleData(data){
            if(!!data){
              this.tableData = data;
            }
            
             
        },
        handleCount(data){
            this.tableParams.total = Number(data);
        },
        pageChange(value){
            console.log(value)
            this.tableParams.currentPage = Number(value);
            this.tableParams.page =Number(value) ;
            this.reloaData(this.pageType);
        },
        monitorUserInfo(userInfo){
            if(!userInfo || !userInfo.autoid){
                this.userInfo = {};
                this.isLogin = false;
                return ;
            }
            this.isLogin =true;
            this.userInfo = userInfo;
        }

    },
   
    watch :{
       'pageType': 'pageTypeMonitor',
        fromData : function (data){
            if(!!data){
                for(let item in data){
                   this.queryData[item] = data[item];
                }
                console.log(this.pageType)
            }
            this.$nextTick(()=>{
                 this.reloaData(this.pageType)
            })
        },
         '$store.state.user.userInfo' : 'monitorUserInfo',
    }

}
</script>

<style lang="less" scoped>
.main_view{
     font-size: 14px;
    .box_type{
        width: 45%;
        margin: 20px 0;
       
    }
   
}
.TableContent{
       // width: 1370px;
        display: block;
        // height: 700px;
        // flex: 1 1 auto;
        position: relative;
        .table_block{
            position: absolute;
            right: 15px;
           
            width: 99%;
        }
    }


</style>