<template>
    <div class='details-ui'>
        <PageHeader :goMethod='goMethod'>
            <template #content>
                <div class='details-msg'>
                    <div class='msg-item'><span>姓名：</span>{{ userData.user_realname }}</div>
                    <div class='msg-item'><span>学号：</span>{{ userData.user_username }}</div>
                    <div class='msg-item'><span>班级：</span>{{ userData.iclass_name }}</div>
                    <div class='msg-item'><span>审核状态：</span>{{ stateObj.state }}</div>
                </div>
                <div class='details-not' v-if="stateObj.type ==='未提交'">
                    <img src="@/assets/images/no_submit.png" alt="">
                    <span><Buttons type='blue' @click="handleClick('编辑报告')">编辑报告</Buttons></span>    
                </div>
                <div class='details-upload' v-if="stateObj.type ==='展示上传文件'">
                    <PageItems>
                        <template #title>实验报告：</template>
                        <div class='upload-main'>
                            <Uploads 
                                v-model:data='reportFileData'
                                modules="box" 
                                filePosition="right" 
                                class="resources-upload"
                                ref="upload"
                                multiples
                                :accepts='["pdf"]' 
                                @uploadAfter='uploadAfter'
                                @uploadChange='uploadResourceAction'
                                :uploadData='uploadResource'>
                                <template #上传提示>
                                    请上传PDF文件
                                </template>
                            </Uploads>
                        </div>
                        <div class='upload-options'>
                            <span><Buttons plain type='blue' @click="handleClick('存储PDF文件',1)">保存</Buttons></span>
                            <span><Buttons type='blue' @click="handleClick('存储PDF文件',2)">提交</Buttons></span>
                        </div>
                    </PageItems>
                </div>
                <div class='details-rich' v-if="stateObj.type ==='展示富文本'">
                    <WangEditor theme='dark' ref="wangEditor" :customButton='customButton' :defaultHTML="defaultHTML" toolLocation="top" class='wang-editor'/>
                     <div class='upload-options'>
                        <span><Buttons plain type='blue' @click="handleClick('提交实验报告',1)">保存</Buttons></span>
                        <span><Buttons type='blue' @click="handleClick('提交实验报告',2)">提交</Buttons></span>
                    </div>
                </div>
                <div class='details-result'>
                    <template v-if="stateObj.type ==='展示HTML字符串' || stateObj.contentType === 'HTML'">
                        <PageItems>
                            <template #title>实验报告：</template>
                            <span class='result-report' v-html='stateObj.content'></span>
                        </PageItems>
                    </template>
                    <template v-if="stateObj.type ==='展示PDF文件' || stateObj.contentType === 'PDF'">
                        <PageItems>
                            <template #title>实验报告：</template>
                            <span class='result-report pdf-report'>
                                <PDFJS v-if="stateObj.filepath" :filepath="stateObj.filepath"></PDFJS>
                            </span>
                        </PageItems>
                    </template>
                    <template v-if="stateObj.type ==='展示教师评语'">
                        <PageItems>
                            <template #title>报告评语：</template>
                            <span class='result-comment' v-html='stateObj.approveContent'></span>
                        </PageItems>
                    </template>
                </div>
            </template>
        </PageHeader>
    </div>
</template>

<script>

import PageItems from '@/components/course/PageItems.vue';
import PageHeader from '@/components/course/PageHeader.vue';
import Uploads from '@/components/common/Uploads/Uploads.vue';
import Buttons from '@/components/course/Buttons.vue';
import WangEditor from '@/components/course/wangEditor/index.vue';
import PDFJS from '@/components/common/PDF/index.vue';
import checker from '@/utils/checker';
import server from "@/service/server";
import axios from '@/utils/axios';

import { ElMessage } from 'element-plus'
import { resolve } from 'path';

export default {
    name: 'detail',
    data() {
        return {
            filename:"",
            viewState : '', // 未提交状态 上传实验报告 编辑报告状态 报告已提交 pdf报告
            uploadData : [],
            uploadResource : {
                url : server.upload,
                data : {},
            },
           
            customButton : [
                // {key : 'submit',label:'保存',on_click:this.issueWangEditorHandleSubmit}
            ],
            reportTemplate: null,
            defaultHTML: "",
            uploadUrl: [], // 实验报告附件
            reportFileData : [], // 实验报告附件file数据
            reportData: null, // 实验报告数据
            stateConfig : [//editSte save 保存 submit exprecordtypeid报告类型 approvetype审批状态 

                {key : '1',exprecordtypeid:1,editSte:'save',approvetype : 1,state:'草稿',title:'草稿 编辑富文本 保存 可更新内容',type:'展示富文本'},
                //{key : '2',exprecordtypeid:1,editSte:'submit',approvetype : 1,state:'草稿',title:'草稿 编辑富文本 提交 状态已更改不可更新内容',},
                {key : '3',exprecordtypeid:1,approvetype : 2,state:'待审核',title:'待审核 展示实验报告，禁用编辑',type:'展示HTML字符串'},
                {key : '4',exprecordtypeid:1,approvetype : 3,state:'已审核',title:'已审核 展示实验报告 教师评语 分数',type:'展示教师评语',contentType:'HTML'},

                {key : '5',exprecordtypeid:2,editSte:'save',state:'草稿',approvetype : 1,title:'草稿 根据实验模板编辑 保存 可更新内容',type:'展示富文本'},
                //{key : '5',exprecordtypeid:2,editSte:'submit',state:'草稿',approvetype : 1,title:'草稿 根据实验模板编辑 提交 状态已更改不可更新内容',},
                {key : '6',exprecordtypeid:2,approvetype : 2,state:'待审核',title:'待审核 展示实验报告，禁用编辑',type:'展示HTML字符串'},
                {key : '7',exprecordtypeid:2,approvetype : 3,state:'已审核',title:'已审核 展示实验报告 教师评语 分数',type:'展示教师评语',contentType:'HTML'},

                {key : '8',exprecordtypeid:3,editSte:'save',state:'草稿',approvetype : 1,title:'草稿 上传文件 保存 可更新内容',type:'展示上传文件'},
                //{key : '8',exprecordtypeid:3,editSte:'submit',state:'草稿',approvetype : 1,title:'草稿 上传文件 提交 状态已更改不可更新内容',},
                {key : '9',exprecordtypeid:3,approvetype : 2,state:'待审核',title:'待审核 展示PDF文件 禁用上传',type:'展示PDF文件'},
                {key : '10',exprecordtypeid:3,approvetype : 3,state:'已审核',title:'已审核 展示PDF文件 分数 教师评语',type:'展示教师评语',contentType:'PDF'},
                
                {key : '11',exprecordtypeid:4,approvetype : 1,state:'未提交',title:'默认为未提交状态',type:'未提交'},
                {key : '12',exprecordtypeid:4,approvetype : 2,state:'待审核',title:'待审核 展示实验自动生成的报告',type:'展示HTML字符串'},
                {key : '13',exprecordtypeid:4,approvetype : 3,state:'已审核',title:'已审核 展示实验自动生成的报告 分数 教师评语',type:'展示教师评语',contentType:'HTML'},
                {key : '14',exprecordtypeid:0,approvetype : 0,state:'未提交',title:'默认为未提交状态',type:'未提交'},
            ],
            stateObj : {
                exprecordtypeid : 0,
                editSte : false,
                approvetype : 0
            },
        };
    },

    props: {
        userData: {
            type: Object,
            default : ()=>{return {
                user_username: "无",
                user_realname: "无",
                user_number: "无",
                iclass_name: "无",
            }}
        },
        recordAutoid: {
            type : [String, Number],
            default : 0
        },
        expInfo: {
            type : Object,
            default : ()=>{return {}}
        }
    },
    watch : {

    },
    mounted() {
        console.log(this.expInfo);
        this.getReportTemplate();
        // this.getReport();
    },

    methods: {
        setReportState (type,data){
          
            const {stateConfig} = this;
            switch (type){
                case "报告未提交" : 
                    this.stateObj = stateConfig[stateConfig.length - 1];
                    break;
                case '设置实验报告' : 
                    this.$nextTick(()=>{
                        const {$refs:refs} = this;
                        if(refs.wangEditor && data){
                            refs.wangEditor.setHTML(data);
                        }
                    })
                    break;
                case '设置PDF文件' : 
                    this.reportFileData = [
                        {
                            // key:idx,  
                            key:data.autoid, 
                            imgUrl:data.filepath,
                            autoid:data.autoid,
                            name:data.filename,
                            fileUrl:data.filepath,
                        }
                        
                    ]
                    // this.uploadUrl[0]= data.filepath
                    break;
            }
        },
        getReport(param) {
            const {stateConfig,recordAutoid,expInfo} = this;
            let request = {uiexprecordautoid: recordAutoid, person_center: true};
            axios.get(server.datafile + checker.objTurnParams(request)).then(result => {
                console.log(result);
                if(result.status && result.data && result.data.result && result.data.result.length) {
                    let srouce = result.data.result,stateObj = {},
                    data = srouce[srouce.length-1];
                    console.log(srouce)
               
                    this.reportData = data;
                    if(data.approve_data) {
                        let approve_data = data.approve_data,
                        exprecordtypeid = parseInt(expInfo.datafiletype),
                        approvetype = parseInt(approve_data.approvetype);
                        this.setReportState("设置PDF文件",data);
                        stateObj = stateConfig.filter(item=>item.exprecordtypeid === exprecordtypeid && item.approvetype === approvetype)[0];
                        if(stateObj){
                            stateObj.filepath = data.filepath || '';
                            this.uploadUrl[0] = data.filepath || '';
                            // this.setReportState("设置PDF文件",data);
                            if(exprecordtypeid === 2){
                                stateObj.content = data.content || param.content || ''; 
                            }else {
                                stateObj.content = data.content;        
                            }
                            stateObj.approveContent = approve_data.content || '';
                        }
                        this.stateObj = stateObj || {}
                        this.setReportState('设置实验报告',stateObj.content);
                    } else {
                        this.setReportState('报告未提交')
                    }
                } else {
                    this.setReportState('报告未提交')
                }
            })
            .catch(() => this.setReportState('报告未提交'));
        },
        getReportTemplate() {
            const {expInfo} = this;
            let request = {expautoid: expInfo.expautoid};
            axios.get(server.exprecordset + checker.objTurnParams(request)).then(result => {
                if(result.status && result.data && result.data.result) {
                    let data =  result.data.result[0] || {};
                    this.reportTemplate = data;
                    this.getReport(data);
                } else {
                    this.setReportState('报告未提交')
                }
            })
            .catch(() => {
                this.setReportState('报告未提交')
            });
        },
        goMethod (){
            this.$emit('toBack');
        },
        handleClick (type,data){
            console.log(data)
            const {recordAutoid,reportTemplate,uploadUrl,$refs:refs,stateConfig,expInfo} = this;
            let request = {},stateObj = {};
            switch (type){
                case '编辑报告' : 
                    let datafiletype = parseInt(expInfo.datafiletype);
                    if(datafiletype === 1){
                        stateObj = stateConfig[0];
                        this.stateObj = stateObj;
                    }else if(datafiletype === 2){
                        stateObj = stateConfig[3];
                        stateObj.content = reportTemplate.content;
                        this.stateObj = stateObj;
                        this.setReportState('设置实验报告',stateObj.content);
                    }else if(datafiletype === 3){
                        stateObj = stateConfig[6];
                        this.stateObj = stateObj;
                    }else {
                        ElMessage.warning("实验报告只能由实验自动生成！")
                    }
                    
                    break;
                case '存储PDF文件' : 
                    if(!uploadUrl[0]) return  ElMessage.warning("请上传一个实验报告（PDF）文件！");
                    request = {
                        approvetype : data,
                        uiexprecordautoid : recordAutoid,
                        exprecordtypeid : reportTemplate.exprecordtypeid,
                        filepath : uploadUrl[0],
                        filename:this.filename
                    };
                    this.saveDatafile(request).then(success=>{
                        if(success){
                            this.goMethod(); 
                        }
                    });
                    break;
                case '提交实验报告' : 
                    request = {
                        approvetype : data,
                        uiexprecordautoid : recordAutoid,
                        exprecordtypeid : reportTemplate.exprecordtypeid,
                        content : '',
                    };
                    if(refs.wangEditor){
                        request.content = refs.wangEditor.getHTML();
                        //if(!request.content)return  ElMessage.warning("请填写实验报告！");
                    }
                    this.saveDatafile(request).then(success=>{
                        if(success){
                           this.goMethod(); 
                        }
                    });;
                    break;
            }
        },
        saveDatafile (request){
            const {reportData} = this;
            if(reportData && reportData.autoid){
                request.put_data = true;
                request.autoid = reportData.autoid;
            }
            return new Promise(resolve=>{
                axios.post(server.datafile, request).then(result => {
                    if(result && result.status) {
                        this.reportData = result.data.result[0];
                        let msg = request.approvetype == 1 ? "保存成功!" : "提交成功!";
                        ElMessage({
                            message: msg,
                            type: 'success',
                            offset: 170,
                            duration: 1000,
                        });
                        resolve(true)
                    }
                });
            }) 
        },

        uploadAfter (res){
            if(res){
                  this.filename = res.data.name;
            }
          
        //  console.log(data);
        },
        uploadResourceAction (data) {
            console.log(data.slice(-1));
            if(data && data && data.length) {
                this.uploadUrl.push(data.slice(-1)[0].url);
            }else {
                this.uploadUrl = [];
            }
        },
    },
    components : {
        PageHeader,
        Buttons,
        PageItems,
        Uploads,
        WangEditor,
        PDFJS
    },
};
</script>

<style lang="less" scoped>
    .details-ui{
        width:100%;
        .details-msg{
            width:100%;
            display: flex;
            justify-content: space-evenly;
            .msg-item{
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #666666;
                padding:10px 0;
                >span{
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    margin-right:10px;
                }
            }
        }
        .details-not{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px 0;
            >img{
                width:217px;
                height:368px;
                margin:30px 0;
            }
        }
        .details-upload{
            .upload-main{
                width:100%;
                padding: 0 22px;
            }
            .upload-options{
                text-align:center;
                padding:20px 0 10px;
                >span:nth-child(1){
                    margin-right:20px;
                }
            }
        }
         :deep(.editor-plate i){
            font-style:italic !important ;
         }
          :deep(.result-report i){
            font-style:italic !important ;
         }

        .details-rich{
            padding:10px;
            .wang-editor{
                height:500px;
            }
            
            .upload-options{
                text-align:center;
                padding:20px 0 10px;
                >span:nth-child(1){
                    margin-right:20px;
                }
            }
        }

        .details-result{
            padding:0 10px;
            .result-report{
                min-height: 284px;
                //max-height: 400px;
                overflow: auto;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                background: #F2F2F2;
                border-radius: 4px;
                display: inline-block;
                padding: 15px;
                line-height: 20px;
                // text-indent: 30px;
                text-align: justify;
                width: 100%;
                box-sizing: border-box;
                overflow-x: auto;
                >i{
                  font-style: italic;
                }
            }
            .pdf-report {
                text-indent: unset;
            }
            .result-comment{
                // border: 1px solid #2974EA;
                // border-radius: 4px;
                min-height: 120px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                display: inline-block;
                padding: 15px;
                text-indent: 30px;
                line-height: 20px;
                text-align: justify;
                width: 100%;
                box-sizing: border-box;
                word-break: break-all;
            }
        }
    }
</style>