<template>
  <div class="lectures-ui">
    <div class="lectures-content">
        <div class="lectures-title">
            <span class="lectures-back">
                <span @click="JumpTo">返回实验</span>
                
                <!-- <router-link :to="{name: 'expcore', query:{pid: expid, uid: uiautoid,active:'5'}}"><el-icon><arrow-left /></el-icon>返回实验</router-link> -->
                 
            </span>
            <span class="title-text">{{dataTum.name}}</span>
        </div>
        <div class="lectures-content-box">
            <div :class="['content-shade', dataTumClass]">
                <template v-if='dataTum.targettable==dataTumStyle.rt'>
                    <div class="lectures-richtext"><Look ref="look"></Look></div>
                </template>
                <template v-else-if='dataTum.targettable==dataTumStyle.rs && dataTum.resourcetype=="video" && dataTum.filepath'>
                    <video width="100%" height="100%" controls="controls"  id="removeDownload"  controlslist="nodownload noremoteplayback" oncontextmenu="return false" >
                        <source :src="dataTum.resource_data.filepath" type="video/mp4" />
                    </video>
                </template>
                <template v-else-if='dataTum.targettable==dataTumStyle.rs'>
                    <!-- <div class="lectures-download"><a :href="dataTum.resource_data ? dataTum.resource_data.filepath || dataTum.resource_data.link : GLOBALPATH">下载</a></div> -->
                    <iframe class="content-iframe" :src="dataTum.fileUrl" id="fram-down">

                    </iframe>
                    <!-- <div class="lectures-download"><a href="#" @click="handleDown(dataTum.resource_data)">下载</a></div> -->
                </template>
                <!-- <span class="lectures-next">下一节课</span> -->
            </div>
        </div>
    </div>
    <div  class="lectures-drawer">
        <span class="drawer-back" @click="drawerClose">
            <el-icon v-if='drawerState'><arrow-left /></el-icon>
            <el-icon v-if='!drawerState'><arrow-right /></el-icon>
        </span>
        <div :class="['lectures-box',drawerState?'close':'open']">
            <div class="lectures-plate">
                <el-tabs v-model="tab" @tab-click="handleClick">
                    <el-tab-pane v-for="action in actions" :label="action.title" :name="action.key" :key="action.key">
                        <div class="lectures-tabs-item note" v-if="action.type === 'note' && tab === '0'">
                            <WangEditor ref="wangEditor" :key="action.key" :customButton='customButton' @toSubmit='wangEditorHandleSubmit' :isFull="true" toolLocation="top" theme='dark' class="wang-editor-richtexts" placeholder='请输入笔记内容'/>    
                        </div>
                        <!-- <div class="lectures-tabs-item" v-if="action.type === 'menus'">
                            <MenusLine ></MenusLine>
                        </div> -->
                        <div class="lectures-tabs-item ask" v-if="action.type === 'ask'  && tab === '1'">
                            <Interactive :expautoid="expid" :targettable="targettable" :targetautoid="autoid" class="inclass"></Interactive>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
  </div>
</template>

<script src='@/script/Expcore/lectures.js'></script>
<style lang="less" scoped> @import '~@/styles/Expcore/lectures.less'; </style>
