<template>
  <div class="my-center">
    <div class='my-box common-width'>
        <div class="center-menu">
            <!-- <PageItems>
                <template #title>
                <div class="module-title">
                    个人中心
                    <span class="module-english">Personal Center</span>
                </div>
                </template>
                <template #content>
                <div class="module-details">
                    <Menus :pitchData='pitchData' :isJump='isJump' @pitch='handlePitch' :menusData='menusData'>

                    </Menus>
                </div>
                </template>
            </PageItems> -->
            <div class="module-avatar">
                <img :src='imgUrl' alt="">
                <span class="name">hello,{{realname}}</span>
                <el-upload
                    class="upload-demo"
                    :action="uploadUrl"
                    :headers="{'Authorization':token}"
                    :on-success="handleSuccess"
                    :show-file-list="false"
                    accept=".jpg,.png,.jpeg,.gif">
                    <Buttons plain type='blue'>更换头像</Buttons>
                </el-upload>
            </div>
            <div class="module-details">
                <Menus :pitchData='pitchData' :isJump='isJump' @pitch='handlePitch' :menusData='menusData'>

                </Menus>
            </div>
        </div>
        <div class="center-content">
                <template v-if="pitchData.title==='我的实验'">
                    <Myexp></Myexp>
                </template>
                <template v-if="pitchData.title==='我的金币'">
                    <MyMoney></MyMoney>
                </template>
                <template v-if="pitchData.title==='我的订单'">
                    <MyOrder></MyOrder>
                </template>
                <template v-if="pitchData.title==='我的收藏'">
                    <MyCollect></MyCollect>
                </template>
                <template v-if="pitchData.title==='账户设置'">
                    <MyAccount></MyAccount>
                </template>
                <template v-if="pitchData.title==='消息中心'">
                    <NewsCenter></NewsCenter>
                </template>
        </div>
    </div>
      
  </div>
</template>

<script>
    import PageItems from '@/components/course/PageItems.vue';
    import Menus from '@/components/course/Menus.vue';
    import Myexp from './myexp.vue'; 
    import MyMoney from './mymoney.vue';
    import MyOrder from './myorder.vue';
    import MyCollect from './mycollect.vue';
    import MyAccount  from './myaccount.vue';
    import NewsCenter from './newscenter.vue';
    import Buttons from '@/components/course/Buttons.vue';
    import checker from '@/utils/checker';
    import axios from '@/utils/axios';
    import server from '@/service/server';
    import { mapActions } from 'vuex';
    import common from '@/utils/common';

    export default {
        data (){
            return {
                pitchData : {},
                isJump : false,
                menusData : [
                    {key : '001',title:'我的实验',icon:'xxl-experiment',type:'xxl'},
                    // {key : '002',title:'我的金币',icon:'xxl-gold',type:'xxl'},
                    // {key : '003',title:'我的订单',icon:'Tickets',type:'element'},
                    {key : '004',title:'我的收藏',icon:'Star',type:'element'},
                    {key : '005',title:'账户设置',icon:'User',type:'element'},
                    {key : '006',title:'消息中心',icon:'ChatDotSquare',type:'element'},
                ],
                imgUrl: '',
                realname: '',
                uploadUrl: '',
                token: '',
            }
        },
        created() {
            this.uploadUrl = location.origin + process.env.BASE_URL+ 'api/v3/upload?type=image'
            this.token = `JWT ${localStorage.getItem('token') || ''}`;
        },
        beforeMount (){
            this.pitchData = this.menusData[0];
        },
        mounted(){
            this.getUserInfo();
        },
        methods : {
            handlePitch (data){
                this.pitchData = data;
                //console.log(data);
            },
            getUserInfo(){
                axios.get(server.userinfo).then(result=>{
                    if(result && result.status && result.data && result.data.result){
                        let userData = result.data.result[0];
                        this.imgUrl = userData.face || require('@/assets/images/user_logo.png');
                        this.realname = userData.realname;
                    }
                })
            },
            handleSuccess(response, file){//上传头像
                if(response.status && response.data){
                    let fileList = response.data[0];
                    this.imgUrl = fileList.url;
                    this.putImg(fileList);
                }
            },
            putImg(fileList){//修改用户头像
                let request = {put_data: true, face: fileList.url};
                axios.post(server.userinfo,request).then(result=>{
                    if(result && result.status && result.data && result.data.result){
                        this.setUserInfo(result.data.result[0]);
                        return true;
                    }
                })
            },
            ...mapActions(['setUserInfo'])
        },
        components : {
            PageItems,
            Menus,
            Myexp,
            MyMoney,
            MyOrder,
            MyCollect,
            MyAccount,
            NewsCenter,
            Buttons
        },
    }
</script>

<style scoped lang="less">
   @import url('@/styles/common.less');//公共样式
  .media;//媒体查询

    ::v-deep  .page-item .item-head {
        padding: 10px 15px;
    }
    .my-center{
        width:100%;
        background-color:#F2F2F2;
        padding-top:10px;
        height:100%;
        .my-box{
            margin:auto;
            display: flex;
        }
        .center-menu{
            flex:0 0 250px;
            width:250px;
            background-color: #FFFFFF;
            margin-right:10px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            .module-title{
                display: flex;
                flex-direction: column;
                font-weight: bold;
                color: #1A1A1A;
                font-size: 18px;
                font-family: Microsoft YaHei;
                text-align: left;
                border-bottom: 1px solid #C9C9C9;
                .module-english{
                font-weight: 400;
                color: #B6B6B6;
                font-size: 14px;
                font-family: Microsoft YaHei;
                display: flex;
                flex-direction: column;
                
                margin-top:5px;
                &::after{
                    content: '';
                    display: inline-block;
                    width:72px;
                    height:3px;
                    background-color: #1E5FC2;
                    margin-top:5px;
                }
                }
            }
            .module-avatar{
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top:10px;
                margin-bottom: 10px;
                border-bottom: 1px solid #ECECEC;
                >img{
                    width:122px;
                    height:122px;
                    border-radius: 50%;
                    margin-bottom: 15px;
                }
                .name{
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 36px;
                    text-align: center;
                }
                .upload-demo{
                    margin-bottom: 30px;
                }
            }
            .module-details{
                position: relative;
                padding: 10px 20px;
                margin-top: 10px;
                .details-item{
                    display: flex;
                    align-items: center;
                    margin-bottom:15px;
                    >span:nth-child(1){
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666666;
                        margin-right:auto;
                    }
                    >span:nth-child(2){
                        width: 70px;
                        height: 23px;
                        line-height: 23px;
                        text-align: center;
                        border: 1px solid #6FA8FF;
                        border-radius: 4px;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                        margin-left:auto;
                    }
                }
            }
        }
        .center-content{
            flex:1 1 auto;
            background-color: #FFFFFF;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            display: flex;
            flex-direction: column;
            padding:0 10px;
            .content-title{
                width:100%;
                height:53px;
                display: flex;
                align-items: center;
                justify-items: center;
                flex:0 0 53px;
                border-bottom: 1px solid #F5F5F5;
                >span{
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #1A1A1A;
                }
            }
            .content-main{
                flex:1 1 auto;
            }
        }
    }

</style>