<template>
    <!-- 实验评价 -->
    <div class="evaluate-ui">
        <div class="evaluate-modules">
            <PageItems>
                <template #title>
                    <div class="module-title">
                        好评率
                        <span class="module-english">Response rate</span>
                    </div>
                </template>
                <template #content>
                    <div class="module-box">
                        <span>好评率</span>
                        <span>{{ heighstarRate }}</span>
                    </div>
                    <div class="module-details">
                        <div class="details-item" v-for="item in adata" :key="item.key">
                            <span>{{item.label}}</span>
                            <span>{{item.value}}</span>
                        </div>
                    </div>
                </template>
                <template #bar>
                    <el-tooltip effect="dark" placement="top">
                        <template #content>
                            <div class="module-tips">
                                <span>好评：<b>4星</b> 及以上视为好评</span>
                                <span>中评：<b>3星</b> 和 <b>2星</b> 视为中评</span>
                                <span>差评：<b>1星</b> 和 <b>0星</b> 视为差评</span>
                            </div>
                        </template>
                        <span class="module-btn">
                            <el-icon>
                                <question-filled />
                            </el-icon> 好评率
                        </span>
                    </el-tooltip>
                </template>
            </PageItems>
        </div>
        <div class="details-main">
            <TabsNew v-show="viewState.type === '主页'" :active="active" :menuData="menuData" @toClick="changeTab">
                <div class="details-answer">
                    <Answer v-if="pitchActive" @toClick="handleAnswer" :options='options' @load='handleLoad' :parentid="expautoid"
                        :floor="floor" :getFloorData="getFloorData" :getFloorCount="getFloorCount" :key="pitchActive"/>
                </div>
            </TabsNew>
            <div v-show="viewState.type === '详情'" class="answer-details">
                <Details parentField="uistarautoid" @toBack="viewState.type = '主页'" @updateRate="updateRate" :expautoid="expautoid" :data="viewState.data"></Details>
            </div>
        </div>
    </div>
</template>

<script>
    import PageItems from '@/components/course/PageItems.vue';
    import TabsNew from '@/components/business/Tabs/index.vue';
    import Answer from '@/components/course/Answer.vue';
    import Details from './answerDetails.vue';
    import Pagination from '@/components/course/Pagination.vue';
        
    import axios from '@/utils/axios';
    import server from '@/service/server';
    import checker from '@/utils/checker';
    import common from '@/utils/common';

    export default {
        name : 'evaluate',
        data() {
            return {
                heighstarRate: "0.00%",
                count: {
                    total: 0,
                    img: 0,
                    high: 0,
                    middle: 0,
                    low: 0,
                },
                adata: [
                    { key: '001', label: '评价总数', value: 0 },
                    { key: '002', label: '晒图/视频评价', value: 0 },
                    { key: '003', label: '好评', value: 0 },
                    { key: '004', label: '中评', value: 0 },
                    { key: '005', label: '差评', value: 0 },
                ],
                paging: {
                    total: 0,
                    pageSize: 10,
                    currentPage: 1,
                },
                menuData: [
                    { key: '001', title: '全部评价', type: '全部评价', value: 0, extraParam: {} },
                    { key: '002', title: '晒图/视频评价', type: '晒图/视频评价', value: 0, extraParam: {has_imgs:true} },
                    { key: '003', title: '好评', type: '好评', value: 0, extraParam: {get_target_star:true, get_haigh_star:""} },
                    { key: '004', title: '中评', type: '中评', value: 0, extraParam: {get_target_star:true, get_middle_star:""} },
                    { key: '005', title: '差评', type: '差评', value: 0, extraParam: {get_target_star:true, get_low_star:""} },
                ],
                mediaType: {
                    img: ["jpg","png"],
                    video: ["mp4"]
                },
                floor: 3,
                floorData: {
                    3: {
                        api: server.uistar,
                        parentid: "expautoid",
                        childsize: "answer_size",
                        extraParam: {},
                        isRate: true,
                        rateStyle: {
                            disabled: true,
                            showScore: false
                        },
                    },
                    2: {
                        api: server.answer,
                        parentid: "uistarautoid",
                        childsize: "evaluate_size",
                        extraParam: {}
                    },
                    1: {
                        api: server.evaluate,
                        parentid: "answerautoid",
                        extraParam: {}
                    }
                },
                viewState: {
                    type: '主页',
                },
                answerLoaded: false,
                active: '',
                pitchActive: "",
                options: {//评论组件的功能
                    delete: this.handleDelete,
                    release: this.handleRelease,
                    update: this.handleUpdate,
                    closeUpdate: false,
                    showReply: false,
                    closeDelete: false,
                    showChildBelow: false
                },
                expautoid: 0,
                answerItemCallback: null
            }
        },
        watch: {
            // "count.total": "setPage",
        },
        beforeMount() {
            // let storeParams = common.getStorParams({ path: "/expcore" });
            // if (!storeParams) storeParams = common.getStorParams(this);
            this.expautoid = this.autoid;
        },
        mounted() {
            this.getHeighstarRate();
            this.getCount();
        },
        methods: {
            handleDelete() {

            },
            handleRelease() {

            },
            handleUpdate() {

            },
            handleLoad() {

            },
            getHeighstarRate() {
                let request = { expautoid: this.expautoid, get_heighstar_rate: true };
                axios.get(server.uistar + checker.objTurnParams(request)).then(result => {
                    if (result.status) {
                        this.heighstarRate = result.data;
                    }
                })
            },
            setAdata(key, value) {
                this.adata.forEach((item, index) => {
                    if(item.key == key) {
                        item.value = Number(value) || 0;
                        return true;
                    }
                });
                this.menuData.forEach((item, index) => {
                    if(item.key == key) {
                        item.value = Number(value) || 0;
                        return true;
                    }
                });
            },
            setCount(request, countKey, adataKey) {
                axios.get(server.uistar + checker.objTurnParams(request)).then(result => {
                    if (result.status) {
                        this.count[countKey] = result.data;
                        this.setAdata(adataKey, result.data);
                    }
                });
            },
            getCount() {
                // 评价总数
                this.setCount({ expautoid: this.expautoid, count: true, get_all: "" }, "total", "001");
                // 晒图/视频评价
                this.setCount({ expautoid: this.expautoid, has_imgs: true, count: true, get_all: "" }, "img", "002");
                // 好评
                this.setCount({ expautoid: this.expautoid, get_target_star: true, count: true, get_haigh_star: "", get_all: "" }, "high", "003");
                // 中评
                this.setCount({ expautoid: this.expautoid, get_target_star: true, count: true, get_middle_star: "", get_all: "" }, "middle", "004");
                // 差评
                this.setCount({ expautoid: this.expautoid, get_target_star: true, count: true, get_low_star: "", get_all: "" }, "low", "005");
            },
            getFloorData(floor, paging, parentid, callback) {
                let floorData = this.floorData[floor];
                let request = { 
                    page: paging.currentPage, 
                    per_page: paging.pageSize, 
                    get_all: "", 
                    ...floorData.extraParam,
                    _order_column:"createtime",
                    _order_style:"desc",
                    };
                request[floorData.parentid] = parentid;
                axios.get(floorData.api + checker.objTurnParams(request)).then(result => {
                    let answerData = [];
                    if (result.status && result.data && result.data.result) {
                        result.data.result.forEach((item, index) => {
                            let answer = {
                                key: item.autoid,
                                name: item.user_data ? item.user_data.realname || item.user_data.username : "",
                                iconUrl: item.user_data ? item.user_data.face || require("@/assets/images/user_logo.png") : "",
                                time: item.createtime,
                                content: item.content,
                                imgs: [],
                                videos: [],
                                children: [],
                                star: item.star || 0,
                                isClose: true,
                                isRate: floorData.isRate || false,
                                rateStyle: floorData.rateStyle || {}
                            };
                            if(floorData.childsize) answer.childsize = item[floorData.childsize] || 0;
                            if(item.evalresource_data && item.evalresource_data.filepath) {
                                item.evalresource_data.filepath.split(",").forEach((path, fIndex) => {
                                    let ext = path.split(".").pop();
                                    if(this.mediaType.img.indexOf(ext) != -1) {
                                        answer.imgs.push({
                                            "url": path,
                                            "key": fIndex + 1
                                        });
                                    } else if(this.mediaType.video.indexOf(ext) != -1) {
                                        answer.videos.push({
                                            "url": path,
                                            "key": fIndex + 1
                                        });
                                    }
                                });
                            }
                            answerData.push(answer);
                        });
                    }
                    callback(answerData);
                });
            },
            getFloorCount(floor, parentid, callback) {
                let floorData = this.floorData[floor];
                let request = { count: true, get_all: "", ...floorData.extraParam };
                request[floorData.parentid] = parentid;
                axios.get(floorData.api + checker.objTurnParams(request)).then(result => {
                    let count = 0;
                    if (result.status) {
                        count = result.data;
                    }
                    callback(count);
                });
            },
            changeTab(item) {
                this.pitchActive = item.key;
                this.floorData[3].extraParam = item.extraParam;
            },
            handleAnswer(data, callback) {
                this.viewState = {
                    type: '详情',
                    data: data,
                };
                if(callback && checker.isFunction(callback)) this.answerItemCallback = callback;
            },
            updateRate() {
                let {answerItemCallback} = this;
                answerItemCallback && answerItemCallback();
            }
        },
        components: {
            PageItems,
            TabsNew,
            Answer,
            Details,
            Pagination,
        },
        props: {
            autoid: {
            type: [Number, String],
            default: "",
            },
        },
    }
</script>

<style scoped lang="less">
    ::v-deep  .item-shade  {
        border-bottom: 1px solid #C9C9C9;
    }

    .module-tips {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        padding: 0 5px;
        line-height: 20px;

        >span>b {
            font-weight: bold;
        }
    }

    .evaluate-ui {
        display: flex;
        background-color: #F2F2F2;

        .evaluate-modules {
            width: 266px;
            flex: 0 0 auto;
            margin-right: 10px;
            margin-top: 10px;
            background-color: #FFFFFF;
            border-radius: 4px;

            .module-title {
                display: flex;
                flex-direction: column;
                font-weight: bold;
                color: #1A1A1A;
                font-size: 18px;
                font-family: Microsoft YaHei;
                text-align: left;

                .module-english {
                    font-weight: 400;
                    color: #B6B6B6;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    display: flex;
                    flex-direction: column;

                    margin-top: 5px;

                    &::after {
                        content: '';
                        display: inline-block;
                        width: 72px;
                        height: 3px;
                        background-color: #1E5FC2;
                        margin-top: 5px;
                    }
                }
            }

            .module-box {
                display: flex;
                flex-direction: column;
                align-items: center;

                >span:nth-child(1) {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    display: inline-block;
                }

                >span:nth-child(2) {
                    width: 115px;
                    height: 70px;
                    background: #2974EA;
                    border: 2px solid #6FA8FF;
                    box-shadow: 0px 2px 4px 0px rgba(0, 101, 255, 0.2);
                    border-radius: 10px;
                    line-height: 70px;
                    text-align: center;
                    font-size: 26px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #FFFFFF;
                    margin: 10px 0;
                }
            }

            .module-details {
                position: relative;
                padding: 10px 20px;
                margin-top: 10px;

                &::before {
                    position: absolute;
                    top: 0;
                    left: 20px;
                    right: 20px;
                    height: 1px;
                    background-color: #C9C9C9;
                    content: '';
                }

                .details-item {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;

                    >span:nth-child(1) {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666666;
                        margin-right: auto;
                    }

                    >span:nth-child(2) {
                        width: 70px;
                        height: 23px;
                        line-height: 23px;
                        text-align: center;
                        border: 1px solid #6FA8FF;
                        border-radius: 4px;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                        margin-left: auto;
                    }
                }
            }

            .module-btn {
                position: relative;
                top: 6px;
                font-size: 13px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #2974EA;
                cursor: pointer;
                display: flex;
                align-items: center;

                .el-icon-question {
                    font-size: 16px;
                    margin-right: 5px;
                }
            }
        }

        .details-main {
            margin-top: 10px;
            flex: 1 1 auto;
            background-color: #FFFFFF;

            .details-answer {
                padding: 0 20px;
            }

            .answer-details {
                padding: 0 10px;
            }
        }
    }
</style>