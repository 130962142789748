<template>
  <div class="notice-text">
    <div class='notice-box common-width'>
      <div class='crumbs'>
        <el-breadcrumb separator-class='el-icon-arrow-right'>
          <el-breadcrumb-item to='/notice'>新闻公告</el-breadcrumb-item>
          <el-breadcrumb-item><span class='crumbs-item'>正文</span></el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class='notice-main'>
        <div class="notice-title">
          <span class="title-name">{{title}}</span>
          <div class="title-remark">
            <span>发布时间：{{modifiedtime}}</span>
            <span>浏览量：{{view_count}}</span>
          </div>
        </div>
        <div v-html="content" class="notice-content">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from '@/utils/common';
import axios from "@/utils/axios";
import server from "@/service/server";
import checker from "@/utils/checker";
export default {
  data(){
    return{
      autoid: '',
      title: '',
      modifiedtime: '',
      view_count: '',
      content: '',
    }
  },
  updated() {
  $('.notice-content').find('img').css({
      'max-width': '70%',
      // 'max-height': '100%'
  });
},
  mounted (){
    console.log(this.$router)
    // let storeParams = common.getStorParams(this);
    // if(!storeParams) storeParams = common.getStorParams({path:'/noticemain'});
    this.autoid = window.location.href.split('=')[1]
    //console.log(storeParams);
    this.getData(this.autoid);
    this.getViewCount(this.autoid);
  },
  methods:{
    getData(autoid){
      let request = {
        autoid: autoid,
      }
      axios.get(server.coverage + checker.objTurnParams(request)).then(result=>{
        let newData = result.data.result[0] || [];
        this.title = newData.title;
        this.modifiedtime = newData.createtime;
        this.view_count = parseInt(newData.view_count || 0);
        this.content = newData.content;
      })
    },
    getViewCount(targetautoid){
      let params = {"targettable":"coverages","targetautoid":targetautoid,"has_coverages":''}
      axios.post(server.commview,params)
      .then((result)=>{
        return result;
      })
    }
  }
}
</script>

<style scoped lang="less">
  @import url('@/styles/common.less');//公共样式
  .media;//媒体查询
  .notice-text{
    width:100%;
    background-color:#F2F2F2;
    height:100%;
    .notice-box{
      margin:auto;
    }
    .notice-main{
      width:100%;
      background-color: #FFFFFF;
      padding: 20px 0;
      border-radius: 5px;
      .notice-title{
        width:100%;
        .title-name{
          width: 100%;
          font-size: 24px;
          font-family: 'Microsoft YaHei';
          font-weight: bold;
          color: #333333;
          display: inline-block;
          text-align: center;
          line-height: 40px;
          word-break: break-all;
        }
        .title-remark{
          text-align: right;
          padding: 0 20px;
          >span{
            font-size: 14px;
            font-family: 'Microsoft YaHei';
            font-weight: 400;
            color: #999999;
            display: inline-block;
          }
          >span:nth-child(2){
            margin-left:80px;
          }
        }
      }
      .notice-content{
        padding: 30px 20px 0 20px;
        min-height:500px;
        font-family: 'Microsoft YaHei';
        // text-indent: 2em;
        line-height: 30px;
        word-wrap: break-word;
      }
    } 

    .crumbs{
      padding:10px 0 10px;
      .crumbs-item{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
    }
    
  }
</style>