<template>
    <!-- 合作流程 -->
    <layout_20230210_47e9 style="background-color:white;">
        <template #title>合作流程</template>
        <template #describe>
            优秀的虚拟实验绝不仅仅是三维展示，我们使用多领域仿真算法库提供机理仿真计算作为底层支撑
        </template>
        <div class="main">
            <div class="content_title">
                <!-- <p>合作流程</p>
            <p>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p> -->
            </div>
            <div class="content">
                <div class="content_process" v-for="item in stepsData" :key="item.name"  @mouseover="handleClick(item,'鼠标移入')" @mouseout="handleClick(item,'鼠标移出')">
                    <div class="content_block">
                        <span><img :src=item.imageSource alt=""></span>
                        <span>{{ item.title }}</span>
                        <span>{{ item.value }}</span>
                    </div>
                    <p></p>
                    <p v-if="item.title != '第七步'"><img src="@/assets/images/xxl_gw/lianjie.png" alt=""></p>
                    <div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main_detail" >
            <!-- <div class="phone" v-show="Isshow =='第一步'">
              <span>我就是第一步</span>
            </div>
            <div class="phone" v-show="Isshow =='第二步'">
               <div class="phone_left">
                   <div class="title">
                       <span class="fir_title">北京象新力科技有限公司</span>
                       <span>带数值计算得虚拟仿真</span>
                   </div>
                   <div class="phone_content">
                       <div class="icon"> 
                           <span v-for="item in phone_ICON" :key="item.key">
                               <img :src="item.url" alt="">
                           </span>
                       </div>
                       <div class="concact_phone"> 
                           <span class="list" v-for="item in phone" :key="item.type">
                               <span>{{item.type}}</span>
                               <span>{{item.name}}</span>
                               <span>{{item.phone}}</span>
                           </span>
                       </div>
                   </div>
                   <div class="bottom">
                       <span>感谢您选择我们，北京象新力竭诚为您服务！</span>
                   </div>
               </div>
               <div class="phone_right">
                   <img src="@/assets/images/xxl_gw/right_bkg.png" alt="">
               </div>

            </div> -->
        </div>
    </layout_20230210_47e9>
</template>

<script>
import layout_20230210_47e9 from '@/components/layouts/layout_20230210_47e9.vue';
export default {
    name : "sprocess",
    components: {
        layout_20230210_47e9
    },
    methods:{
    handleClick(data,type){
        switch(type){
            case '鼠标移入':
                this.MouseIn(data)
            break;
            case '鼠标移出':
                this.MouseOut(data)
            break;
        }
    },
    MouseIn(data){
        if(data.title == '第一步' ||data.title== '第二步'){
            this.Isshow=data.title;
        }else{
            this.Isshow=false;
        }
    },

    MouseOut(data){
        this. Isshow=false;
        
    }



    },
    data() {
        return {
            Isshow:false,
            phone:[
                {type:'商务合作:',name:'夏女士',phone:'13811282001'},
                {type:'售前咨询:',name:'吴先生',phone:'13732911781'},
                {type:'售后服务:',name:'吴先生',phone:'13732911781'},
                {type:'软件适用:',name:'吴先生',phone:'13732911781'},
                {type:'项目申报:',name:'吴先生',phone:'13732911781'},

            ],
            phone_ICON:[
                {id:'1',url:require('@/assets/images/xxl_gw/ding.png')},
                {id:'1',url:require('@/assets/images/xxl_gw/wechat.png')},
                {id:'1',url:require('@/assets/images/xxl_gw/phone.png')}
               
            ],
            stepsData: [
                { title: "第一步", value: "在线咨询", imageSource: require('@/assets/images/xxl_gw/fir_step.png') },
                { title: "第二步", value: "电话访问", imageSource: require('@/assets/images/xxl_gw/sec_step.png') },
                { title: "第三步", value: "方案报价", imageSource: require('@/assets/images/xxl_gw/third_step.png') },
                { title: "第四步", value: "产品演示", imageSource: require('@/assets/images/xxl_gw/forth_step.png') },
                { title: "第五步", value: "洽谈合作", imageSource: require('@/assets/images/xxl_gw/fifth_step.png') },
                { title: "第六步", value: "安装培训", imageSource: require('@/assets/images/xxl_gw/six_step.png') },
                { title: "第七步", value: "完成项目", imageSource: require('@/assets/images/xxl_gw/seven_step.png') },
            ]
        }
    }
}
</script>

<style scoped lang="less">

.layout_20230210_47e9{
    margin-bottom: 20px;
}
.main {}

.main_detail{
    width: 60%;
    margin-top: 20px;
    .phone{
        display: flex;
        .phone_left{
             width: 50%; 
             padding-left: 20px;
            .title{
                display: flex;
                flex-direction: column; 
                >span{
                    padding-left: 24px;
                    margin-top: 10px;
                    color: #6D6D6D;
                }
                .fir_title{
                    font-size: 20px;
                    font-weight: bolder;
                    color: #F18D00;
                    border-left: 5px solid #F18D00;
                    padding-left: 20px;
                }
            } 
            .phone_content{
                    padding-left: 20px;
                .icon{
                    width: 45%;
                    display: flex;
                    justify-content: space-around;
                    margin-top: 30px;
                }
                .concact_phone{
                    display: flex;
                    flex-direction: column;
                    height: 150px;
                    justify-content: space-evenly;
                    margin-top: 20px;
                        .list{
                            display: flex;
                            justify-content: space-between;
                            width: 70%;
                            color: #6B6B6B; 
                        }
}
                }
            }
            .bottom{
                margin-top: 20px;
                color:#F18D00 ;
                padding-left: 20px;
            }
         }
        .phone_right{
            width: 50%;
            text-align: end;
            >img{
                height: 100%;
                    // width: auto;
                    // height: webkit-fill-available;
            }
         }
    }
  
.content {
    display: flex;
    justify-content: space-between;
}

.content_process {
    display: flex;
    /* width: 14%; */
    margin: 0 auto;


}

.content_process>P {
    line-height: 94px;
    margin-left: 10%;
}

.content_process>P>img {
    vertical-align: middle;
    width: 64px;
}

.content_block {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100px;
    line-height: 24px;
}

.content_block>span>img {
    width: 64px;
    height: 64px;
}

.content_block>span:nth-child(2) {
    font-weight: bolder;
}

.content_block>span:nth-child(3) {
    color: gray;

}

.phone{
    // height: 300px;
    border: 0.5px solid gray;
    border-top: none;
}
</style>