<template>
  <div class="tabs-ui">
        <el-tabs :lazy="true" v-model='tabVal' type="border-card">
            <el-tab-pane v-for="item in tabsData" :name='item.name' :label='item.label' :key="item.key">
                <template  #label>
                    <span>
                        <i v-if="item.icon" :class="item.icon"></i> 
                        {{item.label}}
                    </span>
                </template>
                <slot :tabsItem='item' v-if="item.name === tabVal"></slot>
            </el-tab-pane>
        </el-tabs>
  </div>
</template>
<script>
export default {
    data (){
        return {
            tabVal : ''
        }
    },
    mounted (){
        this.tabVal = this.active;
    },
    emits : ['tabCahnge','update:active'],
    watch : {
        'tabVal' : 'tabValMethod',
        'active' : 'activeMethod',
        'expautoid': "monitorAutoid",
    },
    methods : {
        tabValMethod (tabVal){
            this.$emit('tabCahnge',tabVal);
            this.$emit('update:active',tabVal)
        },
        activeMethod (active){
            this.tabVal = active;
        },
        monitorAutoid(autoid) {
            this.expautoid = autoid;
        },
    },
    props : {
        tabsData : {
            type : Array,
            default : ()=>{return []}
        },
        active : {
            type : String,
            default : '1'
        }
    },
}
</script>
<style scoped>
    .tabs-ui{
        width: 100%;
        height: 100%; 
    }
    .tabs-ui >>> .el-tabs{
        display: flex;
        flex-direction: column;
        padding:0;
        border:0;
        width:100%;
        height:100%;
        box-shadow: none; 
    }
    .tabs-ui >>> .el-tabs__header{
        flex:0 0 auto;
        background-color: transparent;
        border:none;
    }

    .tabs-ui >>> .el-tabs__content{
        flex:1 1 auto;
        padding:0;
    }

    .tabs-ui >>> .el-tabs__nav-scroll{
        display: flex;
        justify-content: center;
        padding:10px 0;
    }


    .tabs-ui >>> .el-tabs__item{
        border-color: var(--el-border-color-base) !important;
        line-height: normal;
        height:auto;
        padding:7px 10px !important;
    }

    .tabs-ui >>> .el-tabs__item:hover{
        /* color:#F59A23 !important; */
        color:rgba(41,116,234,1) !important;
    }

    .tabs-ui >>> .el-tab-pane{
        width:100%;
        height:100%;
    }

    .tabs-ui >>> .el-tabs__item.is-active{
        background-color: rgba(41,116,234,1) !important;
        color: #FFFFFF !important;
        border-color: rgba(41,116,234,1) !important;
    }

    .tabs-ui >>> .el-tabs__item:first-child{
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .tabs-ui >>> .el-tabs__item:last-child{
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
</style>