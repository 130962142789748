<template>
    <div :style='style' :class="['upload-li',isUndefined(loading[file.key]).state ? 'load' : '']" v-for="file in fileList" :key="file.key">
        <img :src="file.imgUrl" alt="暂无图片" v-if="file.isImg">
        <!--<Videos v-if="file.isMedia" :videoUrl="file.imgUrl"></Videos>-->
        <div class="upload-file" v-if="file.isFile">
            <img :src="getFileLogo(file) || ''" alt="">
            <span :title='file.name'>{{file.name}}</span>
        </div>
        <div v-if='!disabled' :title='file.name' :class="['upload-options',isUndefined(loading[file.key]).state ? 'upload-flex' : '']">
            <template v-if="!isUndefined(loading[file.key]).state">
                <span @click="$emit('reset',file)" title="重置"><el-icon><refresh /></el-icon></span>
                <span @click="$emit('delete',file)" title="删除"><el-icon><delete /></el-icon></span>
                <span v-if="file.isImg" @click="$emit('download',file)" title="下载"><el-icon><download /></el-icon></span>
            </template>
            <template v-if="isUndefined(loading[file.key]).state">
                <span class="upload-num">{{`${isUndefined(loading[file.key]).percentage || 0}%`}}</span>
                <div class="upload-chute" >
                    <span class="upload-slider" :style="{width:`${isUndefined(loading[file.key]).percentage || 0}%`}" ></span>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import fileTypes,{fileTypeImg} from './fileTypes';
import Videos from '@/components/course/Videos.vue';
export default {
    data (){
        return {
            fileList : [],
            loading : {
                state : false,
                percentage : 0,
            },
        }
    },
    emits: ['reset','delete','download'],
    mounted (){
        this.monitorFileLists(this.fileLists);
        this.monitorLoadings();
    },
    components : {
        Videos
    },
    watch : {
        'fileLists' : 'monitorFileLists',
        'loadings' : 'monitorLoadings',
    },
    props : {
        fileLists : {
            type : Array,
            default : ()=>{
                return []
            }
        }, 
        loadStates : {
            type : Boolean,
            default : true,
        },
        disabled : {
            type : Boolean,
            default : false,
        },
        loadings : {
            type : Object,
            default : ()=>{return {}}
        },
        style:{
            type : Object,
            default : ()=>{return {}}
        }
    },
    methods : {
        monitorFileLists (fileLists){
            fileLists.map(item=>{
                item.isImg = this.isImg(item);
                item.isFile = this.isFile(item);
            })
            this.fileList = fileLists;
        },
        monitorLoadings (loadings=this.loadings){
            this.loading = loadings;
        },
        isUndefined (data){
            if(!data)return {}
            return data;
        },
        isImg (data){ //检测是否是图片
            if(/\.jpg$|\.png$/.test(data.imgUrl))return true;
            let type;
            for(const param in fileTypes){
                if(fileTypes[param] === data.type){ type = param; }
            }
            if(type === 'png' || type === 'jpg')return true;
            else return false;
        },
        isFile (data){
            if(/\.jpg$|\.png$/.test(data.imgUrl))return false;
            let type;
            for(const param in fileTypes){
                if(fileTypes[param] === data.type){ type = param; }
            }
            //if(type !== 'png' && type !== 'jpg' && type !== 'mp4')return true;
            if(type !== 'png' && type !== 'jpg')return true;
            else return false; 
        },
        getFileLogo (data){
            let type;
            // debugger;
            for(const param in fileTypes){
                if(data.type && fileTypes[param] === data.type){ 
                    type = param; 
                    break;                    
                } else {type = 'zip';}
            }
            
            if(data.fileUrl){
                type = data.fileUrl.substr(data.fileUrl.match(/\.(?!.*\.)/).index+1);
            }
            
            return fileTypeImg[type]
        },
        isMedia (data){
            let type;
            for(const param in fileTypes){
                if(fileTypes[param] === data.type){ type = param; }
            }
            if(type === 'mp4')return true;
            else return false; 
        }
    },
}
</script>

<style scoped lang="less">
    .upload-li.load{
        .upload-options{
            display: flex;
        }
    }
    .upload-li{
        position: relative;
        flex: 0 0 100px;
        width: 100px;
        height: 100px;
        margin-right: 10px;
        margin-top: 10px;
        &:hover{
            .upload-options{
                display: flex;
            }
        }
        .upload-options.upload-flex{
            flex-direction: column;
        }
        .upload-options{
            position: absolute;
            left:0;right:0;
            top:0;bottom:0;
            background-color: rgba(0,0,0,.5);
            display: flex;
            align-items: center;
            justify-content: center;
            display: none;
            border-radius: 4px;
            >span{
                margin-right: 10px;
                color:rgba(255,255,255,.8);
                cursor: pointer;
                &:hover{
                    color:rgba(255,255,255,1);
                    text-decoration: underline;
                }
            }
            >span:last-child{
                margin-right: 0;
            }
            .upload-num{
                font-size: 25px;
                font-weight: bold;
                color: #FFFFFF;
                margin: 0 0 10px 0;
            }
            .upload-chute{
                width: 80%;
                height: 10px;
                background-color: #ebeef5;
                border-radius: 4px;
                .upload-slider{
                    width: 0;
                    height: 100%;
                    display: block;
                    background-color: #409eff;
                    border-radius: 4px;
                }
            }
        }
        
        >img{
            width:100%;
            height:100%;
            border-radius: 4px;
        }
        .upload-file{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width:100%;
            height:100%; 
            box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            >span{
                margin-top: 10px;
                width: 80%;
                text-align: justify;
                font-size: 14px;
                color: #333333;
                word-break: break-all;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                text-align:center;
            }
            >img{
                width: 50%;
                height: 50%;
            }
        }
    }
</style>