

<template>
    <div class="menus-ui">
        <el-menu :default-active="menuActive" class="el-menu-vertical-demo" @select='handleSelect' @open="handleOpen" @close="handleClose">
            <template v-if="isJump">
                <router-link  :to="{path:menu.path,query:menu.params}" v-for="menu in menusData"  :key="menu.key">
                    <el-menu-item :index="menu.key">
                        <component v-if='menu.type==="element"' :is="$icon[menu.icon]"
                            style="width: 20px; height: 21px; margin-right: 15px">
                        </component>    
                        <i v-if="menu.icon && menu.type==='xxl'" :class="[menu.icon]"></i>
                        <template #title>{{menu.title}}</template>
                    </el-menu-item>
                </router-link>
            </template>
            <template v-if="!isJump">
                <el-menu-item v-for="menu in menusData" :key="menu.key" :index="menu.key">
                    <component v-if='menu.type==="element"' :is="$icon[menu.icon]"
                        style="width: 20px; height: 21px; margin-right: 15px;">
                    </component>    
                    <i v-if="menu.icon && menu.type==='xxl'" :class="[menu.icon]"></i>
                    <template #title>{{menu.title}}</template>
                </el-menu-item>
            </template>
        </el-menu>
    </div>
</template>
<script>
import {routes} from '@/router/index';
import { mapActions } from 'vuex'
import checker from '@/utils/checker';

export default {
    props : {
        menusData : {
            type : Array,
            default : ()=>{return []}
        },
        isJump : {
            Boolean,
            default : true,
        },
        pitchData : {
            type : Object,
            default : ()=>{return {}}
        },
    },
    data (){
        return {
            menuActive : '001',
            routesMap : {},
            timer : null,
        }
    },
    mounted (){
        if(this.pitchData.autoid)this.menuActive = this.pitchData.key;
    },
    beforeMount (){
        this.routesMap = checker.analysisRouter(routes);
        if(this.isJump)this.init();
    },
    computed : {
        menusDataVisit (){
            return this.menusData;
        },
        pathVisit (){
            return this.$route.path;
        },
        pitchVisit(){
            return this.pitchData;
        },
    },
    watch : {
        menusDataVisit (){
            if(this.isJump)this.init();
        },
        pathVisit (){
            if(this.isJump)this.init();
        },
        pitchVisit (data){
            this.menuActive = data.key;
        },
    },
    methods : {
        init (){
            this.setRouterMap(this.routesMap);
            let path = this.$route.path,
            menus = this.menusData.filter(item=>item.path === path)[0];
            if(menus){//没找到自定菜单 说明是子菜单
                this.menuActive = menus.key || '';
            }else {
                menus = this.routesMap[this.$route.name] || {};
                path = menus.parent || '';
                path = '/' + path;
                menus = this.menusData.filter(item=>item.path === path)[0] || {};
                this.menuActive = menus.key || '';
            }
        },
        handleOpen (){

        },
        handleSelect (key){
            const {menusData} = this;
            let idx = menusData.findIndex(item=>item.key === key);
            if(idx>=0)this.$emit('pitch',menusData[idx]);
        },
        handleClose (){

        },
        unmounted (){
            clearTimeout(this.timer);
            this.timer = null;
        },
        ...mapActions(['setRouterMap']),
    },
}
</script>
<style scoped>
    .el-menu-item{
        padding: 0px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px 20px;
        border-radius: 4px;
        color: #999999;
        height:47px;
        font-size: 16px;
        margin-bottom: 15px;
    }
    
    .el-menu-item>i{
        margin-right:15px !important;
        width:auto !important;
        font-size: 20px !important;
    }
    .el-menu-item:hover{
        background-color: #F0F0F0;
    }
    .el-menu{
        background-color: transparent;
        border:none;
    }
    .el-menu-item.is-active{
        color:#F18C00;
        background-color: #2974EA;
        color:#FFFFFF;
    }
    
</style>
<style scoped lang='less'>
    .menus-ui{

    }
</style>