<template>
    <div class='evaluate-ui'>
        <div class='evaluate-user'>
            <img :src="evaluate.userIcon" alt="">
            <span :title="evaluate.realname">{{evaluate.realname}}</span>
        </div>
        <div class='evaluate-main'>
            <span class='evaluate-rate' v-if="!evaluate.hiddenStar">
                实验评分：<el-rate :disabled='true' v-model="star" size="large" />
            </span>
            <span class='evaluate-content'>
                <span v-html="evaluate.content"></span>
                <!-- {{evaluate.content}} -->
            </span>
            
            <div class='evaluate-picture' v-if='medias.length'>
                <div class='picture-item' @click="handleClick('选中媒体',item)" v-for='item in medias' :key='item.key'>
                    <img v-if="item.type==='picture'"  :src="item.url" alt="">
                    <Videos v-if="item.type==='video'" :videoUrl="item.url"></Videos>
                </div>
            </div> 
            <!-- <template v-if='pitchMedia[evaluate.key]'>
                <div class='evaluate-show' :title='pitchMedia[evaluate.key].url'>
                    <img v-if="pitchMedia[evaluate.key].type==='picture'" :src="pitchMedia[evaluate.key].url" alt="">
                    <Videos v-if="pitchMedia[evaluate.key].type==='video'" :videoUrl="pitchMedia[evaluate.key].url"></Videos>
                </div>
            </template> -->
            <template v-if='currentData'>
                <div class='evaluate-show' :title='currentData.url'>
                    <img v-if="currentData.type==='picture'" :src="currentData.url" alt="">
                    <Videos v-if="currentData.type==='video'" :videoUrl="currentData.url"></Videos>
                </div>
            </template>
            
            <div :class="['evaluate-option',!pitchMedia[evaluate.key]?'evaluate-no':'']">
                <span>发布于·{{evaluate.createtime}}</span>
                <div class='option-btn'>
                    <span v-for='(item,idx) in option' :key='idx' @click="handleClick(item)">
                        <span class='option-icon' v-if="item==='点赞'">
                            <i :class="['xxl-fabulous',evaluate.like_state ? 'true':'']"></i>
                            {{evaluate.like_count || 0}}
                        </span>
                        <span class='option-icon' v-else-if="item==='评论'">
                            <i class='xxl-comment'></i>
                             {{evaluate.answer_size || 0}}
                        </span>
                        <template v-else>{{item}}</template>
                    </span>
                </div>
            </div>
            <div :class="['evaluate-input',!isEdit?'hidden' : '']">
                <div class='evaluate-upload'>
                    <Uploads 
                        v-model:data='uploadData'
                        v-model:result='uploadResult'
                        modules="btn" 
                        btnPosition="bottom" 
                        class="resources-upload"
                        :accepts='["jpg","png","mp4"]' 
                        :uploadData='requestData'
                        @uploadBefore='uploadBefore'
                        @uploadAfter='uploadAfter'
                        >
                        <template #btnName>上传图片/视频</template>
                        <template #上传提示>你只可上传9张图片和视频，图片大小不得超过5M，视频大小不得超过50M哦~</template>
                    </Uploads>
                </div>
                <el-input size='small' resize='none' :autosize='{minRows:6,maxRows:10}' v-model="formData.value" maxlength="100" placeholder="请输入评论" show-word-limit type="textarea" />
                <div class='upload-btn'>
                    <Buttons type='info' @click="handleClick('取消')">取消</Buttons>
                    <Buttons type='blue' @click="handleClick('发布')">发布</Buttons>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import common from "@/utils/common";
import Uploads from '@/components/common/Uploads/Uploads.vue';
import Buttons from '@/components/course/Buttons.vue';
import Videos from '@/components/course/Videos.vue';
import { ElMessage } from "element-plus";


export default {
    name: 'evaluate_main',

    data() {
        return {
            star : 0,
            uploadData : [],//存储上传文件的File数据
            uploadResult : [],//存储上传文件 接口的返回数据
            requestData : {//上传文件时可以传入额外的参数

            },
            medias : [],
            isEdit : false,//编辑状态
            evaluate:{},//存储当前组件的基础数据
            pitchMedia : {},
            formData : {},
            currentData:{}
        };
    },
    props : {
        data : {
            type : Object,
            default : ()=>{return {}}
        },
        option : {
            type : Array,
            default : ()=>{return []},
        },
    },
    emits : ['toClick','requestBefore'],
    watch : {
        data : 'dataMonitor',
        uploadResult : 'uploadResultMonitor'
    },
    created () {
        
    },
    mounted() {
        this.dataMonitor();
    },

    methods: {
        uploadBefore(cb){//上传之前的回调 检测是否符合上传文件的条件
            const {uploadResult} = this;
            if(uploadResult && uploadResult.length>=9){
                ElMessage.warning("图片和视频上传已达上限！");
                cb(false);
            }
        },
        uploadAfter (data){
            switch(data.data.type){
                case 'image/png':
                    if(data.data.size / 1024 / 1024 > 5){
                        ElMessage.warning("图片大小不得超过5M");
                        data.cb(false);
                    }
                    break;
                case 'image/jpeg':
                    if(data.data.size / 1024 / 1024 > 5){
                        ElMessage.warning("图片大小不得超过5M");
                        data.cb(false);
                    }
                    break;
                case 'video/mp4':
                    if(data.data.size / 1024 / 1024 > 50){
                        ElMessage.warning("视频大小不得超过50M");
                        data.cb(false);
                    }
                    break;
                 default :
                        ElMessage.warning("不支持该类型");
                        data.cb(false);
            }
        },
        uploadResultMonitor (uploadResult){
            let medias = [];
            if(Array.isArray(uploadResult)){
                uploadResult.map(item=>medias.push(item.url));
                this.formData.medias = medias;
            }
        },
        dataMonitor (data=this.data){
            this.evaluate = data;
            this.star = data.star ? parseFloat(data.star) : 0;
            if(Array.isArray(data.media)){
                let media = [],type;
                data.media.map((url,idx)=>{
                    if(/\.jpg$|\.png$|.jpeg$/.test(url))type='picture';
                    else type='video';
                    if(url)media.push({url, type,key:idx});
                })
                if(media[0]){
                    this.pitchMedia[media[0].key] = media[0];
                }
                this.medias = media;
                this.currentData = this.medias[0];
            }
            this.$emit('requestBefore',{type:'上传',callback:data=>{
                //设置上传参数
                this.requestData = {...data,};
            }});
        },
        handleClick (type,data){
            const {evaluate,pitchMedia,formData} = this;
            let cache={};
            switch (type){
                case '回复' : 
                    this.isEdit = !this.isEdit;
                    break;
                case '取消' : 
                    this.isEdit = false;
                    break;
                case '选中媒体' : 
                    //console.log(data)
                    this.currentData = data;
                    cache[data.key] = data;
                    this.pitchMedia = {...pitchMedia,...cache};
                    //console.log(this.pitchMedia)
                    // let i = 0;
                    // this.medias.map((item)=>{
                    //     if(item.key == data.key){
                    //         this.evaluate.key = i;
                    //     }else{
                    //         ++i;
                    //     }
                    // })
                    break;
                case '删除' :
                    this.$confirm('此操作不可逆，是否继续1?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$emit('requestBefore',{type,data:{...evaluate},callback:state=>{
                            //请求删除操作
                            if(state){
                                this.$emit('requestBefore',{type:'刷新',data:{...evaluate}});
                            }
                        }});
                    }).catch(() => {});
                    break;
                case '收藏' : 
                    this.$emit('requestBefore',{type,data:{...evaluate},callback:state=>{
                        //请求收藏操作
                        if(state){
                            this.$emit('requestBefore',{type:'刷新',data:{...evaluate}});
                        }
                    }});
                    break;
                case '发布' : 
                    this.$emit('requestBefore',{type,data:{
                        ...evaluate, _value:formData.value, _medias : formData.medias || [],
                        },callback:state=>{
                        //请求发布操作
                    }});
                    this.formData = {};
                    this.uploadData = [];
                    this.uploadResult = [];
                    break;
                case '点赞' : 
                    this.$emit('requestBefore',{type,data:{...evaluate},callback:state=>{
                        //请求点赞操作
                    }});
                    break;
                default : 
                    this.$emit('toClick',type);
                    break;
            }
        },
    },
    components : {
        Uploads,
        Buttons,
        Videos,
    },
};
</script>

<style lang="less" scoped>
    .option-icon{
        font-size: 12px;
        display:flex;
        align-items:center;
        >i{
            font-size: 18px;
            //margin-right:0;
            &.true{
                color:#00A1D6;
            }
        }
        
    }
    .evaluate-ui{
        width:100%;
        display:flex;
        //margin:auto;
        padding-top: 10px;
        .evaluate-user{
            display:flex;
            flex-direction: column;
            align-items: center;
            width: 70px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            >span{
                padding:5px 0;
                line-height: 20px;
                text-align: justify;
                font-weight: bold;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 70px;
                text-align: center;
                // width: 50px;
            }
            >img{
                width:50px;
                height:50px;
            }
        }
        .evaluate-main{
            display: flex;
            flex-direction: column;
            margin-left:10px;
            flex: 1 1 auto;
            .evaluate-rate{
                display: flex;
                align-items: center;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #1A1A1A;
                margin-bottom:10px;
            }
            .evaluate-content{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                line-height: 20px;
                text-align:justify;
            }
            .evaluate-picture{
                margin-top:10px;
                flex-wrap: wrap;
                display:flex;
                .picture-item{
                    width: 130px;
                    height: 78px;
                    margin-bottom: 10px;
                    margin-right:10px;
                    >img{
                        width: 100%;
                        height: 100%;
                        cursor:pointer;
                    }
                }
            }
            .evaluate-show{
                width:100%;
                height:500px;
                padding-bottom:10px;
                >img{
                    width:100%;
                    height:100%;
                }
            }
            .evaluate-option{
                display: flex;
                text-align: right;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                padding-bottom:10px;
                &.evaluate-no{
                    padding-top:10px;
                }
                .option-btn{
                    margin-left:auto;
                    display:flex;
                    align-items: center;
                    >span{
                        margin-left:20px;
                        cursor:pointer;
                    }
                }
                
            }

            .evaluate-input{
                transition:height .2s ease;
                height: auto;
                overflow:hidden;
                .evaluate-upload{
                    padding-bottom:10px;
                }
                .upload-btn{
                    padding: 10px 0;
                    text-align: right;
                }
            }
            .evaluate-input.hidden{
                height:0px;
            }
        }
    }


    
</style>