 const filetypes = {
    docx : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    png : 'image/png',
    pdf : 'application/pdf',
    jpg : 'image/jpeg',
    mp4 : 'video/mp4',
    xlsx : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    zip : 'application/x-zip-compressed',
    '7z' : 'application/octet-stream',
}

export const fileTypeImg = {
    docx : require('@/assets/images/docx.png'),
    pdf : require('@/assets/images/pdf.png'),
    mp4 : require('@/assets/images/mp4.png'),
    xlsx : require('@/assets/images/xlsx.png'),
    zip : require('@/assets/images/zip.png'),
    '7z' : require('@/assets/images/7z.png'),
    html : require('@/assets/images/html.png'),
    exe : require('@/assets/images/exe.png'),
};

export const resourceTypes = [
    {
        name: "directory",
        types : [""],
    },
    {
        name: "emulate",
        types : ["exe", "xml"],
    },
    {
        name: "pcemulate",
        types : ["exe", "xml"],
    },
    {
        name: "webemulate",
        types : ["zip", "ico", "png", "html", "js", "css", "unityweb", "unity", "json", "xml", "unity3d", "assetbundle"],
    },
    {
        name: "video",
        types : ["mp4", "avi", "wmv", "3gp", "mkv", "flv", "rmvb"],
    },
    {
        name: "audio",
        types : ["mp3", "wav", "acc", "ogg", "oga", "flac"],
    },
    {
        name: "image",
        types : ["jpg", "jpe", "jpeg", "png", "gif", "svg", "bmp"],
    },
    {
        name: "execute",
        types : ["exe"],
    },
    {
        name: "office",
        types : ["doc", "docx", "xls", "xlsx", "ppt", "pptx", "pdf", "txt", "json", "dat", "csv"],
    },
    {
        name: "case",
        types : ["db", "cst", "vue"],
    },
    {
        name: "flash",
        types : ["swf"],
    },
    {
        name: "web",
        types : ["*"],
    },
    {
        name: "vr",
        types : ["exe"],
    },
    {
        name: "ar",
        types : ["exe"],
    },
    {
        name: "ab",
        types : ["assertbundle"],
    },
    {
        name: "simpaper",
        types : [""],
    },
    {
        name: "thopaper",
        types : [""],
    },
    {
        name: "qalib",
        types : ["json", "dat", "csv"],
    },
    {
        name: "other",
        types : ["*"],
    },
]

export default filetypes;
