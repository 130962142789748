<template>
  <!-- 数据分析 -->
  <div class="analysis-ui">
      <div class="analysis-modules">
        <PageItems>
          <template #title>
            <div class="module-title">
              学情数据
              <span class="module-english">Academic data</span>
            </div>
          </template>
          <template #content>
            <div class="module-details">
              <div class="details-item" v-for="item in adata" :key="item.key">
                <span>{{item.label}}</span>
                <span>{{item.value}}</span>
              </div>
            </div>
          </template>
        </PageItems>
      </div>
      <div class="analysis-main">
        <div class="analysis-search">
          <span>时间筛选：</span>
          <div class="search-day">
            <span @click="handlePitch(day)" :class="[searchData.day===day.key ? 'active' : '']" v-for="day in dayData" :key="day.key">{{day.label}}</span>
          </div>
          <div class="search-item">
            <el-date-picker unlink-panels size='small' @change='handlePicker("two",$event)' range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" v-model="searchData.datetime" type="daterange" > </el-date-picker>
          </div>
          <div class="search-item">
            <Buttons type="info" @click="handleReset">重置</Buttons>
            <Buttons type="blue" icon="el-icon-search" @click="handleInquire">查询</Buttons>
          </div>
        </div>
        <div class="analysis-search">
          <span>数据筛选：</span>
          <div class="search-checks">
            <el-checkbox-group v-model="checkData">
              <el-checkbox :label="check.label" v-for="check in chechList" :key="check.key"></el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="analysis-chart">
          <Echars type="line" :option="options" :data="lineData" :formatter="lineFormatter"></Echars>
        </div>
      </div>
  </div>
</template>

<script>
import PageItems from '@/components/course/PageItems.vue';
import Buttons from '@/components/course/Buttons.vue';
import checker from '@/utils/checker';
import Echars from '@/components/common/echars/index.vue';
import common from '@/utils/common';
import axios from '@/utils/axios';
import server from '@/service/server';
import reportVue from './report.vue';
export default {
  data (){
    return {
      expautoid: '',
      // flag: true,
      adata : [
        {key : '001',label:'实验人次',value : '0', params: 'exp_times'},
        {key : '002',label:'实验浏览量',value : '0', params: 'commview_exp_count'},
        {key : '003',label:'实验人数',value : '0', params: 'exp_user_nums'},
        {key : '004',label:'报告提交数',value : '0', params: 'exp_report_commit_nums'},
        {key : '005',label:'资源浏览量',value : '0', params: 'commview_uiexpdatum_count'},
        {key : '006',label:'资源下载数',value : '0', params: 'commdownload_uiexpdatum_count'},
        {key : '007',label:'评论数',value : '0', params: 'uistar_count'},
        {key : '008',label:'提问数',value : '0', params: 'uiissue_count'},
        {key : '009',label:'公布笔记数',value : '0', params: 'expnote_count'},
      ],
      checkData : ['实验浏览量'],
      chechList : [
        {key : '001',value :0,label : '实验浏览量',params: 'get_views',reqType: 'commview_exp_count'},
        {key : '002',value :0,label : '实验人次',params: 'get_exp_used',reqType: 'exp_times'},
        {key : '003',value :0,label : '实验人数',params: 'get_exp_usernums',reqType: 'exp_user_nums'},
        {key : '004',value :0,label : '评论数',params: 'get_uistars',reqType: 'uistar_count'},
        {key : '005',value :0,label : '提问数',params: 'get_uiissues',reqType: 'uiissue_count'},
        {key : '006',value :0,label : '公布笔记数',params: 'get_open_notes',reqType: 'expnote_count'},
        {key : '007',value :0,label : '报告提交数',params: 'get_report_commit',reqType: 'exp_report_commit_nums'},
        {key : '008',value :0,label : '资源浏览量',params: 'get_uidatums_views',reqType: 'commview_uiexpdatum_count'},
        {key : '008',value :0,label : '资源下载数',params: 'get_uidatums_down',reqType: 'commdownload_uiexpdatum_count'},
      ],
      lineData : [
        {
            name: '',
            type: 'line',
            areaStyle: {
              color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                      offset: 0, color: 'rgba(58,132,255, 0.5)' // 0% 处的颜色
                  }, {
                      offset: 1, color: 'rgba(58,132,255, 0)' // 100% 处的颜色
                  }],
                  global: false // 缺省为 false
              }
            },
            // stack: 'Total',
            data: [120, 132, 101, 134, 90, 230, 210]
        },
      ],
      options : {
        name : '',
        xAxisData : ['10-06', '10-07', '10-08', '10-09', '10-10', '10-11', '10-12'],
      },
      searchData : {
        day : '001',
        datetime: '',
      },
      dayData : [
        {key : '001',value :'1',label:'近7天',dayCount: 7},
        {key : '002',value :'2',label:'近30天',dayCount: 30},

      ],
      defaultValue : [],
    }
  },
  beforeMount() {
    // let storeParams = common.getStorParams({ path: "/expcore" });
    // if (!storeParams) storeParams = common.getStorParams(this);
    this.expautoid = this.autoid;
  },
  mounted (){
    this.init();
    this.getAdata();
    this.getLineData();
  },
  watch : {
    'checkData' : 'monitorCheckData',
  },
  methods : {
    init (){
      this.searchData.datetime = [checker.timestampToDate(new Date().getTime()-1000*60*60*24*7,'YYYY-MM-DD'),checker.timestampToDate(new Date().getTime(),'YYYY-MM-DD')];
      ////console.log(this.defaultValue);
    },
    monitorCheckData (data){
      this.checkData = data;
      this.getLineData();
    },
    lineFormatter(params) {
      var tips="";
      for( let i=0; i<params.length;i++){
          tips += params[i].seriesName + ": " + params[i].value + "<br/>"
      }
      return tips;
      
      // return (params[0].seriesName + ": " + params[0].value + "<br/>"
      //  +
      //     params[1].seriesName + ": " + params[1].value + "<br/>" +
      //     params[2].seriesName + ": " + params[2].value
      //     );
    },
    handlePitch (data){
      this.searchData = {
        ...this.searchData,
        day : data.key,
        datetime : [checker.getDate(new Date().getTime()-1000*60*60*24*data.dayCount,'YYYY-MM-DD'),checker.getDate(new Date().getTime(),'YYYY-MM-DD')],
      };
      this.getLineData();
    },
    handlePicker (){

    },
    getAdata(){
      let request = {
        expautoid: this.expautoid,
        get_data_analyze_left: ''
      }
      axios.get(server.dataanalysis + checker.objTurnParams(request)).then(result=>{
        if(result.status){
          let adata = this.adata,aData = result.data;
          adata.map(item=>{
            item.value = Number(aData[item.params]) || 0;
          })
        }
      })
    },
    getLineData(){
      const {chechList,searchData:{datetime},checkData} = this,legendData=[];
      let request = {
        expautoid: this.expautoid,
        start_date : checker.timestampToDate(new Date(datetime[0]).getTime(),'YYYY-MM-DD'),
        end_date : checker.timestampToDate(new Date(datetime[1]).getTime(),'YYYY-MM-DD'),
        get_data_analyze_right : '',
      },xAxisData={},yAxisData=[];
      checkData.map(check=>{
          let idx = chechList.findIndex(item=>item.label === check),
          cache = chechList[idx];
          if(cache)request[cache.params] = '';
      })
      axios.get(server.dataanalysis + checker.objTurnParams(request)).then(result=>{
          if(result.status){
              let cache = result.data;
              for(const param in cache){
                  let item = cache[param],arr = [],
                  idx = chechList.findIndex(obj=>obj.reqType === param && checkData.indexOf(obj.label) >=0 ),
                  objCheck = chechList[idx];
                  if(idx<0)continue;
                  if(Object.keys(item).length){
                      for(const day in item){
                          xAxisData[day] = true;
                          arr.push(item[day] || 0);
                      }
                      legendData.push(objCheck.label);
                      yAxisData.push({data:arr,name:objCheck.label});
                  }
              }
              //console.log(yAxisData);
              this.assembleLine({xAxisData,yAxisData,legendData});
          }
      })
    },
    assembleLine (data){
      let options = {},lineData=[];
      const {xAxisData,yAxisData,legendData} = data;
      options = {
          name : '',
          xAxisData : Object.keys(xAxisData),
          legendData:legendData,
      }
      yAxisData.map((item,idx)=>{
          lineData.push({
              name : item.name,
              type: 'line',
              areaStyle: {
                  color: {
                      type: 'linear',
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [{
                          offset: 0, color: 'rgba(58,132,255, 0.5)' // 0% 处的颜色
                      }, {
                          offset: 1, color: 'rgba(58,132,255, 0)' // 100% 处的颜色
                      }],
                      global: false // 缺省为 false
                  }
              },
              // stack: 'Total',
              data: item.data,
          })
      })
      this.lineData = lineData;
      this.options = options;
    },
    handleReset(){
      this.searchData.datetime = '';
      this.init();
      this.getAdata();
      this.getLineData();
      this.$message({
        message: '重置成功',
        type: 'success'
      })
    },
    handleInquire(){
      if(this.searchData.datetime == ''){
        this.$message({
          message: '请输入正确的查询信息',
          type: 'warning'
        })
      }else{
        this.getLineData();
      }
    },
  },
  components : {
    PageItems,
    Buttons,
    Echars
  },
  props: {
      autoid: {
      type: [Number, String],
      default: "",
      },
  },
}
  
</script>

<style scoped lang="less">
  .analysis-ui{
    display: flex;
    background-color: #F2F2F2;
    .analysis-modules{
      width:266px;
      flex:0 0 auto;
      margin-right:10px;
      margin-top:10px;
      background-color: #FFFFFF;
      border-radius: 4px;
      .module-title{
        display: flex;
        flex-direction: column;
        font-weight: bold;
        color: #1A1A1A;
        font-size: 18px;
        font-family: Microsoft YaHei;
        text-align: left;
        .module-english{
          font-weight: 400;
          color: #B6B6B6;
          font-size: 14px;
          font-family: Microsoft YaHei;
          display: flex;
          flex-direction: column;
          
          margin-top:5px;
          &::after{
            content: '';
            display: inline-block;
            width:72px;
            height:3px;
            background-color: #1E5FC2;
            margin-top:5px;
          }
        }
      }
      .module-details{
        position: relative;
        padding: 10px 20px;
        margin-top: 10px;
        &::before{
          position: absolute;
          top: 0;
          left: 20px;
          right: 20px;
          height: 1px;
          background-color: #C9C9C9;
          content: '';
        }
        .details-item{
          display: flex;
          align-items: center;
          margin-bottom:15px;
          >span:nth-child(1){
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            margin-right:auto;
          }
          >span:nth-child(2){
            width: 70px;
            height: 23px;
            line-height: 23px;
            text-align: center;
            border: 1px solid #6FA8FF;
            border-radius: 4px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            margin-left:auto;
          }
        }
      }
    }

    .analysis-main{
      margin-top:10px;
      flex:1 1 auto;
      background-color: #FFFFFF;
      padding:10px;
      .analysis-search{
        display: flex;
        align-items: center;
        // justify-content: center;
        padding:10px 0;
        
        >span:nth-child(1){
          height: 12px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #666666;
          flex: 0 0 auto;
          margin-right: 10px;
        }
        .search-day{
          border:1px solid #E6E6E6;
          border-radius: 4px;
          height:32px;
          margin-right: 10px;
          >span{
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            display: inline-block;
            color: #1A1A1A;
            padding: 0 20px;
            height:100%;
            line-height: 32px;
            cursor: pointer;
            &.active{
              background-color: #2974EA;
              border-radius: 4px;
              color:#FFFFFF;
            }
          }
        }
        .search-item{
          margin-right:10px;
        }
        .search-item:last-child{
           margin-right:0;
        }

        .search-checks{
          display: flex;
          flex-wrap: wrap;
          text-align: left;
        }
      }

      .analysis-search:nth-child(2){
        align-items: stretch;
        padding: 15px 0px;
        border-bottom: 1px solid #F2F2F2;
        >span:nth-child(1){
          position: relative;
          top: 3px;
        }
        /deep/ .el-checkbox{
          margin-bottom: 10px;
        }
      }


      .analysis-chart{
        width:100%;
        height:500px;
      }
    }
  }
</style>