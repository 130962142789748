
const state = {
    winHeight : 0,//浏览器窗口高度
    winWidth : 0,//浏览器窗口宽度
    ratio_300 : 0,
    mode : 'home',//当前模块 默认首页
    type : 1,//确定当前框架
    routeMap : {},//存储路由映射
    mainStyle : [],//存储主页的样式
    anchor : '',//锚点标识
    loginsign : '',//登录标识
};

const getters = {};
Object.keys(state).forEach((k) => {
  getters[k] = (s) => s[k]
});

const mutations = {
    SET_WIN_HEIGHT : (state,winHeight)=>{
        state.winHeight = winHeight;
    },
    SET_WIN_WIDTH : (state,winWidth)=>{
        state.winWidth = winWidth;
    },
    SET_RATIO_300 : (state,ratio_300)=>{
        state.ratio_300 = ratio_300;
    },
    SET_MODE : (state,mode)=>{
        state.mode = mode;
    },
    SET_TYPE : (state,type)=>{
        state.type = type;
    },
    SET_ROUTE_MAP : (state,routeMap)=>{
        state.routeMap = routeMap;
    },
    SET_MAIN_STYLE : (state,mainStyle)=>{
        state.mainStyle = mainStyle;
    },
    SET_ANCHOR : (state,anchor)=>{
        state.anchor = anchor;
    },
    SET_LOGINSIGN : (state,loginsign)=>{
        state.loginsign = loginsign;
    },
    SET_USERINFO : (state,userInfo)=>{
        state.userInfo = userInfo;
    },
};

const actions = {
    setWinHeight : ({commit},winHeight)=>{
        commit('SET_WIN_HEIGHT',winHeight);
    },
    setWinWidth : ({commit},winWidth)=>{
        commit('SET_WIN_WIDTH',winWidth);
    },
    setRatio300 : ({commit},ratio_300)=>{
        commit('SET_RATIO_300',ratio_300);
    },
    setMode : ({commit},mode)=>{
        commit('SET_MODE',mode);
    },
    setType : ({commit},type)=>{
        commit('SET_TYPE',type)
    },
    setRouteMap : ({commit},routeMap)=>{
        commit('SET_ROUTE_MAP',routeMap)
    },
    setMainStyle : ({commit},mainStyle)=>{
        commit('SET_MAIN_STYLE',mainStyle)
    },
    setAnchor : ({commit},anchor)=>{
        commit('SET_ANCHOR',anchor)
    },
    setLoginsign : ({commit},loginsign)=>{
        commit('SET_LOGINSIGN',loginsign)
    },
};


export default {
    state,
    getters,
    mutations,
    actions,
}