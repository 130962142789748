<template>
    <div class="news-ui">
        <PageItems>
            <template #title>
                <slot name="title">
                    <div class="news-title">
                        <span class="news-title-name">
                            <slot name="title">{{title}}</slot>
                        </span>
                        <span class="news-title-english">
                            <slot name="englishTitle">{{englishTitle}}</slot>
                        </span>
                    </div>
                </slot>
            </template>
            <template #content>
                <slot></slot>
            </template>
            <template #bar v-if="isMore">
                <span class="news-bar" @click="$emit('onMore')">更多 <el-icon><d-arrow-right /></el-icon></span>
            </template>
        </PageItems>
    </div>
</template>

<script>
import PageItems from '@/components/course/PageItems.vue';
export default {
    data (){
        return {
            data : [],
        }
    },
    props : {
        isMore : {
            type : Boolean,
            default : false,
        },
        title : {
            type : String,
            default : '模块标题',
        },
        englishTitle : {
            type : String,
            default : '模块标题',
        },
    },
    components : {
        PageItems
    },

}
</script>

<style scoped lang="less">
    .news-ui{
        width:100%;
        //box-shadow: 0px 3px 10px 0px rgba(245, 245, 245, 1);
        //padding-bottom:10px;
        .news-title{
            display: flex;
            align-items: center;
            .news-title-name{
                position: relative;
                font-size: 18px;
                line-height: 18px;
                font-weight: bold;
                color: #333333;
                padding-left:10px;
                &::before{
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translate(0, -50%);
                    content: '';
                    display: inline-block;
                    height: 15px;
                    width: 3px;
                    background-color: #1E5FC2;
                }
            }
            .news-title-english{
                position: relative;
                font-size: 14px;
                font-weight: 400;
                color: #B6B6B6;
                margin-left: 20px;
                display: flex;
                flex-direction: column;
                &::after{
                    content: '';
                    display: inline-block;
                    height: 3px;
                    width: 72px;
                    background-color: #1E5FC2;
                    margin-top:2px;
                }
            }
        }

        .news-bar{
            font-size: 14px;
            font-weight: 400;
            color: #B6B6B6;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            >i{
              margin-left:10px;
            }
        }
    }
</style>