//import checker from '@/utils/checker';
//window.$PATHNAME = checker.getPrePathname(window.location.href);
window.$PATHNAME = '';
const httpInfos = {
    apiV2Cozlib: 'api/v2/cozlib', //查询审核通过的课程
    apiV2Resourcerelevance: 'api/v2/resourcerelevance', //获取课程文件资源
    apiV2Cozsection: 'api/v2/cozsection', //获取课程目录树
    apiV2Coztask: 'api/v2/coztask', //添加图文型任务
    apiV2Upload: 'api/v2/upload', //上传课程相关文件资源
    // 南昌航空大学
    user : 'api/v3/user',//获取所有用户
    userinfo: 'api/v3/userinfo', //用 token 获取用户信息
    uitext: 'api/v3/uitext', //获取实验介绍的图文数据
    acscode: 'api/v3/acscode', //修改手机号时获取手机短信验证码

    uiexprecord: 'api/v3/uiexprecord', //获取实验记录

    exprecordset: 'api/v3/exprecordset', //获取实验报告模板

    datafile: 'api/v3/datafile', //获取实验报告

    uiexpdatum: 'api/v3/uiexpdatum', //获取实验资料数据

    uistar: 'api/v3/uistar', //获取评价数据

    uiissue: 'api/v3/uiissue', //获取论题数据

    ui: 'api/v3/ui', //根据实验id获取ui
    expapply: 'api/v3/expapply', //获取我的实验

    subject: 'api/v3/subject', //获取专业信息

    upload: 'api/v3/upload', //上传图片资源

    exp: 'api/v3/exp', //实验 接口

    resourcetype: 'api/v3/resourcetype', //获取资源类型

    datumtype: 'api/v3/datumtype', //获取数据资料类型

    richtext: 'api/v3/richtext', //获取图文数据

    expnote: 'api/v3/expnote', //实验笔记

    expuv: 'api/v3/expuv', //统计使用量
    exppv: 'api/v3/exppv', //统计浏览量

    answer: 'api/v3/answer', //评论相关接口

    evaluate: 'api/v3/evaluate', //回复相关接口

    message: 'api/v3/message', //获取消息数据

    log: 'api/v3/log', //获取所有日志

    token: 'api/v3/token', //

    start: 'api/v3/verification_code/start',
    major: 'api/v3/major', //专业相关接口

    expdeclareyear: 'api/v3/expdeclareyear', //获取申报年份

    teachsupport: 'api/v3/teachsupport', //获取教学支持热线
    teachteam: 'api/v3/teachteam', //获取教学团队信息
    teachteammember: 'api/v3/teachteammember', //获取团队成员
    programa: 'api/v3/programa', //获取首页栏目
    coverage: 'api/v3/coverage',//获取首页新闻标题
    recommendexp: 'api/v3/recommendexp', //获取首页推荐项目
    activitynotice: 'api/v3/activitynotice',//获取首页活动专题
    expgrade: 'api/v3/expgrade',//获取首页应用排行
    commview: 'api/v3/commview',//获取新闻浏览量变化
    dataanalysis : 'api/v3/dataanalysis',//教师工作台首页数据
    collectstar: 'api/v3/collectstar',//获取点赞收藏数据
    resource : 'api/v3/resource',//修改资源名称
    agency : 'api/v3/agency',//获取机构
    agencystyle : 'api/v3/agencystyle',//获取机构类型
    // userinfo : 'api/v1/userinfo',//用户登录
    user : 'api/v3/user',//获取所有用户
    banner : 'api/v3/banner',//获取轮播图
    activeproject : 'api/v3/activeproject',//获取活动实验

    profile: 'api/v3/profile',//下载用户名单模板
    favorite:'api/v3/favorite',
    commdownload:'api/v3/commdownload',
    tokenonlycode:'api/v3/tokenonlycode',
    acscode:'api/v3/acscode',


    /////////////////////////官网接口对接/////////////////////////////////////////////
    // 新闻中心:
    coverage:'api/v3/coverage' ,// 新闻中心 / 解决方案
    hotspots:'api/v3/hotspots', // 获取热点信息
    footers:'api/v3/footers', //  获取底部
    honor:'api/v3/honor',  // 获取资质数据
    //资源下载
    fileresource:'api/v3/fileresource',  // 获取资源数据

    // 项目推荐
    recommendexplib:'api/v3/recommendexplib', // 获取推荐类型
    recommendexp:'api/v3/recommendexp',     // 获取推荐实验列表
    block:'api/v3/ctechnic',

    acquisition : 'api/v3/acquisition',//获客接口


};
for (const key in httpInfos) {
    if(process.env.BASE_URL){
        // console.log(process.env.BASE_URL);
        httpInfos[key] = process.env.BASE_URL + httpInfos[key];
    }else {
        httpInfos[key] = httpInfos[key];
    }
}
export default httpInfos;