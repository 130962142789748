<template>
  <div class="crumbs-ui">
      <div class="crumbs-main common-width">
          <div 
          :class="['crumbs-menus',pitch.key === menu.key ? 'active' : '']" 
          @click="handlePitch(menu)" 
          v-for="menu in menusData" 
          :key="menu.key">
              {{menu.label}}
          </div>
      </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import common from '@/utils/common';
import {routes} from '@/router/index';
import axios from "@/utils/axios";
import axiosTool from '@/utils/axios_tool';
import server from "@/service/server";
export default {
    name:'Menu',
    data (){
        return {
            menusData : [
                // {key:'home',label:'首页',icon:'',path:'/home'},
                // {key:'exp',label:'实验中心',icon:'',path:'/exps'},
                // {key:'notice',label:'新闻公告',icon:'',path:'/notice'},
                // {key:'special',label:'活动专题',icon:'',path:'/special'},
                // {key:'ranking',label:'应用排行',icon:'',path:'/ranking'},
            ],
            pitch : {},
            routeMap : {},//存储地址与路由的映射
            pathMap : {
                1 : 'home',
                2 : 'goods',
                3 : 'notice',
                4 : 'special',
                5 : 'ranking',
                6 : 'abutment',
            },
            axiosTool : {},
        }
    },
    watch : {
        '$route.path' : 'monitorPath',
        '$store.state.common.routeMap' : 'monitorRouteMap',
    },
    created (){
        this.axiosTool = new axiosTool('get',server.programa);
    },
    mounted (){
        this.routeMap = this.$store.state.common.routeMap;
        this.getMenusData();
    },
    methods : {
        getMenusData(){//获取菜单列表
            const {pathMap,axiosTool:{programa}} = this;
            let menusData = [];
            programa().then(data=>{
                if(data && Array.isArray(data.result)){
                    data.result.map(item=>{
                        item.key = this.pathMap[item.autoid];
                        item.label = item.name,
                        item.icon = '';
                        item.path = '/' + this.pathMap[item.autoid];
                        menusData.push(item);
                    })
                }
                // menusData.push({
                //     key : this.pathMap[6],
                //     label : '对接文档',
                //     icon : '',
                //     path : 'http://www.s6_2.moocmooe.com/ahdqgc/docs/',
                // });
                this.menusData = menusData;
                this.checkRoute();
            })
        },
        monitorRouteMap (routeMap){//获取并设置routeMap
            this.routeMap = routeMap;
        },
        monitorPath (){//检测路由变化时动态更新菜单选中状态
            this.checkRoute();
        },
        checkRoute (param){ //检测并设置选中的菜单
            const {routeMap,menusData} = this;
            let path = param || this.$route.path,pathObj = routeMap[path];
            if(path === '/my_center'){
                this.pitch = {};
            }else if(pathObj && pathObj.path){
              let findIdx = menusData.findIndex(item=>item.path === pathObj.path);
              if(findIdx >= 0){
                  this.pitch = menusData[findIdx];
              }else if(pathObj.parent)return this.checkRoute(pathObj.parent);
              else {
                  this.pitch = menusData[0];
              }
            }else {
                this.pitch = menusData[0];
            }
        },
        handlePitch (data){
            this.pitch = data;
            this.$router.push(data.path);
        },
        ...mapActions(['setType','setMainStyle'])
    },
}
</script>

<style scoped lang="less">
    @import url('@/styles/common.less');//公共样式
    .media;//媒体查询
    .crumbs-ui{
        width:100%;
        height:50px;
        display: flex;
        justify-content: center;
        background-color: #1E5FC2;
        .crumbs-main{
            display: flex;
            align-items: center;
            .crumbs-menus{
                float: left;

                padding: 5px 40px 0;
                color: #FFFFFF;
                display: flex;
                flex-direction: column;
                justify-content: center;
                cursor: pointer;
                text-align: center;
                &::after{
                    content: '';
                    height: 2px;
                    width: 100%;
                    color: #FFFFFF;
                    display: inline-block;
                    background-color: #FFFFFF;
                    margin-top: 10px;
                    opacity: 0;
                }
                &.active{
                    &::after{
                        opacity: 1;
                    }
                }
                &:hover{
                    &::after{
                        opacity: 1;
                    }
                }
            }
        }
    }
</style>