<template>
  <div class="aranking-ui">
    <div class="aranking-title" >
      <div class="title-item" v-for="item in titleData" :key="item.key">
        <img :src="item.logo" alt="热门排行" />
        <span>{{ item.title }}</span>
      </div>
    </div>
    <div class="aranking-main" v-if='data.length'>
      <div class='aranking-li' v-for="(item,idx) in titleData" :key="item.key">
        <div class="aranking-item" v-for="(child,i) in data.filter(ite=>ite.expgrade_type === item.title)" :key="child.key" @click="handleExpClick(child)" >
            <span :class="['aranking-' + (idx + 1)]" v-if="i <= 2">{{ i + 1 }}</span>
            <span v-if="i > 2">{{ i + 1 }}</span>
            <img :src="child.imgUrl" alt="" />
            <div class="ranking-describe"> 
              <span>{{ child.title }}</span>
              <span>
                <span>{{ child.realname }}</span>
              </span>
              <span><el-icon><user-filled /></el-icon>{{ child.exppv_num }}</span>
            </div>
          </div>
      </div>
    </div>
    <NoData v-else></NoData>
  </div>
</template>

<script>
import axios from "@/utils/axios";
import NoData from "@/components/common/nodata.vue";
import server from "@/service/server";
import checker from "@/utils/checker";
import common from "@/utils/common";
import axiosTool from '@/utils/axios_tool';
export default {
  data() {
    return {
      titleData: [
        {
          key: "001",
          title: "热门排行",
          children: [],
          logo : require('@/assets/images/pcrown1.png'),
        },
        {
          key: "002",
          title: "好评排行",
          children: [],
          logo : require('@/assets/images/pcrown2.png'),
        },
        {
          key: "003",
          title: "吸引排行",
          children: [],
          logo : require('@/assets/images/pcrown3.png'),
        },
      ],
    };
  },
  emits : ['toClick'],
  props : {
    data : {
      type : Array,
      default : ()=>{return[]}
    }
  },
  components : {
    NoData
  },
  mounted() {
  },
  methods: {
    handleExpClick(data) {
      //console.log(data);
      this.$emit('toClick',data) 
    },
  },
};
</script>

<style scoped lang="less">

  .no-data{
    margin: auto;
    .no-data-img{
      background-image: url('~@/assets/images/Empty.jpg');
      background-size: 1595px 3004px;
      background-position: -1047px -204px;
      width: 242px;
      height: 228px;
      display: inline-block;
    }
  }

.aranking-ui {
  width: 100%;
  display: flex;
  flex-direction: column;
  .aranking-title {
    position: relative;
    display: flex;
    padding: 20px 0 10px;
    .title-item{
      position: relative;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e6e6e6;
      flex: 0 0 33.33%;
      > img {
        width: 39px;
        height: 36px;
      }
      > span {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 185px;
        height: 2px;
        background-color: #ff635f;
        content: "";
        display: inline-block;
      }
    }
    
  }
  .aranking-main {
    display: flex;
    .aranking-li {
      flex: 0 0 33.33333%;
      .aranking-item {
        display: flex;
        align-items: center;
        height: 84px;
        margin-top: 10px;
        cursor: pointer;
        > span {
          width: 34px;
          height: 32px;
          border-radius: 4px;
          text-align: center;
          line-height: 32px;
          font-size: 17px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #1a1a1a;
          flex: 0 0 auto;
          margin-right: 10px;
        }
        span.aranking-1 {
          background-color: #ff635f;
          color: #ffffff;
        }
        span.aranking-2 {
          background-color: #ffc53c;
          color: #ffffff;
        }
        span.aranking-3 {
          background-color: #6fa8ff;
          color: #ffffff;
        }
        > img {
          width: 142px;
          height: 100%;
          border-radius: 4px;
          flex: 0 0 auto;
        }
        .ranking-describe {
          display: flex;
          flex-direction: column;
          align-content: space-between;
          text-align: left;
          height: 100%;
          padding: 0 10px;
          > span {
            display: inline-block;
          }
          > span:nth-child(1) {
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #1a1a1a;
            margin-bottom: auto;
            padding-top: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 185px;
          }
          > span:nth-child(2) > span {
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            border-radius: 4px;
            padding: 5px;
            background-color: #f2f2f2;
            flex: 0 0 auto;
          }
          > span:nth-child(3) {
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            margin-top: auto;
            display: flex;
            align-items: center;
            > i {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
</style>