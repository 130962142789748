<template>
    <div :class="['page-item',adapt ? 'adapt' : '']">
        <div class="item-head">
            <div class="item-shade">
                <span class="head-title">
                    <slot name="title">{{title}}</slot>
                </span>
                <span v-if="!isHiddenTitleRight" class="head-right"><slot name="bar"></slot></span>
            </div>
            
        </div>
        <div class="title-content">
            <slot name='content'></slot>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    data (){
        return {
            isHiddenTitleRight : true,
        }
    },
    props : {
        title : {
            type : String,
            default : '项头标题',
        },
        adapt : {
            type : Boolean,
            default : false,
        },
    },
    mounted (){
        if(this.$slots.bar){
            this.isHiddenTitleRight = false;
        }else {
            this.isHiddenTitleRight = true;
        }

    },
}
</script>

<style scoped>
    .page-item{
        width:100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
    }
    .page-item.adapt{
        height:100%;
    }
    .page-item .item-head{
        clear:both;
        flex:0 0 auto;
        padding:10px 22px;
    }
    .page-item .item-head .item-shade{
        width:100%;
        display: flex;
        align-items: center;
    }

    .item-shade .head-title{
        margin-right:auto;
        font-weight: bold;
        color: #1A1A1A;
        font-size: 18px;
        width: 100%;
    }

    .item-shade .head-right{
        float:right;
        margin-left:auto;
        flex: 0 0 auto;
    }

    .page-item .title-content{
        position: relative;
        flex:1 1 auto;
    }
</style>