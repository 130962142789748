import {
  createWebHashHistory,
  createRouter
} from "vue-router";
import guard from './guard';
import Main from '@/views/Main/index.vue';
import Home from '@/views/Main/Home.vue';
import Exps from '@/views/Main/Exps.vue';
import Notice from '@/views/Main/Notice.vue';
import NoticeText from '@/views/Main/NoticeText.vue';
import Special from '@/views/Main/Special.vue';
import NewsFeature from '@/views/Main/NewsFeature.vue';
import Ranking from '@/views/Main/Ranking.vue';
import MyCenter from '@/views/Main/MyCenter/index.vue';
import Login from '@/views/Login/login.vue';

import Myexp from '@/views/Main/MyCenter/Myexp/index.vue';
import Expcore from '@/views/Main/Expcore/index.vue';
import Report from '@/views/Main/Expcore/report.vue';
import Lectures from '@/views/Main/Expcore/lectures.vue';
import Test from '@/components/business/Evaluate/test.vue';

//象新力官网路由文件 ↓
import portal from '@/views/Home/index.vue';
import home_new from '@/views/Home/home.vue';
import News from '@/views/Home/News/index.vue';
import Solution from '@/views/Home/Solution/index.vue';
import Goods from '@/views/Home/Goods/index.vue';

import SolutionGoods from '@/views/Home/SolutionGoods/index.vue';

import Download from '@/views/Home/Download/index.vue';
import Aboutus from '@/views/Home/Aboutus/index.vue';
import GwNoticeText from '@/views/Home/News/GwNoticeText.vue'

const uccn = [
  {
    path: '/',
    name: 'portal',
    component: portal,
    title: '门户页',
    redirect: 'home1',
    children: [
      {
        path: '/home1',
        name: 'home1',
        component: home_new,
        title: '官网首页',
      },
      {
        path: '/news',
        name: 'news',
        component: News,
        title: '新闻中心',
      },
      {
        path: '/news_details',
        name: 'news_details',
        component: NoticeText,
        title: '新闻中心',
        parent: '/news',

      },
      {
        path: '/solution',
        name: 'solution',
        component: Solution,
        title: '解决方案',
      },
      {
        path: '/solution_goods',
        name: 'solution_goods',
        // component: Goods,
        component: SolutionGoods,
        title: '产品中心',
      },
      {
        path: '/expcore',
        name: 'expcore',
        component: Expcore,
        title: '实验详情',
        parent: '/goods',
      },
      {
        path: '/download',
        name: 'download',
        component: Download,
        title: '资源下载',
      },
      {
        path: '/aboutus',
        name: 'aboutus',
        component: Aboutus,
        title: '关于我们',
      },
      {
        path: '/GwNoticeText',
        name: 'GwNoticeText',
        component: GwNoticeText,
        title: '新闻详情'
      }


    ]
  },
]

const routes = [
  {
    path: '/lectures',
    name: 'lectures',
    title: '前台-视频学习',
    component: Lectures,
  },
  {
    path: '/test',
    name: 'test',
    title: '前台-视频学习',
    component: Test,
  },

  ...uccn,



  {
    path: '/main',
    name: 'main',
    component: Main,
    title: '前台界面',
    redirect: 'home',
    children: [{
      path: '/home',
      name: 'home',
      component: Home,
      title: '后台门户界面',
    },
    {
      path: '/exps',
      name: 'exps',
      component: Exps,
      title: '实验中心',
    },
    {
      path: '/notice',
      name: 'notice',
      component: Notice,
      title: '新闻公告',
    },
    {
      path: '/noticemain',
      name: 'noticemain',
      component: NoticeText,
      title: '公告正文',
      parent: '/notice',
    },
    {
      path: '/special',
      name: 'special',
      component: Special,
      title: '新闻专题',
    },
    {
      path: '/NewsFeature',
      name: 'NewsFeature',
      component: NewsFeature,
      title: '新闻专题详情',
      parent: '/special',
    },
    {
      path: '/ranking',
      name: 'ranking',
      component: Ranking,
      title: '应用排行',
    },
    {
      path: '/my_center',
      name: 'my_center',
      component: MyCenter,
      title: '个人中心',
    },
    // {
    //   path: '/expcore',
    //   name: 'expcore',
    //   component: Expcore,
    //   title: '实验详情',
    //   parent: '/goods',
    // },
    {
      path: '/my_exp',
      name: 'my_exp',
      title: '我的实验',
      component: Myexp,
      parent: '/my_center',
    },
    ],
  },
  {
    path: '/report',
    name: 'report',
    component: Report,
    title: '实验报告',
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    title: '登录界面',
  },
]

export {
  uccn,
  routes
};

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: 'router-link-active',
  routes,
})

router.beforeEach(guard);

export default router;