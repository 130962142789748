<template>
    <div class="no-data">
        <span class='no-data-img'></span>
        <span class="empty-remark">暂无数据</span>
    </div>
</template>
<script>
    import {
    defineComponent
    } from 'vue'

    export default defineComponent({
    name: 'NoData',
    inheritAttrs: false,
    props: {}
    })
</script>
<style scoped lang='less'>
.no-data{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .no-data-img{
        background-image: url('~@/assets/images/Empty.jpg');
        background-size: 1595px 3004px;
        background-position: -1047px -204px;
        width: 242px;
        height: 228px;
        display: inline-block;
    }
    .empty-remark{
        display: inline-block;
        padding: 10px 0;
        color: #e0e3e9;
        white-space: nowrap;
    }
}
</style>
