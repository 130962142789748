<template>
    <layout_20230210_47e9 mode>
        <div class="solution">
            <div class="solution-nav">
                <div :class="['nav-item']" @click="handleClick('选中', item)"
                    v-for="item in navData" :key="item.key">
                    <span>{{ item.title }}</span>
                    <img v-if="active.key === item.key" class="nav-item-pitch" :src="base_url + 'images/home/solution_pitch.png'" alt="">
                </div>
            </div>
            <div class="solution-content" v-if="!!active.content">
                <div v-html="active.content"></div>
                <div class="solution-files" v-if="content_data.length">
                    <span class="files-title">附件连接</span>
                    <a :href="file.url" target="_blank" v-for="file in content_data" :key="file.key">{{file.filename}}</a>
                </div>
            </div>
            <NoData v-else></NoData>
            
        </div>
    </layout_20230210_47e9>
</template>

<script>
import { defineComponent } from 'vue';
import layout_20230210_47e9 from '@/components/layouts/layout_20230210_47e9.vue';
import server from "@/service/server";
import axiosTool from '@/utils/axios_tool';
import NoData from '@/components/common/nodata.vue';

export default defineComponent({
    name: 'panel',
    components: {
        layout_20230210_47e9,
        NoData
    },
    mounted() {
        this.axiosTool = new axiosTool(this, [
            {
                key: 'coverage', type: 'get', title: '获取所有的解决方案', url: server.coverage,
                params: {
                    template: '',
                    getreq: { get_all: true, typeid: '6', parentid: '0' }
                }
            },
        ])

        this.querySolutionTitle();
    },
    data() {
        return {
            navData: [],
            active: {},
            content_data : [],
        }
    },
    watch : {
        active : 'activeMonitor'
    },
    methods: {
        handleClick(type, data) {
            switch (type) {
                case '选中':
                    this.active = data;
                    this.querySolutioinSub();
                    break;
            }
        },
        activeMonitor (active){
            let content_data = [];
            if (active.expattachment){
                try{
                    content_data = JSON.parse(active.expattachment);
                    content_data.map((o,idx)=>{
                        o.key = idx;
                    })
                }catch(e){
                    content_data = [];
                }
            }
            this.content_data = content_data;
        },
        querySolutionTitle() {
            this.axiosTool.coverage().then(data => {
                if (data.status && Array.isArray(data.result)) {
                    let navData = [];
                    data.result.forEach((o) => {
                        o.key = o.autoid;
                        navData.push(o);
                    })
                    this.active = data.result[0] || {};
                    this.navData = navData;
                }
            })
        },
    },
})
</script>

<style scoped lang="less">
.solution {
    display: flex;
}

.solution-nav {
    flex: 0 0 auto;
    background-color: #F1F4F7;
    color: #737A92;
}

.nav-item {
    position: relative;
    height:56px;
    line-height: 56px;
    width: 330px;
    cursor: pointer;

    >span{
        position: relative;
        z-index: 1;
        padding-left: 20px;
    }
    .nav-item-pitch{
        position: absolute;
        left:0;right:0;
        top : 0;bottom: 0;
    }
}

.nav-item.active {
    color: #F18D00;

}

.nav-item.active::before {
    position: absolute;
    content: '';
    width: 3px;
    display: inline-block;
    height: 100%;
    background: #F18D00;
    top: 0;
    left: 0;
}

.solution-content {
    flex: 1 1 auto;
    margin-left: 20px;
    line-height: 30px;
}

.solution-files{
    padding-top:20px;
    display: flex;
    flex-direction: column;
}

.files-title{
    font-size: 20px;
    font-weight: bold;
}

.content-item {
    margin-bottom: 10px;
}

.content-title {

    margin-bottom: 10px;
}

.title-icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: linear-gradient(180deg, #104C9F, #7D93CA);
    box-shadow: 0px 4px 0px 0px #3A559A, 0px 8px 0px 0px rgba(255, 255, 255, 0.2), 0px -8px 0px 0px rgba(255, 255, 255, 0.2);
    margin-right: 10px;
}

.title-txt {
    font-size: 20px;
    font-weight: bold;
    color: #134894;
}

.content-box {

}
.content-txt{
    margin-bottom: 10px;
    line-height: 28px;
}

.content-img{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap : 10px;
}

.img-elem{
    display: inline-block;
    height:100px;
    width:100%;
}

</style>