<template>
    <div class="home-ui">
        <template v-for="item in config.children" :key="item.key">
            <div ref="mode" :data-key="item.key" :style="item.style" :class="['home-shade',animation[item.key]]">
                <component :refresh="animation[item.key]" :is="item.component"></component>
            </div>
        </template>
    </div>
</template>

<script>
import _ from '@/views/Home/config';
import * as components from '@/views/Home/index.js';
export default {
    data() {
        return {
            config: {},
            win_height : 0,
            animation:{},
        }
    },
    created() {
        this.config = _['home'];
    },
    mounted() {
        this.get_win_height();
        
        window.addEventListener('scroll', this.scroll, false)
        window.addEventListener('resize', this.resize, false)
        //console.log(this.$refs.mode);
        this.$nextTick(this.get_elem_height)
    },
    methods: {
        get_win_height() {
            const win_height = document.body.clientHeight;
            this.win_height = win_height;
        },
        get_elem_height() {
            const { $refs: refs ,win_height} = this;
            const modes = refs.mode;
            for (let i = 0; i < modes.length; i++) {
                const data_key = modes[i].getAttribute('data-key');
                const top = modes[i].getBoundingClientRect().top;
                if(win_height - top > 50){
                    this.animation[data_key] = 'show';
                }else {
                    this.animation[data_key] = 'show';
                }
            }
        },
        resize() {
            this.get_win_height();
        },
        scroll() {
            this.get_elem_height();
        },
    },
    components: {
        ...components.home,
    },
    unmounted() {
        window.removeEventListener('scroll', this.scroll, false);
        window.removeEventListener('resize', this.resize, false);
    },
}
</script>

<style scoped lang="less">
    .home-shade{
        position: relative;
        top:50px;
        opacity: 0;
        transition: opacity .3s ease-in,top .3s ease-in;
        margin-top: 65px;
    }

    .home-shade.show {
        opacity: 1;
        top:0;
    }

    .home-shade.hidden{
        opacity: 0;
        top:50px;
    }

</style>