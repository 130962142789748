<template>
    <!-- 新闻中心 -->
    <layout_20230210_47e9>
        <template #title>新闻中心</template>
        
        <layout_20230210_5f79>
            <!-- <template #line> 
                <img class="center-line" src="/images/home/center_line.png" alt="新闻中心分割线">
            </template> -->
            <template #left-head>
                <div class="press-center">
                    
                    <div class="el-tabs" >
                    <el-tabs class="new-tabs" v-model="tab_active" @tab-click="handleClick">
                        <el-tab-pane v-for="menu in menuData" :key="menu.key" :name="menu.key">
                            <template #label>
                                <el-icon class="press-icon" :size="20">
                                    <component :is="menu.icon"></component>
                                </el-icon>
                                {{ menu.value }}
                                <!-- <img class="center-line" :src="`${base_url}images/home/center_line.png`" alt="新闻中心分割线"> -->
                            </template>
                            <!-- <div class="news_content" v-if="menu.type == active">
                                <div class="news_content_detail" v-for="listt in menu.listData" :key="listt.key"> -->
                             <div class="news_content">
                                <div class="news_content_detail" v-for="listt in menuContent" :key="listt.key">
                                    <span class="list-item" style="cursor: pointer;" @click="to_details(listt)"> <span class="list-content">{{ listt.title }}</span></span>
                                </div>
                                <div v-if="!menuContent.length">
                                    <NoData></NoData>
                                </div>
                             </div>
                        </el-tab-pane>
                    </el-tabs>
                    </div>
                    <div class="press-more" @click="toMore()">
                        更多 <Icon class="more-icon" name="ArrowRightBold" ></Icon>
                    </div>
                </div>
            </template>
            <template #right-head>
                <span class="press-title">
                    热点聚焦
                </span>
                <img class="center-line" :src="`${base_url}images/home/fire_line.png`" alt="热点聚焦分割线">
            </template>
            <template #right-content>
                <div class="press-card">
                    <div class="card-item" style="cursor: pointer;" v-for="item in hot_focus" :key="item.key" @click="JumpTo(item.linkto)">
                        <a>
                        <!-- <a :href="item.linkto"> -->
                            <img :src="item.imgpath">
                        </a>
                    </div>
                    <!-- <div class="card-item">
                        <img src="/images/home/xkt.png" alt="象课堂">
                    </div>
                    <div class="card-item">
                        <img src="/images/home/zxxnfzpt.png" alt="在线虚拟仿真平台">
                    </div> -->
                </div>
            </template>
        </layout_20230210_5f79>
        <template #line>
             <img class="center-linee" :src="base_url + '/images/home/center_line.png'" alt="新闻中心分割线">
        </template>
    </layout_20230210_47e9>
</template>

<script>
import layout_20230210_47e9 from '@/components/layouts/layout_20230210_47e9.vue';
import layout_20230210_5f79 from '@/components/layouts/layout_20230210_5f79.vue';
import Fire from '@/components/common/icon/fire.vue';
import Icon from '@/components/common/icon/index.vue';
import NoData from '@/components/common/nodata.vue';
import server from "@/service/server";
import common from "@/utils/common";
import axiosTool from '@/utils/axios_tool';

export default {
    name : "news",
    components: {
        layout_20230210_47e9,
        layout_20230210_5f79,
        Fire,
        Icon,
        NoData
    },
    data() {
        return {
            typeid:1,
            axiosTool : {},
            tab_active: 1,
            hot_focus:[
                {
                    type:'象新力杯竞赛',
                    bac_image:require('@/assets/images/xxl_gw/xxljsb.png'),
                    link:"xxxxxhttpxx:///////////////////////////"
                },
                {
                    type:'象课堂',
                    bac_image:require('@/assets/images/xxl_gw/xkt.png'),
                    link:"xxxxxhttpxx:///////////////////////////"
                },
                {
                    type:'在线虚拟仿真平台',
                    bac_image:require('@/assets/images/xxl_gw/zxxnfzpt.png'),
                    link:"xxxxxhttpxx:///////////////////////////"
                },
            ],
            menuData: [],
            menuContent:[],
        }
    },
    watch:{
        'tab_active':'changeTab'
    },
    created(){
        this.axiosTool= new axiosTool(this,[
            {key:"coverage_block", type:'get',title:'获取新闻板块类型',url:server.coverage,params:{
                getreq:{get_newstype:true}
            }},
            {key:'coverage_imformation',type:'get',title:'新闻中心信息获取',url:server.coverage,params:{
                getreq:{relevance:'index',page:1,per_page:6,typeid:1,newstypeid:1}
            }},
            {key:'hotspots',type:'get',title:'获取热点信息',url:server.hotspots},
        ])
    },
    mounted(){
        this.axiosTool._axios().then(data=>{
            const [coverage_block,coverage_imformation,hotspots] = data;
            this.coverage_block(coverage_block);
            this.coverage_imformation(coverage_imformation);
            this.get_hotspots(hotspots);

        })
    },
    methods: {
        toMore(){
            common.removeParams('/news');
            // common.setStorParams(this,{autoid: data.key});
            this.$router.push('/news');
        },
        to_details (data){
            common.removeParams('/GwNoticeText');
            // common.setStorParams(this,{autoid: data.key});
            this.$router.push('/GwNoticeText'+'?id=' + data.autoid);
        },
        JumpTo(data){
            if(!!data){
                window.open(data);
            }
        },
        handleClick(data) {
            // console.log(data)
        },
        get_hotspots(res){
            if(res.status && res.data&& res.data.result){
               this.hot_focus  = res.data.result;
               this.hot_focus.map(item=>{
                //    item.imgpath = require('/@'+item.imgpath)
               })
               console.log(this.hot_focus)
            }
            
        },
        changeTab(key){
            if(!!key){
                this.axiosTool.coverage_imformation({},request=>{
                    const {getreq} = request;
                    getreq.newstypeid =Number(key);
                    return {...request}
                }).then(res=>{
                    this.coverage_imformation(res)
                })
            }
        },
        coverage_block(res){   //  获取新闻中心栏目类型
           if(res.status && res.data){
              this.menuData = res.data;
              this.menuData.map(item=>{
                  switch (item.key){
                      case 1:
                        item.icon='FolderOpened'
                      break;
                      case 2:
                        item.icon='Fire'
                      break;
                      case 3:
                        item.icon = 'OfficeBuilding'
                      break;
                  }
              })
           }
        },
        coverage_imformation(res){
            if(res.status && res.data&& res.data.result){
                this.menuContent = res.data.result;
                
            }
        }
    },
}
</script>


<style scoped lang="less">
@import url('./common.less');
.tabs-theme-yb;
:deep(.left-head){
    position: relative;
    //background: url(/images/home/center_line.png) no-repeat ;
    // border-bottom: 1px solid #E4E4E4;
}

:deep(.left-head)::before{
    position: absolute;
    bottom: 0px;
    left: 0;
    content: '';
    height: 1px;
    width: 100%;
    background-color: #E4E4E4;

}

.center-linee{
    text-align: center;
    margin: 0 auto;
    width: 100%;
    position: absolute
}
.center-line {
    position: absolute;
    // width: 100%;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
}
:deep(.el-tabs__item){
    font-size: 20px;
}
.new-tabs{

}

.press-center {
    position: relative;
    display: flex;
    // align-items: center;
    .el-tabs{
        display: block;
        width:100%;
    }
    .press-icon {
        position: relative;
        top: 2px;
        
    }

    .press-more {
        position: absolute;
        right:0;
        top:0;
        flex: 0 0 auto;
        margin-left: auto;
        color: #8A8A8A;
        display: flex;
        // align-items: center;
        cursor: pointer;
        height:40px;
        align-items: center;
        font-size: 20px;
    }

    .press-more:hover {
        color: #F18D00;
        text-decoration: underline;
    }
}

.press-title {
    color: #353535;
    font-weight: 18px;
    font-weight: bold;
    font-size: 20px;
}

.press-card {
    width: 260px;
    margin-top: 20px;

    .card-item {
        width: 100%;
        // height: 103.7px;
        // background-color: #F18D00;
        margin-bottom: 20px;
        cursor: pointer;
        >a >img{
            width: -webkit-fill-available;
            display: inline-block;
            // height: 90px;
    }
        }
    }


.list-content{
    position: relative;
    font-size: 18px;
}
.list-content::before{
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #8A8A8A;
    content: '';
}
.press-list {
    margin-top:20px;
    .list-item {
        display: flex;
        align-items: center;
        color: #8A8A8A;
        font-size: 20px;
        margin-bottom: 20px;
        cursor: pointer !important;
       
    }
    .list-item:hover{
        text-decoration: underline;
    }
}


.list-item::before {
    content: '';
    width: 8px;
    height: 8px;
    background-color: #8A8A8A;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.news_content{
    min-height: 297px;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    justify-content: start;
    padding-top: 20px;
    color: #8A8A8A;
    font-size: 18px;
    padding-left:10px;
    .news_content_detail{
        margin-bottom: 32.5px;
    }
}
</style>