<template>
    <div class='test-ui'>
        <Evaluate @requestBefore='requestBefore' :httpInfo='httpInfo' :data='data' :option='option' />
    </div>
</template>

<script>
import Evaluate from './index.vue';
export default {
    name: 'evaluate_test',

    data() {
        return {
            data : [],
            option : ['收藏','评论','回复','删除',['回复','删除']],
            httpInfo : {},
        };
    },

    mounted() {
        let source = [
            {
                key:'001',
                userIcon : '',
                realname:'',
                rote:5,
                content:'',
                createtime:'',
                media:[],
                collect_count : '',//收藏
                comment_count : '',//评论
                children : [
                    {
                        key:'002',
                        userIcon : '',
                        realname:'',
                        rote:5,
                        content:'',
                        createtime:'',
                        media:[],
                        collect_count : '',//收藏
                        comment_count : '',//评论
                    },
                    {
                        key:'003',
                        userIcon : '',
                        realname:'',
                        rote:5,
                        content:'',
                        createtime:'',
                        media:[],
                        collect : '',//收藏
                        comment : '',//评论
                    }
                ],
            },{
                key:'004',
                userIcon : '',
                realname:'',
                rote:5,
                content:'',
                createtime:'',
                media:[],
                collect_count : '',//收藏
                comment_count : '',//评论
                children : [
                    {
                        key:'005',
                        userIcon : '',
                        realname:'',
                        rote:5,
                        content:'',
                        createtime:'',
                        media:[],
                        collect_count : '',//收藏
                        comment_count : '',//评论
                        children:[
                            {
                                key:'007',
                                userIcon : '',
                                realname:'',
                                rote:5,
                                content:'',
                                createtime:'',
                                media:[],
                                collect_count : '',//收藏
                                comment_count : '',//评论
                            },
                            {
                                key:'008',
                                userIcon : '',
                                realname:'',
                                rote:5,
                                content:'',
                                createtime:'',
                                media:[],
                                collect_count : '',//收藏
                                comment_count : '',//评论
                            }
                        ],
                    },
                    {
                        key:'006',
                        userIcon : '',
                        realname:'',
                        rote:5,
                        content:'',
                        createtime:'',
                        media:[],
                        collect_count : '',//收藏
                        comment_count : '',//评论
                    }
                ],
            }
        ];
        this.data = source;
    },

    methods: {
        requestBefore(data){//请求之前的回调
            const {callback} = data.data;
            if(callback)callback(data);
        },
    },
    components : {
        Evaluate,
    },
};
</script>

<style lang="less" scoped>
    .test-ui{
        width:800px;
        margin:auto;
    }
</style>