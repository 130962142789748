<template>
    <div class="head-ui">

        <Layout>
            <template #logo>
                <img :src="`${base_url}images/home/logo.png`" alt="xxl_logo">
            </template>
            <template #login>
                <el-input v-model="search" class="head-search" size="small" placeholder="">
                    <template #suffix>
                        <el-icon class="head-search-icon">
                            <Search />
                        </el-icon>
                    </template>
                </el-input>
                <div class="login-btns">
                    <!-- 登录状态时，显示的内容 -->
                    <template v-if="isLogin">
                        <el-dropdown @command="handleCommand">
                            <span class="header-user-action" tabindex="-1">
                                <el-avatar :key='userInfo.face'>
                                    <img :src="userInfo.face" alt="logo">
                                </el-avatar>
                                <span class="user-name" style="color: white;">你好，{{ userInfo.realname }}</span>
                            </span>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item :command="action.command" v-for="action in userActions"
                                        :icon="action.icon" :divided="action.divided" :disabled="action.disabled"
                                        :key="action.key">
                                        {{ action.label }}
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </template>
                    <!-- 未登录状态时，显示的内容 -->
                    <template v-else>
                        <span class="login" @click='toLogin("登录")'>登录</span>
                        <span class="register" @click='toLogin("注册")'>注册</span>
                    </template>

                </div>
            </template>

            <template #menu>
                <!-- <el-tabs v-model="active" @tab-click="handleClick">
                    <el-tab-pane v-for="menu in menuData" :key="menu.key" :name="menu.name">
                        <template #label></template>
</el-tab-pane>
</el-tabs> -->
                <Menus :data="menuData"></Menus>
            </template>
            <template #contact>
                <span class="free-trial" @click="click('申请试用')">免费试用</span>
                <img class="phone" :src="`${base_url}images/home/phone.png`" alt="">
            </template>
        </Layout>

        <MessageBoxs :title="popup.title" :state="popup.state" @close="messageClose" :style='popup.style'>
            <div class="main-form">
                <el-form label-position="top" @validate="validate_change" ref="ruleFormRef" :model="formData"
                    :rules="rules" label-width="120px" class="demo-ruleForm" size="default" status-icon>
                    <el-form-item label="姓名" prop="realname">
                        <el-input class="main-input" key="realname" placeholder="请输入" v-model="formData.realname" />
                    </el-form-item>
                    <el-form-item label="手机号" prop="phone">
                        <el-input class="main-input" key="phone" placeholder="请输入" v-model="formData.phone" />
                    </el-form-item>
                    <el-form-item label="邮箱地址" prop="emil">
                        <el-input class="main-input" key="emil" placeholder="请输入" v-model="formData.emil" />
                    </el-form-item>
                    <el-form-item label="单位名称" prop="unit">
                        <el-input class="main-input" key="unit" placeholder="请输入" v-model="formData.unit" />
                    </el-form-item>
                    <!-- <el-form-item label="软件名称" prop="software">
                        <el-input class="main-input" key="software" placeholder="请输入" v-model="formData.software" />
                    </el-form-item> -->

                    <el-form-item label="需求描述" prop="software">
                        <WangEditor ref="wangEditor" theme='dark' class="wang-editor" />
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <!-- :disabled="!isSubmit.state" -->
                <Buttons type="primary" @click="click('提交获客信息')">提交</Buttons>
                <Buttons type="info" plain @click="messageClose">取消</Buttons>
            </template>
        </MessageBoxs>
    </div>
</template>

<script>
import Layout from '@/components/layouts/layout_20230209_b19b.vue';
import Menus from '@/components/common/Menu/index.vue';
import axios from '@/utils/axios';
import checker from '@/utils/checker';
import server from '@/service/server';
import MessageBoxs from "@/components/course/MessageBoxs.vue";
import Buttons from "@/components/course/Buttons.vue";
import axiosTool from '@/utils/axios_tool';
import WangEditor from '@/components/course/wangEditor/index.vue'
import { ElMessage } from "element-plus";

import { mapActions } from "vuex";
const USER_ACTIONS = [
    // {key : 'pcenter',label:'个人中心',icon:'',command : 'pcenter'},
    { key: 'quit', label: '退出登录', icon: 'SwitchButton', divided: true, command: 'quit' },
]

const MENU_DATA = [
    { path: '/home1', name: '首页', label: '首页', },
    { path: '/news', name: '新闻中心', label: '新闻中心', },
    { path: '/solution', label: '解决方案', name: '解决方案', },
    { path: '/solution_goods', label: '产品中心', name: '产品中心', },
    { path: 'http://www.s6.moocmooe.com/tqduzs', label: '象课堂', name: '象课堂', },
    { path: 'http://www.s6_1.moocmooe.com:81/xs/#/Photowall', label: '大赛风采', name: '大赛风采', },
    { path: 'http://www.s6.moocmooe.com/tqduzs', label: '在线仿真平台', name: '在线仿真平台', },
    { path: '/download', label: '资源下载', name: '资源下载', },
    { path: '/aboutus', label: '关于我们', name: '关于我们', },
    { path: 'http://www.s6_2.moocmooe.com/ahdqgc/docs/', label: '对接文档', name: '对接文档',sort:9 }
]
export default {
    name: "heads",
    watch: {
        '$store.state.user.userInfo': 'monitorUserInfo',
        '$route.path': 'monitorPath',
    },

    data() {
        return {
            userActions: checker.cloneObj(USER_ACTIONS),
            isLogin: false,//true 已登录 false 未登录 默认未登录
            userInfo: {},//存储用户当前信息
            base_url: process.env.BASE_URL,
            search: '',
            active: '首页',
            menuData: [],
            popup: {
                title: '',
                state: false,
                style: {},
            },
            isSubmit: {},
            formData: {},
            rules: [],
        }
    },
    mounted() {
        console.log(this.$store);
    },
    created() {
        this.query_menus();
        this.rules = this.rules_data();
        this.axiosTool = new axiosTool(this, [
            {
                key: 'coverage_add', type: 'post', title: '新增获客信息', url: server.acquisition, params: {
                    template: 'formData:post_realname|post_phone|post_emil|post_unit|post_software',
                    postreq: { typeid: '4' }
                }
            },
        ])
    },
    components: {
        Layout,
        Menus,
        MessageBoxs,
        Buttons,
        WangEditor
    },
    methods: {
        monitorUserInfo(userInfo) {//监控登录状态
            if (!userInfo || !userInfo.autoid) {
                this.userInfo = {};
                this.isLogin = false;
                return;
            }
            this.isLogin = true;
            if (!userInfo.face) {
                userInfo.face = require('@/assets/images/user_logo.png');
            }
            this.userInfo = userInfo;
            this.handleActions();
        },
        query_menus() {
            axios.get(server.programa + '?parentid=0').then(data => {
                let result = [];
                if (data.status && data.data && data.data.result) {
                    result = data.data.result;
                    result = result.map(item => {
                        let idx = MENU_DATA.findIndex(o => o.name === item.name);
                        let cache = MENU_DATA[idx];
                        item.label = item.name;
                        return {
                            ...item,
                            ...cache,
                        }
                    })
                }
                result.push({ key: result.length,parentid: 0,path:'http://www.s6_2.moocmooe.com/ahdqgc/docs/', name: '对接文档', label : '对接文档',})
                this.menuData = result;
            })
        },
        handleCommand(command) {
            const { userInfo } = this;
            let query;
            switch (command) {
                case 'pcenter': //个人中心
                    this.$router.push('/my_center');
                    break;
                case 'quit': //退出登录
                    this.$confirm('即将退出登录，是否继续？', '消息提醒', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.deleteToken();
                    }).catch(() => { });
                    break;
                // default : //管理员工作台 || 教师工作台
                //     window.open(this.ADMINGLOBALPATH + '/admin/');
                // break;
            }
        },
        rules_data() {
            return {
                realname: [
                    { required: true, message: '姓名不能为空哦！', trigger: 'blur', },
                ],
                phone: [
                    { required: true, validator: this.verify_phone, message: '手机号格式不正确！', trigger: 'blur', },
                ],
                emil: [
                    { required: true, validator: this.verify_email, message: '邮箱格式不正确！', trigger: 'blur', },
                ],
                unit: [
                    { required: true, message: '单位名称不能为空哦！', trigger: 'blur', },
                ],
            }
        },
        validate_change(key, isValid, message) {
            this.isSubmit[key] = isValid;
            const v = ['realname', 'phone'];
            this.isSubmit.state = true;
            for (const _v of v) {
                if (!this.isSubmit[_v]) {
                    this.isSubmit.state = false;
                }
            }
        },
        verify_phone(rule, value, callback) {
            let verify = checker.phone(value);
            if (!verify[0]) {
                callback(new Error(rule.message))
            } else {
                callback();
            }
        },
        verify_email(rule, value, callback) {
            // 调用 checker 中的邮箱校验方法
            let verify = checker.email(value);
            if (!verify[0]) {
                callback(new Error(rule.message)); // 校验失败返回错误信息
            } else {
                callback(); // 校验通过
            }
        },
        click(type) {
            switch (type) {
                case '申请试用':
                    this.popup = {
                        title: '填写信息',
                        state: true,
                        style: {},
                    }
                    break;
                case '提交获客信息':

                    this.submit();
                    break;
            }
        },
        submit() {
            const { formData, $refs: refs } = this;
            if (formData.emil && !checker.email(formData.emil)[0]) {
                ElMessage.warning("邮箱格式不正确！");
                return;
            }


            this.axiosTool.coverage_add({}, req => {
                const { postreq } = req;
                if (refs.wangEditor) {
                    postreq['software'] = refs.wangEditor.getHTML()
                }
                return { ...req, postreq }
            }).then(data => {
                if (data.status) {
                    ElMessage.success("申请信息已提交，稍后我们的工作人员将与您联系！");
                    this.messageClose();
                    this.formData = {};
                }
            })
        },
        messageClose() {
            this.popup = {};
        },
        deleteToken() {
            axios.post(server.token, { delete_data: true }).then(result => {
                if (result.status) {
                    localStorage.clear();
                    sessionStorage.clear();
                    this.$router.push('/home1');
                    window.location.reload();
                } else {
                    window.location.reload();
                }
            })
        },
        handleActions() {
            const { userInfo } = this;
            let userActions = checker.cloneObj(USER_ACTIONS), idx = 0;
            // switch (userInfo.role){
            //     case '1' : //管理员
            //         if(detection('admin'))return;
            //         userActions.unshift({key : 'admin',label:'管理员工作台',icon:'',command : 'admin'},)
            //         break;
            //     case '3' : //教师
            //         if(detection('teacher'))return;
            //         userActions.unshift({key : 'teacher',label:'教师工作台',icon:'',command : 'teacher'},)
            //         break;
            // }
            this.userActions = userActions;
            function detection(username) {//检测是否存在相同的菜单
                let idx = userActions.findIndex(item => item.key === username);
                return idx >= 0;
            }
        },
        toLogin(type) {
            if (type == '登录') {
                this.setLoginsign('登录');
                this.$router.push('/login');
            } else if (type == '注册') {
                this.setLoginsign('注册');
                this.$router.push('/login');
            }
        },
        handleClick() {

        },
        ...mapActions(["setLoginsign"]),
    },
}
</script>

<script setup>
import { Search } from '@element-plus/icons-vue';
</script>

<style scoped lang="less">
@import url('./common.less');
@import url('@/assets/less/theme.less');

.main-input :deep(.el-input__suffix) {
    display: flex;
    align-items: center;
}

.tabs-theme-yw;

.header-user-action {
    display: flex;
    align-items: center;
}

.wang-editor {
    height: 200px;
}

.user-name {
    margin-left: 10px;
}

:deep(.el-tooltip__trigger:focus-visible) {
    outline: unset;
}

.header-user-action:focus {
    border-color: transparent;
}

:deep(.head-search .el-input__suffix) {
    display: flex;
}

:deep(.head-search .el-input__inner) {

    background-color: rgba(255, 255, 255, .2);
}

:deep(.head-search .el-input__inner):focus {
    border-color: #F18D00;
}

:deep(.head-search .el-input__suffix .el-input__suffix-inner) {
    display: flex;
    align-items: center;
}


:deep(.el-menu-item-cctv) {
    padding: 0 28px;
}

.free-trial {
    display: inline-block;
    padding: 5px 10px;
    background-color: #F18D00;
    color: #FFFFFF;
    font-size: 20px;
    margin-right: 10px;
    cursor: pointer;
    .not-select;
    border-radius: 4px;
}

.phone {
    height: 24px;
}

.login-btns {

    font-size: 14px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-left: 20px;
    flex: 0 0 auto;

    .login {
        display: flex;
        align-items: center;
        flex: 0 0 auto;
        cursor: pointer;
        .not-select;
        font-size: 20px;
    }

    .login::after {
        content: '';
        display: block;
        height: 15px;
        width: 2px;
        background-color: #FFFFFF;
        margin: 0 5px;
        position: relative;
        top: 1px;

    }

    .register {
        flex: 0 0 auto;
        cursor: pointer;
        .not-select;
        font-size: 20px;
    }
}

.head-search-icon {
    padding-right: 10px;
}



.head-ui {
    position: relative;
    z-index: 1;

}
</style>