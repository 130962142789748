<template>
    <div class="table-ui">
        <div class="table-head">
            <slot name="head"></slot>
        </div>
        <div :class="['table-content',isPage ? '' : 'no-page']">
            <el-table
            fit
            row-key='autoid' 
            ref="multipleTable" 
            @select='select' 
            @select-all='selectAll'  
            @selection-change="selectChange" 
            :data="tableData" 
            v-loading="loading"
            style="width: 100%" 
            :max-height="maxHeight"
            :row-class-name="tableRowClassName">
                <!-- <el-table-column v-if="params.checked" :selectable='checkSelectable' type="selection" :disabled='true' :width="params.checkdWidth || 55"> </el-table-column> -->
                 <el-table-column v-if="params.checked" :selectable='checkSelectable' type="selection"  :reserve-selection="true" :disabled='false' :width="params.checkdWidth || 55"> </el-table-column>
                <!-- :reserve-selection="true" （分页中）用于保存上一页checkboxs的选中状态 -->
                <template v-for="item in headerData" :key="item.id">
                    <!-- 正常表格头 -->
                    <el-table-column :show-overflow-tooltip='true' v-if="!item.options" :data-key='item.key' :align='item.align' :prop="item.key"  :width='item.style ? item.style.width : ""' :label="item.label"> 
                        <template #default="{row}">
                            <slot :name='item.key' :item='{row,column : item}'>{{row[item.key]}}</slot>
                        </template>          
                    </el-table-column>
                    <!-- 操作表格头 -->
                    <el-table-column :show-overflow-tooltip='true' align='center' v-if="!!item.options" :prop="item.key"  :width='item.style ? item.style.width : ""' :label="item.label || '操作'"> 
                        <template #default="scope">
                            <div class="table-option"  :data-id="tableData[scope.$index] && tableData[scope.$index].autoid || ''">
                                <template v-for="option in optionData[scope.$index + '_' + item.key]" :key="option.key">
                                    <Buttons @click="handleClick(option,tableData[scope.$index])" :class="['table-buttons',option.sortable?'sortable-move' : '']" :icon='option.icon' :title='option.title' v-if="!!option && !!option.button">{{option.label}}</Buttons>
                                    <i @click="handleClick(option,tableData[scope.$index])" :title='option.title' v-if="!option.button && option.icon && option.type==='xxl'" :style='option.style' :class="[option.icon,'table-options']"></i>
                                    <span :class="[option.sortable?'sortable-move' : '']" :title='option.title' v-if="!option.button && option.icon && option.type==='element'" >
                                        <component @click="handleClick(option,tableData[scope.$index])" :is="$icon[option.icon]" 
                                            :style="{width:'20px',height:'21px',marginRight:'5px',...option.style}" :class="[option.icon,'table-options']">
                                        </component>
                                    </span>    
                                    <span :class="['default-btn',option.sortable?'sortable-move' : '']" :style='option.style || {}' @click="handleClick(option,tableData[scope.$index])" v-if="!option.icon && !option.button">{{option.label}}</span>
                                </template>
                            </div>
                        </template>
                    </el-table-column>
                </template>
                
            </el-table>
        </div>
        <div class="table-page" v-if="isPage">
            <Pagination 
            :total='params.total || 0' 
            :pageSize='params.pageSize || 10' 
            :currentPage='params.currentPage || 1'
            :hideOnSinglePage='false'
            @pageChange='pageChange'
            />
        </div>
    </div>
</template>

<script>

import Pagination from './Pagination.vue';
import Buttons from './Buttons.vue';
import { toRef } from 'vue'
import common from '@/utils/common';
export default {
    data(){
        return {
            searchVal : '',
            headerData : [],
            optionData : {},
        }
    },
    beforeMount(){
        this.headerData = this.columnData;
        this.selectionData = this.selectedKeys;
    },
    mounted (){
        this.tableDataMethod();
        this.columnMethod();
        this.$nextTick(()=>{
            if(this.sortable)this.dragSort();
            if(!this.params.checkedAll && this.params.checked){
                let checkAll = document.querySelector('.el-table th>.cell');
                if(checkAll){
                    checkAll.style.width = '56px'
                    checkAll.innerHTML = '选择'
                }
            }
        });
    },
    watch : {
        'selectedKeys' : 'selectedKeysMethod',
        'columnData' : 'columnMethod',
        'tableData' : 'tableDataMethod',
    },
    components : {
        Pagination,
        Buttons,
    },
    methods : {
        tableDataMethod (cacheData=this.tableData){
            const {headerData,tableData} = this;
            let optionData = {};
            if(cacheData.length && headerData.length){
                cacheData.map((item,idx)=>{
                    headerData.map(head=>{
                        if(typeof head.options === 'function'){
                            let array = head.options(item);
                            optionData[idx+'_'+head.key] = array || [];
                        }
                    }) 
                })
            }
            this.optionData = optionData;
        },
        selectedKeysMethod (keys){
            this.$nextTick(()=>{
                const {tableData} = this;
                if(Array.isArray(keys) && this.$refs.multipleTable){
                    keys.forEach(item=>{//tableData
                        let idx = tableData.findIndex(items=>items.autoid === item.autoid);
                        if(idx>=0)this.$refs.multipleTable.toggleRowSelection(tableData[idx],true);
                        
                    });
                }
            });
        },
        
        columnMethod(columnData=this.columnData){
            this.headerData = columnData;
        },
        tableRowClassName({rowIndex}) {
            if (rowIndex % 2 === 0) {return 'prefix-row';}
            else {return 'suffix-row';}
        },
        handleClick (data,item){
            if(data.on_click){data.on_click(item)}
        },
        selectChange : function(selection){
            if(!this.params.checkedAll && this.params.checked){//单选
                if (selection.length > 1) {
                    this.$refs.multipleTable.clearSelection();
                    let pop = selection.pop();
                    this.$refs.multipleTable.toggleRowSelection(pop);
                }
            }
        },
        select (data){
           if(this.params.selectChange && this.params.checkedAll)this.params.selectChange(data);
           else if(this.params.selectChange && !this.params.checkedAll)this.params.selectChange([data[data.length-1]]);
        },
        selectAll (data){
            if(this.params.selectChange)this.params.selectChange(data);
        },
        pageChange (currentPage){
            if(this.params.pageChange)this.params.pageChange(currentPage);
        },
        checkSelectable (row,idx){
            return !row.disabled;
        },
        dragSort (){
            
            const el = this.$refs.multipleTable.$el.querySelectorAll('table.el-table__body> tbody')[0],
            sortable = this._sortable.create(el, {
                onEnd: evt => {
                    const {params,tableData} = this;
                    let elems = evt.from.children,
                    oldid = elems[evt.oldIndex].querySelector('td.el-table__cell>.cell>.table-option').getAttribute('data-id'),
                    newid = elems[evt.newIndex].querySelector('td.el-table__cell>.cell>.table-option').getAttribute('data-id'),
                    arr = ['prefix-row','suffix-row'];
                    for( let i = 0 ; i < elems.length ; i++ ){
                        let elem = elems[i];
                        elem.className = resetClass(elem.className,i%2);
                    }
                    function resetClass (calssName,idx){
                        let names = calssName.split(' '),
                        delIdx = names.findIndex(item=>arr.indexOf(item.trim())>=0);
                        if(delIdx>=0)names.splice(delIdx,1);
                        names.push(arr[idx]);
                        return names.join(' ');
                    }
                    if(params.sortableChange){
                        let oldData = tableData.findIndex(item=>item.autoid == oldid),
                        newData = tableData.findIndex(item=>item.autoid == newid);
                        params.sortableChange({oldData:tableData[oldData],newData:tableData[newData]})
                    }
                },
                draggable : '.el-table__row',
                handle:'.sortable-move',
                direction : 'vertical',
                animation: 150,
            })
        },
        unmounted (){
            
        }
    },
    props : {
        tableData : {
            type : Array,
            default : ()=>{return []}
        },
        columnData : {
            type : Array,
            default : ()=>{return []}
        },
        selectedKeys : {
            type : Array,
            default : ()=>{return []}
        },
        params : {
            type : Object,
            default : ()=>{return {}},
        },
        isPage : {
            type : Boolean,
            default : true,
        },
        maxHeight : {
            type : [String,Number],
            default : 'none',
        },
        loading : {
            type : Boolean,
            default : false,
        },
        sortable : {//是否支持拖动排序
            type : Boolean,
            default : false,
        },
    }
}
</script>
<style>
    .table-options{
        font-size: 20px;
        margin-right:10px;
        cursor: pointer;
    }
    .table-options:hover{
        color:rgb(241,140,0,1);
    }
    .table-options:last-child{
        margin-right:0; 
    }
    .el-table .prefix-row {
        background-color: #FFFFFF;
    }

    .el-table .suffix-row {
        background-color: #F5F5F5;
    }
    .has-gutter tr th{
        background-color: #F5F5F5;
        padding: 5px 0;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner{
        background-color:rgb(241,140,0,1);
        border:1px solid rgb(241,140,0,1);
    }
    .el-checkbox__inner:hover{
        border:1px solid rgb(241,140,0,1);
    }
    .el-checkbox__inner:active{
        border:1px solid rgb(241,140,0,1);
    }
     .el-checkbox__inner:focus{
        border:1px solid rgb(241,140,0,1);
    }

    .el-checkbox__input.is-indeterminate .el-checkbox__inner{
        background-color:rgb(241,140,0,1);
        border:1px solid rgb(241,140,0,1);
    }
    .el-checkbox__input.is-focus .el-checkbox__inner {
        border:1px solid rgb(241,140,0,1);
    }
    .el-table__body{
        width:100% !important;
    }
    .el-table__header {
        width:100% !important;
    }
    .el-table__header>colgroup>col:last-child{
        display: none;
    }

</style>
<style scoped lang='less'>
    .default-btn{
        color:#2974EA;
        cursor: pointer;
    }
    /deep/ .el-table__header-wrapper table thead tr th{
        background-color: #F5F5F5;
    }

    .table-ui{
        width:100%;
        height:100%;
        display: flex;
        flex:0 0 auto;
        flex-direction: column;
    }
    .table-ui .table-head{
        width:100%;
        flex:0 0 auto;
        display: flex;
        align-items: center;
    }

    .table-ui .table-head .table-head-left{
        float:left;
    }

    .table-ui .table-head .table-head-right{
        float:right;
    }

    .table-ui .table-content{
        width:100%;
        flex:1 1 auto;
        padding: 10px 0 20px; 
    }
    .table-option{
        width:100%;
        display: flex;
        justify-content: space-around;
    }
    .table-ui .table-content.no-page{
        padding:0;
    }
    .table-ui  .table-page{
        width:100%;
        flex:0 0 auto;
        padding: 10px 0;
        text-align:center;
    }

</style>