<template>
    <div class="tabs-ui">
        <div>
            <div class="tabs-items">
                <div :class="['tabs-item',pitchActive === item.key ? 'active' : '']" :ref="`tabs_${item.key}`" :data-key="item.key" @click="handleClick(item)" v-for="item in data" :key="item.key">
                    {{item.title}}{{"value" in item ? "[" + (item.value || 0) + "]" : ""}}
                </div>
                <span ref="slider" :style="{left:picthItemStyle.left,width:picthItemStyle.width}" class="tabs-slider"></span>
                <div class="tabs-bar"><slot name="bar"></slot></div>
            </div>
            <div class="tabs-content">
                <slot :pitch="pitch"></slot>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data (){
        return {
            data : [],
            pitchActive : '',
            pitch : {},
            picthItemStyle: {
                width: 0,
                left: 0,
            },
            left : {},
            widths : {},
        }
    },
    mounted (){
        this.init();
        
    },
    watch : {
        'menuData' : 'monitorMenuData'
    },
    methods : {
        init (){
            const {menuData} = this;
            let item = menuData[0];
            if(!item)return;
            this.pitchActive = item.key;
            this.pitch = item;
            this.data = this.menuData;
            this.$emit('toClick', item);
            this.saveElemWidth();
        },
        monitorMenuData (menuData){
            this.data = menuData;
            this.init();
        },
        handleClick (data){
            this.pitchActive = data.key;
            this.pitch = data;
            this.$emit('toClick',data);
            this.$nextTick(()=>this.saveElemWidth());
        },
        saveElemWidth (){
            this.$nextTick(() => {
                const {$refs:refs} = this;
                let elem = refs[`tabs_${this.pitchActive}`][0];
                this.picthItemStyle.left = elem.offsetLeft + "px";
                this.picthItemStyle.width = elem.offsetWidth + "px";
            });
        },
    },
    
    props : {
        menuData : {
            type : Array,
            default : ()=>{return []}
        },
        active : {
            tyep : String,
            default : ()=>{
                const {menuData} = this;
                let item = menuData[0] || {};
                return item.key || '';
            },
        }
    },
}
</script>

<style scoped lang="less">
    .tabs-ui{
        padding:0 10px;
        .tabs-items{
          position: relative;
          display: flex;
          border-bottom: 1px solid #F2F2F2; 
          .tabs-slider{
            transition: left .2s ease-out,width .2s ease-out;
            position: absolute;
            bottom: 0;
            left:0;
            width:0px;
            content: '';
            height:2px;
            background-color: #1E5FC2;
          }
          .tabs-item{
            padding: 0 10px;
            color:#999999;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            height:40px;
            line-height: 40px;
            cursor: pointer;
            
            &.active {
                color:#1E5FC2;
            }
          }

          .tabs-bar{
              margin-left:auto;
              flex:0 0 auto;
              display: flex;
              align-items: center;
          }
        }
        // .tabs-content{

        // }
    }
    
</style>