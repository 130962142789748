<template>
  <div class="banner-ui" >
    <!-- <el-carousel pause-on-hover height="420px" :interval="3000" :initial-index='1' arrow="hover">
      <el-carousel-item v-for="item in bannerData" :key="item.autoid">
        <template v-if="item.link != ''">
          <a class='banner-a' target="_blank" :href="item.link"><img :src="item.imgsrc" :alt="item.title"></a>
        </template>
        <template v-else>
          <a class='banner-a' target="_blank"><img :src="item.imgsrc" :alt="item.title"></a>
        </template>
      </el-carousel-item>
    </el-carousel> -->
    <el-carousel height="420px" :initial-index='0' v-if="bannerData.length > 0" arrow="hover" pause-on-hover :interval="3000"  >
      <el-carousel-item v-for="item in bannerData" :key="item.autoid" @change="changeBanner(item)">
        <template v-if="item.link != ''">
          <a class='banner-a' target="_blank" :href="item.link"><img :src="item.imgsrc"></a>
        </template>
        <template v-else>
          <a class='banner-a' target="_blank"><img :src="item.imgsrc"></a>
        </template>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
// import common from "@/utils/common";
import axiosTool from "@/utils/axios_tool";
// import axios from "@/utils/axios";
import server from "@/service/server";
export default {
  data (){
    return {
      bannerData : [],
      axiosTool : {},
    }
  },
  created (){
    this.axiosTool = new axiosTool('get',server.banner);
  },
  mounted (){
    this.getBanner();
  },
  methods : {
    changeBanner(data){
     //console.log(data)
    },
    getBanner (){
      let bannerData = [];
      this.axiosTool.loading = true;
      this.axiosTool.banner().then(data=>{
        //console.log(data)
        this.axiosTool.loading = false;
        if(data && data.result){
          data.result.map(item=>{
            bannerData.push(item);
          })
        }
        this.bannerData = bannerData;
        this.$nextTick(()=>{
          let elem = document.querySelector('.el-carousel__item');
          if(elem)elem.style.display = 'block';
        });
      });
    },
  },
}
</script>

<style lang="less" scoped>

  .el-carousel__item a {
    // color: #475669;
    font-size: 14px;
    // opacity: 0.75;
    line-height: 150px;
    margin: 0;
    text-align: center;
  }

  .el-carousel__item:nth-child(2n) {
    // background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    // background-color: #d3dce6;
  }
  .banner-ui{
    width:1920px !important;
    height:420px;
    margin: 0 auto;
    .banner-a{
      width:100%;
      height:100%;
      >img{
        width:100%;
        height:100%;
      }
    }
  }
</style>