<template>
    <div class="news-ui">
        <template v-for="item in config.children" :key="item.key">
            <component :is="item.component"></component>
        </template>
    </div>
</template>

<script>
    import _ from '@/views/Home/config';
    import components from '@/views/Home/News/index.js';


    export default {
        data (){
            return {
                config : {},

            }
        },
        created (){
            console.log(components)
            this.config = _['news'];
        },
        components : {
            ...components,
        }
    }
</script>

<style scoped lang="less">
    .news-ui{
        // height:700px;
    }
</style>