<template>
  <!-- 应用排行 -->
  <div class="ranking-ui">
    <div class='ranking-main common-width'>
        <div class='ranking-tabs'>
          <Tabs v-model:active="active" :tabsData="options" @tabCahnge="handleTab" >
            <template #default="{ tabsItem: item }">
                <div class='ranking-title'>
                  <div class='tabs-child'>
                    <div @click="handlePitch(item)" :class="['child-item',tabActive === item.key ? 'active' : '' ]" v-for='item in tabsData' :key='item.key'>
                      <img v-if='tabActive !== item.key' :src="item.imgUrl" alt="">
                      <img v-if='tabActive === item.key' :src="item.active_img" alt="">
                      <span class='child-title'>{{item.label}}</span>
                      <span class='child-english'>{{item.enName}}</span>
                    </div>
                  </div>
                  <div class='ranking-remark'>注：排行榜每月1日0点更新</div>
                </div>
                <div class='ranking-content'>
                  <div class='ranking-item'  v-for='(item,idx) in rankData' :key='item.key'>
                    <img class='item-icon' v-if='idx===0' :src="tabimgUrl" alt="">
                    <span class='item-icon' v-else>{{idx+1}}</span>
                    <img class='item-img' @click='handleCilck(item)' :src="item.imgUrl" alt="">
                    <span class='item-title'>{{item.title}}</span>
                    <span class='item-name'>{{item.realname}}</span>
                    <div class='item-count'>
                      <span>{{item.exppv_num}}</span>
                      <span>{{exp_tittle}}</span>
                    </div>
                  </div>
                  <NoData v-if='!rankData.length'></NoData>
                </div>
            </template>
          </Tabs>
        </div>
    </div>
    
  </div>
</template>

<script>
import axios from "@/utils/axios";
import server from "@/service/server";
import checker from "@/utils/checker";
import NoData from "@/components/common/nodata.vue";
import Banber from "./Banner.vue";
import Tabs from "@/components/course/Tabs.vue";
import common from "@/utils/common";
import { ElLoading } from "element-plus";
import axiosTool from "@/utils/axios_tool";

export default {
  data() {
    return {
      active: "本月排行",
      tabActive: "好评排行",
      options: [
        { key: "本月排行", name: '本月排行', label: "本月排行", type: "本月排行" },
        { key: "总排行", name: '总排行', label: "总排行", type: "总排行" },
      ],
      tabsData: [
        { key: "好评排行",imgUrl:require('@/assets/images/pcrown2.png'),active_img:require('@/assets/images/pcrown_yellow.png'), label: "好评排行", enName: "PRAISE RANKING", },
        { key: "热门排行",imgUrl:require('@/assets/images/pcrown1.png'),active_img:require('@/assets/images/pcrown_red.png'), label: "热门排行", enName: "HOT RANKING", },
        { key: "吸引排行",imgUrl:require('@/assets/images/pcrown3.png'),active_img:require('@/assets/images/pcrown_blue.png'), label: "吸引排行", enName: "ABSORB RANKING", },
      ],
      tabimgUrl : require('@/assets/images/pcrown2.png'),
      rankData: [],
      axiosTool : {},
      exp_tittle:"",
    };
  },
  created (){
    this.axiosTool = new axiosTool(this,[
      {key:'expgrade_join',title:'查询热门排行',type:'get',url:server.expgrade,params : {getreq:{column:'join',style:'desc',publish:2}}},
      {key:'expgrade_evaluate',alone:true,title:'查询好评排行',type:'get',url:server.expgrade,params : {getreq:{column:'evaluate',style:'desc',publish:2}}},
      {key:'expgrade_star',alone:true,title:'查询吸引排行',type:'get',url:server.expgrade,params : {getreq:{column:'star',style:'desc',publish:2}}},

      {key:'expgrade_count',title:'查询排行总数',type:'get',url:server.expgrade,params : {getreq:{column:'join',count:true,style:'desc',publish:2}}},

      {key:'expgrade_join_all',alone:true,title:'查询所有热门排行',type:'get',url:server.expgrade,params : {getreq:{column:'joinall',style:'desc',publish:2}}},
      {key:'expgrade_evaluate_all',alone:true,title:'查询所有好评排行',type:'get',url:server.expgrade,params : {getreq:{column:'evaluateall',style:'desc',publish:2}}},
      {key:'expgrade_star_all',alone:true,title:'查询所有吸引排行',type:'get',url:server.expgrade,params : {getreq:{column:'starall',style:'desc',publish:2}}},

      {type:'post',url:server.exppv},
    ])
  },
  mounted() {
    this.axiosTool.loading = true;
    this.axiosTool._axios().then(data=>{
      const [joinData] = data;
      this.handleExpgrade(joinData);
      this.axiosTool.loading = false;
    })
  },
  methods: {
    handleTab (active){
      this.active = active;
      this.handlePitch();
    },

    handleGradeCount(){//查询排行总数
      this.axiosTool.loading = true;
      this.axiosTool.expgrade_count().then(data=>{
        this.axiosTool.loading = false;
      })
    },


    handleExpgrade(data){
      let rankData = [];
      if(data && Array.isArray(data.result)){
        let array = data.result;
        for(let i = 0 ; i < array.length ; i++){
          let cache = array[i],
          exp_data = cache.exp_data,faces = [];
          cache.exppv_num = cache.join || 0;
          if(exp_data){
            faces = exp_data.face.split(',');
            cache.key = exp_data.autoid;
            cache.title = exp_data.title;
            if(exp_data.master_data){
              cache.realname = exp_data.master_data.realname; 
            }else {
              cache.realname = '暂无';
            }
            faces.map(face=>{
              if(face.indexOf('jpg') != -1){
                  exp_data.face = face
              }else if(face.indexOf('png') != -1){
                  exp_data.face = face
              }else if(face.indexOf('jpeg') != -1){
                  exp_data.face = face
              }else{
                  // exp_data.face = require('@/assets/images/default_exp.jpg');
                  if(exp_data.face=='')
                            exp_data.face = require('@/assets/images/default_exp.jpg')
              }
            })
            cache.imgUrl = exp_data.face;
          }else {
            cache.key = checker.uuid(8);
            cache.title = exp_data.title;
            cache.imgUrl = require('@/assets/images/default_exp.jpg');
            cache.exppv_num = 0;
          }
          rankData.push(cache);
        }
      }
      this.rankData = rankData;
      this.handleGradeCount();
    },
    handleCilck(data) {
      common.removeParams('/exps');
      this.postExppv(data.expautoid);
      common.setStorParams({ path: "/expcore" }, { autoid: data.expautoid });
      this.$router.push("/expcore");
    },
    handlePitch(data={key:this.tabActive,imgUrl:this.tabimgUrl}) {
      this.tabActive = data.key;
      this.tabimgUrl = data.imgUrl;
      this.queryExpgrade();
    },
    queryExpgrade(){
      const {active,tabActive} = this;
      let type = `${active}_${tabActive}`;
      switch (type) {
        case "本月排行_好评排行":
          this.axiosTool.loading = true;
          this.axiosTool.expgrade_join().then(data=>{
            this.handleExpgrade(data);
            this.axiosTool.loading = false;
            this.exp_tittle = "好评人次"
          })
          break;
        case "本月排行_热门排行":
          this.axiosTool.loading = true;
          this.axiosTool.expgrade_evaluate().then(data=>{
            this.handleExpgrade(data);
            this.axiosTool.loading = false;
            this.exp_tittle = "实验人次"
          })
          break;
        case "本月排行_吸引排行":
          this.axiosTool.loading = true;
          this.axiosTool.expgrade_star().then(data=>{
            this.handleExpgrade(data);
            this.axiosTool.loading = false;
            this.exp_tittle = "点赞人次"
          })
          break;
        case '总排行_好评排行' : 
          this.axiosTool.loading = true;
          this.axiosTool.expgrade_join_all().then(data=>{
            this.handleExpgrade(data);
            this.axiosTool.loading = false;
            this.exp_tittle = "好评人次"
          })
          break;
        case '总排行_热门排行' : 
          this.axiosTool.loading = true;
          this.axiosTool.expgrade_evaluate_all().then(data=>{
            this.handleExpgrade(data);
            this.axiosTool.loading = false;
            this.exp_tittle = "实验人次"
          })
          break;
        case '总排行_吸引排行' : 
          this.axiosTool.loading = true;
          this.axiosTool.expgrade_star_all().then(data=>{
            this.handleExpgrade(data);
            this.axiosTool.loading = false;
            this.exp_tittle = "点赞人次"
          })
          break;
      }
    },
    postExppv(autoid){//浏览量
      this.axiosTool.loading = true;
      this.axiosTool.exppv({},data=>{
        const {postreq} = data;
        postreq.expautoid = autoid
        return {...data,postreq}
      }).then(result=>{
        this.axiosTool.loading = false;
      })
    },
  },
  components: {
    Banber,
    NoData,
    Tabs,
  },
};
</script>

<style scoped lang="less">
@import url("@/styles/common.less"); //公共样式
.media; //媒体查询
.no-data{
    margin: auto;
    .no-data-img{
      background-image: url('~@/assets/images/Empty.jpg');
      background-size: 1595px 3004px;
      background-position: -1047px -204px;
      width: 242px;
      height: 228px;
      display: inline-block;
    }
  }





.ranking-ui{
  width:100%;
  .ranking-main{
    margin:auto;
  }
}

.ranking-tabs{
  padding-top: 10px;
  .ranking-title{
    position:relative;
    border-bottom:1px solid #F2F2F2;
    padding-top: 10px;
    .tabs-child{
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .child-item{
        height: 73px;
        width:170px;
        display: flex;
        align-items: center;
        cursor:pointer;
        .child-title{
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
        >img{
          position: relative;
          top: 3px;
        }
        .child-english{
          display:none;
        }
      }
      .child-item.active{
        flex-direction: column;
        position:relative;
        &::after{
          content:'';
          width:100%;
          display:inline-block;
          height:3px;
          background-color:#1E5FC2;
        }
        .child-title{
          position: relative;
          z-index: 1;
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          margin-top: 10px;
        }
        > img{
          position:absolute;
          width:60px;
          height:56px;
          top: 0;
        }
        .child-english{
          display: inline-block;
          position: relative;
          z-index: 1;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #B6B6B6;
          padding: 10px 0 5px;
        }
      }
    }
    .ranking-remark{
      position:absolute;
      right:0;bottom:10px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #B6B6B6;
    }
  }
  .ranking-content{
    width:100%;
    padding:10px 0;
    .ranking-item{
      display: flex;
      align-items: center;
      padding: 10px 0;
      
      .item-icon{
        width: 40px;
        height: 37px;
        margin: 0 20px;
        text-align: center;
        line-height: 37px;
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      .item-img{
        width: 211px;
        height: 125px;
        border-radius: 4px;
        margin-right: 20px;
        cursor:pointer;

      }

      .item-title{
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #9195A3;
        flex:1 1 auto;
        text-align:left;
        max-width: 483px;
        text-align: justify;
        word-break: break-all;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .item-name{
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #9195A3;
        text-align: center;
        flex:0 0 200px;
      }
      .item-count{
        display: flex;
        flex-direction: column;
        align-items: center;
        >span:first-child{
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #9195A3;
          margin-bottom:10px;
        }
        >span:last-child{
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #D2D7E6;
        }
      }
    }
  }
}
</style>