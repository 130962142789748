import checker from './checker';
import common from "@/utils/common";
import { ElLoading ,ElMessage} from "element-plus";
import axios from './axios';
import _ from 'lodash';
// 批量处理接口请求
// 根据接口地址映射单个接口的函数 可以单独调用
// 返回数据顺序与设置接口顺序相同

// 接口错误/异常 返回信息 提示问题

// 与后端规定返回的基本数据结构

// 嵌套接口请求 


// 指定 vue 实例为 this 对象
// 接口参数通过字段来配置【配置单个属性 或者对象{}】 字段通过条件过滤 每次执行时 都会实时获取vue实例中的状态值
// 抛出接口函数时 同时附带git post方法
// 
// 
// 对象 单个
// 支持加载进度条


class axiosTool {
    #promise_arr = [];//存储所有请求的 promise
    state = null; //
    loading = false;
    #httpInfos = null; 
    #loading_option = {
        lock: false,
        background: "rgba(255, 255, 255, 0.7)",
        'custom-class' : 'custom-loading',
    }
    _post = {};
    #loading_tool = null;
    timeout = 0;
    constructor (_this,arg){
        this._this = _this;//存储vue实例
        this.children = {};
        let httpInfos;
        if(['Array','Object'].indexOf(common.getClass(arg)) <0 ){
            httpInfos = arguments;
        }else {
            httpInfos = arg || _this;
        }
        this.#httpInfos = httpInfos;
        this.paramSwitch();
        this.monitorLoading();
    }

    monitorLoading (){//监听加载组件状态
        let _this = this;
        Object.defineProperty(this, 'loading', {
            set : function (value){
                if(value){
                    _this.#loading_tool = ElLoading.service(_this.#loading_option);
                    setTimeout(()=>{
                        if(_this.#loading_tool){
                            _this.#loading_tool.close();
                            _this.#loading_tool = null;
                        }
                    },3000);
                }else if(_this.#loading_tool){
                    _this.#loading_tool.close();
                    _this.#loading_tool = null;
                }
            },
        })
    }
    
    paramSwitch (){//参数分发
        let org = this.#httpInfos,type = common.getClass(org);
        type = common.getClass(org);
        this.#promise_arr = [];
        this[`handle${type}`](org);
        this._axios = ()=>Promise.all(this.#promise_arr);//此处 批量调用接口
    }

    stateSign = (state=this.state)=> common.getClass(state) === 'Boolean';

    handleArray (arg){//处理数组
        arg.forEach(item=>{
            if(item.parent){
                this.children[item.parent] = item;
                return ;
            }
            if(item.type==='get' && !item.alone){//批量查询时不需要立即执行
                let promise = new Promise((resolve)=>this.to_axios({...checker.cloneObj(item),resolve}))
                this.#promise_arr.push(promise);
            }
            this[item.key || this.urlKey(item.url)] = this.axios_loading.bind(this,checker.cloneObj(item));
        })
    }

    handleObject (arg){//处理对象
        
        if(arg.type==='get'){
            let promise = new Promise((resolve)=>this.to_axios({...checker.cloneObj(arg),resolve}))
            this.#promise_arr.push(promise);
        }
        this[arg.key || this.urlKey(arg.url)] = this.axios_loading.bind(this,checker.cloneObj(arg));
    }

    handleArguments (arg){//处理参数
        arg = common.toArray(arg);
        if(common.getClass(arg[0]) === 'Object')arg.splice(0,1);
        const [type,url,params,headers] = arg;
        if(type==='get'){
            let promise = new Promise((resolve)=>this.to_axios({...checker.cloneObj({type,url,params,headers}),resolve}))
            this.#promise_arr.push(promise);
        }
        this[this.urlKey(url)] = this.axios_loading.bind(this,checker.cloneObj({type,url,params,headers}))//默认 get
    }
    
    axios_loading (oldParam,newParam,cb){
        const data = checker.cloneObj(oldParam);

        if(newParam){for(const param in newParam){
            data[param] = newParam[param];
        }}

        return new Promise((resolve)=>this.to_axios({...data,cb,resolve}));
    }

    to_axios (item){//请求接口
        const {url='',key,params={},type='get',headers={},cb,resolve,source={}} = item,cache=[];
        let urlStr = url,_params = this.handleParams(params),urlKey = this.urlKey(url);

        if(cb){  _params = cb({getreq:_params.getreq || {},postreq:_params.postreq || {}}) };//此处可被外界修改请求参数 移除不需要的参数

        urlStr = urlStr + checker.objTurnParams(_params.getreq);
        cache.push(urlStr,_params.postreq,{headers:headers});
        axios[type](...cache).then(result=>{
            if(result && !result.status){//接口异常
                if(new Date().getTime() - this.timeout >= 3000){
                    this.handleMessage(result)
                    this.timeout = new Date().getTime();
                }
                resolve(this.handleResult(result));
            }else if(result){
                resolve(this.handleResult(result));
            }
        }).catch(err=>{
            ElMessage.warning('服务器异常，请联系管理员！');
            resolve({status : false,data:null});
        })
    }

    handleMessage (data){
        if(data.msg === 'failure'){
            // ElMessage.warning('数据加载失败！');
        }else if(data.msg === 'required login'){
            //ElMessage.warning('登录标识已失效，请重新登录！');
        }else if(typeof data.data === 'string' && data.data){
            ElMessage.warning(data.data);
        }else if(data.msg === 'expautoid is required!'){
            ElMessage.warning('实验标识不能为空！');
        }
    }

    handleResult (data){
        let sign;
        if(data.status && data.data && data.data.result){//列表
            sign = Array.isArray(data.data.result);
            return {
                ...data,
                result : sign?data.data.result : false,
            }
        }else if(data.status && data.data && Array.isArray(data.data)){//对象
            return {
                ...data,
                result : data.data,
            }
        }else if(data.status && data.data && !Array.isArray(data.data)){
            sign = !!data.data;
            return {
                ...data,
                data : sign ? data.data : false,
            }
        }else if(data){//未查到任何数据
            return {
                ...data
            }
        }else if(!data){//未查到任何数据
            return {
                data : false,
                result :false,
            }
        }else {
            return {
                data : false,
                result :false,
            }
        }
    }

    handleParams(data){//处理请求参数 数据类型 string 格式 string : param1|params2 || string,string : param1|params2 || string
        let template = data.template,array = [],getreq = {},postreq = {},resultData = {};
        if(template && common.getClass(template)!== 'String'){ console.warn('template 参数格式 =》 string : get_param1 | post_param2'); return {}; }
        else if(template){ array = template.split(','); }//['string : param1|params2','string : param1|params2'];
        if(common.getClass(data.getreq)==='Object')this.getmerge(data.getreq,getreq);
        if(common.getClass(data.postreq)==='Object')this.getmerge(data.postreq,postreq); 
        
        for(let item of array){
            let cache = item.trim().split(':'),key=cache[0],paramCache = null,check=null,//[string,param1|params2] | [string,get_param1|post_params2]
            objdata = this._this[key];
            if(cache[1]){
                let params = cache[1].split('|');//[param1,params2]

                for(let param of params){
                    
                    if(param.indexOf('post')>=0){  

                        paramCache = this.getalias(param.trim().replace('post_',''));
                        check = {key:paramCache.param,value:objdata[paramCache.alias]};
                        if(objdata[paramCache.alias])postreq[paramCache.param] = objdata[paramCache.alias];
                        else if(data.check && data.check(check))postreq[paramCache.param] = objdata[paramCache.alias];
                    }else {
                        paramCache = this.getalias(param.trim().replace('get_',''));
                        check = {key:paramCache.param,value:objdata[paramCache.alias]};
                        if(objdata[paramCache.alias])getreq[paramCache.param] = objdata[paramCache.alias];
                        else if(data.check && data.check(check))getreq[paramCache.param] = objdata[paramCache.alias];
                    }
                }
            }else {
                
                if(key.indexOf('post')>=0){
                    paramCache = this.getalias(key.trim().replace('post_',''));
                    check = {key:paramCache.param,value:objdata};
                    if(objdata)postreq[paramCache.param] = objdata;
                    else if(data.check && data.check(check))postreq[paramCache.param] = objdata;
                }else {
                    paramCache = this.getalias(key.trim().replace('get_',''));
                    check = {key:paramCache.param,value:objdata};
                    if(objdata)getreq[paramCache.param] = objdata;
                    else if(data.check && data.check(check))getreq[paramCache.param] = objdata;
                }
            }
        }
        resultData = {getreq,postreq};
        if(common.getClass(data.monitor) === 'Function')data.monitor(resultData,(data)=>{
            const {getreq,postreq} = data;
            resultData = {getreq,postreq};
        })
        return resultData;                                                                                                                                                                                                                                                                                                             
    }


    getmerge(data,request){
        if(!data)return;
        for(const  param in data){ 
            request[param] = data[param];
        };
    }

    getalias(name){
        if(name.indexOf('=')>=0){
            let array = name.split('=');
            return {
                param : array[0].trim(),
                alias : array[1].trim(),
            }
        };
        return { param : name,alias:name};
    }

    urlKey (url){//获取url 的 \最后一位
        return url ? url.match(/[^\/]*$/)[0] : url;
    }
}

export default axiosTool;