
import WangEditor from '@/components/course/wangEditor/index.vue';
import Look from '@/components/course/wangEditor/look.vue';
// import MenusLine from '@/components/course/MenusLine.vue';
import Interactive from '@/components/course/Interactive.vue';
import checker from '@/utils/checker';
import axios from '@/utils/axios';
import server from '@/service/server';
import common from '@/utils/common';
export default {
    data (){
        return {
            autoid: '',
            mark: '',
            targetautoid: '',
            tab : '0',
            actions : [
                {key : '0',title : '笔记',type:'note'},
                // {key : '1',title : '目录',type:'menus'},
                {key : '1',title : '提问',type:'ask'},
            ],
            drawerState : false,
            customButton : [
                {key : 'submit',label:'保存',on_click:this.wangEditorHandleSubmit}
            ],
            targettable: "uiexpdatums",
            dataTum: {},
            dataTumStyle: {
                rt: "richtexts",
                rs: "resources"
            },
            expNote: null,
            expid: '',
            pid: "",
            uiautoid: "",
            timer: '',
        }
    },
    created (){
        let params = checker.getQueryParams();
        if(params && typeof params.pid !== 'undefined')this.expid = params.pid;
        if(params && typeof params.did !== 'undefined')this.pid = params.did;
        if(params && typeof params.uid !== 'undefined')this.uiautoid = params.uid;
        let $app = document.querySelector('#app');
        $app.style.padding = 0;
    },
    computed: {
        dataTumClass() {
            return this.dataTum.targettable;
        }
    },
    mounted () {
        let storeParams = common.getStorParams({path:'/lectures'});
        if(!storeParams) storeParams = common.getStorParams(this);
        this.autoid = storeParams.key;
          this.targetautoid = storeParams.key; //cs
        // this.targetautoid = storeParams.autoid;
        this.expid = storeParams.expautoid;
        if(!!storeParams.expdatum_data){
            this.pid = storeParams.expdatum_data.targetautoid;
        }else{
            this.pid = storeParams.targetautoid;
        }
       
        this.activee = storeParams.currentActive;
        this.queryDatums();
        this.postStudy();
        this.queryExpNote();
    },
    components : {
        WangEditor,
        Look,
        // MenusLine,
        Interactive,
    },
    beforeRouteLeave(to, from, next){//路由切换时关闭定时器
        next();
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    },
    methods : {
        JumpTo(){
            let activeIndex =window.sessionStorage.getItem('resouceIndex');
            common.removeParams('/exps');
            common.setStorParams({path:"/expcore"}, {autoid: this.expid ,active:activeIndex});
            this.$router.push("/expcore");
        },
        handleClick (tab){
            this.tab = tab.index;
            const action = this.actions[tab.index]
            if(action.type == "note" && this.expNote) {
                this.tab = '0'
                this.queryExpNote()
            } else if(action.type == "ask") {

                // TODO 处理论题

            }
        },
        drawerClose (){
            this.drawerState = !this.drawerState;
        },
        queryDatums() {
            const {autoid} = this;
            let request = {
                autoid: autoid,
            }
            axios.get(server.uiexpdatum + checker.objTurnParams(request)).then(result=>{
                if(result.status && result.data && result.data.result && result.data.result.length){
                    this.dataTum = result.data.result[0];
                    if(this.dataTum.resource_data){
                        this.dataTum.name = this.dataTum.resource_data.name
                        this.dataTum.fileUrl = this.dataTum.resource_data.filepath || this.dataTum.resource_data.link;
                        this.dataTum.fileUrl += '#toolbar=0&navpanes=0';
                        this.dataTum.fileUrl += '#toolbar=0';
                        this.dataTum.resourcetype = this.dataTum.resource_data.resourcetype;
                        if(this.dataTum.resourcetype=='video'){
                            // var removeVideo = $(".removeDownload")
                            // removeVideo[0]['disablePictureInPicture'] = true; //disablePictureInPicture的属性改为true
                        }
                        this.dataTum.filepath = this.dataTum.resource_data.filepath
                    }
                    if(this.dataTum.richtext_data){
                        this.dataTum.name = this.dataTum.richtext_data.title
                    }
                    if(this.dataTum.targettable == this.dataTumStyle.rt) {
                        this.queryRichtextData(this.dataTum.targetautoid)
                    }
                    if(this.dataTum.targettable == this.dataTumStyle.rs) {
                        this.queryResourcesData(this.dataTum.targetautoid)
                    }
                }
            })
        },

        queryRichtextData(autoid) {
            const {$refs:refs} = this;
            const request = {
                autoid: autoid,
            }
            axios.get(server.richtext + checker.objTurnParams(request)).then(result=>{
                if(result.status && result.data && result.data.result && result.data.result.length){
                    if(refs.look){
                        refs.look.setHTML(result.data.result[0].context);
                    }
                }
            })
        },
        queryResourcesData(autoid) {
            const request = {
                autoid: autoid,
            }
            axios.get(server.resource + checker.objTurnParams(request)).then(result=>{
                if(result.status && result.data && result.data.result && result.data.result.length){
                    // this.$refs.look.setHTML(result.data.result[0].context);
                    return true;
                }
            })
        },
        postStudy(){//发送开始资料学习的请求
            const {targettable,expid,targetautoid} = this;
            let request = {
                start_view: true,
                expautoid: expid,
                targettable: targettable,
                targetautoid: targetautoid,
                has_expdatums: ''
            }
            axios.post(server.commview,request).then(result=>{
                if(result && result.status && result.data && result.data.result && result.data.result.length){
                    this.mark = result.data.result[0].mark;
                    this.timer = setInterval(this.postEndStudy,120000);
                }
            })
        },
        postEndStudy(){//发送关闭资料学习的请求
            const {mark} = this;
            let request = {
                start_view: false,
                mark: mark,
                has_expdatums: ''
            }
            axios.post(server.commview,request).then(result=>{
                if(result && result.status && result.data && result.data.result && result.data.result.length){
                    return true;
                }
            })
        },
        queryExpNote() {//查询笔记
            
            let request = {
                // targettable: this.targettable,
                targetautoid: this.autoid,
                //targetautoid: this.key,
                expautoid: this.expid,
                owner: ''
            }
            axios.get(server.expnote + checker.objTurnParams(request)).then(result=>{
                if(result.status && result.data && result.data.result && result.data.result.length){
                    this.expNote = result.data.result[0];
                    let expnoteData = result.data.result[0];
                    const {$refs:refs} = this;
                    if(expnoteData.approve_data){
                        if(expnoteData.approve_data.approvetype_name == '待审核'){
                            this.$refs.wangEditor[0].handleDiable();
                        }
                    }
                    if(refs.wangEditor && refs.wangEditor[0] && refs.wangEditor[0].setHTML){
                        refs.wangEditor[0].setHTML(result.data.result[0].content);
                    }
                }
            })
        },
        wangEditorHandleSubmit(content) {//新增笔记
            let request = {
                content : content,
                name:this.dataTum.name
            };
            if(this.expNote) {
                request.autoid = this.expNote.autoid
                request.put_data = true
            } else {
                request.expautoid = this.expid
                request.targettable = this.targettable
                request.targetautoid = this.autoid
            }
            axios.post(server.expnote, request).then(result=>{
                if(result.status){
                    this.$message({message: '保存成功！',type: 'success',duration:1000});
                }
            })
        },
        // handleDown(data){
        //     window.open(data.filepath || data.link)
        // },
    },
    unmounted () {
        let $app = document.querySelector('#app');
        $app.removeAttribute('style');
    },
}