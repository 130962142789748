<template>
    <!-- PC实验监控 -->
    <div class="expcore-check">
        <span>运行环境检测：</span>
        <div class="expcore-ul">
          <div class="expcore-li">
            <template v-if="__browserd__">
              <el-icon color="rgba(41,116,234,1)" :size="20"><CircleCheckFilled /></el-icon>
            </template>
            <template v-else>
              <el-icon color="#e6a23c" :size="20"><WarningFilled /></el-icon>
            </template>
          推荐使用 <b>谷歌</b> 或者 <b>火狐</b> 浏览器
          <a class="expcore-browserd" :href="`${base_name}ChromeSetup.exe`">谷歌下载并安装</a> 
          <a class="expcore-browserd" :href="`${base_name}FirefoxSetup.exe`">火狐下载并安装</a>
          <template v-if="!__browserd__">
            【请下载推荐浏览器并安装后打开】
          </template>
          
          </div>
          <div class="expcore-li">
            <template v-if="__plan__">
              <el-icon color="rgba(41,116,234,1)" :size="20"><CircleCheckFilled /></el-icon>
            </template>
            <template v-else>
              <el-icon color="#e6a23c" :size="20"><WarningFilled /></el-icon>
            </template>
            
            实验安装状态：<span ref="plat_status" id='plat_status'>未检测到实验正常安装</span>
            <div class="expcore-reset" id="b201">，请 
              <template v-if="__browserd__">
                <button id="checksoftware_button" @click="handleClick('重新检测')" class="expcore-btn download-start expcore-reset">重新检测</button>
              </template>
              <template v-else>
                <button disabled class="expcore-btn download-start expcore-reset">重新检测</button>
              </template>
              ，如果检测失败请 
            <a @click="handleClick('下载实验')" class="expcore-btn expcore-btn-link">下载实验并安装</a>
            </div>
          </div>
          <!-- <div style="display: inline-block;" id="b202"></div> -->
          <div class="expcore-li">
            <template v-if="__running__">
              <el-icon color="rgba(41,116,234,1)" :size="20"><CircleCheckFilled /></el-icon>
            </template>
            <template v-else>
              <el-icon color="#e6a23c" :size="20"><WarningFilled /></el-icon>
            </template>
            实验启动状态：<span id="game_start_status">实验未启动 </span> 
            <template v-if="__browserd__">
              <button id="game_start_button" @click="handleClick('启动实验')" class="expcore-btn download-start">点击启动实验</button> 
            </template>
            <template v-else>
              <button disabled class="expcore-btn download-start">点击启动实验</button> 
            </template>
            <a class="expcore-btn download-again expcore-btn-link" @click="handleClick('下载实验')">重新下载并安装</a>
          </div>
        </div>
      </div>
</template>
<script>
import Header from './header.vue';
import Tabs from '@/components/course/Tabs.vue';
import Details from './details.vue';
import Record from './record.vue';
import Evaluate from './evaluate.vue';
import Problem from './problem.vue';
import Expdata from './expdata.vue';
import Analysis from './analysis.vue';
import axios from '@/utils/axios';
import server from '@/service/server';
import checker from '@/utils/checker';
import common from '@/utils/common';

export default {
  data (){
    return {
      __browserd__ : false,
      __plan__ : false,
      __running__ : false,
      timer : null,
      index:1,
      expdata : {},
      base_name : common.BASE_URL,
    }
  },
    props :{
        data : {
            type:Object,
            default : (()=>{return {}})
        },
    },
  components : {
    Header,
    Tabs,
    Details,
    Record,
    Evaluate,
    Problem,
    Expdata,
    Analysis,
  },
  watch: {
    'data' : 'dataMonitor',
     '$store.getters.userInfo' : 'monitorUserInfo',
  },

  mounted (){
    common.removeJSSDK();
    common.createJSSDK('RocketLauncher',()=>{
      if(window.check_plat_loop_init){
        window.check_plat_loop_init(
          {
            exp_type_id:'expid_58589275_randomid_92644999_dianqidasai_2022_exp_07',
            required_exp_version: '2',
            required_plat_version: '2',
          });
      }
    });
    this.monitorUserInfo();
    this.dataMonitor()
    window.check_plat_loop_open = true;
    clearInterval(this.timer);
    this.timer = setInterval (()=>{
      let __plan__,__running__,__browserd__ = true;
      if(window.simclient_plat_connected){
        __plan__ = true;
      }else {
        __plan__= false;
      }
      if(window.simclient_game_running_status){
        __running__ = true;
      }else {
        __running__ = false;
      }
      if(common.browserd() === '火狐'){
        __browserd__ = '火狐';
      } else if(common.browserd() === 'chrome'){
        __browserd__ = 'chrome';
      } else {
        __plan__ = false;
        __running__ = false;
        __browserd__ = false;
        window.simclient_game_running_status = false;
        window.simclient_plat_connected = false;
      }
      this.__plan__ = __plan__;
      this.__running__ = __running__;
      this.__browserd__ = __browserd__;
    },500)
  },
  methods : {
    monitorUserInfo (userinfo=this.$store.getters.userInfo){
      this.userinfo = userinfo;
    },
    initParam (){
      const {expdata,userinfo} = this;

      this.getUi(expdata,result=>{
        let token = localStorage.getItem('token'),
        location = window.location;
         let exname = expdata.resource_data.expname[0];
         let pojname = expdata.explain;
        let websocketParam = {
          // projectname: encodeURI(encodeURI('火箭启动器' || "")),
          // expname: encodeURI(encodeURI('火箭启动器' || "")),
          projectname: encodeURI(encodeURI(exname || "")),
          expname: encodeURI(encodeURI(exname|| "")),
          downloadEnable: true, 
          autostart: true,
          objectid: "",
          studyno: "",
          keyid: "",
          web:1,
          mark:"zdff0sdfds1651129873892",
          expautoid : expdata.autoid,
          uiautoid : result.autoid,
          uiresourceautoid : expdata.resourceid,
          token : token,
          websitewsip:location.host,
          websitewsport:location.port || (window.location.protocol === 'https' ? '443' : '80'),
          websitepathname:process.env.BASE_URL,
          username : userinfo.username,
          realname : userinfo.realname,
          website_http_https : window.location.protocol.replace(':',''),
        };
        window.simclient_params = checker.objTurnParams(websocketParam);
      })
    },
    getUi (data,cb){
        let obj = {
            get_all_ui : true,
            targettable:'exps',
            targetautoid : data.autoid,
        }
        axios.get(server.ui + checker.objTurnParams(obj)).then(result=>{
            if(result.status && result.data && result.data.result){
                let array = result.data.result,
                cache = array.filter(item=>item.slot==='uiexprecords')[0];
                if(cb)cb(cache)
            }
        });
    },
    dataMonitor (data=this.data){
        if(data && data.autoid){
            this.expdata = data;
            this.initParam();
        }
    },
    getExpapply (){
      const {expdata} = this;
      let request = {
        expautoid : expdata.autoid,
      };
      return axios.get(server.expapply + checker.objTurnParams(request)).then(result=>{
        if(result && result.data && result.data.result && result.data.result.length){
          return true
        }else {
          return this.setExpApply();
        }
        
      })
    },
    setExpApply (){
      const {expdata} = this;
      let request = {
        expautoid : expdata.autoid,
        studytypeid : 2,
      }
      return axios.post(server.expapply,request).then(result=>{
        if(result && result.data && result.data.result && result.data.result.length){
          return true;
        }else {
          return false;
        }
      })
    },
    postExpuv(){//添加使用量
      const {expdata} = this;
      let request = {
          expautoid: expdata.autoid
      }
      axios.post(server.expuv,request).then(result=>{
          if(result.status){
              return true;
          }
      })
    },
    handleClick (type){
        const {expdata} = this;

        switch (type){
            case '下载实验' : 
                if(expdata.resource_data && expdata.resource_data.filepath){
                    let resource = expdata.resource_data;
                    window.open(resource.filepath);
                }
                break;
            case '重新检测' : 
                window.start_sim_client(expdata);
                break;
            case '启动实验' : 
                this.getExpapply().then(success=>{
                    if(success){
                      window.start_sim_game();
                      this.postExpuv();
                    }
                })
                break;
        }
    }
  }
}

</script>

<style scoped lang="less">

  .expcore-browserd{
    margin-left:10px;
  }
    .expcore-check{
        margin-bottom: 10px;
        background: #FFF;
        padding: 10px;
        border-radius: 4px;
        >span{
        font-size: 18px;
        font-weight: bold;
        display: inline-block;
        margin-bottom: 10px;
        }
        .expcore-ul{
        padding-left:20px;
        line-height: 48px;
        .expcore-li{
            display: flex;
            align-items: center;
            :deep(button):disabled{
                background-color: #ECF5FF !important;
                color:#A0CFFF !important;
                cursor: not-allowed !important;
                transform: scale(1) !important;
                } 
                .expcore-btn{
                background-color: #ECF5FF;
                color: #409EFF;
                display: inline-block;
                cursor: pointer;
                border: none;
                border-radius: 4px;
                padding: 0 10px;
                height:35px;
                font-size:15px;
                flex:0 0 auto;
                }
                .expcore-btn.expcore-btn-link{
                background-color:transparent;
                text-decoration: underline;
                color:#0000EE;
                padding:0;
                }
                .expcore-btn.download-again{
                margin-left: auto;
                }
                .expcore-btn.download-start{
                margin-left:10px;
                height: 40px;
                display: inline-block;
                background-color: rgba(35,116,238,1);
                color: #FFFFFF;
                font-weight: bold;
                }
                .expcore-btn.expcore-reset{
                background-color: rgba(35,116,238,1);
                margin-left:3px;
                }
                .expcore-btn:active{
                transform: scale(.98);
                }
                .expcore-reset{
                display: inline-block;
                }

                :deep(.el-icon){
                margin-right:10px;
                position: relative;
                top: 1px;
                }
            }
        }
    }
</style>
