const options = {}
//饼状图
options.pie = ops=>{
    const {name,size,formatter,legendTop,colors,seriesCenter,data} = ops;
    return {
        legend: {
            top: legendTop || '80%',
            orient:'horizontal',
            x:'center',
            y:' 1px',
            width:'200px',
            padding:[1, 20,0,0],
            itemWidth:30,
            textStyle:{
                color:'#000',
            },
        },
        grid:{
            top:'50%',
            left:'100%',
            right:'25%',
            bottom:'25%',
        },
        color:colors || ['#EF6C00', '#00796B', '#C0CA33','#F9A825'],//饼图颜色
        tooltip: {
            trigger: 'item',
            // formatter : function(){
            //     return '123123'
            // }
        },
        series: [
            {
                name: name, 
                type: 'pie', 
                radius: size || '150px',//调整饼状图大小  //****
                center: seriesCenter || ['50%', '38%'],
                itemStyle: {
                    borderRadius: 0
                },
                label : {
                    normal : {
                        position : 'inner',
                        show : true,
                        //formatter : formatter() || '{d}%', //****
                        formatter : function(params){
                            let data = params.data;
                            if(data.value == 0){
                                data.labelLine.show = false;
                                data.label.show = false;
                            }else{
                                return formatter(params);
                            }
                        }, //****
                        fontSize: '14',
                        color:'#FFF',
                    }
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '16',
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: true
                },
                // data: [//****
                //     { value: 20, name: 'rose 1' },
                //     { value: 38, name: 'rose 2' },
                //     { value: 32, name: 'rose 3' },
                //     { value: 30, name: 'rose 11' },
                //     { value: 28, name: 'rose 5' },
                //     { value: 26, name: 'rose 6' },
                //     { value: 22, name: 'rose 7' },
                //     { value: 18, name: 'rose 8' }
                // ],
                data : data,
            }
        ]
    }
};
//折线图
options.line = ops => {
    const {name,legendData,xAxisType,xAxisData,data,formatter} = ops;
    return {
        title: {
            text: name || '',
        },
        tooltip: {
            trigger: 'axis',
            formatter: formatter || new Function()
        },
        legend: {
            data: legendData || ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        toolbox: {
            feature: {
                //saveAsImage: {}
            }
        },
        xAxis: {
            type: xAxisType || 'category',
            boundaryGap: false,
            data: xAxisData || ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            splitLine: {
                show: false
            }
        },
        yAxis: {
            type: 'value'
        },
        series: data || [
            {
                name: 'Email',
                type: 'line',
                stack: 'Total',
                data: [120, 132, 101, 134, 90, 230, 210]
            },
        ]
    }
};
//柱状图
options.bar = ops => {

};

export default type=>options[type];