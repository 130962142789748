<template>
    <!-- 合作院校 -->
    <layout_20230210_47e9>
        <template #title>合作院校</template>
        <div class="main">
            <el-carousel :autoplay="true" class="x-carousel" >
                <el-carousel-item v-for="item_out in data" :key="item_out.key">
                    <div class="banner_block">
                        <span v-for="item_inner in item_out" :key="item_inner.key">
                            <img :src="item_inner.path">
                        </span>
                    </div>
                    <!-- <img :src="item.url" :alt="item.title"> -->
                </el-carousel-item>
            </el-carousel>
        </div>
    </layout_20230210_47e9>
</template>

<script>
import layout_20230210_47e9 from '@/components/layouts/layout_20230210_47e9.vue';
export default {
    name : "school",
    components: {
        layout_20230210_47e9
    },
    data() {
        return {
            data: [
                [
                   
                    { name: 'item1', path: require('@/assets/images/xxl_gw/北京大学logo.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/湖南大学logo.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/华中科技大学logo.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/清华大学logo.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/山东大学logo.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/上海交通大学logo.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/四川大学logo.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/武汉大学logo.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/西安交通大学logo.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/浙江大学logo.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/郑州大学logo.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/中国科学技术大学logo.png') },
                ],
                [
                    { name: 'item1', path: require('@/assets/images/xxl_gw/东华大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/东南大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/福州大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/广西电力职业技术学院.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/贵州大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/国家电网.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/海军工程大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/杭州师范大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/河北大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/湖南大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/华北电力大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/华南理工.png') },
                ],

                [
                    { name: 'item1', path: require('@/assets/images/xxl_gw/怀柔实验室.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/火箭军工程大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/清华大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/山东大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/上海海洋大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/上海交通.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/上海理工大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/深圳职业.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/神州集团.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/顺德职业技术学院.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/天津大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/天津商业大学.png') },
                ],

                [
                    { name: 'item1', path: require('@/assets/images/xxl_gw/同济大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/武汉大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/武汉电力.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/武汉理工.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/西安交通.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/西安理工.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/西北大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/长沙理工.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/浙江大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/浙江商业.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/浙江树人大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/郑州大学.png') },
                ],
                [
                    { name: 'item1', path: require('@/assets/images/xxl_gw/中国船舶重工.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/中国地质大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/中国科学技术大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/中国科学院.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/中国石油大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/中南大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/中南民族大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/中山大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/重庆大学.png') },
                    { name: 'item1', path: require('@/assets/images/xxl_gw/重庆电力.png') },
                ],
            ],
            // height : '619px',
        }
    },
}
</script>


<style scoped lang="less">
/deep/ .el-carousel__container{
    height: 250px;
}
.main{
    width: 100%;
    
}

:deep(.el-carousel__button){
    background-color: #F18D00;
}

.banner_block{
    /* width: 90%; */
    display: flex;
    flex-wrap: wrap;
    line-height: 88px;
    /* vertical-align: middle; */
    margin-top: 3%;
}
.banner_block >span{
    width: 16.65%;
}

</style>