<template>
    <div class="screen-ui">
        <template v-for="items in data" :key="items.key">
            <div class="screen-ul" v-if="!!(items.children.length > 1)">
                <span class="screen-label">
                    {{items.label}}
                </span>
                <div class="screen-items">
                    <span :class="['screen-item',pitchData[items.type] === item.key ? 'active' : '']" @click="handlePitch(items,item)" v-for="item in items.children" :key="item.key">
                        {{item.label}}
                    </span>
                </div>
            </div>
        </template>
        <div class="screen-ul search" v-if="searchItems.length">
            <span class="screen-label">
                关键词：
            </span>
            <div class="screen-items search-items">
                <span class="search-input" v-for="item in searchItems" :key="item.key">
                    <el-input :placeholder="item.placeholder" v-model="searchData[item.key]" :disabled='item.disabled'/>
                </span>
                <span class="search-input"><Buttons type="info" size="large" @click="handleReset">重置</Buttons></span>
                <span class="search-input"><Buttons type="blue" icon="el-icon-search" size="large" @click="handleSearch">搜索</Buttons></span>
            </div>
        </div>
        <div class="screen-ul filter">
            <span>排序：</span>
            <div :class="['filter-item',sortActive[item.key] === item.key ? 'active' : '']" @click="handleClick('排序',item)" v-for='item in sortData' :key='item.key'>
                <el-tooltip effect="light" :content="sortType[item.key]==='asc' ? '升序' : sortType[item.key] === 'desc' ? '降序' : '点击排序'" placement="top">
                    {{item.label}}
                </el-tooltip>
                <span class='item-span'>
                    <el-icon :class="['item-top',sortType[item.key]==='asc' ? 'asc' : '']"><caret-top /></el-icon>
                    <el-icon :class="['item-bottom',sortType[item.key]==='desc' ? 'desc' : '']"><caret-bottom /></el-icon>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import Buttons from '@/components/course/Buttons.vue';
import axios from '@/utils/axios';
import server from '@/service/server';
import checker from '@/utils/checker';
import { ElLoading } from 'element-plus';
import common from "@/utils/common";
import axiosTool from '@/utils/axios_tool';
export default {
    data (){
        return {
            majorFlag : true,
            source : [],
            data : [
                {key:'专业大类',majorFlag:true,label:'专业大类：',type:'subjectautoid',children : []},
                {key:'专业分类',majorFlag:false,label:'专业分类：',type:'major',children : []},
                {key:'申报类型',majorFlag:true,label:'申报类型：',type:'level',children : []},
                {key:'申报年份',majorFlag:true,label:'申报年份：',type:'expdeclareyearid',children : []},
                // {key:'005',label:'申报权限：',children : [
                //     {key : '0000',label:'全部'},
                //     {key : '0005',label:'公开'},
                //     {key : '0005',label:'未公开'},
                // ]},
            ],
            sortData : [
                {key:'001',label:'最新',value:'createtime'},
                {key:'002',label:'评分',value:'evaluateall'},
                {key:'003',label:'点赞',value:'starall'},
                {key:'004',label:'使用量',value:'joinall'},
            ],
            sortActive : {},
            sortType : {},
            pitchData : {
                subjectautoid : 0,
                major : 0,
                level : 0,
                expdeclareyearid : 0,
            },
            searchItems : [
                {key : 'title',type:'input',placeholder:'实验名称',disabled:false,},
                {key : 'master_realname',type:'input',placeholder:'负责教师',disabled:false,},
            ],
            searchData : {},
        }
    },
    created (){
        this.axiosToolText = new axiosTool(this,[
          {type:'get',title:'获取专业大类数据',url:server.subject},
          {type:'get',title:'获取专业数据',url:server.major,params : { template:'pitchData:subjectautoid',check:data=>typeof data.value !== 'undefined'}},
          {type:'get',title:'获取申报类型',url:server.exp,params : {getreq:{get_static_exp_declare_type:true}}},
          {type:'get',title:'获取申报年份',url:server.expdeclareyear,params : {getreq:{get_static_exp_declare_type:true}}},
        ]);
    },
    mounted (){
        this.axiosToolText.loading = true;
        this.axiosToolText._axios().then(data=>{
            const [subjectData,majorData,levelData,expdeclareyearData] = data;
            this.handleSubjectData(subjectData);
            this.handleMajorData(majorData);
            this.handleLevelData(levelData);
            this.handleExpdeclareyearData(expdeclareyearData);  
            this.axiosToolText.loading = false;
        })
    },
    components : {
        Buttons
    },
    methods : {
        handleSubjectData (source){
            const {data} = this;
            let subjectIdx = data.findIndex(item=>item.key === '专业大类');
            if(source && source.result){
                source.result.map(item=>{
                    item.key = item.autoid;
                    item.label = item.name;
                })
                source.result.unshift({key : 0,label:'全部'});
                if(subjectIdx != -1){
                    data[subjectIdx].children = source.result;
                }
                this.data = [...data];
                return ;
            }
            data[subjectIdx].children = [];
        },
        handleMajorData (source){
            const {data} = this;
            let majorIdx = data.findIndex(item=>item.key === '专业分类');
            if(source && source.result){
                source.result.map(item=>{
                    item.key = item.autoid;
                    item.label = item.name;
                })
                source.result.unshift({key : 0,label:'全部'});
                data[majorIdx].children = source.result;
                this.data = [...data];
                return ;
            }
            data[majorIdx].children = []
        },
        handleClick (type,item){
            const {searchData,sortType,sortData,sortActive} = this;
            let sign,cache={};
            switch (type) {
                case '排序':
                    if(!sortType[item.key]){
                        sortType[item.key] = 'asc';
                        sign = true;
                    }else if(sortType[item.key] === 'asc'){
                        sortType[item.key] = 'desc';
                        sign = true;
                    }else {
                        delete sortType[item.key];
                        sign = false;
                    }
                    if(sign){
                        sortActive[item.key] = item.key;
                        let _order_column = [],_order_style=[];
                        for(const param in sortActive){
                            let paramIdx = sortData.findIndex(ite=>ite.key === param),
                            obj = sortData[paramIdx];
                            _order_column.push(obj.value);
                            _order_style.push(sortType[param]);
                        }
                        cache._order_column =  _order_column.join(',');
                        cache._order_style = _order_style.join(',');
                    }else {
                        if(searchData._order_column && searchData._order_style){
                            let column_arr = searchData._order_column.split(','),
                            style_arr = searchData._order_style.split(','),
                            paramIdx = sortData.findIndex(ite=>ite.key === item.key),
                            obj = sortData[paramIdx],
                            arr_idx = column_arr.findIndex(ite=>ite === obj.value);
                            column_arr.splice(arr_idx,1);
                            style_arr.splice(arr_idx,1);
                            searchData._order_column = column_arr.join(',');
                            searchData._order_style = style_arr.join(',');
                        }
                        delete sortActive[item.key]
                    };
                    this.sortType = {...sortType};
                    this.searchData = {...searchData,...cache};
                    this.sortActive = {...sortActive};

                    this.$emit('toChange',{...this.searchData});
                    break;
            
                default:
                    break;
            }
        },
        handleLevelData (source){
            const {data} = this;
            let levelIdx = data.findIndex(item=>item.key === '申报类型');
            if(source && source.result){
                source.result.map(item=>{
                    item.key = item.key;
                    item.autoid = item.key,
                    item.label = item.value;
                })
                source.result.unshift({key : 0,label:'全部'});
                data[levelIdx].children = source.result;
                this.data = [...data];
                return ;
            }
            data[levelIdx].children = []
        },
        handleExpdeclareyearData (source){
            const {data} = this;
            let yearlIdx = data.findIndex(item=>item.key === '申报年份');
            if(source && source.result){
                source.result.map(item=>{
                    item.key = item.autoid;
                    item.label = item.year + ' 年';
                })
                source.result.unshift({key : 0,label:'全部'});
                data[yearlIdx].children = source.result;
                this.data = [...data];
                return ;
            }
            data[yearlIdx].children = []
        },
        handlePitch (items,item){
            const {searchData,pitchData,data} = this;
            searchData[items.type] = item.autoid;
            pitchData[items.type] = item.key;
            if(items.key === '专业大类'){//专业大类点击事件
                searchData.major = 0;
                pitchData.major = 0;
                this.axiosToolText.major().then(data=>{
                    this.handleMajorData(data);
                })
            }
            this.$emit('toChange',{...searchData});
        },

        handleSearch (){
            const {searchData} = this;
            this.$emit('toChange',{...searchData});
        },
        handleReset (){
            const {searchItems} = this;
            searchItems.map(item=>{
                delete this.searchData[item.key];
            });
            this.$emit('toChange',{...this.searchData});
        },
    },
}
</script>

<style scoped lang="less">
    .screen-ui{
        padding:10px;
        //box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
        background-color: #FFFFFF;
        border-radius: 4px;
        .screen-ul{
            width:100%;
            display:flex;
            margin-bottom: 10px;
            &:last-child{
                margin-bottom: 0;
            }
            .screen-label{
                flex: 0 0 auto;
                font-size: 18px;
                font-weight: bold;
                padding-top: 10px;
                width: 110px;
                text-align: right;
            }
            .screen-items{
                flex:1 1 auto;
                text-align: left;
                .screen-item{
                    font-size: 16px;
                    color: #333333;
                    //padding: 10px 20px;
                    height:31px;
                    
                    text-align: center;
                    line-height: 31px;
                    display: inline-block;
                    padding:5px 10px;
                    border-radius: 4px;
                    margin-bottom:10px;
                    margin-right: 10px;
                    cursor: pointer;
                    transition: all .1s ease-out;
                    &:hover{
                        background-color: rgba(41, 116, 234, 0.5);
                        color:#FFFFFF;
                    }
                    &.active {
                        background-color: rgba(41, 116, 234, 0.8);
                        color:#FFFFFF;
                    }
                }
            }
        }
        .search{
            padding:10px 0;
            display: flex;
            align-items: center;
            .screen-label{
                padding:0;
            }
            .search-items{
                display: flex;
                padding-top: 5px;
                .search-input{
                    margin-left:10px;
                }
            }
        }
    }

    .screen-ul.filter{
        display: flex;
        align-items: center;
        padding: 10px 10px 0;
        >span{
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #1A1A1A;
        }
        .filter-item{
            display: flex;
            align-items: center;
            display: flex;
            align-items: center;
            padding: 3px 10px;
            border-radius: 4px;
            margin-right: 10px;
            color: #666666;
            cursor :pointer;
            -moz-user-select:none; /*火狐*/
            -webkit-user-select:none; /*webkit浏览器*/
            -ms-user-select:none; /*IE10*/
            -khtml-user-select:none; /*早期浏览器*/
            user-select:none;

            &.active{
                color:#FFFFFF;
                background-color:rgba(41, 116, 234, 0.8);
                .item-span{
                    color: #666666;
                }
            }
            .item-span{
                display: flex;
                flex-direction: column;
                
                .item-top{
                    position:relative;
                    top:3px;
                }
                .item-top.asc{
                    color:#FFFFFF;
                }
                .item-bottom{
                    position:relative;
                    bottom:3px;
                    
                }
                .item-bottom.desc{
                    color:#FFFFFF;
                }
            }
        }
    }


</style>