<template>
  <!-- 门户页 -->
  <div class="home-ui">
    <!-- 轮播图 -->
    <div class="home-banner" :style="back" >
      <Banner v-if="PROGRAMA.bannerData.isShow"></Banner>
    </div>
    <!-- 主体内容 -->
    <div class="home-main common-width">
      <!-- 新闻公告 -->
      <PageIteration
        @onMore="handleClick('notice')"
        isMore
        :title="PROGRAMA.noticeData.name"
        englishTitle="News announcement"
        v-if="PROGRAMA.noticeData.isShow"
      >
        <News :newsData="newsData" @toClick="handleNewClick"/>
      </PageIteration>


      <!-- 大赛软件 -->
      <PageHeader :isBack="false" v-if="PROGRAMA.expData.isShow">
        <template #header>
          <div class="home-shade">
            <span class="home-header">{{PROGRAMA.expData.name}}</span>
            <span class="home-english">COMPETITION SOFTWARE</span>
          </div>
        </template>
        <template #content>
          <Projects :source="source" @toClick="handleExpClick"></Projects>
        </template>
      </PageHeader>

      <div class="home-more" v-if="source.length">
        <Buttons type="blue" @click="handleClick('exps')">更多</Buttons>
      </div>

      <!-- 活动专题 -->
      <PageIteration
        @onMore="handleClick('special')"
        isMore
        :title="PROGRAMA.specialData.name"
        englishTitle="Activity topics"
        v-if="PROGRAMA.specialData.isShow"
      >
        <Aspecial />
      </PageIteration>


      <!-- 本月实验应用排行 -->
      <PageIteration
        @onMore="handleClick('ranking')"
        isMore
        :title="PROGRAMA.rankingData.name"
        englishTitle="Ranking List 注：排行榜每月1日0点更新"
        v-if="PROGRAMA.rankingData.isShow"
      >
        <Aranking @toClick='handleExpClick' :data='expgradeData'/>
      </PageIteration>

      
    </div>
  </div>
</template>

<script>
import News from "@/components/business/News/index.vue";
import PageHeader from "@/components/course/PageHeader.vue";
import Projects from "@/components/business/Projects/index.vue";
import PageIteration from "@/components/business/PageIteration/index.vue";
import Buttons from "@/components/course/Buttons.vue";
import common from "@/utils/common";
import Aspecial from "@/components/business/Aspecial/index.vue";
import Aranking from "@/components/business/Aranking/index.vue";
import Banner from "./Banner.vue";
import axiosTool from "@/utils/axios_tool";
import server from "@/service/server";
import checker from "@/utils/checker";
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      newsData: [],
      programaData: [],
      programaType:{
         banner:false,
         active:false,
         recommend:false,
         expgrade:false,
         news:false,
      },
      source: [],
      refreshKey : 0,
      axiosTool : {},
      expgradeData : [],
      PROGRAMA:{
        bannerData: {key: '6',type: 'banner', isShow: false, name: '轮播图'},
        noticeData: {key: '7',type: 'notice', isShow: false, name: '大赛公告'},
        specialData: {key: '8',type: 'special', isShow: false, name: '活动专题'},
        expData: {key: '9',type: 'exp', isShow: false, name: '大赛软件'},
        rankingData: {key: '10',type: 'ranking', isShow: false, name: '本月实验应用排行'},
      },
    };
  },
  components: {
    News,
    PageHeader,
    Projects,
    Buttons,
    PageIteration,
    Aspecial,
    Aranking,
    Banner,
  },
  created (){
    this.axiosTool = new axiosTool(this,[
      {type:'get',title:'获取首页子栏目',url:server.programa,params:{getreq:{parentid: 1}}},
      {type:'get',title:'获取关联项关联新闻',url:server.coverage,params:{getreq:{relevance: 'index'}}},
      {type:'get',title:'获取推荐实验列表',url:server.recommendexp},
      {key:'expgrade_join',title:'查询热门排行',type:'get',url:server.expgrade,params : {getreq:{column:'join',style:'desc'}}},
      {key:'expgrade_evaluate',title:'查询好评排行',type:'get',url:server.expgrade,params : {getreq:{column:'evaluate',style:'desc'}}},
      {key:'expgrade_star',title:'查询吸引排行',type:'get',url:server.expgrade,params : {getreq:{column:'star',style:'desc'}}},
      {type:'post',url:server.exppv},
    ])
  },
  mounted() {
    this.axiosTool.loading = true;
    this.axiosTool._axios().then(result=>{
      const [programaData,newsData,source,joinData,evaluateData,starData] = result;
      this.expgradeData = [];
      this.getProgramaData(programaData);
      this.getNewsData(newsData);
      this.getSource(source);
      this.handleExpgrade(joinData,'热门排行');
      this.handleExpgrade(evaluateData,'好评排行');
      this.handleExpgrade(starData,'吸引排行');
      this.expgradeData = [...this.expgradeData]
      this.axiosTool.loading = false;
    })
  },
  updated (){
    this.getUserInfo();
  },
  methods: {
    handleClick(type) {
      this.$router.push("/" + type);
    },
    handleExpClick(data) {
      //门户页实验点击后的操作
      common.removeParams('/exps');
      common.setStorParams({path:"/expcore"}, {autoid: data.key, exp_data: data.exp_data});
      this.$router.push("/expcore");
      this.postExppv(data.key);
    },
    handleNewClick(data){ 
      common.removeParams('/notice')
      // common.setStorParams({path:'/noticemain'},{autoid: data.key});
      this.$router.push('/noticemain'+'?id=' + data.key);
    },

    getProgramaData(data){//获取首页子栏目
      const {PROGRAMA} = this;
      if(data && data.result){
        data.result.map(item=>{
          switch (parseInt(item.autoid)){
            case 6:
              PROGRAMA.bannerData.isShow = true;
              PROGRAMA.bannerData.name = item.name;
              break;
            case 7:
              PROGRAMA.noticeData.isShow = true;
              PROGRAMA.noticeData.name = item.name;
              break;
            case 8:
              PROGRAMA.specialData.isShow = true;
              PROGRAMA.specialData.name = item.name;
              break;
            case 9:
              PROGRAMA.expData.isShow = true;
              PROGRAMA.expData.name = item.name;
              break;
            default:
              PROGRAMA.rankingData.isShow = true;
              PROGRAMA.rankingData.name = item.name;
              break;
          }
        })
      }
    },
    getNewsData(data){ //处理 获取关联项关联新闻
      let newsData = [];
      if(data && data.result){
        data.result.map(item=>{
          item.time = checker.timestampToDate(new Date(item.createtime).getTime(),'YYYY-MM');
          item.day = checker.timestampToDate(new Date(item.createtime).getTime(),'DD');
          item.key = item.autoid;
          newsData.push(item);
        })
      }
      this.newsData = newsData;
    },
    getSource(data) { //处理 获取推荐实验列表
      let sourceData = [],levelMap = {1:'国家级',2:'省级',3:'非申报',4:'其他'};
      if(data && data.result){
        data.result.map(item=>{
          if(item.exp_data.autoid){
            item.key = item.exp_data.autoid;
            item.title = item.exp_data.title;
            item.face = item.exp_data.face;
            item.level = levelMap[item.exp_data.level];
            item.expuv_num = item.exp_data.expuv_num;
            item.exppv_num = item.exp_data.exppv_num;
            if(item.exp_data.major_data){
              item.major = item.exp_data.major_data.name;
            }
            if(item.exp_data.master_data){
              item.realname = item.exp_data.master_data.realname;
            }
            if(item.exp_data.expdeclareyear_data){
              item.expdeclareyear = item.exp_data.expdeclareyear_data.year;
            }
            sourceData.push(item);
          }  
        })
      }
      this.source = sourceData;
    },

    handleExpgrade (data,type){// 处理 查询 热门排行、好评排行、吸引排行
      const {expgradeData} = this;
      if(data && Array.isArray(data.result)){
        let array = data.result;
        for(let i = 0 ; i < array.length ; i++){
          if(i>=5)return;
          let cache = array[i],
          exp_data = cache.exp_data,faces = [];
          cache.exppv_num = cache.join;
          cache.expgrade_type = type;
          if(exp_data){
            faces = exp_data.face.split(',');
            cache.key = exp_data.autoid;
            cache.title = exp_data.title;
            if(exp_data.master_data){
              cache.realname = exp_data.master_data.realname; 
            }else {
              cache.realname = '暂无';
            }
      
            faces.map(face=>{
              if(face.indexOf('jpg') != -1){
                  exp_data.face = face
              }else if(face.indexOf('png') != -1){
                  exp_data.face = face
              }else if(face.indexOf('jpeg') != -1){
                  exp_data.face = face
              }else{
                if( exp_data.face==""){
                   exp_data.face = require('@/assets/images/default_exp.jpg');
                }
                 
              }
            })
            cache.imgUrl = exp_data.face;
          }else {
            cache.key = checker.uuid(8);
            cache.title = exp_data.title;
            cache.imgUrl = require('@/assets/images/default_exp.jpg');
          }
          expgradeData.push(cache);
        }
      }
    },
    postExppv(autoid){//浏览量
      this.axiosTool.loading = true;
      this.axiosTool.exppv({},data=>{
        const {postreq} = data;
        postreq.expautoid = autoid;
        return {...data,postreq}
      }).then(result=>{
        if(result.status){
          //console.warn('成功创建浏览记录')
          this.axiosTool.loading = false;
        }
      })
    },
    ...mapActions(['getUserInfo'])
  },
};
</script>

<style scoped lang="less">
@import url("@/styles/common.less"); //公共样式
.media; //媒体查询
:deep(.page-item .item-head){
  padding : 10px 0px;
}
:deep(.news-ui .news-items) {
  padding: 0 10px;
}
:deep(.page-item .item-head .item-shade){
  border-bottom:1px solid #e6e6e6;
  padding-bottom: 5px;
}
.home-ui {
  width: 100%;
  padding-bottom: 10px;
  .home-banner {
    width: 100%;
    margin-bottom: 20px;
  }
  .home-main {
    width: 100%;
    margin: auto;
    .home-shade {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 48px 0 30px;
      .home-header {
        font-weight: bold;
        font-size: 30px;
        color: #333333;
      }
      .home-english {
        font-size: 20px;
        color: #b6b6b6;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px;
        &::after {
          content: "";
          width: 72px;
          height: 3px;
          background-color: #1e5fc2;
          display: inline-block;
          margin-top: 15px;
        }
      }
    }
    .home-more {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px 0 50px;
    }
  }
}
</style>
