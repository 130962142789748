<template>
    <div class="page-header">
        <div class="header-title" v-if="isBack">
            <span class="title-back" @click="onBack"><el-icon><arrow-left-bold /></el-icon>返回</span>
            <slot name='title'></slot>
        </div>
        <slot name="header"></slot>
        <div class="header-content">
            <slot name="content"></slot>
        </div>
        <div v-if='isFooter' class="header-footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
export default {
    methods : {
        onBack : function(){
            if(this.goMethod){
                this.goMethod(()=>{
                    this.analysis(this.$route.path);
                });
            }else {
                this.analysis(this.$route.path);
            }
        },
        analysis : function (path){
            let routerMap = this.$store.getters.menus.routerMap,
            pathNode = routerMap[path];
            this.$router.push(pathNode.parent);
        }, 
    },
    props : {
        isBack : {
            type : Boolean,
            default : true,
        },
        goMethod : {
            type : Function,
            default : () => {}
        },
        isFooter : {
            type : Boolean,
            default : false,
        }
    },
}
</script>
<style scoped>
    
    .page-header{
        width:100%;
        height:100%;
        display: flex;
        flex-direction: column;
    }   
    .page-header .header-title{
        flex:0 0 auto;
        height:50px;
        border-bottom:1px solid #F2F2F2;
        clear:both;
        line-height: 50px;
        padding: 0px 10px;
        text-align: center;
    }

    .page-header .header-title .title-back{
        float:left;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .page-header .header-content{
        position: relative;
        flex:1 1 auto;
    }
    .page-header .header-footer{
        flex:0 0 auto;
        margin-top: 10px;
    }
</style>    