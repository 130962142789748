<template>
    <div class="projects-ui">
        <div class="projects-ul">
            <div class="projects-li" v-for="(array,idx) in data" :key="idx">
                <template v-for="(item) in array" :key="item.key">
                    <div class="projects-item" @click="$emit('toClick',item)">
                        <img :src="item.face" alt="">
                        <!-- <span class="projects-state"><i class="xxl xxl-gold"></i>66</span> -->
                        <span class="projects-title">{{item.title}}</span>
                        <div class="projects-msg user">
                            <span>{{item.major || '专业'}}</span>
                            <span><el-icon><user /></el-icon> <span>{{item.realname || '管理员'}}</span></span>
                        </div>
                        <div class="projects-msg detail">
                            <span>{{item.level || '认证级别'}}</span>
                            <span>{{item.expdeclareyear || '年份'}}</span>
                            <span title='使用量'><el-icon><avatar /></el-icon>{{item.expuv_num || 0}}</span>
                            <span title='浏览量'><el-icon><user-filled /></el-icon>{{item.exppv_num || 0}}</span>
                        </div>
                    </div>
                </template>
            </div>
            <NoData v-if="!data.length"></NoData>
        </div>
    </div>
</template>

<script>
    import NoData from "@/components/common/nodata.vue";
    export default {
        data (){
            return {
                data : [],
            }
        },
        watch : {
            'source' : 'monitorSource'
        },
        props : {
            source : {
                type : Array,
                default : ()=>{return []}
            },
        },
        mounted (){
            this.formatData(this.source);
        },
        components : {
            NoData
        },
        methods : {
            monitorSource (source){
                this.formatData(source);
            },
            formatData (source){
                let array = [],cache = [];
                source.map((item,idx)=>{
                    let face = item.face.split(',');
                    face.map(fitem=>{
                        if(fitem.indexOf('jpg') != -1){
                            item.face = fitem
                        }else if(fitem.indexOf('png') != -1){
                            item.face = fitem
                        }else if(fitem.indexOf('jpeg') != -1){
                            item.face = fitem
                        }else{
                            if(item.face=='')
                            item.face = require('@/assets/images/default_exp.jpg')
                        }
                    })
                    if(idx%4){
                        cache.push(item); 
                    }else{
                        cache = [];
                        cache.push(item);
                        array.push(cache);
                    }
                })
                this.data = array;
            },
        },
    }
</script>

<style scoped lang="less">
    /deep/ .el-empty{
        padding:0;
    }
    .projects-ui{
        width:100%;
        height:100%;
        .projects-ul{
            width:100%;
            .projects-li{
                width:100%;
                display: flex;
                margin-bottom: 20px;
                .projects-item{
                    position: relative;
                    width:316px;
                    display: flex;
                    flex-direction: column;
                    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
                    overflow: hidden;
                    border-radius: 4px;
                    margin-right:15px;
                    &:last-child{
                         margin-right:0;
                    }
                    cursor: pointer;
                    >img{
                        width:100%;
                        height:174px;
                        border:none;
                    }
                    .projects-state{
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 65px;
                        height: 30px;
                        background-color: #f59a23;
                        border-top-right-radius: 4px;
                        border-bottom-left-radius: 4px;
                        font-size: 14px;
                        color: #FFFFFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        >i{
                            margin-right:2px;
                        }
                    }
                    .projects-title {
                        font-weight: bold;
                        color: #1A1A1A;
                        font-size: 16px;
                        line-height: 19px;
                        padding: 10px 12px 10px;
                        height: 30px;
                        text-align: left;
                        word-break: break-all;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                    .projects-msg{
                        display: flex;
                        padding: 0 12px 10px;
                        color:#5e6d82;
                        align-items: center;
                        justify-content: space-between;
                        >span{
                            font-size:12px;
                            >i{
                                margin-right:5px;
                            }
                        }    
                    }

                    .detail >span:nth-child(2){
                        box-shadow: 0px 1px 4px 0px rgba(34, 70, 255, 0.39);
                        background: #6FA8FF;
                        color:#FFFFFF;
                        border-radius: 4px;
                        padding: 5px;
                    }
                    .detail >span:nth-child(1){
                        box-shadow: 0px 1px 4px 0px rgba(34, 70, 255, 0.39);
                        background: #6FA8FF;
                        color:#FFFFFF;
                        border-radius: 4px;
                        padding: 5px;
                    }
                    .detail >span:nth-child(3){
                        display: flex;
                        align-items: center;
                    }
                    .detail >span:nth-child(4){
                        display: flex;
                        align-items: center;
                    }
                    .user>span:nth-child(1){
                        font-size: 16px;
                        color: #1A1A1A;
                        font-family: Microsoft YaHei;
                        max-width: 150px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .user>span:nth-child(2){
                        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
                        background: #F2F2F2;
                        color: #999999;
                        border-radius: 4px;
                        padding: 5px;
                        display: flex;
                        align-items: center;
                        // max-width: 48px;
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                        // white-space: nowrap;
                    >span{
                         max-width: 48px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    }
                }
            }
            .no-data{
                display: flex;
                justify-content: center;
                .no-data-img{
                    background-image: url('~@/assets/images/Empty.jpg');
                    background-size: 1595px 3004px;
                    background-position: -1047px -204px;
                    width: 242px;
                    height: 228px;
                    display: inline-block;
                }
                
            }
        }
    }
</style>