<template>
    <div class='empty'>
        <el-empty>
            <template #image>
                <img class='empty-img' src="empty.svg" alt="">
            </template>
            <template #description>
                <span class='empty-description'>暂无数据</span>
            </template>
        </el-empty>
    </div>
</template>

<script>
export default {
    name: 'empty',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="less" scoped>
    .empty{
        .empty-img{
            height:200px;
        }
        .empty-description{
            font-size:14px;
            color:#909399;
        }
    }
</style>