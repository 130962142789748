<template>
    <div class="download-ui">
        <template v-for="item in config.children" :key="item.key">
            <component :is="item.component" ></component>
        </template>
    </div>
</template>

<script>
    import _ from '@/views/Home/config';
    import components from '@/views/Home/Download/index';
    export default {
        data (){
            return {
                config : {},
            }
        },
        created (){
            this.config = _['download'];
        },
        components : {
            ...components,
        }
    }
</script>

<style scoped lang="less">
    .news-ui{
        // height:600px;
    }
</style>