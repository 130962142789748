<template>
    <div class="expdata-details">
        <PageHeader :goMethod='goMethod'>
            <template #header>
                <div class="expdata-header">
                    <div class="expdata-title">
                        {{data.name || '资料名称'}}
                    </div>
                    <div class="expdata-user">
                        <span>作者: {{data.realname}}</span>
                        <span>出处: {{data.place || "实验申报视频"}}</span>
                        <span>发布于: {{data.createtime}}</span>
                        <span @click="handleLike('点赞',data)">
                            <i style="color:red" v-if="data.like" class="xxl-liked"></i>
                            <i style="color:#BBBBBB" v-else class="xxl-like"></i>
                            <span>{{data.like_size}}</span>
                        </span>
                        <span @click="handleLike('收藏',data)">
                            <i style="color:#FFC12E " v-if="data.collect" class="xxl-collected"></i>
                            <i style="color:#BBBBBB" v-else class="xxl-collect"></i>
                            <span>{{data.collect_size}}</span>
                        </span>
                    </div>
                </div>
            </template>
            <template #content>
                <div class="expdata-content" v-html="data.content">
                </div>
            </template>
        </PageHeader>
    </div>
</template>
<script>
import PageHeader from '@/components/course/PageHeader.vue';
import common from '@/utils/common';
import axios from '@/utils/axios';
import server from '@/service/server';
import checker from '@/utils/checker';

export default{
    data(){
        return{

        }
    },
    methods:{
        goMethod (){
            this.$emit('toBack');
        },
        
        handleLike(type,data){
            switch(type){
                case '点赞':
                    if(data.like){
                        let request = {
                            targettable: 'expnotes',
                            targetautoid: data.autoid,
                            delete_data: true,
                            expautoid: this.expautoid,
                            like: 1
                        }
                        this.postCancel(request,data,type);
                    }else{
                        let request = {
                            targettable: 'expnotes',
                            targetautoid: data.autoid,
                            like: 1,
                            expautoid: this.expautoid
                        }
                        this.postCollectstar(request,data,type);
                    }
                    break;
                case '收藏':
                    if(data.collect){
                        let request = {
                            targettable: 'expnotes',
                            targetautoid: data.autoid,
                            delete_data: true,
                            expautoid: this.expautoid,
                            collect: 1
                        }
                        this.postCancel(request,data,type);
                    }else{
                        let request = {
                            targettable: 'expnotes',
                            targetautoid: data.autoid,
                            collect: 1,
                            expautoid: this.expautoid
                        }
                        this.postCollectstar(request,data,type);
                    }
                    break;
            }
        },
        postCollectstar(request,data,type){
            axios.post(server.collectstar, request).then(result=>{
                if(result.status && result.data && result.data.result && result.data.result.length){
                    switch(type){
                        case '点赞':
                            this.getLike(data);
                            break;
                        case '收藏':
                            this.getCollect(data);
                            break;
                    }
                }
            })
        },
        postCancel(request,data,type){
            axios.post(server.collectstar, request).then(result=>{
                if(result.status && result.data){
                    switch(type){
                        case '点赞':
                            this.getLike(data);
                            break;
                        case '收藏':
                            this.getCollect(data);
                            break;
                    }
                }
            })
        },
        getLike(data){
            let request = {
                targettable: 'expnotes',
                expautoid: this.expautoid,
                targetautoid: data.autoid,
                count: true,
                like: 1
            }
            axios.get(server.collectstar + checker.objTurnParams(request)).then(result=>{
                if(result.status){
                    this.data.like = result.data.like;
                    this.data.like_size = Number(result.data.count);
                }
            })
        },
        getCollect(data){
            let request = {
                targettable: 'expnotes',
                expautoid: this.expautoid,
                targetautoid: data.autoid,
                count: true,
                collect: 1
            }
            axios.get(server.collectstar + checker.objTurnParams(request)).then(result=>{
                if(result.status){
                    this.data.collect = result.data.collect;
                    this.data.collect_size = Number(result.data.count);
                }
            })
        },
    },
    components:{
        PageHeader,
    },
    props:{
        data : {
            type : Object,
            default : ()=>{return {}}
        },
        expautoid : {
            type : [String,Number],
            default : ()=>{return ''}
        },
    }
}
</script>

<style lang="less" scoped>
    .expdata-details{
        width: 100%;
        .expdata-header{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            .expdata-title{
                // width: 96px;
                height: 24px;
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
            }
            .expdata-user{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px;
                >span{
                    height: 12px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    margin: 0 10px;
                    >i{
                        cursor: pointer;
                    }
                }
            }
        }
        .expdata-content{
            height: 600px;
            text-indent: 2em;
            margin-top: 30px;
            line-height: 25px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            overflow: auto;
            word-break: break-all;
            
           padding:0 15px;
            
        }
    }
</style>
