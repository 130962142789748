<template>
    <div class="pagination-ui">
        <el-pagination 
        :page-size='pageSize' 
        @current-change='currentChange' 
        :small="small"
        :background="background"
        layout="prev, pager, next"
        :hide-on-single-page="hideOnSinglePage"
        :current-page='currentPage'
        :total="total"></el-pagination>
    </div>
</template>

<script>
import _ from 'lodash';
export default {
    props : {
        total : {
            type : Number,
            default : 0,
        },
        pageSize : {
            type : Number,
            default : 10,
        },
        currentPage : {
            type : Number,
            default : 1,
        },
        hideOnSinglePage: {
            type: Boolean,
            default: true
        },
        small: {
            type: Boolean,
            default: false
        },
        background: {
            type: Boolean,
            default: true
        }
    },
    mounted (){
        //this.monitorActive(1);
    },
    methods : {
        monitorActive : _.throttle(function(value) {
            this.$nextTick(()=>{
                let location = 0,
                elPager = document.querySelectorAll('.el-pager>li'),
                count = 0,
                total = Math.ceil(this.total/this.pageSize);
                if(value < 5){//总数=8
                    count = 8;
                    location = value;
                }else if((total - value) <= 3 && value >= (total-3) ){//总数=8
                    location = 8-(total - value);
                    count = 8;
                }else {//总数=9
                    location = 5;
                    count = 9;
                }

                for( let i = 0 ; i < count ; i++ ){
                    //if(i > elPager.length-1)break;
                    if(i===0 && location > 2 ){
                        this.delClassName(elPager[i],'radius-left')
                        this.addClassName(elPager[i],'radius-right');
                    }else if((i + 1) === (location-1) && i > 0){
                        this.delClassName(elPager[i],'radius-right')
                        this.addClassName(elPager[i],'radius-left');
                    }else if((i + 1) === (location + 1) && i > 0 && location < (count-1)){
                        this.delClassName(elPager[i],'radius-left')
                        this.addClassName(elPager[i],'radius-right');
                    }else if(i === (count-1) && location < (count-1)){
                        this.delClassName(elPager[i],'radius-right');
                        this.addClassName(elPager[i],'radius-left');
                    }else if(i !== (location - 1) && i > 0 && i < (count-1)){
                        this.addClassName(elPager[i],'radius-left radius-right');
                    }
                }
            })
        },100),
        delClassName : function(event,className){
            let names = names && event.className || '',
            classArr = names.split(' '),
            delIdx = classArr.findIndex(name => name === className);
            if(delIdx>0){
                classArr.splice(delIdx,1);
            }
            if(event)event.className = classArr.join(' ');
        },
        addClassName : function(event,className){
            let names = names && event.className || '',
            delIdx = -1,
            cache = className.split(' '),
            classArr = names.split(' ');
            cache.map(item=>{
                delIdx = classArr.findIndex(name=>name === item);
                if(delIdx < 0){
                    classArr.push(item);
                }
            })
            if(event)event.className = classArr.join(' ').trim();
        },
        currentChange : function(value){
            //this.monitorActive(value);
            this.$emit('pageChange',value);
        },
    },
}
</script>
<style scoped lang='less'>

    :deep(.btn-prev){
        border: none;
        color: #8a8a8a !important;
        //background: none !important;
        padding:0 5px !important;
    }
    :deep(.btn-next){
        border: none;
        color: #8a8a8a !important;
        //background: none !important;
        padding:0 5px !important;
    }
    :deep(.icon){
        width: 12px;
        height: 12px;
    }
    .pagination-ui{
      display: inline-block;
    }
    // /deep/ .btn-next:hover{
    //     background-color: rgba(199,201,204,1) !important;
    //     color: #fff !important;
    // }
    :deep(.btn-prev>i) {
        display:inline-block !important;
    }
    :deep(.btn-next>i){
        display:inline-block !important;
    }
    :deep(.btn-prev):hover{
        //background-color: rgba(199,201,204,1) !important;
        //color: #fff !important;
    }
    // /deep/ .number{
    //     border: none;
    //     color: #8a8a8a !important;
    //     /* background: none !important; */
    //     padding:0 5px !important;
    //     margin:0 !important;
    //     border-radius: 10px !important;
    // }
    // /deep/ .radius-right{
    //     border-top-right-radius: 0px !important;
    //     border-bottom-right-radius: 0px !important;
    // }
    // /deep/ .radius-left{
    //     border-top-left-radius: 0px !important;
    //     border-bottom-left-radius: 0px !important;
    // }

   
    // /deep/ .number:hover{
    //     background-color: rgba(199,201,204,1) !important;
    //     color: #fff !important;
    // }
    // /deep/ .el-icon{
    //     display: inline-block !important;
    // }
    // /deep/ .el-icon.more{
    //     margin:0 !important;
    //     border-radius: 10px !important;
    // }

    //  /deep/ .more.radius-right{
    //     border-top-right-radius: 0px !important;
    //     border-bottom-right-radius: 0px !important;
    // }
    // /deep/ .more.radius-left{
    //     border-top-left-radius: 0px !important;
    //     border-bottom-left-radius: 0px !important;
    // }
    // /deep/ .more.radius-left:hover{
    //     border-top-left-radius: 0px !important;
    //     border-bottom-left-radius: 0px !important;
    // }
    // /deep/ .el-icon.more:hover{
    //     background-color: rgba(199,201,204,1) !important;
    //     color: #fff !important;
    // }
    // /deep/ .active{
    //     background-color: rgba(199,201,204,1) !important;
    //     color: #fff !important;
    //     margin:0 5px !important;
    // }
    // /deep/ .active ~ li {
    //     background-color:green !important;
    // }
    // /deep/ .el-pager li:nth-child(-n +2){
    //     background-color:yellow !important;
    // }

</style>