<template>
  <!-- 提问答疑 -->
  <div class="echars-ui">
    <div class="echars-modules">
      <PageItems>
        <template #title>
          <div class="module-title">
            回复率
            <span class="module-english">Response rate</span>
          </div>
        </template>
        <template #content>
          <div class="module-box">
            <span>回复率</span>
            <span>{{ responseRate }}</span>
          </div>
          <div class="module-details">
            <div class="details-item" v-for="item in adata" :key="item.key">
              <span>{{ item.label }}</span>
              <span>{{ item.value }}</span>
            </div>
            <div class="details-item" v-for="item in adata2" :key="item.key">
              <span>{{ item.label }}</span>
              <span>{{ item.value }}</span>
            </div>
          </div>
        </template>
      </PageItems>
    </div>
    <div class="echars-main">
        <TabsNew  v-show="viewState.type === '主页'" :active="active" :menuData="menuData" @toClick="changeTab">
          <div class="echars-items">
            <div class="echars-item" v-for="item in dataList" :key="item.key">
              <span>{{ item.title }}</span>
              <div class="echars-bar">
                <span>{{ item.name }} 发布于 {{ item.time }} 共{{ item.childsize }}条回复</span>
                <span @click="handleAnswer(item)">详情</span>
              </div>
            </div>
          </div>
          <div class="echars-pagin">
            <Pagination
              :total="paging.total || 0"
              :pageSize="paging.pageSize || 10"
              :currentPage="paging.currentPage || 1"
              @pageChange="pageChange"
            />
          </div>
          <template #bar>
            <span class="echars-add" @click="handleAdd">我要提问</span>
          </template>
        </TabsNew>
        <div v-show="viewState.type === '详情'" class="answer-details">
          <Details parentField="issueautoid" @toBack="viewState.type = '主页'" @updateRate="getCount();getIssueData();" :expautoid="expautoid" :data="viewState.data"></Details>
        </div>
    </div>
    <MessageBoxs
      :title="popupData.title || '弹窗标题'"
      :style="{ width: '800px' }"
      :state="popupData.state"
      @close="closePopup"
    >
      <template v-if="popupData.title === '提问'">
        <div class="problem-ask">
          <div class="ask-item">
            <span>标题：</span>
            <span
              ><el-input
                maxlength="100"
                :show-word-limit="true"
                size="small"
                :input-style="titleWarnStyle"
                v-model="formData.title"
                placeholder="请输入问题标题"
            /></span>
          </div>
          <div class="ask-item">
            <span>内容：</span>
            <div class="ask-btn">
              <span>
                <Uploads
                  v-model:data="mediaData"
                  v-model:result="formData.faces"
                  modules="btn"
                  btnPosition="bottom"
                  class="resources-upload"
                  multiples
                  :accepts="['jpg', 'png', 'mp4']"
                  ref="upload"
                  @uploadBefore="uploadBefore"
                  @uploadAfter="uploadAfter"
                  @uploadChange="uploadResourceAction"
                  :uploadData="mediaResource"
                >
                  <template #btnName>上传图片/视频</template>
                  <template #上传提示>图片和视频数量不得超过10个 图片大小不得超过5M，视频大小不得超过50M</template>
                </Uploads>
              </span>
            </div>
            <span
              ><el-input
                maxlength="300"
                rows="8"
                resize="none"
                type="textarea"
                :show-word-limit="true"
                size="small"
                :input-style="contentWarnStyle"
                v-model="formData.content"
                placeholder="请输入内容"
            /></span>
          </div>
        </div>
      </template>
      <template #footer>
        <template v-if="popupData.title === '提问'">
          <Buttons @click="closePopup" type="info">取消</Buttons>
          <Buttons @click="handleUpload" type="primary">提交</Buttons>
        </template>
        <template v-if="popupData.title === '上传图片/视频'">
          <Buttons @click="closePopup" type="info">返回</Buttons>
          <Buttons type="primary">保存</Buttons>
        </template>
      </template>
    </MessageBoxs>
  </div>
</template>

<script>
import PageItems from "@/components/course/PageItems.vue";
import TabsNew from "@/components/business/Tabs/index.vue";
import Details from "./answerDetails.vue";
import Pagination from "@/components/course/Pagination.vue";
import MessageBoxs from "@/components/course/MessageBoxs.vue";
import Buttons from "@/components/course/Buttons.vue";
import Uploads from "@/components/common/Uploads/Uploads.vue";
import axios from "@/utils/axios";
import server from "@/service/server";
import checker from "@/utils/checker";
import { ElMessage } from 'element-plus'
export default {
  data() {
    return {
      responseRate: "0.00%",
      expautoid: "",
      count: {
        total: 0,
        pendingReply: 0,
        replied: 0,
      },
      uploadUrl: [],
      adata: [
        { key: "001", label: "提问总数", value: 0 },
        { key: "002", label: "等待答案", value: 0 },
        // {key : '003',label:'总回复数',value : '0'},
      ],
      adata2: [{ key: "001", label: "总回复数", value: 0 }],
      popupData: {},
      active: "",
      pitchActive: "",
      extraParam: {},
      menuData: [
        { key: "001", title: "全部问题", type: "全部问题", value: 0, extraParam: {} },
        { key: "002", title: "待回复问题", type: "待回复问题", value: 0, extraParam: {has_answer: false} },
        { key: "003", title: "已回复问题", type: "已回复问题", value: 0, extraParam: {has_answer: true} },
      ],
      mediaType: {
          img: ["jpg","png"],
          video: ["mp4"]
      },
      dataList: [],
      mediaData: [], //上传图片文件
      mediaResource: {
        //上传图片文件
        url : server.upload+'?type=image',
        data: {},
        postBack: (data) => {
         return {
                  profile:true, name: data.name 
                };
        },
        getBack: (data, type) => {
       
        },
      },
      formData: {
          title: "",
          content: ""
      },
      titleWarnStyle: "",
      contentWarnStyle: "",
      warnStyle: "border-color: red",
      paging: {
        total: 0,
        pageSize: 10,
        currentPage: 1,
      },
      viewState: {
        type: "主页",
      },
    };
  },
  beforeMount() {
    // let storeParams = common.getStorParams({ path: "/expcore" });
    // if (!storeParams) storeParams = common.getStorParams(this);
    this.expautoid = this.autoid;
  },
  mounted() {
    // this.getCount();
  },
  watch: {
      "count.total": "setPage",
      'formData.faces' : 'monitorFaces'
  },
  methods: {
    monitorFaces (faces){
      // console.log(faces);
    },
    pageChange(currentPage) {
        this.paging.currentPage = currentPage;
        this.getIssueData();
    },
    uploadChange() {},
    uploadResourceAction(data) {
        if(data && data instanceof Array && data.length && data.slice(-1)[0].url) {
            this.uploadUrl.push(data.slice(-1)[0].url);
        }
    },
    uploadAfter (data){
      switch(data.data.type){
        case 'image/png':
          if(data.data.size / 1024 / 1024 > 5){
              ElMessage.warning("图片大小不得超过5M");
              data.cb(false);
          }
          break;
        case 'image/jpeg':
          if(data.data.size / 1024 / 1024 > 5){
              ElMessage.warning("图片大小不得超过5M");
              data.cb(false);
          }
          break;
        case 'video/mp4':
          if(data.data.size / 1024 / 1024 > 50){
              ElMessage.warning("视频大小不得超过50M");
              data.cb(false);
          }
          break;
        default :
          ElMessage.warning("不支持该类型");
          data.cb(false);
          break;
      }
    },
    uploadBefore(cb) {
      const {mediaData} = this;
       if (mediaData && mediaData.length >= 10) {
        ElMessage.warning("图片上传已达上限！");
        cb(false);
      } 
    },
    getResponseRate() {
      //获取回复率
      let request = {
        expautoid: this.expautoid,
        has_answer_rate: "",
        get_all: "",
      };
      axios
        .get(server.uiissue + checker.objTurnParams(request))
        .then((result) => {
          if (result && result.status) {
            this.responseRate = result.data;
          }
        });
    },
    getResponseNum() {
      //获取总回复数
      let request = {
        expautoid: this.expautoid,
        issue_answer: "",
        count: true,
        get_all: "",
      };
      axios
        .get(server.answer + checker.objTurnParams(request))
        .then((result) => {
          if (result.status && result.data) {
            this.adata2.forEach((item) => {
              if (item.label == "总回复数") {
                item.value = Number(result.data) || 0;
              }
            });
          }
        });
    },
    setAdata(key, value) {
      this.adata.forEach((item) => {
        if (item.key == key) {
          item.value = Number(value) || 0;
          return true;
        }
      });
      this.menuData.forEach((item) => {
        if (item.key == key) {
          item.value = Number(value) || 0;
          return true;
        }
      });
    },
    setCount(request, countKey, adataKey) {
      axios
        .get(server.uiissue + checker.objTurnParams(request))
        .then((result) => {
          if (result && result.status) {
            this.count[countKey] = result.data;
            this.setAdata(adataKey, result.data);
          }
        });
    },
    getCount() {
        this.getResponseRate(); //获取回复率
        this.getResponseNum(); //获取总回复数
        //提问总数/全部问题
        this.setCount({ expautoid: this.expautoid, count: true, get_all: "" }, "", "001");
        //等待答案/待回复问题
        this.setCount({ expautoid: this.expautoid, count: true, get_all: "", has_answer: false }, "pendingReply", "002");
        // 已回复问题
        this.setCount({ expautoid: this.expautoid, count: true, get_all: "", has_answer: true }, "replied", "003");
    },
    getIssueData() {
        let request = {
          expautoid: this.expautoid, 
          page: this.paging.currentPage, 
          per_page: this.paging.pageSize, 
          get_all: "", 
          _order_column:"createtime",
          _order_style:"desc",
          ...this.extraParam
        },dataList = [];
        axios.get(server.uiissue + checker.objTurnParams(request)).then((result) => {
            if (result.status && result.data && result.data.result) {
              result.data.result.forEach((item) => {
                let itemData = {
                  key: item.autoid,
                  title: item.name,
                  content: item.content,
                  name: item.user_data ? item.user_data.realname || "" : "",
                  iconUrl: item.user_data ? item.user_data.face || require("@/assets/images/user_logo.png") : "",
                  time: item.createtime,
                  childsize: item.answer_size || 0,
                  imgs: [],
                  videos: []
                }
                if(item.evalresource_data && item.evalresource_data.filepath) {
                  item.evalresource_data.filepath.split(",").forEach((path, fIndex) => {
                    let ext = path.split(".").pop();
                    if(this.mediaType.img.indexOf(ext) != -1) {
                      itemData.imgs.push({
                          "url": path,
                          "key": fIndex + 1
                      });
                    } else if(this.mediaType.video.indexOf(ext) != -1) {
                      itemData.videos.push({
                          "url": path,
                          "key": fIndex + 1
                      });
                    }
                  });
                }
                dataList.push(itemData);
              });
              this.dataList = dataList;
              this.getCount();
            }
        });
    },
    setPage(count) {
        this.paging.total = Number(count) || 0;
    },
    changeTab(item) {
      this.pitchActive = item.key;
      this.extraParam = item.extraParam;
      this.paging.currentPage = 1;
      this.setCount({ expautoid: this.expautoid, count: true, get_all: "", ...this.extraParam }, "total");
      this.getIssueData();
    },
    handleAnswer(data) {
      this.viewState = {
        type: '详情',
        data: data,
      };
    },
    closePopup() {
      const { popupData } = this;
      switch (popupData.title) {
        case "上传图片/视频":
          this.popupData = {
            ...popupData,
            title: "提问",
          };
          break;
        default:
          this.popupData = {};
          this.mediaData = [];
          break;
      }
    },
    handleUpload() {
      const {formData} = this;
      let titleEmpty = (this.formData.title == ""),
          contentEmpty = (this.formData.content == "");

      // 内容为空时，警告
      if(titleEmpty) this.titleWarnStyle = this.warnStyle;
      else this.titleWarnStyle = "";
      if(contentEmpty) this.contentWarnStyle = this.warnStyle;
      else this.contentWarnStyle = "";

      if(titleEmpty || contentEmpty) return false;

      let filePath = [];
      if(Array.isArray(formData.faces)){
        formData.faces.map(item=>{
          filePath.push(item.url)
        })
      }
      let request = { expautoid: this.expautoid, name: this.formData.title, content: this.formData.content, filepath_str: filePath.join(',') };
      axios.post(server.uiissue, request).then(result => {
        if (result.status) {
          this.getIssueData();
          this.getCount();
        }
      });
      //  this.pitchActive ="001";
      //  this.extraParam ={};
      this.formData.title = "";
      this.formData.content = "";
      this.formData.faces = '';
      this.uploadUrl = [];
      this.$refs.upload.fileList = [];
      
      this.closePopup();
    },
    handleAdd() {
      this.popupData = {
        title: "提问",
        state: true,
      };
    },
  },
  components: {
    PageItems,
    TabsNew,
    Details,
    Pagination,
    MessageBoxs,
    Buttons,
    Uploads,
  },
  props: {
      autoid: {
        type: [Number, String],
        default: "",
      },
  },
};
</script>

<style scoped lang="less">
.problem-ask {
  width: 100%;
  .ask-item {
    display: flex;
    flex-direction: column;
    text-align: left;
    > span:nth-child(1) {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1a1a1a;
      padding: 10px 0;
    }
    // >span:nth-child(2){

    // }
    .ask-btn {
      padding-bottom: 10px;
      display: flex;
      // >span{

      // }
    }
  }
}

.echars-ui {
  width: 100%;
  display: flex;
  background-color: #f2f2f2;
  .echars-modules {
    width: 22%;
    flex: 0 0 auto;
    margin-right: 10px;
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    .module-title {
      display: flex;
      flex-direction: column;
      font-weight: bold;
      color: #1a1a1a;
      font-size: 18px;
      font-family: Microsoft YaHei;
      text-align: left;
      .module-english {
        font-weight: 400;
        color: #b6b6b6;
        font-size: 14px;
        font-family: Microsoft YaHei;
        display: flex;
        flex-direction: column;

        margin-top: 5px;
        &::after {
          content: "";
          display: inline-block;
          width: 72px;
          height: 3px;
          background-color: #1e5fc2;
          margin-top: 5px;
        }
      }
    }
    .module-details {
      position: relative;
      padding: 10px 20px;
      margin-top: 10px;
      &::before {
        position: absolute;
        top: 0;
        left: 20px;
        right: 20px;
        height: 1px;
        background-color: #c9c9c9;
        content: "";
      }
      .details-item {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        > span:nth-child(1) {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin-right: auto;
        }
        > span:nth-child(2) {
          width: 70px;
          height: 23px;
          line-height: 23px;
          text-align: center;
          border: 1px solid #6fa8ff;
          border-radius: 4px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          margin-left: auto;
        }
      }
    }

    .module-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      > span:nth-child(1) {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        display: inline-block;
      }
      > span:nth-child(2) {
        width: 115px;
        height: 70px;
        background: #2974ea;
        border: 2px solid #6fa8ff;
        box-shadow: 0px 2px 4px 0px rgba(0, 101, 255, 0.2);
        border-radius: 10px;
        line-height: 70px;
        text-align: center;
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        margin: 10px 0;
      }
    }
  }
  .echars-main {
    width: 77%;
    margin-top: 10px;
    flex: 1 1 auto;
    background-color: #ffffff;

    .echars-items {
      display: flex;
      flex-direction: column;
      .echars-item {
        text-align: left;
        border-bottom: 1px solid #f6f6f6;
        padding: 0 10px;
        &:last-child {
          border-bottom: none;
        }
        > span {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 28px;
          padding: 10px 0;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: -webkit-fill-available;
        }
        .echars-bar {
          display: flex;
          align-items: center;
          padding: 10px 0;
          > span {
            font-family: Microsoft YaHei;
            font-weight: 400;
          }
          > span:nth-child(1) {
            font-size: 14px;
            color: #999999;
            margin-right: auto;
          }
          > span:nth-child(2) {
            margin-left: auto;
            font-size: 12px;
            color: #727272;
            cursor: pointer;
            &:hover {
              color: rgba(41, 116, 234, 1);
            }
          }
        }
      }
    }
    .echars-pagin {
      padding: 20px 0;
      text-align: center;
    }
    .echars-add {
      width: 100px;
      height: 33px;
      background: #4486cc;
      box-shadow: 0px 1px 4px 0px rgba(3, 71, 144, 0.39);
      border-radius: 4px;
      text-align: center;
      line-height: 33px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
      -moz-user-select: none; /*火狐*/
      -webkit-user-select: none; /*webkit浏览器*/
      -ms-user-select: none; /*IE10*/
      -khtml-user-select: none; /*早期浏览器*/
      user-select: none;
    }
  }
}
</style>