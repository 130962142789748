<template>
  <div class="note-ui">
    <!--实验笔记-->
    <TabsNew :active="active" :menuData="menuData" @toClick="changeTab">
      <template #default="{ pitch: data }">
        <div class="note-main">
          <div class="note-list">
            <div v-show="data.title === '我的笔记'" >
              <div class="note-item" v-for="(item,index) in dataList" :key="item.key"  @click="modify_summary(item,index)" >
                <span :class="item.pic_type=='video'? 'note-video' : item.pic_type=='image'?'note-pic' :'note-doc' "> </span>
                <!-- <img :src="item.imgUrl" alt="" /> -->
                <div :class="currentIndex == item.autoid ? 'item-right-active':'item-right'">
                  <span style="font-weight:bolder;">{{ item.name }}<span style="color:light-grey; font-weight:normal">笔记</span></span>
                 
                  <!-- <span v-html=" item.content">
                  
                  </span> -->
                  <div class="item-footer">
                    <span style="margin-right:10px;">
                      <el-icon><clock /></el-icon>
                      更新于{{item.modifiedtime}}
                      
                      <!-- 更新于2020-12-20 19:30 -->
                    </span>
                    <div class="item-options" v-if="data.title === '我的笔记'"  >
                      <span class="item-label" @click="handleClick('继续学习',item)">继续学习</span>
                      <span class="item-center">|</span>
                          <!-- <el-popconfirm
                        title="这是一段内容确定删除吗？">
                        <el-button slot="reference">删除</el-button>
                        </el-popconfirm> -->
                      <span class="item-label" @click="delete_summary(item)">删除</span>
                    </div>
                    <!-- <div class="actions-main" v-if="data.title === '公开笔记'">
                      <span class="actions-btn help"
                        ><i class="actions-btn-icon"></i>2000</span
                      >
                      <span class="actions-btn collect"
                        ><i class="actions-btn-icon"></i>2000</span
                      >
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="note-pagin">
                <Pagination
                  v-if="dataList.length"
                  :total="paging1.total || 0"
                  :pageSize="paging1.pageSize || 4"
                  :currentPage="paging1.currentPage || 1"
                  @pageChange="pageChange1"
                  :hideOnSinglePage="false"
                />
              </div>
            </div>
            <div v-show="data.title === '公开笔记'"> 
                <div class="note-item" v-for="item in passdataList" :key="item.key" @click="showContent(item)" >
                <span :class="item.pic_type=='video'? 'note-video' : item.pic_type=='image'?'note-pic' :'note-doc' "> </span>
                <!-- <img :src="item.imgUrl" alt="" /> -->
                <div :class="currentIndex == item.autoid ? 'item-right-active':'item-right'" >
                  <!-- <p class="level">优秀</p> -->
                  <div class="level">
                    
                      <p :class="item.notelevel_data == '优秀'? 'class1' :item.notelevel_data == '良好' ? 'class2' : 'class3'">{{item.notelevel_data}}</p> 
                      <span>{{ item.resausename || '审核中' }} 笔记</span>
                  </div>
                
                  <!-- <span v-html=" item.content">
                  
                  </span> -->
                  <div class="item-footer">
                    <span style="margin-right:10px;">
                      <el-icon><clock /></el-icon>
                      更新于{{item.modifiedtime}}
                      
                      <!-- 更新于2020-12-20 19:30 -->
                    </span>
                    <div class="item-options" v-if="data.title === '我的笔记'" >
                      <span class="item-label" @click="handleClick('继续学习',item)">继续学习</span>
                      <span class="item-center">|</span>
                      <span class="item-label" @click="delete_summary(item)">删除</span>
                    </div>
                    <div class="actions-main" v-if="data.title === '公开笔记'">
                      <span class="actions-btn help" v-if="item.notelevel_data != '审核中'"> 
                        <i class="actions-btn-icon" @click="handleClick('点赞',item)">
                          <img v-if="!item.like" src="@/assets/images/help.png" alt="">
                          <img v-else src="@/assets/images/help_red.png" alt="">
                        </i>
                        {{item.like_size || 0}}
                        </span>
                      <span class="actions-btn collect" v-if="item.notelevel_data != '审核中'">
                        <i class="actions-btn-icon" @click="handleClick('收藏',item)">
                          <img v-if="!item.collect" src="@/assets/images/collect.png" alt="">
                          <img v-else src="@/assets/images/collect_yellow.png" alt="">
                        </i>
                        {{item.collect_size || 0}}
                      </span>
                          <span class="actions-btn delete"  v-if="item.notelevel_data !='审核中'" style="margin-left:15px">
                        <i class="actions-btn-icon" @click="handleClick('删除公开',item)">
                          <img src="@/assets/images/footer_del.png" alt=""/>
                        </i>
                      </span>
                          
                    </div>
                  </div>
                </div>
              </div>
          
              <div class="note-pagin" >
                <Pagination
                  v-if="passdataList.length"
                  :total="paging2.total || 0"
                  :pageSize="paging2.pageSize || 4"
                  :currentPage="paging2.currentPage || 1"
                  @pageChange="pageChange2"
                  :hideOnSinglePage="false"
                />
              </div>
            </div>
          </div>
          <template v-if="data.title === '我的笔记'">
            <div class="note-rich">
               <p v-show="editData.tipShow" style="margin-bottom:12px">当前处于审核状态，不可编辑哦!</p>
              <div class="rich-main">
                  <WangEditor
                    @titlename="getMessage"
                    theme="white"
                    ref="wangEditor"
                    :customButton="customButton"
                    toolLocation="top"
                    class="wang-editor"
                  />
                
              </div>
              <div class="rich-options">
                <span><Buttons type="blue" :disabled="editData.buttontstate" plain @click="submit_summary">公开笔记</Buttons></span>
                <span><Buttons type="blue" :disabled="editData.buttontstate"  @click="contentHandle(editData.buttonType)">{{editData.buttonType}}</Buttons></span>
              </div>
              <!-- <div class=""></div> -->
            </div>
          </template>
          <template v-if="data.title === '公开笔记'">
            <div class="note-rich public">
                 <span v-html="currentShowData.content"></span>
            </div>
          </template>
        </div>
      </template>
    </TabsNew>

    <MessageBoxs
      :style="{ width: '100px'}"
      :title="popupState.title || '弹窗标题'"
      :state="popupState.state"
       @close="closePopup(popupData, '取消')"
    >
      <template v-if="popupState.title === '新建笔记'">
        <div class="record-popup" style="margin: 15px 0;">
          <!-- <p style="padding:20px 0px 20px 0px;">关联资料：</p> -->
          <el-form >
            <el-form-item label="关联资料：">
              <el-select  v-model="editData.value" value-key="autoid" clearable  @change="changeSelect" >
                <el-option
                  v-for="item in editData.resourseList"
                  :key="item.autoid"
                  :label="item.resource_data==' '? item.richtext_data.title : item.resource_data.name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
         <!-- <el-input
            placeholder="请输入内容"
            v-model="editData.currentExpnoteName"
            clearable>
         </el-input> -->
       
        </div> 
        
      </template>
      <template #footer>
        <span>
          <Buttons
            style="border: 1px solid lightgrey; margin-right: 15px"
            type="disabled"
            @click="closePopup(popupData, '取消')"
            >取消</Buttons
          >
        </span>
        <span>
          <Buttons :disabled = !editData.value  type="primary" @click="closePopup(popupData, '确定')"
            >确定</Buttons>
        </span>
      </template>
    </MessageBoxs>

    <MessageBoxs
      :style="{ width: '100px', height: '10%' }"
      :title="popupData.title || '弹窗标题'"
      :state="popupData.state"
       @close="closePopup(popupData, '笔记删除取消')"
    >
      <template v-if="popupData.title === '操作确认'">
        <span style="text-align: center;line-height: 60px;">删除后将无法还原，确认删除本条笔记？</span>
      </template>
      <template #footer>
        <span>
          <Buttons
            style="border: 1px solid lightgrey; margin-right: 15px"
            type="disabled"
            @click="closePopup(popupData, '笔记删除取消')"
            >取消</Buttons>
        </span>
        <span>
          <Buttons type="primary" @click="closePopup(popupData, '笔记删除确定')"
            >确定</Buttons>
        </span>
      </template>
    </MessageBoxs>
  </div>
</template>

<script>
import TabsNew from "@/components/business/Tabs/index.vue";
import Pagination from "@/components/course/Pagination.vue";
import WangEditor from "@/components/course/wangEditor/note_editor.vue";
import Buttons from "@/components/course/Buttons.vue";
import MessageBoxs from "@/components/course/MessageBoxs.vue";
import axios from '@/utils/axios';
import checker from '@/utils/checker';
import server from "@/service/server";
import { ElMessage } from 'element-plus'
import { buttonType } from 'element-plus';
import common from "@/utils/common";

// import { EventBus } from "@/utils/event-bus";

export default {
  props : {
    expInfo : {
      type : Object,
      default : ()=>{return {
        expautoid: null,
      }}
    },
  },
  name: "note",

  data() {
    return {
      countcs:"0",
      editData:{
        value:"", 
        currentSourceId:"",
        buttonType:"保存",
        resourseList:[],
        command:"" ,// 编辑或保存按钮指令
        currentContent:"",
        tipShow:false,
        buttontstate:false,
        buttonSunmitstate:true,
        currentindex:0,
      },
      currentIndex:'',
      currentNovel:{content:""}, // 用于缓存当前选中的笔记信息
      currentDelNovel:{},
      popupData:{},
      popupState: {},
    
      active: "001",
      menuData: [
        { key: "001", title: "我的笔记", value: "" },
        { key: "002", title: "公开笔记", value: "" },
      ],
      dataList: [],
      passdataList:[],
      paging1: {
        total: 0,
        pageSize: 4,
        currentPage: 1,
      },
      paging2: {
        total: 0,
        pageSize: 4,
        currentPage: 1,
      },
      customButton: [
        {
          key: "submit",
          icon: "Plus",
          label: "新建笔记",
          on_click: this.issueWangEditorHandleSubmit,
        },
      ],
      //公开笔记
        currentShowData:{},
    };
  },

  mounted() {
    this.get_summary_list(0);
    // this.get_summary_list_count();
    
    this.get_summary_pass_list();
    // this. get_summary_pass_list_count();
    // this.get_liked_count();
    // this.get_collect_count();
  },

  methods: {
    // get_liked_count(){

    // },
    // get_collect_count(){

    // },
    changeTab(item){
      if(item.key == '001'){
        this.get_summary_list(0);
        // this.get_summary_list_count();
      }
      else if(item.key  == '002'){
        this.get_summary_pass_list();
        // this. get_summary_pass_list_count();
      }
    },
   
    pageChange1(currentPage){
      this.paging1.currentPage = currentPage;
      this.get_summary_list(0);
      // this.get_summary_list_count();
    },

    pageChange2(currentPage){
      this.paging2.currentPage = currentPage;
      this.get_summary_pass_list()
      // this.get_summary_pass_list_count()
    },

    handleClick(type,data){
      switch(type){
        case '点赞':
          if(data.like){
            this.postCancel('点赞',data);
          }else{
            let likeRequest = {
                targettable: 'expnotes',
                targetautoid: data.autoid,
                like: 1,
                expautoid: data.expautoid,
                // delete_data:true,
            }
            this.postCollectstar(likeRequest,data)
          }
          break;
        case '收藏':
          if(data.collect){
            this.postCancel('收藏',data);
            }else{
            let collectRequest = {
                targettable: 'expnotes',
                targetautoid: data.autoid,
                collect: 1,
                expautoid: data.expautoid,
                // delete_data:true,
            }
            this.postCollectstar(collectRequest,data)
          }
          break;
        case '继续学习':
          if(data){
            data.key = data.targetautoid;
            data.resource_data = data.expdatum_data.resource_data;
            common.setStorParams({path:'/lectures'},data);
            this.$router.push('/lectures');
          }
          break;
        case '删除公开':
          this.popupData = {
            state: true,
            title: "操作确认",
          };
          this.currentDelNovel = data;
          this.currentDelNovel.key = '002'
          break;
      }
    },
   // 取消点赞、收藏
    postCancel(type,data){
      let request = {};
      switch(type){
        case '点赞':
          request = {
            targettable: 'expnotes',
            targetautoid: data.autoid,
            delete_data: true,
            expautoid: data.expautoid,
            like: 1,
            delete_data:true
          }
          axios.post(server.collectstar, request).then(result=>{
            if(result.status && result.data){
              this.get_summary_pass_list();
              // this.getLike(cache);
            }
          })
          break;
        case '收藏':
          request = {
            targettable: 'expnotes',
            targetautoid: data.autoid,
            delete_data: true,
            expautoid: data.expautoid,
            collect: 1,
            delete_data:true
          }
          axios.post(server.collectstar, request).then(result=>{
            if(result.status && result.data){
              this.get_summary_pass_list();
            }
          })
          break;
      }
    },
  // 点赞收藏
    postCollectstar(request,data){
      axios.post(server.collectstar, request).then(result=>{
        if(result.status && result.data && result.data.result && result.data.result.length){
          this.get_summary_pass_list();
        }
      })
    },
    showContent(item){
      this.currentShowData = item;
      this.currentIndex = item.autoid;
    },
    delete_summary(item){
      this.popupData = {
        state: true,
        title: "操作确认",
      };
      this.currentDelNovel = item;
      this.currentDelNovel.key = '001';
    },
    delete_summary_action(){
      const {expInfo} = this;
      let data = this.currentDelNovel,
          request = { autoid:data.autoid , delete_data:true,expautoid:expInfo.expautoid};
      axios.post(server.expnote,request).then(result => {
        // this.get_summary_list_count();
        // this. get_summary_pass_list_count();
        if(result.status) {
          ElMessage({
            message: '删除成功',
            type: 'success',
            duration : 3000
          })
          if(this.currentDelNovel.key =='001'){
            this.$refs.wangEditor.setHTML(" ");
            this.editData.tipShow = false;
            this.editData.buttontstate = false;
            this.$refs.wangEditor.handleAbled();
            this.get_summary_list(0);
            // this.get_summary_list_count();
          }
          else if(this.currentDelNovel.key =='002'){
            this.currentShowData.content = '';
            this.get_summary_pass_list();
            // this. get_summary_pass_list_count();
          }
          //this.changeTab(this.currentDelNovel.key)
          // this.get_summary_list(); 
          // this.get_summary_pass_list()
        }
        else{
          this.$message.error("删除失败，稍后再试");
        }
      });  
    },
    submit_summary(){
      const {currentNovel} = this;
      let currentchange = this.$refs.wangEditor.getHTML(); // 当前编辑器的笔记内容
      let origin = currentNovel.content || ''; 
     //  let origin =  this.editData.content; // 已经保存到数据库的笔记内容
      if( currentchange == origin){
        this.submit_summary_action(currentNovel.index);
      }else{
        this.$message.error('提交失败，请先保存');
      }
    },
    
    submit_summary_action(index){
      let summaryid = this.currentNovel.autoid,
      request = { autoid: summaryid, put_data: true, approvetype: 2};
      axios.post(server.expnote,request).then(result => {
        if(result.status && result.data && result.data.result) {
          this.editData.tipShow  = true;
          this.editData.buttontstate = true;
          this.$refs.wangEditor.handleDiable();
          ElMessage({
            message: '提交成功！请耐心等待审核结果',
            type: 'success',
            duration : 3000
          })
          this.get_summary_list(index);
        }
      });
    },
    get_summary_pass_list(){
      let {expInfo} = this,
          request = { 
            expautoid: expInfo.expautoid, 
            owner_get_pass:true, 
            owner:true,
            page: this.paging2.currentPage,
            per_page: this.paging2.pageSize
          };
      axios.get(server.expnote + checker.objTurnParams(request)).then(result => {
        let resultdata = [];
        if(result.status && result.data ) {
          resultdata  = result.data.result;
          resultdata.map(item =>{
           item.pic_type = item.expdatum_data.resource_data.resourcetype;
            if(item.notelevel_data ==""){
              item.notelevel_data = '审核中';
            }
            if(Object.keys(item.expdatum_data).length){
              item.resausename =  item.expdatum_data.resource_data.name ;
            }
            else{
              item.resausename =  "资料已删除" ;
            }
          })
          this.passdataList= resultdata;
          if(this.passdataList.length>0){
            this.currentIndex = this.passdataList[0].autoid;
            this.currentShowData = this.passdataList[0];
          }
        }
        this.get_summary_pass_list_count()
      });
    },

    get_summary_pass_list_count(){
      let {expInfo} = this,
          request = { 
            expautoid: expInfo.expautoid,
            owner_get_pass:true, 
            owner:true,
            count:true
          };
      axios.get(server.expnote + checker.objTurnParams(request)).then(result => {
        if(result.status) {
          this.paging2.total= Number(result.data) || 0;
          this.menuData[1].value = result.data || 0;
        }
      });
    },

    modify_summary(item,index){
      this.currentIndex = item.autoid;
      switch(item.approve_data.approvetype_name){
        case '未提交':
          this.$refs.wangEditor.handleAbled();
          this.editData.buttontstate = false;
          this.editData.currentSourceId= item.targetautoid;
          this.editData.currentSourceName = item.name;
          this.editData.buttonType = "保存";
          this.editData.tipShow= false;
          break;
        case '待审核':
          this.$refs.wangEditor.handleDiable();
          this.editData.tipShow= true;
          this.editData.buttontstate = true;
          break;
      }
      this.currentNovel = item;
      this.currentNovel.index = index;
      this.$refs.wangEditor.setHTML(item.content);
    },
    get_summary_list(index){
      let {expInfo} = this,
        request = { 
          expautoid: expInfo.expautoid, 
          owner:true,
          page: this.paging1.currentPage,
          per_page: this.paging1.pageSize 
        },
        summaryData;
      axios.get(server.expnote + checker.objTurnParams(request)).then(result => {
        if(result.status && result.data && result.data.result) {

          summaryData  = result.data.result;
          summaryData.map(item=>{
            if(item.expdatum_data && item.expdatum_data.resource_data){
              item.pic_type = item.expdatum_data.resource_data.resourcetype;
            }
          })
          this.dataList = summaryData;
          // this.editData.tipShow = false;
          // var indexx = 0;
          // 判断当前列表  空：禁选（编辑+公开笔记+保存按钮），非空：显示第一条数据 的内容以及按钮状态；
          if(summaryData.length){
            // if(index != "" && index != "undefined"){
            //   indexx = index;
            // }else{
            //   indexx = 0;
            // }
            if(summaryData[index].approve_data.approvetype_name =='待审核'){
              this.$refs.wangEditor.handleDiable();
              this.editData.buttontstate = true;
              this.editData.tipShow = true;
            }else{
              this.$refs.wangEditor.handleAbled();
              this.editData.tipShow= false;
              this.editData.buttontstate = false;
            }
            this.$refs.wangEditor.setHTML(summaryData[index].content)
            this.editData.currentSourceId = summaryData[index].targetautoid;
            this.editData.currentSourceName = summaryData[index].name;
            this.currentIndex = summaryData[index].autoid;
            this.currentNovel = summaryData[index];
            this.currentNovel.index = index;   // 一般的保存 是手动出发才有 内容下标记
                                                  // 为防止初始化造成的无下表
          }else{
            this.$refs.wangEditor.setHTML('');
            this.$refs.wangEditor.handleDiable();
            this.editData.buttontstate = true;
            this.editData.tipShow = false;
          }
        }
        this.get_summary_list_count();
      });
    },
    get_summary_list_count(){
      const {expInfo} = this;
      let request = { expautoid: expInfo.expautoid, owner:true, count:true  };
      axios.get(server.expnote + checker.objTurnParams(request)).then(result => {
        if(result.status) {
          this.paging1.total = Number(result.data) || 0;
          this.menuData[0].value = result.data || 0;
        }
      });
    },
    changeSelect(e){
      const {dataList} = this;
      let sourceId = e.autoid, data = dataList;
      for(let i=0;i<data.length;i++){
        if(data[i].targetautoid == sourceId) {
          this.$refs.wangEditor.setHTML(data[i].content);
          break;
        }
        else{
          // this.$refs.wangEditor.setHTML(" ");
        }
      }
      let SourceName = ''
      if(!!e.resource_data){
        SourceName =  e.resource_data.name;
      } else if(!!e.name){
        SourceName = e.name;
      }
      this.editData.currentSourceId = e.autoid;
      //  this.editData.currentSourceId = e.targetautoid;
      this.editData.currentSourceName = SourceName;
    },
    contentHandle(data){
      switch(data){
        case '编辑':
          break;
        case '保存':
          this.contentSave();
          break;
      }
    },
    contentSave(index){
      const {expInfo} = this;
      let targitid = this.editData.currentSourceId,
        notename = this.editData.currentSourceName;
      this.editData.currentContent = this.$refs.wangEditor.getHTML();
      // this.currentNovel.content =  this.$refs.wangEditor.getHTML();
      if(targitid){
        let request = {
          targetautoid: targitid, 
          targettable: "uiexpdatums",
          expautoid: expInfo.expautoid,
          content: this.editData.currentContent,
          name: notename
        };
        axios.post(server.expnote,request).then(result=>{
          if(result && result.status && result.data && result.data.result){
            this.editData.value=" ";
            //  this.editData.state = false;
            ElMessage({
              message: '保存成功！',
              type: 'success',
              duration : 1000
            })
            if(index== 0){
              this.get_summary_list(0);
            }else{
              this.get_summary_list(this.currentNovel.index);
            }
               this.currentNovel=result.data.result[0]; 
          }
          else{
            this.$message.error("添加失败")
          }
        })
      }
      else{
        this.$message.error("添加失败,关联资料有误")
      }
    },
    wangEditorHandleSubmit(data){
     //  this.editData.currentContent = data
    },
    getMessage(value) {
      switch (value) {
        case "新建笔记":
          // this.editData.value = '';
          // this.$refs.wangEditor.setHTML( " ");
          // this.editData.tipShow = false;
          // this.editData.buttontstate = false;
          // this.$refs.wangEditor.handleAbled();
          this.editData.currentSourceId= "";
          this.popupState = {
            state: true,
            title: "新建笔记",
          };
          this.get_resource_list()
          break;
      }
    },
    get_resource_list(){
      const {expInfo} = this;
      let request = { expautoid: expInfo.expautoid,has_not_expnote:true},resourseList = [];
      axios.get(server.uiexpdatum + checker.objTurnParams(request)).then(result => {
        if(result && result.status && result.data && result.data.result){
          let dataList = result.data.result;
          dataList.map(item=>{
            if(item.resource_data && Object.keys(item.resource_data).length){
              resourseList.push(item);
            }
          })
          this.editData.resourseList = resourseList;
        }
      });
    },
    closePopup(data, type) {
      switch (type) {
        case "确定":
          // this.get_resource_list()
          this.editData.value = '';
          this.$refs.wangEditor.setHTML( " ");
          this.editData.tipShow = false;
          this.editData.buttontstate = false;
          this.$refs.wangEditor.handleAbled();
          // this.editData.currentSourceId= " ";
          this.contentSave(0);
          this.editData.buttonType = "保存";
          break;
        case "取消":
          // this.editData.value=" ";
          // this.get_summary_list();
          // this.editData.buttontstate = true;
          // this.$refs.wangEditor.handleDiable();
          // this.editData.state = false;
          break;
        case '笔记删除确定':
          this.delete_summary_action();
          // this.get_summary_list_count();
          // this.get_summary_pass_list_count();
          break;
        case '笔记删除取消':
          this.editData.value=" ";
          break;
      }
      // this.get_summary_list(0);
      // this.linePaging.pageSize = 0;
      this.popupState.state = false;
      this.popupData = false;
    },
    pageChange() {},
    issueWangEditorHandleSubmit() {},
  },
  components: {
    TabsNew,
    Pagination,
    WangEditor,
    Buttons,
    MessageBoxs,
    checker,
    server,
    axios,
    ElMessage,
    common,
  },
};
</script>

<style lang="less" scoped>
// :v-deep .message-box{
//   z-index:100000;
// }
::v-deep .tel-input__suffix {
  position: absolute;
  top: 13px;
}
::v-deep .el-icon.el-select__caret.el-input__icon {
  display: inline-flex;
}
::v-deep .tabs-ui {
  padding-right: 0;
}
::v-deep .message-plate {
  position: absolute;
    z-index:10000000;
  
}
// ::v-deep .editor-plate > i{
//     font-style:italic !important ;
//  }
 :deep( .editor-plate i){
   font-style:italic !important ;
}
.el-icon el-select__caret el-input__icon {
  display: none;
}
.note-ui {
  width: 100%;
  .note-main {
    display: flex;
    background-color: #f6f6f8;
    .note-list {
      flex: 1 1 auto;
      width: 63%;
      background-color: #ffffff;
      .note-item {
        display: flex;
        padding: 10px 0;
        > img {
          width:  190px;
          height: 120px;
          border-radius: 4px;
        }
        .note-doc{
           width: 190px;
          height: 120px;
          border-radius: 4px;
          background-image: url('~@/assets/images/note_doc.png') ;
          background-size: 100% 100%;
        }
        .note-pic{
           width:  190px;
          height: 120px;
          border-radius: 4px;
          background-image: url('~@/assets/images/note_pic.png') ;
          background-size: 100% 100%;
        }
        .note-video{
           width:  190px;
          height: 120px;
          border-radius: 4px;
          background-image: url('~@/assets/images/note_video.png') ;
          background-size: 100% 100%;
        }
        .item-right {
           display: flex;
           flex-direction: column;
           padding: 10px 10px 0;
           background-color: white;
           width: 61%;
          .class1{
               background-color: #39C44D;
               display: inline-block;
               padding: 7px 15px;
               color: white;
               border-radius: 7px;
               margin-right: 10px;
            }
         .class2{
             background-color: #FF5D3D;
             display: inline-block;
             padding: 7px 15px;
             color: white;
             border-radius: 7px;
             margin-right: 10px;
          }
        .class3{
             background-color: #b3b3b3 ;
             display: inline-block;
             padding: 7px 15px;
             color: white;
             border-radius: 7px;
 
    margin-right: 10px;
}
          .level{
            display: flex;
             >p{
              //  width: 60px;
               text-align: center;
             }
             >span{
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
              line-height: 30px;
             }
             
          }
          // > span:nth-child(1) {
          //   font-size: 16px;
          //   font-family: Microsoft YaHei;
          //   font-weight: 400;
          //   color: #1a1a1a;
          // }
          > span:nth-child(2) {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin: 10px 0;
            line-height: 20px;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
          }
          .item-footer {
            margin-top: auto;
            display: flex;
            align-items: center;
            > span {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #cccccc;
              display: flex;
              align-items: center;
              > i {
                margin-right: 10px;
              }
            }
            .item-options {
              margin-left: auto;
              .item-label {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #727272;
                cursor: pointer;
              }
              .item-center {
                padding: 0 10px;
                color: #b3b3b3;
              }
            }
            .actions-main {
              display: flex;
              align-items: center;
              display: flex;
              margin-left: auto;
              .actions-btn {
                float: right;
                display: flex;
                align-items: center;
                .actions-btn-icon {
                  display: inline-block;
                  background-size: 100% 100%;
                  margin-right: 5px;
                  font-size: 15px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #bbbbbb;
                }
                &.help {
                  margin-right: 20px;
                  .actions-btn-icon {
                    width: 16px;
                    height: 15px;
                    background-image: url("~@/assets/images/help.png");
                  }
                }
                &.collect {
                  .actions-btn-icon {
                    width: 15px;
                    height: 19px;
                    background-image: url("~@/assets/images/collect.png");
                  }
                }
              }
            }
          }
        }
        .item-right-active {
          display: flex;
          flex-direction: column;
          padding: 10px 10px 0;
         background-color: aliceblue;
         width: 61%;
          .class1{
               background-color: #39C44D;
               display: inline-block;
               padding: 7px 15px;
               color: white;
               border-radius: 7px;
               margin-right: 10px;
               text-align: center;
              //  width: 15%;
              //  height: 20px;
}
          .class2{
              background-color: #FF5D3D;
              display: inline-block;
              padding: 7px 15px;
              color: white;
              border-radius: 7px;
              margin-right: 10px;
              text-align: center;
              // width: 15%;
              // height: 20px;
          }
          .class3{
            background-color: #b3b3b3 ;
            display: inline-block;
            padding: 7px 15px;
            color: white;
            border-radius: 7px;
            margin-right: 10px;
            text-align: center;
            // width: 15%;
            // height: 20px;
          }
          .level{
            display: flex;
             >p{
              //  width: 60px;
               text-align: center;
             }
             >span{
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
              width: 80%;
              word-break: break-all;
              line-height: 30px;
              
             }
             
          }
          // > span:nth-child(1) {
          //   font-size: 16px;
          //   font-family: Microsoft YaHei;
          //   font-weight: 400;
          //   color: #1a1a1a;
          // }
          > span:nth-child(2) {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin: 10px 0;
            line-height: 20px;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
          }
          .item-footer {
            margin-top: auto;
            display: flex;
            align-items: center;
            > span {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #cccccc;
              display: flex;
              align-items: center;
              > i {
                margin-right: 10px;
              }
            }
            .item-options {
              margin-left: auto;
              .item-label {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #727272;
                cursor: pointer;
              }
              .item-center {
                padding: 0 10px;
                color: #b3b3b3;
              }
            }
            .actions-main {
              display: flex;
              align-items: center;
              display: flex;
              margin-left: auto;
              .actions-btn {
                float: right;
                display: flex;
                align-items: center;
                .actions-btn-icon {
                  display: inline-block;
                  background-size: 100% 100%;
                  margin-right: 5px;
                  font-size: 15px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #bbbbbb;
                }
                &.help {
                  margin-right: 20px;
                  .actions-btn-icon {
                    width: 16px;
                    height: 15px;
                    background-image: url("~@/assets/images/help.png");
                  }
                }
                &.collect {
                  .actions-btn-icon {
                    width: 15px;
                    height: 19px;
                    background-image: url("~@/assets/images/collect.png");
                  }
                }
              }
            }
          }
        }
      }

      .note-pagin {
        text-align: center;
        padding: 20px 0 10px;
      }
    }
    .note-rich {
      z-index:12;
      flex: 0 0 350px;
      width: 350px;
      margin: 10px 0 0 10px;
      .rich-main {
        width: 100%;
        background-color: #ffffff;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      .wang-editor {
        height: 500px;
      }
      .rich-options {
        padding: 10px;
        text-align: right;
        > span:nth-child(1) {
          margin-right: 10px;
        }
      }
      >p{
        color: red;
      }
    }
    .public {
      width: 330px;
      flex: 0 0 330px;
      background-color: #ffffff;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      padding: 10px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 25px;
      height: 400px;
      overflow: auto;
      word-break: break-all;
    }
  }
}

</style>