<template>
  <!-- 我的收藏 -->
  <div class="my-collect">
    <div class="my-title">
      <span>我的实验</span>
      <div class="my-search">
        <span class="my-select">
          <el-select
            size="small"
            v-model="searchData.select"
            value-key="id"
            clearable
            placeholder="请选择"
            @change="searchSelect"
          >
            <el-option
              v-for="item in optionsType"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </span>
        <el-input
          size="small"
          class="my-search-input"
          v-model="currentData.cus_search_value"
          placeholder="请输入关键字"
        />
        <Buttons type="info" @click="resetSearch()">重置</Buttons>
        <Buttons type="blue" icon="el-icon-search" @click="SearchAction"
          >查询</Buttons>
      </div>
    </div>
    <div class="collect-main">
      <div class="collect-folder">
        <Buttons
          icon="el-icon-plus"
          type="blue"
          plain
          @click="handleClick('新建文件夹', ' ')"
          >新建收藏夹</Buttons>
        <div class="folder-list">
          <div
            :class="currentIndex == '0010' ? 'activefolder' : 'folder-li'"
            @click="handleClick('默认收藏夹', ' ')"
          >
            <img src="@/assets/images/folder.png" alt="" />

            <!-- <template v-if="!renames[folder]"> -->
              <span style="margin: 0 auto">全部收藏</span>
              <!-- <el-dropdown @command="handleCommand">
                <el-icon class="folder-icon"><more-filled /></el-icon>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      :command="{ data: folder, commond: option.label }"
                      v-for="option in menusData"
                      :key="option.key"
                    >
                      <span class="item-label">{{ option.label }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown> -->
            <!-- </template> -->
            <!-- <template v-if="renames[folder.autoid]">
              <span>
                <el-input
                  size="small"
                  class="my-rename"
                  v-model="currentData.renameData"
                  placeholder="请输入"
                />
              </span>
              <span class="rename-option">
                <el-icon title="保存" @click="handleRename('submit', folder)"
                  ><circle-check
                /></el-icon>
                <el-icon title="关闭" @click="handleRename('close', folder)"
                  ><circle-close
                /></el-icon>
              </span>
            </template> -->
          </div>
          <div
            :class="currentIndex == folder.autoid ? 'activefolder' : 'folder-li'"
            v-for="folder in folderData"
            :key="folder.autoid"
            @click="handleClick('查看收藏夹', folder)"
          >
            <img src="@/assets/images/folder.png" alt="folder" />
            <template v-if="!renames[folder.autoid]">
              <span>{{ folder.name }}</span>
              <el-dropdown @command="handleCommand">
                <el-icon class="folder-icon"><more-filled /></el-icon>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      :command="{ data: folder, commond: option.label }"
                      v-for="option in menusData"
                      :key="option.key"
                    >
                      <span class="item-label">{{ option.label }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
            <template v-if="renames[folder.autoid]">
              <span>
                <el-input
                  size="small"
                  class="my-rename"
                  v-model="currentData.renameData"
                  placeholder="请输入"
                />
              </span>
              <span class="rename-option">
                <el-icon title="保存" @click="handleRename('submit', folder)">
                  <circle-check />
                </el-icon>
                <el-icon title="关闭" @click="handleRename('close', folder)">
                  <circle-close />
                </el-icon>
              </span>
            </template>
          </div>
        </div>
      </div>
      <div class="collect-content">
        <div class="collect-option" v-if="dataList.length">
          <span
            ><el-checkbox
              v-model="checkData.all"
              :indeterminate="checkData.indeterminate"
              @change="handleCheck('all', $event)"
              label="全选"
              size="large"
            ></el-checkbox
          ></span>
          <Buttons
            :disabled="currentData.buttonType"
            type="blue"
            @click="handleClick('移入收藏夹', ' ')"
            >移动至</Buttons>
          <Buttons
            :disabled="currentData.buttonType"
            plain
            type="info"
            @click="handleClick('批量取消收藏', '')"
            >取消收藏</Buttons>
        </div>
        <div class="collect-items">
          <div class="collect-item" v-for="item in dataList" :key="item.key">
            <img :src="item.pic" alt="" />
            <span class="collect-check">
              <el-checkbox
                v-model="checkData[item.key]"
                @change="handleCheck('item', item, $event)"
                size="large">
              </el-checkbox>
            </span>
            <div class="item-right">
              <span>
                <span
                  :class="item.collectName == '该收藏已被原作者删除' ? 'active_grey' : ''"
                >{{ item.collectName }}</span>
              </span>
              <span>
                <p
                  v-if="
                    item.targettable == 'expnotes' &&
                    item.collectName != '该收藏已被原作者删除'
                  "
                  v-html="item.collectContent"
                  @click="handleClick('查看收藏内容详情', item)"
                  title="点击查看内容详情"
                  style="cursor: pointer"
                ></p>
                <!-- <span v-if="!item.targettable=='expnotes'">
                    <p v-if="item.collectName !='该收藏已被原作者删除'"  
                     v-html="item.collectContent"
                    @click="handleClick('查看收藏内容详情', item)"
                    title="点击查看内容详情"
                    style="cursor: pointer"></p>
                    </span>   -->
                <span v-if="item.targettable == 'exps'">
                  <a href="javascript:void(0)" @click="jumpTo('exps', item)"
                    >查看实验</a>
                </span>
                <span v-if="item.targettable == 'uiexpdatums'">
                  <a
                    href="javascript:void(0)"
                    @click="jumpTo('uiexpdatums', item)"
                    >查看资料</a>
                </span>
              </span>
              <div class="item-footer">
                <span>
                  <el-icon><clock /></el-icon>收藏于{{
                    item.modifiedtime
                  }}</span>
                <img
                  v-if="item.collectName != '该收藏已被原作者删除'"
                  src="@/assets/images/footer_move.png"
                  alt=""
                  @click="handleClick('移入收藏夹', item)"
                />
                <img
                  v-if="item.collectName != '该收藏已被原作者删除'"
                  src="@/assets/images/footer_del.png"
                  alt=""
                  @click="handleClick('取消收藏', item)"
                />
              </div>
            </div>
          </div>

          <Empty v-if="!dataList.length" />
          <template v-if="currentData.conten1 == 1">
            <div class="collect-pagin">
              <Pagination
                v-if="dataList.length"
                :total="paging.total || 0"
                :pageSize="paging.pageSize || 3"
                :currentPage="paging.currentPage || 1"
                @pageChange="pageChange"
              />
            </div>
          </template>
          <template v-else-if="currentData.conten1 == 2">
            <div class="collect-pagin">
              <Pagination
                v-if="dataList.length"
                :total="paging2.total || 0"
                :pageSize="paging2.pageSize || 3"
                :currentPage="paging2.currentPage || 1"
                @pageChange="pageChange2"
                :hideOnSinglePage="false"
              />
            </div>
          </template>
          <template v-else-if="currentData.conten1 == 3">
            <div class="collect-pagin">
              <Pagination
                v-if="dataList.length"
                :total="paging3.total || 0"
                :pageSize="paging3.pageSize || 3"
                :currentPage="paging3.currentPage || 1"
                @pageChange="pageChange3"
                :hideOnSinglePage="false"
              />
            </div>
          </template>
        </div>
      </div>
    </div>

    <MessageBoxs
      :style="popupcollect.style"
      :title="popupcollect.title || '弹窗标题'"
      :state="popupcollect.state"
      @close="closePopup('取消', ' ')"
    >
      <div
        class="scenter-popup"
        style="margin-top: 20px; text-align: center"
        v-if="popupcollect.title == '取消收藏操作确认'"
      >
        <span class="scenter-content">
          <span>确认取消收藏吗</span>
        </span>
      </div>

      <div
        class="scenter-popup"
        style="margin-top: 20px; text-align: center"
        v-else-if="popupcollect.title == '文件夹删除'"
      >
        <span class="scenter-content">
          <span
            >你确认要删除这个收藏夹吗？<br />
            删除收藏夹后，里面的收藏内容将一并删除</span
          >
        </span>
      </div>

      <div
        class="scenter-popup"
        style="margin-top: 20px; text-align: center"
        v-else-if="popupcollect.title == '批量取消收藏'"
      >
        <span class="scenter-content">
          <span>确认取消这些收藏吗？</span>
        </span>
      </div>

      <div
        class="scenter-popup"
        style="margin-top: 20px; text-align: center"
        v-else-if="popupcollect.title == '移入文件夹'"
      >
        <!-- <span class='scenter-title'>{{popupData.data && popupData.data.title}}</span> -->
        <span class="scenter-content">
          <span>文件夹：</span>
          <el-form style="display: inline-block">
            <el-form-item label="" :label-width="formLabelWidth">
              <el-select
                v-model="currentData.folderSelectValue"
                value-key="autoid"
                clearable
                @change="changeSelect"
              >
                <el-option
                  v-for="item in folderData"
                  :key="item.autoid"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </span>
      </div>

      <div
        class="scenter-popup"
        style="margin-top: 20px; text-align: center"
        v-else-if="popupcollect.title == '新建文件夹'"
      >
        <!-- <span class='scenter-title'>{{popupData.data && popupData.data.title}}</span> -->
        <span class="scenter-content">
          <span>文件名：</span>
          <el-input
            label="文件名"
            placeholder="请输入内容"
            v-model="currentData.folderName"
            style="width: 50%"
          >
          </el-input>
        </span>
      </div>

      <template #footer>
        <Buttons type="primary" @click="closePopup(' ', popupcollect.title)"
          >确定</Buttons>
        <Buttons type="info" @click="closePopup(' ', '不取消收藏')"
          >取消</Buttons>
      </template>
    </MessageBoxs>

    <MessageBoxs
      :style="popupcontent.style"
      :title="popupcontent.title || '弹窗标题'"
      :state="popupcontent.state"
      @close="closePopup('取消', ' ')"
    >
      <div class="content-popup" style="margin-top: 20px; text-align: center">
        <p>{{ CurrentcontentData.title }}</p>
        <div class="content-header">
          <span>姓名：{{ CurrentcontentData.name }}</span>
          <span>发布于：{{ CurrentcontentData.modifiedtime }}</span>
          <span>点赞量：{{ CurrentcontentData.like_size }}</span>
          <span>收藏量：{{ CurrentcontentData.collect_size }}</span>
        </div>

        <div class="content-middle">
          <span v-html="CurrentcontentData.content"> </span>
        </div>
      </div>
      <template #footer>
        <Buttons type="primary" @click="closePopup()">关闭</Buttons>
      </template>
    </MessageBoxs>
  </div>
</template>

<script>
import MessageBoxs from "@/components/course/MessageBoxs.vue";
import Buttons from "@/components/course/Buttons.vue";
import Pagination from "@/components/course/Pagination.vue";
import axios from "@/utils/axios";
import checker from "@/utils/checker";
import server from "@/service/server";
import { ElMessage } from "element-plus";
import common from "@/utils/common.js";
import Empty from "@/components/course/Empty.vue";

export default {
  components: {
    // TabsNew,
    Pagination,
    // WangEditor,
    Buttons,
    MessageBoxs,
    checker,
    server,
    axios,
    ElMessage,
    Empty,
  },
  data() {
    return {
      List: {
        data1: [],
        data2: [],
      },
      currentData: {
        folderName: "",
        renameData: "",
        folderSelectValue: "",
        folderList: {},
        folderId: "",
        selectData: [],
        delFolder: {},
        cus_search: "expnotes",
        cus_search_value: "",
        conten1: 1,
        searchShow: false,
        buttonType: true,
        //contentData:{}
      },
      folder_type: "默认",
      currentIndex: "",
      watchALL: true,
      CurrentcontentData: {},
      currentCollectContent: {},
      popupData: {},
      // popupfolder: {},
      popupcollect: {},
      // popupcontrol: {},
      popupcontent: {},
      paging: {
        total: 0,
        pageSize: 3,
        currentPage: 1,
      },
      paging2: {
        total: 0,
        pageSize: 3,
        currentPage: 1,
      },
      paging3: {
        total: 0,
        pageSize: 3,
        currentPage: 1,
      },
      menusData: [
        { key: "001", label: "重命名" },
        { key: "002", label: "删除" },
        { key: "003", label: "上移" },
        { key: "004", label: "下移" },
      ],
      folderData: [],
      renames: [],
      dataList: [],
      pitchAll: "",
      checkData: {
        all: false,
        indeterminate: false,
      },
      optionsType: [
        { id: "01", val: "expnotes", name: "笔记" },
        { id: "02", val: "exps", name: "实验" },
        { id: "03", val: "uiexpdatums", name: "资料" },
      ],
      searchData: { select: { id: "01", val: "expnotes", name: "笔记" } },
    };
  },
  mounted() {
    this.get_allCollection_list();
    this.get_favorite_list();
    // this.get_allCollection_count();
  },
  methods: {
    selectmonitor() {},
    searchSelect(e) {
      // let data = e;
      this.currentData.cus_search = e.val;
    },
    pageChange(currentPage) {
      this.paging.currentPage = currentPage;
      this.get_allCollection_list();
      // this.get_allCollection_count();
    },
    pageChange2(currentPage) {
      this.paging2.currentPage = currentPage;
      this.watchFolder();
    },
    pageChange3(currentPage) {
      this.paging3.currentPage = currentPage;
      this.SearchAction();
      // this.searchCount();
    },
    changeSelect(e) {
      this.currentData.folderId = e.autoid;
    },
    get_allCollection_count() {
      let request = { get_favorite: true, count: true };
      axios
        .get(server.collectstar + checker.objTurnParams(request))
        .then((result) => {
          if (result.status && result.data) {
            this.paging.total = Number(result.data);
          }
        });
    },
    handleClick(type, data) {
      switch (type) {
        case "新建文件夹":
          this.popupcollect = {
            title: "新建文件夹",
            state: true,
            style: { width: "400px" },
          };
          break;
        case "查看收藏夹":
          this.currentIndex = data.autoid;
          this.currentData.folderList = data;
          this.folder_type = "其他";
          this.watchFolder(data);
          break;
        case "移入收藏夹":
          this.currentCollectContent = data;
          this.popupcollect = {
            title: "移入文件夹",
            state: true,
            style: { width: "400px" },
          };
          break;
        case "取消收藏":
          this.currentCollectContent = data;
          this.popupcollect = {
            title: "取消收藏操作确认",
            state: true,
            style: { width: "400px" },
          };
          break;
        case "默认收藏夹":
          this.folder_type = "默认";
          this.get_allCollection_list();
          break;
        case "批量取消收藏":
          this.popupcollect = {
            title: "批量取消收藏",
            state: true,
            style: { width: "400px" },
          };
          break;
        case "查看收藏内容详情":
          let obj = {};
          // obj.name = data.user_name;
          obj.name = data.expnote_data.user_data.username;
          obj.title = data.collectName;
          obj.modifiedtime = data.expnote_data.modifiedtime;
          obj.content = data.collectContent;
          if (data.collectstar_data) {
            obj.collect_size = data.collectstar_data.collect || 0;
            obj.like_size = data.expnote_data.like_size || 0;
          } else {
            obj.collect_size = data.collect || 0;
            obj.like_size = data.expnote_data.like_size || 0;
          }
          //  obj.type = data.targettable=="expnotes"?''
          this.CurrentcontentData = obj;
          this.popupcontent = {
            title: "查看详情",
            state: true,
            style: { width: "1200px" },
          };
          break;
        case "查询实验内容":
          this.SearchAction();
          break;
      }
    },
    jumpTo(type, data) {
      switch (type) {
        case "exps":
          common.removeParams("/exps");
          common.setStorParams(
            { path: "/expcore" },
            { autoid: data.expautoid, exp_data: data.exp_data }
          );
          this.$router.push("/expcore");
          break;
        case "uiexpdatums":
          data.resource_data = data.uiexpdatum_data.resource_data;
          data.key = data.keyid;
          // let jump_data = data.concat()
          common.setStorParams({ path: "/lectures" }, data);
          this.$router.push("/lectures");
          //  common.removeParams('/expcore');
          //  common.setStorParams({path:"/lectures"}, {autoid: data.expautoid, exp_data: data.uiexpdatum_data});
          //  this.$router.push("/lectures");
          break;
      }
    },
    resetSearch() {
      this.searchData.select = "";
      this.currentData.cus_search_value = "";
      this.get_allCollection_list();
      this.searchData = { select: { id: "01", val: "expnotes", name: "笔记" } };
    },
    SearchAction() {
      const { dataList, checkData } = this;
      this.currentData.conten1 = 3;
      let type = this.currentData.cus_search;
      let value = this.currentData.cus_search_value;
      this.searchCount();
      if (type && value) {
      // if (type != "" && value != "") {
        let request = {
          custom_search: type,
          custom_search_val: value,
          page: this.paging3.currentPage,
          per_page: this.paging3.pageSize,
        };
        axios
          .get(server.favorite + checker.objTurnParams(request))
          .then((result) => {
            if (!result.status) return;
            let dataa = result.data.result;
            if (result.data && result.data.result) {
              dataa.forEach((item) => {
                item.key = item.autoid;
                item.targetType = item.targettable;
                item.targettable = item.targettable;
                item.expautoid = item.expautoid;
                if (type == "expnotes") {
                  item.collectName =
                    item.expnote_data.name + "笔记" || "该收藏已被原作者删除";
                  item.collectContent = item.expnote_data.content;
                  item.expnotesid = item.expnote_data.autoid;
                  item.keyid = item.expnote_data.autoid;
                  item.user_name = item.expnote_data.user_data.user_name;
                  item.pic = require("@/assets/images/txt.png");
                } else if (type == "exps") {
                  item.collectName =
                    item.exp_data.title + "实验" || "该收藏已被原作者删除";
                  item.collectContent = item.exp_data.explain;
                  item.keyid = item.exp_data.autoid;
                  item.user_name = item.exp_data.master_data.user_name;
                  let faces = item.exp_data.face.split(",");
                  faces.forEach((facee) => {
                    if (facee.indexOf("jpg") != -1) {
                      item.pic = facee;
                    } else if (facee.indexOf("png") != -1) {
                      item.pic = facee;
                    } else if (facee.indexOf("jpeg") != -1) {
                      item.pic = facee;
                    } else {
                      if (item.pic == "") {
                        item.pic = require("@/assets/images/default_exp.jpg");
                      }
                    }
                  });
                } else if (type == "uiexpdatums") {
                  item.collectName =
                    item.uiexpdatum_data.resource_data.name ||
                    "该作者已被原作者删除";
                  item.collectContent = "null";
                  item.expnotesid = item.uiexpdatum_data.autoid;
                  item.keyid = item.uiexpdatum_data.autoid;
                  switch (item.uiexpdatum_data.resource_data.resourcetype) {
                    case "office":
                      item.pic = require("@/assets/images/document.png");
                      break;
                    case "image":
                      item.pic = require("@/assets/images/video_image.png");
                      break;
                    case "video":
                      item.pic = require("@/assets/images/video_image.png");
                      break;
                  }
                  //   item.user_name = item.uiexpdatum_data.user_data.user_name;
                }
              });
              this.dataList = dataa;
              this.currentIndex = "0012";
            } else {
              this.dataList = [];
              this.currentData.searchShow = 0;
            }
          });
      } else {
        this.$message.error("查询关键字不得为空！");
      }
    },
    searchCount() {
      const { dataList, checkData } = this;
      let type = this.currentData.cus_search;
      let value = this.currentData.cus_search_value;
      if (type && value) {
      // if (type != "" && value != " ") {
        let request = {
          custom_search: type,
          custom_search_val: value,
          count: true,
        };
        axios
          .get(server.favorite + checker.objTurnParams(request))
          .then((result) => {
            this.paging3.total = Number(result.data);
          });
      }
    },
    closePopup(data, type) {
      switch (type) {
        case "新建文件夹":
          this.create_new_folder();
          this.currentData.folderName = " ";
          break;
        case "取消新建文件夹":
          break;
        case "移入文件夹":
          this.moveTofolder();
          break;
        case "取消移入文件夹":
          break;
        case "取消收藏操作确认":
          this.cancelCollect();
          break;
        case "不取消收藏":
          break;
        case "批量取消收藏":
          this.cancelCollectMore();
          break;
        case "文件夹删除":
          this.deleteFolder(this.currentData.delFolder);
          break;
      }
      // this.linePaging.pageSize = 0;
      // this.popupData.state = false;
      // this.popupfolder.state = false;
      this.popupcollect.state = false;
      // this.popupcontrol.state = false;
      this.popupcontent.state = false;
    },

    cancelCollectMore() {
      const { dataList, checkData } = this;
      let str = this.currentData.selectData.map(Number).join(",");
      if (str != "") {
        let request = {
          autoids: str,
          delete_data: true,
          collect: 1,
          split: ",",
        };
        axios.post(server.collectstar, request).then((result) => {
          if (result.status) {
            this.$message.success("取消收藏成功！");
            this.checkData.all = "";
            this.checkData.indeterminate = false;
            dataList.forEach((item) => {
              this.checkData[item.key] = "";
            });
            // this.get_allCollection_count();
            this.get_allCollection_list();
            this.get_favorite_list();
          } else {
            this.$message.error("取消收藏失败");
          }
        });
      }
    },
    cancelCollect() {
      var data = this.currentCollectContent;
      var targitname = " "; // 判断是哪种类型收藏
      var targitid = " ";
      var expautoid = " ";
      switch (data.targettable || data.targetType) {
        case "exps":
          targitname = data.targetType || data.targettable;
          targitid = data.expautoid;
          expautoid = data.expautoid;
          break;
        case "expnotes":
          targitname = data.targettable || data.targetType;
          targitid = data.expnotesid;
          expautoid = data.expautoid;
          break;
        case "uiexpdatums":
          targitname = data.targettable;
          targitid = data.uiexpdatumid;
          expautoid = data.expautoid;
          break;
      }
      let autoidd = data.autoid;
      if (this.folder_type == "其他") {
        autoidd = data.collectstar_data.autoid;
      }
      // let request = {targettable:targitname,targetautoid:targitid,expautoid:expautoid,collect:1,delete_data:true}
      let request = { autoid: autoidd, delete_data: true, collect: 1 };
      axios.post(server.collectstar, request).then((result) => {
        if (result.status) {
          this.$message.success("取消收藏成功！");
          // this.get_allCollection_count();
          this.get_allCollection_list();
          this.get_favorite_list();
          // dataList.forEach((item) => {
          // this.checkData[item.key] = "";
          //   });
        } else {
          this.$message.error("取消收藏失败");
        }
      });
    },
    watchFolder(res) {
      // this.get_summary_list();
      this.watchALL = false;
      let data = this.currentData.folderList;
      this.currentData.conten1 = 2;
      const { dataList, checkData } = this;
      this.currentData.selectData = []; // 清空上一个所选文件夹，批量选中的内容
      this.checkData.all = " ";
      this.checkData.indeterminate = false;
      dataList.forEach((item) => {
        this.checkData[item.key] = " ";
      });
      if (data) {
        let request = {
          parentid: data.autoid,
          page: this.paging2.currentPage,
          per_page: this.paging2.pageSize,
        };
        axios
          .get(server.favorite + checker.objTurnParams(request))
          .then((result) => {
            if (result.status && result.data && result.data.result) {
              let dataa = result.data.result;
              dataa.forEach((item) => {
                item.key = item.collectstarautoid;
                //let faces = !!item.collectstar_data.exp_data ? item.collectstar_data.exp_data.face.split(',') : !!item.collectstar_data.uiexpdatum_data ? item.collectstar_data.uiexpdatum_data.face.split(','):item.collectstar_data.expnote_data.face.split(',');
                if (!!item.collectstar_data.expnote_data) {
                  item.collectName =
                    item.collectstar_data.expnote_data.name ||
                    "该收藏已被原作者删除";
                  item.collectstar_data.expnote_data.content;
                  item.targetType = item.collectstar_data.targettable;
                  item.targettable = item.collectstar_data.targettable;
                  item.expautoid = item.collectstar_data.expautoid;
                  item.expnotesid = item.collectstar_data.expnote_data.autoid;
                  item.keyid = item.collectstar_data.expnote_data.autoid;
                  item.pic = require("@/assets/images/txt.png");
                  //  item.user_name =item.collectstar_data.expnote_data.user_data.user_name;
                } else if (!!item.collectstar_data.exp_data) {
                  item.collectName =
                    item.collectstar_data.exp_data.title ||
                    "该收藏已被原作者删除";
                  item.collectContent = item.collectstar_data.exp_data.explain;
                  item.targetType = item.collectstar_data.targettable;
                  item.targettable = item.collectstar_data.targettable;
                  item.expautoid = item.collectstar_data.expautoid;
                  item.keyid = item.collectstar_data.expautoid;
                  let faces = item.collectstar_data.exp_data.face.split(",");
                  faces.forEach((facee) => {
                    if (facee.indexOf("jpg") != -1) {
                      item.pic = facee;
                    } else if (facee.indexOf("png") != -1) {
                      item.pic = facee;
                    } else if (facee.indexOf("jpeg") != -1) {
                      item.pic = facee;
                    } else {
                      // if(item.pic==''){
                      item.pic = require("@/assets/images/default_exp.jpg");
                      // }
                    }
                  });
                } else if (!!item.collectstar_data.uiexpdatum_data) {
                  item.collectName =
                    item.collectstar_data.uiexpdatum_data.resource_data.name ||
                    "该收藏已被原作者删除";
                  item.collectContent = "null";
                  item.targetType = item.collectstar_data.targettable;
                  item.targettable = item.collectstar_data.targettable;
                  item.expautoid = item.collectstar_data.expautoid;
                  item.keyid = item.collectstar_data.uiexpdatum_data.autoid;
                  item.user_name = " ";
                  switch (
                    item.collectstar_data.uiexpdatum_data.resource_data
                      .resourcetype
                  ) {
                    case "office":
                      item.pic = require("@/assets/images/document.png");
                      break;
                    case "image":
                      item.pic = require("@/assets/images/video_image.png");
                      break;
                    case "video":
                      item.pic = require("@/assets/images/video_image.png");
                      break;
                  }
                }
                // item.collectName = item.collectstar_data.expnote_data.expdatum_data.resource_data.name || item.collectstar_data.exp_data.title;
                // item.collectContent = item.collectstar_data.expnote_data.content || item.collectstar_data.exp_data.explain;
              });
              this.List.data2 = dataa;
              this.dataList = this.List.data2;
            }
          });
        this.getfolderCount(data); // 获取每个文件夹内容数量
      }
    },
    getfolderCount(data) {
      if (data) {
        let request = { parentid: data.autoid, count: true };
        axios
          .get(server.favorite + checker.objTurnParams(request))
          .then((result) => {
            if(result && result.status){
              this.paging2.total = Number(result.data);
            }
          });
      }
    },
    renameFolder(data) {
      let rename = this.currentData.renameData;
      if (data && this.currentData.renameData) {
        let request = {
          put_data: true,
          autoid: data.autoid,
          name: this.currentData.renameData,
        };
        axios.post(server.favorite, request).then((result) => {
          if (result.status && result.data) {
            this.$message.success("修改成功");
            this.get_favorite_list();
          } else {
            this.$message.error("修改失败");
          }
          this.currentData.renameData = "";
        });
      } else {
        this.$message.error("修改失败，文件名不得为空");
      }
      delete this.renames[data.autoid];
    },
    deleteFolder(data) {
      if (data) {
        let request = { delete_data: true, autoid: data.autoid };
        axios.post(server.favorite, request).then((result) => {
          if (result.status && result.data) {
            this.get_favorite_list();
            this.$message.success("删除成功");
          } else {
            this.$message.error("删除失败");
          }
        });
      } else {
        this.$message.error("删除失败");
      }
    },
    // 创建收藏夹
    createFolder() {
      this.popupData = {
        title: "新建文件夹",
        state: true,
        // data : data,
        style: { width: "400px" },
        // content:data.content,
        // message_id:data.autoid,
        // read:data.read
      };
    },

    create_new_folder() {
      let folder_name = this.currentData.folderName;
      let length = this.folderData.length + 1;
      let index = -Number(length);
      if (folder_name) {
        let request = { name: folder_name, sort: index };
        axios.post(server.favorite, request).then((result) => {
          if (result.status && result.data && result.data.result) {
            this.$message.success("创建成功");
            this.get_favorite_list();
            this.get_allCollection_list();
          } else {
            this.$message.error("创建失败");
          }
        });
      } else {
        this.$message.error("创建失败，文件夹名不得为空");
      }
    },

    moveTofolder() {
      // 单文件移动
      if (this.currentCollectContent != " ") {
        let collectsid = this.currentCollectContent.key;
        let pid = this.currentData.folderId;
        if (collectsid && pid) {
          let request = { collectstarautoid: collectsid, parentid: pid };
          axios.post(server.favorite, request).then((result) => {
            if (result.status && result.data) {
              this.$message.success("移入成功");
              this.currentData.folderSelectValue = " ";
              this.get_allCollection_list();
              this.get_favorite_list();
            } else {
              this.$message.success("移入失败");
            }
          });
        } else {
          this.$message.error("移动失败，未选择文件夹");
        }
      }
      // 多文件移动
      else {
        let pid = this.currentData.folderId;
        let str = this.currentData.selectData.map(Number).join(",");
        let request = { collectstarautoids: str, parentid: pid, split: "," };
        axios.post(server.favorite, request).then((result) => {
          if (result.status) {
            this.$message.success("移入成功");
            this.currentData.folderSelectValue = " ";
            this.get_allCollection_list();
            this.get_favorite_list();
          } else {
            this.$message.success("移入失败");
          }
        });
      }
    },
    // 请求获取所有收藏列表
    get_allCollection_list() {
      this.currentIndex = "0010";
      this.currentData.conten1 = 1;
      let request = {
        get_favorite: true,
        page: this.paging.currentPage,
        per_page: this.paging.pageSize,
      };
      let array = [];
      this.currentData.selectData = [];
      axios
        .get(server.collectstar + checker.objTurnParams(request))
        .then((result) => {
          if (result.status && result.data && result.data.result) {
            let dataa = result.data.result;
            dataa.forEach((item) => {
              item.key = item.autoid;
            });
            dataa.forEach((item) => {
              switch (item.targettable) {
                case "exps":
                  if (item.exp_data && Object.keys(item.exp_data).length) {
                    let faces = item.exp_data.face.split(",");
                    faces.forEach((facee) => {
                      if (facee.indexOf("jpg") != -1) {
                        item.pic = facee;
                      } else if (facee.indexOf("png") != -1) {
                        item.pic = facee;
                      } else if (facee.indexOf("jpeg") != -1) {
                        item.pic = facee;
                      } else {
                        // if(item.pic==''){
                        item.pic = require("@/assets/images/default_exp.jpg");
                        // }
                      }
                    });
                    item.collectName =
                      item.exp_data.title + "实验" || "该收藏已被原作者删除";
                    item.collectContent = item.exp_data.explain;
                    item.keyid = item.exp_data.autoid;
                  }
                  // array.push(item.expautoid)
                  break;
                case "expnotes":
                  item.collectName =
                    item.expnote_data.name + "笔记" || "该收藏已被原作者删除";
                  item.collectContent = item.expnote_data.content;
                  item.expnotesid = item.expnote_data.autoid;
                  item.keyid = item.expnote_data.autoid;
                  item.pic = require("@/assets/images/txt.png");
                  //  array.push(item.expnote_data.autoid);
                  break;
                case "uiexpdatums":
                  item.keyid = item.uiexpdatum_data.autoid;
                  item.collectContent = "null";
                  item.collectName =
                    item.uiexpdatum_data.resource_data.name ||
                    "该收藏已被原作者删除";
                  switch (item.uiexpdatum_data.resource_data.resourcetype) {
                    case "office":
                      item.pic = require("@/assets/images/document.png");
                      break;
                    case "image":
                      item.pic = require("@/assets/images/video_image.png");
                      break;
                    case "video":
                      item.pic = require("@/assets/images/video_image.png");
                      break;
                  }
                  break;
              }
            });
            this.List.data1 = dataa;
            this.dataList = this.List.data1;
          }
          this.get_allCollection_count();
        });
    },
    //获取收藏夹列表
    get_favorite_list() {
      let request = { parentid: 0, _disable_page_: true, };
      axios
        .get(server.favorite + checker.objTurnParams(request))
        .then((result) => {
          if (result.status && result.data && result.data.result) {
            this.folderData = result.data.result;
            // this.currentData.folderList = result.
          }
        });
    },

    handleCommand(command) {
      const { commond, data } = command;
      switch (commond) {
        case "重命名":
          this.renames[data.autoid] = true;
          // this.renameFolder(data);
          break;
        case "删除":
          this.currentData.delFolder = data;
          this.popupcollect = {
            title: "文件夹删除",
            state: true,
            style: { width: "400px" },
          };
          // this.deleteFolder(data);
          break;
        case "上移":
          this.replace(data, 1);
          break;
        case "下移":
          this.replace(data, -1);
          break;
      }
    },
    replace(data, step) {
      let folder = this.folderData,
        folderLength = this.folderData.length,
        CurrentIndex = "",
        upIndex = "",
        downIndex = "",
        upId = "",
        downId = "",
        upsort = 0,
        currentSort = 0;
      for (var i = 0; i < folderLength; i++) {
        if (data.autoid == folder[i].autoid) {
          CurrentIndex = i;
        }
      }
      if (step == 1) {
        if (CurrentIndex == 0) {
          this.$message.error("已经置顶,无法移动！");
        } else {
          upIndex = CurrentIndex - 1;
          let middle = Number(folder[upIndex].sort);
          upsort = data.sort;
          upId = folder[upIndex].autoid;
          currentSort = middle;
          let res1 = "";
          this.replaceAction(currentSort, data.autoid).then((res) => {
            res1 = res;
            if (res) {
              this.replaceAction(upsort, upId).then((res) => {
                if (res1 && res) {
                  this.$message.success("移动成功");
                  this.get_allCollection_list();
                  this.get_favorite_list();
                }
              });
            } else {
              this.$message.success("移动失败");
            }
          });
        }
      } else if (step == -1) {
        if (CurrentIndex == folderLength - 1) {
          this.$message.error("已经置底,无法移动！");
        } else {
          downIndex = CurrentIndex + 1;
          let middle = Number(folder[downIndex].sort);
          let downsort = 0;
          downsort = data.sort;
          downId = folder[downIndex].autoid;
          currentSort = middle;
          this.replaceAction(currentSort, data.autoid);
          let res1 = "";
          this.replaceAction(currentSort, data.autoid).then((res) => {
            res1 = res;
            if (res) {
              this.replaceAction(downsort, downId).then((res) => {
                if (res1 && res) {
                  this.$message.success("移动成功");
                  this.get_allCollection_list();
                  this.get_favorite_list();
                }
              });
            } else {
              this.$message.success("移动失败");
            }
          });
        }
      } else {
      }
    },

    async replaceAction(sort, id) {
      let sortt = sort;
      let idd = id;
      let res = "";
      let request = { put_data: true, autoid: idd, sort: sortt };
      await axios.post(server.favorite, request).then((result) => {
        if (result.status) {
          res = 1;
          // this.$message.success("移动成功")
        } else {
          res = 0;
          // this.$message.error("移动失败")
        }
      });

      return res;
    },
    handleRename(type, data) {
      switch (type) {
        case "close":
          this.currentData.renameData = " ";
          delete this.renames[data.autoid];
          break;
        case "submit":
          this.renameFolder(data);
          break;
      }
    },
    handleCheck(type, value, data) {
      const { dataList, checkData } = this;
      switch (type) {
        case "all":
          this.resoleSelectALL(value, data);
          this.checkData.all = value;
          this.checkData.indeterminate = false;
          dataList.forEach((item) => {
            this.checkData[item.key] = value;
          });
          break;
        case "item":
          this.resoleItenCollect(value, data);
          this.checkData[value.key] = data;
          let cache = [];
          for (const check in checkData) {
            if (
              checkData[check] &&
              ["all", "indeterminate"].indexOf(check) < 0
            ) {
              cache.push(check);
            }
          }
          if (cache.length === dataList.length) {
            this.checkData.indeterminate = false;
            this.checkData.all = true;
          } else if (cache.length === 0) {
            this.checkData.all = false;
            this.checkData.indeterminate = false;
          } else {
            this.checkData.indeterminate = true;
            this.checkData.all = false;
          }
          break;
      }
    },
    // 批量处理全选状态
    resoleSelectALL(value, type) {
      let array = this.dataList.concat();
      var arr = [];
      array.forEach((item) => {
        arr.push(item.key);
      });
      if (!value) {
        array = [];
        this.currentData.selectData = array;
      } else {
        this.currentData.selectData = arr;
      }
      if (this.currentData.selectData.length) {
        this.currentData.buttonType = false;
      } else {
        this.currentData.buttonType = true;
      }
    },

    // 处理批量数据 （非全选状态）
    resoleItenCollect(value, type) {
      if (type) {
        // 选中状态
        this.currentData.selectData.push(value.key);
      } else {
        let arr2 = this.currentData.selectData;
        for (var i = 0; i < arr2.length; i++) {
          if (arr2[i] == value.key) {
            arr2.splice(i, 1);
          }
        }
      }
      if (this.currentData.selectData.length) {
        this.currentData.buttonType = false;
      } else {
        this.currentData.buttonType = true;
      }
    },
  },
};
</script>

<style scoped lang="less">
.active_grey {
  color: #999999;
}
.activefolder {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #e5e5e5;
  color: blue;
  font-weight: bolder;
}
::v-deep .scenter-popup .el-icon.el-select__caret.el-input__icon {
  position: relative;
  top: 50%;
}
.collect-pagin {
  padding: 20px 0;
  text-align: center;
}
.content-popup {
  margin-top: 20px;
  > p {
    margin-top: 10px;
    font-size: 20px;
    font-weight: bold;
  }
  .content-header {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    text-align: center;
    color: grey;

    > span {
      display: inline-block;
      width: 24%;
    }
  }
}
.content-middle {
  margin-bottom: 20px;
  margin-top: 30px;
  min-height: 200px;
  padding-left: 40px;
  text-align: left;
  line-height: 25px;
  height: 300px;
  word-break: break-all;
  overflow: scroll;
}
.item-label {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
}
::v-deep .el-input--small .el-input__icon {
  line-height: 0;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: rgba(41, 116, 234, 1);
  border: 1px solid rgba(41, 116, 234, 0.7);
}
::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: rgba(41, 116, 234, 1);
  border: 1px solid rgba(41, 116, 234, 0.7);
}
::v-deep .el-checkbox {
  display: flex;
  align-items: center;
}
.my-collect {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.my-title {
  width: 100%;
  height: 53px;
  display: flex;
  align-items: center;
  justify-items: center;
  flex: 0 0 53px;
  border-bottom: 1px solid #f5f5f5;
  > span {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #1a1a1a;
  }
  .my-search {
    display: flex;
    align-items: center;
    margin-left: auto;
    .my-search-input,
    .my-select {
      margin-right: 10px;
    }
  }
}

.collect-main {
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex: 1 1 auto;
  .collect-folder {
    width: 170px;
    height: 670px;
    overflow: auto;
    flex: 0 0 170px;
    background-color: #f5f5f5;
    border-radius: 4px;
    padding: 10px 15px;
    text-align: left;
    .folder-list {
      .folder-li {
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        border-bottom: 1px solid #e5e5e5;
        cursor: pointer;
        .folder-icon {
          transform: rotate(90deg);
        }
        .my-rename {
          width: 100px;
        }
        .rename-option {
          font-size: 16px;
          > i {
            cursor: pointer;
          }
          > i:nth-child(1) {
            margin: 0 5px;
            color: #3eb36b;
          }
          > i:nth-child(2) {
            color: #da0000;
          }
        }
        &:last-child {
          border: none;
        }
        > img {
          width: 23px;
          height: 20px;
          margin-right: 5px;
        }
        > span {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
        }
        .el-icon-more {
          transform: rotate(90deg);
          height: 13px;
        }
      }
    }
  }
  .collect-content {
    flex: 1 1 auto;
    padding-left: 20px;
    .collect-option {
      display: flex;
      align-items: center;
      > span {
        margin-right: 10px;
      }
    }
    .collect-items {
      .collect-item {
        position: relative;
        display: flex;
        padding: 10px 0;
        > img {
          width: 220px;
          height: 150px;
          border-radius: 4px;
          flex: 0 0 220px;
          border: 1px solid lightgray;
        }
        .item-right {
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
          text-align: left;
          padding: 0 10px 0 20px;
          height: 200px;
          > span:nth-child(1) {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #1a1a1a;
            margin-bottom: 10px;
            > span {
              line-height: 19px;
              padding: 10px 12px 10px;
              height: 30px;
              text-align: left;
              word-break: break-all;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
          > span:nth-child(2) {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            text-align: justify;
            line-height: 20px;
            text-indent: 24px;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-all;
            height: 36%;
            overflow: auto;
          }
          .item-footer {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #cccccc;
            // margin-top: auto;
            display: flex;
            align-items: center;
            > span {
              display: flex;
              align-items: center;
              > i {
                margin-right: 5px;
                font-size: 18px;
              }
              .el-icon-time {
                margin-right: 10px;
                font-size: 20px;
              }
            }
            > img {
              cursor: pointer;
            }
            > img:nth-child(2) {
              width: 18px;
              height: 15px;
              margin-left: auto;
            }
            > img:nth-child(3) {
              width: 20px;
              height: 20px;
              margin-left: 20px;
            }
          }
        }

        .collect-check {
          position: absolute;
          left: 10px;
          top: 20px;
          padding: 5px 10px;
          border-radius: 4px;
          background-color: rgba(255, 255, 255, 0.8);
          box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
</style>