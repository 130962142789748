<template>
  <div class="details-ui">
    <div class="details-modules">
      <div class="module-items">
        <div class="module-item" v-for="item in moduleData" :key="item.key">
          <PageItems>
            <template #title>
              <div class="module-title">
                {{ item.title }}
                <span class="module-english">{{ item.english }}</span>
              </div>
            </template>
            <template #content>
              <template v-if="item.title === '教学支持热线'">
                <div class="contacts-main">
                  <div
                    class="contacts-item"
                    v-for="citem in contactData"
                    :key="citem.key"
                  >
                    <div v-if="contactData.length != 0"> 
                      <i
                        :class="[
                          iconMap[citem.type] || 'el-icon-question',
                          'icon-shade',
                        ]"
                      ></i>
                      <span>{{ citem.label }}：</span>
                      <span :title="citem.value" class="contacts-value">
                        {{ citem.value }}
                      </span>
                      <el-icon class="contacts-copy" title="点击复制" @click="handleCopy(citem)"><copy-document /></el-icon>
                    </div>
                    <div class="nodata" v-else>
                        暂无数据
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="item.title === '教学团队'">
                <div class="contacts-main">
                  <div class="contacts-users">
                    <span class="users-label">实验负责人</span>
                    <div class="users-name">
                      <el-icon><user-filled /></el-icon><span>{{realname}}</span>
                    </div>
                  </div>
                  <div class="users-list">
                    <Tabs
                      v-model:active="active"
                      :tabsData="tabsData"
                      @tabCahnge="handleTab"
                    >
                      <template #default="{ tabsItem: item }">
                        <div class="users-item">
                          <div
                            class="users-item-li"
                            v-for="user in (userData[active] || [])"
                            :key="user.key"
                          >
                            <el-icon><user-filled /></el-icon>
                            <span style="width:47%">{{ user.name }}</span>
                            <span style="color:rgba(41, 116, 234, 1); font-size:12px;cursor:pointer;"  @click="handlePopup('师资介绍',user)">师资介绍</span>
                          </div>
                        </div>
                      </template>
                    </Tabs>
                  </div>
                </div>
              </template>
              <template v-if="item.title === '学情数据'">
                <div class="contacts-main">
                  <div
                    class="contacts-data"
                    v-for="item in adata"
                    :key="item.key"
                  >
                    <span>{{ item.label }}</span>
                    <span>{{ item.value }}</span>
                  </div>
                </div>
              </template>
            </template>
            <template #bar>
              <span
                @click="handlePopup('教学情况')"
                class="module-btn"
                v-if="item.title === '教学团队'"
                >教学情况</span
              >
              <span
                @click="handlePopup('学情详情')"
                class="module-btn"
                v-if="item.title === '学情数据'"
                >查看详情</span
              >
            </template>
          </PageItems>
        </div>
      </div>
    </div>
    <div class="details-main">
      <TabsNew :active="active" :menuData="menuData" @toClick="changeTab">
        <template #default="{ pitch: data }">
          <!-- <template> -->
            <PageHeader :isBack="false">
              <template #header>
                <div class="home-shade">
                  <span class="home-header" v-if='data.title'>——{{data.title}}——</span>
                </div>
              </template>
              <template #content>
                <div class="home-content" v-html="data.content"></div>
              </template>
            </PageHeader>
        </template>
      </TabsNew>
      <div class="nodata" v-if="menuData.length === 0">
          暂无数据
      </div>
    </div>
    <MessageBoxs
      :title="popupData.title"
      :state="popupData.state"
      @close="messageClose"
      :style='popupData.style'>
      <!-- <div class="mess_content"> -->
      <span class="situation" v-if='popupData.content' v-html="popupData.content || ''"></span>
      <span class="situation" v-if='!popupData.content'> 
          <!-- <div class="teamMember" v-if="popupData.title=='师资介绍' ">
             哈哈哈哈
          </div> -->
      </span>
      <!-- </div> -->
      <template #footer>
        <Buttons type="primary" @click="messageClose">关闭</Buttons>
      </template>
    </MessageBoxs>
  </div>
</template>

<script>
import PageItems from "@/components/course/PageItems.vue";
import { ElMessage } from "element-plus";
import PageHeader from "@/components/course/PageHeader.vue";
import Tabs from "@/components/course/Tabs.vue";
import TabsNew from "@/components/business/Tabs/index.vue";
import MessageBoxs from "@/components/course/MessageBoxs.vue";
import Buttons from "@/components/course/Buttons.vue";

import axios from "@/utils/axios";
import server from "@/service/server";
import checker from "@/utils/checker";
// import common from '@/utils/common';

export default {
  updated() {
  // $('.home-content').find('i').css({
     
  // });
},
  data() {
    return {
      expautoid: '',
      popupData: {},
      teachteamData: {},
      uiData: [],
      moduleData: [
        {
          key: "001",
          title: "教学支持热线",
          english: "Teaching support hotline",
        },
        { key: "002", title: "教学团队", english: "Teaching team" },
        { key: "003", title: "学情数据", english: "Academic data" },
      ],
      contactData: [
        // { key: "001", label: "电话", type: "PHONE", value: "17683850609" },
        // { key: "002", label: "QQ", type: "QQ", value: "745741358" },
        // { key: "003", label: "微信", type: "WECHAT", value: "16213541682" },
        // { key: "004", label: "QQ群", type: "QQGROUP", value: "745740628" },
        // { key: "005", label: "座机", type: "LANDLINE", value: "010-53685568" },
        // {
        //   key: "006",
        //   label: "邮箱",
        //   type: "EMAIL",
        //   value: "74574068111@qq.com",
        // },
      ],
      menuActive: "",
      iconMap: {
        Phone: "xxl xxl-phone",
        QQ: "xxl xxl-QQ",
        WeChat: "xxl xxl-wechat",
        QQGroup: "xxl xxl-qqgroup",
        Landline: "xxl xxl-landline",
        email: "xxl xxl-email",
        Fax: "xxl xxl-fax",
      },
      active: "1",
      tabsData: [
        { key: "001", name: '1', label: "主要成员", type: "主要成员" },
        { key: "002", name: '0', label: "其他成员", type: "其他成员" },
      ],
      userData:{},
      adata: [
        //学情数据
        { key: "001", label: "实验人次：", value: "0" ,params: "exp_times"},
        { key: "002", label: "实验浏览量:", value: "0" ,params: "commview_exp_count"},
        { key: "003", label: "实验平均用时(min):", value: "0" ,params: "exp_life_average"},
        { key: "004", label: "实验完成率:", value: "0" ,params: "exp_operator_finish_rate"},
        { key: "005", label: "实验及格率:", value: "0" ,params: "exp_operator_pass_rate"},
      ],
    };
  },
  props: {
    autoid: {
      type: [Number, String],
      default: "",
    },
    realname: {
      type: String,
      default: "",
    },
    menuData: {
      type: Array,
      default: []
    },
  },
  mounted() {
    // let storeParams = common.getStorParams({path:'/expcore'});
    // if(!storeParams) storeParams = common.getStorParams(this);
    // this.expautoid = storeParams.autoid;
    this.getContactData();
    this.getTeachteamAutoid();
    this.getLearningData();
  },
  watch: {
    autoid: "monitorAutoid",
  },
  methods: {
    monitorAutoid() {
      this.getContactData();
      this.getTeachteamAutoid();
      this.getLearningData();
    },
    getUiText(item){
      let request = {uiautoid: item.key};
      axios.get(server.uitext + checker.objTurnParams(request)).then(result=>{
        if(result && result.status && result.data && result.data.result){
          let dataList = result.data.result || [];
          if(dataList.length != 0){
            item.content = dataList[0].content;
          }
        }
      })
    },
    changeTab(item){
      this.getUiText(item);
    },
    handleCopy(data) {
      const _input = document.createElement("input");
      _input.style.opacity = 0;
      _input.style.position = "absolute";
      _input.setAttribute("value", data.value);
      document.body.appendChild(_input);
      _input.select();
      _input.setSelectionRange(0, 9999);
      if (document.execCommand("copy")) {
        document.execCommand("copy");
        ElMessage({
          message: data.label + "复制成功！",
          showClose: true,
          type: "success",
        });
      } else {
        ElMessage({
          message: data.label + "复制失败！",
          showClose: true,
          type: "warning",
        });
      }
      document.body.removeChild(_input);
    },
    handleTab(data) {
      const {active,userData} = this;
      //console.log(active,userData);
    },
    messageClose() {
      this.popupData.state = false;
    },
    handleSubmit() {},
    handlePopup(type,data) {
      switch (type) {
        case "教学情况":
          this.popupData = {
            title: "教学情况",
            state: true,
            content: this.teachteamData.info || "",
            style : {width:'800px'}
          };
          break;
        case "学情详情":
          this.$emit('toClick','跳转至详情');
          break;
        case "师资介绍":
          //console.log(data)
         this.popupData = {
            title: "师资介绍",
            state: true,
            content: data.info || "",
            // style : {width:'400px',height:'400px'}
          };

          break; 
      }
    },
    getContactData() {
      const { autoid } = this;
      let request = {expautoid: autoid};
      axios.get(server.teachsupport + checker.objTurnParams(request)).then((result) => {
          if (result && result.status && result.data.result && result.data.result.length) {
            let cache = result.data.result[result.data.result.length - 1] || {},
            content = cache.content || '';
            content = JSON.parse(content || '{}') || [];
            this.contactData = content;
          }else{
            this.contactData = []
          }
        });
    },
    getTeachteamAutoid(){
      const { autoid } = this;
      let request = {expautoid: autoid};
      axios.get(server.teachteam + checker.objTurnParams(request)).then(result=>{
        if(result && result.status && result.data && result.data.result[0]){
          let teachteamData = result.data.result[0];
          this.teachteamData = teachteamData;
          this.getMainusersData(teachteamData.autoid);
        }
      })
    },
    getMainusersData(teachteamautoid) {
      let request = {teachteamautoid: teachteamautoid},userData = {};
      axios.get(server.teachteammember + checker.objTurnParams(request)).then((result) => {
        if (result && result.status && result.data.result) {
          let dataList = result.data.result || [];
          dataList.map((item) => {
            if(item.member_data){
              if(!userData[item.primary||0])userData[item.primary||0]=[];
              userData[item.primary||0].push({
                key: item.autoid,
                name: item.member_data.realname,
                info:item.info
              })
            }
          });
          //console.log(result,userData);
          this.userData = userData; 
        }
      });
    },
    getLearningData(){
      const { autoid } = this;
      let request = {
        expautoid: autoid,
        get_data_sumary: ''
      }
      axios.get(server.dataanalysis + checker.objTurnParams(request)).then(result=>{
        if(result && result.status && result.data){
          let adata = result.data || {};
          this.adata.forEach(item=>{
            if(item.params == 'exp_life_average'){item.value = (parseFloat(adata['exp_life_average']) / 60).toFixed(2);}
            else{item.value = adata[item.params];}
          })
        }
      })
    }
  },
  components: {
    PageItems,
    Tabs,
    PageHeader,
    TabsNew,
    MessageBoxs,
    Buttons,
  },
};
</script>

<style scoped lang="less">
:deep(.home-content i){
  font-style:italic !important ;
}
:deep(.home-content b){
  font-weight: bold !important;
}
:deep(.item-shade){
  border-bottom: 1px solid #c9c9c9;
}
:deep(.page-item){
  height: 100%;
  margin-bottom: 0;
}
:deep(.tabs-ui .el-tabs__nav-scroll){
  justify-content: center !important;
  padding-left: 1px !important;
  padding: 10px !important;
}
:deep(.el-tabs--border-card > .el-tabs__header .el-tabs__item:first-child){
  margin-left: 1px;
}
:deep(.el-tabs__item){
  margin-right: 0 !important;
  width: 110px;
}
:deep(.el-tabs__item:last-child){
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
:deep(.el-tabs__item:first-child){
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}


// .mess_content{
//     height: 500px;
 
    // overflow: scroll;
   .situation {
    display: inline-block;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    text-align: left;
    height:600px;
    // overflow: auto;
    word-break: break-all;
    padding-top: 10px;
  }
// }

  :deep(.message-content){
    word-break: break-all;
    overflow: auto;
    height: 500px;
  }
.details-ui {
  display: flex;
  background-color: #f2f2f2;
  .details-modules {
    width: 266px;
    flex: 0 0 auto;
    margin-right: 10px;
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    .module-items {
      .module-item {
        width: 100%;
        min-height: 200px;
        .module-btn {
          position: relative;
          top: 6px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #2974ea;
          cursor: pointer;
        }
        .module-title {
          display: flex;
          flex-direction: column;
          font-weight: bold;
          color: #1a1a1a;
          font-size: 18px;
          font-family: Microsoft YaHei;
          text-align: left;
          .module-english {
            font-weight: 400;
            color: #b6b6b6;
            font-size: 14px;
            font-family: Microsoft YaHei;
            display: flex;
            flex-direction: column;

            margin-top: 5px;
            &::after {
              content: "";
              display: inline-block;
              width: 72px;
              height: 3px;
              background-color: #1e5fc2;
              margin-top: 5px;
            }
          }
        }

        .contacts-main {
          padding: 0 22px 20px;
          .contacts-item {
            text-align: left;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            position: relative;
            .icon-shade {
              margin-right: 10px;
              width: 25px;
              height: 25px;
              background-color: rgba(41, 116, 234, 1);
              color: #ffffff;
              text-align: center;
              line-height: 25px;
              border-radius: 4px;
            }
            > span:first-child {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              display: inline-block;
              width: 52px;
              text-align: right;
            }
            .contacts-value {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #333333;
              display: inline-block;
              width: 110px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .contacts-copy {
              position: absolute;
              right: 0px;
              top: 50%;
              transform: translate(0, -50%);
              display: block;
              font-size: 20px;
              font-weight: bold;
              cursor: pointer;
              //box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 10%);
              background-color: #ffffff;
              &:hover {
                color: rgba(41, 116, 234, 1);
              }
              display: none;
            }
            &:hover {
              .contacts-copy {
                display: block;
              }
            }
          }

          .contacts-users {
            .users-label {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
            }
            .users-name {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 10px 0;
              > i {
                font-size: 20px;
                color: #1e5fc2;
                margin-right: 10px;
              }
              > span {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
              }
            }
          }
          .users-list {
            width: 100%;
            .users-item {
              border: 1px solid #e6e6e6;
              padding: 0 10px;
              border-radius: 4px;
              .users-item-li {
                padding: 10px 0;
                border-bottom: 1px solid #f2f2f2;
                text-align: left;
                display: flex;
                align-items: center;
                > i {
                  font-size: 20px;
                  color: #1e5fc2;
                  margin-right: 10px;
                  padding: 0 10px;
                }
                > span {
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  color: #333333;
                }
              }
            }
          }

          .contacts-data {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            > span:nth-child(1) {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
            }
            > span:nth-child(2) {
              width: 70px;
              height: 23px;
              border: 1px solid #6fa8ff;
              border-radius: 4px;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #333333;
              display: inline-block;
              text-align: center;
              line-height: 23px;
              margin-left: auto;
            }
          }
        }
      }
    }
  }
  .details-main {
    min-height: 600px;
    margin-top: 10px;
    flex: 1 1 auto;
    background-color: #ffffff;
    .home-shade {
      display: flex;
      flex-direction: column;
      padding: 20px 0;
      .home-header {
        text-align: center;
        font-weight: bold;
        font-size: 30px;
        color: #333333;
      }
      .home-english {
        font-size: 20px;
        color: #b6b6b6;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px;
        &::after {
          content: "";
          width: 72px;
          height: 3px;
          background-color: #1e5fc2;
          display: inline-block;
          margin-top: 15px;
        }
      }
    }
    .home-content{
      line-height: 25px;
      // text-indent: 2em;
      word-break: break-all;
      // width: 900px;
      // height: 900px;
      overflow: auto;
    }
    >img{
      width:"100%"
    }
    .expresult{
      line-height: 30px;
      font-size: 20px;
    }
    .nodata{
      background-color: #ffffff;
      height:77px;
      line-height: 77px;
      color:#BFBFBF;
      text-align: center;
    }
  }
}
</style>