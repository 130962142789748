<template>
  <layout_20230210_47e9 :mode="'隐藏标题'">

    <div class="main_foot">
      <div class="content_top">
        <div class="block_all" v-for=" item_outer in ListData" :key="item_outer.key">
          <span class="block_title_1" >{{ item_outer._title }}</span>
          <div :class="item_outer._title != '关注我们' ? 'paragraph_block' : 'paragraph_block_img'">
            <div class="_block" v-for="_item_ in item_outer.result" :key="_item_.key">
               <div class="_bolck_in" v-show="_item_.isshow" >
                 <span class="img_block" v-if="item_outer._title == '关注我们'">
                    <img class="paragraph_img" :src="_item_.QRcode" alt="">
                 </span>
                 <div class="secont_titl" style="cursor: pointer;" @click="jumpTo(_item_,'跳转')">
                  {{_item_.subtitle}}
                </div>
               </div>
              <!-- <span v-if="item_outer.type != '关注我们'">
                <span v-show="_item_.isshow">{{ _item_.subtitle }}</span>
              </span>
              <span v-else>
                <div>
                  <img :src="_item_.QRcode" alt="">
                  <span></span>
                </div>
              </span> -->
            </div>
          </div>
        </div>
      </div>
      <div class="content_bottom">
        <div class="footer_main" v-for="item in footerData">
           <span class="foo_title" v-if="item.type=='con'">{{ item.title }}</span>
           <span class="foo_title" v-if="item.type=='pic'">
            <img  class='foo_title_img' :src="item.title" alt="">
           </span>
           <a class="foo_content" target="_blank" :href="item.href" v-if="!!item.href">{{ item.content }}</a>
           <span class="foo_content" v-else>{{ item.content }}</span>
        </div>
         
      </div>
    </div>
  </layout_20230210_47e9>
</template>

<script>
import layout_20230210_47e9 from '@/components/layouts/layout_20230210_47e9.vue';
import axiosTool from '@/utils/axios_tool';
import server from "@/service/server";
export default {
  created(){
    this.axiosTool = new axiosTool(this,[
      {key:'get_link',type:'get',title:'获取友情链接',url:server.footers,params:{
        getreq:{title:'友情链接'}
      }},
      {key:'get_route',type:'get',title:'导航',url:server.footers,params:{
        getreq:{title:'导航'}
      }},
      {key:'get_major',type:'get',title:'专业',url:server.footers,params:{
        getreq:{title:'专业'}
      }},
      {key:'get_concact',type:'get',title:'联系我们',url:server.footers,params:{
        getreq:{title:'联系我们'}
      }},
      {key:'get_care',type:'get',title:'关注我们',url:server.footers,params:{
        getreq:{title:'关注我们'}
      }}
    ])
  },
  mounted(){
      this.axiosTool._axios().then(data=>{
        const [link,route,major,concact,care] =data;
        this.HandleClick(link,'友情链接');
        this.HandleClick(route,'导航');
        this.HandleClick(major,'专业');
        this.HandleClick(concact,'联系我们');
        this.HandleClick(care,'关注我们');
      })
  },
  components: {
    layout_20230210_47e9
  },
  name: "footers",
  data() {
    return {
      ListData: [],
      footerData:[
        {title:'版权:',content:'北京象新力科技有限公司 版权所有' ,type:'con'},
        {title:'技术支持:',content:'北京象新力科技有限公司',type:'con'},
        {title:'',content:' © 2015 - 2021 ',type:'con'},
        {title:require("@/assets/images/xxl_gw/foot-icon.png"),content:'京ICP备17005251号-1',type:'pic'},
        {title:require("@/assets/images/xxl_gw/foot-icon.png"),href : 'https://beian.mps.gov.cn/#/query/webSearch?code=11010802043135',content:'京公网安备11010802043135',type:'pic'},
      
      ]
    }
  },
  methods:{
    jumpTo(data){
      console.log(data)
      if(!!data.linkto){
        window.open(data.linkto)
      }
    },
    HandleClick(data,type){
      let {ListData} = this;
      if(data.status && data.result.length>0){
        if(type=='关注我们'){
          data.result.map(item=>{
            item.QRcode = item.QRcode
          })
        }
          data._title = type;
          ListData.push(data);
      }
    }
  }
}
</script>


<style scoped lang="less">
.layout_20230210_47e9{
  padding-top:0;
}

.main_foot {
  padding:20px;
}

.foo_content{
  text-decoration: none;
  color: #97989D;
}

.content_top {
  display: flex;
  justify-content: space-between;
  /* height: 100px; */
  color: white;
  /* margin: 100px auto;*/
 padding-top:48px; 
}

.block_all {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.block_title_1 {
  font-weight: bolder;
  font-size: 16px;
}

.paragraph_block {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  // height: 200px;
  color: #97989D;
}
._block{
  margin-bottom:15px;
  font-size: 14px;
  
}

.paragraph_block_img {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  // width: 207px;
  ._block{
     margin-bottom:15px;
    font-size: 14px;
    margin-right: 10px;
    ._bolck_in{
      display: flex;
      flex-direction: column;
      .secont_titl{
        //display: inline-block;
        // cursor: pointer;
        margin-top: 10px;
      }
      .img_block{
        // height: 70px;
        text-align: center;

      }
    
    }
  }
}

.paragraph_img{
  width:100px;
}
.content_bottom{
  display: flex;
    justify-content: space-between;
    color: #97989D;
    font-size: 14px;
    margin-top: 38px;
    width: 75%;
    margin: 0 auto;
    margin-top: 50px;
    .footer_main{
      line-height: 20.17px;
      .foo_title{
        margin-right: 5px;
        .foo_title_img{
          display: inline-block;
          vertical-align: middle;
          height: 18px;
        }
      }
    }
}
</style>