<template>
    <div class="header-ui common-width">
       <div class="header-logo">
            <img :src="base_url + 'images/platform_logo.png'" alt="">
        </div>
        <div class="header-menu">
            <div class="header-menu-bar">
                <span class="header-menu-chinese">{{_globalJson.title}}</span>
                <span class="header-menu-english">{{_globalJson.english}} </span>
            </div>
        </div>
        <div class="header-user">
            <!-- 已登录状态时 显示的内容 -->
            <template v-if="isLogin">
                <el-dropdown @command="handleCommand">
                    <span class="header-user-action">
                        <el-avatar :key='userInfo.face'>
                            <img :src="userInfo.face" alt="logo">
                        </el-avatar>
                        <span class="user-name">{{userInfo.realname}}</span>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item :command="action.command" 
                            v-for="action in userActions" 
                            :icon="action.icon" 
                            :divided="action.divided" :disabled="action.disabled" :key="action.key">
                                {{action.label}}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </template>
            <!-- 未登录状态时 显示的内容 -->
            <template v-else>
                <!-- <div class="header-btns"> -->
                    <span class="header-btns-login" @click='toLogin("登录")'>
                        <i style="color:#4976e8" class="xxl-yonghu"></i>
                        登录</span>
                    <!-- <span>|</span> -->
                    <span class="header-btns-register" @click='toLogin("注册")'>注册</span>
                    <!-- <span class="header-btns-login" @click='toLogin("登录")'>登录</span>
                    <span class="header-btns-register" @click='toLogin("注册")'>注册</span> -->
                <!-- </div> -->
            </template>
        </div>
    </div>
</template>

<script>
import common from '@/utils/common';
import axios from '@/utils/axios';
import checker from '@/utils/checker';
import server from '@/service/server';
import { mapActions } from "vuex";
const USER_ACTIONS = [
    {key : 'pcenter',label:'个人中心',icon:'',command : 'pcenter'},
    {key : 'quit',label:'退出登录',icon:'SwitchButton',divided:true,command : 'quit'},
]
export default {
    data (){
        return {
            userActions : checker.cloneObj(USER_ACTIONS),
            isLogin : false,//true 已登录 false 未登录 默认未登录
            userInfo : {},//存储用户当前信息
            base_url:process.env.BASE_URL,
        }
    },
    components : {
    },
    watch: {
        '$store.state.user.userInfo' : 'monitorUserInfo',
        '$route.path' : 'monitorPath',
    },
    beforeMount (){
        let userinfo = this.$store.state.user.userInfo
        this.monitorUserInfo(userinfo);
    },
    methods : {
        monitorPath (){
            this.userInfo = this.$store.getters.userInfo;
        },
        monitorUserInfo (userInfo){//监控登录状态
            if(!userInfo || !userInfo.autoid){
                this.userInfo = {};
                this.isLogin = false;
                return ;
            }
            this.isLogin = true;
            if(!userInfo.face){
                userInfo.face = require('@/assets/images/user_logo.png');
            }
            this.userInfo = userInfo;
            this.handleActions();
        },
        toLogin (type){//跳转值登录页
            if(type == '登录'){
                this.setLoginsign('登录');
                this.$router.push('/login');
            }else{
                this.setLoginsign('注册');
                this.$router.push('/login');
            }
        },
        handleActions (){
            const {userInfo} = this;
            let userActions = checker.cloneObj(USER_ACTIONS),idx = 0;
            switch (userInfo.role){
                case '1' : //管理员
                    if(detection('admin'))return;
                    userActions.unshift({key : 'admin',label:'管理员工作台',icon:'',command : 'admin'},)
                    break;
                case '3' : //教师
                    if(detection('teacher'))return;
                    userActions.unshift({key : 'teacher',label:'教师工作台',icon:'',command : 'teacher'},)
                    break;
            }
            this.userActions = userActions;
            function detection (username){//检测是否存在相同的菜单
                let idx = userActions.findIndex(item=>item.key === username);
                return idx>=0;
            }
        },
        handleCommand (command){
            const {userInfo} = this;
            let query;
            switch (command){
                case 'pcenter' : //个人中心
                    this.$router.push('/my_center');
                    break;
                case 'quit' : //退出登录
                    this.$confirm('即将退出登录，是否继续？', '消息提醒', { 
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.deleteToken();
                    }).catch(() => {});
                    break;
                default : //管理员工作台 || 教师工作台
                    //window.open(this.GLOBALPATH + '/admin');
                    window.open(this.ADMINGLOBALPATH + '/admin/');
                    //console.log(this.ADMINGLOBALPATH + '/admin/');
                    // common.setStorParams({path:'/admin'});
                    // this.$router.push('/admin');
                    break;
            }
        },
        deleteToken (){
            axios.post(server.token,{delete_data:true}).then(result=>{
                if(result.status){
                    localStorage.clear();
                    sessionStorage.clear();
                    this.$router.push('/login');
                }else {
                    window.location.reload();
                }
            })
        },
        ...mapActions(["setLoginsign"]),
    },
    
}
</script>

<style scoped lang='less'>
    :deep(.el-avatar>img){
        width: 40px;
    }

    @import url('@/styles/common.less');//公共样式
    .media;//媒体查询
    .header-ui{
        width:100%;
        display: flex;
        justify-content: center;
        background-color: #FFFFFF;
        padding:10px 0;
        height:60px;
        margin: auto;
        .header-logo{
            flex:0 0 auto;
            //width: 300px;
            text-align: left;
            >img{
                // width: 288px;
                height:60px;
                margin-right: 10px;
            }
        }
        .header-menu{
            margin-right:auto;
            flex:0 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height:100%;
            //margin-left: 30px;
            // text-align: left;
            .header-menu-bar{
                border-left: 1px solid #080808;
                display: flex;
                flex-direction: column;
                padding-left:30px;
                margin-left:30px;
                .header-menu-chinese{
                    font-size: 24px;
                    padding-bottom: 12px;
                }
                .header-menu-english{
                    font-size: 12px;
                }
            }
            
        }
        .header-user{
            margin-left:auto;
            flex:0 0 auto;
            display: flex;
            align-items: center;
            .header-user-action{
                display: flex;
                align-items: center;
                .user-name{
                    margin-left:10px;
                }
            }
            .header-btns{
                display: flex;
                // flex-direction: column;
                >span{
                    width: 82px;
                    height: 33px;
                    border-radius: 4px;
                    line-height: 33px;
                    border: none;
                    text-align: center;
                    display: block;
                    padding: 0;
                    margin-bottom: 3px;
                    font-size: 12px;
                    font-weight: bold;
                    cursor: pointer;
                }
            }
            .header-btns-login{
                // background: #2974EA;
                // box-shadow: 0px 3px 14px 0px rgb(41 116 234 / 33%);
                // margin-bottom: 3px;
                // color:#FFFFFF;
                cursor: pointer;
                color: #4976e8;
                margin-right: 10px;
                font-family: Microsoft YaHei;
            }
            .header-btns-register{
                // font-size: 12px;
                // background-color: rgb(236, 245, 255);
                // box-shadow: 0px 3px 14px 0px rgb(236 245 255 / 33%);
                cursor: pointer;
                color: #4976e8;
                margin-left: 10px;
                position: relative;
                font-family: Microsoft YaHei;
                &::before{
                    content: "";
                    background: #cfcfcf;
                    height: 17px;
                    width: 1px;
                    position: absolute;
                    left: -11px;
                }
            }
        }
    }
</style>