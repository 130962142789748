<template>
 <div class="top_bkg"></div>
  <div class="exps-ui common-width">
   
    <Screen @toChange="searchChange"></Screen>
    <div class="exps-main">
      <Projects :source="dataList" @toClick="handleExpClick"> </Projects>
      <Pagination
        v-if="dataList.length"
        :total="paging.total || 0"
        :pageSize="paging.pageSize || 10"
        :currentPage="paging.currentPage || 1"
        @pageChange="pageChange"
      />
    </div>
  </div>
</template>
<script>
import Screen from "@/components/business/Screen/index.vue";
import Projects from "@/components/business/Projects/index.vue";
import Pagination from "@/components/course/Pagination.vue";
import axios from "@/utils/axios";
import server from "@/service/server";
import common from "@/utils/common";
import axiosTool from '@/utils/axios_tool';

export default {
  data() {
    return {
      paging: {
        total: 0,
        pageSize: 12,
        currentPage: 1,
      },
      pageTopTotal: 0,
      searchData: {},
      dataListTop: [],
      dataList: [],
      axiosTool : {},
    };
  },
  created (){
    this.axiosTool = new axiosTool(this,[
      {type:'get',title:'获取实验列表',url:server.exp,params:{
        template : 'searchData:master_realname|_order_column|_order_style|expdeclareyearid|title|subjectautoid|major|level|post_level,paging:page=currentPage|per_page=pageSize',
        getreq:{publish:2,exp_center_ranking:true},
      }},
      {key:'exp_count',type:'get',title:'获取实验列表总数',url:server.exp,params:{
        template : 'searchData:master_realname|_order_column|_order_style|expdeclareyearid|title|subjectautoid|major|level|post_level',
        getreq:{publish:2,exp_center_ranking:true,count:true},
      }},
      {type:'post',title:'添加浏览记录',url:server.exppv},
    ]);
  },
  mounted() {
    this.axiosTool.loading = true;
    this.axiosTool._axios().then(data=>{
      const [expdata,expcount] = data;
      this.getExps(expdata);
      this.axiosTool.loading = false;
      this.getExpsCount(expcount);
   
    })
  },
  methods: {
    queryTable (){
     this.axiosTool.loading = true;
      this.axiosTool.exp().then(data=>{
           this.axiosTool.loading = false;
        this.getExps(data);
        this.axiosTool.exp_count().then(expcount=>{
          this.getExpsCount(expcount);
        });
      })
    },
    pageChange(currentPage) {
      this.paging.currentPage = currentPage;
      this.queryTable();
    },
    handleExpClick(data) {
      //跳转至 实验详情
      this.postExppv(data.autoid);
      common.setStorParams(this, {autoid: data.autoid});
      this.$router.push("/expcore");
    },
    searchChange(data) {
      //搜索实验中心的实验数据
      this.searchData = data;
      this.paging.currentPage = 1;
      this.queryTable();

    },
    getExps(data) {//获取非置顶实验列表
      
      let dataList = [],
      levelMap = {1:'国家级',2:'省级',3:'非申报',4:'其他'};
      if(data && data.result){
        data.result.map(item=>{
          item.key = item.autoid;
          item.faceUrl = item.face || require("@/assets/images/default_exp.jpg");
          item.level = levelMap[item.level] || "";
          if (item.master_data) {
            item.realname = item.master_data.realname;
          }else{
            item.realname = '';
          }
          if (item.major_data) {
            item.major = item.major_data.name;
          }else{
            item.major = '';
          }
          if (item.expdeclareyear_data) {
            item.expdeclareyear = item.expdeclareyear_data.year;
          }else{
            item.expdeclareyear = '';
          }
          dataList.push(item);
        })
      }
      this.dataList = dataList;
    },
    getExpsCount(data) { //查询非置顶实验列表总数
      if(!data)return;
      this.paging.total = parseInt(data.data);
    },
    postExppv(autoid){//浏览量
      this.axiosTool.loading = true;
      this.axiosTool.exppv({},data=>{
        const {postreq} = data;
        postreq.expautoid = autoid
        return {...data,postreq}
      }).then(result=>{
          //console.log(result);
          this.axiosTool.loading = false;
      })
    },
  },
  components: {
    Screen,
    Projects,
    Pagination,
  },
};
</script>

<style scoped lang="less">
  @import url("@/styles/common.less"); //公共样式
  .top_bkg{
    height: 135px;
    background-color: moccasin;
  }
  .media; //媒体查询
  .exps-ui {
    width: 100%;
    margin: auto;
    .exps-main {
      padding: 0 20px 20px;
      background-color: #ffffff;
      border-radius: 4px;
      text-align: center;
    }
  }
</style>