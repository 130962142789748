<template>
    <div class="solution-ui">
        <template v-for="item in config.children" :key="item.key">
            <component :is="item.component"></component>
        </template>
    </div>
</template>

<script>
import _ from '@/views/Home/config';
import components from './index.js';
export default {
    data() {
        return {
            config: {},
        }
    },
    created() {
        this.config = _['solution'];
    },
    components: {
        ...components,
    }
}
</script>

<style scoped lang="less">
</style>