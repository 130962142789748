<template>
    <!-- 核心技术 -->
    <layout_20230210_47e9>
        <template #title>核心技术</template>
        <template #describe>
            <span class="second_shade" style="text-align: center; display: block;">
                {{ introduction }}
            </span>
        </template>
        <div class="main"  @mouseleave="MouseLeave">
            <el-tabs class="mian_blockk" v-model="active" @tab-click="changeTab">
                <el-tab-pane v-for="menu in list_tech_Data" :key="menu.key" :name="menu.key" >
                    <template #label>
                        <span class="menuIcon">
                            <img :src="active==menu.key ? menu.logo_url_active :menu.logo_url" alt="" >
                            {{ menu.title }}
                        </span>
                    </template>
                            <div class="exam_block" v-for="exam_item in list_tech_Data" :key="exam_item.key" >
                                <div class="main_c" v-if="exam_item.key==active"  @mouseenter="changeTab">
                                    <div class="block_left" >
                                        <span class="title">{{ exam_item.subtitle }}</span>
                                        <span class="paragraph">{{ exam_item.describe }}</span>
                                        <span class="button"  v-if="!(exam_item.button=='false' )" @click="handleClick('跳转至象课堂',exam_item.to_href)">{{exam_item.btn_name}}</span>
                                    </div>
                                        <div class="block_right">
                                            <span v-if="exam_item.url_type === 'img'">
                                                <img class="block_img" :src="exam_item.url" alt="">
                                            </span>
                                            <span v-else>
                                                <Videos  v-if="exam_item.url.substr(exam_item.url.match(/\.(?!.*\.)/).index+1) ==='mp4'" :videoUrl="exam_item.url"  id="videoT" @startVi="startVideo"></Videos>
                                            </span>
                                        </div>
                                </div>
                            </div>
                </el-tab-pane>
            </el-tabs>
           
        </div>
    </layout_20230210_47e9>
</template>

<script>
import layout_20230210_47e9 from '@/components/layouts/layout_20230210_47e9.vue';
import axios from "@/utils/axios";
import server from "@/service/server";
import checker from '@/utils/checker';
import axiosTool from '@/utils/axios_tool'
import { ElMessage } from "element-plus";
import Videos from '@/components/course/Videos.vue';
export default {
    name: "ctechnic",
    components: {
        layout_20230210_47e9,
        Videos,
    },
    props: {

    },
    watch:{
        // 'active' : 'changeTab'
    },
    mounted() {
        this.current_tech_data = this.list_tech_Data[0];
        this.getData()
        // 自动切换效果
       // this.AutoChangeTab()   !!!
    },
    created(){
        this.axiosTool = new axiosTool(this,[
            {
                key:'RequestIntro',
                type:'get',
                title:'查询介绍内容',
                url:server.block,
                params: { getreq: {mode:true} },
            },
            {
                key:'RequestBlock',
                type:'get',
                title:'查询板块',
                url:server.block,
                params: { getreq: {typeid:5} },
            }
        ])
    },
    data() {
        return {
            active: '',
            autoTab:'',
            autotype : false,
            // currentIndex:'',
            current_tech_data: {},
            list_tech_Data: [
                // {
                //     key: '001', title: "实时仿真", descirbe: "采用了高性能的实时数据库和多任务并行调度技术，仿真精度可精确到毫秒级，并提供了各种数据通讯接口，可适用于各类实时仿真系统和半实物仿真系统的开发和运行。", 
                //     activeIcon: require('@/assets/images/xxl_gw/1-pre.png'),
                //     defaultIcon:require('@/assets/images/xxl_gw/1-def.png'),
                //     rightpic: require('@/assets/images/xxl_gw/核心技术1.png'),
                //     subtitle:'可视化三维可搭建虚拟仿真软件的先行者',
                //     isButton:true
                // },
                // {
                //     key: '002', title: "设计验证", descirbe: "能动、机电、电气、机械等多学科交叉融合，进行虚实一体的仿真实训、设计验证操作",
                //     activeIcon: require('@/assets/images/xxl_gw/2-pre.png'),
                //     defaultIcon:require('@/assets/images/xxl_gw/2-def.png'),
                //     rightpic: require('@/assets/images/xxl_gw/核心技术1.png'),
                //     subtitle:'可视化维可搭建虚拟仿真软件的先行者'
                // },
                // {
                //     key: '003', title: "设计验证", descirbe: "能动、机电、电气、机械等多学科交叉融合，进行虚实一体的仿真实训、设计验证操作",
                //     activeIcon: require('@/assets/images/xxl_gw/3-pre.png'),
                //     defaultIcon:require('@/assets/images/xxl_gw/3-def.png'),
                //     rightpic: require('@/assets/images/xxl_gw/核心技术1.png'),
                // },
                // {
                //     key: '004', title: "设计验证", descirbe: "能动、机电、电气、机械等多学科交叉融合，进行虚实一体的仿真实训、设计验证操作",
                //     activeIcon: require('@/assets/images/xxl_gw/4-pre.png'),
                //     defaultIcon:require('@/assets/images/xxl_gw/4-def.png'),
                //     rightpic: require('@/assets/images/xxl_gw/核心技术1.png'),
                // },
              
            ],
            introduction:''
        }
    },

    methods: {
        startVideo(data){
            if(data=='true'){
                this.autotype =false;
            }else{
                 this.autotype =true;
            }
        },
        getData(){
            this.axiosTool.RequestIntro().then(data=>{
                if(!!data && !!data.result && data.result[0]){
                    this.introduction = data.result[0].content;
                }
            })
            this.axiosTool.RequestBlock().then(data=>{
                
                this.handleDataList(data)
            })

        },
        handleDataList(res){
        var {list_tech_Data} = this;
        if(!!res && res.data && res.data.result){
            this.active = res.data.result[0].autoid
           res.data.result.map(item=>{
            item.key = item.autoid
            if(item.url && item.url.indexOf('.mp4')>=0){
                item.url_type = 'video';
            }else{
                item.url_type = 'img';
            }
            list_tech_Data.push(item);
            
           })
        }
       },
        changeTab(){
           window.clearInterval(this.autoTab)
        },
       // 检测鼠标移出事件
        MouseLeave(){ 
            var curIndex = '';
            for(let i=0 ;i<this.list_tech_Data.length;i++){
                if(this.active==this.list_tech_Data[i].key){
                    curIndex = i;
                }
            }

            this.AutoChangeTab(curIndex)
        },
        AutoChangeTab(currentIndex){
            var {list_tech_Data} = this;
            let _this = this;
            if(!currentIndex){
                var currentIndex = 0
            }
           _this.autoTab = setInterval(async () =>{
                if(list_tech_Data[currentIndex].key == _this.active){
                    if(currentIndex< list_tech_Data.length-1){
                        _this.active = list_tech_Data[currentIndex+1].key;
                        currentIndex =currentIndex+1 ;
                        
                    }
                    else{
                        currentIndex= 0;
                        _this.active= list_tech_Data[currentIndex].key 
                    }
                }
            },3000)
        },
        handleClick(type, data) {
            switch (type) {
                case "鼠标移入":
                    this.active = data.key;
                    this.current_tech_data = data;
                    break;
                case '跳转至象课堂' : 
                    window.open(data);
                    break;
            }
        }
    }
}
</script>

<style scoped lang="less">
// :deep(.el-tabs .el-tabs--top){
//   width: 93%;
//     margin: 0 auto;
// }
.fade-enter,
    .fade-leave-to {
        transform: translateX(-20%);
    }
 
    .fade-enter-active,
    .fade-leave-active {
        transition: 6s;
    }
 
    .fade-enter-to {
        transform: translateX(-30%)
    }

:deep(.el-tabs__item){
    font-size: 18px;
}

:deep(.mian_blockk) {
    margin-bottom: 15px;
    .menuIcon{
        display: flex;
        flex-direction: column;
        line-height: 21px;
        text-align: center;
    }

    .el-tabs__nav-scroll {
        display: flex;
        justify-content: space-around;
        height: 108px;

        .el-tabs__nav {
            display: flex;
            width: 71%;
            margin: 0 auto;
            justify-content: space-between;

            .el-tabs__item:hover {
                color: #FFA400;
            }

            .el-tabs__active-bar {
                width:86px !important;
                background-color: #FFA400;
            }
          
            .el-tabs__item.is-active {
                // background-color: #FFA400 !important;
                color: #FFA400 !important;
                border-color: #FFA400 !important;
            }
        }
    }
}

:deep(.el-tabs__content){
    overflow: visible;
}
.exam_block{
        .main_c {
            display: flex;
            padding-top:10px;
            padding-left: 37px;
            padding-right: 38px;
            height: 360px;
            .block_left{
                display: flex;
                flex-direction: column;
                width: 50%;
                .title{
                    font-size: 24px;
                    width: 40%;
                    line-height: 42px;
                    color: #353535;
                }
                .paragraph{
                    width: 69%;
                    font-size: 16px;
                    line-height: 24px;
                    margin-top: 6%;
                    font-family: MicrosoftYaHei;
                    color: #565656;
                    height:140PX;
                }
                .button{
                    margin-top: 6%;
                    border: 1px solid #F18D00;
                    padding: 10px;
                    background-color: #F18D00;
                    color: white;
                    margin-right:auto;
                    cursor: pointer;
                    text-align: center;
                    border-radius: 4px;
                }
            }
            .block_right{
                // position: absolute;
                // left: 50%;
                // >span{
                //     >img{
                //         width: 80%;
                //     }
                // }
               
            }
            .block_img{
                width:670px;
                height:341px;
            }
        }
    }
.content_block_tab {
    display: grid;
    justify-content: flex-start;
    grid-template-columns: repeat(4,1fr);
    gap:10px;
    
   
}
.content_block_tab_null{
    text-align: center;
    >span{
        margin: 0 auto;
        >img{
            width: 500px;
        }
    }
}
.block_describe{
    text-align: center;
    margin-top: 62px;
    color: #686969;
}

.block_describe_tabs{
  text-align: center;
  margin-top: 62px;
  font-size: 14px;
  color: #686969;
}
.learn_more{
  width: 121px;
  background-color: #FFA400;
  color: white;
  text-align: center;
  height: 35px;
  line-height: 33px;
  margin: 30px auto;
  cursor: pointer;
  border-radius: 3px;
}
</style>
