<template>
    <div ref="mode" class="layout_20230210_47e9 common-width">
        <div class="layout-head" v-if="mode !== '隐藏标题'">
            <span class="head-title">
                <img class="line" v-if="mode === '标题线'" :src="`${base_url}images/home/title_line.png`" alt="">
                <slot name="title"></slot>
            </span>
            <span class="head-describe">
                <slot name="describe"></slot>
            </span>
        </div>

        <div class="layout-content">
            <slot></slot>
        </div>
        <div class="line-blocc">
            <slot name="line"></slot>

        </div>

    </div>
</template>

<script>
export default {
    name: 'layout_20230210_47e9',
    data() {
        return {
            top: '',
        }
    },
    mounted() {
        this.$nextTick(this.get_top);
        window.addEventListener('resize', this.resize, false)
    },
    methods: {
        resize() {
            this.get_top();
        },
        get_top() {
            const { $refs: refs } = this;
            const mode = refs.mode;
            if(!mode){
                return;
            }
            this.top = mode.getBoundingClientRect().top;
        },
    },
    props: {
        mode: {
            type: String,
            default: '默认',//标题线
        },

    },
    unmounted() {

    },
}
</script>

<style scoped lang="less">
@import url('@/assets/less/theme.less');
@import url('@/styles/common.less');

.layout_20230210_47e9 {
    flex: 1 1 auto;
    margin: auto;

    .layout-head {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 10px;

        .head-title {
            position: relative;
            color: #353535;
            font-size: 28px;
            padding: 10px 0 30px;
            display: inline-block;
            width: 100%;
            text-align: center;
            font-weight: 600;
        }

        .head-title .line {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .head-describe {
            color: #686969;
            font-size: 16px;
            line-height: 30px;
            text-align: justify;
        }
    }

    .layout-content {}

    .line-blocc {
        display: flex;
        width: 1400px;
        text-align: center;
        position: relative;
        padding-top:20px;
    }
}

.media;</style>