<template>
  <div class="header-ui">
        <div class='header-banner'>
            <el-carousel height="300px" :interval= '3000' v-if='cache && cache.faces && cache.faces.length>1' :autoplay="autotype">
                <!-- <el-carousel height="300px"   v-if='cache && cache.faces && cache.faces.length>1'> -->
                <el-carousel-item v-for="(url,key) in (cache && cache.faces)" :key="key">
                    <!-- 'wwwww.mp4'.substr('wwwww.mp4'.match(/\.(?!.*\.)/).index+1); -->
                    <Videos  v-if="url.substr(url.match(/\.(?!.*\.)/).index+1) ==='mp4'" :videoUrl="url"  id="videoT" @startVi="startVideo"></Videos>
                    <img v-else class='banner-singin' :src="url" alt="">
                </el-carousel-item>
            </el-carousel>
            <img class='banner-singin' v-else :src="cache && cache.faces && cache.faces[0] || ''" alt="">
        </div>
      
      <div class="header-main">
            <span class="header-title">{{cache.title}}</span>
            <div class="header-msg">
                <span><el-rate v-model="uistarValue" text-color="#FF635F" allow-half disabled show-score score-template="{value}" :colors="evaluateColors"> </el-rate></span>
                <span>所属专业大类：{{cache.subject || '专业大类'}}</span>
                <span>对应专业：{{cache.major || '专业名称'}}</span>
            </div>
            <div class="header-msg">
                <span>负责教师：{{cache.realname}}</span>
                <span>{{cache.level}}</span>
                <span>{{cache.expdeclareyear}}</span>
                <!-- <div class="header-msg-money">金币兑换：<i></i><span class="money">1000</span>  <span class="state">永久有效</span></div> -->
            </div>
            <!-- <span class="header-content" :title="cache.explain">{{cache.explain || ''}}</span> -->
            <span class="header-content" >
                <span class="content-detail">  {{cache.explain || ''}}</span>
            </span>
            <div class="header-actions">
                <template v-if="userRole === '1'">
                    <Buttons v-if="expStyle !== 'emulate'" type="blue" @click="startExp(cache)">开始实验</Buttons>
                </template>
                <template v-else>
                    <Buttons v-if="verifystate === 0 || verifystate === 3" type="blue" @click="applyExp(cache.autoid)">申请实验</Buttons>
                    <Buttons v-if="verifystate === 1" type="blue" disabled>申请中</Buttons>
                    <Buttons v-if="verifystate === 2 && expStyle !== 'emulate'"  type="blue" @click="startExp(cache)">开始实验</Buttons>
                </template>
                <!-- <Buttons type="blue" icon="el-icon-shopping-cart-1" @click="shopping(cache.autoid)">加入购物车</Buttons> -->
                <div class="actions-main">
                    <span class="actions-btn help">
                        <i class="actions-btn-icon" title="点击复制实验地址" @click="handCilck('复制地址')">
                            <img src="@/assets/images/copy.png" alt="">
                        </i>
                    </span>
                    <span class="actions-btn help">
                        <i class="actions-btn-icon" @click="handCilck(likeData.label,cache)">
                            <img v-if="!likeData.status" src="@/assets/images/help.png" alt="">
                            <img v-else src="@/assets/images/help_red.png" alt="">
                        </i>
                        {{likeData.count || 0}}
                    </span>
                    <span class="actions-btn collect">
                        <i class="actions-btn-icon" @click="handCilck(collectData.label,cache)">
                            <img v-if="!collectData.status" src="@/assets/images/collect.png" alt="">
                            <img v-else src="@/assets/images/collect_yellow.png" alt="">
                        </i>
                        {{collectData.count || 0}}
                    </span>
                    <!-- <span class="actions-btn" v-for="item in likeData" :key="item.key">
                        <i class="actions-btn-icon" @click="handleClick(item,cache)">
                            <img :src="item.imgUrl" alt="">
                        </i>
                        {{item.likeNum}}
                    </span> -->
                </div>
            </div>
      </div>
  </div>
</template>

<script>
import Buttons from "@/components/course/Buttons.vue"
import axios from '@/utils/axios';
import server from '@/service/server';
import common from '@/utils/common';
import checker from '@/utils/checker';
import Videos from '@/components/course/Videos.vue';
import { ElMessage } from "element-plus";
export default {
    data (){
        return {
            autotype:true,
            emulateStart : null,
            evaluateColors : ['rgba(255,49,44,.3)', 'rgba(255,49,44,.6)', 'rgba(255,49,44,1)'],
            uistarValue : 0,
            cache : {},
            verifystate: '',
            likeData:{
                status: false,
                count: 0,
                label: '点赞'
            },
            collectData:{
                status: false,
                count: 0,
                label: '收藏'
            },
            userRole: '',
            websocketParam : {
                uiautoid : 706,
                uiresourceautoid:99,
                mark:"zdff0sdfds1651129873892",
                websitewsip:"119.23.40.38",
                websitewsport:"16800",
                websitepathname:"/declare",
                web:"1",
                projectname:"",
                expname:"",
                token:"",
                serveraddress:"http://119.23.40.38:16800/declare",
                user_username:"admin",
                user_realname:"",
                user_role:"admin",
                exp_title: "配电网单相接地故障保护虚拟仿真实验",
                explib_title: "1000MW火电实验库",
                
            },
            userinfo : {},
            expStyle: '',
            VideoType:false
        }
    },
    watch : {
        'data' : 'monitorData',
        '$store.getters.userInfo' : 'monitorUserInfo',
        //  videoT:'videoMonitor'
    },
    created(){
        this.judgeUser();
        
    },
    mounted (){
        this.monitorUserInfo();
        this.monitorData();
        this.VideoType = false;
        // let video = document.getElementById("videoT")
        // this.videoT = video.onplay;
    },
    components : {
        Buttons,
        Videos,
    },
    methods : {
        // videoMonitor(){
        //    console.log("hahahah")
        // },
        startVideo(data){
            if(data=='true'){
                this.autotype =false;
            }else{
                 this.autotype =true;
            }
        },
        monitorUserInfo (userinfo=this.$store.getters.userInfo){
            this.userinfo = userinfo;
        },
        downloadExp (data){
            if(window.__expConfig__ && window.__expConfig__.downloadExp){
                window.__expConfig__.downloadExp(data);
            }else {
                this.$message.error('下载失败')
            }
            // const {cache} = this;
            // if(cache.resource_data && cache.resource_data.filepath){
            //     let resource = cache.resource_data;
            //     console.log(resource.filepath);
            //     window.open(resource.filepath);
            //     //common.downloadFile(resource.filepath,resource.name);
            // }
        },
       
        openExp(data){
            const {userinfo} = this;
            if(data.style === 'pcemulate' || data.style =='emulate'){
                this.getUi(data,result=>{
                    let token = sessionStorage.getItem('token'),
                    location = window.location;
                    let exname = data.resource_data.expname[0];
                    let pojname = data.explain;
                    this.websocketParam = {
                        projectname: encodeURI(encodeURI(exname || "")),
                        expname: encodeURI(encodeURI(exname || "")),
                        downloadEnable: true, 
                        autostart: true,
                        objectid: "",
                        studyno: "",
                        keyid: "",
                        web:1,
                        mark:"zdff0sdfds1651129873892",
                        expautoid : data.autoid,
                        uiautoid : result.autoid,
                        uiresourceautoid : data.resourceid,
                        token : token,
                        websitewsip:location.host,
                        websitewsport:location.port || (window.location.protocol === 'https' ? '443' : '80'),
                        websitepathname:process.env.BASE_URL,
                        username : userinfo.username,
                        realname : userinfo.realname,
                    };
                    this.startPcExp();
                    this.postExpuv(data.autoid);
                })
            }else {
                this.openWebgl(data);
                if(this.userRole == '1'){
                    this.applyExp(data.autoid);
                }
            }
        },



        judgeUser(){
            let request = {autoids: window.localStorage.getItem("userAutoid"), get_users_info: true}
            axios.get(server.userinfo + checker.objTurnParams(request)).then(result=>{
                if(result.status && result.data){
                    this.userRole = result.data.result[0].role;
                }
            })
        },
        monitorData (data=this.data){

            switch(data.level){
                case "1":
                  data.level = "国家级";
                  break;
                case "2":
                  data.level = "省级";
                  break;
                case "3":
                  data.level = "非申报";
                  break;
                case "4":
                  data.level = "其他";
                  break;
            }
            
            if(data.subject_data){
                data.subject = data.subject_data.name;
            }

            if(data.master_data){
                data.realname = data.master_data.realname;
            }

            if(data.major_data){
                data.major = data.major_data.name;
            }

            if(data.expdeclareyear_data){
                data.expdeclareyear = data.expdeclareyear_data.year;
            }

            if(data.face){
                data.faces = data.face.split(',');
            }

            if(data.resource_data){
                this.expStyle = data.resource_data.resourcetype;
            }

            this.cache = data;

            if(Object.keys(data).length != 0){
                this.getLike(data);
                this.getCollect(data);
                this.getExpapply(data.autoid);
                this.getUistarValue(data.autoid);
            }

        },
        getUistarValue(expautoid){//获取实验评分星级
            let request = {
                expautoid: expautoid,
                get_avgstar: true
            }
            axios.get(server.uistar + checker.objTurnParams(request)).then(result=>{
                if(result.status){
                    this.uistarValue = parseFloat(parseFloat(result.data || 0).toFixed(1));
                }else{
                    this.uistarValue = 0
                }
            })
        },
        getLike(data){//获取点赞数
            let requestLike = {
                targettable: 'exps',
                expautoid: data.autoid,
                targetautoid: data.autoid,
                count: true,
                like: 1
            }
            axios.get(server.collectstar + checker.objTurnParams(requestLike)).then(result=>{
                if(result.status){
                    this.likeData.count = Number(result.data.count)
                    this.likeData.status = result.data.like
                }
            })
        },
        getCollect(data){//获取收藏数
            let requestCollect = {
                targettable: 'exps',
                targetautoid: data.autoid,
                expautoid: data.autoid,
                count: true,
                collect: 1
            }
            axios.get(server.collectstar + checker.objTurnParams(requestCollect)).then(result=>{
                if(result.status){
                    this.collectData.count = Number(result.data.count)
                    this.collectData.status = result.data.collect
                }
            })
        },
        postCollectstar(request,cache){//点赞收藏
            axios.post(server.collectstar, request).then(result=>{
                if(result.status && result.data && result.data.result && result.data.result.length){
                    ElMessage.success('操作成功！');
                    this.getLike(cache);
                    this.getCollect(cache);
                }else{
                    ElMessage.error(result.data || result.msg || '操作失败');
                }
            })
        },
        postCancel(label,cache){//取消
            let request = {};
            switch(label){
                case '点赞':
                    request = {
                        targettable: 'exps',
                        targetautoid: cache.autoid,
                        delete_data: true,
                        expautoid: cache.autoid,
                        like: 1
                    }
                    axios.post(server.collectstar, request).then(result=>{
                        if(result.status && result.data){
                            ElMessage.success('操作成功！');
                            this.getLike(cache);
                        }else{
                            ElMessage.error(result.data || result.msg || '操作失败');
                        }
                    })
                    break;
                case '收藏':
                    request = {
                        targettable: 'exps',
                        targetautoid: cache.autoid,
                        delete_data: true,
                        expautoid: cache.autoid,
                        collect: 1
                    }
                    axios.post(server.collectstar, request).then(result=>{
                        if(result.status && result.data){
                            ElMessage.success('操作成功！');
                            this.getCollect(cache);
                        }else{
                            ElMessage.error(result.data || result.msg || '操作失败');
                        }
                    })
                    break;
            }
        },
        handCilck(label,cache){
            switch(label){
                case '点赞':
                    if(this.likeData.status){
                        this.postCancel(label,cache);
                    }else{
                        let likeRequest = {
                            targettable: 'exps',
                            targetautoid: cache.autoid,
                            like: 1,
                            expautoid: cache.autoid
                        }
                        this.postCollectstar(likeRequest,cache);
                    }
                    break;
                case '收藏':
                    if(this.collectData.status){
                        this.postCancel(label,cache);
                    }else{
                        let collectRequest = {
                            targettable: 'exps',
                            targetautoid: cache.autoid,
                            collect: 1,
                            expautoid: cache.autoid
                        }
                        this.postCollectstar(collectRequest,cache);
                    }
                    break;
                case '复制地址' : 
                    this.handleCopy();
                    break;
            }
        },

        handleCopy() {
            const {cache} = this;
            let url = this.GLOBALPATH+'/#/expcore?espace=true&expautoid=' + common.Encrypt(cache.autoid);
            const _input = document.createElement("input");
            _input.style.opacity = 0;
            _input.style.position = "absolute";
            _input.setAttribute("value", url);
            document.body.appendChild(_input);
            _input.select();
            _input.setSelectionRange(0, 9999);
            if (document.execCommand("copy")) {
                document.execCommand("copy");
                ElMessage({
                message: "复制成功！",
                showClose: true,
                type: "success",
                });
            } else {
                ElMessage({
                message: "复制失败！",
                showClose: true,
                type: "warning",
                });
            }
            document.body.removeChild(_input);
        },
        applyExp(autoid){
            let request = {
                    expautoid: autoid,
                    studytypeid: 2,
                }
            axios.post(server.expapply,request).then(result=>{
                if(result.status){
                    this.getExpapply(autoid);
                }
            })
        },
        startExp(data){
            const {userinfo,expStyle} = this;
            // if(expStyle === 'pcemulate'){ // pc实验
              if(expStyle === 'emulate'){ // pc实验
                this.getUi(data,result=>{
                    //console.log(data);
                    let token = localStorage.getItem('token'),
                    location = window.location;
                    this.websocketParam = {
                        projectname: encodeURI(encodeURI('火箭启动器' || "")),
                        expname: encodeURI(encodeURI('火箭启动器' || "")),
                        downloadEnable: true, 
                        autostart: true,
                        objectid: "",
                        studyno: "",
                        keyid: "",
                        web:1,
                        mark:"zdff0sdfds" + new Date().getTime(),
                        expautoid : data.autoid,
                        uiautoid : result.autoid,
                        uiresourceautoid : data.resourceid,
                        token : token,
                        websitewsip:location.host,
                        websitewsport:location.port || (window.location.protocol === 'https' ? '443' : '80'),
                        websitepathname:process.env.BASE_URL,
                        username : userinfo.username,
                        realname : userinfo.realname,
                    };
                    this.startPcExp();
                })
                
            } else if(expStyle){  //link外部链接 或者 web实验
                this.openWebgl(data);
                if(this.userRole == '1'){
                    this.applyExp(data.autoid);
                }
            }
        },

        startPcExp (){
            const {websocketParam} = this;
            let request = {};
            for(const param in websocketParam){
                if(websocketParam[param]){
                    request[param] = websocketParam[param];
                }
            }
            let emulateStart = new EmulateStart({
                websocketParam : checker.objTurnParams(request).replace('?',''),
            });
            emulateStart.startGame();
        },

        getUi (data,cb){
            let obj = {
                get_all_ui : true,
                targettable:'exps',
                targetautoid : data.autoid,
            }
            axios.get(server.ui + checker.objTurnParams(obj)).then(result=>{
                if(result.status && result.data && result.data.result){
                    let array = result.data.result,
                    cache = array.filter(item=>item.slot==='uiexprecords')[0];
                    if(cb)cb(cache)
                }
            });
        },

        postExpuv(autoid){//添加使用量
            let request = {
                expautoid: autoid
            }
            axios.post(server.expuv,request).then(result=>{
                if(result.status){
                    return true;
                }
            })
        },
        // shopping(autoid){
        //     this.$message({
        //         message: "添加成功",
        //         type: "success"
        //     })
        // },
        getExpapply(autoid){
            let request = {
                expautoid: autoid
            }
            axios.get(server.expapply + checker.objTurnParams(request)).then(result=>{
                if(result.status){
                    let expapplyData = result.data.result || [];
                    if(expapplyData.length != 0){
                        this.verifystate = parseInt(expapplyData[0].verifystate);
                    }else{
                        this.verifystate = 0;
                    }
                }
            })
        },
        openWebgl(data){
            const {expStyle ,cache} = this;
            let request = {
                autoid: data.autoid
            },path,webglUrl;
            axios.get(server.exp + checker.objTurnParams(request)).then(result=>{
                if(result && result.status && result.data && result.data.result){
                    if(result.data.result[0] && result.data.result[0].resource_data){
                        this.postExpuv(data.autoid);
                        let tokeen = localStorage.getItem('token');
                        
                        if(expStyle === 'webemulate'){
                            path = result.data.result[0].resource_data.filepath || '';
                            webglUrl = window.location.protocol + '//' + window.location.host + path + '?expautoid=' + data.autoid;
                            window.open(webglUrl);
                        }else{
                            path = result.data.result[0].resource_data.link || '';
                            if(path.indexOf('?') >= 0){
                                path = path +'&token=' + tokeen; 
                            }else{
                                path = path +'?token=' + tokeen;  
                            }
                            this.getUi(cache,uidata=>{
                                let expdata = {'expautoid': data.autoid, 'uiautoid': uidata.autoid};
                                expdata = common.base64_encode(JSON.stringify(expdata));
                                if(['string','number'].indexOf(typeof(uidata.autoid)) >= 0){
                                    window.open(path + '&expdata=' + expdata);
                                }
                            })
                        }
                    }
                }
            })
        },
        winOpen (url){
            let windowName="win"+(new Date()).getTime();
            window.open(url,windowName,"top=0, left=0, toolbar=no, menubar=no,resizable=no,location=no");
        },
    },
    props : {
        data : {
            type :Object,
            default : ()=>{return {}}
        }
    },
}
</script>

<style scoped lang="less">
    /deep/ .el-rate__icon{
        font-size:22px;
    }
    /deep/ .el-rate__text{
        font-weight: bold;
        font-size: 18px;
    }
    .header-ui{
        display: flex;
        margin-top: 10px;
        padding:10px;
        background-color: #FFFFFF;
        margin-bottom: 10px;
        border-radius: 4px;
        .header-banner{
            width:500px;
            height:300px;
            flex:0 0 auto;
            border-radius: 4px;
            .banner-singin{
                width:100%;
                height:100%;
            }
        }
        .header-main{
            width: 650px;
            flex:1 1 auto;
            display: flex;
            flex-direction: column;
            text-align: left;
            padding: 10px 10px 0 20px;
            .header-title{
                font-size:26px;
                font-weight: bold;
                color: #1A1A1A;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                
            }
            .header-msg{
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 14px;
                color: #666666;
                .header-msg-money{
                    display: flex;
                    align-items: center;
                    >i{
                        width: 20px;
                        height: 16px;
                        background-image: url('~@/assets/images/gold.png');
                        display: inline-block;
                        background-size: 100% 100%;
                        margin-right: 5px;
                    }
                    >span.money{
                        font-size: 16px;
                        font-weight: bold;
                        color: #1A1A1A;
                        margin-right: 10px;
                    }
                    >span.state{
                        width: 59px;
                        height: 20px;
                        border: 1px solid #FF635F;
                        border-radius: 4px;
                        font-size: 12px;
                        font-weight: 400;
                        color: #FF635F;
                        display: inline-block;
                        text-align: center;
                        line-height: 20px;
                    }
                }
                >span:nth-child(2){
                    text-align: left;
                }
                
                &:nth-child(2){
                    margin-top:15px;
                    margin-bottom: 15px;
                }
                &:nth-child(3){
                    margin-bottom: 15px;
                }
            }
            .header-content{
                font-size: 12px;
                font-weight: 400;
                color: #989898;
                flex: 1 1 auto;
                line-height: 28px;
                text-indent: 24px;
                text-align: justify;
                word-wrap: break-word;
                overflow: hidden;
                // height: 150px;
                white-space: normal;
                // .content-detail{
                        // word-break: break-all;
                        // text-overflow: ellipsis;
                        // display: -webkit-box;
                        // -webkit-box-orient: vertical;
                        // -webkit-line-clamp: 2;
                        // overflow: hidden;
                // }
            }
            .header-actions{
                display: flex;
                .actions-main{
                    display: flex;
                    align-items: center;
                    display: flex;
                    margin-left:auto;
                    .actions-btn{
                        float:right;
                        display: flex;
                        align-items: center;
                        padding: 0 10px;
                        .actions-btn-icon{
                            display: inline-block;
                            background-size: 100% 100%;
                            margin-right:5px;
                            > img{
                                width: 15px;
                                height: 17px;
                                cursor:pointer;
                            }
                        }
                        // &.help{
                        //     margin-right:20px;
                        //     .actions-btn-icon{
                        //         width: 16px;
                        //         height: 15px;
                        //         cursor: pointer;
                        //         background-image: url('~@/assets/images/help.png');
                                
                        //     } 
                        // }
                        // &.collect{
                            
                        //     .actions-btn-icon{
                        //         width: 15px;
                        //         height: 19px;
                        //         cursor: pointer;
                        //         background-image: url('~@/assets/images/collect.png');
                        //     }
                        // }
                    }
                }
                
            }
        }
    }

</style>