import { routes } from '@/router/index';
import xss from './xss';
import axios from "@/utils/axios";
// import server from "@/service/server";
import checker from '@/utils/checker';
import { ElLoading } from "element-plus";
import {ref,reactive,onMounted} from 'vue'

const CryptoJS = require('crypto-js'); //引用AES源码js
const key = CryptoJS.enc.Utf8.parse("1234123412ABCDEF"); //十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse('ABCDEF1234123412'); //十六位十六进制数作为密钥偏移量
function getClass(o) { //判断数据类型
    return Object.prototype.toString.call(o).slice(8, -1);
}

function legalUtl (value){
    return /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/.test(value);
}

function toArray(data) { //Arguments 转数组
    if (getClass(data) === "Object") return data;
    try {
        return Array.prototype.slice.call(data);
    } catch (e) {
        var arr = [];
        for (var i = 0, len = data.length; i < len; i++) {
            arr[i] = data[i];
        }
        return arr;
    }
}

function cloneObj(obj) {
    let result, oClass = getClass(obj);
    if (oClass == "Object") result = {}; //判断传入的如果是对象，继续遍历
    else if (oClass == "Array") result = []; //判断传入的如果是数组，继续遍历
    else return obj; //如果是基本数据类型就直接返回
    for (let i in obj) {
        let copy = obj[i];
        if (getClass(copy) == "Object") result[i] = cloneObj(copy); //递归方法 ，如果对象继续变量obj[i],下一级还是对象，就obj[i][i]
        else if (getClass(copy) == "Array") result[i] = cloneObj(copy); //递归方法 ，如果对象继续数组obj[i],下一级还是数组，就obj[i][i]
        else result[i] = copy; //基本数据类型则赋值给属性
    }
    return result;
}

function monitorXss(obj) {
    let result, oClass = getClass(obj);
    if (oClass == "Object") result = {}; //判断传入的如果是对象，继续遍历
    else if (oClass == "Array") result = []; //判断传入的如果是数组，继续遍历
    else return xss.process(obj); //如果是基本数据类型就直接返回
    for (let i in obj) {
        let copy = obj[i];
        if (getClass(copy) == "Object") result[i] = monitorXss(copy); //递归方法 ，如果对象继续变量obj[i],下一级还是对象，就obj[i][i]
        else if (getClass(copy) == "Array") result[i] = monitorXss(copy); //递归方法 ，如果对象继续数组obj[i],下一级还是数组，就obj[i][i]
        else if (getClass(copy) == "String") result[i] = xss.process(copy); //基本数据类型则赋值给属性
        // else if (getClass(copy) == "Number") result[i] = copy; //基本数据类型则赋值给属性
        // else if (getClass(copy) == "Boolean") result[i] = copy; //基本数据类型则赋值给属性
        else result[i] = copy;
    }
    return result;
};

//加密方法
function Encrypt(word) {
    let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.ciphertext.toString().toUpperCase();
}

function downloadBlobFile(file) {
    let aTag = document.createElement('a');
    aTag.download = file.name;
    let href = URL.createObjectURL(new Blob([file], { type: file.type }));
    aTag.href = href;
    aTag.click();
    URL.revokeObjectURL(href);
}


function dataURLtoBlob(dataurl, name) { //base64转file
    let arr = dataurl.split(',');
    mime = arr[0].match(/:(.*?);/)[1];
    bstr = atob(arr[1]);
    n = bstr.length;
    u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], name, {
        type: mime,
    })
}

//解密方法
function Decrypt(word) {
    let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
    let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    let decrypt = CryptoJS.AES.decrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
}

function expAutoid(autoid) {
    if (autoid.indexOf('#') >= 0) {
        let array = autoid.split('#');
        autoid = array[0];
    }
    return autoid;
}

//使用尾递归函数将数据处理成Tree数据
function Recursion(data) {
    let cache = JSON.parse(JSON.stringify(data)),
        cache_back = JSON.parse(JSON.stringify(data)),
        parents = data.filter(item => typeof item.parent === 'undefined');
    deep(parents);

    function deep(source) {
        if (!source.length) return;
        let sourceData = []
        for (let i = 0; i < source.length; i++) {
            let item = source[i],
                delIdxs = [],
                children = cache.filter((ite, idx) => {
                    if (ite.parent === item.key) {
                        delIdxs.push(idx);//找出所有的子元素
                        return true;
                    }
                });
            item.children = children;
            delIdxs.forEach(idx => cache.splice(idx, 1)); //删除已经遍历过的数据
            children.forEach(child => {
                let idx = cache_back.findIndex(ite => ite.parent === child.key);
                if (idx >= 0) sourceData.push(child);
            })
        }
        return deep(sourceData);
    }
    return parents;
}

//存储参数
function setStorParams(_this, value, key) {
    let params = localStorage.getItem('storParams'),
        cache = {};
    if (!key) {
        if (!_this.$route) key = _this.path;
        else key = _this.$route.path;
    }
    if (params) {
        params = JSON.parse(Decrypt(params));
        params[key] = value;
    } else {
        params = {};
        params[key] = value;
    }
    cache = Encrypt(JSON.stringify(params));
    localStorage.setItem('storParams', cache);
}

//获取路由映射
function getRouteMap(_this) {
    return _this.$store.state.common.routeMap;
}

//获取参数
function getStorParams(_this, all) {
    let cache = localStorage.getItem('storParams'),
        key = getParamsKey(_this);
    if (cache) cache = JSON.parse(Decrypt(cache));
    cache = cache || {};
    if (all) return cache;

    else return cache[key] || false;
}

//删除参数
function removeParams(key) {
    let params = localStorage.getItem('storParams'),
        cache = {};
    if (params) {
        params = JSON.parse(Decrypt(params));
        if (key) delete params[key]
    } else {
        params = {};
    }
    cache = Encrypt(JSON.stringify(params));
    localStorage.setItem('storParams', cache);
}

//获取参数的key
function getParamsKey(_this) {
    if (!_this.$store) return _this.path;
    let routeMap = getRouteMap(_this),
        path = _this.$route.path,
        route = routeMap[path],
        key = '';
    if (route) key = route.parent || route.path;
    return key;
}

//将路由数据 生成 数据对象映射
function analysisRoute() {
    let routeData = common.cloneObj(routes),
        routeMap = {};
    while (true) {
        let array = [];
        routeData.map(item => {
            delete item.component;
            if (item.children && item.children.length) {
                array.push(...item.children);
            }
            delete item.children;
            routeMap[item.path] = item;
            routeData = array;
        })
        if (!array.length) break;
    }
    return routeMap;
};

//获取token
function getToken() {
    return localStorage.getItem('token');
};

//生成hashcode码
function hashCode(str) {
    let h = 0,
        len = str.length,
        t = 2147483648;
    for (let i = 0; i < len; i++) {
        h = 31 * h + str.charCodeAt(i);
        if (h > 2147483647) h %= t;
    }
    return h;
}

//生成随机数
function randomWord(randomFlag, min, max) {
    let str = "",
        range = min,
        arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    // 随机产生
    if (randomFlag) {
        range = Math.round(Math.random() * (max - min)) + min;
    }
    for (let i = 0; i < range; i++) {
        let pos = Math.round(Math.random() * (arr.length - 1));
        str += arr[pos];
    }
    return str;
}

function gethashcode() { //获取hashcode
    //定义一个时间戳，计算与1970年相差的毫秒数  用来获得唯一时间
    let timestamp = (new Date()).valueOf(),
        myRandom = randomWord(false, 6),
        hashcode = hashCode(myRandom + timestamp.toString());
    return hashcode;
}

function assemblePromise(promises) {
    let array = [];
    promises.map(func => {
        array.push(new Promise(resolve => func(resolve)));
    })
    return array;
}




function urlKey(url) { //获取url 的 \最后一位
    return url.match(/[^\/]*$/)[0];
}

function pwdcheck(pwd) { //密码校验
    const reg = (str, rstr) => new RegExp(rstr).test(str);
    if (pwd.length < 8 || pwd.length > 32) return false;
    let rC = {
        lW: '[a-z]',
        uW: '[A-Z]',
        nW: '[0-9]',
        sW: /[.~!@_]/im,
    };
    let tR = {
        l: reg(pwd, rC.lW),
        u: reg(pwd, rC.uW),
        n: reg(pwd, rC.nW),
        s: reg(pwd, rC.sW)
    };
    if ((tR.l && tR.u && tR.n) || (tR.l && tR.u && tR.s) || (tR.s && tR.u && tR.n) || (tR.s && tR.l && tR.n)) {
        if (checks(pwd)) return true;
        else return false;
    }else return false;
    function checks(str){
        let regEn = /[`^*()+<>:"{},\/;'[\]]/im,
        regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;
        if(regEn.test(str) || regCn.test(str))return false;
        return true;
    }
}

function downloadFile(url, filename = '未知文件') {
    const el = document.createElement('a');
    el.style.display = 'none';
    el.setAttribute('target', '_blank');
    el.setAttribute('download', filename);
    el.href = url;
    console.log(el);
    document.body.appendChild(el);
    el.click();
    document.body.removeChild(el);
}

function createJSSDK (name,cb){
    let _script = document.createElement('script'),
    _body = document.getElementsByTagName('body')[0];
    _script.id = 'rocketLauncher';
    _script.src = `${process.env.BASE_URL}${name}.js`;
    _script.onload = function (){
        if(cb)cb();
    }
    _body.appendChild(_script);
}

function removeJSSDK (){
    let _body = document.getElementsByTagName('body')[0],
    _script = document.getElementById('rocketLauncher');
    if(_script){
        _body.removeChild(_script);
    }
    
}

function browserd() {
    var ua = navigator.userAgent;
    if(/360/i.test(ua)){
        return '360';
    }else if (/firefox/i.test(ua)) {
        return '火狐';
    } else if (/chrome/i.test(ua)) {
        return 'chrome';
    } else if (/msie/i.test(ua)) {
        return 'IE';
    }else if ("ActiveXObject" in window) {
        return 'IE11';
    }
}
//敏感信息隐藏，仅限手机号11位
function hideInfo_phone(val){
    if(val.length > 0){
        let value = val.substring(0,3) + '******' + val.substring(9,11);
        return value;
    }
}
//真实姓名
function hideInfo_realname(val){
    if(val.length > 0){
        let value;
        switch(val.length){
            case 1:
                value = val;
                break;
            case 2:
                value = val.substring(0,1) + '*';
                break;
            case 3:
                value = val.substring(0,1) + '*' + val.substring(2,3);
                break;
            default:
                value = val.substring(0,1) + '**' + val.substring(3,userData.realname.length);
                break;
        }
        return value;
    }
}
// num为传入的值，n为保留的小数位
function fomatFloat(num,n){
    var f = parseFloat(num);
    if(isNaN(f)){
        return false;
    }
    f = Math.round(num*Math.pow(10, n))/Math.pow(10, n); // n 幂   
    if(n > 0){
        var s = f.toString();
        var rs = s.indexOf('.');
        //判定如果是整数，增加小数点再补0
        if(rs < 0){
            rs = s.length;
            s += '.'; 
        }
        while(s.length <= rs + n){
            s += '0';
        }
        return s;
    }else{
        return f;
    }
}
// function GetMouseMove(){
//     	// 定义默认的宽高
// 	const movement = reactive({w:window.pageXOffset,h:window.pageYOffset});
// 	onMounted(()=>{
// 		// 当窗口发生变化时候更新宽高
// 		window.addEventListener("mousemove",function(event){
// 			movement.w = event.pageX;
// 			movement.h = event.pageY;
// 		})
// 	})
// 	// 返回size
// 	return movement;
// }

 // base64 加密
function base64_encode(val){
    const str=CryptoJS.enc.Utf8.parse(val);
    const base64=CryptoJS.enc.Base64.stringify(str);
    return base64
}
 // base64 解密
 function base64_decode(val){
    const words  = CryptoJS.enc.Base64.parse(val);
    const deBase64 = words.toString(CryptoJS.enc.Utf8)
    return deBase64
}





const common = {};
common.cloneObj = cloneObj;
common.getClass = getClass;
common.Encrypt = Encrypt;
common.Decrypt = Decrypt;
common.setStorParams = setStorParams;
common.getStorParams = getStorParams;
common.removeParams = removeParams;
common.getRouteMap = getRouteMap;
common.analysisRoute = analysisRoute;
common.getToken = getToken;
common.gethashcode = gethashcode;
common.assemblePromise = assemblePromise;
common.monitorXss = monitorXss;
common.urlKey = urlKey;
common.pwdcheck = pwdcheck;
common.toArray = toArray;
common.Recursion = Recursion;
common.downloadFile = downloadFile;
common.expAutoid = expAutoid;
common.createJSSDK = createJSSDK;
common.removeJSSDK = removeJSSDK;
common.browserd = browserd;
common.hideInfo_phone = hideInfo_phone;
common.hideInfo_realname = hideInfo_realname;
common.fomatFloat = fomatFloat;
common.base64_encode = base64_encode;
common.base64_decode = base64_decode;
common.legalUtl = legalUtl;
common.BASE_URL = process.env.BASE_URL;
// common.GetMouseMove = GetMouseMove;

export default common;