<template>
    <!-- 账户设置 -->
    <div class="my-account">
        <div class="my-title">
          <span>账户设置</span>
        </div>
        <!-- <div class="account-head">
            <img :src='formData.imgUrl' alt="">
            <el-upload
                class="upload-demo"
                :action="uploadUrl"
                :headers="{'Authorization':token}"
                :on-success="handleSuccess"
                :show-file-list="false"
                accept=".jpg,.png,.jpeg,.gif">
                <Buttons plain type='blue'>更换头像</Buttons>
            </el-upload>
        </div> -->
        <div class="account-main">
            <div class="account-item">
                <span class="item-label">真实姓名：</span>
                <span>{{formData.realname}}</span>
            </div>
            <div class="account-item">
                <span class="item-label">身份：</span>
                <span>{{formData.role}}</span>
            </div>
            <div class="account-item">
                <span class="item-label">帐号：</span>
                <span>{{formData.username}}</span>
                <!-- <div class="item-box">
                    <template v-if="!updates.username">
                        <span class="box-label">{{formData.username}}</span>
                        <Buttons plain type='blue' @click="handleEdit('username')">修改账号</Buttons>
                    </template>
                    <template v-if="updates.username">
                        <el-input size='medium' class="box-input" v-model="formData.username" placeholder="请输入" />
                        <el-icon><check /></el-icon>
                        <el-icon @click="handleClose('username')"><close /></el-icon>
                    </template>
                </div> -->
            </div>
            <!-- <div class="account-item">
                <span class="item-label">邮箱：</span>
                <div class="item-box">
                    <template v-if="!updates.email">
                        <span class="box-label">{{formData.email}}</span>
                        <Buttons plain type='blue' @click="handleEdit('email')">更换邮箱</Buttons>
                    </template>
                    <template v-if="updates.email">
                        <el-input size='medium' class="box-input" v-model="formData.email" placeholder="请输入" />
                        <el-icon><check /></el-icon>
                        <el-icon @click="handleClose('email')"><close /></el-icon>
                    </template>
                </div>
            </div> -->
            <!-- <div class="account-item">
                <span class="item-label">手机号：</span>
                <div class="item-box">
                    <template v-if="!updates.phone">
                        <span class="box-label">{{formData.phone}}</span>
                        <Buttons plain type='blue' @click="handleEdit('phone')">更换手机号</Buttons>
                    </template>
                    <template v-if="updates.phone">
                        <el-input size='medium' class="box-input" v-model="formData.phone" placeholder="请输入" />
                        <el-icon><check /></el-icon>
                        <el-icon @click="handleClose('phone')"><close /></el-icon>
                        
                    </template>
                </div>
            </div> -->
            <!-- <div class="account-item">
                <span class="item-label">绑定手机：</span>
                <span v-if="formData.phone">{{formData.phone}}</span>
                <span class="edit_info" @click="handeClick('更换手机号')">修改手机号</span>
            </div> -->
            <div class="account-item">
                <span class="item-label">登录密码：</span>
                <span class="edit_info" @click="handeClick('修改密码')">修改密码</span>
            </div>
            <!-- <div class="account-item">
                <span class="item-label">原密码：</span>
                <div class="item-box">
                    <el-input show-password size='small' type="password" class="box-input" v-model="formData.oldpwd" placeholder="请输入" />
                </div>
            </div>
            <div class="account-item">
                <span class="item-label">新密码：</span>
                <div class="item-box">
                    <el-input show-password type="password" size='small' class="box-input" v-model="formData.newpwd" placeholder="请输入" />
                    <span class="box-msg" v-if="newFlag">密码必须至少由8-32位大写字母、小写字母、数字和特殊符号_.@~!中的三种组成</span>
                </div>
            </div>
            <div class="account-item">
                <span class="item-label">确定密码：</span>
                <div class="item-box">
                    <el-input show-password type="password" size='small' class="box-input" v-model="formData.conpwd" placeholder="请输入" />
                    <span class="box-msg" v-if="conFlag">两次密码输入不一致，请重新输入</span>
                </div>
            </div>
            <div class="account-item submit">
                <Buttons type='blue' @click="handleSubmit">确认修改</Buttons>
            </div> -->
            <MessageBoxs
                :title="popupData.title || '弹窗标题'"
                :style="{ width: '500px' }"
                :state="popupData.state"
                @close="closePopup"
            >
                <template v-if="popupData.title === '更换手机号'">
                    <span class="login-input">已绑定的手机：{{formData.phone}}</span>

                    <span class='login-input'>
                        <el-input size='large' v-model="formData.newphone" placeholder="请输入新手机号码" maxlength="11" show-word-limit>
                            <template #prefix>
                                <el-icon class="el-input__icon"><Iphone /></el-icon>
                            </template>
                        </el-input>
                    </span>

                    <div class='login-input'>
                        <el-input class='login-code' size='large' v-model="formData.newphonecode"  placeholder="请输入短信验证码"/>
                        <div class="duanxing">
                            <span class="duanxing1" v-if="login_code" @click="getPhoneCode_update('更换用户手机号')">获取验证码</span>
                            <span class="duanxing2" v-else>
                                <span>{{count}}秒后</span>
                                <span>重新获取</span>
                            </span>
                        </div>
                    </div>
                    
                    <span class='login-input login-log' v-if="!!viewState.msg">
                        <template v-if="!!viewState.msg && viewState.msg !== '检测成功'">
                            <el-icon class='login-icon'><warning-filled /></el-icon>
                            {{viewState.msgTitle}}
                        </template>
                        <template v-if="viewState.msg === '验证通过'">
                            <el-icon class='login-icon finish'><success-filled /></el-icon>
                            <span class='finish'>{{viewState.msgTitle}}</span>
                        </template>
                    </span>
                </template>
                <template v-if="popupData.title === '修改密码'">
                    <span class='login-input'>
                        <el-input size='large' type='password' v-model="formData.oldpwd"  placeholder="请输入初始密码">
                            <template #prefix>
                                <el-icon class="el-input__icon"><lock /></el-icon>
                            </template>
                        </el-input>
                    </span>
                    <span class='login-input'>
                        <el-input size='large' type='password' v-model="formData.newpwd"  placeholder="请输入新密码">
                            <template #prefix>
                                <el-icon class="el-input__icon"><lock /></el-icon>
                            </template>
                        </el-input>
                    </span>
                    <span class='login-input'>
                        <el-input size='large' type='password' v-model="formData.conpwd"  placeholder="请再次输入新密码">
                            <template #prefix>
                                <el-icon class="el-input__icon"><lock /></el-icon>
                            </template>
                        </el-input>
                    </span>
                    <span class='login-input login-log' v-if="!!viewState.msg">
                        <template v-if="!!viewState.msg && viewState.msg !== '检测成功'">
                            <el-icon class='login-icon'><warning-filled /></el-icon>
                            {{viewState.msgTitle}}
                        </template>
                        <template v-if="viewState.msg === '验证通过'">
                            <el-icon class='login-icon finish'><success-filled /></el-icon>
                            <span class='finish'>{{viewState.msgTitle}}</span>
                        </template>
                    </span>
                </template>
                <template #footer>
                    <template v-if="popupData.title === '更换手机号'">
                        <Buttons @click="handeClick('更换用户手机号')" type="blue">确认</Buttons>
                    </template>
                    <template v-if="popupData.title === '修改密码'">
                        <Buttons @click="handeClick('修改用户密码')" type="blue">确认修改</Buttons>
                    </template>
                </template>
            </MessageBoxs>
        </div>
    </div>
</template>

<script>
import Buttons from '@/components/course/Buttons.vue';
import axiosTool from '@/utils/axios_tool';
import checker from '@/utils/checker';
import axios from '@/utils/axios';
import server from '@/service/server';
import common from '@/utils/common';
import { ElMessageBox, ElMessage} from 'element-plus';
import { mapActions } from 'vuex';
import MessageBoxs from '@/components/course/MessageBoxs.vue';
// import { docCookies } from '@/utils/cookie'
const TIME_COUNT = 60;
export default {
    data (){
        return {
            formData : {},
            updates : {},
            uploadUrl: '',
            newFlag: false,
            status: false,
            conFlag: false,
            token : '',
            popupData: {},
            viewState: {},
            login_code: true,
            count: '',
            timer: null,
        }
    },
    created() {
        this.uploadUrl = location.origin + process.env.BASE_URL+ 'api/v3/upload?type=image'
        this.token = `JWT ${localStorage.getItem('token') || ''}`;
        this.axiosTool = new axiosTool(this,[
            {key:'update_phone',type:'post',title:'修改用户手机号',url:server.userinfo},
            {key:'get_smscode',type:'post',title:'获取手机短信验证码',url:server.acscode},
        ])
    },
    mounted(){
        this.getUserInfo();
    },
    methods : {
        handleEdit (type){
            this.updates[type] = true;
        },
        handleClose (type){
            this.updates[type] = false;
        },
        getUserInfo(){
            const {formData} = this;
            axios.get(server.userinfo).then(result=>{
                if(result.status && result.data && result.data.result){
                    let userData = result.data.result[0];
                    formData.imgUrl = userData.face || require('@/assets/images/user_logo.png');
                    formData.realname = userData.realname;
                    if(userData.role_data){formData.role = userData.role_data.langname;}
                    formData.phone = userData.phone;
                    formData.username = userData.username;
                }
            })
        },
        handleSuccess(response, file){//上传头像
            const {formData} = this
            if(response.status && response.data){
                let fileList = response.data[0];
                formData.imgUrl = fileList.url;
                
                this.putImg(fileList);
            }
        },
        putImg(fileList){//修改用户头像
            let request = {put_data: true, face: fileList.url};
            axios.post(server.userinfo,request).then(result=>{
                if(result && result.status && result.data && result.data.result){
                    this.setUserInfo(result.data.result[0]);
                    return true;
                }
            })
        },
        handeClick(type){
            let detection;
            switch(type){
                case "更换手机号":
                    this.popupData = {
                        title: type,
                        state: true,
                    }
                    this.formData.newphone = '';
                    this.formData.newphonecode = '';
                    break;
                case "修改密码":
                    this.popupData = {
                        title: type,
                        state: true,
                    }
                    break;
                case '更换用户手机号':
                    detection = this.detectionEmpty(type);
                    if(!detection) return;
                    this.handleCommit_phone();
                    break;
                case '修改用户密码':
                    detection = this.detectionEmpty(type);
                    if(!detection) return;
                    this.handleCommit_pwd();
                    break;
            }
        },
        closePopup(){
            this.popupData = {};
            this.viewState.msg = '';
            this.viewState.msgTitle = '';
        },
        detectionEmpty (type){
            const {formData} = this;
            if(type === '修改用户密码'){
                if((!formData.oldpwd || !common.pwdcheck(formData.oldpwd||''))){//原密码格式错误
                    this.viewState.msg = '密码格式错误！';
                    this.viewState.msgTitle = '初始密码必须至少由8-32位大小写字母、数字和_.@~!中的三种组成';
                    return false;
                }
                if((!formData.newpwd || !common.pwdcheck(formData.newpwd||''))){//新密码格式错误
                    this.viewState.msg = '密码格式错误！';
                    this.viewState.msgTitle = '新密码必须至少由8-32位大小写字母、数字和_.@~!中的三种组成';
                    return false;
                }
                if((!formData.conpwd || !common.pwdcheck(formData.conpwd||''))){//确认密码格式错误
                    this.viewState.msg = '密码格式错误！';
                    this.viewState.msgTitle = '确认密码必须至少由8-32位大小写字母、数字和_.@~!中的三种组成';
                    return false;
                }
    
                if(formData.newpwd != formData.conpwd){//密码错误
                    this.viewState.msg = '两次密码输入不一致，请重新输入！';
                    this.viewState.msgTitle = '两次密码输入不一致，请重新输入！';
                    return false;
                }
            }else if(type === '更换用户手机号'){
                if(!formData.newphone){
                    this.viewState.msg = '请输入手机号！';
                    this.viewState.msgTitle = '请输入手机号！';
                    return false;
                }else{
                    if((!/^[0-9]{11}$/.test(formData.newphone))){
                        this.viewState.msg = '手机号错误！';
                        this.viewState.msgTitle = '手机号必须是11位纯数字!';
                        return false;
                    }else if(!/^1[3456789]\d{9}$/.test(formData.newphone)){
                        this.viewState.msg = '手机号错误！';
                        this.viewState.msgTitle = '请确认手机号输入是否正确';
                        return false;
                    }
                }
            }

            this.viewState.msg = '检测成功';
            return true;
        },
        handleCommit_phone(){
            const {formData} = this;
            this.axiosTool.update_phone({},request=>{
                const {postreq} = request;
                postreq.put_data = true;
                postreq.phone = formData.newphone;
                postreq.code = formData.newphonecode;
                return {...request,postreq}
            }).then(data=>{
                if(data && data.status){
                    ElMessage.success("更换成功！");
                    this.getUserInfo();
                    this.closePopup();
                }else{
                    this.viewState.msg = data.msg || '更换失败，请重新尝试';
                    this.viewState.msgTitle = data.data || '更换失败，请重新尝试';
                }
            })
        },
        getPhoneCode_update(type){//获取更换手机验证码
            const {formData} = this;
            let detection = this.detectionEmpty(type);
            if(!detection)return;
            if(formData.newphone){
                this.axiosTool.get_smscode({},request=>{
                    const {postreq} = request;
                    postreq.phone = formData.newphone;
                    postreq.type = 'updatephone';
                    return {...request,postreq}
                }).then(data=>{
                    if(data && data.status){
                        if(!this.timer){
                            this.count = TIME_COUNT;
                            this.login_code = false;
                            this.timer = setInterval(()=>{
                                if(this.count>0 && this.count<= TIME_COUNT){
                                    this.count --;
                                }else{
                                    this.login_code = true;
                                    clearInterval(this.timer);
                                    this.timer = null;
                                }
                            },1000)
                        }
                        ElMessage.success("发送成功");
                        this.viewState.msg = '';
                        this.viewState.msgTitle = '';
                    } else if(data.data === 'over frequency delay' || (data.data).indexOf('触发分钟级流控Permits') != -1){
                        this.viewState.msg = '请勿频繁操作';
                        this.viewState.msgTitle = '请勿频繁操作';
                    } else {
                        this.viewState.msg = data.data;
                        this.viewState.msgTitle = data.data;
                    }
                })
            }
        },
        handleCommit_pwd(){
            const {formData} = this;
            let request = {oldkey: checker.encryption(formData.oldpwd), newkey: checker.encryption(formData.newpwd)}
            axios.post(server.userinfo + '?editpwd', request).then(result=>{
                if(result.status){
                    this.viewState.msg = '';
                    this.viewState.msgTitle = '';
                    ElMessageBox.alert('修改成功，请重新登录！', '提示', {
                        confirmButtonText: '确定',
                        callback: () => {
                            localStorage.clear();
                            sessionStorage.clear();
                            this.$router.push('/login');
                        },
                    })
                }else{
                    this.viewState.msg = '原密码输入错误！';
                    this.viewState.msgTitle = '原密码输入错误！';
                }
            })
        },

        // handleSubmit(){//修改用户密码
        //     const {formData} = this
        //     this.conFlag = false;
        //     if(formData.oldpwd != '' && formData.newpwd != '' && formData.conpwd != ''){
        //         if(formData.newpwd != formData.conpwd){
        //             this.conFlag = true;
        //         }else{
        //             let pwdVerify = this.testPassword(formData.newpwd);
        //             if(pwdVerify){
        //                 let request = {oldkey: checker.encryption(formData.oldpwd), newkey: checker.encryption(formData.newpwd)}
        //                 this.conFlag = false;
        //                 axios.post(server.userinfo + '?editpwd', request).then(result=>{
        //                     if(result.status){
        //                         ElMessageBox.alert('修改成功，请重新登录！', '提示', {
        //                             confirmButtonText: '确定',
        //                             callback: () => {
        //                                 localStorage.clear();
        //                                 sessionStorage.clear();
        //                                 this.$router.push('/login');
        //                             },
        //                         })
        //                     }else{
        //                         this.$message({
        //                             message: '原密码输入错误',
        //                             type: 'warning'
        //                         })
        //                     }
        //                 })
        //             }else{
        //                 this.$message({
        //                     message: '请输入正确的密码信息',
        //                     type: 'warning'
        //                 })
        //             }
        //         }
        //     }else{
        //         this.$message({
        //             message: '请输入正确的密码信息',
        //             type: 'warning'
        //         })
        //     }
        // },
        // testPassword(str){//验证密码强度
        //     let rC = {
        //         lW: '[a-z]',
        //         uW: '[A-Z]',
        //         nW: '[0-9]',
        //         sW: /[`!@#$_%&?]/im
        //     };
        //     if(str.length < 8 || str.length > 32){
        //         console.log(this.newFlag = true, this.status = false);
        //         return this.newFlag = true, this.status = false
        //     }else{
        //         let tR = {
        //             l: this.reg(str, rC.lW),
        //             u: this.reg(str, rC.uW),
        //             n: this.reg(str, rC.nW),
        //             s: this.reg(str, rC.sW)
        //         };
        //         if ((tR.l && tR.u && tR.n) || (tR.l && tR.u && tR.s) || (tR.s && tR.u && tR.n) || (tR.s && tR.l && tR.n)) {
        //             if (this.checks(str)) {
        //                 return this.newFlag = true, this.status = false
        //             } else {
        //                 return this.newFlag = false, this.status = true
        //             }
        //         } else {
        //             return this.newFlag = true, this.status = false
        //         }
        //     }
        // },
        // reg(str, rStr){
        //     let reg = new RegExp(rStr);
        //     if (reg.test(str)) return true;
        //     else return false;
        // },
        // checks(newName) {
        //     let regEn = /[`^*()+<>:"{},.\/;'[\]]/im,
        //         regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;
        //     if (regEn.test(newName) || regCn.test(newName)) {
        //         return true;
        //     }
        //     return false;
        // },

        ...mapActions(['setUserInfo'])
    },
    components : {
        Buttons,
        MessageBoxs,
    },
}
</script>

<style scoped lang='less'>
    @import '~@/styles/common.less';
    // ::v-deep  .el-input__suffix  {
    //     top: 10px;
    // }
    :deep(.el-icon.el-input_icon){
        position:relative;
        top:20px;
    }
    :deep(.el-input__prefix){
        display: flex;
        justify-content: center;
        align-items: center;
    }
    :deep(.el-icon.el-input__icon){
        font-size: 20px;
    }
    .my-title{
        width:100%;
        height:53px;
        display: flex;
        align-items: center;
        justify-items: center;
        flex:0 0 53px;
        border-bottom: 1px solid #F5F5F5;
        >span{
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #1A1A1A;
        }
    }
    .my-account{
        display: flex;
        align-items: center;
        flex-direction: column;
        .account-head{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top:10px;
            margin-right:98px;
            margin-bottom: 10px;
            >img{
                width:122px;
                height:122px;
                border-radius: 50%;
                margin-bottom: 10px;
            }
        }
        .account-main{
            width:100%;
            // margin-left: 100px;
            .account-item.submit{
                justify-content: center;
                padding:20px 0;
                margin-right: 120px;
            }
            .account-item{
                display: flex;
                align-items: center;
                height:32px;
                padding: 10px 0;
                >span:nth-child(1){
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    display: inline-block;
                    width: 120px;
                    text-align: right;
                    flex: 0 0 120px;
                    margin-right:40px;
                }
                >span:nth-child(2){
                    width:auto;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    // color: #1A1A1A;
                    flex:0 0 auto;
                    text-align: left;
                    margin-right: 50px;
                }
                .edit_info{
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #1E5FC2;
                    line-height: 36px;
                    cursor: pointer;
                }
                .item-box{
                    display: flex;
                    align-items: center;
                    .box-input{
                        width:220px;
                        margin-right:10px;
                    }
                    .box-label{
                        display: inline-block;
                        width:250px;
                        text-align: left;
                        margin-right:10px;
                    }
                    .box-msg{
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FF0000;
                    }
                    >i{
                        font-size: 24px;
                        cursor: pointer;
                    }
                    >i:nth-child(2){
                        margin-left:10px;
                        margin-right:20px;
                        color:#3EB36B;
                    }
                    >i:nth-child(3){
                        color:#DA0000;
                        
                    }
                }
            }
            .login-input{
                padding: 0 50px;
                display: flex;
                margin-bottom:20px;
                margin-top:20px;
                flex:0 0 auto;
                .duanxing{
                    width: 133px;
                    border: 1px solid #1E5FBB;
                    border-radius: 2px 2px 0px 2px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 20px;
                    .duanxing1{
                        width: 90px;
                        height: 18px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #1E5FBB;
                        cursor: pointer;
                    }
                    .duanxing2{
                        width: 72px;
                        height: 40px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666666;
                        line-height: 18px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                }
                .login-code{
                    flex:1 1 auto;
                }
            }
            .login-log{
                align-items: center;
                color:#F53A50;
                line-height: 24px;
                text-align: left;
                .login-icon{
                    margin-right:5px;
                    color:#F53A50;
                    font-size:18px;
                    position: relative;
                    top: 1px;
                }
                .finish{
                    color:#409EFF;
                }
            }
        }
    }
</style>