<template>
    <div class="main-ui">
        <!-- 前台头部 -->
        <div class="main-head">
          <Header />
        </div>
        <div class="main-menus">
          <!--前台导航部分-->
          <Menus></Menus>
        </div>
        <div class="main-context">
          <!--前台内容-->
          <router-view/>
        </div>
        <div class="main-footer">
          <Footer />
        </div>
    </div>
</template>

<script>
import Footer from './footer.vue';
import Header from './Header.vue';
import Menus from './Menus.vue';
import common from '@/utils/common.js';
import { mapActions } from 'vuex';
import axios from '@/utils/axios';
import server from '@/service/server';
export default {
  name:'_main_',
 data (){
   return {
      refreshKey : 0,
      timeout:"",
      curtime:new Date()
   }
 },
 components : {
   Header,
   Footer,
   Menus,
 },
  methods : {
    getToken(){
      let time = new Date();
      axios.get(server.userinfo, {params:{get_token:'true'}}).then(result=>{
        if(result && result.status){
          let tokenn = result.token;
          window.localStorage.setItem('token',tokenn);
           window.localStorage.setItem('curTime',(Number(time.getTime())+1200000)+'') 
        }
      })
    },
    judgeStatus(){
      axios.get(server.userinfo).then(result=>{
        try {
          if(!result || !result.status){
            localStorage.clear();
            clearInterval(this.timeout);
            // sessionStorage.clear();
            ElMessage.warning('登录标识已失效，请重新登录！');
                  // timer = new Date().getTime();
                  // window.location.href = window.__GLOBALPATH + '/#/';
          }
          else{
            clearInterval(this.timeout);
            this.timeout = setInterval(()=>{
              let curTiming = new Date() ;
              let lastTime = window.localStorage.getItem('curTime');
              if(curTiming.getTime() >= lastTime && !!lastTime){
                this.getToken();
              }
            },20000)
          }
        } catch (error) {}
      })
    },
  },
  created(){
    // 这个模式是自动续token 不管有没有操作
    this.judgeStatus()
 
  },
  mounted(){

  },

  beforeRouteUpdate (to){
    this.$nextTick(()=>{
      this.refreshKey ++;
    });
  },
}
</script>

<style scoped lang='less'>
    
   
    .main-ui{
      width:100%;
      height:100%;
      display: flex;
      flex-direction: column;
      .main-head{
        height:80px;
        width:100%;
        flex: 0 0 80px;
      }
      .main-menus{
        width:100%;
      }
      .main-context{
        width:100%;
        flex:1 1 auto;
        margin: auto;
      }
      .main-footer{
        width:100%;
        flex: 0 0 auto;
      } 
    }
</style>