<template>
    <div class="fixed-box">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        data (){
            return {
                style : {},
            }
        },
        created (){
            window.addEventListener('scroll',(data)=>{
                const left = document.scrollingElement.scrollLeft;
                this.style.left = -left + 'px';
            })
        },
        methods : {

        },
    }
</script>


<style scoped lang="less">
    .fixed-box {
        position: fixed;
        top:0;
        left:0;
        max-width: 1920px;
        margin:auto;
    }
</style>