<template>
    <div class="layout_20230209_2847">
        <template v-for="item in source" :key="item.key">
            <Floating :class="['moudule-item', item.clazz]" v-if="item.floating && !item.hidden">
                <img v-if="item.isBg" class="item-bg" :src="`${base_url}images/home/head_bg.png`" alt="">
                <div :class="['content', item.adaptive ? 'auto' : 'common-width content-custom']">
                    <slot :name="item.slot" :data="item"></slot>
                </div>
            </Floating>
            <div :class="['moudule-item', item.clazz,item.theme]" v-else-if="!item.hidden">
                <img v-if="item.isBg && item.theme === 'black'" class="item-bg" :src="`${base_url}images/home/head_bg.png`" alt="">
                <div :class="['content', item.adaptive ? 'auto' : 'common-width content-custom']">
                    <slot :name="item.slot" :data="item"></slot>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import Floating from '@/components/common/floating.vue';
export default {
    components : {
        Floating
    },
    name: 'layout_20230209_2847',
    data() {
        return {
            source : [],
        }
    },
    created (){
        this.dataMonitor();
    },
    props: {
        data: {
            type: Array,
            default: function () {
                return [];
            }
        },
    },
    watch : {
        data : "dataMonitor",
    },
    methods : {
        dataMonitor (data=this.data){
            this.source = this.processData(data);
        },
        //数据加工
        processData (data){
            const cache = [];
            data.forEach((item,idx)=>{
                const stripe = idx % 2 ? 'one' : 'two';
                item.clazz = [stripe,item.className];
                item = this.processHeader(item);
                cache.push(item);
            })
            return cache;
        },
        processHeader (data){
            if(data.isBg)delete data.isBg;
            if(data.slot === 'heads'){
                data.isBg = true;
            }
            return data
        },
    },
}
</script>

<style scoped lang="less">
@import url('@/assets/less/theme.less');
@import url('@/styles/common.less');

.layout_20230209_2847 {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 100%;
}

.moudule-item {
    position: relative;
    width: 100%;
    flex: 0 0 auto;
    display: flex;
    min-width: max-content;
}

.content {
    position: relative;
    flex: 0 0 auto;
    min-width: 1050px;
    margin: auto;
}


.moudule-item:first-child .content.content-custom{
    padding-top:10px;
}

.content.auto {
    min-width: 100%;
}

.moudule-item.one{
    background-color: #F7F8FA;
}

.content.content-custom{
    padding:0 10px;
}

.item-bg {
    position: absolute;
    top: 0;
    left:0;
    right: 0;
    border: none;
    display: block;
    height: 100%;
    width: 100%;

}

.head-ui {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
}

.moudule-item.white{
    position: relative;
    background-color: #FFFFFF;
}

.moudule-item.footer-ui {
    background-color: #232A37;
}

// @media screen and (min-width:0px) and (max-width:1200px) {
//     .moudule-item {
//         min-width: 1250px;
//         // padding: 0 10px;
//     }
// }
.media;
</style>