import { reactive, shallowRef } from 'vue'
// 引入全部图标
// import * as Icons from "@element-plus/icons"

// 按需引入图标
import {
  Tickets,
  StarFilled,
  UserFilled,
  ChatDotSquare,
  Menu,
  User,
  Star,
  Edit,
  Delete,
  CircleCheckFilled,
  CircleCloseFilled,
  Checked,
  Failed,
  List,
  Sort,
  CircleClose,
} from '@element-plus/icons'

const dictIcon = reactive({
  'Tickets': shallowRef(Tickets),
  'StarFilled': shallowRef(StarFilled),
  'UserFilled': shallowRef(UserFilled),
  'ChatDotSquare': shallowRef(ChatDotSquare),
  'Menu': shallowRef(Menu),
  'User': shallowRef(User),
  'Star': shallowRef(Star),
  'Edit': shallowRef(Edit),
  'Delete': shallowRef(Delete),
  'CircleCheckFilled': shallowRef(CircleCheckFilled),
  'CircleCloseFilled': shallowRef(CircleCloseFilled),
  'Checked': shallowRef(Checked),
  'Failed': shallowRef(Failed),
  'List': shallowRef(List),
  'Sort': shallowRef(Sort),
  'CircleClose': shallowRef(CircleClose),
})



const installIcon = {
  install(Vue) {
    // 便于模板获取
    Vue.config.globalProperties.$icon = dictIcon
    // 使用全部图标
    // app.config.globalProperties.$icon = Icons
  }
}

export default installIcon;