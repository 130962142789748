<template>
    <div class="answer-item-shade">
        <img v-if="data.iconUrl" :src="data.iconUrl" alt="">
        <img v-if="!data.iconUrl" src="@/assets/images/user_logo.png" alt="">
        <div class="shade-plate">
            <div class="answer-item-li">
                <span v-if="!isHidden" class="answer-txt name">{{data.name}}·发布于 {{data.time}}</span>
                <span v-if="isHidden" class="answer-txt name is-hidden">
                    <!--<i v-if="isHidden && isChildren" :class="[isClose?'el-icon-caret-bottom':'el-icon-caret-right',loading ? 'el-icon-loading' : '']"></i>-->
                    <el-icon v-if="floor>1 && showChildBelow && !isClose"><caret-bottom /></el-icon>
                    <el-icon v-if="floor>1 && showChildBelow && isClose"><caret-right /></el-icon>
                    {{data.name}}·发布于 {{data.time}}
                </span>
                <span class="answer-btn delete" v-if="isDelete" @click="handleDelete">删除</span>
            </div>
            <div class="answer-item-li" v-if="data.isRate">
                <span class="answer-rate">实验评分：</span><el-rate :disabled="data.rateStyle && data.rateStyle.disabled || false" v-model="data.star" text-color="#FF635F" :show-score="data.rateStyle && data.rateStyle.showScore || false" score-template="{data.star}" :colors="evaluateColors"> </el-rate>
            </div>
            <div class="answer-item-li">
                <span class="answer-txt title">{{data.content && data.content.replace('undefined','')}}</span>
                        <!-- 111-->
                <span class="answer-btn reply" v-if="floor > 2 && !isReply && isShowReply" @click="handleReply">回复</span>
                <!-- <span class="answer-btn reply" v-if="floor > 1 && !isReply && isShowReply" @click="handleReply">回复</span> -->
                <span class="answer-btn update" v-if="floor > 1 && !isReply && isUpdate" @click="handleUpdate">修改</span>
            </div>
            <div class="answer-item-li" v-if="data.imgs || data.videos">
                <div class="answer-imgs">
                    <template v-if="data.videos && data.videos.length">
                        <div class="video-shade" @click="handleMedia({url:video.url,type:'video'})" v-for="video in data.videos" :key="video.key">
                            <video :src="video.url"></video>
                            <div class="video-bg">
                                <span ><i class="xxl xxl-video-start"></i></span>
                            </div>
                        </div>
                    </template>
                    <template v-if="data.imgs && data.imgs.length">
                        <img @click="handleMedia({url:img.url,type:'img'})" :src="img.url" alt="" v-for="img in data.imgs" :key="img.key">
                    </template>
                </div>
            </div>
            <div class="answer-item-li" v-if="mediaData.url">
                <div class="answer-video">
                    <Videos v-if="mediaData.type === 'video'" :videoUrl="mediaData.url" :autoplay="false"></Videos>
                    <img v-if="mediaData.type === 'img'" :src="mediaData.url" alt="">
                </div>
            </div>
            <div class="answer-item-li column" v-if="showReplyInput">
                
                <el-input maxlength="300" rows="5" resize="none" type="textarea" :show-word-limit='true' size='small' v-model="valueVal" placeholder="请输入回复" />
                <div class="answer-upload">
                    <Uploads 
                        :data='uploadData'
                        v-model:result="cache.faces" 
                        modules="btn" 
                        btnPosition="top"
                        class="resources-upload"
                        :accepts='["jpg","png","mp4"]' 
                        ref="upload"
                        @uploadBefore='uploadBefore'
                        @uploadAfter='uploadAfter'
                        @uploadChange='uploadResourceAction'
                        :uploadData='mediaResource'>
                        <template #btnName>上传图片/视频</template>
                        <template #上传提示>你只可上传9张图片和视频，图片大小不得超过5M，视频大小不得超过50M哦~</template>
                    </Uploads>
                </div>
                <div class="reply-btn">
                    <span @click="handleCancel">取消</span>
                    <span v-if="actionType === 'reply'" @click="replyAction">发布</span>
                    <span v-if="actionType === 'update'" @click="updateAction">修改</span>
                </div>
            </div>
        </div>
    </div>
    <div class="icon-msg" v-if="'childsize' in data && showReplyCount && floor > 1">
        <!-- 111-->
         <span class="icon-text" v-if="floor > 2" @click="handleHidden"><i class="iconfont">&#xe630;</i> {{ data.childsize || 0 }}</span>
        <!-- <span class="icon-text" @click="handleHidden"><i class="iconfont">&#xe630;</i> {{ data.childsize || 0 }}</span> -->
    </div>
</template>

<script>

import Videos from '@/components/course/Videos.vue';
import Uploads from '@/components/common/Uploads/Uploads.vue';
import { ElMessage } from "element-plus";
// import AnswerLoop from './AnswerLoop.vue';
export default {
    name : 'answer_loop_item',
    data (){
        return {
            cache: {},
            isClose : true,
            loading : false,
            valueVal : '',//评论文本
            actionType : 'reply',
            mediaResource : {

            },
            mediaData : {},
            uploadData : [],
            uploadUrl: [],
            evaluateColors : ['rgba(255,49,44,.3)', 'rgba(255,49,44,.6)', 'rgba(255,49,44,1)'],
            showReplyInput: false,
        }
    },
    emits : ['toReply','handleUpdate','handleClose','toClick','handleDelete'],
    props : {
        pageSmall: {
            type: Boolean,
            default: false
        },
        resource : {
            type : Object,
            default : ()=>{return {}}
        },
        isReply : {
            type : Boolean,
            default : false,
        },
        isShowReply : {
            type : Boolean,
            default : true,
        },
        showReplyCount : {
            type : Boolean,
            default : false,
        },
        parent : {//存储上一级数据
            type : Object,
            default : ()=>{return {}}
        },
        isHidden : {//是否支持隐藏
            type : Boolean,
            default : false,
        },
        isDelete : {
            type : Boolean,
            default : true,
        },
        isUpdate : {
            type : Boolean,
            default : true,
        },
        data: {
            type: Object,
            default: ()=>{return {}},
        },
        floor: {
            type: Number,
            default: 1,
        },
        uploadFloorData: {
            type: Function,
            default() {
                return () => {};
            },
        },
        parentid: {
            type: [Number,String],
            default: 0,
        },
        // 在下方展开
        showChildBelow: {
            type: Boolean,
            default: false
        },
    },
    mounted (){
        const {data} = this;
        this.showReplyInput = this.isReply;
        if(data.videos && data.videos.length){
            this.mediaData = {...data.videos[0],type : 'video'};
        }else if(data.imgs && data.imgs.length){
            this.mediaData = {...data.imgs[0],type : 'img'};
        }
        data.star = data.star ? parseFloat(data.star) : 0,
        this.mediaResource = this.resource;
    },
    computed : {
    },
    watch : {
        "resource": "monitorResource"
    },
    components : {
        Videos,
        Uploads,
        // AnswerLoop
    },
    methods : {
        monitorResource (resource){
            this.mediaResource = resource;
        },
        uploadBefore (cb){
            const {cache} = this;
            // console.log(cache);
            if(cache.faces && cache.faces.length>=9){
                ElMessage.warning("图片和视频上传已达上限！");
                cb(false);
            }
        },
        uploadAfter (data){
            switch(data.data.type){
                case 'image/png':
                    if(data.data.size / 1024 / 1024 > 5){
                        ElMessage.warning("图片大小不得超过5M");
                        data.cb(false);
                    }
                    break;
                case 'image/jpeg':
                    if(data.data.size / 1024 / 1024 > 5){
                        ElMessage.warning("图片大小不得超过5M");
                        data.cb(false);
                    }
                    break;
                case 'video/mp4':
                    if(data.data.size / 1024 / 1024 > 50){
                        ElMessage.warning("视频大小不得超过50M");
                        data.cb(false);
                    }
                    break;
                default :
                    ElMessage.warning("不支持该类型");
                    data.cb(false);
                    break;
            }
        },
        uploadResourceAction (data){
            if(data && data instanceof Array && data.length && data.slice(-1)[0].url) {
                this.uploadUrl.push(data.slice(-1)[0].url);
            }
        },
        handleMedia (data){
            this.mediaData = data;
        },
        handleDelete (){
            const {data,parent} = this;
            this.$emit('handleDelete',data,parent);
        },
        handleReply (){
            this.showReplyInput = true;
            this.actionType = 'reply';
        },
        clearReply (){
            this.uploadUrl = [];
            this.valueVal = '';
            this.$refs.upload.fileList = [];
        },
        handleCancel() {
            this.clearReply();
            this.showReplyInput = false;
        },
        replyAction (){
            // const {data,parent,valueVal} = this;
            let filePath = this.uploadUrl.join(",");
            if(!this.valueVal){
                return ElMessage.warning("请输入内容！");
            }
            let request = { content: this.valueVal, filepath_str: filePath };
            this.uploadFloorData(this.floor - 1, this.parentid, request, () => {
                this.data.childsize++;
                this.handleHidden();
            });            
            this.clearReply();
        },

        handleUpdate (){
            const {data} = this;
            this.actionType = 'update';
            this.valueVal = data.content;
            this.$emit('toReply',true);
        },

        updateAction (){
            const {data,valueVal,parent} = this;
            this.handleCancel();
            this.$emit('handleUpdate',{...data,valueVal},parent);
        },

        handleHidden (){
            // debugger
            if(this.showChildBelow) {
                this.isClose = !this.isClose;
                this.$emit("handleClose", this.isClose);
            }
            else{
             
                this.$emit('toClick', this.data);
            }
        }
    },
}
</script>

<style scoped lang='less'>
    .answer-rate{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #1A1A1A;
    }
    .answer-imgs{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        >img, >.video-shade>video{
            width:130px;
            height: 78px;
            border-radius: 4px;
            margin-right:10px;
            cursor: pointer;
            margin-bottom: 10px;
            &:last-child{
                margin-right:0;
            }
        }
        >span{
            display: inline-block;
            width:130px;
            height: 78px;
            border-radius: 4px;
            margin-right:10px;
            
        }
        .video-shade{
            position: relative;
            width:130px;
            height: 78px;
            margin-right:10px;
            cursor: pointer;
            margin-bottom: 10px;
            .video-bg{
                position: absolute;
                left:0;right:0;
                top:0;bottom: 0;
                background-color: rgba(0,0,0,.3);
                display: flex;
                justify-content: center;
                align-items: center;
                >span{
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    background-color: rgba(255,255,255,.5);
                    border:2px solid #FFFFFF;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    >i{
                        font-size: 20px;
                        margin-right:0;
                        color:#FFFFFF;
                    }
                }
            }
            >img{
                width:100%;
                height:100%;
               border-radius: 4px;
            }
        }
    }
    .answer-video{
        width: 600px;
        // height:300px;
        border-radius: 4px;
        >img{
            height:100%;
        }
    }
    .answer-ui{
        .answer-item{
            .answer-item-shade {
                display: flex;
                //align-items: center;
                width:100%;
                padding-bottom: 10px;
                padding-top: 10px;
                >img{
                    flex:0 0 auto;
                    width:50px;
                    height:50px;
                    border-radius: 50%;
                    margin-right: 10px;

                }
                .shade-plate{
                    flex:1 1 auto;
                    .answer-item-li{
                        display: flex;
                        line-height: 25px;
                        padding-right: 10px;
                        min-height:25px;
                        &.column{
                            flex-direction: column;
                            margin-top: 10px;
                        }
                        .answer-upload{
                            padding-top:10px;
                        }
                        .reply-btn{
                            font-size: 12px;
                            text-align: right;
                            >span{
                                display: inline-block;
                                padding-left:10px;
                                cursor: pointer;
                                color:#999999;
                                &:last-child{
                                    color:#3EB36B;
                                }
                            }
                        }
                        .answer-txt{
                            margin-right:auto;
                            flex:1 1 auto;
                            text-align: left;
                            text-align: justify;
                            word-break: break-all;
                            
                            &.name{
                                font-size: 14px;
                                color: #999999;
                            }
                            &.title{
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #999999;
                                line-height: 28px;
                                padding-bottom: 10px;
                            }
                        }
                        .answer-btn{
                            margin-left:auto;
                            font-size: 12px;
                            cursor: pointer;
                            flex:0 0 auto;
                            margin-left:10px;
                            display: inline-block;
                            &.delete {
                                color:#FF6F6F;
                            }
                            &.reply{
                                color:#999999;
                                position: relative;
                                top: 1px;
                            }
                            &.update{
                                color:#999999;
                            }
                        }
                    }
                }
                
            }
            .icon-msg {
                text-align: right;
                padding: 5px 0;
                .icon-text {
                    cursor: pointer;
                    color: #999999;
                    &:hover {
                        color: #2974EA;
                    }
                }
            }
        }
    }
</style>