

const state = {
    applyList : {
        tabActive : null,
    },
    menus : {
        routerMap : {},
    },
    isEditor : false,
    courseMsg : {},
    slot : '',//实验详情标识
    issueAutoid : '',//评论 论题id
    myexp : {},//存储我的实验数据
    selectKey : '',
};

const getters = {};
Object.keys(state).forEach((k) => {
  getters[k] = (s) => s[k]
});

const mutations = {
    SET_TAB_ACTIVE : (state,tabActive)=>{
        state.applyList.tabActive = tabActive;
    },
    SET_ROUTER_MAP : (state,routerMap)=>{
        state.menus.routerMap = routerMap;
    },
    SET_IS_EDITOR : (state,isEditor)=>{
        state.isEditor = isEditor;
    },
    SET_COURSEMSG : (state,courseMsg)=>{
        state.courseMsg = courseMsg;
    },
    SET_SLOT : (state,slot) =>{
        state.slot = slot
    },
    SET_ISSUEAUTOID : (state,issueAutoid)=>{
        state.issueAutoid = issueAutoid
    },
    SET_MYEXP : (state,myexp)=>{
        state.myexp = myexp
    },
    SET_SELECT_KEY : (state,selectKey)=>{
        state.selectKey = selectKey
    }
};

const actions = {
    setTabActive : ({commit},tabActive)=>{
        commit('SET_TAB_ACTIVE',tabActive);
    },
    setRouterMap : ({commit},routerMap)=>{
        commit('SET_ROUTER_MAP',routerMap);
    },
    setIsEditor : ({commit},isEditor)=>{
        commit('SET_IS_EDITOR',isEditor);
    },
    setCourseMsg : ({commit},courseMsg)=>{
        commit('SET_COURSEMSG',courseMsg);
    },
    setSlot  : ({commit},slot)=>{
        commit('SET_SLOT',slot);
    },
    setIssueAutoid : ({commit},issueAutoid)=>{
        commit('SET_ISSUEAUTOID',issueAutoid);
    },
    setMyExp : ({commit},myexp)=>{
        commit('SET_MYEXP',myexp);
    },
    setSelectKey : ({commit},selectKey)=>{
        commit('SET_SELECT_KEY',selectKey);
    },
};


export default {
    state,
    getters,
    mutations,
    actions,
}