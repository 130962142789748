<template>
    <div class="interactive-ui">
        <div class="interactive-item" :style='item.style' v-for="item in labelData" :key="item.key">
            <div class="interactive-title">
                <span class="title-txt">{{item.title}}</span>
                <span class="title-btn" v-for="btn in item.btns" :key="btn.key">
                    {{btn.label}}<i :class="btn.icon"></i>
                </span>
            </div>
            <div class="interactive-content">
                <template v-if="item.type === 'title'">
                    <el-input v-model="issueName" size='small'  placeholder="请输入问题标题" />
                    <WangEditor theme='dark' ref="wangEditor" :customButton='customButton' @toSubmit='issueWangEditorHandleSubmit' toolLocation="bottom" :menus='menu' class='wang-editor'/>
                </template>
                <template v-if="item.type === 'context'">
                    <div class="interactive-box">
                        <template v-if="issueList.length">
                            <div v-for="item in issueList" class="context-item" :currentItem="item" :key='item.autoid' @click="showDetails(item)">
                                <span class="context-title">{{item.name}}</span>
                                <span class="context-label">{{item.realname}} 发布于 {{item.createtime}}， {{item.answer_size}}人回答</span>
                            </div>
                        </template>
                        <template v-else>
                            <NoData></NoData>
                        </template>
                    </div>  
                </template>
            </div>
        </div>
        
        <div :class="['interactive-drawer',popup.state ? 'show' : '']">
            <div class="drawer-box">
                <div class="drawer-head">
                    <span class="drawer-head-back" @click="handlePopup">
                        <el-icon><arrow-left /></el-icon>返回
                    </span>
                </div>
                <div class="drawer-content" v-if="currentIssue">
                    <div class="interactive-item">
                        <div class="interactive-title">
                            <span class="title-txt drawer-title">{{currentIssue.name}}</span>
                        </div>
                        <div class="interactive-content">
                            <div class="interactive-msg">
                                <span class="interactive-name">{{currentIssue.user_data && currentIssue.user_data.realname}}</span>
                                <span class="interactive-release">发布于 {{currentIssue.createtime}}</span>
                            </div>
                            <div class="interactive-describe"><Look :defaultHtmlContent="currentIssue.content" :isFull="true"></Look></div>
                        </div>
                        <div class="interactive-title">
                            <span class="title-txt drawer-title">全部回答</span>
                        </div>
                        <div class="interactive-content">
                            <div class="doubt-content">
                                <div class="doubt-list">
                                    <Answer :options='options' @load='handleLoad' :data='answerData'/>
                                    <div class='list-item' v-for='item in answerData' :key="item.autoid">
                                        <img :src="item.iconUrl" alt="">
                                        <div class='list-item-head'>
                                            <span>{{item.name}}·发布于 {{item.time}}</span>
                                            <span>{{item.content}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="interactive-item">
                        <div class="doubt-evaluate">
                            <span class="evaluate-input"><el-input size="small" v-model="value" type="textarea" placeholder="请输入您的评论" /></span>
                            <span class="evaluate-btn"><Buttons @click="addAnswer" type='primary'>发表评论</Buttons></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import WangEditor from '@/components/course/wangEditor/index.vue';
import NoData from '@/components/common/nodata.vue';
import Answer from '@/components/course/Answer.vue';
import Look from '@/components/course/wangEditor/look.vue';
import Buttons from '@/components/course/Buttons.vue';
import checker from '@/utils/checker';
import axios from '@/utils/axios';
import server from '@/service/server';
import { ElMessage } from "element-plus";
export default {
    data (){
        return {
            labelData : [
                {key : '1',title:'我要提问',type : 'title',style : {flex:'0 0 auto'}},
                {key : '2',title:'等你回答',type : 'context',style : {flex:'1 1 auto',flexDirection : 'column',display:'flex'},btns : [
                    // {key : '3',label:'更多',icon : 'el-icon-arrow-right'}
                ]},
            ],
            menu:[ 'image',],
            popupConfig : [
                {key : '查看问题详情',type : 'problem'}
            ],
            popup : {
                state : false,
            },
            customButton : [
                {key : 'submit',label:'保存',on_click:this.issueWangEditorHandleSubmit}
            ],
            issueList: [],
            issueName: "",
            currentIssue: null,
            answerData : [],
            options : {//评论组件的功能
                delete : this.handleDelete,
                release : this.handleRelease,
                update : this.handleUpdate,
                closeDelete : false,
                closeUpdate : false
            },
            evaluateMap : {},//存储回复数据键值对
            value : '',
            answerAutoidMap : {},
            evaluateAutoidMap : {},
        }
    },
    props : {
        expautoid : {
            type : [String,Number],
            default : '',
        },
        targettable : {
            type : String,
            default : '',
        },
        targetautoid : {
            type : String,
            default : '',
        }
    },
    mounted() {
        this.queryIssueData()
    },
    components : {
        WangEditor,
        Answer,
        Look,
        Buttons,
        NoData
    },
    watch:{
        'targetautoid': 'targetautoidMethods'
    },
    methods : {
        targetautoidMethods(){
            this.queryIssueData();
        },
        handlePopup() {
            this.popup.state = !this.popup.state;
            if(!this.popup.state) this.currentIssue = null
        },
        showDetails (issue){
            this.handlePopup()
            this.currentIssue = issue
            this.getAnswer()
        },
        issueWangEditorHandleSubmit(content) {
            if(!content || !this.issueName) return ElMessage.warning('信息不得为空！');
            let request = {
                targettable: this.targettable,
                targetautoid: this.targetautoid,
                expautoid: this.expautoid,
                name: this.issueName,
                content: content
            };
            // if(!this.issueName || !content ) return ElMessage.warning('信息不得为空！');
            // this.issueName = ""
            if(this.$refs.wangEditor && this.$refs.wangEditor[0])this.$refs.wangEditor[0].clear();
            axios.post(server.uiissue, request).then(result=>{
                if(result && result.status && result.data && result.data.result){
                    this.issueName = '';
                    this.$message({message: '提交成功！',type: 'success',duration:1000})
                    this.queryIssueData();
                }
            })
        },
        queryIssueData() {
            let request = {
                targettable: this.targettable,
                targetautoid: this.targetautoid,
                _disable_page_: true,
                _order_column : 'createtime',
                _order_style : 'desc',
                get_all: ""
            }
            axios.get(server.uiissue + checker.objTurnParams(request)).then(result=>{
                if(result.status && result.data && result.data.result && result.data.result.length){
                    let issueList = result.data.result || [];
                    issueList.map(item=>{
                        item.answer_size = parseInt(item.answer_size || 0);
                        if(item.user_data){item.realname = item.user_data.realname}  
                    })
                    this.issueList = issueList;
                }
            })
        },
        addAnswer () {//发表评论
            const {value} = this;
            let request = {
                content : value,
                issueautoid : this.currentIssue.autoid,
                expautoid: this.expautoid
            };
            if(!value)return this.$message({message: '请输入评论内容！',type: 'warning',duration:1000});
            axios.post(server.answer,request).then(result=>{
                if(result.status){
                    this.getAnswer();
                    this.$message({message: '发表成功！',type: 'success',duration:1000});
                    this.value = '';
                }
            })
        },
        getAnswer (func){//获取评论
            let request = {
                issueautoid: this.currentIssue.autoid,
                _disable_page_: true,
                _order_column : 'createtime',
                _order_style : 'desc',
                get_all: ""
            };
            axios.get(server.answer + checker.objTurnParams(request)).then(result=>{
                if(result.status && result.data && result.data.result && result.data.result.length){
                    let answerData = result.data.result || [];
                    answerData.map(item=>{
                        item.time = item.createtime;
                        item.evaluateSize = item.evaluate_size;
                        item.type = 'answer';
                        if(this.answerAutoidMap[item.autoid]){
                            item.key = this.answerAutoidMap[item.autoid];
                        }else {
                            item.key = checker.uuid(6) + item.autoid;
                            this.answerAutoidMap[item.autoid] = item.key;
                        }
                        if(this.evaluateMap[item.autoid]){
                            item.children = this.evaluateMap[item.autoid];
                        }
                        if(item.user_data){ 
                            item.name = item.user_data.realname;
                            item.iconUrl = item.user_data.face ? this.APIORIGIN + item.user_data.face : '';
                        }else item.name = '';
                    })
                    this.answerData = answerData;
                    if(func)func();
                }
            })
        },
        handleUpdate (data,parent){
            let request = {
                content : data.value,
                put_data : true,
                autoid : data.autoid,
            };
            if(!data.value)return this.$message({message: '请输入评论内容！',type: 'warning',duration:1000});
            switch(data.type){
                case 'answer' ://发布评论
                    axios.post(server.answer,request).then(rsult=>{
                        if(rsult.status){
                            this.$message({message: '操作成功！',type: 'success',duration:1000});
                            this.getAnswer(()=>{
                                this.handleLoad(data);
                            });
                        }
                    })
                    break;
                case 'evaluate' : //发布回复
                    axios.post(server.evaluate,request).then(rsult=>{
                        if(rsult.status){
                            this.$message({message: '操作成功！',type: 'success',duration:1000});
                            this.handleLoad(parent);
                        }
                    })
                    break;
            }
        },
        handleDelete (data,parent){
            let request = {
                autoid : data.autoid,
                delete_data : true,
            };
            this.$confirm(`即将删除当前${data.type === 'answer' ? '评论' :'回复'}，是否继续？`, '消息提醒', { 
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                switch(data.type){
                    case 'answer' ://删除评论
                        axios.post(server.answer,request).then(rsult=>{
                            if(rsult.status){
                                this.$message({message: '操作成功！',type: 'success',duration:1000});
                                this.getAnswer();
                            }
                        })
                        break;
                    case 'evaluate' : //删除回复
                        axios.post(server.evaluate,request).then(rsult=>{
                            if(rsult.status){
                                this.$message({message: '操作成功！',type: 'success',duration:1000});
                                this.handleLoad(parent);
                            }
                        })
                        break;
                }
            }).catch(() => {});
            
            
        },
        handleRelease (data,parent){//回复操作
            let request = {
                content : data.valueVal,
            };
            if(!data.valueVal)return this.$message({message: '请输入评论内容！',type: 'warning',duration:1000});
            switch(data.type){
                case 'answer' ://发布评论
                    request.answerautoid = data.autoid;
                    axios.post(server.evaluate,request).then(rsult=>{
                        if(rsult.status){
                            this.$message({message: '操作成功！',type: 'success',duration:1000});
                            this.getAnswer(()=>{
                                this.handleLoad(data);
                            });
                        }
                    })
                    break;
                case 'evaluate' : //发布回复
                    request.answerautoid = data.autoid;
                    axios.post(server.evaluate,request).then(rsult=>{
                        if(rsult.status){
                            this.$message({message: '操作成功！',type: 'success',duration:1000});
                            this.handleLoad(parent);
                        }
                    })
                    break;
            }
        },
        handleLoad (data,back){//加载回复数据
            const {answerData} = this;
            let request = {
                answerautoid : data.autoid,
                _order_column : 'createtime',
                _order_style : 'desc',
                _disable_page_: true
            };
            axios.get(server.evaluate + checker.objTurnParams(request)).then(result=>{
                if(result.status){
                    let evaluateData = result.data.result || [],
                    idx = answerData.findIndex(item=>item.autoid === data.autoid);
                    evaluateData.map(item=>{
                        item.time = item.createtime;
                        item.evaluateSize = item.evaluate_size;
                        item.type = 'evaluate';

                        if(this.evaluateAutoidMap[item.autoid]){
                            item.key = this.evaluateAutoidMap[item.autoid];
                        }else {
                            item.key = checker.uuid(6) + item.autoid;
                            this.evaluateAutoidMap[item.autoid] = item.key;
                        }

                        if(item.user_data){ 
                            item.name = item.user_data.realname;
                            item.iconUrl = item.user_data.face ? this.APIORIGIN + item.user_data.face : '';
                        }else item.name = '';
                    })
                    if(idx>=0){
                        answerData[idx].children = evaluateData;
                        this.evaluateMap[answerData[idx].autoid] = evaluateData;
                    }
                    this.answerData = [...answerData];
                    if(back)back(true);
                }else {
                    if(back)back(false); 
                }
            })
        },
    },
    unmounted (){
    },
}
</script>

<style scoped lang='less'>

    .doubt-list{
        .list-item{
            display: flex;
            margin-bottom: 10px;
            >img{
                width: 50px !important;
                height: 50px;
                border-radius: 50%;
                margin-right: 10px;
            }
            .list-item-head{
                font-size: 14px;
                color: #999999;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                >span:nth-child(2){
                    display:inline-block;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                }
            }
            
        }
    }

    .interactive-ui{
        width:100%;
        height:100%;
        display: flex;
        flex-direction: column;
        .interactive-drawer{
            position: absolute;
            left: 100%;
            top: 0;
            width:100%;
            height:100%;
            z-index: 10003;
            display: flex;
            transition: left .3s;
            .drawer-box{
                background-color: #FFF;
                border-radius: 4px;
                flex:1 1 auto;
                margin:10px;
                padding: 10px;
                box-shadow: 0 0 10px #999999;
                display: flex;
                flex-direction: column;
                width:100%;
                .drawer-head{
                    width:100%;
                    display: flex;
                    flex:0 0 auto;
                    padding-bottom: 10px;
                    width:100%;
                    .drawer-head-back{
                        margin-right:auto;
                        font-size: 15px;
                        cursor: pointer;
                        -moz-user-select:none; /*火狐*/
                        -webkit-user-select:none; /*webkit浏览器*/
                        -ms-user-select:none; /*IE10*/
                        -khtml-user-select:none; /*早期浏览器*/
                        user-select:none;
                        display: flex;
                        align-items: center;
                        .el-icon-arrow-left{
                            font-size: 15px;
                        }
                        &:active {
                            color:#F18C00;
                        }
                    }
                }
                .drawer-content{
                    flex:1 1 auto;
                    overflow: auto;
                    display: flex;
                    flex-direction: column;
                    width:100%;
                    .interactive-item {
                        flex:1 1 auto;
                        width:100%;
                        img {
                            width: 100%;
                        }
                    }
                    >.interactive-item:first-child{
                        overflow: auto;
                    }
                    >.interactive-item:last-child{
                        margin-bottom: 0;
                        height: 165px;
                        flex:0 0 auto;
                    }
                }
            }
            &.show {
               left: 0;
            }
        }
        
    }
    .interactive-item{
        width:100%;
        margin-bottom: 10px;
        .interactive-title{
            width:100%;
            display: flex;
            color: #FFFFFF;
            font-size: 16px;
            align-items: center;
            flex:0 0 auto;
            .title-txt{
                margin-right: auto;
                font-weight: bold;
            }
            .drawer-title{
                color:#1A1A1A;
                text-align: left;
            }
            .title-btn{
                margin-left:auto;
                font-size: 12px;
                color:#666666;
                cursor: pointer;
                -moz-user-select:none; /*火狐*/
                -webkit-user-select:none; /*webkit浏览器*/
                -ms-user-select:none; /*IE10*/
                -khtml-user-select:none; /*早期浏览器*/
                user-select:none;
                &:active {
                    color:#FFFFFF;
                }
            }
        }
        .interactive-content{
            position: relative;
            margin-top: 10px;
            width: 100%;
            flex: 1 1 auto;
            font-size: 16px;
            
            .interactive-box{
                position: absolute;
                left:0;right:0;
                top:0;bottom:0;
                background-color: #FFFFFF;
                padding: 10px 20px;
                border-radius: 4px;
                overflow: auto;
                .context-item{
                    width:100%;
                    text-align: left;
                    margin-bottom: 20px;
                    line-height: 25px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .context-title{
                        width:100%;
                        display: inline-block;
                        font-weight: bold;
                        color:#1A1A1A;
                        cursor: pointer;
                        &:hover{
                            color:#F18C00;
                        }
                    }
                    .context-label{
                        width:100%;
                        display: inline-block;
                        font-size: 12px;
                        color:#999999;
                    }
                }
            }   

            .interactive-msg{
                width:100%;    
                margin-bottom: 15px;
                text-align: left;
                .interactive-name{
                    font-size: 14px;
                    color: #666666;
                    margin-right: 10px;
                }
                .interactive-release{
                    font-size: 14px;
                    color: #999999;
                }   
            }

            .interactive-describe{
                font-size: 15px;
                text-align: left;
                line-height: 25px;
                clear: both;
                >img{
                    width: 205px;
                    height: 122px;
                    border-radius: 10px;
                    float: left;
                    margin-right: 10px;
                }
            }
        }
        .doubt-evaluate {
            height: 100%;
            display: flex;
            flex-direction: column;
            .evaluate-input {
                flex:0 0 auto;
                height: 110px;
                .el-textarea {
                    height: 100%;
                }
            }
            .evaluate-btn {
                flex:1 1 auto;
                padding: 10px 0 7px;
                button {
                    width: 90px;
                    height: 38px;
                    background: #2974EA;
                    box-shadow: 0px 1px 4px 0px rgba(3, 71, 144, 0.39);
                    border-radius: 4px;
                    font-size: 16px;
                }
            }
        }
    }
    /deep/ .doubt-evaluate .evaluate-input .el-textarea textarea {
        height: 100%;
    }
    .wang-editor{
        height:150px;
    }

    /deep/ .drawer-content::-webkit-scrollbar{
        width:0;
    }
    /deep/ .interactive-box::-webkit-scrollbar{
        width:0;
    }
</style>