//路由守卫
import axios from '@/utils/axios'
import server from '@/service/server';
import common from '@/utils/common';
import checker from '@/utils/checker';
import { ElMessage} from "element-plus";
let routeMap = null;
const exclude = ['/login','/news_details','/solution_goods','/','/home1','/solution','/goods','/download','/aboutus','/news', '/hlogin', '/test', '/home', '/exps', '/notice', '/special', '/ranking', '/noticemain', '/NewsFeature','/GwNoticeText']; //排除监控的路由
const funcCache = [loginMonitor, routeMonitor];
let timerOut = 0;
async function monitor(to) {
    if (!routeMap) routeMap = common.analysisRoute();
    const param = await pips(...funcCache)({ current: true, path: to.path });
    if (!param.current) return { hash: `#${common.gethashcode()}`, path: param.path, query: param.query, replace: true };
    return true;
};

function pips(...funcs) {
    return function(def) {
        //arg 第一个参数 或者时默认参数 func下一个函数
        return funcs.reduce(function(arg, func) {
            if (!arg.current) return arg; //禁止通行 直接返回结果
            return func(arg); //通行流向下一个函数进行监控
        }, def);
    }
}

//浏览器回退到子界面时，需要检测是否存在参数，如果不存在 需要重定向父界面
function routeMonitor(arg) {
    if (!routeMap) return arg;
    const pathCache = routeMap[arg.path];
    if (!pathCache || !pathCache.parent) return arg; //没找到路由 或者 没有param 时 直接放行
    if (pathCache.parent) {
        let param = common.getStorParams({ path: pathCache.path });
        if (!param) { //对应子界面没有参数时
            return { path: pathCache.parent, current: false };
        }
    }
    return arg;
}

//检测登录状态 如果没有登录 只能访问首页和登陆注册页面
async function loginMonitor(arg) {

    

    let isLogin = true;
    if (exclude.indexOf(arg.path) >= 0) return arg; //排除的路由 直接放行
    const response = await axios.get(server.userinfo);
    if (response && response.status && response.data && Array.isArray(response.data.result)) {
        isLogin = false;
    }
    let params = checker.getQueryParams();
    if (isLogin) {
        localStorage.clear();
        sessionStorage.clear();
        if(new Date().getTime() - timerOut >= 3000 && window._SIGN_){
            ElMessage.warning('登录标识已失效，请重新登录！');
            timerOut = new Date().getTime();
            window._SIGN_ = false;
            setTimeout(()=>{
                window._SIGN_ = true;
            },3000)
        }
        return { path: '/', query: params, current: false }
    };
    return arg;
}






export default async function guard(to, from) {
    return await monitor(to);
}