<template>
  <!-- 新闻/活动 专题 -->
  <div class="special-ui">
    <!-- <div class="special-banber">
      <Banber></Banber>
    </div> -->
    <div class="special-main common-width">
      <div class="special-title">
        <div class="title-name">
          <span>活动专题</span>
          <span>SPECIAL</span>
        </div>
      </div>
      <div class='special-content'>
        <div class='special-item' v-for='item in specialList' :key='item.key'>
          <div class='item-title'>
            <div class='title-top'>
              <span class='title-name'>{{item.title}}</span>
              <Buttons class='title-more' @click="chackdetails(item)">查看更多</Buttons>
            </div>
            <span>{{ item.content }}</span>
          </div>
          <div class='item-ul' v-if='item.exp_data'>
            <div class='item-li' @click="handleExpClick(exp)" v-for='exp in item.exp_data.filter((item,idx)=>idx<4)' :key='exp.autoid'>
                <div class='item-shade'>
                  <img :src="exp.face" alt="">
                  <span class='li-title'>{{ exp.title }}</span>
                  <div class='li-footer'>
                    <span>{{ exp.majorname }}</span>
                    <span><el-icon class='li-user'><user /></el-icon>{{ exp.realname }}</span>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <NoData v-if='!specialList.length'></NoData>
      </div>
    </div>
    <div class="special-page">
      <Pagination
        v-if="specialList.length"
        :total="paging.total || 0"
        :pageSize="paging.pageSize || 5"
        :currentPage="paging.currentPage || 1"
        @pageChange="pageChange"
        :hide-on-single-page="false"
      />
    </div>
  </div>
</template>

<script>
import Buttons from "@/components/course/Buttons.vue";
import Banber from "./Banner.vue";
// import { mapActions } from "vuex";
import NoData from "@/components/common/nodata.vue";
import common from "@/utils/common";
import axios from "@/utils/axios";
import server from "@/service/server";
import checker from "@/utils/checker";
import Pagination from "@/components/course/Pagination.vue";
import { ElLoading } from "element-plus";
import axiosTool from '@/utils/axios_tool';

export default {
  data() {
    return {
      specialListTop: [],
      specialList: [],
      paging: {
        total: 0,
        pageSize: 3,
        currentPage: 1,
      },
      axiosTool : {},
    };
  },
  components: {
    Buttons,
    Banber,
    Pagination,
    NoData,
  },
  created (){
    this.axiosTool = new axiosTool(this,[
      {key:'get_top_exp',type : 'get',title : '获取置顶数据',url : server.activitynotice,params:{
        getreq : {publish:2,totop:true },
      }},
      {key:'get_no_top_exp',type : 'get',title : '获取非置顶数据',url : server.activitynotice,params:{
        template : 'paging:page=currentPage|per_page=pageSize',
        getreq : {publish:2,totop:false},
      }},
      {key:'get_no_top_exp_count',type:'get',title:'获取非置顶总数',url:server.activitynotice,params:{
        getreq : { publish:2,count:true,_dispage_:true,totop:false, },
      }},
      {type:'post',title:'添加浏览记录',url:server.exppv},
    ]);
  },
  mounted() {
    this.axiosTool.loading = true;
    this.axiosTool._axios().then(data=>{
      const [topExp,noTopExp,noTopExpCount] = data;
      this.handleTopExp(topExp);
      this.handleNoTopExp(noTopExp);
      this.handleNoTopExpCount(noTopExpCount);
      this.axiosTool.loading = false;
    })


    // this.getTotopExpData();
    // // this.getExpData();
    // this.getExpDataPage();
  },
  methods: {

    handleTopExp(data){
      let specialListTop = [];
      if(data && data.result){
        data.result.map(item=>{
          item.key = item.autoid;
          if(Array.isArray(item.exp_data)){
            item.exp_data.map((exp,index)=>{
              if(parseInt(exp.publish) == 2){
                let faces = exp.face.split(',');
                faces.map(face=>{
                  if(face.indexOf('jpg') != -1){
                      exp.face = face
                  }else if(face.indexOf('png') != -1){
                      exp.face = face
                  }else if(face.indexOf('jpeg') != -1){
                      exp.face = face
                  }else{
                      // exp.face = require('@/assets/images/default_exp.jpg')
                      if(exp.face=='')
                            exp.face = require('@/assets/images/default_exp.jpg')
                  }
                })
                if(exp.master_data){
                  exp.realname = exp.master_data.realname  || '暂无';
                }else {
                   exp.realname = '暂无';
                }
                if(exp.major_data){
                  exp.majorname = exp.major_data.name  || '暂无';
                }else {
                   exp.majorname = '暂无';
                }
              }else{
                delete item.exp_data[index];
              }
            })
          }
          specialListTop.push(item);
        })
        this.specialListTop = specialListTop;
      }
    },
    handleNoTopExp(data){
      const {paging,specialListTop} = this;
      let specialList=[];
      if(data && data.result){
        data.result.map(item=>{
          item.key = item.autoid;
          if(Array.isArray(item.exp_data)){
            item.exp_data.map((exp,index)=>{
              if(parseInt(exp.publish) == 2){
                let faces = exp.face.split(',');
                faces.map(face=>{
                  if(face.indexOf('jpg') != -1){
                      exp.face = face
                  }else if(face.indexOf('png') != -1){
                      exp.face = face
                  }else if(face.indexOf('jpeg') != -1){
                      exp.face = face
                  }else{
                      exp.face = require('@/assets/images/default_exp.jpg');
                  }
                })
                if(exp.master_data){
                  exp.realname = exp.master_data.realname || '暂无';
                }else {
                   exp.realname = '暂无';
                }
                if(exp.major_data){
                  exp.majorname = exp.major_data.name || '暂无';
                }else {
                   exp.majorname = '暂无';
                }
              }else{
                delete item.exp_data[index];
              }
            })
          }
          specialList.push(item);
        })
      }
      if(paging.currentPage === 1){
        specialListTop.map(item=>{
          specialList.unshift(item);
        })
      }
      this.specialList = specialList;
      // console.log(specialList);
    },

    handleNoTopExpCount (data){
      if(!data)return;
      this.paging.total = parseInt(data.data);
    },

    pageChange(currentPage) {
      this.paging.currentPage = currentPage;
      this.queryTable();
      
    },
    queryTable (){
      const {axiosTool} = this;
      this.axiosTool.loading = true;
      axiosTool.get_no_top_exp().then(noTopExp=>{
        this.handleNoTopExp(noTopExp);
        axiosTool.get_no_top_exp_count().then(noTopExpCount=>{
          this.handleNoTopExpCount(noTopExpCount);
          this.axiosTool.loading = false;
        })
      })
    },
    chackdetails(data) {
      common.setStorParams(this, data);
      this.$router.push("/NewsFeature");
    },

    handleExpClick(data) {
      common.removeParams('/exps');
      common.setStorParams({ path: "/expcore" }, { autoid: data.autoid });
      this.$router.push("/expcore");
      this.postExppv(data.autoid);
    },
    postExppv(autoid) {
      //浏览量
      this.axiosTool.loading = true;
      this.axiosTool.exppv({},data=>{
        const {postreq} = data;
        postreq.expautoid = autoid
        return {...data,postreq}
      }).then(result=>{
          //console.log(result);
          this.axiosTool.loading = false;
      })
    },
  },
};
</script>

<style scoped lang="less">
@import url("@/styles/common.less"); //公共样式

.special-page{
  padding:20px 0;
  text-align:center;
}
.media; //媒体查询
.no-data{
  margin: auto;
  .no-data-img{
    background-image: url('~@/assets/images/Empty.jpg');
    background-size: 1595px 3004px;
    background-position: -1047px -204px;
    width: 242px;
    height: 228px;
    display: inline-block;
  }
}


.special-title {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom:10px;
  .title-name {
    width: 120px;
    height: 114px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("~@/assets/images/news.png");
    > span:nth-child(1) {
      position: relative;
      top: 10px;
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }

    > span:nth-child(2) {
      position: relative;
      top: 20px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #b6b6b6;
    }
    &::after {
      position: relative;
      top: 30px;
      width: 160px;
      height: 2px;
      background-color: #1e5fc2;
      content: "";
      display: inline-block;
    }
  }
}

.special-ui{
  width:100%;
  height:100%;
  .special-main{
    margin: auto;
  }
}

.special-content{
  width:100%;
  height:100%;
  .special-item{
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,.1);
    padding:20px;
    border-radius:4px;
    margin-bottom:10px;
    &:last-child{
      margin-bottom:0;
    }
    .item-title{
      margin-bottom: 10px;
      .title-top{
        display: flex;
        position:relative;
        margin-bottom:10px;
        .title-name{
          position:absolute;
          left:50%;top:0;
          transform:translate(-50%, 0);
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #1A1A1A;
          height: 100%;
          display: flex;
          align-items: center;
        }
        .title-more{
          margin-left:auto;
        }
      }
      >span{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 25px;
        text-align: justify;
        text-indent: 32px;
        display: inline-block;
        line-height: 21px;
        word-break: break-all;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
    .item-ul{
      display: flex;
      // justify-content: space-between;
      .item-li{
        flex:0 0 25%;
        &:first-child .item-shade{
          padding-left:0;
        }
        &:last-child .item-shade{
          padding-right:0;
        }
        .item-shade{
          display: flex;
          flex-direction: column;
          padding: 0 10px;
          cursor:pointer;
          >img{
            width: 100%;
            height: 171px;
            border-radius:4px;
          }
          .li-title{
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #1A1A1A;
            line-height: 27px;
            padding:10px 0;
            word-break: break-all;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            height: 23px;

          }
          .li-footer{
            display: flex;
            align-items: center;
            justify-content: space-between;
            >span:nth-child(1){
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #1A1A1A;
            }
            >span:nth-child(2){
              display:flex;
              align-items: center;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
            }
            .li-user{
              margin-right:5px;
            }
          }
        }
      }
    }
  }
}
</style>
