<template>
    <el-sub-menu class="el-sub-menu-cctv" :index="data.name" v-if="data.child">
        <template #title>{{data.label}}</template>
        <template v-for="menu in data.children" :key="menu.key">
            <Loop :data="menu" v-if="menu.child"></Loop>
            <el-menu-item class="el-menu-item-cctv" v-else :index="menu.name">{{menu.label}}</el-menu-item>
        </template>
    </el-sub-menu>
</template>

<script>
    import Loop from './loop.vue';
    export default {
        name : "loop",
        components : {
            Loop
        },
        data (){
            return {


            }
        },
        props : {
            data : {
                type : Object,
                default : function (){
                    return {};
                }
            }
        },
    }
</script>

<style scoped lang="less">
    @import url('@/assets/less/theme.less');
    .el-sub-menu-cctv{
        list-style: none;
        margin: 0;
        padding-left: 0;
        float: left;
        height: 60px;
        line-height: 60px;
        border-bottom: 2px solid transparent;
    }

    
    
    .el-sub-menu-cctv.is-active{
        border-color: @global_background;
    }

    .el-sub-menu-cctv :deep(.el-sub-menu__title){
        height: 100%;
        border-bottom: 2px solid transparent;
        display: flex;
        align-items: center;
        font-size: var(--el-menu-item-font-size);
        color: @global_color;
        padding: 0 var(--el-menu-base-level-padding);
        list-style: none;
        cursor: pointer;
        position: relative;
        transition: border-color var(--el-transition-duration),background-color var(--el-transition-duration),color var(--el-transition-duration);
        box-sizing: border-box;
        white-space: nowrap;
        float: left;
    }
    
    .el-menu--horizontal .el-menu .el-menu-item.el-menu-item-cctv{
        color:#333333;
    }
    .el-sub-menu-cctv.is-active :deep(.el-sub-menu__title),
    .el-menu--horizontal .el-menu .el-menu-item.el-menu-item-cctv.is-active,
    .el-menu--horizontal .el-menu .el-menu-item.el-menu-item-cctv:not(.is-disabled):focus,
    .el-menu--horizontal .el-menu .el-menu-item.el-menu-item-cctv:not(.is-disabled):hover{
        color:@global_background;
    }
    
</style>