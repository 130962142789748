<template>
    <!-- 我的实验 -->
    <div class="my-exp">
        <div class="my-title">
            <span>我的实验</span>
            <div class="my-search">
                    <el-input size='small' class="my-search-input" v-model="searchData.title" placeholder="请输入关键字" />
                    <Buttons @click="handleClear" type="info">重置</Buttons>
                    <Buttons @click="handleSearch" type="blue" icon="el-icon-search">查询</Buttons>
            </div>
        </div>
        <div class="exp-main">
            <div class="exp-item"  v-for="item in dataList" :key="item.key">
                <!-- <img @click="handleExp(item)" :src="item.imgUrl" alt=""> -->
                  <!-- <img @click="handleExp(item)" :src="item.imgUrl" alt=""> -->
                  <img :src="item.imgUrl"  @click="handleCommand(item,'实验笔记')" alt=""> 

                <div class="item-center"  @click="handleCommand(item,'实验笔记')">
                    <span>{{item.title}}</span>
                    <span>实验负责人：{{item.realname}}</span>
                    <span>专业：{{item.subjectName}}</span>
                </div>
                <div class="item-right">
                    <span v-if='!item.no_exp'>已学习：{{ item.studyCount }}次</span>
                    <span v-if='item.no_exp'>实验不存在</span>
                    <div  v-if='item.no_exp' class="item-option">
                          <span class="c-pointer" @click='handleWithdrawal(item)'>退选</span>
                    </div>
                   
                    
                    <div class="item-option" v-if='!item.no_exp'>
                        <span class="c-pointer" @click='handleClick(item)'>进入</span>
                         <span>|</span> 
                         <span class="c-pointer" @click='handleWithdrawal(item)'>退选</span> 
                        <span v-if="item.verifystate === '2' || item.verifystate =='1'">|</span>
                        <el-dropdown :hide-on-click="true">
                        <!-- <el-dropdown :hide-on-click="true" @command="handleCommand(item)"> -->
                            <span class="c-pointer" v-if="item.verifystate === '2' || item.verifystate =='1'">更多</span>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item v-for="oItem in options" @click="handleCommand(item,oItem.type)" :command="{type: oItem.type, item: item}" :key="oItem.key">
                                        {{oItem.label}}
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>
                </div>
            </div>

            <Empty v-if="!dataList.length" />
            <div class="exp-pagin" v-else>
                <Pagination :hideOnSinglePage='false' :total='paging.total || 0' :pageSize='paging.pageSize || 10' 
                        :currentPage='paging.currentPage || 1' @pageChange='pageChange' />
            </div>
        </div>
    </div>
</template>

<script>
import Buttons from '@/components/course/Buttons.vue';
import Pagination from '@/components/course/Pagination.vue';
import axios from "@/utils/axios";
import server from "@/service/server";
import common from "@/utils/common";
import checker from '@/utils/checker';
import Empty from '@/components/course/Empty.vue';
import { ElMessage } from 'element-plus'

export default {
    data (){
        return {
            paging : {
                total : 0,
                pageSize : 5,
                currentPage : 1,
            },
            searchData : {
                title: ''
            },
            options : [
                {key : '001',label : '笔记', type: "实验笔记"},
                {key : '002',label : '提问', type: '实验提问'},
                {key : '003',label : '记录', type: '实验记录'},
                {key : '004',label : '评价', type: '实验评价'},
            ],
            dataList : [],
        }
    },
    mounted (){
        this.queryExpData();
        this.queryExpDataCount();
    },
    methods : {
        pageChange (currentPage){
            this.paging.currentPage = currentPage;
            this.queryExpData();
            this.queryExpDataCount();
        },
        handleExp (data){
            if(data.expautoid){
                common.removeParams('/exps');
                common.setStorParams({path:"/expcore"}, {autoid: data.expautoid});
                this.$router.push("/expcore");
                return ;
            }
            return this.$message({message: '当前实验已丢失，请联系管理员！',type: 'warning'})
        },
        handleClick (data){
            common.removeParams('/exps');
            common.setStorParams(this, {autoid: data.expautoid}, "/exps");
            this.$router.push("/expcore");
        },
        handleCommand(data,optype) {
            // if(data.verifystate =='1') return ElMessage.warning("申请中实验无法查看详情");
            if(data.no_exp) return ElMessage.warning("无法查看，该实验已被下架/删除");
            let expid = "",
                type = optype,
                titlee = '';
            if(!data.item){
                expid = data.expautoid; 
                type  = type;
                titlee = data.title;
            }else{
                expid = data.item.expautoid;
                type  = type;
                titlee = data.title;
            }
            common.setStorParams(this, {expautoid: expid, myexpType: type,title:titlee});
            // common.setStorParams(this, {expautoid: data.item.expautoid, myexpType: data.type});
            this.$router.push("/my_exp");
        },
        hancleOptionClick() {
            console.log("click")
        },
        handleWithdrawal (data){
            this.$confirm('退选当前课程？', { 
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.deleteExpapply({delete_data:true,autoid : data.key});
            }).catch(() => {});
        },
        deleteExpapply(result){
            axios.post(server.expapply,result).then(result=>{
                if(result.status){
                    this.queryExpData();
                }
            })
        },
        queryExpData (){
            let dataList = [], 
                request = {
                    page: this.paging.currentPage,
                    per_page: this.paging.pageSize,
                };
            if(this.searchData.title != ''){
                request['exp_reg'] = '';
                request['title'] = this.searchData.title;
            }
            axios.get(server.expapply + checker.objTurnParams(request)).then(result=>{
                if(result.status && result.data && result.data.result && result.data.result.length){
                    let array = result.data.result;
                    array.forEach((apply_item)=>{
                        let item = {
                            "key": apply_item.autoid,
                            "imgUrl": require('@/assets/images/default_exp.jpg'),
                            "title": '暂无',
                            "realname": '暂无',
                            "username": '暂无',
                            "subjectName": '暂无',
                            "studyCount": 0,
                            "no_exp": true,
                            "verifystate": String(apply_item.verifystate),
                        },exp_item = apply_item.exp_data || {}, num_item = apply_item;
                        item.expautoid = apply_item.expautoid;
                        item.studyCount = num_item.recordsize || 0;
                        if(exp_item && Object.keys(exp_item).length != 0) {
                            item["no_exp"] = false;
                            exp_item.face.split(',').forEach(url=>{
                                let ext = url.split(".").pop();
                                if(ext && ["jpg", "png", "jpeg"].indexOf(ext) != -1) {
                                    item.imgUrl = url || require('@/assets/images/default_exp.jpg');
                                }
                            });
                            item.title = exp_item.title || "暂无";
                            if(exp_item.master_data){
                                item.realname = exp_item.master_data.realname;
                                item.username = exp_item.master_data.username;
                            }
                            if(exp_item.subject_data){
                                item.subjectName = exp_item.subject_data.name;
                            }
                        }
                        dataList.push(item);
                    });
                }
                this.dataList = dataList;
            })
        },
        queryExpDataCount(){
            let request = {
                count: true
            }
            if(this.searchData.title != ''){
                request['exp_reg'] = '';
                request['title'] = this.searchData.title;
            }
            axios.get(server.expapply + checker.objTurnParams(request)).then(result=>{
                if(result.status && result.data){
                    this.paging.total = parseInt(result.data);
                }
            })
        },
        handleClear(){
            this.searchData.title = '';
             this.queryExpData();

        },
        handleSearch(){
            if(this.searchData.title == ''){
                this.$message({
                    message: '实验名称不能为空',
                    type: 'warning'
                })
                this.queryExpData();
                this.queryExpDataCount();
            }else{
                this.queryExpData();
                this.queryExpDataCount();
            }
        },
    },
    components : {
        Buttons,
        Pagination,
        Empty,
    },
}
</script>

<style scoped lang="less">
    .my-exp{
        width:100%;
        height:100%;
        .my-title{
            width:100%;
            height:53px;
            display: flex;
            align-items: center;
            justify-items: center;
            flex:0 0 53px;
            border-bottom: 1px solid #F5F5F5;
            >span{
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #1A1A1A;
            }
            .my-search{
                display: flex;
                align-items: center;
                margin-left:auto;
                .my-search-input{
                    margin-right:10px;
                }
            }
        }
        .exp-main{
            .exp-item{
                display: flex;
                padding: 20px 10px;
                border-bottom: 1px solid #F5F5F5;
                &:last-child{
                    border-bottom: none;
                }
                >img{
                    width:200px;
                    height:112px;
                    margin-right:20px;
                    flex:0 0 200px;
                    border-radius: 4px;
                    cursor: pointer;
                }
                .item-center{
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    flex:1 1 auto;
                    >span{
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #999999;
                        flex:0 0 auto;
                        margin-top: 10px;
                    }
                    
                    >span:nth-child(1){
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                        // flex:1 1 auto;
                        margin-top: 0;
                        word-break: break-all;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                        width: 320px;
                    }
                }
                
                .item-right{
                    display: flex;
                    flex-direction: column;
                    >span{
                        width: 105px;
                        height: 30px;
                        background: #F2F2F2;
                        border-radius: 4px;
                        text-align: center;
                        line-height: 30px;
                        display: inline-block;
                        flex:0 0 auto;
                        margin-left: auto;
                        margin-bottom: auto;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #1A1A1A;
                    }
                    .item-option{
                        display: flex;
                        align-items: center;
                        margin-top: auto;
                        text-align: right;
                        .c-pointer {
                            cursor: pointer;
                        }
                        >span{
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #666666;
                        }
                        >span:nth-child(2n){
                            display: inline-block;
                            padding:0 10px;
                        }

                    }
                }
            }

            .exp-pagin{
                padding:20px 0;
                text-align:center;
            }
        }
    }
</style>