<template>
  <div :class="['look-ui', isFull ? fullClass : '']" ref="wangeditorLook" id='wangeditor-look'>
      <div class="" ref="wangeditorBar" style="display:none"></div>
  </div>
</template>

<script>
export default {
    name : 'look',
    data (){
        return {
            editor : null,
            fullClass: "full"
        }
    },
    props : {
        placeholder : {
            type : String,
            default : '请输入正文',
        },
        menus : {
            type : Array,
            default : ()=>{return [
                'bold',
                'italic',
                'underline',
                'indent',
                'image',
                'link',
            ]}
        },
        defaultHtmlContent: {
            type: String,
            default: ""
        },
        isFull: {
            type: Boolean,//是否全屏显示编辑区域
            default: false
        }
    },
    mounted (){
        const wangEditor = this.wangEditor;
        const editor = new wangEditor(this.$refs.wangeditorBar,this.$refs.wangeditorLook);
        this.editor = editor;
        this.editorInit();
    },
    methods : {
        editorInit (){
            const {editor} = this;
            editor.config.menus = this.menus;
            editor.config.placeholder = this.placeholder;
            editor.create();
            editor.disable();
            this.clreateIdx ++ ;
            if(this.defaultHtmlContent) {
                this.setHTML(this.defaultHtmlContent)
            }
        },
        destroyEditor (){
            this.editor.destroy();
            this.editor = null;
        },
        getJSON (){//获取JSON数据
            const {editor} = this;
            return editor.txt.getJSON();
        },
        setJSON (data){//设置JSON数据
            const {editor} = this;
            editor.txt.setJSON(data);
        },
        setHTML (data){//设置JSON数据
            const {editor} = this;
            editor.txt.html(data);
        },
    },
}
</script>

<style scoped lang='less'>
    .look-ui{
        overflow: auto;
        text-align: left;
    }
</style>