<template>
    <div class="video-ui">
        <!-- <video width="100%" height="100%" controls="controls" autoplay="autoplay"> -->
        <video
            autoplay="true"
            width="100%"
            height="100%"
            @play="playStart"
        >
            <source :src="url" type="video/ogg" />
            <source :src="url" type="video/mp4" />
            <source :src="url" type="video/webm" />
            <object :data="url" width="100%" height="100%">
                <embed width="100%" height="100%" :src="url" />
            </object>
        </video>
    </div>
</template>

<script>
export default {
    data() {
        return {
            url: "",
        };
    },
    watch: {
        videoUrl: "monitorVideoUrl",
    },
    mounted() {
        this.url = this.videoUrl;
    },
    methods: {
        monitorVideoUrl(videoUrl) {
            this.url = videoUrl;
        },
        // 视频播放触发
        playStart() {
            console.log("hahhhh");
            this.$emit("startVi", "true");
        },
    },
    props: {
        videoUrl: {
            type: String,
            default: "",
        },
    },
};
</script>
<style scoped lang="less">
.video-ui {
    width: 100%;
    height: 100%;
    position: relative;
    > video {
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }
}

/* 进度条 */
video::-webkit-media-controls-timeline {
    display: none;
}
video::-webkit-media-controls-toggle-closed-captions-button {
    display: none;
}
</style>
