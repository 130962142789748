//首页布局
import layout_20230209_2847 from '@/components/layouts/layout_20230209_2847.vue';
//轮播图
import Carousel from './carousel.vue';
//首页
import Heads from './heads.vue';
//新闻中心
import News from './news.vue';
//解决方案
import Solution from './solution.vue';


//职业教育
import Education from './education.vue';
//核心技术
import Ctechnic from './ctechnic.vue';
//产品中心
import Goods from './goods.vue';


//合作院校
import School from './school.vue'
//关于我们
import Aboutus from './aboutus.vue';
//海报数据
import post_card from './postcard.vue';
//合作流程
import Sprocess from './sprocess.vue';
//底部导航栏
import Footers from './footers.vue';

const home = {
    News,
    Solution,
    Education,
    Ctechnic,
    Goods,
    School,
    Aboutus,
    post_card,
    Sprocess,
}
export default {
    layout_20230209_2847,
    Carousel,
    Heads,
    Footers,
   // GWNoticeText

}

export {
    home
}