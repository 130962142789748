<template>
  <!-- 实验记录 -->
  <div class="record-ui">
    <div class="record-modules">
      <PageItems>
        <template #title>
          <div class="module-title">
            学情数据
            <span class="module-english">Academic data</span>
          </div>
        </template>
        <template #content>
          <div class="module-details">
            <div class="details-item" v-for="item in adata" :key="item.key">
              <span>{{item.label}}</span>
              <span>{{item.value}}</span>
            </div>
          </div>
        </template>
      </PageItems>
      <PageItems>
        <template #title>
          <div class="module-title">
            综合成绩评定
            <span class="module-english">Performance evaluation</span>
          </div>
        </template>
        <template #content>
            <div class="module-echars"> 
              <Echars v-if="flag" :option="options" :data="pirData"></Echars>
            </div>
        </template>
      </PageItems>
    </div>
    <div class="record-main">
      <Table :columnData='columnData' :tableData='tableData' :params='tableParams' :selectedKeys='selectedKeys' :isPage='true'  v-show="viewState.type === '主页'">
        <template #head>
            <div class="record-form">
                <span><el-input size='small' v-model="searchData.realname" placeholder="请输入姓名" /></span>
                <span><el-date-picker size='small' @change='handlePicker("two",$event)' range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" v-model="searchData.datetwotime" type="daterange" > </el-date-picker></span>
                <span>
                  <Buttons type="info" @click="handleReset">重置</Buttons>
                  <Buttons type="blue" @click="handleInquire">查询</Buttons>
                </span>
            </div>
        </template>
      </Table>
     <div v-if="viewState.type === '详情'" class="expdata-details">
            <recordDetail @toBack="viewState.type = '主页'" :recordAutoid="recordAutoid" :expautoid ='expautoid' :recordData='viewState.data'></recordDetail>
     </div>
    </div>
  </div>
</template>

<script>
import PageItems from '@/components/course/PageItems.vue';
import Echars from '@/components/common/echars/index.vue';
import Table from '@/components/course/Table.vue';
import Buttons from '@/components/course/Buttons.vue';
import checker from '@/utils/checker';
import axios from '@/utils/axios';
import server from '@/service/server';
import common from '@/utils/common';
import recordDetail from './recordDetail.vue';
export default {
  data (){
    return {
      flag: false,
      expautoid: 0,
      recordAutoid:0,
      dataval:{},
      userInfo : {},//存储用户当前信息
      userRole : '',//用户当前角色信息
      viewState: {
          type: '主页',
      },
      adata : [
        {key : '001',label:'实验人次',value : '0', params:"exp_times"},
        {key : '002',label:'实验浏览量',value : '0', params:"commview_exp_count"},
        {key : '003',label:'实验人数',value : '0', params:"exp_user_nums"},
        {key : '004',label:'实验操作平均用时(min)',value : '0', params:"exp_life_average"},
        {key : '005',label:'实验操作完成率',value : '0', params:"exp_operator_finish_rate"},
        {key : '006',label:'实验操作及格率',value : '0', params:"exp_operator_pass_rate"},
        // {key : '007',label:'实验报告提交数',value : '0', params:"exp_report_commit_nums"},
        {key : '008',label:'实验报告提交率',value : '0', params:"exp_report_commit_rate"},
        {key : '008',label:'实验报告及格率',value : '0', params:"exp_report_pass_rate"},
      ],
      options : {
        name : '综合成绩评定',
        size : '100px',
        legendTop : '80%',
        colors : ['#EF6C00', '#00796B', '#C0CA33','#F9A825'],
        seriesCenter : ['50%', '38%'],
        formatter : (data)=>{
          return `${data.value} 人`;
        },
      },
      columnData : [
        {key : 'realname',label : '姓名',style:{width:80},align:'center'},
        {key : 'username',label : '账号',align:'center'},
        {key : 'iclass',label : '班级',align:'center'},
        {key : 'createtime',label : '记录生成时间',style:{width:160},align:'center'},
        {key : 'duration',label : '实验时长(min)',style:{width:120},align:'center'},
        {key : 'param1',label : '操作得分',style:{width:80},align:'center'},
        {key : 'param2',label : '报告得分',style:{width:80},align:'center'},
        {key : 'param3',label : '综合得分',style:{width:80},align:'center'},
        {key : 'action',style:{width:80},label : '实验报告',options : ()=>{
            return [
              // {key : '2',label : '详情',on_click:this.handleDetail},
               {key : '2',label : '详情',on_click:this.handleRecord},
            ];
        }},
      ],
      tableData : [],
      tableParams : {
        pageChange: this.pageChange,
        total: 0,
        pageSize: 15,
        currentPage: 1
      },
      searchData : {
        // realname: '',
        // datetwotime: '',
      },
      defaultValue : [],
      selectedKeys : [],
      pirData : [
        { value: 0, name: '优秀' ,params: 'final_score_outstand', labelLine: {show: true},label:{show:true}},
        { value: 0, name: '良好' ,params: 'final_score_good', labelLine: {show: true},label:{show:true}},
        { value: 0, name: '中等' ,params: 'final_score_middle', labelLine: {show: true},label:{show:true}},
        { value: 0, name: '不达标' ,params: 'final_score_low', labelLine: {show: true},label:{show:true}},
      ],
    }
  },
  beforeMount() {
    // let storeParams = common.getStorParams({ path: "/expcore" });
    // if (!storeParams) storeParams = common.getStorParams(this);
    this.expautoid = this.autoid;
    let userinfo = this.$store.state.user.userInfo;
    this.monitorUserInfo(userinfo);
  },
  watch: {
      '$store.state.user.userInfo' : 'monitorUserInfo',
  },
  mounted (){
    // this.searchData.datetwotime = [checker.timestampToDate(new Date().getTime()),checker.timestampToDate(new Date().getTime())];
    this.getLearningData();//学情数据
    this.getComprehensiveScore();//综合成绩评定
    this.getTableData();//实验记录
    // this.getTableDataCount();//实验记录总数
  },
  methods : {
    monitorUserInfo (userInfo){//监控登录状态
        if(!userInfo.autoid)return;
        this.userInfo = userInfo;
        this.userRole = userInfo.role;
    },
    handleDetail (data){
      const routeData = this.$router.resolve({
            path: '/report',
            query:{
                      // name: '实验报告', 
                  // pid :data.datafile_data.autoid,
                pid: data.autoid,
                datafiletype : data.datafiletype,
            }
        });
    
      window.open(routeData.href,'_blank');
    },
     timeChange(data){
         let time = data;
        //  let h = parseInt(time / 60 / 60 % 24)
        //      h = h < 10 ? '0' + h : h
             let m = parseInt(time / 60 % 60)
             m = m < 10 ? '0' + m : m
             let s = parseInt(time % 60)
             s = s < 10 ? '0' + s : s
            // 作为返回值返回
           let finnal = m+'.'+s
          //  if(data=='0'||''){
          //    finnal= 0
          //  }
            return finnal
    },
    handleRecord(data){
      this.viewState.data = data;
      let dataa = {
        expautoid:data.expautoid,
        recordid:data.key
      }
     if(!!data.key){
       this.recordAutoid = data.key;
     }
    //  if(!!data.expautoid){
    //   this.expautoid = data.ex
    //  }
     
      this.viewState.type = '详情';
    },
    
    handlePicker (){},
    getLearningData(){
      let request = {
        expautoid: this.expautoid,
        get_data_sumary: ''
      }
      axios.get(server.dataanalysis + checker.objTurnParams(request)).then(result=>{
        if(result.status){
          let Adata = result.data || {},adata = this.adata;
          adata.map(item=>{
            if(item.params == 'exp_life_average'){item.value = (parseFloat(Adata['exp_life_average']) / 60).toFixed(2);}
            else{item.value = Adata[item.params];}
          })
          // this.Adata = [...Adata];
        }
      })
    },
    getComprehensiveScore(){
      let request = {
        expautoid: this.expautoid,
        get_gradelevel: ''
      }
      axios.get(server.dataanalysis + checker.objTurnParams(request)).then(result=>{
        if(result.status){
          let PirData = result.data || {},pirData = this.pirData;
          pirData.map(item=>{
            item.value = parseInt(PirData[item.params])
          })
          this.flag = true;
        }
      })
    },
    getTableDataCount(){
      let request = {
        expautoid: parseInt(this.expautoid),
        count: true,
        exp_center:true,

      };
      // switch(this.userRole){
      //     case '1' || '3':
      //         request['get_all'] = true;
      //         break;
      //     case '4':
      //         break;
      // }
      if(this.searchData.realname){
        // if(this.userRole == '1' || this.userRole == '3'){
            request['realname'] = this.searchData.realname;
            request['get_user'] = true;
        //     delete request['get_all']
        // }
      }
      if(this.searchData.datetwotime){
        request['start_date'] = checker.timestampToDate(new Date(this.searchData.datetwotime[0]).getTime(),'YYYY-MM-DD');
        request['end_date'] = checker.timestampToDate(new Date(this.searchData.datetwotime[1]).getTime(),'YYYY-MM-DD');
      }
      axios.get(server.uiexprecord + checker.objTurnParams(request)).then(result=>{
        if(result && result.status && result.data){
          this.tableParams.total = Number(result.data);
        }
      })
    },
    pageChange(currentPage){
      this.tableParams.currentPage = currentPage;
      this.getTableData();
      this.getTableDataCount();
    },
    getTableData(type){
      let request = {
        expautoid: parseInt(this.expautoid),
        page: this.tableParams.currentPage,
        per_page: this.tableParams.pageSize,
        exp_center:true,
        _order_column:"createtime",
        _order_style:"desc"
      },tableData = [];
      switch(this.userRole){
          case '1':
              request['get_all'] = true;
              break;
          case '3' : 
              request['get_all'] = true;
            break;
          case '4':
              break;
      }
      if(this.searchData.realname){
        // if(this.userRole == '1' || this.userRole == '3'){
            request['realname'] = this.searchData.realname;
            request['get_user'] = true; 
        // }
      }
      if(this.searchData.datetwotime){
        request['start_date'] = checker.timestampToDate(new Date(this.searchData.datetwotime[0]).getTime(),'YYYY-MM-DD');
        request['end_date'] = checker.timestampToDate(new Date(this.searchData.datetwotime[1]).getTime(),'YYYY-MM-DD');
      }
      if(type =='delete_getall'){
       
        delete request.get_all;
      }
      axios.get(server.uiexprecord + checker.objTurnParams(request)).then(result=>{
        if(result && result.status && result.data && result.data.result){
          result.data.result.map(item=>{
            let user_iclass_data = item.user_iclass_data || {},datafile_data = item.datafile_data || {},
            duration = (item.life / 60).toFixed(2);
            item.realname = item.user_realname || '';
            item.username = item.user_username || '';
            item.iclass = user_iclass_data.name || '';
            item.createtime = item.createtime || '';
            item.duration = duration || '0.00';
            item.param1 = item.score || '0';
            item.param2 = datafile_data.report_score || '0';
            item.param3 =item.finalscore|| '0';
            item.key = item.autoid;
            // item.param3 = parseInt(item.finalscore).toFixed(1) || '0';
            tableData.push(item)
          })
          this.tableData = tableData;
          this.getTableDataCount();
        }
      })
    },
    handleReset(){
      this.searchData.realname = '';
      this.searchData.datetwotime = '';
      this.getTableData();
      this.getTableDataCount();
      this.$message({
        message: '重置成功',
        type: 'success'
      })
    },
    handleInquire(){
      if(this.searchData.realname == '' && (this.searchData.datetwotime == null || this.searchData.datetwotime == '')){
        this.$message({
          message: '请填写正确的查询信息',
          type: 'warning'
        })
   
        this.getTableData();
        // this.getTableDataCount();
      }else{
        this.getTableData('delete_getall');
        // this.getTableDataCount();
      }
    },
  },
  components : {
    PageItems,
    Echars,
    Table,
    Buttons,
    recordDetail
  },
  props: {
    autoid: {
      type: [Number, String],
      default: "",
    },
  },
}
</script>

<style scoped lang="less">
  ::v-deep  .el-date-editor.el-range-editor {
    width:250px;
  }
  .record-ui{
    display: flex;
    background-color: #F2F2F2;
    .record-modules{
      width:266px;
      flex:0 0 auto;
      margin-right:10px;
      margin-top:10px;
      background-color: #FFFFFF;
      border-radius: 4px;
      .module-title{
        display: flex;
        flex-direction: column;
        font-weight: bold;
        color: #1A1A1A;
        font-size: 18px;
        font-family: Microsoft YaHei;
        text-align: left;
        .module-english{
          font-weight: 400;
          color: #B6B6B6;
          font-size: 14px;
          font-family: Microsoft YaHei;
          display: flex;
          flex-direction: column;
          
          margin-top:5px;
          &::after{
            content: '';
            display: inline-block;
            width:72px;
            height:3px;
            background-color: #1E5FC2;
            margin-top:5px;
          }
        }
      }
      .module-details{
        position: relative;
        padding: 10px 20px;
        margin-top: 10px;
        &::before{
          position: absolute;
          top: 0;
          left: 20px;
          right: 20px;
          height: 1px;
          background-color: #C9C9C9;
          content: '';
        }
        .details-item{
          display: flex;
          align-items: center;
          margin-bottom:15px;
          >span:nth-child(1){
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            margin-right:auto;
          }
          >span:nth-child(2){
            width: 70px;
            height: 23px;
            line-height: 23px;
            text-align: center;
            border: 1px solid #6FA8FF;
            border-radius: 4px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            margin-left:auto;
          }
        }
      }

      .module-echars{
        width:100%;
        height:300px;
      }
    }
    .record-main{
      margin-top:10px;
      flex:1 1 auto;
      background-color: #FFFFFF;
      padding:0 10px;
      .record-form{
        display: flex;
        padding-top:10px;
        align-items: center;
        >span:nth-child(1){
          margin-right:10px;
        }
        >span:nth-child(2){
          margin-right:10px;
        }
      }
    }
  }
</style>