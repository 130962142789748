<template>
  <!-- 实验资料 -->
  <div class="expdata-ui">
    <div class="expdata-modules">
      <PageItems>
        <template #title>
          <div class="module-title">
            学情数据
            <span class="module-english">Academic data</span>
          </div>
        </template>
        <template #content>
          <div class="module-details">
            <div class="details-item" v-for="item in adata" :key="item.key">
              <span>{{item.label}}</span>
              <span>{{item.value}}</span>
            </div>
          </div>
        </template>
      </PageItems>
    </div>
    <div class="expdata-main">
      <TabsNew :active="active" :menuData="menuData" @toClick="changeTab" v-show="viewState.type === '主页'">
        <template v-if="resourceFlag">
          <div v-if="resourceData.resource.length">
            <div class="expdata-box">
              <div class="expdata-list" v-for="item in resourceData.resource" :key="item.key">
                <div class="expdata-item">
                  <div class="expdata-title">
                    <span class="read">{{item.resource_data.resourcetype_langname || '资料'}}</span>
                    <span>{{item.title}}</span>
                  </div>
                  <div style="display:flex;"> 
                    <div class="expdata-icon" @click="downloadResause(item)"><el-icon><download /></el-icon>{{item.download_size}}</div>
                    <div class="expdata-icon" @click="handleCollect('收藏',item)">
                      <i class="expdata-pitch" >
                        <i style="color:#FFC12E " v-if="item.collect" class="xxl-collected"></i>
                        <i style="color:#BBBBBB" v-else class="xxl-collect"></i>
                      </i>
                      {{item.collect_size}}
                    </div>
                    <Buttons type="blue" plain @click='handleClick(item)' :disabled="monitorLength(item.resource_data)">进入学习</Buttons>
                  </div>
                </div>
              </div>
            </div>
            <div class="exp-page">
              <Pagination
                :total="paging.total || 0"
                :pageSize="paging.pageSize || 5"
                :currentPage="paging.currentPage || 1"
                @pageChange="pageChange"
              />
            </div>
          </div>
          <div class='no-data' v-else>
            <el-empty :image-size="242">
                <template #image>
                    <span class='no-data-img'></span>
                </template>
            </el-empty>
          </div>
        </template>
        <template v-if="noteFlag">
          <div v-if="resourceData.note.length">
            <div class="expdata-box">
              <div class="note-list" v-for="item in resourceData.note" :key="item.key">
                <div class="note-avatar">
                  <img v-if="item.face" :src="item.face" alt="">
                  <img v-if="!item.face" src="@/assets/images/user_logo.png" alt="">
                </div>
                <div class="note-body">
                  <div class="note-user">
                    <span>{{item.realname}} · 发布于{{item.createtime}}</span>
                    <a :href="item.videoUrl" @click="handleClick(item)">{{item.resource_name}}</a>
                       <!-- <a :href="item.videoUrl">实验申报视频</a> -->
                  </div>
                  <div class="note-evaluate">
                    <span>笔记评分:</span>
                    <div>
                      <span v-if="item.notelevelid == '1'" class="excellent"><span>优秀</span></span>
                      <span v-if="item.notelevelid == '2'" class="good"><span>良好</span></span>
                      <span v-if="item.notelevelid == '3'" class="qualified"><span>合格</span></span>
                      <span @click="handleLike('点赞',item)">
                        <i style="color:red" v-if="item.like" class="xxl-liked"></i>
                        <i style="color:#BBBBBB" v-else class="xxl-like"></i>
                        <span>{{item.like_size}}</span>
                      </span>
                      <span @click="handleLike('收藏',item)">
                        <i style="color:#FFC12E " v-if="item.collect" class="xxl-collected"></i>
                        <i style="color:#BBBBBB" v-else class="xxl-collect"></i>
                        <span>{{item.collect_size}}</span>
                      </span>
                    </div>
                  </div>
                  <div class="note-content" title="点击前往详情页" v-html="item.content">
                  </div>
                  <div class="note-content1" @click="handleDetails(item)">
                    <span>点击加载更多</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="expnote-page">
              <Pagination
                :total="paging2.total || 0"
                :pageSize="paging2.pageSize || 5"
                :currentPage="paging2.currentPage || 1"
                @pageChange="pageChange2"
              />
            </div>
          </div>
          <div class='no-data' v-else>
            <el-empty :image-size="242">
                <template #image>
                    <span class='no-data-img'></span>
                </template>
            </el-empty>
          </div>
        </template>
      </TabsNew>
      <div v-show="viewState.type === '详情'" class="expdata-details">
          <expDataDetails @toBack="viewState.type = '主页'" :expautoid="expautoid" :data="viewState.data"></expDataDetails>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/course/Pagination.vue";
import PageItems from '@/components/course/PageItems.vue';
import TabsNew from '@/components/business/Tabs/index.vue';
import Buttons from '@/components/course/Buttons.vue';
import common from '@/utils/common';
import axios from '@/utils/axios';
import server from '@/service/server';
import checker from '@/utils/checker';
import expDataDetails from './expDataDetails.vue';
import { ElMessage } from 'element-plus';
export default {
  data (){
    return {
      adata : [
        {key : '001',label:'资源总数',value : '0',params: 'expdatums_count'},
        {key : '002',label:'学习人次',value : '0',params: 'study_times'},
        {key : '003',label:'学习人数',value : '0',params: 'study_nums'},
        {key : '004',label:'平均学习时长(min)',value : '0',params: 'expdatums_life_average'},
        {key : '005',label:'资源下载次数',value : '0',params: 'commdownload_uiexpdatum_count'},
        {key : '006',label:'资源收藏数',value : '0',params: 'expdatums_collect_nums'},
        {key : '007',label:'笔记数',value : '0',params: 'expnote_count'},
        {key : '008',label:'优秀笔记数',value : '0',params: 'nice_expnote_count'},
      ],
      active : '',
      menuData : [
        {key : '001',noteParam: {},title : '资源列表',type : '资源列表',value : '0', children: []},
        {key : '002',noteParam: {},title : '全部学习笔记',type : '全部学习笔记',value : '0', children: []},
        {key : '003',noteParam: {notelevelid : '1'},title : '优秀笔记',type : '优秀笔记',value : '0', children: []},
        {key : '004',noteParam: {notelevelid : '2'},title : '良好笔记',type : '达标笔记',value : '0', children: []},
        {key : '005',noteParam: {notelevelid : '3'},title : '合格笔记',type : '不达标笔记',value : '0', children: []},
      ],
      resourceData: {
        resource: [],
        note: []
      },
      resourceFlag : true,
      noteFlag : false,
      noteParam : {},
      paging: {
        total: 0,
        pageSize: 5,
        currentPage: 1,
      },
      paging2: {
        total: 0,
        pageSize: 5,
        currentPage: 1,
      },
      viewState: {
          type: '主页',
      },
      expautoid: '',
      userRole:''
    }
  },
  beforeMount() {
    this.expautoid = this.autoid;
  },
  mounted(){

  },
  created(){
     this.judgeUser();
  },
  mounted(){
    this.getAdata();
    this.getCount();
  },
  methods : {
    monitorLength(data){
      if(Object.keys(data).length == 0){
        return true
      }else{
        return false
      }
    },
    judgeUser(){
      let request = {autoids: window.localStorage.getItem("userAutoid"), get_users_info: true}
      axios.get(server.userinfo + checker.objTurnParams(request)).then(result=>{
        if(result.status && result.data){
          this.userRole = result.data.result[0].role;
        }
      })
    },
    downloadResause(item){
      if(item.resource_data && Object.keys(item.resource_data).length != 0){
        let path = item.resource_data.filepath;
        window.open(path);
        let request = {
          expautoid: Number(item.expautoid) ,
          targetautoid: Number(item.autoid) ,
          targettable: 'uiexpdatums'
        }
        axios.post(server.commdownload ,request).then(result=>{
          if(result.status && result.data){
            this.getResource();
            this.getAdata();
            this.getCount();
          }
        })
      }else{
        ElMessage.warning('该资料不存在，无法下载');
      }
    },
    getAdata(){
      let request = {
        expautoid: this.expautoid,
        get_expdatums_data_sumary: ''
      }
      axios.get(server.dataanalysis + checker.objTurnParams(request)).then(result=>{
        if(result.status && result.data){
          let adata = this.adata,aData = result.data;
          adata.map(item=>{
            if(item.params == 'expdatums_life_average'){item.value = (parseFloat(aData['expdatums_life_average']) / 60).toFixed(2);}
            else{item.value = Number(aData[item.params]) || 0;}
          })
          this.getResource();
        }
      })
    },
    getResource(){
      const {expautoid} = this;
      let resource = [], title,
          request = {
            expautoid: expautoid, 
            // publish: 2, 
            _order_column: 'createtime',
            _order_style: 'desc',
            page: this.paging.currentPage,
            per_page: this.paging.pageSize,
            view: 'desc' 
          };
           // get_all: true
      // 权限判断（student 特殊处理）
        // if( this.userRole ==4){
        //   delete request.get_all;
        // }
      if(expautoid){
        axios.get(server.uiexpdatum + checker.objTurnParams(request)).then(result=>{
          if(result.status && result.data && result.data.result && result.data.result.length){
            let dataList = result.data.result; 
            dataList.map(item=>{
              let resource_data = item.resource_data || {};
              if(resource_data && Object.keys(resource_data).length != 0){
                item.title = resource_data.name;
                if(resource_data.resourcetype_langname == '办公文件'){
                  resource_data.resourcetype_langname = '文档';
                }
              }else if(item.richtext_data && Object.keys(item.richtext_data).length != 0){
                item.title = item.richtext_data.title
              }else{
                item.title = '该资源不存在'
              }
              item.expautoid = expautoid;
              item.key = item.autoid;
              if(item.count_data){
                item.download_size = item.count_data.download || 0;
                item.collect_size = item.count_data.collect || 0;
              }
              // if(item.collect == " "){
              //   item.collect = false;
              // }
             // item.collect_type = item.collect;
              resource.push(item);
            })
            this.resourceData.resource = resource;
            this.getResourceCount();
          }else{
            this.resourceData.resource = []
            this.getResourceCount();
          }
        })
      }
    },
    getResourceCount(){
      const {menuData,expautoid} = this;
      let request = {expautoid: expautoid, count: true},
          index = menuData.findIndex(item=>item.type == '资源列表'),
          resource = menuData[index];
      if(expautoid){
        axios.get(server.uiexpdatum + checker.objTurnParams(request)).then(result=>{
          if(result.status && result.data){
            resource.value = result.data;
            this.paging.total = parseInt(result.data);
          }
        })
      }
    },
    getNoteData(){
      let request = {
        page: this.paging2.currentPage, 
        per_page: this.paging2.pageSize, 
        expautoid: this.expautoid,
        get_pass: '',
        ...this.noteParam,
      }, note = [];
      axios.get(server.expnote + checker.objTurnParams(request)).then(result=>{
        if(result.status && result.data && result.data.result){
          result.data.result.map(item=>{

            item.key = item.autoid;
            item.collect_size = item.collect_size || 0;
            item.like_size = item.like_size || 0;
            item.notelevelid = String(item.notelevelid);
            item.face = item.user_face==''? require('@/assets/images/user_logo.png') : item.user_face
            item.realname = item.user_realname;
         
            if(item.name){
               item.resource_name = item.name || '';
            }
           
            note.push(item);
          })
          this.resourceData.note = note;
        }
      })

    },
    getCount() {
      //全部学习笔记
      this.setCount({ expautoid: this.expautoid, count: true, get_pass: "" }, "002");
      //优秀笔记
      this.setCount({ expautoid: this.expautoid, count: true, get_pass: "", notelevelid: '1' }, "003");
      //达标笔记
      this.setCount({ expautoid: this.expautoid, count: true, get_pass: "", notelevelid: '2' }, "004");
      //不达标笔记
      this.setCount({ expautoid: this.expautoid, count: true, get_pass: "", notelevelid: '3' }, "005");
    },
    changeTab(data){//标签页切换
      if(data.title == '资源列表'){
        this.resourceFlag = true;
        this.noteFlag = false;
        this.getResource();
      }else{
        this.resourceFlag = false;
        this.noteFlag = true;
        window.localStorage.setItem('dataKey',data.key);
        this.noteParam = data.noteParam;
        this.setCount({expautoid: this.expautoid, count: true, get_pass: '', ...this.noteParam}, data.key);
        this.getNoteData();
      }
    },
    setCount(request, menuDataKey) {
      axios
        .get(server.expnote + checker.objTurnParams(request))
        .then((result) => {
          if (result.status && result.data) {
            this.setAdata(menuDataKey, result.data);
          }
        });
    },
    setAdata(key, value) {
      this.menuData.forEach((item) => {
        if (item.key == key) {
          item.value = Number(value) || 0;
          this.paging2.total = Number(value) || 0;
          return true;
        }
      });
    },
    handleClick(data){
      if(!!data.expdatum_data){
        data.resource_data = data.expdatum_data.resource_data;
        data.key = data.targetautoid;
        // data.resource_name = data.expdatum_data.resource_data.name;
      }

      common.setStorParams({path:'/lectures'},data);
      this.$router.push('/lectures');
    },
    pageChange(currentPage) {
      //分页
      this.paging.currentPage = currentPage;
      this.getResource();
    },
    pageChange2(currentPage) {
      //分页
      this.paging2.currentPage = currentPage;
      this.getNoteData();
      this.setCount({expautoid: this.expautoid, count: true, get_pass: '', ...this.noteParam},window.localStorage.getItem('dataKey'))
    },
    handleDetails(data){
      this.viewState = {
          type: '详情',
          data: data,
      };
    },
    handleLike(type,data){
      switch(type){
        case '点赞':
          if(data.like){
            let request = {
              targettable: 'expnotes',
              targetautoid: data.autoid,
              delete_data: true,
              expautoid: this.expautoid,
              like: 1
            }
            this.postCancel(request,data,type);
          }else{
            let request = {
              targettable: 'expnotes',
              targetautoid: data.autoid,
              like: 1,
              expautoid: this.expautoid
            }
            this.postCollectstar(request,data,type);
          }
          break;
        case '收藏':
          if(data.collect){
            let request = {
              targettable: 'expnotes',
              targetautoid: data.autoid,
              delete_data: true,
              expautoid: this.expautoid,
              collect: 1
            }
            this.postCancel(request,data,type);
          }else{
            let request = {
              targettable: 'expnotes',
              targetautoid: data.autoid,
              collect: 1,
              expautoid: this.expautoid
            }
            this.postCollectstar(request,data,type);
          }
          break;
      }
    },
    postCollectstar(request,data,type){
      axios.post(server.collectstar, request).then(result=>{
          if(result.status && result.data && result.data.result && result.data.result.length){
              switch(type){
                case '点赞':
                  this.getLike(data);
                  break;
                case '收藏':
                  this.getCollect(data);
                  break;
              }
          }
      })
    },
    postCancel(request,data,type){
      axios.post(server.collectstar, request).then(result=>{
        if(result.status && result.data){
          switch(type){
            case '点赞':
              this.getLike(data);
              break;
            case '收藏':
              this.getCollect(data);
              break;
          }
        }
      })
    },
    getLike(data){
      const {resourceData} = this;
      let request = {
          targettable: 'expnotes',
          expautoid: this.expautoid,
          targetautoid: data.autoid,
          count: true,
          like: 1
      }
      let noteIdx = resourceData.note.findIndex(item=>item.key == data.autoid);
      axios.get(server.collectstar + checker.objTurnParams(request)).then(result=>{
          if(result.status){
            resourceData.note[noteIdx].like = result.data.like;
            resourceData.note[noteIdx].like_size = Number(result.data.count);
          }
      })
    },
    getCollect(data){
      const {resourceData} = this;
      let request = {
        targettable: 'expnotes',
        expautoid: this.expautoid,
        targetautoid: data.autoid,
        count: true,
        collect: 1
      }
      let noteIdx = resourceData.note.findIndex(item=>item.key == data.autoid);
      axios.get(server.collectstar + checker.objTurnParams(request)).then(result=>{
          if(result.status){
            resourceData.note[noteIdx].collect = result.data.collect;
            resourceData.note[noteIdx].collect_size = Number(result.data.count);
          }
      })
    },
    handleCollect(type,data){
      if(Object.keys(data.resource_data).length){
        switch(type){
          case '收藏':
            if(!!data.collect){
              var request2 = {
                targettable: 'uiexpdatums',
                targetautoid: data.autoid,
                delete_data: true,
                expautoid: this.expautoid,
                collect: 1
              }
              this.CancelColResouse(request2,data,type);
            }else{
                var request1 = {
                targettable: 'uiexpdatums',
                targetautoid: data.autoid,
                collect: 1,
                expautoid: this.expautoid
              }
              this.ColResouse(request1,data,type); 
            }
        }
      }else{
        ElMessage.warning('该资料不存在，收藏失败');
      }
    },
    
  // 取消资源的收藏
    CancelColResouse(request,data,type){
      axios.post(server.collectstar, request).then(result=>{
        if(result.status && result.data){
          switch(type){
            case "收藏":
              this.getResource(data);
              break;
          }
        }
      })  
    },
    ColResouse(request,data,type){
      axios.post(server.collectstar,request).then(result=>{
        if(result.status){
        // if(result.status && result.data && result.data.result && result.data.result.length){
          switch(type){
            case '收藏':
              this.getResource(data);
              break;
          }
        }
      })
    },
  // 收藏资源
  },
  computed:{
  },
  components : {
    PageItems,
    TabsNew,
    Buttons,
    Pagination,
    expDataDetails,
  },
  props: {
      autoid: {
        type: [Number, String],
        default: "",
      },
  },
  
}
</script>

<style scoped lang="less">
  .expdata-ui{
    display: flex;
    background-color: #F2F2F2;
    .expdata-modules{
      width:266px;
      flex:0 0 auto;
      margin-right:10px;
      margin-top:10px;
      background-color: #FFFFFF;
      border-radius: 4px;
      .module-title{
        display: flex;
        flex-direction: column;
        font-weight: bold;
        color: #1A1A1A;
        font-size: 18px;
        font-family: Microsoft YaHei;
        text-align: left;
        .module-english{
          font-weight: 400;
          color: #B6B6B6;
          font-size: 14px;
          font-family: Microsoft YaHei;
          display: flex;
          flex-direction: column;
          
          margin-top:5px;
          &::after{
            content: '';
            display: inline-block;
            width:72px;
            height:3px;
            background-color: #1E5FC2;
            margin-top:5px;
          }
        }
      }
      .module-details{
        position: relative;
        padding: 10px 20px;
        margin-top: 10px;
        &::before{
          position: absolute;
          top: 0;
          left: 20px;
          right: 20px;
          height: 1px;
          background-color: #C9C9C9;
          content: '';
        }
        .details-item{
          display: flex;
          align-items: center;
          margin-bottom:15px;
          >span:nth-child(1){
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            margin-right:auto;
          }
          >span:nth-child(2){
            width: 70px;
            height: 23px;
            line-height: 23px;
            text-align: center;
            border: 1px solid #6FA8FF;
            border-radius: 4px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            margin-left:auto;
          }
        }
      }
    }
    .expdata-main{
      min-height: 550px;
      margin-top:10px;
      flex:1 1 auto;
      background-color: #FFFFFF;
      .expdata-box{
        //height: 750px;
        .expdata-list{
          .expdata-item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding:20px 0;
            border-bottom: 1px solid #F7F7F7;
            .expdata-title{
              display: flex;
              align-items: center;
              justify-content: space-between;
              >span:nth-child(1){
                width:70px;
                height:32px;
                text-align: center;
                line-height: 32px;
                border-radius: 4px;
                color:#FFFFFF;
                font-size: 14px;
                margin-right:20px;
              }
              .read{
                background-color: #2469D5;
              }
            }
            .video{
              background-color: #6CD9DC;
            }
            >span:nth-child(2){
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #797979;
            }
            .expdata-icon{
              display: flex;
              align-items: center;
              font-size: 15px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #BBBBBB;
              margin-right: 40px;
              cursor: pointer;
              >i{
                margin-right: 5px;
              }
            }
            .expdata-icon:nth-child(3){
              margin-left: auto;
            }
            .expdata-icon:nth-child(4){
              >i{
                width: 15px;
                height: 19px;
                display: inline-block;
                background-size: 100% 100%;
                margin-right: 5px;
                background-image: url('~@/assets/images/collect.png');
              }
              >i.expdata-pitch{
                background-image: url('~@/assets/images/collect_yellow.png');
              }
            }
          }
        }
      }
      .exp-page{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
      }
      .expnote-page{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
      }
      .note-list{
        display: flex;
        height: 230px;
        margin: 20px 0;
        width: 100%;
        .note-avatar{
          // width: 25%;
          >img{
            border-radius: 50%;
            width: 50px;
            height: 50px;
          }
        }
        .note-body{
          display: flex;
          flex-direction: column;
          margin-left: 30px;
          width: 90%;
          .note-user{
            display: flex;
            height: 15%;
            align-items: center;
            >span:first-child{
              // width: 185px;
              height: 12px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
            }
            >a{
              text-decoration: underline;
              // width: 85px;
              height: 12px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #2974EA;
              cursor: pointer;
              margin-left: 10px;
            }
          }
          .note-evaluate{
            display: flex;
            align-items: center;
            // width: 100%;
            height: 25%;
            margin-top: 15px;
            >span:first-child{
              width: 63px;
              height: 12px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #1A1A1A;
            }
            >div{
              display: flex;
              align-items: center;
              justify-content: space-around;
              >span{
                margin: 0 10px;
              }
              .excellent{
                width: 50px;
                height: 23px;
                background: linear-gradient(180deg, #FFBE63, #FF5F5F);
                box-shadow: 0px 2px 5px 0px rgba(255, 12, 42, 0.5);
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                >span{
                  font-size: 13px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #FFFFFF;
                  text-align: center;
                }
              }
              .good{
                width: 50px;
                height: 23px;
                background: linear-gradient(180deg, #64D551, #1CAE09);
                box-shadow: 0px 2px 5px 0px rgba(35, 177, 15, 0.5);
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                >span{
                  font-size: 13px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #FFFFFF;
                  text-align: center;
                }
              }
              .qualified{
                width: 50px;
                height: 23px;
                background: linear-gradient(180deg, #B7B7B7, #7E7E7E);
                box-shadow: 0px 2px 5px 0px rgba(172, 172, 172, 0.5);
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                >span{
                  font-size: 13px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #FFFFFF;
                  text-align: center;
                }
              }
            }
          }
          .note-content{
            // height: 60%;
            overflow: hidden;
            white-space: normal;
            // text-overflow: ellipsis;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            line-height: 28px;
           
            word-break: break-all;
            opacity: 0.8;
            // height: 90%;
          }
            .note-content1{
              position: relative;
              // height: 161px;
              background: cornflowerblue;
              text-align: center;
               cursor: pointer;
              >span{
                color: white;
                font-size: 16px;
                line-height: 43px;
              }
            }
        }
      }
      .no-data{
        margin: auto;
        .no-data-img{
          background-image: url('~@/assets/images/Empty.jpg');
          background-size: 1595px 3004px;
          background-position: -1047px -204px;
          width: 242px;
          height: 228px;
          display: inline-block;
        }
      }
    }
  }
</style>