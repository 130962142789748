<template>
    <!-- 实验中心 -->
  <div class="expcore">
    <div class="top_bkg"></div>
    <div class='expcore-box common-width'> 
      <div class='crumbs'>
        <el-breadcrumb separator-class='el-icon-arrow-right'>
          <el-breadcrumb-item to='/exps'>实验中心</el-breadcrumb-item>
          <el-breadcrumb-item><span class='crumbs-item'>{{expdata.subject || "专业大类"}}</span></el-breadcrumb-item>
          <el-breadcrumb-item><span class='crumbs-item'>{{expdata.major || "专业名称"}}</span></el-breadcrumb-item>
          <el-breadcrumb-item><span class='crumbs-item'>{{expdata.title || "实验名称"}}</span></el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <Header :data="expdata"></Header>
      <template v-if="verifystate == '2' && expdata.resourcetype === 'emulate'">
        <Pcemulate :data='expdata'/>
      </template>
      <div class="expcore-main">
        <Tabs :expautoid="autoid" v-model:active='active' :tabsData='tabsData' @tabCahnge='handleClick_exp'>
          <template #default="{tabsItem:item}">
            <Details @toClick="handleClick" :autoid="autoid" :menuData="uitextMenuData" :realname="expdata.master_data ? expdata.master_data.realname || '' : ''" v-if="item.type === '实验详情'"></Details>
            <Record :autoid="autoid" v-if="item.type === '实验记录'"></Record>
            <Evaluate :autoid="autoid" v-if="item.type === '实验评价'"></Evaluate>
            <Problem :autoid="autoid" v-if="item.type === '提问答疑'"></Problem>
            <Expdata :autoid="autoid" :exp_active="active" v-if="item.type === '实验资料'"></Expdata>
            <Analysis :autoid="autoid" v-if="item.type === '数据分析'"></Analysis>
          </template>
        </Tabs>
      </div>
    </div>
    
  </div>
</template>
<script>
import Header from './header.vue';
import Tabs from '@/components/course/Tabs.vue';
import Details from './details.vue';
import Record from './record.vue';
import Pcemulate from './pcemulate';
import Evaluate from './evaluate.vue';
import Problem from './problem.vue';
import Expdata from './expdata.vue';
import Analysis from './analysis.vue';
import axios from '@/utils/axios';
import server from '@/service/server';
import checker from '@/utils/checker';
import common from '@/utils/common';
const TABS_ROW_DATA = [
    {key : '001',name : '1',publish: '2',label : '实验详情',type: '实验详情', slot: "", show: true},
    {key : '002',name : '2',publish: '2',label : '实验记录',type: '实验记录', slot: "uiexprecords", show: false},
    {key : '003',name : '3',publish: '2',label : '实验评价',type: '实验评价', slot: "uistars", show: false},
    {key : '004',name : '4',publish: '2',label : '提问答疑',type: '提问答疑', slot: "uiissues", show:false},
    {key : '005',name : '5',publish: '2',label : '实验资料',type: '实验资料', slot: "uiexpdatums", show: false},
    {key : '006',name : '6',publish: '2',label : '数据分析',type: '数据分析', slot: "",show: true},
]
export default {
  data (){
    return {
      autoid : '',
      resauseIndex:0,
      active : '1',
      tabsData: [],
      uitextMenuData: [],
      expdata : {},
      verifystate : false,
      userinfo : {},
    }
  },
  components : {
    Header,
    Tabs,
    Details,
    Record,
    Evaluate,
    Problem,
    Expdata,
    Analysis,
    Pcemulate,
  },
  watch: {
    '$route.query.active': 'typeMonitor',
    '$store.getters.userInfo' : 'monitorUserInfo',
  },

  mounted (){
    let storeParams = common.getStorParams(this);
    if(!storeParams) {
      
      storeParams = common.getStorParams({path:'/expcore'});
      
      if(storeParams.exp_data){
        if(Object.keys(storeParams.exp_data).length == 0){
          this.$confirm('该实验已下架或被删除，点击确定返回首页', '消息提醒', { 
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$router.push("/");
          }).catch(() => {
            this.$router.push("/");
          });
        }
      }
    }
    this.autoid = storeParams.autoid;
    this.monitorUserInfo();
    this.getExpDetails();
    this.getUiData();
    
    this.$nextTick(()=>{
      this.active  = storeParams.active || '1';
      this.getExpapply();
    })
  },
  methods : {
    monitorUserInfo (data=this.$store.getters.userInfo){
      this.userinfo = data;
    },
    typeMonitor(data = this.data){
      this.$nextTick(()=>{
        this.active = data;
      })
    },
    handleClick (type){
      switch (type){
        case '跳转至详情' : 
          this.active = '2';
          break;
      }
    },
    handleClick_exp(data){
      common.setStorParams({path:"/expcore"}, {autoid: this.autoid ,exp_active: data});
    },
    getExpDetails (){
      const {autoid} = this;
      let request = { autoid : autoid },expdata;
      axios.get(server.exp + checker.objTurnParams(request)).then(result=>{
        if(result && result.status && result.data.result && result.data.result.length){
          expdata = result.data.result[0] || {};
          if(Number(expdata.publish) == 2){
            if(expdata.subject_data){
              expdata.subject = expdata.subject_data.name;
            }else{
              expdata.subject = ''
            }
            if(expdata.master_data){
              expdata.realname = expdata.master_data.realname;
            }else{
              expdata.realname = ''
            }
            if(expdata.major_data){
              expdata.major = expdata.major_data.name;
            }else{
              expdata.major = ''
            }
            if(expdata.expdeclareyear_data){
              expdata.expdeclareyear = expdata.expdeclareyear_data.year;
            }else{
              expdata.expdeclareyear = ''
            }
            if(expdata.resource_data){
              expdata.resourcetype = expdata.resource_data.resourcetype;
            }
            if(expdata.explain == 'null'){
              expdata.explain = ''
            }
            this.expdata = {...expdata};
          }else{
            this.$confirm('该实验已下架或被删除，点击确定返回首页', '消息提醒', { 
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$router.push("/");
            }).catch(() => {
              this.$router.push("/");
            });
          }
        }else{
          this.$confirm('该实验已下架或被删除，点击确定返回首页', '消息提醒', { 
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$router.push("/");
          }).catch(() => {
            this.$router.push("/");
          });
        }
      })
    },
    getUiData() {
      const {autoid} = this;
      let request = { targettable: "exps",  targetautoid: autoid, get_all_ui: "" },
          slots = [],uitextMenuData = [],tabsRawData = common.cloneObj(TABS_ROW_DATA),delIdx=[];
      axios.get(server.ui + checker.objTurnParams(request)).then(result=>{
        if(result && result.status && result.data && result.data.result){
          let cache = result.data.result;
          cache.forEach((item) => {
            if(slots.indexOf(item.slot) == -1) {
              let slotIdx = tabsRawData.findIndex(ite=>ite.slot === item.slot);
              if(item.name && slotIdx>=0){
                tabsRawData[slotIdx].label = item.name;
                tabsRawData[slotIdx].publish = item.publish;
                if(item.publish == '2'){
                  tabsRawData[slotIdx].sign = true;
                }
              }
              slots.push(item.slot);
            }
            if(item.slot == "uitexts") uitextMenuData.push(this.setUitextItem(item));
          });
          tabsRawData.map((item,idx)=>{
            if(!item.sign && !!item.slot){delIdx.push(idx);}
          })
          let count = 0;
          delIdx.map(idx=>{
            tabsRawData.splice(idx-count,1)
            count ++;
          });
          if(delIdx.length){
            this.active = tabsRawData[0].name;
          }
          this.uitextMenuData = uitextMenuData;
          let i = 1 ;
          tabsRawData.map((item)=>{
            item.indexx = i++;
            if(item.type=="实验资料"){
              window.sessionStorage.setItem('resouceIndex',item.indexx);
            }
          })
          this.tabsData = tabsRawData;
        }
      })
    },
    setUitextItem(item) {
      let itemObj = {
        key: item.autoid,
        title: item.name,
        publish: item.publish,
        // value: '',
        content: '',
        entitle: item.name.toUpperCase()
      }
      return itemObj;
    },
    getExpapply(){
        const {autoid,userinfo} = this;
        let request = {
            expautoid: autoid,
        }
        axios.get(server.expapply + checker.objTurnParams(request)).then(result=>{
            if(result.status){
                let expapplyData = result.data.result || [];
                if(expapplyData.length != 0){
                    this.verifystate = parseInt(expapplyData[0].verifystate);
                }else if(userinfo.role == '1'){
                  this.setExpApply();
                }else {
                  this.verifystate = 0;
                }
            }
        })
    },
    setExpApply (){
      const {autoid} = this;
      let request = {
        expautoid : autoid,
        studytypeid : 2,
      }
      axios.post(server.expapply,request).then(result=>{
        if(result && result.data && result.data.result && result.data.result.length){
          let expapplyData = result.data.result || [];
          this.verifystate = parseInt(expapplyData[0].verifystate);
        }else {
          this.verifystate = 0;
        }
      })
    }
    //...mapActions(['setActive'])
  },
}

</script>

<style scoped lang="less">
  @import url('@/styles/common.less');//公共样式
  .media;//媒体查询
  /deep/ .el-tabs--border-card{
    background:none;
  }
  /deep/ .tabs-ui .el-tabs__header{
    border-radius: 4px;
    background-color: #FFFFFF;
  }
  ::v-deep  .el-tabs__nav-scroll {
    justify-content: left !important;
    padding-left:1px !important;
    padding:10px !important;
  }
  ::v-deep  .el-tabs__item {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    margin-right:20px;
    text-align:center;
  }

  .top_bkg{
    height: 135px;
    background-color: moccasin;
  }
  
  .expcore{
    width:100%;
    background-color:#F2F2F2;
    .expcore-main{
      border-radius: 4px;
    }
    .expcore-box{
      margin:auto;
    }
    .crumbs{
      padding-top:10px;
      .crumbs-item{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
    }
  }
</style>
