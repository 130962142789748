/* eslint-disable no-unused-vars */
import axios from "axios";
import common from "./common";
import {ElLoading,ElMessage} from 'element-plus';
const exclude = ['userinfo'];//排除监控的接口地址
//请求头设置
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.delete['Content-Type'] = 'application/x-www-form-urlencoded';
let timer = 0;
//设置超时
axios.defaults.timeout = 5000000000;

axios.defaults.transformRequest = [function(data) {
    let ret = ''
    if (Object.prototype.toString.call(data) === '[object FormData]') {
        return data
    } else {
        let idx = 0
        for (let it in data) {
            if (idx === 0) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it])
            } else {
                ret += '&' + encodeURIComponent(it) + '=' + encodeURIComponent(data[it])
            }
            idx++
        }
        return ret
    }
}]

// 请求拦截
axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token')
        if (token && token!='undefined') { //此项目目前使用session机制，故关闭Authorization
            config.headers.Authorization = `JWT ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 返回拦截
axios.interceptors.response.use(
    response => {
        let base_Url=["/api/v3/datafile"];

        let state = true;
        base_Url.map(item=>{
            let url = response.request.responseURL;
            
            if(url.indexOf(item)>=0){
               state = false;
            }
        })
      //  console.log(response)
        let data = response.data;
        if (response.status === 500) {
            console.warn('后台报错 联系后台管理员处理')
            window.location.href = '/error/index.html';
            return Promise.reject() //要返回一个promise对象出去
        }else if(response.status === 404) {
            console.warn('接口地址错误 请检查请求链接')
            return Promise.reject()
        }else if(data.msg === 'required login' && exclude.indexOf(common.urlKey(response.request.responseURL))<0){
            if(new Date().getTime() - timer >= 3000){
                localStorage.clear();
                sessionStorage.clear();
                ElMessage.warning('登录标识已失效，请重新登录！');
                timer = new Date().getTime();
                window.location.href = window.__GLOBALPATH + '/#/';
            }
        }else if(data && state)return data;
        return response.data;
    },
    (err) => {
        //window.location.href = '/error/index.html';
        if (err.message == "Operation canceled by the user.") {
            return
        }
    }
    
);

export default axios