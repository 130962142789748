<template>
    <div class="layout_20230210_5f79">
        <div class="layout-left">
            <div class="left-head">
                <slot name="left-head"></slot>
            </div>
            <div class="left-content">
                <slot name="left-content"></slot>
            </div>
        </div>
        <div class="layout-right">
            <div class="right-head">
                <slot name="right-head"></slot>
            </div>
            <div class="right-content">
                <slot name="right-content"></slot>
            </div>
        </div>

    <div class="line_blo">
        <slot name="line"></slot>
    </div>

    </div>

</template>


<script>
</script>

<style scoped lang="less">
.layout_20230210_5f79 {
    display: flex;
    .layout-left {
        flex:1 1 auto;
        .left-head{
            height:39px;
        }
        .left-content {

        }
    }

    .layout-right{
        flex:0 0 auto;
        margin-left:50px;
        .right-head{
            position: relative;
            height: 39px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .right-content{

        }
    }
    .line_blo{
        // width: 80%;
        display: inline-block;
    }

}
</style>