<template>
    <!-- 产品中心 -->
    <layout_20230210_47e9>
        <template #title>产品中心</template>
        <div class="main">
            <!-- <img class="center-line" src="/images/home/center_line.png" alt="新闻中心分割线"> -->
            <el-tabs class="mian_blockk" v-model="active">
                <el-tab-pane v-for="menu in menuData" :key="menu.autoid" :name="menu.autoid">
                    <template #label>
                        <span :class="menu.icon">
                        </span>
                        <!-- <el-icon class="press-icon" :size="20">
                            <component :is="menu.icon"></component>
                        </el-icon> -->
                        {{ menu.title }}
                    </template>
                    <!-- <span v-if="menu.name === active"> -->
                    <div class="content_block_tab" v-if="examdata.length > 0">
                        <div class="exam_block" @click="to_click(exam_item)" v-for="exam_item in examdata"
                            :key="exam_item.key">
                            <span class="exam_image"><img :src="exam_item.exp_data.face"></span>
                            <span class="exam_title_tab">{{ exam_item.exp_data.title }}</span>
                        </div>
                    </div>
                    <div class="content_block_tab_null" v-else>
                        <span>
                            <img :src="nodata" alt="">
                        </span>
                    </div>
                    <!-- </span> -->
                    <!-- </template> -->
                    <div class="block_describe">
                        <span>{{ menu.introduction }}</span>
                    </div>

                </el-tab-pane>
            </el-tabs>
            <div class="learn_more" @click="to_goods">
                <span>了解更多</span>
            </div>
        </div>
    </layout_20230210_47e9>
</template>

<script>
import layout_20230210_47e9 from '@/components/layouts/layout_20230210_47e9.vue';
import axiosTool from '@/utils/axios_tool';
import server from "@/service/server";
import checker from '@/utils/checker';
import icon from '@/assets/revalance/icon.js';
import common from "@/utils/common";

// import 
export default {
    name: "goods",
    components: {
        layout_20230210_47e9,
        server,
        checker,
        icon
    },
    created() {
        this.axiosTool = new axiosTool(this, [
            {
                key: 'get_recommend_type', title: '获取推荐类型', type: 'get', url: server.recommendexplib, params: {
                }
            },
            {
                key: 'get_recommend_list', title: '依据推荐id获取对应的推荐列表', type: 'get', url: server.recommendexp, params: {
                    template: 'queryData:recommendexplibid'
                }
            },
            {type:'post',url:server.exppv},
        ])
        console.log(this.axiosTool)
    },
    mounted() {
        console.log(icon)
        console.log(this.axiosTool)
        this.axiosTool._axios().then(data => {
            const [recomType, recomList] = data;
            this.handleType(recomType);
            this.handleList(recomList);
        })
    },
    watch: {
        'active': 'Tabschange',
    },
    data() {
        return {
            nodata: require('@/assets/images/xxl_gw/nodata.png'),
            queryData: {
                recommendexplibid: 1
            },
            active: '',
            menuData: [],
            examdata: [],
            menuData1: [
                {
                    key: '001',
                    label: '能源动力',
                    name: '能源动力',
                    icon: 'folder-opened',
                    active: '',
                },
                { key: '002', label: '电气工程', name: '电气工程', icon: 'folder-opened', active: '', examdata: [] },
                { key: '003', label: '机械工程', name: '机械工程', icon: 'folder-opened', active: '', examdata: [] },
                { key: '004', label: '环境工程', name: '环境工程', icon: 'folder-opened', active: '', examdata: [] }
            ]
        }
    },
    methods: {
        Tabschange(data) {
            console.log(data)
            // this.menuData = [];
            if (data) {
                this.queryData.recommendexplibid = Number(data);
                this.axiosTool.get_recommend_list().then(res => {
                    this.handleList(res);
                })
            }
        },
        to_goods() {
            this.$router.push('/solution_goods');
        },
        to_click(data) {
            common.removeParams('/goods');
            let exp_data = {};
            if(data.exp_data){
                exp_data = data.exp_data;
            }
            common.setStorParams({ path: "/expcore" }, { autoid: exp_data.autoid, exp_data: exp_data });
            this.$router.push("/expcore");
            this.postExppv(data.expautoid);
        },
        postExppv(autoid) {//浏览量
            this.axiosTool.loading = true;
            this.axiosTool.exppv({}, data => {
                const { postreq } = data;
                postreq.expautoid = autoid;
                return { ...data, postreq }
            }).then(result => {
                if (result.status) {
                    //console.warn('成功创建浏览记录')
                    this.axiosTool.loading = false;
                }
            })
        },
        handleType(res) {
            let { menuData } = this;
            console.log(icon)
            let dataa = icon.good_icon;

            if (res.status && res.data && res.data.result) {
                menuData = res.data.result;
                dataa.map(item => {
                    menuData.map(item_ou => {
                        if (item.revalance == item_ou.autoid) {
                            item_ou.icon = item.title;
                        }
                    })
                })

                this.active = res.data.result[0].autoid;

                this.menuData = menuData
                //console.log(this.menuData)
            }
        },
        handleList(res) {
            if (res.status && res.data && res.data.result) {
                this.examdata = res.data.result;
                //  console.log(item.exp_data[0].face)
                //     res.data.result.map(item=>{
                //         console.log(item.exp_data.face)
                //    item.face = require('@'+item.exp_data.face);
                //    this.examdata.push(item)
                // })
            }
        }

    }
}
</script>

<style scoped lang="less">
// :deep(.el-tabs .el-tabs--top){
//   width: 93%;
//     margin: 0 auto;
// }

:deep(.el-tabs__item) {
    font-size: 18px;
}

:deep(.mian_blockk) {
    margin-bottom: 15px;

    .el-tabs__nav-scroll {
        display: flex;
        justify-content: space-around;


        .el-tabs__nav {
            display: flex;
            width: 71%;
            margin: 0 auto;
            justify-content: space-between;

            .el-tabs__item:hover {
                color: #FFA400;
            }

            .el-tabs__active-bar {
                background-color: #FFA400;
            }

            .el-tabs__item.is-active {
                // background-color: #FFA400 !important;
                color: #FFA400 !important;
                border-color: #FFA400 !important;
            }
        }
    }
}

:deep(.el-tabs__content) {
    overflow: visible;
}

.content_block_tab {
    display: grid;
    justify-content: flex-start;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;

    .exam_block {
        display: flex;
        flex-direction: column;
        text-align: center;
        // box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
        padding-top: 10px;
        cursor: pointer;

        .exam_image {
            width: 100%;
            height: 100%;

            >img {
                width: 300px;
                height: 200px;
            }
        }

        .exam_title_tab {
            font-size: 16px;
            font-weight: bolder;
            padding: 10px 0;
            height: 52px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
    }

    .exam_block:hover {
        text-decoration: underline;
    }
}

.content_block_tab_null {
    text-align: center;

    >span {
        margin: 0 auto;

        >img {
            width: 500px;
        }
    }
}

.block_describe {
    text-align: center;
    margin-top: 62px;
    color: #686969;
}

.block_describe_tabs {
    text-align: center;
    margin-top: 62px;
    font-size: 14px;
    color: #686969;
}

.learn_more {
    width: 121px;
    background-color: #f18d00;
    color: white;
    text-align: center;
    height: 35px;
    line-height: 33px;
    margin: 30px auto;
    cursor: pointer;
    border-radius: 3px;
}</style>