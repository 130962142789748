<template>
  <!--新闻公告-->
  <div class="notice-ui">
    <div class="notice-main common-width">
      <PageIteration>
        <template #title>
          <div class="notice-title">
            <div class="title-name">
              <span>新闻公告</span>
              <span>NEWS</span>
            </div>
          </div>
        </template>
        <News @toClick="handleClick" :newsData="newsData" :line="1" />
      </PageIteration>
      <div class="notice-page" v-if="newsData.length">
        <Pagination
          :total="paging.total || 0"
          :pageSize="paging.pageSize || 10"
          :currentPage="paging.currentPage || 1"
          @pageChange="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import News from "@/components/business/News/index.vue";
import Pagination from "@/components/course/Pagination.vue";
import PageIteration from "@/components/business/PageIteration/index.vue";
import Banber from "./Banner.vue";
import common from "@/utils/common";
import axios from "@/utils/axios";
import server from "@/service/server";
import axiosTool from '@/utils/axios_tool';
import checker from "@/utils/checker";
export default {
  data() {
    return {
      newsDataTop: [],
      newsData: [],
      paging: {
        total: 0,
        pageSize: 10,
        currentPage: 1,
      },
      axiosTool : {},
    };
  },
  created () {
    this.axiosTool = new axiosTool(this,[
      {key:'get_top_news',type:'get',title:'获取置顶新闻',url:server.coverage,params : {getreq:{totop:true}}},
      {key:'get_news',type:'get',title:'获取非置顶新闻',url:server.coverage,params : {getreq:{_order_column:'createtime',_order_style:'desc',totop:false},template:'paging:page=currentPage|per_page=pageSize'}},
      {key:'get_news_count',type:'get',title:'获取非置顶新闻总数',url:server.coverage,params : {getreq:{_order_column:'createtime',_order_style:'desc', totop:false,count:true,_dispage_:true}}},
    ])
  },
  mounted(){
    this.axiosTool.loading = true;
    this.axiosTool._axios().then(data=>{
      const [topNews,News,count] = data;
      this.handleTopNews(topNews);
      this.handleNews(News);
      this.handleNewsCount(count);
      this.axiosTool.loading = false;
    })
  },
  methods: {
    handleTopNews (data){
      let newsDataTop = [];
      if(data && data.result){
        data.result.map(item=>{
          item.time = checker.timestampToDate(new Date(item.createtime).getTime(),'YYYY-MM');
          item.day = checker.timestampToDate(new Date(item.createtime).getTime(),'DD');
          item.key = item.autoid;
          newsDataTop.push(item);
        });
      }
      this.newsDataTop = newsDataTop;
    },
    handleNews (data){
      let newsData = []
      const {paging,newsDataTop} = this;
      if(data && data.result){
        data.result.map(item=>{
          item.time = checker.timestampToDate(new Date(item.createtime).getTime(),'YYYY-MM');
          item.day = checker.timestampToDate(new Date(item.createtime).getTime(),'DD');
          item.key = item.autoid;
          newsData.push(item);
        });
      }
      if(paging.currentPage === 1){
        newsDataTop.map((item) => newsData.unshift(item));
      }
      this.newsData = newsData;
    },
    handleNewsCount(data){
      // console.log(typeof(data),data)
      if(typeof data !== 'undefined'){
          this.paging.total = Number(data.data);
        // this.paging.total = parseInt(data);
      }
    },
    pageChange(currentPage) {
      this.paging.currentPage = currentPage;
      this.axiosTool.loading = true;
      this.axiosTool['get_news']().then(data=>{
        this.handleNews(data);
        this.axiosTool['get_news_count']().then(data=>{
          this.handleNewsCount(data);
          this.axiosTool.loading = false;
        });
      });
    },
    handleClick(data) {
      common.removeParams('/notice');
      // common.setStorParams(this,{autoid: data.key});
      this.$router.push('/noticemain'+'?id=' + data.key);
    },
  },
  components: {
    News,
    Pagination,
    PageIteration,
    Banber,
  },
};
</script>

<style scoped lang="less">
@import url("@/styles/common.less"); //公共样式
.media; //媒体查询
:deep(.page-item .item-head){
  padding: 0;
}

:deep(.news-ui .news-items){
  padding: 0 10px;
}
.notice-ui {
  width: 100%;
  background-color: #ffffff;

  border-radius: 4px;
  .notice-banber {
    width: 100%;
    margin-bottom: 10px;
  }
  .notice-main {
    width: 100%;
    margin: auto;
    .notice-page {
      padding: 20px 0;
      text-align: center;
    }

    .notice-title {
      width: 100%;
      display: flex;
      justify-content: center;
      .title-name {
        width: 120px;
        height: 114px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-image: url("~@/assets/images/news.png");
        > span:nth-child(1) {
          position: relative;
          top: 10px;
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }

        > span:nth-child(2) {
          position: relative;
          top: 20px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #b6b6b6;
        }
        &::after {
          position: relative;
          top: 30px;
          width: 160px;
          height: 2px;
          background-color: #1e5fc2;
          content: "";
          display: inline-block;
        }
      }
    }
  }
}
</style>