<template>
    <div class='issue-ui'>
        <!--实验提问-->
        
        <template v-if="viewState.type==='提问列表'">
            <TabsNew :active="active" :menuData="menuData" @toClick="changeTab">
                <template #default>
                    <div class='issue-main'>
                        <template v-if="pitchActive && pitchActive.type == '发起的提问'">
                            <div class='issue-item' v-for='item in dataList' :key='item.key'>
                                <span>{{item.title}}</span>
                                <span v-html="item.content"></span>
                                <div class='issue-footer'>
                                    <span>{{ item.name }} 发布于 {{ item.time }} 共{{ item.childsize }}条回复</span>
                                    <div class='issue-options'>
                                        <span @click='handleClick(item)'>详情</span>
                                        <span>|</span>
                                        <span @click='handleDelete(item)'>删除</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class='issue-item' v-for='item in dataList' :key='item.key'>
                                <span v-html="item.content"></span>
                                <div class='issue-footer'>
                                    <span>{{ item.name }} 发布于 {{ item.time }}</span>
                                    <div class='issue-options'>
                                        <span @click='handleClick(item)'>详情</span>
                                        <span>|</span>
                                        <span @click='handleDelete(item)'>删除</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div class='issue-page'>
                            <Pagination v-if="dataList.length" :total='paging.total || 0' :pageSize='paging.pageSize || 10' 
                                :currentPage='paging.currentPage || 1' @pageChange='pageChange' />
                        </div>
                    </div>
                </template>
            </TabsNew>
        </template>
        <template v-if="viewState.type==='提问详情'">
             <!-- <Details parentField="issueautoid" @toBack="viewState = '提问列表'" @updateRate="getCount();getData(pitchActive);" :expautoid="expInfo.expautoid" :data="issueData"></Details> -->
             <Details parentField="issueautoid" @toBack="viewState.type = '提问列表'" @updateRate="getCount();getData(pitchActive);" :expautoid="expInfo.expautoid" :data="viewState.data"></Details>
        </template>
    </div>
</template>

<script>
import TabsNew from "@/components/business/Tabs/index.vue";
import Pagination from '@/components/course/Pagination.vue';
import Details from '@/views/Main/Expcore/answerDetails.vue';

import axios from "@/utils/axios";
import server from "@/service/server";
import checker from "@/utils/checker";
import common from "@/utils/common";

import { ElMessage } from 'element-plus'

export default {
    name: 'XxlMasterIssue',

    data() {
        return {
            menuData: [
                { key: "001", title: "发起的提问", type: "发起的提问", value: 0, api: server.uiissue, extraParam: {} },
                { key: "002", title: "我的回答", type: "我的回答", value: 0, api: server.answer, extraParam: {my_issues_answer: ''} },
            ],
            active : '',
            pitchActive: null,
            mediaType: {
                img: ["jpg","png"],
                video: ["mp4"]
            },
            dataList : [],
            paging : {
                total : 0,
                pageSize : 10,
                currentPage : 1,
            },
            viewState : {type: '提问列表'},
            issueData : [],
        };
    },

    props : {
        expInfo : {
            type : Object,
            default : ()=>{return {
                expautoid: null,
            }}
        },
    },
    mounted() {
        this.getCount();
    },

    methods: {
        pageChange (currentPage) {
            this.paging.currentPage = currentPage;
            this.dataList = [];
            this.getData(this.pitchActive);
        },
        handleClick (data){
            // if(type==2){
            //   this.issueType.data = "我的回答详情";
            // }
            this.viewState.type = '提问详情';
            this.viewState.data = data;
            this.issueData = data;
        },
        handleDelete(data) {
            let {expInfo, pitchActive} = this;
            let request = {autoid: data.key, delete_data: true };
            axios.post(pitchActive.api, request).then((result) => {
                if (result.status) {
                    ElMessage({
                        message: '删除成功!',
                        type: 'success',
                    });
                    this.setCount(pitchActive.api, { expautoid: expInfo.expautoid, count: true, ...pitchActive.extraParam }, pitchActive.key, true);
                    this.getData(pitchActive);
                }
            });
        },
        changeTab(item) {
            let {expInfo} = this;
            this.pitchActive = item;
            this.extraParam = item.extraParam;
            this.paging.currentPage = 1;
            this.setCount(item.api, { expautoid: expInfo.expautoid, count: true, ...item.extraParam }, item.key, true);
            this.dataList = [];
            this.getData(item);
        },
        getData(item) {
            let {expInfo} = this;
            let request = {expautoid: expInfo.expautoid, page: this.paging.currentPage, per_page: this.paging.pageSize, ...this.extraParam },
                dataList = [];
            axios.get(item.api + checker.objTurnParams(request)).then((result) => {
                if (result.status && result.data && result.data.result) {
                    result.data.result.forEach((item) => {
                        let itemData = {
                            key: item.autoid,
                            title: item.name || "",
                            content: item.content || "",
                            name: item.user_data ? item.user_data.realname || "" : "",
                            iconUrl: item.user_data ? item.user_data.face || require("@/assets/images/user_logo.png") : "",
                            time: item.createtime,
                            childsize: item.answer_size || 0,
                            imgs: [],
                            videos: []
                        }
                        if(item.evalresource_data && item.evalresource_data.filepath) {
                            item.evalresource_data.filepath.split(",").forEach((path, fIndex) => {
                                let ext = path.split(".").pop();
                                if(this.mediaType.img.indexOf(ext) != -1) {
                                    itemData.imgs.push({
                                        "url": path,
                                        "key": fIndex + 1
                                    });
                                } else if(this.mediaType.video.indexOf(ext) != -1) {
                                    itemData.videos.push({
                                        "url": path,
                                        "key": fIndex + 1
                                    });
                                }
                            });
                        }
                        dataList.push(itemData);
                    });
                    this.dataList = dataList;
                }
            });
        },
     
        setAdata(key, value) {
            this.menuData.forEach((item) => {
                if (item.key == key) {
                    item.value = Number(value) || 0;
                    return true;
                }
            });
        },
        setCount(api, request, adataKey, setTotal=false) {
            axios.get(api + checker.objTurnParams(request)).then((result) => {
                if (result.status) {
                    this.setAdata(adataKey, result.data);
                    if(setTotal) this.paging.total = Number(result.data) || 0;
                }
            });
        },
        getCount() {
            let {expInfo} = this;
            // 发起的提问
            this.setCount(server.uiissue, { expautoid: expInfo.expautoid, count: true }, "001");
            // 我的回答
            this.setCount(server.answer, { expautoid: expInfo.expautoid, my_issues_answer: '', count: true }, "002");
        },
    },
    components : {
        TabsNew,
        Pagination,
        Details,
    },
};
</script>

<style lang="less" scoped>
    .issue-ui{
        width:100%;
        .issue-main{
            .issue-item{
                display: flex;
                flex-direction: column;
                padding: 15px 10px;
                border-bottom:1px solid #F6F6F6;
                >span:nth-child(1){
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 28px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: -webkit-fill-available;
                }
                >span:nth-child(2){
                    font-size: 16px;
                    font-weight: 400;
                    color: #989898;
                    text-indent: 30px;
                    text-align: justify;
                    line-height: 25px;
                    padding: 10px 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: -webkit-fill-available;
                }
                .issue-footer{
                    display: flex;
                    justify-content: space-between;
                    >span{
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #999999;
                    }
                    .issue-options{
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #727272;
                        cursor:pointer;
                        >span:nth-child(2){
                            padding:0 10px;
                        }
                    }
                }
            }

            .issue-page{
                width:100%;
                text-align:center;
                padding:20px 0 10px;
            }
        }
    }
</style>