<template>
  <el-button :icon='icon' :disabled='disabled' :size="size" :class="['button-ui',type,plain?'plain':'',disabled ? 'disabled' : '']">
    <slot name='icon'></slot>
    <slot></slot>
  </el-button>
</template>

<script>
export default {
    props : {
        type : {
            type : String,
            default : 'primary',
        },
        disabled : {
            type : Boolean,
            default : false,
        },
        plain : {
            type : Boolean,
            default : false,
        },
        icon : {
            type : String,
            default : '',
        },
        size : {
            type : String,
            default : 'small',
        },
    }
}
</script>
<style scoped lang='less'>
    ::v-deep  .el-icon {
        --font-size:12px;
        width:auto;
        height:auto;
        margin-right: 5px;
        position: relative;
        top: 1px;
    }
    .button-ui{
        /* box-shadow: 0px 3px 7px 0px rgba(85, 85, 85, 0.15); */
        border-radius: 4px;
        border:none;
    }
    .primary{
        background-color: rgba(62,179,107,1);
        color:#FFFFFF;
        display: inline-flex;
        align-items: center;
    }
    
    .primary:active{
        background-color: rgba(62,179,107,.9) !important;
    }
    .primary.disabled{
        background-color: rgba(62,179,107,.5) !important;
    }
    .primary.disabled:hover{
        background-color: rgba(62,179,107,.5) !important;
        color:#FFFFFF;
    }
    .primary:hover{
        /* border:1px solid #3EB36B !important; */
        background-color: rgba(62,179,107,.7);
    }

    .primary.plain{
        background-color: transparent;
        border:1px solid rgba(62,179,107,1);
        color:rgba(62,179,107,1);
    }
    .primary.plain:active{
        background-color: transparent !important;
        border:1px solid rgba(62,179,107,.9);
        color:rgba(62,179,107,.9);
    }
    .primary.plain.disabled{
        border:1px solid rgba(62,179,107,.5);
        color:rgba(62,179,107,.5);
    }
    .primary.plain:hover{
        border:1px solid rgba(62,179,107,.7);
        color:rgba(62,179,107,.7);
    }



    .blue{
        background-color: rgba(41,116,234,1);
        color:#FFFFFF;
    }
    
    .blue:active{
        background-color: rgba(41,116,234,.9) !important;
    }
    .blue.disabled{
        background-color: rgba(41,116,234,.5) !important;
    }

    .blue.disabled:hover{
        background-color: rgba(41,116,234,.5) !important;
        color:#FFFFFF;
    }
    .blue:hover{
        /* border:1px solid #3EB36B !important; */
        background-color: rgba(41,116,234,.7);
    }

    .blue.plain{
        background-color: transparent;
        border:1px solid rgba(41,116,234,1);
        color:rgba(41,116,234,1);
    }
    .blue.plain:active{
        background-color: transparent !important;
        border:1px solid rgba(41,116,234,.9);
        color:rgba(41,116,234,.9);
    }
    .blue.plain.disabled{
        border:1px solid rgba(41,116,234,.5);
        color:rgba(41,116,234,.5);
        background-color: transparent !important;
    }
    .blue.plain:hover{
        border:1px solid rgba(41,116,234,.7);
        color:rgba(41,116,234,.7);
    }


    .info.plain{
        background-color : transparent;
        border:1px solid rgba(62,179,107,1);
        color:rgba(62,179,107,1);
    }
    .info.plain:active{
        background-color: transparent !important;
        transform: scale(.98);
    }
    .info.plain:hover{
        background-color: transparent !important;
    }


    .info{
        background-color: rgb(235, 235, 235,1);
        color:rgb(144, 147, 153);
    }
    .info:active{
        background-color: rgba(235, 235, 235,.9) !important;
    }
    .info.disabled{
        background-color: rgba(235, 235, 235,.5) !important;
    }
    .info.disabled:hover{
        background-color: rgba(235, 235, 235,.5) !important;
        color:rgb(144, 147, 153);
    }
    .info:hover{
        /* border:1px solid #3EB36B !important; */
        background-color: rgba(235, 235, 235,.7);
    }

    .info.plain{
        background-color : transparent;
        border:1px solid rgb(235, 235, 235,1);
        color:rgb(144, 147, 153);
    }
    .info.plain:active{
        background-color: transparent !important;
        transform: scale(.98);
    }
    .info.plain:hover{
        background-color: transparent !important;
    }



    .danger{
        background-color: rgb(255,111,111,1);
        color:#FFFFFF;
    }
    .danger:active{
        background-color: rgba(255,111,111,.9) !important;
    }
    .danger:hover{
        /* border:1px solid #3EB36B !important; */
        background-color: rgba(255,111,111,.7);
    }
    .danger.plain{
        background-color : transparent;
        border:1px solid rgb(255,111,111,1);
        color:rgb(255,111,111,1);
    }
    .danger.plain:active{
        background-color: transparent !important;
        transform: scale(.98);
    }
    .danger.plain:hover{
        background-color: transparent !important;
    }

    .danger.disabled{
        background-color: rgba(255,111,111,.5) !important;
    }
    .danger.disabled:hover{
        background-color: rgba(255,111,111,.5) !important;
        color:#FFFFFF;
    }



    .warning{
        background-color: rgb(241,140,0,1);
        color:#FFFFFF;
    }
    .warning:active{
        background-color: rgba(241,140,0,.9) !important;
    }
    .warning:hover{
        background-color: rgba(241,140,0,.7);
    }
    .warning.disabled{
        background-color: rgba(235, 235, 235,.5) !important;
        color:#999999;
    }

    .warning.plain{
        background-color : transparent;
        border:1px solid rgba(241,140,0,1);
        color:rgba(241,140,0,1);
    }
    .warning.plain:active{
        background-color: transparent !important;
        transform: scale(.98);
    }

    .warning.plain.disabled{
        border-color: rgba(235, 235, 235,.5) !important;
        background-color: rgba(235, 235, 235,.5) !important;
        color:#999999;
    }
    .warning.plain.disabled:hover{
        background-color: rgba(235, 235, 235,.5) !important;
    }

    .warning.plain:hover{
        background-color: transparent !important;
    }

</style>