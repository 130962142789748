import json from '../../global.config.json';

const variable = {
    scroll_vertical_decrement_bg : '/images/top.png',
    scroll_vertical_increment_bg : '/images/bottom.png',
    scroll_horizontal_decrement_bg : '/images/left.png',
    scroll_horizontal_increment_bg : '/images/right.png',
    scroll_corner_bg : '/images/favicon.ico',
}

const url = v=>`url(${v})`;

for (const v in variable){
    const name = `--${v}`;
    const value = url(json.publicPath + variable[v]);
    document.documentElement.style.setProperty(name,value);
}