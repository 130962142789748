<template>
  <iframe
    ref="iframe"
    v-if="filepath"
    :src="BASE_URL + 'js/PDF.JS/web/?file=' + filepath"
    scrolling="no"
    frameborder="0"
  ></iframe>
</template>

<script>

export default {
    name: 'pdfjs',
  data() {
    return {
        BASE_URL : process.env.BASE_URL
    }
  },

  props: {
      filepath: {
          type: String,
          default: ""
      }
  },

  mounted() {
      this.fitIframeHeightLoop(this.$refs.iframe);
  },

  components: {
      
  },

  directives: {
      
  },

  created() {
      
  },

  methods: {
      fitIframeHeightLoop(iframe) {
        let getIframeHeightTimes = 0,
            iframeHeightRendered = false;

        let iResize = (iframeHeight) => {
            iframeHeight = iframeHeight || iframe.contentWindow.document.body.offsetHeight;
            iframe.style.height = iframeHeight + 'px';
        }

        let getIframeHeight = () => {
            let childWindow = iframe.contentWindow,
                iframeHeight = childWindow.document && childWindow.document.body.offsetHeight || 0;
            getIframeHeightTimes++;
            if (iframeHeight != 0 && !iframeHeightRendered) {
                iframeHeightRendered = true;
                setTimeout(getIframeHeight, 500);
            } else {
                renderIframeHeight(iframeHeight);
            }
        }

        let renderIframeHeight = (iframeHeight) => {
            if (iframeHeight == 0) {
                setTimeout(getIframeHeight, 500);
            } else {
                iResize(iframeHeight);
            }
        }
        window.onmessage = (message) => {
            let iframeHeight = message.data.iframeHeight
            iframeHeight && iResize(iframeHeight);
            renderIframeHeight(message.data.iframeHeight)
        }

        window.onresize = () => {
            console.log('Window: Hello everybody, I had resizeed');
            iResize();
        }
    }
  }
}
</script>

<style scoped>
iframe {
  width: 100%;
}
</style>
