<template>
<!-- <div class="news_main">
        <div class="head_banner">
            <img :src="headImg" alt="">
        </div>
    </div > -->
     <div class="news_nenu">
        <div class="menus_header">
            <span class="menu_title">新闻中心</span>
            <span class="dash_line"></span>
        </div>
        <div class="menus_content">
            <div class="left_mune">   
                <div :class="['menu_item']" v-for=" item in menu_type" :key="item.key" @click="handleClick('切换',item)">
                    <span>{{item.value}} </span>
                    <img v-if="active == item.key" class="menu-item-pitch" :src="base_url + 'images/home/pitch.png'" alt="">
                </div>
            </div>
            <div class="right_content">
                <newscontent :keyy='active'></newscontent>
            </div>
        </div>
     </div>
</template>

<script>
import Pagination from '@/components/course/Pagination.vue';
import newscontent from './NewsContent.vue'
import axiosTool from '@/utils/axios_tool';
import server from "@/service/server";
export default {
    name:'news_main',
    components:{
        newscontent,
        axiosTool,
        server,
        Pagination
    },
    data (){
        return{
            active:4,
            headImg:require('@/assets/images/xxl_gw/news_banner.png'),
            menu_type:[]
        }

    },
    methods:{
        handleClick(type,data){
            console.log(type,data)
            switch(type){
                case '切换':
                    if(!!data.key){
                         this.active = data.key;
                    }    
                break;
            }
        },
        handleNewsType(res){
            let {menu_type} = this;
            if(res.data){
                res.data.map(item=>{
                    menu_type.push(item)
                })
            }
            menu_type.unshift({
                key: 4,
                value: "全部"
            })
            console.log(this.menu_type)
        }
    },
    created(){
        this.axiosTool = new axiosTool(this,[
            {
                key:'get_newstype',title:'获取新闻中心类型',type:'get',url:server.coverage,params:{
                getreq:{get_newstype:true}
            }
            }
        ])
    },
    mounted(){
        this.axiosTool._axios().then(data=>{
            const [newType]= data;
            this.handleNewsType(newType);
        })
    }
}
</script>

<style scoped lang="less"> 

.news_main{
    // height: 900px;
    display: block;
    width: 100%;
    .head_banner{
        
        display: block;
        width: 100%;
        >img{
            height: inherit;
            width:100%;
            height: 455px;
        }
    }
}
 .news_nenu{
        min-height: 600px;
        width: 77%;
        margin: 0 auto;
        position: relative;
        top: 0;
        background-color: white;
        padding: 30px 0 0;
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        border-radius: 13px;
        .menus_header{
            font-size: 22px;
            color: #323232;
            display: flex;
            flex-direction:column;
            padding: 0 61px 13px 41px;
            flex:0 0 auto;
            .menu_title{
                
            }
            .dash_line{
                border: 1px dashed #737A92;
                /* height: 1px; */
                margin-top: 20px;
                width: 100%;
            }
        }
        .menus_content{
            margin-top: 20px;
            width: 100%;
            // height: 300px;
            display: flex;
            justify-content: space-between;
            flex:1 1 auto;
            .left_mune{
                width: 260px;
                background: #F1F4F7;
                // padding-left: 40px ;
                //  padding-top: 20px;
                    color: #737A92;
                 .menu_item{
                    position: relative;
                    padding-left: 40px;
                    cursor: pointer;
                    // padding-top: 20px;
                    >span{
                        position: relative;
                        z-index: 1;
                        height: 51px;
                        line-height: 51px;
                      
                        font-size:18px ;
                    }

                    .menu-item-pitch{
                        position: absolute;
                        left:0;
                        right:0;
                        top:0;
                        bottom: 0;
                    }
                }
            }
            .right_content{
                width: 74%;
                // background-color: antiquewhite;                       
            }
            .footer{
                text-align: center;
                width: 90%;
            }
           
        }

    }
    .menu_item.active{
        color: #F18D00;
        border-right: 3px solid #F18D00;
    }
    
</style>