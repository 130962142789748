<template>
    <el-carousel :autoplay="true" class="x-carousel" :height="height" >
        <el-carousel-item v-for="item in data" :key="item.key">
            <img  class="carousel-img" @click="click(item)" :src="item.url" :alt="item.title">
        </el-carousel-item>
    </el-carousel>
</template>

<script>
import axios from '@/utils/axios';
import checker from '@/utils/checker';
import server from '@/service/server';
export default {
    name: 'carousel',
    data() {
        return {
            data: [],
            height: '619px',
        }
    },
    created (){
        this.get_data();
        
    },
    methods : {
        click (data){
            if(data.link){
                window.open(data.link);
            }
        },
        get_data (){
            axios.get(server.banner).then(data=>{
                let result = [];
                if(data.status && data.data && data.data.result){
                    result = data.data.result;
                    result = result.map(o=>{
                        o.key = o.autoid;
                        o.url = o.imgsrc;
                        return o;
                    })
                }
                this.data = result;
                console.log(data);
            })
        }
    },
}
</script>

<style scoped lang="less">
.x-carousel {
    height: auto;
    width: 100%;
    text-align: center;

    .carousel-img{
        width:100%;
        cursor: pointer;
    }
}
</style>