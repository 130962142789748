<template>
    <el-menu :default-active="activeIndex" class="el-menu-cctv" mode="horizontal" @select="handleSelect">
        <template v-for="menu in menuData" :key="menu.key">
            <Loop v-if="menu.child"  :data="menu" ></Loop>
            <el-menu-item class="el-menu-item-cctv" v-else :index="menu.name">
                <span class="item-cctv-span" v-if="menu.legal_path" @click="handleClick('外部跳转',menu)">{{menu.label}}</span>
                <template v-else>
                    {{menu.label}}
                </template>
            </el-menu-item>
        </template>
    </el-menu>
</template>


<script>
import Loop from './loop.vue';
import utils from '@/utils/checker';
import common from '@/utils/common';

export default {
    components : {
        Loop
    },
    data() {
        return {
            menuData : [],
            activeIndex: '首页',
            menuMap : {},
        }
    },
    created (){
        this.dataMonitor();
        
    },
    watch : {
        data : 'dataMonitor',
        '$route.path' : 'initMenu'
    },
    methods: {
        initMenu (key){
            const {menuMap,$route:{path}} = this;
            key = key ? key : path;
            if(menuMap[key]){
                this.activeIndex = menuMap[key].name;
            }
        },
        dataMonitor (data=this.data){
            this.menuData = this.edv(data);
            this.initMenu();
        },
        handleSelect(data) {
            const {menuData} = this;
            let idx = menuData.findIndex(o=>o.name === data),
            menu = menuData[idx];
            if(menu){
                this.$router.push(menu.path);
            }
        },
        handleClick (type,data){
            switch (type){
                case '外部跳转' : 
                    window.open(data.path)
                    break;
            }
        },
        edv (data){//数据加工
            const cache = [];
            data.forEach(item=>{
                item.key = item.key ? item.key : utils.uuid(8);
                item.child = false;
                item.legal_path = common.legalUtl(item.path);
                this.menuMap[item.path] = item;
                if(Array.isArray(item.children) && item.children.length){
                    item.child = true;
                    item.children = this.edv(item.children);
                }
                cache.push(item);
            })
            return cache;
        }
    },
    props : {
        data : {
            type : Array,
            default : function (){
                return [];
            }
        }
    },
}
</script>

<style scoped lang="less">
    @import url('@/assets/less/theme.less');
    .el-menu-cctv{
        background-color: transparent;
        .not-select;
    }
    .el-menu.el-menu--horizontal{
        border:none;
    }
    .el-menu-item-cctv{
        color: @global_color;
        font-size: 20px;
        .item-cctv-span{
            display: block;
        }
    }

    .menu-link{
        text-decoration: auto;
    }

    .el-menu-item-cctv:not(.is-disabled):focus,
    .el-menu-item-cctv:not(.is-disabled):hover{
        background-color: transparent;
        color: @global_background;
    }

    .el-menu-item-cctv.is-active,.el-sub-menu.is-active{
        color:@global_background;
        border-color: @global_background;
    }


    :deep(.el-sub-menu.el-sub-menu__hide-arrow){
        list-style: none;
        margin: 0;
        padding-left: 0;
        float: left;
        height: 60px;
        line-height: 60px;
        border-bottom: 2px solid transparent;
        color: #FFFFFF;
    }
</style>