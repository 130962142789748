<template>
  <div class="upload-ui">
        <!-- 按钮模式下 -->
        <template v-if="modules === 'btn'">
            <div class="upload-main" ref='fileElem'>
                <div class="upload-list" v-if="btnPosition === 'top' && fileList.length">
                    <Btn :disabled='disabled' @delete="deleteAction" @reset="resetFile" @download="downloadFile" :loadings="loading" :fileLists="fileList"></Btn>
                </div>
                <div :class="['upload-btn',!uploadState ? 'hidden' : '',disabled?'disabled':'']" v-if='!disabled'>
                    <input :key="inputKey" class="upload-input" name='file'   :accept='accept'  @change="uploadChange" :multiple='multiple'  ref="upload"  type="file"/>
                    <span>
                      <Buttons @click="handleUpload" type="blue"><slot name="btnName">文件上传</slot></Buttons>
                      <span><slot name='上传提示'></slot></span>
                    </span>
                </div>
                <div class="upload-list" v-if="btnPosition === 'bottom' && fileList.length">
                    <Btn :disabled='disabled' @delete="deleteAction" @reset="resetFile"  @download="downloadFile" :loadings="loading" :fileLists="fileList"></Btn>
                </div>
            </div>
        </template>
        <!-- 盒子模式下 -->
        <template v-if="modules === 'box'">
            <div :class="['upload-main',filePosition,modules]" ref='fileElem'>
                <div class="upload-list" v-if="(filePosition === 'top' || filePosition === 'left') && fileList.length">
                    <Btn :disabled='disabled' @delete="deleteAction" @reset="resetFile"  @download="downloadFile" :loadings="loading" :fileLists="fileList"></Btn>
                </div>
                <div :class="['upload-box',!uploadState ? 'hidden' : '',disabled?'disabled':'']" v-if='!disabled'>
                    <input :key="inputKey" class="upload-input" name='file'   :accept='accept'  @change="uploadChange" :multiple='multiple'  ref="upload"  type="file"/>
                    <div class="upload-shade btn-box" @click="handleUpload">
                        <img src="@/assets/images/addFile.png" alt="">
                        <span><slot name='上传提示'></slot></span>
                    </div>
                </div>
                <div class="upload-list" v-if="(filePosition === 'bottom' || filePosition === 'right') && fileList.length">
                    <Btn :disabled='disabled' @delete="deleteAction" @reset="resetFile"  @download="downloadFile" :loadings="loading" :fileLists="fileList"></Btn>
                </div>
            </div>
        </template>
      <!-- 区域模式下（支持拖拽上传） -->
      <template v-if="modules === 'region'">
          <div :class="['upload-main',modules]" ref='fileElem'>
            <div :class="['upload-region',disabled ? 'disabled' : '']">
                <div :class="['region-main',fileList.length ? 'hidden' : '',disabled?'disabled':'']">
                    <input :key="inputKey" class="upload-input" name='file'   :accept='accept'  @change="uploadChange" :multiple='multiple'  ref="upload"  type="file"/>
                    <div class="upload-shade region" @click="handleUpload">
                        <img src="@/assets/images/addFile.png" alt="">
                        <span><slot name='上传提示'></slot></span>
                    </div>
                </div>
                <template v-if="fileList.length">
                    <div class="upload-shade region" >
                        <Btn :disabled='disabled' @delete="deleteAction" @reset="resetFile"  @download="downloadFile" :loadings="loading" :fileLists="fileList"></Btn>
                    </div>
                </template>
            </div>
          </div>  
      </template>
  </div>
</template>

<script>
import Buttons from "@/components/course/Buttons.vue";
import checker from '@/utils/checker';
import common from '@/utils/common';
import axios from '@/utils/axios';
import Btn from './btn.vue';
import fileTypes,{fileTypeImg, resourceTypes}  from './fileTypes';

export default {
    props : {
        modules : {
            type : String,
            default : 'btn',//btn box region 
        },
        type : {
            type : String,//img file
            default : 'img'
        },
        btnPosition : {//box 模式下 按钮的位置 top  bottom
            type : String,
            default : 'bottom'
        },
        filePosition : {//按钮模式下 文件的显示位置 top  bottom left right
            type : String,
            default : 'bottom'
        },
        multiples : {//是否不允许上传多个文件 false 允许 true 不允许
            type : Boolean,
            default : false,
        },
        disabled : {//是否禁用
            type : Boolean,
            default : false,
        },
        drag : {//是否需要支持拖拽
            type : Boolean,
            default : false,
        },
        accepts : {//规定上传文件的类型
            type : Array,
            default : ()=>{return []}
        },
        mediaType: {// 支持的图片、视频文件类型
            type : Object,
            default : ()=>{return {
                img: ["jpg","png"],
                video: ["mp4"]
            }}
        },
        data : {//文件数据源
            type : Array,
            default : ()=>{return []}
        },
        uploadData : {//文件上传地址
            type : Object,
            default : ()=>{return {
                url : '',//地址
                data : {},//参数
            }},
        },
        result : {
            type : [String,Object,Array],
            default : {},
        },
        mode:{ // production test
            type : String,
            default : 'production',
        },
        clearFaces : {
            type : Boolean,
            default : true,
        },
    },
    emits : ['update:data','update:result','uploadBefore','uploadAfter','uploadChange'],
    data (){
        return {
            fileList : [],
            multiple : false,//是否不允许上传多个文件 false 允许 true 不允许
            accept : '',//规定上传文件的类型
            opType : '',//操作指令
            resetObj : {},//重置 重新选择的文件数据
            inputKey : 0,//
            uploadState : true,//上传按钮显示状态
            loading : {},//存储文件上传加载进度与状态
            resultData : [],
            cancelState : true,//用于标识上传文件的取消状态
        }
    },
    watch : {
        'fileList' : 'monitorFileList',
        'data' : 'monitorData',
        'clearFaces':{
                handler(newv,oldv){
                    if(!newv){
                        this.resultData = []
                    }
                },
                deep:true
            }
    },
    beforeMount (){
        let accepts = [];
        this.accepts.map(type=>accepts.push(fileTypes[type]));
        this.accept = accepts.join(',');//设置组件上传的文件类型
        if(this.drag)this.multiple = true;//是否支持拖拽
        else this.multiple = this.multiples;//是否允许上传多个文件
    },
    mounted (){
        if(this.drag)this.registerDragEvent();
        this.monitorData();
    },
    methods : {
        monitorData(data=this.data){
            const {result,multiples} = this;
            if(data && data.length){
                data = data.map(item=>{
                    if(!item.autoid) {
                        item.autoid = checker.uuid(8);
                    }
                    return item;
                })
            }
            
            if(result.length && !multiples)this.resultData = result;
            else if(!multiples)this.resultData = checker.cloneObj(data) || [];
            this.fileList = checker.cloneObj(data) || [];
        },
        monitorFileList (fileList){
            const {multiple,modules} = this;
            // this.inputKey ++;
            if(modules === 'region'){
                this.uploadState = false;
            }else if(!multiple){//允许上传多个文件
                this.uploadState = true;
            }else if(fileList.length >= 1){//不允许上传多个文件
                this.uploadState = false;
            }else{
                this.uploadState = true;
            }
            //this.$emit('update:data',fileList);
        },
        getFileLogo (data){//获取每个文件所对应的LOGO
            let type;
            for(const param in fileTypes){
                if(fileTypes[param] === data.type){ type = param; }
            }
            return fileTypeImg[type]
        },
        downloadFile (data){
            let a = document.createElement('a');
            a.download = data.name;//这边是文件名，可以自定义
            a.href = data.imgUrl;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a); 
        },
        deleteAction : function (data){//删除文件
      
            const {fileList,resultData} = this;
            let delIdx = fileList.findIndex(item=>item.key === data.key);
            if(delIdx < 0)return;
            if(fileList[delIdx]){
                let delFileIdx = resultData.findIndex(item=>item.autoid === fileList[delIdx].autoid);
                if(delFileIdx>=0)resultData.splice(delFileIdx,1);
                this.$emit('update:result',[...resultData]);
            }
            fileList.splice(delIdx,1);
            this.$emit('update:data',[...fileList]);
            this.fileList = [...fileList];
            // console.log(fileList)
            this.resultData = [...resultData];
            this.$emit('uploadChange',this.resultData);
            // console.log(resultData)
        },
        registerDragEvent(){//注册拖拽事件
            const {$refs} = this;
            let elem = $refs.fileElem;
            if(!elem)return;
            elem.addEventListener('dragover',this.handleDragover,false);
            elem.addEventListener('drop',this.handleDrop,false);
        },
        handleDragover (e){//当拖拽目标离开目标区域时触发
            e.stopPropagation();
            e.preventDefault();
            e.dataTransfer.dropEffect = 'copy';
        },
        handleDrop (e){
            e.stopPropagation();
            e.preventDefault(); 
            let files = e.dataTransfer.files[0];
            this.opType = 'add';
            this.uploadChange([files]);
        },
        resetFile : function (file){//重置 重新选择文件
            const {disabled} = this;
            if(disabled)return;
            this.opType = 'reset';
            this.handleUpload();
            this.opType = 'reset';
            this.resetObj = file;
        },
        handleUpload (){//调用上传文件组件
            const {disabled} = this;
            if(disabled)return;
            let sign = true;
            if(this.opType!=='reset')this.$emit('uploadBefore',success=>{ sign = success; });
            if(!sign)return;
            this.opType = 'add';
            let upload = this.$refs.upload,
            events = document.createEvent("MouseEvents");
            events.initEvent("click", true, true);
            if(upload)upload.dispatchEvent(events);
            this.registerFocus();
            
        },
        registerFocus (){//用于监听上传文件时用户的取消动作
            window.addEventListener('focus',this.handleFocus)
        },
        handleFocus (){
            setTimeout(()=>{
                const {$refs:refs,cancelState} = this;
                let inputEle,files = [];
                if(refs.upload && cancelState){
                    inputEle = refs.upload;
                    files = inputEle.files[0];
                    if(!files){
                        this.opType = '';
                    }
                }
                this.cancelState = true;
                window.removeEventListener('focus',this.handleFocus)
            },500)
        },
        uploadChange (data){
            const {opType} = this;
            let file = this.getFile(data);
            if(file.length===0)return;//预防空数组导致异常
            file = Array.from(file);
            let sign = true;
            this.inputKey ++;
            this.$emit('uploadAfter',{data:file[0],cb:success=>{ sign = success; }});
            if(!sign)return;
            this.cancelState = false;
            switch (opType){
                case 'add' : //添加文件
                    this.isImg(file,()=>{
                        this.addFileAction(file);
                    });
                    break;
                case 'reset' : //重置文件
                    this.isImg(file,()=>{
                        this.resetFileAction(file);
                    });
                    break;
            }
            this.opType = '';
        },
        isImg : function(data,callback){//检查是否是图片
            const [file] = data;
            let sign = false;
            this.mediaType.img.map(key=>{if(fileTypes[key] === file.type)sign = key;});

            if(!sign)return callback && callback();
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function (e){
                let base64 = this.result;
                var mb = (e.total/1024)/1024;// 单位/M
                data[0].imgUrl = base64;
                if(callback)callback();
            };
        },
        addFileAction : function (data){
            const {fileList} = this;
            const [file] = data;
            file.key = checker.uuid(16);
            fileList.push(file);
            this.fileList = [...fileList];
            this.addFileInterface(file,result=>{
                this.interfaceBack(file,result);
                
            });
        },
        resetFileAction :function (data){
            const {resetObj,fileList} = this;
            const [file] = data;
            const idx = fileList.findIndex(item=>item.key === resetObj.key);
            file.key = resetObj.key;
            file.autoid = resetObj.autoid;
            fileList[idx] = file;
            this.fileList = [...fileList]
            this.addFileInterface(file,result=>{
                this.interfaceBack(file,result);
            });
        },
        interfaceBack (file,result={}){
            const {fileList,resultData} = this;
            // 设置进度条
            const idx = fileList.findIndex(item=>item.key === file.key);
            const resultIdx = resultData.findIndex(item=>item.autoid === file.autoid);
            if(resultIdx>=0)resultData[resultIdx] = result;
            else resultData.push(result);
            file.autoid = result.autoid || '';
            if(idx>=0)fileList[idx] = file;
            this.$emit('update:result',[...resultData]);
            this.$emit('update:data',[...fileList]);
            this.$emit('uploadChange',[...resultData]);
            this.fileList = [...fileList];
            this.resultData = [...resultData];
        },
        addFileInterface (data,func){//调用上传接口
            const {uploadData,mode} = this;
            if(mode === 'test' && func)return func()//测试组件 不需要调用接口
            // let sign = true;
            // this.$emit('uploadAfter',success=>{ sign = success; });
            // if(!sign)return;
            
            if(!uploadData.url)return console.warn('上传地址不能为空');

            let request = new FormData(),getData = {};
            if(uploadData.data){//额外的post请求参数
                for(const param in uploadData.data){
                    request.append(param,uploadData.data[param]);
                }
            }
            
            if(uploadData.postBack){//post 请求回调
                let postData = uploadData.postBack({...data,filename:data.name.split('.')[0]}) || {};
                if(Object.prototype.toString(postData) === '[object Object]'){
                    for(const param in postData){
                        request.append(param,postData[param]);
                    }
                }
            }
            getData["type"] = this.getResourceType(this.getFileType(data.type));
            if(uploadData.getBack){// git 请求回调 额外的git 请求参数
                let cache = uploadData.getBack(data,this.getFileType(data.type)) || {};
                if(Object.prototype.toString(cache) === '[object Object]'){
                    getData = cache;
                }
            }
            request.append(data.name, data);
            //request.append("profile", true);
            // console.log(request)
            this.loading[data.key] = {};
            this.loading[data.key].state = true;
            this.loading = common.cloneObj(this.loading);
            axios.post(uploadData.url + checker.objTurnParams(getData),request,{
                timeout : 1000 * 60 * 60 * 24,
                onUploadProgress : (progressEvent)=>{
                    this.loading[data.key].percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    this.loading.percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    this.loading = common.cloneObj(this.loading);
                }
            }).then(result=>{
                if(result && result.status){
                    this.loading[data.key].state = false;
                    if(func)func(result.data[0]);
                    this.$message({message: '上传成功！',type: 'success',duration:1000});
                }else {
                    this.loading[data.key].state = false;
                    this.$message({message: '上传失败！',type: 'warning',duration:1000});
                } 
            })
        },  
        getFileType (type){//获取文件类型
            for(const param in fileTypes){ if(fileTypes[param] === type)return param; }
        },
        getResourceType(type) {
            let result;
            resourceTypes.forEach((resType, idx) => {
                if(resType.types.indexOf(type) != -1) {
                    result = resType.name;
                    return;
                }
            });
            return result || "other";
        },
        getFile (data){
            if(data.srcElement){
                return data.srcElement.files;
            }else {
                return data;
            }
        }
    },
    
    components : {
        Buttons,
        Btn,
    },
}
</script>

<style scoped lang="less">
    .upload-ui .upload-main .upload-box .upload-shade.btn-box{
        width:150px;
        height:150px;
        flex-direction: column;
        >span{
            margin-top: 10px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            word-break: break-all;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 18px;
            padding: 0 10px;
            text-align: justify;
        }
    }
    .upload-ui{
        .upload-main.box{
            position:relative;
            ::v-deep .upload-li{
                width: 150px;
                height: 150px;
                flex: 0 0 150px;
            }
            ::v-deep .upload-file>span{
                -webkit-line-clamp: 2;
                line-height: 16px;
            }
        }
        .upload-main{
            text-align: left;
            &.left,&.right{
                display: flex;
                .upload-list{
                    padding-bottom: 0;
                    ::v-deep .upload-li{
                        margin-top: 0;
                    }
                }
            }
            &.right{
                .upload-box{
                    margin-right:10px;
                }
            }
            .upload-list{
                display: flex;
                flex-wrap:wrap;
                padding-bottom: 10px;

                // background-color: #CCE8FF;
                // border-radius: 4px;
                
            }
            .upload-btn{
                position: relative;
                width: auto;
                height: 34px;
                display: inline-block;
                &.disabled{
                    cursor: not-allowed;
                }
                &.hidden{
                    // position:absolute;
                    // z-index:-1;
                }
                .upload-input{
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 1;
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                }
                >span{
                    display: inline-block;
                    position: relative;
                    z-index: 2;
                    >span{
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 12px;
                        margin-left:10px;
                        display: inline-block;
                        padding: 10px 0;
                    }
                }
            }

            .upload-box{
                position: relative;
                width: auto;
                display: inline-block;
                border: 1px dashed #d9d9d9;
                border-radius: 4px;
                cursor: pointer;
                &.disabled{
                    cursor: not-allowed;
                }
                &.hidden{
                    position:absolute;
                    z-index:-1;
                }
                &:hover{
                    border-color: #409eff;
                }
                .upload-input{
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 1;
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                }
                .upload-shade{
                    position: relative;
                    z-index: 2;
                    width:100px;
                    height:100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            
            .upload-region{
                width:100%;
                position: relative;
                height:100%;
                border: 1px dashed #d9d9d9;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                cursor: pointer;
                background-color: #FFFFFF;
                &.disabled{
                    border: none;
                    cursor:auto;
                }
                .region-main{
                    width:100%;
                    display: flex;
                    &.disabled{
                        cursor: not-allowed;
                    }
                    &.hidden{
                        position: absolute;
                        z-index:-1;
                    }
                }
                &:hover{
                    border-color: #409eff;
                }
                .upload-input{
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 1;
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                }
                 .upload-shade{
                    position: relative;
                    z-index: 2;
                    height:200px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    flex:1 1 auto;
                    >span{
                        margin-top:20px;
                        color: #B6B6B6;
                    }
                }
                .upload-shade.region{
                    width:100%;
                    height:100%;
                    position:relative;
                    text-align:center;
                    >span{
                        margin-top:10px;
                        line-height: 20px;
                        width:80%;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #69759C;
                        word-break: break-all;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                    ::v-deep .upload-li {
                        width:100%;
                        height:100%;
                        flex:0 0 auto;
                        margin:0;
                    }
                    ::v-deep .upload-file {
                        box-shadow:none;
                    }
                    ::v-deep  .upload-file>img {
                        width: 50px;
                        height: auto;
                    }
                    ::v-deep  .upload-file>span {
                        line-height: 16px;
                        -webkit-line-clamp: 2;
                    }
                    >img{
                        width: 55px;
                    }
                    >i{
                        position: absolute;
                        right: -10px;
                        top: -10px;
                        z-index: 5;
                        display: inline-block;
                        line-height: 16px;
                        width:25px;
                        height:25px;
                        >svg{
                            width:100%;
                            height:100%;
                        }
                    }
                }
            }
        }

        .upload-main.region{
            height:100%;
        }
    }
</style>