<template>
  <div class="evaluate-details">
      <PageHeader :goMethod='goMethod'>
          <template #content>
              <div v-if="data" class="evaluate-main">
                <span v-if="data.title" class="evaluate-context">
                    {{data.title || ""}}
                </span>
                 <span v-if="!data.title" class="evaluate-context" style="margin-bottom:20px;">
                  
                   <span v-if="!!topic"> {{topic.name ||""}}</span>
                      <span class="evaluate-publishdate"  v-if="!!topic">发布于 {{topic.createtime}} {{topic.childsize || 0}}人回答</span>
                      <span  v-if="!!topic" class="evaluate-content" style="display:block;" v-html="topic.content"></span>
                </span>
               
                <span class="evaluate-publishdate">{{data.name}}·发布于 {{data.time}} {{data.childsize || 0}}人回答</span>
                <span class="evaluate-content" v-html="data.content"></span>
                <div class="evaluate-media">
                    <div class="video-shade" @click="handleMedia({url:video.url,type:'video'})" v-for="video in videos" :key="video.key">
                        <video :src="video.url"></video>
                        <div class="video-bg">
                            <span ><i class="xxl xxl-video-start"></i></span>
                        </div>
                    </div>
                    <img @click="handleMedia({url:img.url,type:'img'})" :src="img.url" alt="" v-for="img in imgs" :key="img.key">
                </div>
                <div class="answer-video" v-if="mediaData.type">
                    <Videos v-if="mediaData.type === 'video'" :videoUrl="mediaData.url"></Videos>
                    <img v-if="mediaData.type === 'img'" :src="mediaData.url" alt="">
                </div>
                <div class="evaluate-option">
                    <span>
                        <Uploads 
                            v-model:data='uploadData'
                            v-model:result='cache.faces' 
                            modules="btn" 
                            btnPosition="bottom" 
                            mode="production" 
                            class="resources-upload"
                            :accepts='["jpg","png","mp4"]' 
                            ref="upload"
                            @uploadBefore='uploadBefore' 
                            @uploadAfter='uploadAfter' 
                            @uploadChange='uploadResourceAction' 
                            :uploadData='uploadResource'
                            :mediaType="mediaType"
                            :clearFaces='clearfaces'
                            >
                            <template #btnName>上传图片/视频</template>
                            <template #上传提示>图片和视频数量不得超过10个 图片大小不得超过5M，视频大小不得超过50M</template>
                        </Uploads>
                    </span>
                    
                    <el-input maxlength="300" rows="5" resize="none" type="textarea" :show-word-limit='true' size='small' v-model="valueVal" placeholder="请输入回复" />
                    <div class="evaluate-btns">
                        <!--<Buttons type="info" class="evaluate-btn">取消</Buttons>-->
                        <Buttons type="blue" class="evaluate-btn" @click="handleUpload">发布</Buttons>
                    </div>
                </div>
                <div class="evaluate-reply" :key="answerKey">
                    <Answer @toClick="handleAnswer" :options='options' @load='handleLoad'
                        :parentid="data.key"
                        :floor="floor" :getFloorData="getFloorData" :getFloorCount="getFloorCount"
                        :uploadFloorData="uploadFloorData" :uploadData='uploadResource'/>
                </div>
            </div>
          </template>
          
      </PageHeader>
  </div>
</template>

<script>
import PageHeader from '@/components/course/PageHeader.vue';
import Videos from '@/components/course/Videos.vue';
import Answer from '@/components/course/Answer.vue';
import Buttons from '@/components/course/Buttons.vue';
import Uploads from '@/components/common/Uploads/Uploads.vue';

import axios from '@/utils/axios';
import server from '@/service/server';
import checker from '@/utils/checker';
import { ElMessage } from "element-plus";

export default {
name: 'Details',

    data (){
        return {
            // 回复组件动态id
            topic:{name:""},
            answerKey: 0,
            clearfaces:true,
            uploadData : [],
            cache : {},
            imgs : [],
            uploadResource : {
                url : server.upload,
                data : {}
            },
            // paging : {
            //     total : 0,
            //     pageSize : 10,
            //     currentPage : 1,
            // },
            videos : [],
            mediaData : {},
            mediaType: {
                img: ["jpg","png"],
                video: ["mp4"]
            },
            answerCount: 0,
            uploadUrl: [],
            answerData: [],
            valueVal : '',
            options : {//评论组件的功能
                delete : this.handleDelete,
                release : this.handleRelease,
                update : this.handleUpdate,
                closeUpdate : false,
                showReply : true,
                closeDelete : false,
                showChildBelow: true,
            },
           
            floor: 2,
            floorData: {
                2: {
                    api: server.answer,
                    parentid: this.parentField,
                    childsize: "evaluate_size",
                    extraParam: {}
                },
                1: {
                    api: server.evaluate,
                    parentid: "answerautoid",
                    extraParam: {}
                }
            },
        }
    },
    watch : {
        'data' : 'monitorData',
 
    },
    mounted (){
        this.formatData(this.data);
        // this.ismonitor();
       
    },
    created(){
        
    },
    methods : {
        monitorData (data){
            this.formatData(data);
        },
        formatData (data){
            this.getquestion(data);
            this.answerKey++;
            this.imgs = [];
            this.videos = [];
            this.mediaData = {};
            if(data.imgs || data.videos){
                this.imgs = data.imgs || [];
                this.videos = data.videos || [];
                if(data.videos.length){
                    this.mediaData = {...data.videos[0],type:'video'}
                }
                else if(data.imgs.length){
                    this.mediaData = {...data.imgs[0],type:'img'}
                }
            }
            
        },
        getquestion(data){
            if(data.title==''){
                let request = {
                    autoid:data.key,
                    get_all:true
                }
                axios.get(server.uiissue + checker.objTurnParams(request)).then(result => {
                    if(result&& result.data){
                        this.topic = result.data.result[0]
                    }
                })
            }
        },
       
        handleAnswer (){

        },
        handleDelete (){

        },
        handleRelease (){

        },
        handleUpdate (){

        },
        handleLoad (){

        },
        uploadAfter (data){
            switch(data.data.type){
                case 'image/png':
                    if(data.data.size / 1024 / 1024 > 5){
                        ElMessage.warning("图片大小不得超过5M");
                        data.cb(false);
                    }
                    break;
                case 'image/jpeg':
                    if(data.data.size / 1024 / 1024 > 5){
                        ElMessage.warning("图片大小不得超过5M");
                        data.cb(false);
                    }
                    break;
                case 'video/mp4':
                    if(data.data.size / 1024 / 1024 > 50){
                        ElMessage.warning("视频大小不得超过50M");
                        data.cb(false);
                    }
                    break;
                default :
                    ElMessage.warning("不支持该类型");
                    data.cb(false);
            }
        },
        uploadBefore (cb){
            const {cache} = this;
            if(cache.faces && cache.faces.length>=10){
                ElMessage.warning("图片和视频上传已达上限！");
                cb(false);
            }else{
                this.clearfaces = true;
            }
        },
        uploadResourceAction (data){
            if(data && data instanceof Array && data.length && data.slice(-1)[0].url) {
                this.uploadUrl.push(data.slice(-1)[0].url);
            }
        },
        getFloorData(floor, paging, parentid, callback) {
            let floorData = this.floorData[floor];
            let request = { 
                page: paging.currentPage, 
                per_page: paging.pageSize, 
                get_all: "", 
                ...floorData.extraParam,
                _order_column:"createtime",
                _order_style:"desc",
            };
            request[floorData.parentid] = parentid;
            axios.get(floorData.api + checker.objTurnParams(request)).then(result => {
                let answerData = [];
                if (result.status && result.data && result.data.result) {
                    result.data.result.forEach((item) => {
                        let answer = {
                            key: item.autoid,
                            name: item.user_data ? item.user_data.realname || item.user_data.username : "",
                            iconUrl: item.user_data ? item.user_data.face || require("@/assets/images/user_logo.png") : "",
                            time: item.createtime,
                            content: item.content,
                            imgs: [],
                            videos: [],
                            children: [],
                            star: item.star,
                            isClose: true
                        };
                        if(floorData.childsize) answer.childsize = item[floorData.childsize] || 0;
                        if(item.evalresource_data && item.evalresource_data.filepath) {
                            item.evalresource_data.filepath.split(",").forEach((path, fIndex) => {
                                let ext = path.split(".").pop();
                                if(this.mediaType.img.indexOf(ext) != -1) {
                                    answer.imgs.push({
                                        "url": path,
                                        "key": fIndex + 1
                                    });
                                } else if(this.mediaType.video.indexOf(ext) != -1) {
                                    answer.videos.push({
                                        "url": path,
                                        "key": fIndex + 1
                                    });
                                }
                            });
                        }
                        answerData.push(answer);
                    });
                }
                checker.isFunction(callback) && callback(answerData);
            });
        },
        uploadFloorData(floor, parentid, param, callback) {
            let floorData = this.floorData[floor];
            let request = { ...param };
            request[floorData.parentid] = parentid;
            axios.post(floorData.api, request).then(result => {
                if(result.status && result.data) {
                    checker.isFunction(callback) && callback(result.data);
                }
            });
        },
        getFloorCount(floor, parentid, callback) {
            let floorData = this.floorData[floor];
            let request = { count: true, get_all: "", ...floorData.extraParam };
            request[floorData.parentid] = parentid;
            axios.get(floorData.api + checker.objTurnParams(request)).then(result => {
                let count = 0;
                if (result.status) {
                    count = result.data;
                }
                callback(count);
            });
        },
        pageChange (page){
            this.paging.currentPage = page;
            this.getAnswer();
        },
        goMethod (){
            this.$emit('toBack')
        },
        handleMedia (data){
            this.mediaData = data;
        },
        handleUpload() {
            let filePath = this.uploadUrl.join(",");
            let request = { expautoid: this.expautoid, content: this.valueVal, filepath_str: filePath };
            request[this.parentField] = this.data.key;
            if(this.valueVal != ''){
                axios.post(server.answer, request).then(result => {
                    if ( result && result.status) {
                        ElMessage.success("操作成功！");
                        this.answerKey++;
                        this.data.childsize++;
                        this.$emit('updateRate');
                        this.valueVal = "";
                        this.uploadUrl = [];
                        this.$refs.upload.fileList = [];
                        this.cache.faces = [];
                        this.clearfaces = false;
                    }
                });
            }else{
                ElMessage.warning("回复内容不能为空！");
            }
            
        }
    },
    components : {
        PageHeader,
        Videos,
        Answer,
        Buttons,
        Uploads,
    },
    props : {
        data : {
            type : Object,
            default : ()=>{return {}}
        },
        expautoid: {
            type: [Number, String],
            default: 0
        },
        parentField: {
            type: String,
            default: ""
        },
    },
}
</script>

<style scoped lang="less">
    .evaluate-reply{
        width:100%;
        .answer-page{
            width:100%;
            text-align:center;
            padding:20px 0 10px;
        }
    }
    .evaluate-details{
        width:100%;
        .evaluate-main{
            padding: 10px;
            display: flex;
            flex-direction: column;
            text-align: left;
            .answer-video{
                height:300px;
                border-radius: 4px;
                >img{
                    height:100%;
                }
            }
            .evaluate-option {
                margin:20px 0;
                .evaluate-btn{
                    margin-bottom: 10px;
                }
                .evaluate-btns{
                    margin-top:10px;
                    text-align: right;
                }
                >span{
                    display: inline-block;
                    padding:10px 0;
                }
            }
            .evaluate-context{
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #1A1A1A;
                line-height: 28px;
                word-break: break-all;
            }
            .evaluate-publishdate{
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                padding:10px 0;
            }
            .evaluate-content{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                line-height: 25px;
                // text-indent: 30px;
                text-align: justify;
                word-break: break-all;
            }
            .evaluate-media{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                padding:10px 0;
                >img, >.video-shade>video {
                    width:130px;
                    height: 78px;
                    border-radius: 4px;
                    margin-right:10px;
                    cursor: pointer;
                    margin-bottom: 10px;
                    &:last-child{
                        margin-right:0;
                    }
                }
                .video-shade{
                    position: relative;
                    width:130px;
                    height: 78px;
                    margin-right:10px;
                    cursor: pointer;
                    margin-bottom: 10px;
                    .video-bg{
                        position: absolute;
                        left:0;right:0;
                        top:0;bottom: 0;
                        background-color: rgba(0,0,0,.3);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        >span{
                            width: 35px;
                            height: 35px;
                            border-radius: 50%;
                            background-color: rgba(255,255,255,.5);
                            border:2px solid #FFFFFF;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            >i{
                                font-size: 20px;
                                margin-right:0;
                                color:#FFFFFF;
                            }
                        }
                    }
                    >img{
                        width:100%;
                        height:100%;
                        border-radius: 4px;
                    }
                }
            }
        }
    }

</style>