import Vuex from 'vuex'
import user from './modules/user'
import course from './modules/course';
import common from './modules/common';
const store = new Vuex.Store({
  modules: {
    user,
    course,
    common,
  }, 
})

export default store
