<template>
    <!-- 解决方案 -->
    <layout_20230210_47e9>

        <template #title>解决方案</template>
        <template #describe>
            <span>{{ describe }}</span>
        </template>
        <div class="solution_main">
            <div class="solution-ui">
                <div class="solution-left">
                    <div @click="handleClick('切换', item)" :class="['solution-item', active === item.autoid ? 'active' : '']"
                        v-for="item in solution_title_data" :key="item.key">
                        <img class="item-line" :src="`${base_url}images/home/solution_line.png`" alt="解决方案分割线">
                        <span class="item-label">{{ item.title }}</span>
                    </div>
                </div>


                <div class="solution-right">
                    <span class="solution-title">{{ current_content.subtitle }}</span>
                    <div class="solution-describe">
                        <span class="describe nnnss">
                            {{ current_content.abstract }}
                        </span>
                    </div>
                    <div class="solution-list">
                        <div class="solution-item" v-for="_item_ in current_content.expattachment" :key=_item_.key>
                            <div class="item-shade">
                                <span class="item-title" @click="file_download(_item_)">
                                    <img class="item-title-logo" :src="`${base_url}images/home/pre.png`" alt="">
                                    <span>{{ _item_.filename.trim() }}</span>
                                </span>
                                <span class="item-time">{{ _item_.createtime }} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <education></education> -->
    </layout_20230210_47e9>
</template>

<script>
import layout_20230210_47e9 from '@/components/layouts/layout_20230210_47e9.vue';
import education from './education.vue';
import axiosTool from '@/utils/axios_tool';
import server from "@/service/server";
export default {
    components: {
        education,
        layout_20230210_47e9,
        axiosTool
    },
    name: "solution",
    props: {

    },
    mounted() {
    },
    created() {
        this.axiosTool = new axiosTool(this, [
            {
                key: 'get_solution', type: 'get', title: '获取所有的解决方案', url: server.coverage, params: {
                    getreq: { get_all: true, typeid: 2 },
                }
            },
            {
                key: 'get_solution_describe', type: 'get', title: '获取解决方案的概述', url: server.coverage, params: {
                    getreq: { autoid: 38 },
                }
            }
        ])
    },
    mounted() {
        this.axiosTool._axios().then(result => {
            const [get_solution, get_solution_describe] = result;
            this.handle_solution(get_solution)          // 获取所有的解决方案
            this.get_solution_describe(get_solution_describe)     // 获取解决方案的概述
            this.$nextTick(this.describeMonitor)
        })
    },
    data() {
        return {
            current_content: {},
            solution_title_data: [],
            active: '',
            describe: '',
        }
    },
    methods: {
        get_solution_describe(res) {
            if (res.status && res.result.length >= 1) {
                this.describe = res.result[0].abstract;
            }
        },
        file_download(data) {
            window.open(data.url)
        },
        describeMonitor() {
            const d_dom = document.querySelector('.describe.nnnss')
            window.$clamp(d_dom, { clamp: 'auto' })
            let _h = d_dom.clientHeight;
            let a = _h/30;
            console.log(a);
            d_dom.style.height = (Math.floor(a)  * 30)+ "px";
        },
        handle_solution(data) {
            if (data.status && data.data && data.data.result) {
                this.solution_title_data = data.data.result
                if (this.solution_title_data.length) {
                    this.active = this.solution_title_data[0].autoid;
                }

                this.current_content = this.solution_title_data[0] || {}
                this.solution_title_data.map(item => {
                    try {
                        item.expattachment = JSON.parse(item.expattachment)
                        item.expattachment = item.expattachment.slice(0, 3).map(o => {
                            o.filename = o.filename.trim()
                            o.createtime = o.createtime.split(' ')[0];
                            return o;
                        });

                    } catch (e) {
                        item.expattachment = []
                    }

                })

            }
            // console.log(this.solution_title_data)
        },
        handleClick(type, data) {
            if (typeof (data) === "object") {
                this.current_content = data
            }
            switch (type) {
                case '切换':
                    this.active = data.autoid;
                    setTimeout(() => {
                        this.describeMonitor()
                    }, 100)
                    break;
            }
        }
    },
}
</script>


<style scoped lang="less">
@import url('@/assets/less/theme.less');

.solution_main {
    margin-bottom: 55px;
}

.solution-ui {
    display: flex;
    box-shadow: @global_box_shadow;
    // padding: 10px;
    background-color: #FFFFFF;

    .solution-left {
        flex: 0 0 auto;
        background-color: #2F354B;

        .solution-item {
            background-color: #2F354B;
            position: relative;
            color: #FFFFFF;
            padding: 0 20px;
            cursor: pointer;

            .item-line {
                position: absolute;
                bottom: 0;
                left: 20px;
                right: 20px;
                height: 1px;
                width: 90%;
            }

            .item-label {
                padding: 23px 0;
                font-size: 18px;
                display: inline-block;
            }
        }

        .solution-item:last-child {
            .item-line {
                display: none;
            }
        }

        .solution-item.active {
            position: relative;
            background-color: @global_background;

            .item-line {
                display: none;
            }
        }

        .solution-item.active::after {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            width: 0;
            height: 0;
            content: '';
            border: 6px solid #FFFFFF;
            border-top-color: transparent;
            border-left-color: transparent;
            border-bottom-color: transparent;
        }
    }

    .solution-right {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        padding: 45px 40px;

        .solution-title {
            flex: 0 0 auto;
            color: #494949;
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 20px;
        }

        .solution-describe {
            position: relative;
            flex: 1 1 auto;
            color: #8A8A8A;
            font-size: 18px;
            line-height: 30px;
            text-align: justify;

            .describe {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;

                .nnnss {
                    display: inline-block;
                    height: 100%;
                    width: 100%;
                }
            }


        }

        .solution-list {
            flex: 0 0 auto;
            display: flex;
            margin-top: 20px;
            margin-bottom: 0;

            .solution-item {
                position: relative;
                flex: 1 1 auto;
                height: 116px;
                border: 1px solid #AFAFAF;
                margin-right: 20px;

                .item-shade {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    padding: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    .item-title {
                        line-height: 25px;
                        text-align: justify;
                        cursor: pointer;

                        .item-title-logo {
                            height: 15px;
                            position: relative;
                            top: 2px;
                            margin-right: 5px;
                        }
                    }

                    .item-time {
                        color: #AFAFAF;
                    }
                }
            }

            .solution-item:hover {
                border-color: #F18D00;

                .item-shade .item-time {
                    color: #F18D00;
                }

                .item-title {
                    color: #F18D00;
                    // text-decoration: underline;
                }
            }

            .solution-item:last-child {
                margin-right: 0;
            }
        }
    }
}</style>