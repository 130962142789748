<template>
    <!-- 消息中心 -->
    <div class='my-scenter'>
        <div class="my-title">
          <span>消息中心</span>
          <span>未读消息共{{un_readMessageCount}}条</span>
          <div class="my-search">
                <Buttons type="blue" icon="xxl-eliminate" @click="clear_readed()">清除未读</Buttons>
          </div>
        </div>
        <div class='scenter-main'>
            <div class='scenter-item' @click='handleClick(item)' v-for='item in dataList' :key='item.key'>
                 <span> <el-badge :is-dot="item.read=='false'">【{{item.style}}】 </el-badge> <span style="padding-left:2px;">{{item.title}} </span></span>
               
                <span>{{item.createtime}}</span> 
            </div>
            <div class="scenter-pagin">
                <Pagination v-if="dataList.length"  :total='paging.total || 0' :pageSize='paging.pageSize || 10' 
                :currentPage='paging.currentPage || 1' @pageChange='pageChange' />
            </div>
        </div>
        <MessageBoxs :style='popupData.style' :title="popupData.title || '弹窗标题'" :state='popupData.state' @close="closePopup">
            <div class='scenter-popup'>
                <span class='scenter-title'>{{popupData.data && popupData.data.title}}</span>
                <span class='scenter-content' v-html="popupData.content"></span>
            </div>
            <template #footer>
                <Buttons type="primary" @click="closePopup(popupData)">我知道了</Buttons>
            </template>
        </MessageBoxs>
    </div>
</template>
<script>
import MessageBoxs from '@/components/course/MessageBoxs.vue';
import Buttons from '@/components/course/Buttons.vue';
import Pagination from '@/components/course/Pagination.vue';
import axios from '@/utils/axios';
import axiosTool from '@/utils/axios_tool';
import server from "@/service/server";
import checker from '@/utils/checker';
import { toRaw } from "@vue/reactivity";
import { ElMessage } from 'element-plus';


export default {
    data (){
        return {
            un_read_autoid:[],
            un_readMessageCount:0,
            // tableParams:{
            //     currentPage:1,
            //     pageSize:20,
            // },
            searchData : {},
            popupData : {},
            dataList : [],
            paging : {
                total : 0,
                pageSize : 10,
                currentPage : 1,
            },
        }
    },
    created(){},
    mounted() {
        this.get_messageList();
        // this.get_unreadCount();
        this.get_unreadList();
    },
    watch : {
     
    },
    methods : {
        get_unreadList(){
            let request = {
                get_receive:true,
                _order_column:"createtime",
                _order_style:"desc",
                read:false
            };
            axios.get(server.message + checker.objTurnParams(request)).then(result=>{
                if(result.status && result.data && result.data.result){
                    let resultdata = result.data.result;
                    this.un_read_autoid = resultdata.map(item=>{
                        if(item){
                            return item.autoid
                        }
                    })
                }
            })
            this.get_unreadCount();
        },
        clear_readed(){
            let message_id = toRaw(this.un_read_autoid),
                request = {autoids:message_id,put_data:true,read:true};
            if(message_id.length){
                axios.post(server.message,request).then(result=>{
                    if(result.msg=='success'){
                        this.get_unreadList();
                        // this.get_messageList_count();
                        this.get_messageList();
                    }
                })
            }else{
                ElMessage.warning('暂无未读消息！')
            }

        },
        get_unreadCount(){
            let request = {read:false,count:true,get_receive:true};
            axios.get(server.message + checker.objTurnParams(request)).then(result=>{
                if(result.status){ 
                    this.un_readMessageCount = Number(result.data);
                }
            })
        },
        get_messageList(){
            let {paging} = this,
            request = {
                per_page:paging.pageSize,
                page:paging.currentPage,
                get_receive:true,
                _order_column:"createtime",
                _order_style:"desc"
            };
            axios.get(server.message + checker.objTurnParams(request)).then(result=>{
                if(result.status && result.data && result.data.result){
                    let dataa = result.data.result;
                    this.dataList = dataa;
                }
            })
            this.get_messageList_count();
        },
        get_messageList_count(){
            let request = {count:true, get_receive:true};
            axios.get(server.message + checker.objTurnParams(request)).then(result=>{
                if(result.status && result.data){
                    this.paging.total = Number(result.data) 
                }
            })
        },
        post_read_message(data){
            let message_id = data.autoid,
            request = {autoid:message_id,put_data:true,read:true};
            axios.post(server.message ,request).then(result=>{})
        },
        closePopup (data){
            this.get_messageList();
            this.get_unreadList();
            this.popupData.state = false;
        },
        handleClick (data){
            data.read = 'true';
            this.post_read_message(data);
            this.popupData = {
                title : '消息通知',
                state : true,
                data : data,
                style : {width:'800px'},
                content:data.content,
                message_id:data.autoid,
            };
         
        },
        pageChange (currentPage){
            this.paging.currentPage = currentPage;
            this.get_messageList();
            this.get_unreadList();
            // this.get_unreadList();
        },
    },
    components : {
        Buttons,
        Pagination,
        MessageBoxs,
        axiosTool,
        server,
        checker
    },
}
</script>

<style scoped lang='less'>
    .scenter-popup{
        width:100%;
        >span{
            display: inline-block;
        }
        .scenter-title{
            width: 100%;
            font-size: 16px;
            color: #333333;
            text-align: center;
            font-weight: bold;
            padding: 10px 0;
        }
        .scenter-content{
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            line-height: 25px;
            text-align: justify;
            text-indent: 30px;
            height: 480px;
            overflow: auto;
        }
    }
    .scenter-pagin{
        padding:20px 0;
        text-align:center;
        flex: 1 1 auto;
    }
    .my-scenter{
        width:100%;
        height:100%;
    }
    .my-title{
        width:100%;
        height:53px;
        display: flex;
        align-items: center;
        justify-items: center;
        flex:0 0 53px;
        border-bottom: 1px solid #F5F5F5;
        >span{
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #1A1A1A;
           
        }
        >span:nth-child(2){
             font-size: 15px;
             font-family: Microsoft YaHei;
             display: inline-block;
             margin-left: 10px;
             color: grey;
             font-weight: normal;
        }
        .my-search{
            display: flex;
            align-items: center;
            margin-left:auto;
            .my-search-input{
                margin-right:10px;
            }
        }
    }
    .scenter-main{
        width:100%;
        .scenter-item{
            display: flex;
            padding: 20px 0;
            border-bottom: 1px solid #F5F5F5;
            cursor:pointer;
            &:hover{
                >span:nth-child(1){
                    color:rgba(41,116,234,.7);
                }
            }
             >span:nth-child(1){
                margin-right:auto;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #1A1A1A;
             }
             >span:nth-child(2){
                 margin-left:auto;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
             }
        }
    }
</style>