<template>
    <div class='login-ui'>
      
        <div class='login-main'>
             <span class='login-logo'>
                <img :src="base_url + 'images/platform_logo_ico.png'"  alt="虚拟仿真实验教学共享平台" style="height:100px">
                <!-- <span></span> -->
            </span>
            <span class='login-title'>
                {{_globalJson.title}}
            </span>
            <div :class="['login-body',viewState.state==='注册_1'?'register-body1':'',viewState.state==='注册_2'?'register-body2':'']">
                <div class="pannel_tab">
                 <el-tabs v-model="activeTab" @tab-click="handChange">
                    <el-tab-pane label="登录" name="first"></el-tab-pane>
                    <el-tab-pane label="注册" name="second"></el-tab-pane>
                 </el-tabs>
                </div>
                <!-- <span class='body-title'>{{viewState.title}}</span> -->
                
                <!-- <template v-if="viewState.title==='用户注册'">
                    <el-steps class='login-state' :active="viewState.step" align-center>
                        <el-step v-for='item in registerData' :key='item.key'>
                            <template #icon>
                                <span v-if='viewState.step > item.value' :class="['register',viewState.step>item.value?'finish':'']"></span>
                                <span v-else :class="['register',viewState.step==item.value?'action':'']">{{item.label}}</span>
                            </template>
                            <template #title>
                                <span :class="['register-label',viewState.step>=item.value?'action':'']">{{item.title}}</span>
                            </template>
                        </el-step>
                    </el-steps>
                </template> -->
                <!-- <span class='login-input' v-if="['注册_1','登录'].indexOf(viewState.state)>=0">
                    <el-input size='large' v-model="formData.username" placeholder="用户名必须是4-16位大写字母、小写字母或数字">
                        <template #prefix>
                            <el-icon class="el-input__icon"><user /></el-icon>
                        </template>
                    </el-input>
                </span> -->
                <span class='login-input' v-if="['注册_1','登录'].indexOf(viewState.state)>=0">
                    <el-input v-if="viewState.state=='注册_1'" size='large' v-model="formData.username" placeholder="用户名必须是4-16位大写字母、小写字母或数字">
                        <template #prefix>
                            <el-icon class="el-input__icon"><user /></el-icon>
                        </template>
                    </el-input>
                     <el-input v-else size='large' v-model="formData.username" placeholder="请输入用户名">
                        <template #prefix>
                            <el-icon class="el-input__icon"><user /></el-icon>
                        </template>
                    </el-input>

                </span>
                <template v-if="viewState.state==='注册_1'">
                    <span class='login-input'>
                        <el-input size='large'  type='password' v-model="formData.password"  placeholder="密码由8-32位字母、数字和_.@~!中的三种组成">
                            <template #prefix>
                                <el-icon class="el-input__icon"><lock /></el-icon>
                            </template>
                        </el-input>
                    </span>
                    <span class='login-input'>
                        <el-input size='large' v-model="formData.realname"  placeholder="请输入真实姓名" :maxlength="10" show-word-limit>
                            <template #prefix>
                                <el-icon class="el-input__icon"><postcard /></el-icon>
                            </template>
                        </el-input>
                    </span>
                </template>

                <template v-if="viewState.state==='注册_2'">
                    <span class='login-input'>
                        <el-select suffix-icon='caret-top' size='large' class='input-select' v-model="formData.gender" clearable placeholder="请选择性别">
                            <el-option
                            v-for="item in genderData"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            />
                            <template #prefix>
                                <el-icon class='select-icon'><user /></el-icon>
                            </template>
                        </el-select>
                    </span>
                    
                    <!-- <span class='login-input'>
                        <el-select suffix-icon='caret-top' size='large' class='input-select' v-model="formData.role" clearable placeholder="请选择身份">
                            <el-option
                            v-for="item in []"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            />
                            <template #prefix>
                                <el-icon class='select-icon'><postcard /></el-icon>
                            </template>
                        </el-select>
                    </span> -->
                    <span class='login-input'>
                        <el-select suffix-icon='caret-top' size='large' class='input-select' v-model="formData.area" clearable @change="handleClick('改变地区')" placeholder="请选择地区">
                            <el-option
                            v-for="item in areaData"
                            :key="item.key"
                            :label="item.label"
                            :value="item.value"
                            />
                            <template #prefix>
                                <el-icon class='select-icon'><Location /></el-icon>
                            </template>
                        </el-select>
                    </span>
                    <span class='login-input'>
                        <el-select suffix-icon='caret-top' size='large' class='input-select' v-model="formData.university" clearable placeholder="选择您所在的机构">
                            <el-option
                            v-for="item in universityData"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            />
                            <template #prefix>
                                <el-icon class='select-icon'><school /></el-icon>
                            </template>
                        </el-select>
                    </span>
                    <!-- <div class="tipp" v-show="!registerVer">
                          <span v-if="!registerVer">{{}}</span>
                    </div> -->
                    <div class='login-input'>
                        <el-input class='login-code' size='large' v-model="formData.code"  placeholder="请输入验证码" />
                        <img @click="handleClick('刷新验证码')" class='login-vcode' :src="codeData.vcode_base64" alt="验证码">
                    </div>
                </template>

                <template v-if="viewState.state==='注册_3'">
                    <div class='register-finish'>
                        <img src="@/assets/images/htgcdx/wangcheng_big.png" alt="">
                        <span class='register-1'>恭喜注册成功！</span>
                        <span class='register-2'>请您妥善保管您的账户信息</span>
                        <span class='register-3 register-4'><b>{{second}}</b>秒后自动跳转至首页</span>
                        <span class='register-3 register-5'>若未自动跳转，请点击<b @click="this.handleClick('跳转至首页')">这里跳转</b></span>
                    </div>
                </template>

                <template v-if="viewState.state==='登录'">
                    <span class='login-input'>
                        <el-input size='large' type='password' v-model="formData.password"  placeholder="请输入密码">
                            <template #prefix>
                                <el-icon class="el-input__icon"><lock /></el-icon>
                            </template>
                        </el-input>
                    </span>
                    <div class='login-input'>
                        <el-input class='login-code' size='large' v-model="formData.code"  placeholder="请输入验证码" @keyup.enter="handleClick('用户登录')"/>
                        <img @click="handleClick('刷新验证码')" class='login-vcode' :src="codeData.vcode_base64" alt="">
                    </div>
                </template>
                <div>
                <span class='login-input login-log' v-if="!!viewState.msg && ['注册_1','注册_2','登录'].indexOf(viewState.state)>=0">
                    <template v-if="!!viewState.msg && viewState.msg !== '检测成功'">
                        <el-icon class='login-icon'><warning-filled /></el-icon>
                        {{viewState.msgTitle}}
                    </template>
                    <template v-if="viewState.msg === '验证通过'">
                        <el-icon class='login-icon finish'><success-filled /></el-icon>
                        <span class='finish'>{{viewState.msgTitle}}</span>
                    </template>
                </span>

                <div :class="['login-input','login-btn']" @click="handleClick('用户登录')" v-if="viewState.state==='登录' && loginstate">
                    <span> 
                        <el-icon v-if='loading' size='25' class="is-loading"><loading /></el-icon>登 录
                    </span> 
                </div>
                <div :class="['login-input','login-btn','disabled']" @click="handleClick('用户登录')" v-else-if="viewState.state==='登录'">
                    <span>登 录</span> 
                </div>
              </div>
                <div class='login-input login-btn' @click="handleClick(viewState.state==='注册_1'?'下一步':'用户注册')" v-if="['注册_1','注册_2'].indexOf(viewState.state)>=0">
                    <span>{{viewState.state==='注册_1'?'下一步':'注 册'}}</span> 
                </div>
                
                <div class='login-input footer-btns'>
                    <span class='left' v-if="viewState.state==='登录'" style="color:grey">忘记密码请联系管理员</span>
                    <span class='left' v-if="viewState.state==='注册_2'"  @click="handleClick('上一步')">上一步</span>
                    <!-- <span class='right' v-if="viewState.state==='登录'" @click="handleClick('注册_1')">没有账号，去注册</span> -->
                    <!-- <span class='right' v-if="['注册_1','注册_2'].indexOf(viewState.state)>=0" @click="handleClick('登录')">已有账号，去登录</span> -->
                </div>
            </div>
            <div class="login-footer">
                <!-- <span>建设单位：航天工程大学 合作单位：北京象新力科技有限公司 </span> -->
                <!-- <span>Copyright © 2015 - 2021京ICP备17005251号-1</span> -->
            </div>
        </div>
      
    </div>
</template>

<script>
import axiosTool from '@/utils/axios_tool';
import server from "@/service/server";
import checker from "@/utils/checker";
import common from "@/utils/common";
import dataList from "@/utils/chinaUniversityList";
import { ElMessage } from "element-plus";
import { mapActions } from 'vuex';

let schoolCache = {};
let timeout = null;

function registerParam (array){
    let cache = {};
    for(let name of array){
        cache[`formData.${name}`] = function (data) {
            this.monitorForm(name,data)
        }
    }
    return cache;
}

const params = ['username','password','code'];

export default {
    name: 'login-ui',

    data() {
        return {
            activeTab:'first',
            formData : {},
            codeData : {},
            viewState : {
                state : '登录',
                title : "用户登录",
                step : 1,
                msg : '',
                msgTitle : '',
            },
            registerData : [
                {key:'001',title:'账号设置',label:'1',value:1},
                {key:'002',title:'信息设置',label:'2',value:2},
                {key:'003',title:'注册完成',label:'3',value:3},
            ],
            axiosTool : {},
            genderData : [
                {key:'001',value:'男',label:'男',},
                {key:'002',value:'女',label:'女',},
                {key:'003',value:'保密',label:'保密',}
            ],
            areaData : [],
            universityData : [],
            loading : false,
            second : 5,//5秒后跳转
            loginstate : false,//登录按钮可点击
            base_url : process.env.BASE_URL,
            // registerVer:true,
        };
    },
    watch : {
        'formData.area' : 'areaMonitor',
        '$store.getters.loginsign' : 'loginsignMethod',
        ...registerParam(params)
    },
    created (){
        this.state = false;
        this.axiosTool = new axiosTool(this,[
            {key:'getCode',type:'post',title:'获取验证码',url:server.start},
            {key:'token',type:'post',title:'用户登录接口',url:server.token,params:{
                template : 'formData:post_username|post_password|post_code_token|post_code',
            }},
            {key:'log',type:'post',title:'创建登录日志',url:server.log},
            {key:'register',type:'post',title:'用户注册接口',url:server.user,params:{
                template : 'formData:post_username|post_realname|post_password|post_gender|post_agency_langname|post_code_token|post_code',
                postreq:{bind_agency : true},
            }},
            {key:'register_monitor',type:'post',title:'检测用户是否已经被注册',url:server.user,params:{
                postreq:{check_user_status:true}
            }},
        ])
        this.initData();
    },
    mounted() {
        this.getCode();
        this.loginsignMethod();
    },

    methods: {
        monitorForm (){
            const {formData} = this;
            let sign = true;
            for(const key of params){
                if(!formData[key]){
                    sign = false;
                }
            }
            this.loginstate = sign;
        },
        loginsignMethod (type=this.$store.getters.loginsign){
            switch (type){
                case '登录' : 
                    this.activeTab = 'first';
                    this.handleClick('登录');
                    break;
                case '注册' : 
                    this.activeTab = 'second';
                    this.handleClick('注册_1');
                    break;
                
            }
        },
        // loginsignMethod (type=this.$store.getters.loginsign){
        //     switch (type){
        //         case '登录' : 
        //             this.handleClick('登录');
        //             break;
        //         case '注册' : 
        //             this.handleClick('注册_1');
        //             break;
        //     }
        // },
        createTime (){
            clearInterval(timeout);
            timeout = setInterval(() => {
                this.second = this.second -1;
                if(this.second<=0){
                    this.handleClick('跳转至首页');
                    clearInterval(timeout);
                }
            }, 1000);
        },
        areaMonitor (area){
            if(!area){
                this.universityData = [];
                return ;
            };
            let universityData = schoolCache[area] || [];
            universityData = universityData.map(item=>{
                item.key = item.id;
                item.label = item.name;
                item.value = item.name;
                return item;
            })
            this.universityData = universityData;
        },
        initData (){
            if(!Array.isArray(dataList))return;
            let areaData = [];
            dataList.map(item=>{
                areaData.push({
                    ...item,
                    key : item.id,
                    label : item.name,
                    value : item.id,
                })
                schoolCache[item.id] = item.school;
            })
            this.areaData = areaData;
        },
        getCode (){
            let codeData = {};
            this.axiosTool.loading = true;
            this.axiosTool.getCode().then(data=>{
                if(data && data.status && data.data){
                    codeData.vcode_base64 = 'data:image/jpg;base64,' + data.data.base64;
                    this.formData.code_token = data.data.code_token;
                    this.codeData = codeData;
                }
                this.axiosTool.loading = false;
            })
        },
        loginAction (){
            const {formData} = this;
            let curtime = new Date();
            var RowOrigin = {
                x:0,
                y:0
            }
            this.loading = true;
            this.axiosTool.loading = true;
            this.axiosTool.token({},request=>{
                const {postreq} = request;
                postreq.username = checker.encryption(formData.username);
                postreq.password = checker.encryption(formData.password);
                return {...request,postreq}
            }).then(data=>{
                this.axiosTool.loading = false;
                if(data && data.status){
                    window.localStorage.setItem("userAutoid",data.code);
                    window.localStorage.setItem("token",data.token);
                    window.localStorage.setItem('rowValue', JSON.stringify(RowOrigin))
                    window.localStorage.setItem('curTime',(Number(curtime.getTime())+1200000)+'' );
                    this.getUserInfo();
                    ElMessage({
                        message: "登录成功！",
                        type: "success",
                        duration: 1000,
                        onClose: () => {
                            this.createLog();
                            this.loading = false;
                            this.loginstate = false;
                            this.handleClick('跳转至首页');
                        },
                    });
                    this.viewState.msg = '';
                }else {
                    this.loginstate = true;
                    this.loading = false;
                    this.getCode();
                    this.viewState.msg = data.msg || '账号或密码不正确';
                    this.viewState.msgTitle = data.msg || '账号或密码不正确';
                }
            })
        },
        registerMonitor (cb){//监控当前用户是否被注册
            const {formData} = this;
            this.axiosTool.loading = true;
            this.axiosTool.register_monitor({},request=>{
                const {postreq} = request;
                postreq.username = checker.encryption(formData.username);
                return {...request,postreq}
            }).then(data=>{
                this.axiosTool.loading = false;
                if(data && !data.status){
                    if(cb)cb(false);
                    return ElMessage.warning("该用户名已被注册");
                }else if(!data){return;}
                if(cb)cb(true);
            })
        },
       registerUser (cb){//注册用户
            const {formData,viewState} = this;
            var RowOrigin = {
                x:0,
                y:0
            }
             let curtime = new Date();
            let step;
            this.axiosTool.loading = true;
            this.axiosTool.register({},request=>{
                const {postreq} = request;
                postreq.username = checker.encryption(formData.username);
                postreq.password = checker.encryption(formData.password);
                postreq.realname = checker.encryption(formData.realname);
                return {...request,postreq}
            }).then(data=>{
                this.axiosTool.loading = false;
                if(data && data.data && data.status){
                    window.localStorage.setItem('token',data.data.token);
                    window.localStorage.setItem('rowValue', JSON.stringify(RowOrigin))
                    window.localStorage.setItem('curTime',(Number(curtime.getTime())+1200000)+'') 
                    if(cb)cb()
                }else {
                    // this.registerVer = false;
                    // let detection = this.detectionEmpty(data.msg);
                    // ElMessage.warning(data.msg || "用户注册失败");
                    this.viewState.msg = data.data || data.msg;
                    this.viewState.msgTitle = data.data || data.msg;
                    this.getCode();
                }
            })
         
        },
        createLog (){//创建登录日志
            this.axiosTool.loading = true;
            this.axiosTool.log().then(data=>{
                if(data && data.status){}
                this.axiosTool.loading = false;
            })
        },
        detectionEmpty (){
            const {formData,viewState} = this;
            if(!formData.username || !/^[a-zA-Z0-9]{4,16}$/.test(formData.username)){//用户名格式错误
                this.viewState.msg = '账号格式错误！';
                this.viewState.msgTitle = '用户名必须是4-16位大写字母、小写字母或数字';
                return false;
            }

            if(!formData.password || !common.pwdcheck(formData.password||'')){//密码格式错误
                this.viewState.msg = '密码格式错误！';
                this.viewState.msgTitle = '密码必须至少由8-32位大小写字母、数字和_.@~!中的三种组成';
                return false;
            }
 
            // if(!this.registerVer &&viewState.state === '注册_2'){
            //      this.viewState.msg = "验证码错误";
            //     this.viewState.msgTitle = res;
            //     return false;
            // }

            if(viewState.state === '注册_2' && !formData.gender){//性别为空
                this.viewState.msg = '选择性别！';
                this.viewState.msgTitle = '选择性别！';
                return false;
            }
            if(viewState.state === '注册_2' && !formData.university){//机构为空
                this.viewState.msg = '请选择机构！';
                this.viewState.msgTitle = '请选择机构！';
                return false;
            }
            // debugger;
            if(['登录','注册_2'].indexOf(viewState.state) >=0 && !/^[0-9a-zA-Z]{4}$/.test(formData.code)){//验证码错误
                this.viewState.msg = '请输入正确的验证码！';
                this.viewState.msgTitle = '请输入正确的验证码！';
                return false;
            }
            if(viewState.state === '注册_1' && !formData.realname){//真实姓名错误
                this.viewState.msg = '请输入真实姓名！';
                this.viewState.msgTitle = '请输入真实姓名！';
                return false;
            }
            this.viewState.msg = '检测成功';
            return true;
        },
        handleClick (type){
            const {viewState,formData,loginstate} = this;
            let step,detection;
            switch (type){
                case '注册_1' : 
                    this.viewState = {
                        state : type,
                        title : '用户注册',
                        step : 1,
                    };
                    break;
                case '下一步' : 
                    detection = this.detectionEmpty();
                    if(!detection)return;
                    this.registerMonitor(success=>{
                        if(success){
                            step = viewState.step + 1;
                            this.viewState = {
                                state : `注册_${step}`,
                                title : '用户注册',
                                step : step,
                            };
                        }else {
                            this.formData = {
                                ...formData,
                                username : '',
                            };
                        }
                    });
                    break;
                case '上一步' : 
                    step = viewState.step - 1;
                    this.viewState = {
                        state : `注册_${step}`,
                        title : '用户注册',
                        step : step,
                    };
                    break;
                case '登录' : 
                    this.viewState = {
                        state : type,
                        title : '用户登录',
                        step : 1,
                    };
                    this.formData = {};
                    break;
                case '用户登录' : 
                    if(!loginstate)return;
                    detection = this.detectionEmpty();
                    if(!detection)return;
                    this.loginAction();
                    break;
                case '用户注册' : 
                    detection = this.detectionEmpty();
                    if(!detection)return;
                    this.registerUser(()=>{
                        step = viewState.step + 1;
                        this.viewState = {
                            state : `注册_${step}`,
                            title : '用户注册',
                            step : step,
                        };
                        this.getUserInfo();
                        this.createTime();
                    });
                    
                    break;
                case '跳转至首页' : 
                    this.$router.push("/home1");
                    // common.setStorParams({ path: '/exps' }, { autoid: 1 });
                    // this.$router.push("/expcore");
                    break;
                case '刷新验证码' : 
                    this.getCode();
                    break;
                case '改变地区':
                    this.formData.university = '';
               
                    break;

            }
        },
        handChange(tab,event){
            switch(tab.props.name){
                case 'first':
                    this.viewState = {
                        state : '登录',
                        title : '用户登录',
                        step : 1,
                    };
                    this.formData = {};
                    break;
                case'second':
                    this.viewState = {
                        state : '注册_1',
                        title : '用户注册',
                        step : 1,
                    };
                    this.formData = {};
                    break;               
            }
        },
        ...mapActions(['getUserInfo'])
    },
};
</script>

<style lang="less" scoped>
    @import '~@/styles/common.less';
    @element_input_border_color:#3A47A0;
    @element_input_border_focus_color:#3A47A0;
    
    .element-input-reset;
    .element-select-reset;
    :deep(.el-step__line){
        height: 5px !important;
        background-color: #E9EDFD;
        top: 18px !important;
    }
    :deep(.register.action){
        background-image:url('~@/assets/images/htgcdx/dangqiang_1.png');

    }
    :deep(.register.finish){
        background-image:url('~@/assets/images/htgcdx/wangcheng.png');
    }
    :deep(.el-step__icon){
        width: auto;
        height: auto;
        background-color: #214590;
        border: none;
    }
    :deep(.register){
        width: 40px;
        height: 40px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        background-image:url('~@/assets/images/htgcdx/dangqiang_2.png');
        background-size: 100% 100%;
        display: inline-block;
        line-height: 40px;
        text-align: center;
    }
    :deep(.register-label){
        color:#848CA8;
    }
    :deep(.register-label.action){
        color:#FFFFFF;
    }
    :deep(.el-tabs__nav ){
        width: 100%;
    }
    :deep(.input-select .el-input__prefix){
        left:10px;
    }
    :deep(.el-tabs__active-bar){
         width: 50%;
        background-color: black;
    }
    :deep(.el-tabs__item ){
        width: 50%;
        font-size:20px;
    }
    :deep(.el-tabs__item.is-active){
        color: black;   
    }
    :deep(.el-input--prefix .el-input__inner){
        border: 1px solid #ECECEC;
        border-radius: 2px 2px 0px 2px;
        color: black;
        font-size: 15px;
    }
    :deep(.el-input__inner){
        border: 1px solid #ECECEC;
        border-radius: 2px 2px 0px 2px;
        color: black;
    }
    .login-input login-btn {
            margin-top: 99px;
    }

    .select-icon{
        font-size:20px;
    }
    .is-loading{
        font-size:20px;
        margin-right:10px;
    }
    .register-finish{
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1 1 auto;
        >img{
            width: 100px;
            height: 100px;
            padding: 30px 0 40px;
        }
        .register-1{
            font-size: 35px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
        }
        .register-2{
            font-size: 24px;
            font-family: Microsoft YaHei;
            color: #999999;
            margin-top: 20px;
        }
        .register-3{
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color:#999999;
            >b{
                color: #00FF30;
            }
        }
        .register-4{
            margin-top: auto;
            margin-bottom: 10px;
            >b{
                margin-right:5px;
            }
        }
        .register-5{
            margin-bottom: 20px;
            >b{
                text-decoration: underline;
                cursor:pointer;
                margin-left:5px;
            }
        }
    }
    .login-btn.disabled>span{
        cursor:not-allowed !important;
        cursor:no-drop !important;
        background-color: rgba(30,95,187, .5) !important;
    }
    .login-ui{
        width: 100%;
        min-height: 100%;
        display: flex;
        // align-items: center;
        justify-content: center;
        background-image:url('~@/assets/images/htgcdx/login_bg2.png');
        background-size: 100% 100%;
        .pannel_tab{
            margin:0 auto;
            margin-top: 20px;
            width: 80%;
        }
        .login-main{
            display: flex;
            flex-direction: column;
            text-align: center;
            margin-top: 68px;
            width:860px;
            // width:620px;
            .login-logo{
                margin-bottom: 20px;
            }
            .login-title{
                // font-size: 36px;
                // font-family: ZiZhiQuXiMaiTi;
                font-family: STZhongsong;
                font-size: 44px;
                font-weight: 400;
                color: #FFFFFF;
                margin-bottom: 40px;
                display: inline-block;
                font-family: STZhongsong;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 64px;
                // -webkit-text-stroke: 1px #1B3D91;
                // text-stroke: 1px #1B3D91;
            }
            .login-body{
                height: 556px;
                width:474px;
                box-shadow: 0px 3px 24px 0px rgb(40 60 95 / 42%);
                border-radius: 10px;
             //   background-image:url('/static/htgcdx/register_panel.png');
                background-size: 100% 100%;
                display:flex;
                flex-direction: column;
                margin: 0 auto ;
                background-color: white;
                .body-title{
                    font-size: 22px;
                    font-family: ZiZhiQuXiMaiTi;
                    font-weight: 400;
                   // color: #FFFFFF;
                    display: inline-block;
                    width: 100%;
                    height: 50px;
                    text-align: center;
                    line-height: 50px;
                    flex:0 0 auto;
                    .noselect;
                }
             
                .login-input{
                    padding: 0 50px;
                    display: flex;
                    margin-bottom:20px;
                    flex:0 0 auto;
                    font-size: 15px;
                    .input-select{
                        width:100%;
                    }
                    >img{
                        cursor:pointer;
                    }
                    
                }
                .login-input:nth-child(2){
                    padding-top: 16px;
                }
                .login-code{
                    flex:1 1 auto;
                }
                .login-log{
                    align-items: center;
                    color:#F53A50;
                    line-height: 24px;
                    text-align: left;
                    .login-icon{
                        margin-right:5px;
                        color:#F53A50;
                        font-size:18px;
                        position: relative;
                        top: 1px;
                    }
                    .finish{
                        color:#409EFF;
                    }
                }
                .login-btn{
                    height: 50px;
                    >span{
                        cursor:pointer;
                        flex: 1 1 auto;
                        text-align: center;
                        line-height: 50px;
                        color: white;
                      //  background-color: #EAEDFC;
                        font-weight: bold;
                        font-size: 20px;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #1E5FBB;
                        border-radius: 3px;
                    }
                }
                .login-btn.disabled>span{
                    cursor:not-allowed;
                    cursor:no-drop;
                }
                .login-vcode{
                    margin-left:10px;
                    width:100px;
                    flex:0 0 auto;
                }

                .login-footer{
                    flex:0 0 auto;
                    >span{
                      //  color: #FFFFFF;
                        text-decoration: underline;
                        cursor:pointer;
                    }
                    >span.left{
                        margin-right:auto;
                    }
                    >span.right{
                        margin-left:auto;
                    }
                    >span:hover{
                        color: #8C98CA;
                        text-decoration: underline;
                    } 
                }

                .login-state{
                    padding: 20px 0;
                    flex:0 0 auto;
                }
            }

            .login-body.register-body1{
                height:460px;
                .body-title{
                    height: 47px;
                    line-height: 54px;
                }
            }
            .login-body.register-body2{
                height:520px;
                .body-title{
                    height:55px;
                    line-height:60px;
                }
            }
        }
        .login-footer{
            display: flex;
            flex-direction: column;
            color: white;
            margin-top: 50px;
            >span{
                margin-bottom: 10px;
                font-size: 16px;
                font-family: Microsoft YaHei;
            }
        }
    }
    
</style>