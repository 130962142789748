<template>
    <div class="main-home">
        <component :is="config.layout" :data="config.slots">
            <!-- 头部布局部分 head -->
            <!-- <template v-slot:[slot]>fsdafsfas11111</template> -->
            <template v-slot:[item.slot] v-for="item in config.head" :key="item.key">
                <component :is="item.component" v-show="item.iShow"></component>
            </template>
            <!-- 内容布局部分 children-->
            <template #children>
                <router-view />
            </template>
            <!-- 底部布局部分 footer-->
            <template v-slot:[item.slot] v-for="item in config.footer" :key="item.key">
                <component :is="item.component"></component>
            </template>
        </component>
        <!-- <div class="main-acquisition">
            <div class="acquisition-item">
                <img src="@/assets/images/kf-3.png" alt="">
                <div class="item-shade" @click="click('申请试用')">
                    <span class="item-shiyong">申请试用</span>
                    <span class="item-tiyan">便捷体验虚拟仿真实验</span>
                </div>
            </div>
        </div> -->
        <MessageBoxs :title="popup.title" :state="popup.state" @close="messageClose" :style='popup.style'>
            <div class="main-form">
                <el-form @validate="validate_change" ref="ruleFormRef" :model="formData" :rules="rules" label-width="120px" class="demo-ruleForm"
                    size="default" status-icon>
                    <el-form-item label="姓名" prop="realname">
                        <el-input class="main-input" v-model="formData.realname" />
                    </el-form-item>
                    <el-form-item label="手机号" prop="phone">
                        <el-input class="main-input" v-model="formData.phone" />
                    </el-form-item>
                    <el-form-item label="邮箱地址" prop="emil">
                        <el-input class="main-input" v-model="formData.emil" />
                    </el-form-item>
                    <el-form-item label="单位名称" prop="unit">
                        <el-input class="main-input" v-model="formData.unit" />
                    </el-form-item>
                    <el-form-item label="软件名称" prop="software">
                        <el-input class="main-input" v-model="formData.software" />
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <Buttons :disabled="!isSubmit.state" type="primary" @click="click('提交获客信息')">提交</Buttons>
                <Buttons type="info" plain @click="messageClose">取消</Buttons>
            </template>
        </MessageBoxs>
    </div>
</template>

<script>
import _ from '@/views/Home/config';
import components from './index.js';
import MessageBoxs from "@/components/course/MessageBoxs.vue";
import Buttons from "@/components/course/Buttons.vue";
import checker from '@/utils/checker';
import server from "@/service/server";
import axiosTool from '@/utils/axios_tool'
import { ElMessage } from "element-plus";
export default {
    name: "Home",
    watch: {
        "$route.path": "func"
    },
    data() {
        return {
            config: {},//布局的配置
            slot: 'head',
            currentRoute: '',
            popup: {
                title: '',
                state: false,
                style: {},
            },
            formData: {},
            rules: [],
            isSubmit : {},
        }
    },
    created() {
        const config = _['common'];
        this.config = JSON.parse(JSON.stringify(config));
        this.func(this.$route.path)

        this.rules = this.rules_data();

        this.axiosTool = new axiosTool(this, [
            {
                key: 'coverage_add', type: 'post', title: '新增获客信息', url: server.acquisition, params: {
                    template: 'formData:post_realname|post_phone|post_emil|post_unit|post_software',
                    postreq: { typeid: '4' }
                }
            },
        ])
    },
    methods: {
        func(data) {
            let _config = this.config;
            if (!!data) {
                _config.head.map(item => {
                    item.iShow = true
                    item.exclude.map(item_inner => {
                        if ('/' + item_inner == data) {
                            item.iShow = false
                        }
                    })
                })
            }
            //   console.log(_config.head)
            this.config = { ..._config }
        },
        rules_data() {
            return {
                realname: [
                    { required: true, message: '姓名不能为空哦！', trigger: 'blur', },
                ],
                phone: [
                    { required: true, validator: this.verify_phone, message: '手机号格式不正确！', trigger: 'blur', },
                ],
            }
        },
        validate_change (key,isValid,message){
            this.isSubmit[key] = isValid;
            const v = ['realname','phone'];
            this.isSubmit.state = true;
            for (const _v of v){
                if(!this.isSubmit[_v]){
                    this.isSubmit.state = false;
                }
            }
        },
        verify_phone(rule, value, callback) {
            let verify = checker.phone(value);
            if (!verify[0]) {
                callback(new Error(rule.message))
            } else {
                callback();
            }
        },
        click(type) {
            switch (type) {
                case '申请试用':
                    this.popup = {
                        title: '填写信息',
                        state: true,
                        style: {},
                    }
                    break;
                case '提交获客信息':
                    
                    this.submit();
                    break;
            }
        },
        submit() {
            const {formData} = this;
            if(formData.emil && !checker.email(formData.emil)[0]){
                ElMessage.warning("邮箱格式不正确！");
                return;
            }

            this.axiosTool.coverage_add().then(data=>{
                if(data.status){
                    ElMessage.success("申请信息已提交，稍后我们的工作人员将与您联系！");
                    this.messageClose();
                    this.formData = {};
                }
            })
        },
        messageClose() {
            this.popup = {};
        }
    },
    components: { ...components, MessageBoxs, Buttons },
}
</script>


<style scoped lang="less">
.main-input :deep(.el-input__suffix) {
    display: flex;
    align-items: center;
}

.main-home {
    position: relative;
    width: 100%;
    height: 100%;
}

.main-acquisition {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);

    .acquisition-item {
        cursor: pointer;

        >img {
            position: relative;
            z-index: 1;
        }

        .item-shade {
            display: flex;
            position: absolute;
            left: 35px;
            top: 0px;
            height: 60px;
            width: 0;
            background-color: #FFFFFF;
            border-radius: 20px;
            transition: opacity ease-in 0.2s, width ease-in 0.2s;
            opacity: 0;
            box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
            flex-direction: column;
            justify-content: center;
            padding-left: 32px;
            flex-wrap: wrap;
            overflow: hidden;

            .item-shiyong {
                font-size: 16px;
                color: #61656d;
                margin-bottom: 5px;
                width: 152px;
            }

            .item-tiyan {
                font-size: 14px;
                color: #b4bbc7;
                width: 152px;
            }
        }
    }

    .item-shade:hover {
        .item-shiyong {
            color: #f18d00;
        }

        .item-tiyan {
            color: #ffca80;
        }
    }

    .acquisition-item:hover {
        .item-shade {
            width: 152px;
            opacity: 1;
        }

    }
}
</style>