<template>
<div class="main">
    <div class="header">

    </div>
    <div class="main_content">

    </div>
</div>

</template>

<script>
export default {
    name:'goodMain',
    mounted(){
        this.$router.push('/exps');
    },
    data(){
        return{
            
        }
    }
}
</script>

<style lang="less" scoped>
.main{
    .header{
        height: 125px;
        background-color: aqua;
    }
    .main_content{
        height: 700px;
    }
}

</style>