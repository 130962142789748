/* eslint-disable no-shadow */
import axios from '@/utils/axios'
import server from '@/service/server';

const state = {
    userInfo: {},

    userInfoBefore: {},
};

const getters = {};
Object.keys(state).forEach((k) => {
    getters[k] = (s) => s[k]
});

const mutations = {
    SET_USER_INFO: (state, userInfo) => {
        state.userInfo = Object.assign({}, userInfo)
        state.userInfoBefore = Object.assign({}, userInfo)
    },
    SET_USER_INFO_ITEM: (state, item) => {
        const [key, value] = item
        state['userInfo'][key] = value
    }
};

const actions = {
    getUserInfo: async function({ commit }) {
        const response = await axios.get(server.userinfo);
        if (response && response.status && response.data && response.data.result && response.data.result instanceof Array && response.data.result.length) {
            //if(response.token)localStorage.setItem("token", response.token)
            commit('SET_USER_INFO', response['data']['result'][0] || false);
        } else {
            commit('SET_USER_INFO', false);
            return response;
        }
        return response;
    },

    setUserInfoItem: ({ commit }, item) => {
        commit('SET_USER_INFO_ITEM', item)
    },
    setUserInfo({ commit }, item) {
        commit('SET_USER_INFO', item)
    },
    /**
     * @description: 比较差异来更新用户信息
     */
    updateUserInfo: async({ commit }, { id }) => {
        const { userInfo, userInfoBefore } = state
        let diff = { autoid: id }
        for (let key in userInfo) {
            if (userInfo[key] !== userInfoBefore[key]) {
                diff[key] = userInfo[key]
            }
        }
        try {
            const response = await axios.put('/user', diff)
            commit('SET_USER_INFO', response['data']['result'][0])
            return response
        } catch (error) {
            return error
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
}