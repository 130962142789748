<template>
    <div class='page-main'>
        <div class='page-title'>
            <span class='page-cn'><slot name='cnName'></slot></span>
            <span class='page-en'><slot name='enName'></slot></span>
            <slot name='custom'></slot>
        </div>
        <div class='page-content'>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'page_title',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="less" scoped>
    .page-main{
        width:100%;
        overflow: hidden;
        .page-title{
            text-align: left;
            border-bottom: 1px solid #E6E6E6;
            padding: 10px 0;
            display: flex;
            align-items: center;
            .page-cn{
                position: relative;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
                padding-left:10px;
                &::before{
                    content: '';
                    height: 16px;
                    width: 3px;
                    position: absolute;
                    left: 0;
                    top: 55%;
                    transform: translate(0, -50%);
                    background-color: #1E5FC2;
                }
            }
            .page-en{
                position: relative;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #B6B6B6;
                margin-left: 15px;
                &::after{
                    content: '';
                    height: 3px;
                    width: 72px;
                    position: absolute;
                    left: 0;
                    bottom: -12px;
                    background-color: #1E5FC2;
                }
            }
        }
        .page-content{
            width:100%;
        }
    }
</style>