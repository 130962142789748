<template>
    <div class='evaluate-ui'>
        <!--实验评价-->
        <PageItems>
            <template #title>实验评价<span class='evaluate-count'>共有{{count}}个评价</span></template>
            <div class="evaluate-option">
                <span>
                    <Uploads 
                        v-model:data='uploadData' 
                        v-model:result='uploadResult'
                        modules="btn" 
                        btnPosition="bottom" 
                        class="resources-upload"
                        :accepts='["jpg","png","mp4"]' 
                        :uploadData='uploadResource'
                        @uploadBefore='uploadBefore'  
                        @uploadAfter='uploadAfter'  
                        >
                        <template #btnName>上传图片/视频</template>
                        <template #上传提示>你只可上传9张图片和视频，图片大小不得超过5M，视频大小不得超过50M哦~</template>
                    </Uploads>
                </span>
                <span>
                    实验评分：<el-rate v-model="fromData.star" text-color="#FF635F" :colors="evaluateColors"> </el-rate>
                </span>
                <el-input maxlength="300" rows="5" resize="none" type="textarea" :show-word-limit='true' size='small' v-model="fromData.content" placeholder="请输入你的评论" />
                <div class="evaluate-btns">
                    <!--<Buttons type="info" class="evaluate-btn">取消</Buttons>-->
                    <Buttons type="blue" class="evaluate-btn" @click="handleClick('发布')">发布</Buttons>
                </div>
            </div>
            <div class="details-main">
                <div class="details-answer" >
                    <!-- <Answer @toClick="handleAnswer" :options='options' @load='handleLoad' :parentid="expInfo.expautoid"
                        :floor="floor" :getFloorData="getFloorData" :getFloorCount="getFloorCount" :key="refreshKey"/> -->
                    <Evaluate :option='option' @requestBefore='requestBefore' :data='answerData'/>
                </div>
                <div class="details-page">
                    <Pagination
                        :total="paging.total || 0"
                        :pageSize="paging.pageSize || 5"
                        :currentPage="paging.currentPage || 1"
                        @pageChange="pageChange"
                    />
                </div>
                <!-- <div v-show="viewState.type === '详情'" class="answer-details">
                    <Details parentField="uistarautoid" @toBack="viewState.type = '主页'" @updateRate="updateRate" :expautoid="expInfo.expautoid" :data="viewState.data"></Details>
                </div> -->

            </div>
        </PageItems>
    </div>
</template>

<script>
import Uploads from '@/components/common/Uploads/Uploads.vue';
import PageItems from '@/components/course/PageItems.vue';
import Pagination from '@/components/course/Pagination.vue';
import Answer from '@/components/course/Answer.vue';
import Details from '@/views/Main/Expcore/answerDetails.vue';
import NoData from "@/components/common/nodata.vue";
import Evaluate from '@/components/business/Evaluate/index.vue';
import axiosTool from '@/utils/axios_tool';
import Buttons from '@/components/course/Buttons.vue';
import axios from "@/utils/axios";
import server from "@/service/server";
import common from "@/utils/common";
import checker from '@/utils/checker';
import Empty from '@/components/course/Empty.vue';
import {ElMessage,} from 'element-plus'
export default {
    name: 'evaluate',
    props : {
        expInfo : {
            type : Object,
            default : ()=>{return {}}
        }
    },

    data() {
        return {
            count: 0,
            uploadData : [],
            uploadResource : {
                url : server.upload,
                data : {},
            },
            paging : {
                total : 0,
                pageSize : 5,
                currentPage : 1,
            },
            answerPaging : {
                total : 0,
                pageSize : 5,
                currentPage : 1,
            },
            option : ['点赞','评论','回复','删除',['评论','回复','删除']],
            answerData : [],
            evaluateColors : ['rgba(255,49,44,.3)', 'rgba(255,49,44,.6)', 'rgba(255,49,44,1)'],
            options: {//评论组件的功能
                delete: this.handleDelete,
                release: this.handleRelease,
                update: this.handleUpdate,
                closeUpdate: false,
                showReply: false,
                closeDelete: false,
                showChildBelow: false
            },
            viewState: {
                type: '主页',
            },
            answerLoaded: false,
            floor: 3,
            floorData: {
                3: {
                    api: server.uistar,
                    parentid: "expautoid",
                    childsize: "answer_size",
                    extraParam: {},
                    isRate: true,
                    rateStyle: {
                        disabled: true,
                        showScore: false
                    },
                },
                2: {
                    api: server.answer,
                    parentid: "uistarautoid",
                    childsize: "evaluate_size",
                    extraParam: {get_all: ""}
                },
                1: {
                    api: server.evaluate,
                    parentid: "answerautoid",
                    extraParam: {get_all: ""}
                }
            },
            answerItemCallback: null,
            uploadResult : [],
            fromData : {},
            uploadUrl : [],
            mediaType: {
                img: ["jpg","png"],
                video: ["mp4"]
            },
            userData : {},
            refreshKey: 0,
        };
    },
    watch:{
        'expInfo' : 'queryEvaluate',
        'uploadResult' : 'uploadResultEvaluate',
        '$store.state.user.userInfo' : 'monitorUserInfo',
    },
    created (){
        this.axiosTool = new axiosTool(this,[
            {key:'getUistar',type:'get',title:'获取评论列表',url:server.uistar,params:{
                template : 'expInfo:expautoid,paging:page=currentPage|per_page=pageSize',
                getreq:{ _order_column:"createtime", _order_style:"desc"},
            }},
            {key:'getUistarCount',type:'get',title:'获取评论列表数量',url:server.uistar,params:{
                template : 'expInfo:expautoid',
                getreq:{count:true},
            }},
            {key:'deleteUistar',type:'post',title:'删除评论',url:server.uistar,params:{
                postreq:{delete_data:true},
            }},
            {key:'collectstar',type:'post',title:'实验评价点赞',url:server.collectstar,params:{
                template : 'expInfo:post_expautoid',
                postreq:{targettable:'uistars',like:1},
            }},
            {key:'getAnswer',alone : true,type:'get',title:'获取回复',url:server.answer,params:{
                getreq:{get_all:true},
            }},
            {key:'addAnswer',type:'post',title:'回复评论',url:server.answer},

            {key:'deleteAnswer',type:'post',title:'删除评论',url:server.answer,params:{
                postreq:{delete_data:true},
            }},
            {key:'addEvaluate',type:'post',title:'回复评论的回复',url:server.evaluate},
            {key:'getEvaluate',alone : true,type:'get',title:'获取回复的回复',url:server.evaluate,params:{
                getreq:{get_all:true},
            }},
        ]);
    },
    mounted() {
        this.initData();
        this.monitorUserInfo();
    },

    methods: {
        initData (){
            const {expInfo} = this;
            if(!expInfo.expautoid)return;
            this.axiosTool.loading = true;
            this.axiosTool._axios().then(data=>{
                const [expInfoData] = data;
                this.handleExpInfo(expInfoData);
                this.axiosTool.loading = false;
            })
        },
        monitorUserInfo (userInfo=this.$store.state.user.userInfo){
            this.userData = userInfo;
        },
        handleExpInfo (data){
            const {answerData} = this;
            let sourceData = [];
            if(data && data.result){
                data.result.map(item=>{
                    item.key = item.autoid;
                    if(item.user_data){
                        item.userIcon = item.user_data.face || require('@/assets/images/user_logo.png');
                        item.realname = item.user_data.realname || item.user_data.username;
                    }
                    if(item.evalresource_data){
                        let filepaths = item.evalresource_data.filepath.split(",");
                        filepaths.sort((a,b)=>{
                            if(/\.jpg$|\.png$|.jpeg$/.test(a)) return 1;
                            else return -1;
                        })
                        item.media = filepaths;
                    }
                    //item.answer_size = item.answer_size;//评论 
                    if(item.like_data){
                        item.collect_count = 0;//收藏
                        item.like_state = item.like_data.like == 'true';
                        item.like_count = item.like_data.like_count;//点赞
                        item.like_autoid = item.like_data.autoid;
                    }
                    let answer = answerData.filter(ite=> ite.autoid === item.autoid)[0];
                    if(answer && answer.children){
                        item.children = answer.children;
                    }
                    sourceData.push(item);
                })
            }
            
            this.axiosTool.getUistarCount().then(res=>{
                this.count = Number(res.data);
                this.paging.total = Number(res.data);
            })
            this.answerData = common.Recursion(sourceData);
        },
        handleAnswer (data,callback){
            // this.viewState = {
            //     type: '详情',
            //     data: data,
            // };
            // if(callback && checker.isFunction(callback)) this.answerItemCallback = callback;
            let answer = [];
            if(data && data.result){
                data.result.map(item=>{
                    item.key = item.autoid;
                    if(item.user_data){
                        item.userIcon = item.user_data.face || require('@/assets/images/user_logo.png'); 
                        item.realname = item.user_data.realname || item.user_data.username;
                    }
                    if(item.evalresource_data){
                        let filepaths = item.evalresource_data.filepath.split(",");
                        filepaths.sort((a,b)=>{
                            if(/\.jpg$|\.png$|.jpeg$/.test(a)) return 1;
                            else return -1;
                        })
                        item.media = filepaths;
                    }
                    item.collect_count = 0;//收藏
                    item.comment_count = 0;//评论 
                    answer.push(item);
                })
            }
            
            if(callback)callback(answer);
        },

        getAnswer (data,callback){
            this.axiosTool.loading = true;
            this.axiosTool.getAnswer({},request=>{
                this.axiosTool.loading = false;
                const {getreq} = request;
                getreq.uistarautoid = data.autoid;
                if(data.pageSize)getreq.per_page = data.pageSize;
                if(data.currentPage)getreq.page = data.currentPage;
                return {...request,getreq};
            }).then(result=>{
                let autoids = [];
                if(result.result){
                    for(let evaItem of result.result){
                        autoids.push(evaItem.autoid);
                    }
                }
                this.axiosTool.getEvaluate({},evaRquest=>{
                    const {getreq} = evaRquest;
                    getreq.answerautoids = autoids.join(',');
                    return {...evaRquest,getreq}
                }).then(evaluateData=>{
                    let cache = [];
                    if(evaluateData.result){
                        for(let evaItem of result.result){
                            let evaluateArr = evaluateData.result.filter(item=>item.answerautoid === evaItem.autoid);
                            evaItem.evaluate_size = evaluateArr.length;
                            cache.push(evaItem);
                        }
                    }
                    result.result = cache;
                    this.handleAnswer(result,callback)
                })
                
            });
        },
        
        uploadAfter (data){
            switch(data.data.type){
                case 'image/png':
                    if(data.data.size / 1024 / 1024 > 5){
                        ElMessage.warning("图片大小不得超过5M");
                        data.cb(false);
                    }
                    break;
                case 'image/jpeg':
                    if(data.data.size / 1024 / 1024 > 5){
                        ElMessage.warning("图片大小不得超过5M");
                        data.cb(false);
                    }
                    break;
                case 'video/mp4':
                    if(data.data.size / 1024 / 1024 > 50){
                        ElMessage.warning("视频大小不得超过50M");
                        data.cb(false);
                    }
                    break;
                default :
                        ElMessage.warning("不支持该类型");
                        data.cb(false);
            }
        },
        uploadBefore (cb){
            const {uploadUrl} = this;
            if(uploadUrl.length>=9){
                ElMessage.warning("文件上传已达上限！");
                cb(false);
            }
        },
        uploadResultEvaluate (data){
            let uploadUrl = []
            if(Array.isArray(data)){
                data.map(item=>uploadUrl.push(item.url));
            }
            this.uploadUrl = uploadUrl;
        },
        handleLoad (){

        },
        handleUpdate (){

        },
        requestBefore (option){
            const {expInfo} = this;
            const {type,data,callback} = option;
            switch(type){
                case '外_刷新' : 
                    this.axiosTool.getUistar().then(result=>this.handleExpInfo(result));
                    break;
                case '内_刷新' : 
                    this.axiosTool.getUistar().then(result=>{
                        this.handleExpInfo(result);
                        this.getAnswer(data,callback);
                    })
                    break;
                case '外_评论' : 
                    
                    this.getAnswer(data,callback);
                    break;
                case '内_发布' : 
                    this.axiosTool.addEvaluate({},request=>{
                        const {postreq} = request;
                        if(data.parent){
                            postreq.answerautoid = data.autoid;
                        }
                        postreq.content = data._value;
                        postreq.filepath_str = data._medias.join(',');
                        return {...request,postreq}
                    }).then(result=>{
                        if(result){
                            ElMessage({ showClose: true, message: '操作成功！', type: 'success' });
                            if(data.parent){
                                this.getAnswer(data.parent,callback);
                            }
                        }
                    })
                    break;
                case '外_点赞' : 
                    this.axiosTool.collectstar({},request=>{
                        const {postreq} = request;
                        if(data.like_state){
                            postreq.delete_data = true;
                            postreq.autoid = data.like_autoid;
                        }
                        postreq.targetautoid = data.autoid;
                        return {...request}
                    }).then(result=>{
                        if(result){
                            ElMessage({ showClose: true, message: '操作成功！', type: 'success' });
                            this.axiosTool.getUistar().then(result=>this.handleExpInfo(result))
                        }
                    })
                    break;
                case '外_发布' : 
                    this.axiosTool.addAnswer({},request=>{
                        const {postreq} = request;
                        postreq.uistarautoid = data.autoid;
                        postreq.content = data._value;
                        postreq.filepath_str = data._medias.join(',');
                        postreq.expautoid = expInfo.expautoid;
                        return {...request,postreq}
                    }).then(result=>{
                        if(result){
                            ElMessage({ showClose: true, message: '操作成功！', type: 'success' });
                            this.axiosTool.getUistar().then(result=>{
                                this.handleExpInfo(result);
                                this.getAnswer(data,callback);
                            })
                        }
                    })
                    break;
                case '外_上传' : 
                    if(callback)callback({
                        url : server.upload,
                        postBack: (data) => {
                            return {
                                profile:true, name: data.name 
                            };
                        },
                    })
                    break;
                case '内_上传' : 
                    if(callback)callback({
                        url : server.upload,
                        postBack: (data) => {
                            return {
                                profile:true, name: data.name 
                            };
                        },
                    })
                    break;
                case '外_删除' : 
                    this.axiosTool.deleteUistar({},request=>{
                        const {postreq} = request;
                        postreq.autoid = data.autoid;
                        return {...request,postreq};
                    }).then(result=>{
                        if(result){
                            ElMessage({ showClose: true, message: '操作成功！', type: 'success' });
                            if(callback)callback(true);
                        }
                    })
                    break;
                case '内_删除' : 
                    
                    this.axiosTool.deleteAnswer({},request=>{
                        const {postreq} = request;
                        postreq.autoid = data.autoid;
                        return {...request,postreq};
                    }).then(result=>{
                        if(result){
                            ElMessage({ showClose: true, message: '操作成功！', type: 'success' });
                            if(callback)callback(true);
                        }
                        if(callback)callback(true);
                    })
                    break;
                case '内_评论' : 
                    this.axiosTool.getEvaluate({},evaRquest=>{
                        const {getreq} = evaRquest;
                        getreq.answerautoid = data.autoid;
                        return {...evaRquest,getreq}
                    }).then(evaluateData=>{
                        this.handleAnswer(evaluateData,callback)
                    })
                    break;
            }
        },
        handleRelease (data,parent){
            const {uploadUrl,expInfo} = this;
            let request = {
                uistarautoid : data.key,
                content : data.valueVal,
                filepath_str : uploadUrl.join(','),
                expautoid : expInfo.expautoid,
            };
            
            axios.post(server.answer,request).then(result=>{
                if(result.status){
                    ElMessage({ showClose: true, message: '操作成功！', type: 'success' });
                    this.fromData = {};
                    this.uploadUrl = [];
                    this.queryEvaluate(expInfo);
                }else {
                    ElMessage.error("操作失败");
                }
            })
            
        },
        pageChange (currentPage){
            this.paging.currentPage = currentPage;
            this.axiosTool.getUistar().then(result=>this.handleExpInfo(result));
        },
        handleClick (type){
            switch (type){
                case '发布' : 
                    if(this.inspectParam())return;
                    this.saveUistar();
                    break;
            };
        },
        queryEvaluate (expInfo){
            this.initData();
        },
        inspectParam (){
            const {fromData} = this;
            if(typeof fromData.content === 'undefined' || fromData.content===''){
                 ElMessage.warning("请输入您的评论！");
                return true;
            }
        },
        saveUistar (){
            const {fromData,uploadUrl,expInfo} = this;
            let request = {
                expautoid : expInfo.expautoid,
                content : fromData.content || '',
                star : fromData.star || 0,
                filepath_str : uploadUrl.join(',')
            };
            axios.post(server.uistar,request).then(result=>{
                if(result.status){
                    ElMessage({ showClose: true, message: '操作成功！', type: 'success' });
                    this.fromData = {};
                    this.uploadData = [];
                    this.uploadResult = [];
                    this.viewState.type = '主页';
                    this.refreshKey++;
                    this.axiosTool.getUistar().then(res=>{
                        this.handleExpInfo(res);
                    })
                }else {
                    ElMessage.error("操作失败");
                }
            })
        },
        getFloorData(floor, paging, parentid, callback) {
            let floorData = this.floorData[floor];
            let request = { page: paging.currentPage, per_page: paging.pageSize, ...floorData.extraParam };
            request[floorData.parentid] = parentid;
            axios.get(floorData.api + checker.objTurnParams(request)).then(result => {
                let answerData = [];
                if (result.status && result.data && result.data.result) {
                    result.data.result.forEach((item, index) => {
                        let answer = {
                            key: item.autoid,
                            name: item.user_data ? item.user_data.realname || item.user_data.username : "",
                            iconUrl: item.user_data ? item.user_data.face || require("@/assets/images/user_logo.png") : "",
                            time: item.createtime,
                            content: item.content,
                            imgs: [],
                            videos: [],
                            children: [],
                            star: item.star || 0,
                            isClose: true,
                            isRate: floorData.isRate || false,
                            rateStyle: floorData.rateStyle || {}
                        };
                        if(floorData.childsize) answer.childsize = item[floorData.childsize] || 0;
                        if(item.evalresource_data && item.evalresource_data.filepath) {
                            item.evalresource_data.filepath.split(",").forEach((path, fIndex) => {
                                let ext = path.split(".").pop();
                                if(this.mediaType.img.indexOf(ext) != -1) {
                                    answer.imgs.push({
                                        "url": path,
                                        "key": fIndex + 1
                                    });
                                } else if(this.mediaType.video.indexOf(ext) != -1) {
                                    answer.videos.push({
                                        "url": path,
                                        "key": fIndex + 1
                                    });
                                }
                            });
                        }
                        
                        answerData.push(answer);
                    });
                }
                callback(answerData);
            });
        },
        getFloorCount(floor, parentid, callback) {
            let floorData = this.floorData[floor];
            let request = { count: true, ...floorData.extraParam };
            request[floorData.parentid] = parentid;
            axios.get(floorData.api + checker.objTurnParams(request)).then(result => {
                let count = 0;
                if (result.status) {
                    count = result.data;
                }
                callback(count);
            });
        },
        updateRate() {
            let {answerItemCallback} = this;
            answerItemCallback && answerItemCallback();
        }
    },
    components : {
        PageItems,
        Uploads,
        Buttons,
        Answer,
        Details,
        Pagination,
        Evaluate,
        NoData,
    },
};
</script>

<style lang="less" scoped>
    .evaluate-reply{
        padding: 0 22px;
        .answer-page{
            width:100%;
            text-align:center;
            padding:20px 0 10px;
        }
    }
    .evaluate-ui{
        .evaluate-count{
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            margin-left: 15px;
        }

        .evaluate-option {
            padding: 0 22px;
            .evaluate-btn{
                margin-bottom: 10px;
            }
            .evaluate-btns{
                margin-top:10px;
                text-align: right;
            }
            >span:nth-child(1){
                display: flex;
                >span{
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 12px;
                    margin-left:10px;
                    display: inline-block;
                    padding: 10px 0;
                }
            }
            >span:nth-child(2){
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #1A1A1A;
                padding-top:0;
                display: flex;
                align-items: center;
            }
            >span{
                display: inline-block;
                padding:10px 0;
            }
        }

        .details-main {
            margin-top: 10px;
            flex: 1 1 auto;
            background-color: #FFFFFF;

            .details-answer {
                padding: 0 20px;
            }

            .answer-details {
                padding: 0 10px;
            }
            .details-page{
                display: flex;
                align-items: center;
                justify-content: center;
                height: 50px;
            }
        }
    }
</style>