<template>
    <!-- 我的金币 -->
    <div class="my-money">
        <div class="my-title">
          <span>我的金币</span>
        </div>
        <div class="money-main">
            <div class="money-msg">
                <PageTitle>
                    <template #cnName>我的资产</template>
                    <template #enName>My assets</template>
                    <div class="money-items">
                        <span><el-icon><question-filled /></el-icon>关于金币</span>
                        <div class="money-item">
                            <span><img src="@/assets/images/gold.png" alt="">金币</span>
                            <span>10000</span>
                        </div>
                        <div class="money-item">
                            <span>累计获得</span>
                            <span>10000</span>
                        </div>
                        <div class="money-item">
                            <span>累计使用</span>
                            <span>10000</span>
                        </div>
                        <div class="money-item">
                            <span>支付订单数</span>
                            <span>10000</span>
                        </div>
                    </div>
                </PageTitle> 
            </div>
            <div class="money-detailed">
                <PageTitle>
                    <template #cnName>近期明细</template>
                    <template #enName>Recent details</template>
                    <template #custom><span class='money-btn'>金币明细</span></template>
                    <div class="money-list">
                        <div class="list-item" v-for="item in dataList" :key="item.key">
                            <div class="item-left">
                                <span>完成每日登录</span>
                                <span>{{item.time}}</span>
                            </div>
                            <span>+5</span>
                        </div>
                    </div>
                    <div class="money-pagin">
                        <Pagination v-if="dataList.length"  :total='paging.total || 0' :pageSize='paging.pageSize || 10' 
                        :currentPage='paging.currentPage || 1' @pageChange='pageChange' />
                    </div>
                </PageTitle> 
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from '@/components/course/Pagination.vue';
import PageTitle from '@/components/course/PageTitle.vue';
export default {
    data (){
        return {
            paging : {
                total : 10,
                pageSize : 10,
                currentPage : 1,
            },
            dataList : [
                {key : '001',time:'2020-05-01 15:00'},
                {key : '002',time:'2020-06-02 10:00'},
                {key : '003',time:'2021-07-03 09:00'},
                {key : '004',time:'2021-09-04 14:00'},
                {key : '005',time:'2021-11-05 13:00'},
            ],
        }
    },
    methods : {
        pageChange (){

        },
    },
    components : {
        Pagination,
        PageTitle
    },
}
</script>

<style scoped lang="less">
    .my-money{
        width:100%;
        height:100%;
        .my-title{
            width:100%;
            height:53px;
            display: flex;
            align-items: center;
            justify-items: center;
            flex:0 0 53px;
            border-bottom: 1px solid #F5F5F5;
            >span{
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #1A1A1A;
            }
        }
        .money-main{
            overflow: hidden;
            .money-msg{
                width:100%;
                background-color: #F5F5F5;
                overflow: hidden;
                .money-items{
                    position: relative;
                    display: flex;
                    justify-content: space-around;
                    background-color: #FFF;
                    margin: 10px;
                    border-radius: 4px;
                    padding-right: 30px;
                    .money-item{
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        padding: 30px 0;
                        >span:nth-child(1){
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #1A1A1A;
                            flex:1 1 auto;
                            display: flex;
                            align-items: center;
                            display: inline-block;
                            margin-bottom: 10px;
                        }
                        >span{
                            font-size: 26px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #1A1A1A;
                            flex:0 0 auto;
                            >img{
                                width:33px;
                                height:26px;
                                margin-right:10px;
                            }
                        }
                        
                    }
                    .money-item:nth-child(2){
                        &::after{
                            position: absolute;
                            right: -70px;
                            top: 50%;
                            transform: translate(0, -50%);
                            content: '';
                            width: 1px;
                            height: 80px;
                            background-color: #E5E5E5;
                        }
                    }
                    >span{
                        position: absolute;
                        right:0;top:0;
                        font-size: 13px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #2974EA;
                        padding:10px;
                        display:flex;
                        >i{
                            color:#1E5FC2;
                            font-size: 14px;
                            margin-right:5px;
                        }   
                    }
                }
            }
            .money-detailed{
                width:100%;
                overflow: hidden;
                margin-top:15px;
                .money-btn{
                    margin-left:auto;
                    font-size: 13px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #2974EA;
                }
                .money-list{
                    width:100%;
                    .list-item{
                        display: flex;
                        align-items: center;
                        padding: 15px 10px;
                        border-bottom: 1px solid #E6E6E6;
                        .item-left{
                            margin-right: auto;
                            display: flex;
                            flex-direction: column;
                            text-align: left;
                            >span:nth-child(1){
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #1A1A1A;
                                margin-bottom: 10px;
                            }
                            >span:nth-child(2){
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #B6B6B6;
                            }
                        }
                        >span{
                            font-size: 26px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #FF635F;
                            margin-left:auto;
                        }
                    }
                }
                .money-pagin{
                    padding:20px 0;
                    text-align:center;
                }
            }
            
        }
        .money-main.items{
            background-color: #FFFFFF;
            margin-top:15px;
        }
    }

</style>