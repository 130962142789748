<template>
    <div class="News_Con">
      <!-- {{con_key}} -->
      <div class="Con_item" @click="to_details(item)"  v-for="item in contentList" :key="item.key" >
          <div class="left_Time">
               <span class="news-date-day">{{item.day}}</span>
                <span class="news-date-year">{{item.time}}</span>
          </div>
          <span class="right_Title">{{item.title}}</span>
      </div>
    </div>
    <div class="footer" v-if="true">
        <Pagination 
        :total='params.total || 0' 
        :pageSize='params.pageSize || 5' 
        :currentPage='params.currentPage || 1'
        :hideOnSinglePage='false'
        @pageChange='pageChange'
        />
    </div>
</template>

<script>
import axiosTool from '@/utils/axios_tool';
import server from "@/service/server";
import checker from '@/utils/checker'
import common from "@/utils/common";
import Pagination from '@/components/course/Pagination.vue';
export default {
    name:'newscontent',
    props : {
        keyy : {
            type : Number,
            default : 4
        }
    },
    components:{
        checker,
        axiosTool,
        server,
        Pagination
    },
    watch:{
        keyy:"keyMonitor"
    },
    created(){
          this.axiosTool = new axiosTool(this,[
              {
                  key:'get_content_data',title:'获取内容数据',type:'get',url:server.coverage,params:{
                  template:'questData:newstypeid,params:page|per_page',
                  getreq:{get_all:true,publish:2,typeid:1, _order_column:'createtime',_order_style:'desc'}
                  }
              },
              {
                  key:'get_content_count',title:'获取内容数据的数量',type:'get',url:server.coverage,params:{
                  template:'questData:newstypeid',
                  getreq:{get_all:true,publish:2,typeid:1,count:true, _order_column:'createtime',_order_style:'desc'}
                  }
              }
             
          ])
    },
    mounted(){
        this.keyMonitor();
        this.questDataFun();
       
    },
    data(){
        return{
              params:{
                total:0,
                pageSize:10,
                currentPage:1,
                page:1,
                per_page:10
            },
            questData:{relevance:'index'},
            con_key:4,
            contentList:[
            ]
        }
    },
    methods:{
        Godetail(data){
            console.log(data)
        },
        pageChange(data){
            // let {params} = this;
           if(!!data){
              this.params.currentPage = Number(data);
              this.params.page = Number(data)
              this.questDataFun();
           }
        },
        to_details (data){
            console.log(data)
            common.removeParams('/GwNoticeText');
            // common.setStorParams(this,{autoid: data.key});
            this.$router.push('/GwNoticeText'+'?id=' + data.autoid);
        },
        to_details2 (data){
            common.removeParams('/news_details');
            // common.setStorParams(this,{autoid: data.key});
            this.$router.push('/news_details'+'?id=' + data.key);
        },
        keyMonitor(res){
            if(!!res ){
                if(res!=4){
                    this.questData.newstypeid = Number(res);
                }else{
                 this.questData.newstypeid = undefined;
                }
            }
            this.questDataFun();

            // console.log(this.params.total)
        },
        
        questDataFun(){
            this.axiosTool.get_content_data().then(res=>{
                if(res.status && res.data && res.data.result){
                    this.formatTimeFun(res.data.result);
                    this.axiosTool.get_content_count().then(res=>{
                        if(res.status && res.data){
                            this.params.total = Number(res.data);
                        }
                    })
                    
                }
            })
        },
        formatTimeFun(testArray){
            let {contentList} = this;
            // let testArray = contentList;
            testArray.map(item=>{
                item.time = checker.timestampToDate(new Date(item.createtime).getTime(),'YYYY-MM');
                item.day = checker.timestampToDate(new Date(item.createtime).getTime(),'DD');
            })
            this.contentList = {...testArray};
            // console.log(this.contentList)
        }
    }
}

</script>

<style lang="less" scoped>
.News_Con{
    
    .Con_item{
        width: 94%;
        display: flex;
        margin-bottom: 20px;
        height: 62px;
        background-color: rgb(247,249,250);
        cursor: pointer;
        padding:10px 0;
        .left_Time{
            display: flex;
            flex-direction: column;
            color: goldenrod;
            width: 100px;
            align-items: center;
            line-height: 17px;
            padding-top: 10px;
            .news-date-day{
                font-size: 20px;
                font-weight: bold;
                margin-bottom:5px;
                text-align:center;
            }
            .news-date-year{
                padding-top:5px;
                border-top:1px solid #BFBFBF;
            }
        }
        .right_Title{
            line-height: 62px;
            text-align: left;
        }
    }
}
.footer{
    text-align: center;
    width: 90%; 
    margin-bottom: 20px;
}

</style>