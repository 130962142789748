<template>
  <!-- 活动专题详情 -->
  <div class="special-ui common-width">
    <div class='crumbs'>
      <el-breadcrumb separator-class='el-icon-arrow-right'>
        <el-breadcrumb-item to='/special'>活动专题</el-breadcrumb-item>
        <el-breadcrumb-item><span class='crumbs-item'>{{title}}</span></el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="special-intro-title">{{ title }}</div>
    <div class="special-intro-entitle">
      {{ subtitle }}
    </div>
    <div class="special-intro-icon"><span></span></div>
    <div class="special-intro">{{ introduction }}</div>
    <div class="special-intro-ul" v-for="item in expData" :key="item.key">
      <div class="special-intro-ul-li">
        <img :src="item.url" alt="" />
        <div class="special-intro-ul-li-con">
          <span>{{ item.title }}</span>
          <div class="uspecial">
            <span>{{ item.major }}</span>
            <span>{{ item.realname }}</span>
          </div>
          <div class="aspecial">
            <span>{{ item.level }}</span>
            <span>{{ item.year }}</span>
          </div>
          <div>
            <el-button class="special-btn" size="small" @click="handleExpClick(item)">前往学习</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Buttons from "@/components/course/Buttons.vue";
import common from "@/utils/common";
import axios from "@/utils/axios";
import server from "@/service/server";
export default {
  data() {
    return {
      title: "",
      subtitle: "",
      introduction: "",
      expData: [],
    };
  },
  components: { Buttons },
  mounted() {
    let storeParams = common.getStorParams({ path: "/NewsFeature" });
    if (!storeParams) storeParams = common.getStorParams(this);
    // //console.log(storeParams);
    this.title = storeParams.title;
    this.subtitle = storeParams.subtitle;
    this.introduction = storeParams.content;
    this.getExpData(storeParams.exp_data);
    // console.log(storeParams);

  },
  methods: {
    getExpData(data) {
      if(!data)return;
      let expData = [];
      if (data.length != 0) {
        data.map((item) => {
          if (item) {
            let major, realname, year, level;
            if(item.major_data){
              major = item.major_data.name
            }
            if(item.master_data){
              realname = item.master_data.realname
            }
            if(item.expdeclareyear_data){
              year = item.expdeclareyear_data.year
            }
            switch(item.level){
              case "1":
                level = "国家级";
                break;
              case "2":
                level = "省级";
                break;
              case "3":
                level = "非申报";
                break;
              case "4":
                level = "其他";
                break;
            }
            if(parseInt(item.publish) == 2){
              expData.push({
                publish: item.publish,
                key: item.autoid,
                url: item.face,
                title: item.title,
                major: major || '',
                realname: realname || '',
                level: level,
                year: year || '',
              });
            }
            this.expData = expData;
          }
        });
      }
    },
    handleExpClick(data) {
      // //console.log(data);
      common.removeParams('/exps');
      common.setStorParams({ path: "/expcore" }, { autoid: data.key });
      this.$router.push("/expcore");
      this.postExppv(data.key);
    },
    postExppv(autoid){//浏览量
      let request = {
        expautoid: autoid
      }
      axios.post(server.exppv,request).then(result=>{
        if(result.status){
          return true;
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
@import url("@/styles/common.less"); //公共样式
.media; //媒体查询
.special-ui {
  width: 100%;
  margin: auto;
  .crumbs{
    padding:10px 0 10px;
    .crumbs-item{
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
  }
  .special-intro-title {
    padding: 20px 12px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-family: 'Microsoft YaHei';
    font-weight: bold;
    color: #333333;
  }
  .special-intro-entitle {
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: 'Microsoft YaHei';
    font-weight: 400;
    color: #B6B6B6;
  }
  .special-intro-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    > span {
      content: "";
      display: inline-block;
      height: 3px;
      width: 72px;
      background-color: #1e5fc2;
    }
  }
  .special-intro {
    margin: 10px 12px 10px;
    text-align: left;
    text-indent: 2em;
    font-size: 12px;
    font-family: 'Microsoft YaHei';
    font-weight: 400;
    color: #989898;
    line-height: 28px;
  }
  .special-intro-ul {
    width: 570px;
    display: inline-block;
    margin: 10px 10px;
    border: #6fa8ff solid 1px;
    border-radius: 10px;
    background-color: #ffffff;
    .special-intro-ul-li {
      display: flex;
      justify-content: space-between;
      > img {
        width: 50%;
        height: 174px;
        border-radius: 10px;
      }
      .special-intro-ul-li-con {
        width: 50%;
        display: flex;
        flex-direction: column;
        text-align: left;
        > span {
          padding-left: 5%;
          padding-top: 5%;
          // height: 25%;
          font-size: 16px;
          font-family: 'Microsoft YaHei';
          font-weight: bold;
          color: #1A1A1A;
          line-height: 21px;
          word-break: break-all;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

        }
        .uspecial {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: 5%;
          padding-right: 5%;
          padding-bottom: 5%;
          >span:first-child{
            font-size: 16px;
            font-family: 'Microsoft YaHei';
            font-weight: 400;
            color: #1A1A1A;
          }
          > span:last-child {
            text-align: center;
            font-size: 14px;
            font-family: "Microsoft YaHei";
            font-weight: 400;
            color: #999999;
            border-radius: 4px;
            padding: 5px;
            background: #F2F2F2;
          }
        }
        .aspecial {
          display: flex;
          padding-left: 5%;
          padding-right: 5%;
          padding-bottom: 5%;
          > span:first-child {
            padding: 5px;
            background: #6fa8ff;
            line-height: 15px;
            border-radius: 5px;
            font-size: 12px;
            font-family: 'Microsoft YaHei';
            font-weight: 400;
            color: #FFFFFF;
          }
          > span:last-child {
            padding: 5px;
            margin-left: 20px;
            background: #6fa8ff;
            line-height: 15px;
            border-radius: 5px;
            font-size: 14px;
            font-family: 'Microsoft YaHei';
            font-weight: 400;
            color: #FFFFFF;
          }
        }
        > div {
          .special-btn {
            background: #fff;
            border: #6fa8ff 1px solid;
            float: right;
            margin-right: 5%;
            font-size: 14px;
            font-family: 'Microsoft YaHei';
            font-weight: 400;
            color: #2974EA;
          }
        }
      }
    }
  }
}
</style>