<template>
  <!-- 我的订单 -->
  <div class="my-order">
      <div class="my-title">
          <span>我的订单</span>
          <div class="my-search">
                <el-input size='small' class="my-search-input" v-model="searchData.username" placeholder="请输入关键字" />
                <Buttons type="info">重置</Buttons>
                <Buttons type="blue" icon="el-icon-search" >查询</Buttons>
          </div>
      </div>
      <div class="order-main">
        <TabsNew :active="active" :menuData="menuData">
          <template #default>
            <div class="order-table">
              <div class="order-items order-head">
                <span>订单信息</span>
                <span>金币数</span>
                <span>时效</span>
                <span>交易状态</span>
                <span>操作</span>
              </div>
              <div class="order-items order-body">
                <div class="order-item" v-for="item in dataList" :key="item.key">
                  <span>2020-10-08 15:02:00 订单编号：{{item.code}}</span>
                  <div class="item-main">
                    <img src="@/assets/images/linux.png" alt="">
                    <div class="item-li">
                      <img src="@/assets/images/gold.png" alt="">10000
                    </div>
                    <span class="item-li">永久有效</span>
                    <span class="item-li">{{item.state}}</span>
                    <div class="item-li">
                      <span>付款截止</span>
                      <span>剩余22时33分</span>
                      <span><Buttons type="warning">继续支付</Buttons></span>
                      <span>取消支付</span>
                    </div>
                  </div>
                </div>

                <div class="order-pagin">
                    <Pagination v-if="dataList.length"  :total='paging.total || 0' :pageSize='paging.pageSize || 10' 
                            :currentPage='paging.currentPage || 1' @pageChange='pageChange' />
                </div>
              </div>
            </div>
          </template>
        </TabsNew>
      </div>
  </div>
</template>

<script>
import Buttons from '@/components/course/Buttons.vue';
import Pagination from '@/components/course/Pagination.vue';
import TabsNew from '@/components/business/Tabs/index.vue';
export default {
  data (){
    return {
      searchData : {},
      active : '',
      paging : {
          total : 10,
          pageSize : 10,
          currentPage : 1,
      },
      menuData : [
        {key : '001',title : '全部订单',value : ''},
        {key : '002',title : '待支付',value : ''},
        {key : '003',title : '已完成',value : ''},
        {key : '004',title : '已取消',value : ''},
        
      ],
      dataList : [
        {key : '001',state:'已支付',code:'32205697591'},
        {key : '002',state:'待支付',code:'32205697592'},
        {key : '003',state:'已支付',code:'32205697593'},
      ],
    }
  },
  methods : {
    pageChange (){
      
    },
  },
  components : {
    Buttons,
    Pagination,
    TabsNew,
  }
}
</script>

<style scoped lang="less">
  .my-order{
    width:100%;
    height:100%;
    .my-title{
        width:100%;
        height:53px;
        display: flex;
        align-items: center;
        justify-items: center;
        flex:0 0 53px;
        border-bottom: 1px solid #F5F5F5;
        >span{
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #1A1A1A;
        }
        .my-search{
            display: flex;
            align-items: center;
            margin-left:auto;
            .my-search-input{
                margin-right:10px;
            }
        }
    }

    .order-main{
      width:100%;
      .order-table{
        width:100%;
        margin-top: 10px;
        .order-items{
          padding: 0 10px;
          >span{
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }

          .order-pagin{
            padding:20px 0;
            text-align:center;
          }
        }
        .order-head{
          display: flex;
          height:30px;
          background-color: #F5F5F5;
          border-radius: 4px;
          line-height: 30px;
          justify-content: space-between;
          >span:nth-child(1){
            flex:0 0 154px;
          }
          >span:nth-child(2){
            flex:0 0 94px;
          }
          >span:nth-child(3){
            flex:0 0 65px;
          }
          >span:nth-child(4){
            flex:0 0 50px;
          }
          >span:nth-child(5){
            flex:0 0 82px;
          }
        }
        .order-body{
          .order-item{
            display: flex;
            flex-direction: column;
            >span{
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #1A1A1A;
              text-align: left;
              padding: 20px 0 5px;
            }
            .item-main{
              width:100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              >img{
                width: 154px;
                height: 92px;
                border-radius: 4px;
              }
              .item-li:nth-child(2){
                font-size: 19px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #1A1A1A;
                display: flex;
                align-items: center;
                >img{
                  width: 25px;
                  height: 19px;
                  margin-right:10px;
                }
              }
              .item-li:nth-child(3){
                  width:65px;
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  color: #FF635F;
              }
              .item-li:nth-child(4){
                  width:50px;
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #1A1A1A;
              }
              .item-li:nth-child(5){
                display: flex;
                flex-direction: column;
                  >span:nth-child(1),>span:nth-child(2){
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FF635F;
                    margin-bottom: 10px;
                  }
                  >span:nth-child(3){
                    margin-bottom: 10px;
                  }
                  >span:nth-child(4){
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #060606;
                  }
              }
            }
            

          }
        }
      }
    }
  }
</style>