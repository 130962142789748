<template>

    <div class="layout_20230209_b19b">
        <div class="layout-block">
            <div class="block-left"><slot name="logo"></slot></div>
            <div class="block-right"><slot name="login"></slot></div>
        </div>
        <img class="layout-line" :src="`${base_url}images/home/line.png`" alt="">
        <div class="layout-block">
            <div class="block-left menu"><slot name="menu"></slot></div>
            <div class="block-right"><slot name="contact"></slot></div>
        </div>
    </div>
</template>

<script>    
    export default {
        name : 'layout_20230209_b19b',
        data (){
            return {

            }
        }
    }
</script>


<style scoped lang="less">
    .layout-line{
        padding-top: 5px;
    }
    .layout_20230209_b19b{
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        height:100%;
        .layout-block{
            width: 100%;
            flex:0 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .block-left{
                flex:0 0 auto;
            }
            .block-left.menu{
                flex:1 1 auto;
            }
            .block-right{
                flex:0 0 auto;
                display: flex;
                align-items: center;
            }
        }
    }
</style>