<template>
    <div class='my-exp-detail'>
        <div class='exp-box common-width'>
            <div class='exp-title'>
                <span @click='toBack'><el-icon><arrow-left-bold /></el-icon>我的实验</span>
                <span>{{expInfo.title}}</span>
            </div>
            <div class='exp-main'>
                <div class='exp-menu'>
                    <div @click='handlePitch(menu)' :class="['menu-item',active === menu.key ? 'active' : '']" v-for='menu in menuData' :key='menu.key'>
                        {{menu.title}}
                    </div>
                </div>
                <div class='exp-content'>
                    <template v-if="active==='实验记录'">
                        <Record :expInfo='expInfo' />
                    </template>
                    <template v-if="active==='实验提问'">
                        <Issue :expInfo='expInfo' />
                    </template>
                    <template v-if="active==='实验笔记'">
                        <Note :expInfo='expInfo' />
                    </template>
                    <template v-if="active==='实验评价'">
                        <Evaluate :expInfo='expInfo' />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Buttons from '@/components/course/Buttons.vue';
import Record from './record.vue';
import Issue from './issue.vue';
import Note from './note.vue';
import Evaluate from './evaluate.vue';
import common from "@/utils/common";
export default {
    name: 'myexp',
    data() {
        return {
            menuData : [
                {key : '实验笔记',title:'实验笔记',},
                {key : '实验提问',title:'实验提问',},
                {key : '实验记录',title:'实验记录',},
                {key : '实验评价',title:'实验评价',},
            ],
            expInfo : {},
            active: "",
            expautoid: "",
        };
    },
    mounted() {
        this.init();
    },

    methods: {
        init (){
            let expInfo = common.getStorParams(this);
            if(expInfo){
                this.expInfo = expInfo;
                if(expInfo.myexpType) this.active = expInfo.myexpType;
                if(expInfo.expautoid) this.expautoid = expInfo.expautoid;
            }
        },
        handlePitch (data){
            this.active = data.key;
        },
        toBack (){
            this.$router.push('/my_center');
        },        
    },
    components : {
        Buttons,
        Record,
        Issue,
        Note,
        Evaluate,
    },
};
</script>

<style lang="less" scoped>
    @import url('@/styles/common.less');//公共样式
    .media;//媒体查询
    .my-exp-detail{
        height:100%;
        padding-top:10px;
        background-color: #F2F2F2;
        height: 100%;
        .exp-box{
            display:flex;
            flex-direction: column;
            height: 100%;
            margin:auto;
            background-color:#FFF;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
        .exp-title{
            position:relative;
            display:flex;
            height:80px;
            align-items:center;
            border-bottom:1px solid #F5F5F5;
            padding: 0 10px;
            flex:0 0 80px;
            >span:nth-child(1){
                
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                display:flex;
                cursor:pointer;
                >i{
                    margin-right:5px;
                }
            }
            >span:nth-child(2){
                position:absolute;
                left:50%;
                top:50%;
                transform:translate(-50%,-50%);
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #1A1A1A;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                width: 61%;
            }
            >span:nth-child(3){
                margin-left:auto;
            }
        }
        .exp-main{
            display:flex;
            flex:1 1 auto;
            width: 100%;
            .exp-menu{
                width: 18%;
                flex:0 0 210px;
                padding:10px;
                background-color:#FAFAFA;
                .menu-item{
                    width: 210px;
                    height: 50px;
                    border-radius: 4px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 50px;
                    text-align: center;
                    color: #999999;
                    opacity: 0.9;
                    cursor:pointer;
                }
                .menu-item.active{
                    box-shadow: 0px 3px 14px 0px rgba(41, 116, 234, 0.33);
                    background-color: #2974EA;
                    color: #FFFEFE;
                    opacity: 1;
                }
            }
            .exp-content{
                width: 80%;
                flex:1 1 auto;
                box-sizing: border-box;
            }
        }
    }
</style>