<template>
  <div class="message-box" v-if="state">
      <div class="message-plate" :style='style'>
          <div :class="['message-title',titlePosition || '']" v-if="title">
            <span class="message-txt">{{title}}</span>
            <span class="message-close" @click="$emit('close')"><el-icon><close-bold /></el-icon></span>
          </div>
          <slot name="title"></slot>
          <div class="message-content">
            <slot></slot>
          </div>
          <div class="message-footer">
              <slot name="footer"></slot>
          </div> 
      </div>
  </div>
</template>

<script>
export default {
  props : {
    state : {
      type : Boolean,
      default : false,
    },
    title : {
      type : String,
      default : '',
    },
    titlePosition : {//left center
      type : String,
      default : '',
    },
    style:{
      type : Object,
      default : ()=>{return {
        width:'500px',
      }},
    }
  }
}
</script>


<style scoped>
  .message-box{
    position: fixed;
    left:0;right:0;
    top:0;bottom:0;
    z-index: 100;
    background-color: rgba(26,26,26,.3);
  }
  .message-plate{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    min-width: 500px;
    min-height: 150px;
    background-color: #FFF;
    border-radius: 10px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
  }
  .message-box .message-plate .message-title{
    color: #1A1A1A;
    font-size:20px;
    padding-bottom: 10px;
    text-align: left;
    border-bottom: 1px solid #F2F2F2;
    flex: 0 0 auto;
  }

  .message-box .message-plate .message-title.center{
    text-align: center;
  }

  .message-box .message-plate .message-title .message-txt{
    font-size:20px;
    font-weight: bold;
  }

  .message-box .message-plate .message-title .message-close{
    float:right;
    cursor: pointer;
  }
  .message-box .message-plate .message-title .message-close>i{
    color:#999191;
    font-size: 25px;
  }
  
  

  .message-box .message-plate .message-content{
    flex:1 1 auto;
    padding-top: 10px;
  }
  .message-box .message-plate .message-footer{
    flex:0 0 auto;
    padding-top: 10px;
    text-align:center;
  }
</style>