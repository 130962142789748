<template>
    <div class='evaluate-loop' v-for='item in source' :key='item.key'>
        <div class='evaluate-item'>
            <Evaluate @requestBefore="data=>requestBefore(data.type?`外_${data.type}`:data.nodeType, data.data,data.callback)" :httpInfo='evaluateHttpInfo' :option='evaluateOption' :data='item' @toClick="type=>handleClick(type,item)"/>
        </div>
        <template v-if='item.children && item.children.length'>
            <div class='evaluate-child' v-show='!!actives[item.key]'>
                <Node :ref="'node_'+item.key" 
                @requestBefore="data=>requestBefore(`内_${data.type}`, 
                data.data,data.callback)"  
                :option='nodeOption' 
                :evalData='item'
                :userData='userData'
                :data='item.children'></Node>
            </div>
        </template>
    </div>
</template>
<script>
import common from "@/utils/common";
import Uploads from '@/components/common/Uploads/Uploads.vue';
import Buttons from '@/components/course/Buttons.vue';
import Evaluate from './main.vue';
import Node from './node.vue';
import axiosTool from '@/utils/axios_tool';

export default {
    name: 'evaluateLoop',

    data() {
        return {
            source : [],
            actives : {},
            nodeOption : [],//菜单配置
            evaluateOption : [],//菜单配置
            nodeHttpInfo : {},//接口配置
            evaluateHttpInfo : {},//接口配置
        };
    },
    watch : {
        data : 'dataMonitor',
        option : "optionMonitor",
        httpInfo : 'httpInfoMonitor',
    },
    props : {
        data : {
            type : Array,
            default : ()=>{return []}
        },
        option : {
            type : Array,
            default : ()=>{return []}
        },
        httpInfo : {
            type : Object,
            default : ()=>{return {}},
        },
        userData : {
            type : Object,
            default : ()=>{return {}},
        },
    },
    emits : [
        'requestBefore',
        'refresh',
        'update:data'
    ],
    created (){
        
    },
    mounted() {
        this.dataMonitor();
        this.optionMonitor();
        this.httpInfoMonitor();
    },

    methods: {

        httpInfoMonitor (httpInfo=this.httpInfo){
            let nodeHttpInfo = {},
            evaluateHttpInfo = {};
            for(const httpUrl in httpInfo){
                if(httpUrl === 'children'){
                    nodeHttpInfo = httpInfo[httpUrl];
                }else {
                    evaluateHttpInfo[httpUrl] = httpInfo[httpUrl];
                }
            }
            this.nodeHttpInfo = nodeHttpInfo;
            this.evaluateHttpInfo = evaluateHttpInfo;
        },
        uploadBefore(){//上传之前的回调 检测是否符合上传文件的条件

        },
        dataMonitor (data=this.data){
            this.source = data;
        },
        getNodeData (){

        },
        getEvaluateData(){

        },
        optionMonitor (data=this.option){
            if(!data)return ;
            let nodeOption = [],
            evaluateOption = [];
            data.map(item=>{
                if(Array.isArray(item)){
                    nodeOption = [...item]
                }else {
                    evaluateOption.push(item);
                }
            });
            this.nodeOption = nodeOption;
            this.evaluateOption = evaluateOption;
            this.evaluateOption = evaluateOption;
            
        },
        handleClick (type,data){
            const {$refs:refs} = this;
            switch (type){
                case '回复' : 
                    this.isEdit = !this.isEdit;
                    break;
                case '取消' : 
                    this.isEdit = false;
                    break;
                case '评论' : 
                    if(this.actives[data.key]) {
                        this.actives[data.key] = !this.actives[data.key];
                        return;
                    }
                    data.pageSize = 5;
                    data.currentPage = 1;
                    this.$emit('requestBefore',{type:'外_评论',data,callback:result=>{
                        this.renderData(data,result);
                        this.actives[data.key] = !this.actives[data.key];
                    }});
                    // if(this.$refs['evaluate_'+data.key]){
                    //     let elem = this.$refs['evaluate_'+data.key][0];
                    // }
                    //console.log(data);
                    //console.log(elem.offsetHeight,elem.clientHeight,elem.scrollHeight);
                    
                    
                    break; 
            }
        },
        renderData (data,dataSrouce){
            const {source} = this;
            let idx = source.findIndex(item=>item.key === data.key);

            let children = source[idx].children,cache = [];
            for(let item of dataSrouce){
                let idx = children.findIndex(ite=>typeof ite.uistarautoid ==='undefined' && ite.autoid === item.autoid);
                if(idx < 0){
                    item.to = data.realname;
                    item.toIcon = data.userIcon;
                    cache.unshift(item);
                }else {
                    children[idx] = item;
                }
            }
            children = [...children,...cache]


            source[idx].children = dataSrouce;

            this.source = [...source];
            this.$emit('update:data',source);
        },
        requestBefore (type,data,callback,parent){//请求之前的回调
            const {source,$refs:refs} = this;
            switch (type){
                case '外_刷新' :
                    this.$emit('requestBefore',{type,data,callback});
                    break;
                case '外_删除' : 
                    this.$emit('requestBefore',{type,data,callback});
                    break;
                case '外_发布' : 
                    if(refs['node_'+data.key]){
                        let elem = refs['node_'+data.key][0];
                        data.pageSize = elem.pageSize * elem.currentPage;
                        data.currentPage = 1;
                    }
                    this.$emit('requestBefore',{type,data,callback:result=>{
                        if(this.$refs.node)this.renderData(data,result);
                    }});
                    break;
                case '外_收藏' : 
                    this.$emit('requestBefore',{type,data,callback});
                    break;
                case '外_点赞' : 
                    this.$emit('requestBefore',{type,data});
                    break;
                case '内_刷新' :
                    this.$emit('requestBefore',{type,data,callback:result=>{
                        this.renderData(data,result);
                    }});
                    break;
                case '内_删除' : 
                    this.$emit('requestBefore',{type,data,callback});
                    break;
                case '内_发布' : 
                    this.$emit('requestBefore',{type,data,callback:result=>{
                        this.renderData(data.parent,result);
                    }});
                    break;
                case '内_点赞' : 
                    this.$emit('requestBefore',{type,data,callback});
                    break;
                case '内_收藏' : 
                    this.$emit('requestBefore',{type,data,callback});
                    break;
                case '外_上传' : 
                    this.$emit('requestBefore',{type,callback});
                    break;
                case '内_上传' : 
                    this.$emit('requestBefore',{type,callback});
                    break;
                case '内_评论' :
                    this.$emit('requestBefore',{type:'内_评论',data,callback:result=>{
                        let sourceIdx = source.findIndex(item=>item.autoid === data.uistarautoid);
                        if(sourceIdx>=0){
                            let children = source[sourceIdx].children,cache = [];
                            for(let item of result){
                                let idx = children.findIndex(ite=>typeof ite.uistarautoid ==='undefined' && ite.autoid === item.autoid);
                                if(idx < 0){
                                    item.to = data.realname;
                                    item.toIcon = data.userIcon;
                                    cache.push(item);
                                }
                            }
                            children = [...children,...cache]
                            source[sourceIdx].children = children;
                        }
                        this.source = source;
                        this.$emit('update:data',source);
                    }});
                    break;
            }
        },
    },
    components : {
        Uploads,
        Buttons,
        Evaluate,
        Node,
    },
};
</script>

<style lang="less" scoped>
    .evaluate-loop{
        
    }
    .evaluate-child{
        padding-left:95px;
    }
    .evaluate-item{
        padding:10px 0;
    }
</style>