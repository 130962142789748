<template>
    <div class='record-ui'>
        <!--实验记录-->
        <template v-if="viewState === '实验记录'">
            <div class='record-main'>
                <Table :columnData='columnData' :tableData='tableData' :params='tableParams' :selectedKeys='selectedKeys'>
                    <template #head>
                        <div class="record-form">
                            <el-form size='small' :inline="true" :model="searchData" class="demo-form-inline">
                                <el-form-item label="实验时间：" class="form-item">
                                    <el-date-picker unlink-panels @change='pickerChange' range-separator="至" v-model="searchData.time" class="actions-item" type="daterange" > </el-date-picker>
                                </el-form-item>
                                <el-form-item label="综合得分：" class="form-scope">
                                    <span class='score-before'><el-input maxlength='3' v-model="searchData.score.left"  size="small"/></span>
                                    <span class='score-center'>~</span>
                                    <span class='score-after'><el-input maxlength='3' v-model="searchData.score.right" size="small"/></span>
                                </el-form-item>
                                <el-form-item label="综合评价：" class="form-select">
                                    <el-select v-model="searchData.gradelevel"  size="small">
                                        <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                
                                <el-form-item label="" class="form-item" >
                                    <Buttons type='blue' plain @click="handleReset">重置</Buttons>
                                    <Buttons type='blue' icon='Search' @click="handleSerach">搜索</Buttons>
                                </el-form-item>
                            </el-form>
                            <div class="record-options">
                                <span><Buttons icon='Histogram' @click='handleClick' plain type='blue'>我的学习报表</Buttons></span>    
                                <span><Buttons icon='Upload' @click='handleExport' type='blue'>导出</Buttons></span>    
                            </div>
                        </div>
                    </template>
                </Table>
            </div>
        </template>
        <template v-if="viewState === '记录详情'">
            <Details :userData="userData"  :recordAutoid="recordAutoid" :expInfo="expInfo" @toBack="viewState='实验记录';"/>
        </template>
        <MessageBoxs :style="{width:'800px'}" :title="popupStates.title || '弹窗标题'" :state='popupStates.state' @close="closePopup">
            <template v-if="popupStates.title === '我的学习报表'">
                <div class="record-popup">
                    <div class="popup-item" v-for="item in msgList" :key="item.key">
                        <span>{{item.label}}</span>{{item.value}}
                    </div>
                    <div class="popup-echars">
                        <span >*滚动鼠标滚轮可查看更多数据</span>
                        <Echars v-if="lineGraphShow" type="line" :option="lineOptions" :data="lineData" :updateOptions="{scroll: true}" :updateMethod="lineUpdaeMethod" :formatter="lineFormatter" :updateInit="true"></Echars>
                        
                    </div>
                </div>
            </template>
        </MessageBoxs>
    </div>
</template>

<script>
import Table from '@/components/course/Table.vue';
import MessageBoxs from '@/components/course/MessageBoxs.vue';
import Details from './recordDetails.vue';
import Buttons from '@/components/course/Buttons.vue';
import Echars from '@/components/common/echars/index.vue';
import { ElMessage, ElLoading  } from 'element-plus';
import checker from '@/utils/checker';
import server from "@/service/server";
import axios from '@/utils/axios';
import common from "@/utils/common.js";

export default {
    name: 'record',
    data() {
        return {
            columnData : [
                {key : 'realname',label : '姓名',style:{width:80},align:'center'},
                {key : 'username',label : '账号',align:'center'},
                {key : 'createtime',label : '记录生成时间',style:{width:160},align:'center'},
                {key : 'duration',label : '实验时长(min)',style:{width:120},align:'center'},
                {key : 'score',label : '操作得分',style:{width:80},align:'center'},
                {key : 'reportScore',label : '报告得分',style:{width:80},align:'center'},
                {key : 'finalScore',label : '综合得分',style:{width:80},align:'center'},
                {key : 'finalscorelevel',label : '综合评价',style:{width:80},align:'center'},
                {key : 'action',style:{width:80},label : '实验报告',options : ()=>{
                    return [
                    {key : '2',label : '详情',on_click:this.handleDetail},
                    ];
                }},
            ],
            msgList : [
                {key : '001',type: "exp_num", label:'实验总次数：',value:'0'},
                {key : '002',type: "max_score", label:'最高分：',value:'0'},
                {key : '003',type: "min_score", label:'最低分：',value:'0'},
                {key : '004',type: "avg_score", label:'平均分：',value:'0'},
                {key : '005',type: "sum_life", label:'总时长(min)：',value:'0'},
                {key : '006',type: "min_life", label:'最短用时(min)：',value:'0'},
                {key : '007',type: "max_life", label:'最长用时(min)：',value:'0'},
                {key : '008',type: "avg_life", label:'平均时长(min)：',value:'0'},
            ],
            tableParams : {
                pageChange: this.pageChange,
                total: 0,
                pageSize: 10,
                currentPage: 1
            },
            options: [
                { key: 0, value: 0, label: "全部" },
                { key: 1, value: 1, label: "优秀" },
                { key: 2, value: 2, label: "良好" },
                { key: 3, value: 3, label: "及格" },
                { key: 4, value: 4, label: "不及格" },
            ],
            searchData : {
                time: [new Date(new Date().setMonth((new Date().getMonth()-6))), new Date()],
                score: { left: null, right: null },
                gradelevel: 0,
            },
            popupStates : {
            },
            lineOptions : {
                name : '',
                legendData : ['实验成绩','实验报告','综合成绩'],
                xAxisType: "value",
                xAxisData: []
            },
            lineData : [
                {
                    name: '实验成绩',
                    type: "line",
                    smooth: true,
                    data: []
                },
                {
                    name: '实验报告',
                    type: "line",
                    smooth: true,
                    data: []
                },
                {
                    name: '综合成绩',
                    type: "line",
                    smooth: true,
                    data: []
                }
            ],
            linePaging: {
                total: 0,
                pageSize: 0,
                currentPage: 1
            },
            lineGraphShow: false, // 默认不显示学习报表曲线
            selectedKeys : [],
            defaultValue : '',
            tableData : [],
            viewState : '实验记录',
            recordAutoid: 0,
            userData: {},
        };
    },
    props : {
        expInfo : {
            type : Object,
            default : ()=>{return {
                expautoid: null,
            }}
        },
    },
    mounted() {
        let extraRequest = this.setSearchData();
        this.tableParams.currentPage = 1;
        this.getRecordCount(extraRequest);
        this.getRecord(extraRequest);
    },

    methods: {
        setSearchData() {
        
            let {searchData} = this;
            let extraRequest = {};
            if(searchData.time && searchData.time.length == 2 && checker.isDate(searchData.time[0]) && checker.isDate(searchData.time[1])) {
                extraRequest["start_date"] = checker.getDateStr(searchData.time[0]);
                extraRequest["end_date"] = checker.getDateStr(searchData.time[1]);
            }
            if(searchData.score.left && searchData.score.right) {
                extraRequest["score_left"] = searchData.score.left;
                extraRequest["score_right"] = searchData.score.right;
            }
            if(searchData.gradelevel) {
                extraRequest["gradelevel"] = searchData.gradelevel;
            }
            return extraRequest;
        },
        handlePicker (){

        },
        pickerChange (){
        },
        handleDetail (data){
            console.log(data)
            if(data.key) this.recordAutoid = data.key;
            if(data.user) this.userData = data.user;
            this.expInfo.datafiletype = data.datafiletype;
            this.viewState = '记录详情';
        },
        handleReset (){
            this.searchData = {
                time: [],
                score: { left: null, right: null },
                gradelevel: 0,
            };
            this.tableParams.currentPage = 1;
            this.getRecordCount();
            this.getRecord();
        },
        handleSerach (){
            let extraRequest = this.setSearchData();
            this.tableParams.currentPage = 1;
            this.getRecordCount(extraRequest);
            this.getRecord(extraRequest);
        },
        handleExport() {
            let {expInfo} = this;
            let extraRequest = this.setSearchData();
            let request = { expautoid: expInfo.expautoid, export_records: "", ...extraRequest };
            const loading = ElLoading.service({
                lock: true,
                text: '加载中',
                background: 'rgba(0, 0, 0, 0.7)',
            });
            axios.get(server.uiexprecord + checker.objTurnParams(request)).then(result => {
                loading.close();
                if(result.status && result.data) {
                    if(result.data.length) {
                        let url = result.data[0];
                        if(typeof(url) == "string") {
                            window.open(url);
                        }
                        else {
                            ElMessage({
                                message: '不是有效的实验记录文件格式.',
                                type: 'warning',
                            })
                        }
                    } else {
                        ElMessage({
                            message: '实验记录不存在.',
                            type: 'warning',
                        })
                    }
                } else {
                    ElMessage.error('实验记录导出失败！' + result.msg)
                }
            });
        },
        getGraphData() {
            let {expInfo} = this;
            let request = { expautoid: expInfo.expautoid, get_student_uiexprecord_data_graph: "" };
            axios.get(server.dataanalysis + checker.objTurnParams(request)).then(result => {
                if(result.status && result.data && result.data.length) {
                    let exp_data = result.data[0]["exp_data"] || {};
                    this.msgList.forEach((item) => {
                        let type = item.type;
                        let value = Number(exp_data[type]) || 0;
                        if(type.indexOf("life") != -1) {
                        //    item.value = this.timeChange(value);
                           item.value = common.fomatFloat(value / 60,2);
                        } else {
                            if(type == "exp_num") {
                                item.value = common.fomatFloat(value,0);
                                this.linePaging.total = common.fomatFloat(value,0);
                            }else{
                                item.value = common.fomatFloat(value,2);;
                            }
                        }
                    });
                }
                this.lineGraphShow = true;
            });
        },
        
    //  timeChange(data){
    //      let time = data;
    //     //  let h = parseInt(time / 60 / 60 % 24)
    //     //      h = h < 10 ? '0' + h : h
    //          let m = parseInt(time / 60 % 60)
    //          m = m < 10 ? '0' + m : m
    //          let s = parseInt(time % 60)
    //          s = s < 10 ? '0' + s : s
    //         // 作为返回值返回
    //        let finnal = m+'.'+s
    //       //  if(data=='0'||''){
    //       //    finnal= 0
    //       //  }
    //         return finnal
    // },
        setGraphOptions(name, data, type="line") {
            let options = {
                name: name,
                type: type,
                areaStyle: {
                    color: {
                        type: 'linear', x: 0, y: 0, x2: 0, y2: 1, 
                        colorStops: [
                            { offset: 0, color: 'rgba(58,132,255, 0.5)' },  // 0% 处的颜色
                            { offset: 1, color: 'rgba(58,132,255, 0)' } // 100% 处的颜色
                        ],
                        global: false // 缺省为 false
                    }
                },
                stack: 'Total',
                data: data
            };
            return options;
        },
        getGraph(callback) {
            let {expInfo, linePaging} = this;

            this.lineData.forEach((lineSerie) => {
                lineSerie.data = [];
            });

            let request = { expautoid: expInfo.expautoid, get_student_uiexprecord_data_graph: "", get_graph: "", page: linePaging.currentPage, per_page: linePaging.pageSize };
            axios.get(server.dataanalysis + checker.objTurnParams(request)).then(result => {
                if(result.status && result.data && result.data.result && result.data.result.length) {
                    result.data.result.forEach((item, idx) => {
                        this.lineData.forEach((lineSerie) => {
                            switch(lineSerie.name) {
                                case "实验成绩":
                                    lineSerie.data.push({
                                        name: idx+1,
                                        // value: [ idx+1, Number(Number(item.score).toFixed(2)) ]
                                        value: [ idx+1, common.fomatFloat(item.score,2) ]
                                    });
                                    break;
                                case "实验报告":
                                    lineSerie.data.push({
                                        name: idx+1,
                                        // value: [ idx+1, Number(Number(item.datafile_data ? item.datafile_data.report_score || 0 : 0).toFixed(2)) ]
                                        value: [ idx+1, common.fomatFloat((item.datafile_data ? item.datafile_data.report_score || 0 : 0),2) ]
                                    });
                                    break;
                                case "综合成绩":
                                    lineSerie.data.push({
                                        name: idx+1,
                                        // value: [ idx+1, Number(Number(item.finalscore).toFixed(2)) ]
                                        value: [ idx+1, common.fomatFloat(item.finalscore,2) ]
                                    });
                                    break;
                                default:
                                    break;
                            }
                        })
                    });
                }
                checker.isFunction(callback) && callback();
            });
        },
        lineUpdaeMethod(delta, callback) {
            let pageSize = 5;
            let toReturn = (data=[]) => {
                return checker.isFunction(callback) && callback(data)
            }
            if(delta < 0) {
                if(this.linePaging.pageSize >= this.linePaging.total) return toReturn();
                this.linePaging.pageSize += pageSize;
            } else {
                if(this.linePaging.pageSize <= pageSize) return toReturn();
                this.linePaging.pageSize -= pageSize;
            }
            this.getGraph(() => {
                toReturn(this.lineData);
            });
        },
        lineFormatter(params) {
            return (params[0].seriesName + ": " + params[0].value[1] + "<br/>" +
                params[1].seriesName + ": " + params[1].value[1] + "<br/>" +
                params[2].seriesName + ": " + params[2].value[1]);
        },
        closePopup (){
            this.linePaging.pageSize = 0;
            this.popupStates.state = false;
        },
        handleClick (){
            this.popupStates = {
                state : true,
                title : '我的学习报表',
            };
            this.getGraphData();
        },
        getRecord(extraRequest={}) {
            let {expInfo, tableParams} = this;
            let tableData = [];
            let request = { expautoid: expInfo.expautoid, page: tableParams.currentPage, per_page: tableParams.pageSize,  ...extraRequest,_order_column:"createtime",_order_style:"desc"};
            axios.get(server.uiexprecord + checker.objTurnParams(request)).then(result => {
                if(result.status && result.data && result.data.result) {
                    result.data.result.forEach((item) => {
                        let recordItem = {
                            datafiletype : item.datafiletype,
                            key: item.autoid,
                            realname: item.user_realname || "",
                            username: item.user_username || "",
                            iclass: item.user_iclass_data ? item.user_iclass_data.name || "" : "",
                            createtime: item.createtime,
                            // duration: this.timeChange(item.life),
                            duration: common.fomatFloat((item.life || 0) / 60,2),
                            score: common.fomatFloat((item.score || 0),2),
                            finalScore: common.fomatFloat((item.finalscore || 0),2),
                            finalscorelevel: item.finalscorelevel
                        };
                        if(item.datafile_data){
                            recordItem.reportScore = common.fomatFloat((item.datafile_data.report_score || 0),2) ;
                            recordItem.datafileautoid = item.datafile_data.autoid;
                        }
                        recordItem["user"] = {
                            user_username: item.user_username || "无",
                            user_realname: item.user_realname || "无",
                            user_number: item.user_number || "无",
                            iclass_name: item.user_iclass_data ? item.user_iclass_data.name || "无" : "无",
                        }
                        tableData.push(recordItem);
                    });
                }
                this.tableData = tableData;
            });
        },
        getRecordCount(extraRequest={}) {
            let {expInfo} = this;
            let request = { expautoid: expInfo.expautoid, count: true, ...extraRequest };
            axios.get(server.uiexprecord + checker.objTurnParams(request)).then(result => {
                if(result && result.status) this.tableParams.total = Number(result.data) || 0;
            });
        },
        pageChange(currentPage) {
            this.tableParams.currentPage = currentPage;
            this.getRecord();
        }
    },
    components : {
        Table,
        Buttons,
        MessageBoxs,
        Echars,
        Details,
    },
};
</script>

<style lang="less" scoped>
     ::v-deep .el-form-item--small.el-form-item {
        margin-bottom: 0;
    }
    ::v-deep  .el-input--small .el-input__icon {
        line-height: 0;
    }
    ::v-deep  .el-date-editor.el-range-editor {
        width:250px;
    }

    .record-popup{
        width:100%;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .popup-echars{
            width:100%;
            height:500px;
            padding-top:10px;
            >span{
                font-size: 13px;
                color: red;
            }
        }
        .popup-item{
            width:25%;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #666666;
            display:flex;
            align-items:center;
            justify-content: flex-start;
            padding: 10px 0;
            >span{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                margin-right: 10px;
            }
        }
    }

    .record-ui{
        //padding:0 10px;
        
        padding: 0 10px;
        .record-main{
            width:100%;
        }

        
    }
    .record-form{
        display: flex;
        padding-top:10px;
        flex-direction: column;
        .record-options{
            padding-top:10px;
            >span:nth-child(1){
                margin-right:10px;
            }
        }
        >span:nth-child(1){
          margin-right:10px;
        }
        >span:nth-child(2){
          margin-right:10px;
        }

        .form-select{
            width:200px;
        }
        .form-scope{
            
            .score-before,.score-after{
                width:60px;
                display:inline-block;
            }
            .score-center{
                padding:0 10px;
            }
        }
      }
</style>