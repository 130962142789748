<template>
    <!-- 职业教育 -->
    <layout_20230210_47e9 style="width:auto !important" mode="标题线">
        <template #title>职业教育</template>
        <template #describe>
            <span style="text-align: center; display: block;">
                实践教学是职业教育人才培养中的重要一环，象新力通过借助虚拟仿真、增强现实、全息影像等新型数字技术，构建出基于真实岗位情境的虚拟仿真实验
                室、实训室，解决职业教育教学实训中一些“高投入、高难度、高风险及难实施、难观摩难再现”的场号应田
            </span>
        </template>
        <div class="main">
            <div class="content-block" v-for="item in picdata" :key="item.name">
                <img :src="item.path" alt="">
                <p>
                    {{ item.name }}
                </p>
            </div>
        </div>
    </layout_20230210_47e9>
</template>

<script>
import layout_20230210_47e9 from '@/components/layouts/layout_20230210_47e9.vue';
export default {
    name : "education",
    components: {
        layout_20230210_47e9
    },
    data() {
        return {
            picdata: [
                { name: "高投入", path: require('@/assets/images/xxl_gw/高投入.png') },
                { name: "高损耗", path: require('@/assets/images/xxl_gw/高损耗.png') },
                { name: "高风险", path: require('@/assets/images/xxl_gw/高风险.png') },
                { name: "难实施", path: require('@/assets/images/xxl_gw/难实施.png') },
                { name: "难观摩", path: require('@/assets/images/xxl_gw/难观摩.png') },
                { name: "难再现", path: require('@/assets/images/xxl_gw/难再现.png') },

            ]
        }
    }
}
</script>

<style  scoped lang="less">
.layout_20230210_47e9{
    padding: 20px 100px 0;
}
.main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 84px;
    margin-top: 35px;
}

.content-block {
    text-align: center;
    color: #636363;
    font-size: 18px;
}

.content-block>p {
    margin-top: 5px;
    ;
}</style>