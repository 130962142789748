<template>
  <router-view></router-view>
</template>

<script>
import { mapActions } from 'vuex';
import common from '@/utils/common';
import { ref, reactive, onMounted } from 'vue';
import axios from '@/utils/axios';
import server from '@/service/server';
import { json } from 'body-parser';
const exclude = [
  '/admin', '/lectures'
];
export default {
  data() {
    return {
      detectionTime: null,
      routeMap: {},
      moveValue: {}
    }
  },
  watch: {
    '$route.path': 'monitorPath',
  },
  created() {

  },

  mounted() {//此处需要调整 
    let routeMap = common.analysisRoute();
    this.routeMap = routeMap;
    this.setRouteMap(routeMap);
  },
  beforeDestroy() {

  },
  methods: {
    /*  获取坐标的位置 */
    GetMouseMove(event) {
      let movement = reactive({ w: window.pageXOffset, h: window.pageYOffset });
      movement.x = event.pageX;
      movement.y = event.pageY;
      this.moveValue = movement;
    },
    /* 重新获取token*/
    getToken() {
      let time = new Date();
      axios.get(server.userinfo, { params: { get_token: 'true' } }).then(result => {
        if (result && result.status) {
          let tokenn = result.token;
          window.localStorage.setItem('token', tokenn);
        }
      })
    },
    /* 判断登录状态*/
    judgeStatus(result) {
      if (!result || !result.status) {
        localStorage.clear();
        window.removeEventListener("mousemove", this.GetMouseMove);
        clearInterval(this.timeout);
      }
      else {
        window.addEventListener('mousemove', this.GetMouseMove);
        clearInterval(this.timeout);
        this.timeout = setInterval(() => {
          // 我在检测
          this.RowVerify();
        }, 5000)
      }
    },

    /* 坐标比对*/
    RowVerify() {
      const { moveValue } = this
      let last = JSON.parse(localStorage.getItem('rowValue'))
      if (last.x == moveValue.x && last.y == moveValue.y) {
        // console.log('我没动')
      } else {
        //请求新token重新更新token
        // 清掉上一个监听
        // 新增一个新的监听
        this.getToken();
        // console.log('我动了')
        var RowOrigin = { x: moveValue.x, y: moveValue.y }
        window.localStorage.setItem('rowValue', JSON.stringify(RowOrigin))
      }
    },

    /* 路由访问监控 */
    async monitorPath(data) {
        console.log(data)
        if(data=='/login' && document.getElementById('newBridge')){
          document.getElementById('newBridge').style.display = 'none';
         
        }else{
          if(document.getElementById('newBridge')){
            document.getElementById('newBridge').style.display = 'block';
          }
          
        }
      // this.removeParams();
      let res = await this.getUserInfo();
      // 这个是 根据鼠标移动刷新token
      // this.judgeStatus(res)
    },


    removeParams() {//路由变化时动态删除传递过去的参数
      const { routeMap } = this;
      let path = this.$route.path, cache = [], param = {};
      if (exclude.indexOf(path) >= 0) return;
      while (true) {
        if (path && routeMap[path]) {
          cache.push(path);
          if (routeMap[path] && routeMap[path].parent) { path = routeMap[path].parent; }
          else { path = false; }
        } else break;
      }

      param = common.getStorParams(this, true);
      for (const value in param) {
        let idx = cache.findIndex(item => value === item);
        if (idx < 0) {
          common.removeParams(value);
        }
      }
    },
    ...mapActions(['setRouteMap', 'getUserInfo']),
  },
}
</script>

<style scoped lang='less'>
@import './assets/iconfont/index.css';

@font-face {
  font-family: ZiZhiQuXiMaiTi; //自定义字体名称
  src: url('~@/assets/iconfont/ZiZhiQuXiMaiTi-2.ttf'); //注意引入正确位置
}

.app-mian {
  position: relative;
  width: 100%;
  height: 100%;
 
}
</style>