const good_icon = [
    {
        title:'xxl-huishou',
        revalance:4
    },
    {
        title:'xxl-jixie',
        revalance:2
    },
    {
        title:'xxl-nengyuangailan',
        revalance:1
    },
    {
        title:'xxl-lightning-full',
        revalance:3
    },
    {
        title:'xxl-experiment',
        revalance:5
    },
    {
        title:'xxl-resource',
        revalance:6
    }
]

export default{
    good_icon
}