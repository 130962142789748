<template>
  <div class="aspecial-ui">
    <div class="aspecial-items">
      <div class="aspecial-shade" v-for="(arr,idx) in aspecialData" :key="idx">
        <template v-for="(item,index) in arr" :key="item.key">
          <div class="aspecial-item">
            <img :src="item.face" alt="" @click="handleAspecialClick(arr[index])"/>
            <span>{{ item.title }}</span>
            <span>{{ item.content }}</span>
            <span @click="handleAspecialClick(arr[index])">前往查看>></span>
          </div>
        </template>
      </div>
      <div class='no-data' v-if='!aspecialData.length'>
        <el-empty :image-size="242">
            <template #image>
                <span class='no-data-img'></span>
            </template>
        </el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/utils/axios";
import server from "@/service/server";
import common from "@/utils/common";
export default {
  data() {
    return {
      aspecialData: [],
    };
  },
  props: {
    line : {
        type : Number,
        default : 3,
    },
  },
  mounted(){
    this.getAspecialData();
  },
  methods: {
    handleAspecialClick(data) {
      common.setStorParams({path:'/NewsFeature'},data);
      this.$router.push('/NewsFeature');
    },
    getAspecialData(){
      const {line} = this;
      let array = [],cache = [];
      axios.get(server.activitynotice , {params:{publish:2,relevance:'index'}}).then((result)=>{
        if(result && result.status && result.data.result){
          let cacheList = result.data.result || [];
          cacheList.map((item,index)=>{
            item.key = item.autoid;
            item.title = item.title;
            item.content = item.content;
            if(Array.isArray(item.exp_data)){
              item.exp_data = item.exp_data.map(expItem=>{
                let face = expItem.face.split(',');
                face.map(fitem=>{
                  if(fitem.indexOf('jpg') != -1){
                      expItem.face = fitem
                  }else if(fitem.indexOf('png') != -1){
                      expItem.face = fitem
                  }else if(fitem.indexOf('jpeg') != -1){
                      expItem.face = fitem
                  }else{
                      // expItem.face = require('@/assets/images/default_exp.jpg');
                      if(expItem.face=='')
                            expItem.face = require('@/assets/images/default_exp.jpg')
                  }
                })
                if(expItem.master_data){
                  expItem.realname = expItem.master_data.realname;
                }
                if(expItem.major_data){
                  expItem.majorname = expItem.major_data.name;
                }
                return expItem;
              })
            }
            if(index % line){
              cache.push(item)
            }else{
              cache = [];
              cache.push(item);
              array.push(cache);
            }
          })
          this.aspecialData = array;
        }
      })
    },
  },
};
</script>

<style scoped lang="less">
.no-data{
  margin: auto;
  .no-data-img{
    background-image: url('~@/assets/images/Empty.jpg');
    background-size: 1595px 3004px;
    background-position: -1047px -204px;
    width: 242px;
    height: 228px;
    display: inline-block;
  }
}
.aspecial-ui {
  width: 100%;
  padding: 10px 0;
  .aspecial-items {
    width: 100%;
    display: flex;
    // flex-direction: column;
    justify-content: start;
    .aspecial-shade{
      display: flex;
      .aspecial-item {
        // width: 380px;
        position: relative;
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        border-radius: 4px;
        width: 279px;
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-right: 15px;
        // padding: 0 10px;
        > img {
          height: 174px;
          // width: 380px;
          // height: 239px;
          cursor: pointer;
        }
        > span {
          font-family: "Microsoft YaHei";
        }
        > span:nth-child(2) {
          font-size: 18px;
          font-weight: bold;
          color: #1a1a1a;
          padding: 10px 10px;
        }
        > span:nth-child(3) {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          text-indent: 24px;
          text-align: justify;
          line-height: 25px;
          height: 72px;
          display: -webkit-box; /**对象作为伸缩盒子模型展示**/
          -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
          -webkit-line-clamp: 3; /**显示的行数**/
          overflow: hidden; /**隐藏超出的内容**/
          padding: 0 13px;
        }
        > span:nth-child(4) {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          margin-left: auto;
          padding: 10px 0;
          cursor: pointer;
        }
      }
    }
  }
}
</style>