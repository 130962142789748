const VUE_STYLE_FILE_ID = {
    lignt : 'wangEditor_lignt__',
    dark : 'wangEditor_dark__',
    white : 'wangEditor_white__',
}
const common = {};


common.setStyles = function(type){
    common.delStyle();
    let _link = document.createElement('link'),
    head = document.querySelector('head');
    _link.rel="stylesheet";
    _link.href=window.$PATHNAME + `${process.env.BASE_URL}css/wangEditor/${type}.css`;
    _link.id = VUE_STYLE_FILE_ID[type];
    head.appendChild(_link);
    return new Promise((resolve,reject)=>{
        _link.onload = function (){
            console.log('加载成功');
            resolve()
        }
        _link.onerror = function (){
            console.log('加载失败');
            reject()
        }
    })
};

common.delStyle = function (){
    let head = document.querySelector('head');
    for(const item in VUE_STYLE_FILE_ID){
        let _di = '#' + VUE_STYLE_FILE_ID[item]
        let elem = document.querySelector(_di);
        if(elem){
            head.removeChild(elem);
        }
    }
};

class Common {
    VUE_STYLE_FILE_ID = {
        lignt : 'wangEditor_lignt__',
        dark : 'wangEditor_dark__',
        white : 'wangEditor_white__',
    }

    setStyles(type){
        this.delStyle();
        let _link = document.createElement('link'),
        head = document.querySelector('head');
        _link.rel="stylesheet";
        _link.href=window.$PATHNAME + `${process.env.BASE_URL}css/wangEditor/${type}.css`;
        _link.id = this.VUE_STYLE_FILE_ID[type];
        head.appendChild(_link);
        return new Promise((resolve,reject)=>{
            _link.onload = function (){
                console.log('加载成功');
                resolve(true)
            }
            _link.onerror = function (){
                console.log('加载失败');
                reject(false)
            }
        })
    }
    delStyle(){
        let head = document.querySelector('head');
        for(const item in this.VUE_STYLE_FILE_ID){
            let _di = '#' + this.VUE_STYLE_FILE_ID[item]
            let elem = document.querySelector(_di);
            if(elem){
                head.removeChild(elem);
            }
        }
    }
}

export default new Common();