const dataList = [
{
    "id": 1,
    "school": [{
        "id": 1001,
        "name": "\u6e05\u534e\u5927\u5b66"
    },
    {
        "id": 1002,
        "name": "\u5317\u4eac\u5927\u5b66"
    },
    {
        "id": 1003,
        "name": "\u4e2d\u56fd\u4eba\u6c11\u5927\u5b66"
    },
    {
        "id": 1004,
        "name": "\u5317\u4eac\u822a\u7a7a\u822a\u5929\u5927\u5b66"
    },
    {
        "id": 1005,
        "name": "\u5317\u4eac\u90ae\u7535\u5927\u5b66"
    },
    {
        "id": 1006,
        "name": "\u5317\u4eac\u5e08\u8303\u5927\u5b66"
    },
    {
        "id": 1007,
        "name": "\u4e2d\u56fd\u4f20\u5a92\u5927\u5b66"
    },
    {
        "id": 1008,
        "name": "\u5317\u4eac\u8bed\u8a00\u5927\u5b66"
    },
    {
        "id": 1009,
        "name": "\u5317\u4eac\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 1010,
        "name": "\u4e2d\u56fd\u519c\u4e1a\u5927\u5b66"
    },
    {
        "id": 1011,
        "name": "\u5317\u4eac\u7406\u5de5\u5927\u5b66"
    },
    {
        "id": 1012,
        "name": "\u5317\u4eac\u6797\u4e1a\u5927\u5b66"
    },
    {
        "id": 1013,
        "name": "\u5317\u4eac\u4ea4\u901a\u5927\u5b66"
    },
    {
        "id": 1014,
        "name": "\u4e2d\u56fd\u77ff\u4e1a\u5927\u5b66\uff08\u5317\u4eac\uff09"
    },
    {
        "id": 1015,
        "name": "\u5317\u4eac\u4fe1\u606f\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 1016,
        "name": "\u5317\u4eac\u5de5\u4e1a\u5927\u5b66"
    },
    {
        "id": 1017,
        "name": "\u5317\u4eac\u5316\u5de5\u5927\u5b66"
    },
    {
        "id": 1018,
        "name": "\u4e2d\u56fd\u653f\u6cd5\u5927\u5b66"
    },
    {
        "id": 1019,
        "name": "\u5bf9\u5916\u7ecf\u8d38\u5927\u5b66"
    },
    {
        "id": 1020,
        "name": "\u4e2d\u592e\u6c11\u65cf\u5927\u5b66"
    },
    {
        "id": 1021,
        "name": "\u4e2d\u56fd\u5730\u8d28\u5927\u5b66\uff08\u5317\u4eac\uff09"
    },
    {
        "id": 1022,
        "name": "\u4e2d\u79d1\u9662"
    },
    {
        "id": 1023,
        "name": "\u5317\u4eac\u4e2d\u533b\u836f\u5927\u5b66"
    },
    {
        "id": 1024,
        "name": "\u9996\u90fd\u7ecf\u6d4e\u8d38\u6613\u5927\u5b66"
    },
    {
        "id": 1025,
        "name": "\u4e2d\u592e\u8d22\u7ecf\u5927\u5b66"
    },
    {
        "id": 1026,
        "name": "\u5317\u65b9\u5de5\u4e1a\u5927\u5b66"
    },
    {
        "id": 1027,
        "name": "\u4e2d\u56fd\u77f3\u6cb9\u5927\u5b66\uff08\u5317\u4eac\uff09"
    },
    {
        "id": 1028,
        "name": "\u5916\u4ea4\u5b66\u9662"
    },
    {
        "id": 1029,
        "name": "\u9996\u90fd\u5e08\u8303\u5927\u5b66"
    },
    {
        "id": 1030,
        "name": "\u4e2d\u592e\u620f\u5267\u5b66\u9662"
    },
    {
        "id": 1031,
        "name": "\u4e2d\u56fd\u9752\u5e74\u653f\u6cbb\u5b66\u9662"
    },
    {
        "id": 1032,
        "name": "\u5317\u4eac\u5916\u56fd\u8bed\u5927\u5b66"
    },
    {
        "id": 1033,
        "name": "\u534e\u5317\u7535\u529b\u5927\u5b66\uff08\u5317\u4eac\uff09"
    },
    {
        "id": 1034,
        "name": "\u4e2d\u56fd\u4eba\u6c11\u516c\u5b89\u5927\u5b66"
    },
    {
        "id": 1035,
        "name": "\u5317\u4eac\u534f\u548c\u533b\u5b66\u9662"
    },
    {
        "id": 1036,
        "name": "\u5317\u4eac\u4f53\u80b2\u5927\u5b66"
    },
    {
        "id": 1037,
        "name": "\u5317\u4eac\u5de5\u5546\u5927\u5b66"
    },
    {
        "id": 1038,
        "name": "\u5317\u4eac\u8054\u5408\u5927\u5b66"
    },
    {
        "id": 1039,
        "name": "\u9996\u90fd\u533b\u79d1\u5927\u5b66"
    },
    {
        "id": 1040,
        "name": "\u56fd\u9645\u5173\u7cfb\u5b66\u9662"
    },
    {
        "id": 1041,
        "name": "\u4e2d\u592e\u7f8e\u672f\u5b66\u9662"
    },
    {
        "id": 1042,
        "name": "\u5317\u4eac\u7535\u5b50\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 1043,
        "name": "\u4e2d\u56fd\u52b3\u52a8\u5173\u7cfb\u5b66\u9662"
    },
    {
        "id": 1044,
        "name": "\u4e2d\u534e\u5973\u5b50\u5b66\u9662"
    },
    {
        "id": 1045,
        "name": "\u5317\u4eac\u5efa\u7b51\u5de5\u7a0b\u5b66\u9662"
    },
    {
        "id": 1046,
        "name": "\u5317\u4eac\u5370\u5237\u5b66\u9662"
    },
    {
        "id": 1047,
        "name": "\u5317\u4eac\u77f3\u6cb9\u5316\u5de5\u5b66\u9662"
    },
    {
        "id": 1048,
        "name": "\u9996\u94a2\u5de5\u5b66\u9662"
    },
    {
        "id": 1049,
        "name": "\u5317\u4eac\u519c\u5b66\u9662"
    },
    {
        "id": 1050,
        "name": "\u9996\u90fd\u4f53\u80b2\u5b66\u9662"
    },
    {
        "id": 1051,
        "name": "\u5317\u4eac\u7b2c\u4e8c\u5916\u56fd\u8bed\u5b66\u9662"
    },
    {
        "id": 1052,
        "name": "\u5317\u4eac\u7269\u8d44\u5b66\u9662"
    },
    {
        "id": 1053,
        "name": "\u5317\u4eac\u8b66\u5bdf\u5b66\u9662"
    },
    {
        "id": 1054,
        "name": "\u4e2d\u592e\u97f3\u4e50\u5b66\u9662"
    },
    {
        "id": 1055,
        "name": "\u4e2d\u56fd\u620f\u66f2\u5b66\u9662"
    },
    {
        "id": 1056,
        "name": "\u5317\u4eac\u821e\u8e48\u5b66\u9662"
    },
    {
        "id": 1057,
        "name": "\u5317\u4eac\u57ce\u5e02\u5b66\u9662"
    },
    {
        "id": 1058,
        "name": "\u5317\u4eac\u7535\u5f71\u5b66\u9662"
    },
    {
        "id": 1059,
        "name": "\u5317\u4eac\u670d\u88c5\u5b66\u9662"
    },
    {
        "id": 1060,
        "name": "\u9752\u5c9b\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 1061,
        "name": "\u5317\u4eac\u4f53\u80b2\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 1062,
        "name": "\u4e2d\u56fd\u4eba\u6c11\u89e3\u653e\u519b\u88c5\u7532\u5175\u5de5\u7a0b\u5b66\u9662"
    },
    {
        "id": 1067,
        "name": "\u4e2d\u56fd\u77f3\u6cb9\u52d8\u63a2\u5f00\u53d1\u7814\u7a76\u9662"
    },
    {
        "id": 1068,
        "name": "\u5317\u4eac\u751f\u547d\u79d1\u5b66\u7814\u7a76\u6240"
    },
    {
        "id": 1069,
        "name": "\u4e2d\u56fd\u7535\u5f71\u8d44\u6599\u9986"
    },
    {
        "id": 1070,
        "name": "\u5317\u4eac\u5de5\u5546\u5927\u5b66\u5609\u534e\u5b66\u9662"
    },
    {
        "id": 1071,
        "name": "\u9996\u90fd\u5e08\u8303\u5927\u5b66\u79d1\u5fb7\u5b66\u9662"
    },
    {
        "id": 1072,
        "name": "\u5317\u4eac\u5de5\u4e1a\u5927\u5b66\u803f\u4e39\u5b66\u9662"
    },
    {
        "id": 1073,
        "name": "\u5317\u4eac\u5316\u5de5\u5927\u5b66\u5317\u65b9\u5b66\u9662"
    },
    {
        "id": 1074,
        "name": "\u5317\u4eac\u8054\u5408\u5927\u5b66\u5e7f\u544a\u5b66\u9662"
    },
    {
        "id": 1075,
        "name": "\u5317\u4eac\u90ae\u7535\u5927\u5b66\u4e16\u7eaa\u5b66\u9662"
    },
    {
        "id": 1076,
        "name": "\u5317\u4eac\u56fd\u9645\u5546\u52a1\u5b66\u9662"
    },
    {
        "id": 1101,
        "name": "\u5317\u4eac\u5927\u5b66\u533b\u5b66\u90e8"
    },
    {
        "id": 1102,
        "name": "\u5317\u4eac\u653f\u6cd5\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 1103,
        "name": "\u5317\u4eac\u4fe1\u606f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 1104,
        "name": "\u5317\u4eac\u73b0\u4ee3\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 1105,
        "name": "\u5317\u4eac\u73b0\u4ee3\u97f3\u4e50\u7814\u4fee\u5b66\u9662"
    },
    {
        "id": 1106,
        "name": "\u5317\u4eac\u620f\u66f2\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 1107,
        "name": "\u5317\u4eac\u9521\u534e\u56fd\u9645\u7ecf\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 1108,
        "name": "\u5317\u4eac\u76db\u57fa\u827a\u672f\u5b66\u6821"
    },
    {
        "id": 1109,
        "name": "\u5317\u4eac\u57f9\u9ece\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 1110,
        "name": "\u5317\u4eac\u519c\u4e1a\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 1111,
        "name": "\u5317\u4eac\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 1112,
        "name": "\u5317\u4eac\u79d1\u6280\u7ecf\u8425\u7ba1\u7406\u5b66\u9662"
    },
    {
        "id": 1113,
        "name": "\u5317\u4eac\u7ecf\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 1114,
        "name": "\u5317\u4eac\u7ecf\u6d4e\u6280\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 1115,
        "name": "\u5317\u4eac\u4eac\u5317\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 1116,
        "name": "\u5317\u4eac\u4ea4\u901a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 1117,
        "name": "\u5317\u4eac\u5409\u5229\u5927\u5b66"
    },
    {
        "id": 1118,
        "name": "\u5317\u4eac\u6c47\u4f73\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 1119,
        "name": "\u5317\u4eac\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 1120,
        "name": "\u5317\u4eac\u5de5\u5546\u7ba1\u7406\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 1121,
        "name": "\u5317\u4eac\u7535\u5b50\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 1122,
        "name": "\u5317\u4eac\u8d22\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 1123,
        "name": "\u5317\u4eac\u5317\u5927\u65b9\u6b63\u8f6f\u4ef6\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 1124,
        "name": "\u5317\u5927\u8d44\u6e90\u7f8e\u672f\u5b66\u9662"
    },
    {
        "id": 1125,
        "name": "\u5317\u4eac\u4eba\u6587\u5927\u5b66"
    },
    {
        "id": 1126,
        "name": "\u5317\u4eac\u9ad8\u7b49\u79d8\u4e66\u5b66\u9662"
    },
    {
        "id": 1127,
        "name": "\u5317\u4eac\u5e94\u7528\u6280\u672f\u5927\u5b66"
    },
    {
        "id": 1128,
        "name": "\u4e2d\u56fd\u9632\u536b\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 1129,
        "name": "\u4e2d\u56fd\u97f3\u4e50\u5b66\u9662"
    },
    {
        "id": 1130,
        "name": "\u4e2d\u56fd\u4fe1\u606f\u5927\u5b66"
    },
    {
        "id": 1131,
        "name": "\u5317\u4eac\u9752\u5e74\u653f\u6cbb\u5b66\u9662"
    },
    {
        "id": 1132,
        "name": "\u5317\u4eac\u8d22\u7ecf\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 1133,
        "name": "\u5317\u4eac\u7ecf\u6d4e\u7ba1\u7406\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 1134,
        "name": "\u5317\u4eac\u7f8e\u56fd\u82f1\u8bed\u8bed\u8a00\u5b66\u9662"
    },
    {
        "id": 1135,
        "name": "\u4e2d\u56fd\u7ba1\u7406\u8f6f\u4ef6\u5b66\u9662"
    },
    {
        "id": 1136,
        "name": "\u8d22\u653f\u90e8\u8d22\u653f\u79d1\u5b66\u7814\u7a76\u6240"
    },
    {
        "id": 1137,
        "name": "\u5317\u5927\u8d44\u6e90\u5b66\u9662"
    },
    {
        "id": 1138,
        "name": "\u73b0\u4ee3\u7ba1\u7406\u5927\u5b66"
    },
    {
        "id": 1139,
        "name": "\u5317\u4eac\u6c11\u65cf\u5927\u5b66"
    },
    {
        "id": 1140,
        "name": "\u5317\u4eac\u5e02\u52b3\u52a8\u4fdd\u969c\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 1141,
        "name": "\u5317\u4eac\u5e02\u5efa\u8bbe\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 1142,
        "name": "\u5317\u4eac\u5e02\u623f\u5730\u4ea7\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 1143,
        "name": "\u5317\u4eac\u5e02\u6c7d\u8f66\u5de5\u4e1a\u603b\u516c\u53f8\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 1144,
        "name": "\u5317\u4eac\u5e02\u897f\u57ce\u7ecf\u6d4e\u79d1\u5b66\u5927\u5b66"
    },
    {
        "id": 1145,
        "name": "\u5317\u4eac\u5e02\u4e30\u53f0\u533a\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 1146,
        "name": "\u5317\u4eac\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 1147,
        "name": "\u5317\u4eac\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 1148,
        "name": "\u5317\u4eac\u5e02\u4e1c\u57ce\u533a\u804c\u5de5\u4e1a\u4f59\u5927\u5b66"
    },
    {
        "id": 1149,
        "name": "\u5317\u4eac\u5e02\u603b\u5de5\u4f1a\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 1150,
        "name": "\u5317\u4eac\u5e02\u6d77\u6dc0\u533a\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 1151,
        "name": "\u5317\u4eac\u5e02\u5d07\u6587\u533a\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 1152,
        "name": "\u5317\u4eac\u5ba3\u6b66\u7ea2\u65d7\u4e1a\u4f59\u5927\u5b66"
    },
    {
        "id": 1153,
        "name": "\u5317\u4eac\u5e02\u77f3\u666f\u5c71\u533a\u4e1a\u4f59\u5927\u5b66"
    },
    {
        "id": 1154,
        "name": "\u5317\u4eac\u5e02\u671d\u9633\u533a\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 1155,
        "name": "\u5317\u4eac\u5e02\u673a\u68b0\u5de5\u4e1a\u5c40\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 1156,
        "name": "\u5317\u4eac\u533b\u836f\u96c6\u56e2\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 1157,
        "name": "\u5317\u4eac\u52b3\u52a8\u4fdd\u969c\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 1158,
        "name": "\u5317\u4eac\u793e\u4f1a\u7ba1\u7406\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 1160,
        "name": "\u5317\u4eac\u6f14\u827a\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 1161,
        "name": "\u5317\u4eac\u5174\u534e\u5927\u5b66"
    },
    {
        "id": 1162,
        "name": "\u5317\u4eac\u65b0\u56ed\u660e\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 1163,
        "name": "\u4e2d\u592e\u515a\u6821\u7814\u7a76\u751f\u9662"
    },
    {
        "id": 1164,
        "name": "\u4e2d\u56fd\u793e\u79d1\u9662"
    },
    {
        "id": 1165,
        "name": "\u5317\u4eac\u65c5\u6e38\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 1166,
        "name": "\u4e1c\u65b9\u6587\u5316\u827a\u672f\u5b66\u9662"
    },
    {
        "id": 1167,
        "name": "\u9996\u90fd\u8054\u5408\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 1168,
        "name": "\u4e2d\u56fd\u519c\u4e1a\u79d1\u5b66\u9662"
    },
    {
        "id": 1169,
        "name": "\u5317\u4eac\u5f71\u89c6\u7814\u4fee\u5b66\u9662"
    },
    {
        "id": 1170,
        "name": "\u56fd\u5bb6\u6cd5\u5b98\u5b66\u9662"
    },
    {
        "id": 1171,
        "name": "\u5317\u4eac\u5efa\u8bbe\u5927\u5b66"
    },
    {
        "id": 1172,
        "name": "\u5317\u4eac\u91d1\u878d\u5b66\u9662"
    },
    {
        "id": 1173,
        "name": "\u5317\u4eac\u9ec4\u57d4\u5927\u5b66"
    },
    {
        "id": 1174,
        "name": "\u4e2d\u745e\u9152\u5e97\u7ba1\u7406\u5b66\u9662"
    },
    {
        "id": 1175,
        "name": "\u4e2d\u56fd\u5efa\u7b51\u8bbe\u8ba1\u7814\u7a76\u9662"
    },
    {
        "id": 1176,
        "name": "\u5317\u4eac\u6587\u7406\u7814\u4fee\u5b66\u9662"
    },
    {
        "id": 1177,
        "name": "\u5317\u4eac\u5f53\u4ee3\u827a\u672f\u5b66\u9662"
    },
    {
        "id": 1178,
        "name": "\u5317\u4eac\u5927\u5b66\u56fd\u9645\u6cd5\u5b66\u9662"
    },
    {
        "id": 1179,
        "name": "\u5317\u4eac\u4ea4\u901a\u8fd0\u8f93\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 1180,
        "name": "\u4e2d\u56fd\u827a\u672f\u7814\u7a76\u9662"
    },
    {
        "id": 1181,
        "name": "\u5317\u4eac\u5de5\u4e1a\u5927\u5b66\u901a\u5dde\u5206\u6821"
    },
    {
        "id": 1182,
        "name": "\u5317\u4eac\u516b\u7ef4\u7814\u4fee\u5b66\u9662"
    },
    {
        "id": 1998,
        "name": "\u7f51\u7edc\u9500\u552e\u5927\u5b66"
    },
    {
        "id": 1999,
        "name": "\u671d\u9633\u4e8c\u5916"
    },
    {
        "id": 2000,
        "name": "\u4e2d\u5317\u56fd\u9645\u6f14\u827a\u5b66\u6821"
    }],
    "name": "\u5317\u4eac"
},
{
    "id": 2,
    "school": [{
        "id": 2001,
        "name": "\u590d\u65e6\u5927\u5b66"
    },
    {
        "id": 2002,
        "name": "\u4e0a\u6d77\u4ea4\u901a\u5927\u5b66"
    },
    {
        "id": 2003,
        "name": "\u540c\u6d4e\u5927\u5b66"
    },
    {
        "id": 2004,
        "name": "\u534e\u4e1c\u5e08\u5927"
    },
    {
        "id": 2005,
        "name": "\u4e0a\u6d77\u8d22\u7ecf"
    },
    {
        "id": 2006,
        "name": "\u534e\u4e1c\u7406\u5de5"
    },
    {
        "id": 2007,
        "name": "\u4e0a\u6d77\u5546\u5b66\u9662"
    },
    {
        "id": 2008,
        "name": "\u4e1c\u534e\u5927\u5b66"
    },
    {
        "id": 2009,
        "name": "\u4e0a\u6d77\u7406\u5de5"
    },
    {
        "id": 2010,
        "name": "\u4e0a\u6d77\u5927\u5b66"
    },
    {
        "id": 2011,
        "name": "\u4e0a\u6d77\u5916\u56fd\u8bed\u5927\u5b66"
    },
    {
        "id": 2012,
        "name": "\u4e0a\u6d77\u6d77\u4e8b"
    },
    {
        "id": 2013,
        "name": "\u4e0a\u6d77\u4e2d\u535a\u5b66\u9662"
    },
    {
        "id": 2014,
        "name": "\u4e0a\u6d77\u6d77\u6d0b\u5927\u5b66"
    },
    {
        "id": 2015,
        "name": "\u4e0a\u6d77\u4e2d\u533b\u836f"
    },
    {
        "id": 2016,
        "name": "\u4e0a\u6d77\u5e08\u5927"
    },
    {
        "id": 2017,
        "name": "\u5efa\u6865\u5b66\u9662"
    },
    {
        "id": 2018,
        "name": "\u4e0a\u6d77\u653f\u6cd5"
    },
    {
        "id": 2019,
        "name": "\u4e0a\u6d77\u7535\u673a"
    },
    {
        "id": 2020,
        "name": "\u4e0a\u6d77\u4e8c\u5de5\u5927"
    },
    {
        "id": 2021,
        "name": "\u4e0a\u6d77\u5e94\u7528\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 2022,
        "name": "\u4e0a\u6d77\u7535\u529b"
    },
    {
        "id": 2023,
        "name": "\u4e0a\u6d77\u5916\u8d38"
    },
    {
        "id": 2024,
        "name": "\u4e0a\u6d77\u91d1\u878d"
    },
    {
        "id": 2025,
        "name": "\u4e0a\u6d77\u7acb\u4fe1\u4f1a\u8ba1\u5b66\u9662"
    },
    {
        "id": 2026,
        "name": "\u4e0a\u6d77\u4f53\u9662"
    },
    {
        "id": 2027,
        "name": "\u4e0a\u6d77\u97f3\u4e50\u5b66\u9662"
    },
    {
        "id": 2028,
        "name": "\u4e0a\u6d77\u620f\u5267\u5b66\u9662"
    },
    {
        "id": 2029,
        "name": "\u6749\u8fbe\u5b66\u9662"
    },
    {
        "id": 2030,
        "name": "\u534e\u4e1c\u653f\u6cd5\u5927\u5b66"
    },
    {
        "id": 2031,
        "name": "\u4e0a\u6d77\u5e08\u8303\u5927\u5b66\u9752\u5e74\u5b66\u9662\u00a0"
    },
    {
        "id": 2035,
        "name": "\u4e0a\u5916\u8d24\u8fbe\u7ecf\u6d4e\u4eba\u6587\u5b66\u9662"
    },
    {
        "id": 2036,
        "name": "\u540c\u6d4e\u5927\u5b66\u540c\u79d1\u5b66\u9662"
    },
    {
        "id": 2037,
        "name": "\u4e0a\u6d77\u5e08\u8303\u5927\u5b66\u5929\u534e\u5b66\u9662"
    },
    {
        "id": 2038,
        "name": "\u4e0a\u6d77\u4e1c\u65b9\u6587\u5316\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 2039,
        "name": "\u4e0a\u6d77\u5de5\u5546\u5b66\u9662"
    },
    {
        "id": 2102,
        "name": "\u590d\u65e6\u5927\u5b66\u4e0a\u6d77\u89c6\u89c9\u827a\u672f\u5b66\u9662"
    },
    {
        "id": 2103,
        "name": "\u590d\u65e6\u5927\u5b66\u4e0a\u6d77\u533b\u5b66\u9662"
    },
    {
        "id": 2104,
        "name": "\u590d\u65e6\u5927\u5b66\u592a\u5e73\u6d0b\u91d1\u878d\u5b66\u9662"
    },
    {
        "id": 2105,
        "name": "\u4e0a\u6d77\u90a6\u5fb7\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 2106,
        "name": "\u4e0a\u6d77\u8bda\u4fe1\u5b66\u9662"
    },
    {
        "id": 2107,
        "name": "\u4e0a\u6d77\u57ce\u5e02\u7ba1\u7406\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 2108,
        "name": "\u4e0a\u6d77\u51fa\u7248\u5370\u5237\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 2109,
        "name": "\u4e0a\u6d77\u7535\u5f71\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 2110,
        "name": "\u4e0a\u6d77\u7535\u5b50\u4fe1\u606f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 2111,
        "name": "\u4e0a\u6d77\u4e1c\u6d77\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 2112,
        "name": "\u4e0a\u6d77\u5de5\u4f1a\u7ba1\u7406\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 2113,
        "name": "\u4e0a\u6d77\u5de5\u5546\u5916\u56fd\u8bed\u5b66\u9662"
    },
    {
        "id": 2115,
        "name": "\u4e0a\u6d77\u5de5\u827a\u7f8e\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 2116,
        "name": "\u4e0a\u6d77\u516c\u5b89\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 2117,
        "name": "\u4e0a\u6d77\u6d77\u5173\u5b66\u9662"
    },
    {
        "id": 2118,
        "name": "\u4e0a\u6d77\u6d77\u4e8b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 2119,
        "name": "\u4e0a\u6d77\u6d4e\u5149\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 2120,
        "name": "\u4e0a\u6d77\u5efa\u5cf0\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 2121,
        "name": "\u4e0a\u6d77\u4ea4\u901a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 2122,
        "name": "\u4e0a\u6d77\u79d1\u5b66\u6280\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 2123,
        "name": "\u4e0a\u6d77\u7acb\u8fbe\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 2124,
        "name": "\u4e0a\u6d77\u65c5\u6e38\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 2125,
        "name": "\u4e0a\u6d77\u6c11\u8fdc\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 2126,
        "name": "\u4e0a\u6d77\u519c\u6797\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 2127,
        "name": "\u4e0a\u6d77\u6b27\u534e\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 2128,
        "name": "\u4e0a\u6d77\u601d\u535a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 2129,
        "name": "\u4e0a\u6d77\u6258\u666e\u4fe1\u606f\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 2130,
        "name": "\u4e0a\u6d77\u65b0\u4fa8\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 2131,
        "name": "\u4e0a\u6d77\u884c\u5065\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 2132,
        "name": "\u4e0a\u6d77\u533b\u7597\u5668\u68b0\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 2133,
        "name": "\u4e0a\u6d77\u533b\u836f\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 2135,
        "name": "\u4e0a\u6d77\u9707\u65e6\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 2136,
        "name": "\u4e0a\u6d77\u4e2d\u534e\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 2137,
        "name": "\u4e0a\u6d77\u4e2d\u4fa8\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 2138,
        "name": "\u4e0a\u6d77\u7eba\u7ec7\u5de5\u4e1a\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 2139,
        "name": "\u4e0a\u6d77\u4f53\u80b2\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 2140,
        "name": "\u4e0a\u6d77\u533b\u836f\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 2141,
        "name": "\u4e0a\u6d77\u5e02\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 2142,
        "name": "\u4e0a\u6d77\u804c\u5de5\u533b\u5b66\u9662"
    },
    {
        "id": 2143,
        "name": "\u4e0a\u6d77\u804c\u5de5\u4f53\u80b2\u8fd0\u52a8\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 2145,
        "name": "\u534e\u4e1c\u7406\u5de5\u5927\u5b66\u7f51\u7edc\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 2146,
        "name": "\u4e0a\u6d77\u5e02\u7ecf\u6d4e\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 2147,
        "name": "\u4e0a\u6d77\u793e\u4f1a\u79d1\u5b66\u9662"
    },
    {
        "id": 2148,
        "name": "\u4e0a\u6d77\u5927\u5b66\u827a\u672f\u7814\u7a76\u9662"
    },
    {
        "id": 2149,
        "name": "\u4e2d\u56fd\u79d1\u5b66\u9662\u4e0a\u6d77\u751f\u547d\u79d1\u5b66\u7814\u7a76\u9662"
    },
    {
        "id": 2155,
        "name": "\u4e0a\u6d77\u751f\u7269\u5236\u54c1\u7814\u7a76\u6240"
    },
    {
        "id": 2160,
        "name": "\u4e0a\u6d77\u884c\u653f\u5b66\u9662"
    },
    {
        "id": 2162,
        "name": "\u4e0a\u6d77\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 2164,
        "name": "\u62c9\u8428\u5c14\u56fd\u9645\u8bbe\u8ba1\u5b66\u9662"
    },
    {
        "id": 2167,
        "name": "\u4e0a\u6d77\u9752\u5e74\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 2168,
        "name": "\u4e0a\u6d77\u9e3f\u6587\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 2169,
        "name": "\u4e0a\u6d77\u5927\u5b66\u5df4\u58eb\u6c7d\u8f66\u5b66\u9662"
    }],
    "name": "\u4e0a\u6d77"
},
{
    "id": 3,
    "school": [{
        "id": 3001,
        "name": "\u54c8\u5c14\u6ee8\u5de5\u4e1a\u5927\u5b66"
    },
    {
        "id": 3002,
        "name": "\u54c8\u5de5\u7a0b"
    },
    {
        "id": 3003,
        "name": "\u4e1c\u5317\u6797\u5927"
    },
    {
        "id": 3004,
        "name": "\u4e1c\u5317\u519c\u4e1a\u5927\u5b66"
    },
    {
        "id": 3005,
        "name": "\u54c8\u5c14\u6ee8\u533b\u79d1\u5927\u5b66"
    },
    {
        "id": 3006,
        "name": "\u9ed1\u9f99\u6c5f\u4e2d\u533b\u836f"
    },
    {
        "id": 3007,
        "name": "\u9ed1\u5de5\u7a0b"
    },
    {
        "id": 3008,
        "name": "\u9ed1\u9f99\u6c5f\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 3009,
        "name": "\u54c8\u5c14\u6ee8\u5b66\u9662"
    },
    {
        "id": 3010,
        "name": "\u54c8\u5c14\u6ee8\u4f53\u9662"
    },
    {
        "id": 3011,
        "name": "\u4e1c\u65b9\u5b66\u9662"
    },
    {
        "id": 3012,
        "name": "\u9ed1\u9f99\u6c5f\u5927\u5b66"
    },
    {
        "id": 3013,
        "name": "\u54c8\u5c14\u6ee8\u5546\u4e1a\u5927\u5b66"
    },
    {
        "id": 3014,
        "name": "\u54c8\u5e08\u5927"
    },
    {
        "id": 3015,
        "name": "\u54c8\u5c14\u6ee8\u7406\u5de5\u5927\u5b66"
    },
    {
        "id": 3016,
        "name": "\u9ed1\u9f99\u6c5f\u6280\u5e08\u5b66\u9662"
    },
    {
        "id": 3017,
        "name": "\u9ed1\u9f99\u6c5f\u7701\u62a4\u7406\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 3051,
        "name": "\u4f73\u6728\u65af\u5927\u5b66"
    },
    {
        "id": 3101,
        "name": "\u9f50\u9f50\u54c8\u5c14\u5927\u5b66"
    },
    {
        "id": 3102,
        "name": "\u9f50\u9f50\u54c8\u5c14\u533b\u5b66\u9662"
    },
    {
        "id": 3151,
        "name": "\u9ed1\u9f99\u6c5f\u516b\u4e00\u519c\u57a6\u5927\u5b66"
    },
    {
        "id": 3152,
        "name": "\u4e1c\u5317\u77f3\u6cb9\u5927\u5b66"
    },
    {
        "id": 3153,
        "name": "\u5927\u5e86\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 3201,
        "name": "\u7261\u4e39\u6c5f\u533b\u5b66\u9662"
    },
    {
        "id": 3202,
        "name": "\u7261\u4e39\u6c5f\u5e08\u8303"
    },
    {
        "id": 3251,
        "name": "\u7ee5\u5316\u5b66\u9662"
    },
    {
        "id": 3301,
        "name": "\u9ed1\u6cb3\u5b66\u9662"
    },
    {
        "id": 3401,
        "name": "\u5927\u5e86\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 3402,
        "name": "\u5927\u5e86\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 3403,
        "name": "\u5927\u5174\u5b89\u5cad\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 3404,
        "name": "\u54c8\u5c14\u6ee8\u7535\u529b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 3405,
        "name": "\u54c8\u5c14\u6ee8\u534e\u590f\u8ba1\u7b97\u673a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 3406,
        "name": "\u54c8\u5c14\u6ee8\u91d1\u878d\u5b66\u9662"
    },
    {
        "id": 3407,
        "name": "\u54c8\u5c14\u6ee8\u94c1\u9053\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 3408,
        "name": "\u54c8\u5c14\u6ee8\u73b0\u4ee3\u516c\u5171\u5173\u7cfb\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 3409,
        "name": "\u54c8\u5c14\u6ee8\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 3410,
        "name": "\u9e64\u5c97\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 3411,
        "name": "\u9ed1\u9f99\u6c5f\u5317\u5f00\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 3412,
        "name": "\u9ed1\u9f99\u6c5f\u5de5\u5546\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 3414,
        "name": "\u9ed1\u9f99\u6c5f\u5efa\u7b51\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 3415,
        "name": "\u9ed1\u9f99\u6c5f\u6797\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 3416,
        "name": "\u9ed1\u9f99\u6c5f\u65c5\u6e38\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 3417,
        "name": "\u9ed1\u9f99\u6c5f\u7164\u70ad\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 3418,
        "name": "\u9ed1\u9f99\u6c5f\u6c11\u65cf\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 3419,
        "name": "\u9ed1\u9f99\u6c5f\u519c\u57a6\u6797\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 3420,
        "name": "\u9ed1\u9f99\u6c5f\u519c\u57a6\u519c\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 3421,
        "name": "\u9ed1\u9f99\u6c5f\u519c\u57a6\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 3422,
        "name": "\u9ed1\u9f99\u6c5f\u519c\u4e1a\u5de5\u7a0b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 3423,
        "name": "\u9ed1\u9f99\u6c5f\u519c\u4e1a\u7ecf\u6d4e\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 3424,
        "name": "\u9ed1\u9f99\u6c5f\u519c\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 3425,
        "name": "\u9ed1\u9f99\u6c5f\u4e09\u6c5f\u7f8e\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 3426,
        "name": "\u9ed1\u9f99\u6c5f\u5546\u4e1a\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 3427,
        "name": "\u9ed1\u9f99\u6c5f\u751f\u6001\u5de5\u7a0b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 3428,
        "name": "\u9ed1\u9f99\u6c5f\u751f\u7269\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 3429,
        "name": "\u9ed1\u9f99\u6c5f\u53f8\u6cd5\u8b66\u5b98\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 3430,
        "name": "\u9ed1\u9f99\u6c5f\u4fe1\u606f\u6280\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 3431,
        "name": "\u9ed1\u9f99\u6c5f\u755c\u7267\u517d\u533b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 3432,
        "name": "\u9ed1\u9f99\u6c5f\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 3433,
        "name": "\u9e21\u897f\u5927\u5b66"
    },
    {
        "id": 3434,
        "name": "\u7261\u4e39\u6c5f\u5927\u5b66"
    },
    {
        "id": 3435,
        "name": "\u4e03\u53f0\u6cb3\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 3436,
        "name": "\u9f50\u9f50\u54c8\u5c14\u9ad8\u7b49\u5e08\u8303\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 3437,
        "name": "\u9f50\u9f50\u54c8\u5c14\u5de5\u7a0b\u5b66\u9662"
    },
    {
        "id": 3438,
        "name": "\u4f0a\u6625\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 3439,
        "name": "\u54c8\u5c14\u6ee8\u5e08\u8303\u5927\u5b66\u963f\u57ce\u5b66\u9662"
    },
    {
        "id": 3441,
        "name": "\u9ed1\u9f99\u6c5f\u7701\u653f\u6cd5\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 3442,
        "name": "\u9ed1\u9f99\u6c5f\u4ea4\u901a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 3443,
        "name": "\u54c8\u5c14\u6ee8\u5e94\u7528\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 3501,
        "name": "\u9ed1\u9f99\u6c5f\u7701\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 3502,
        "name": "\u54c8\u5c14\u6ee8\u7406\u5de5\u5927\u5b66\u8fdc\u4e1c\u5b66\u9662"
    },
    {
        "id": 3503,
        "name": "\u54c8\u5c14\u6ee8\u5e08\u8303\u5927\u5b66\u547c\u5170\u5b66\u9662"
    },
    {
        "id": 3516,
        "name": "\u9ed1\u9f99\u6c5f\u5916\u56fd\u8bed\u5b66\u9662"
    },
    {
        "id": 3517,
        "name": "\u54c8\u5c14\u6ee8\u5546\u4e1a\u5927\u5b66\u5fb7\u5f3a\u5546\u52a1\u5b66\u9662"
    },
    {
        "id": 3518,
        "name": "\u4e1c\u5317\u77f3\u6cb9\u5927\u5b66\u534e\u745e\u5b66\u9662"
    },
    {
        "id": 3519,
        "name": "\u4e1c\u5317\u519c\u4e1a\u5927\u5b66\u6210\u680b\u5b66\u9662"
    },
    {
        "id": 3521,
        "name": "\u9ed1\u9f99\u6c5f\u5927\u5b66\u5251\u6865\u5b66\u9662"
    },
    {
        "id": 3522,
        "name": "\u54c8\u5c14\u6ee8\u5546\u4e1a\u5927\u5b66\u5e7f\u53a6\u5b66\u9662"
    },
    {
        "id": 3523,
        "name": "\u54c8\u5c14\u6ee8\u5de5\u4e1a\u5927\u5b66\u534e\u5fb7\u5e94\u7528\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 3525,
        "name": "\u54c8\u5c14\u6ee8\u5e02\u804c\u5de5\u533b\u5b66\u9662"
    },
    {
        "id": 3527,
        "name": "\u4f73\u6728\u65af\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 3529,
        "name": "\u9ed1\u9f99\u6c5f\u5e7c\u513f\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 3530,
        "name": "\u54c8\u5c14\u6ee8\u5916\u56fd\u8bed\u5b66\u9662"
    },
    {
        "id": 3533,
        "name": "\u54c8\u5c14\u6ee8\u79d1\u5b66\u6280\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 3534,
        "name": "\u9ed1\u9f99\u6c5f\u7cae\u98df\u804c\u4e1a\u5b66\u9662\u3000\u3000"
    },
    {
        "id": 3535,
        "name": "\u4f73\u6728\u65af\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 3536,
        "name": "\u9ed1\u9f99\u6c5f\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 3537,
        "name": "\u54c8\u5c14\u6ee8\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 3538,
        "name": "\u9ed1\u9f99\u6c5f\u5927\u9e4f\u4f20\u5a92\u5b66\u9662"
    },
    {
        "id": 3539,
        "name": "\u9e21\u897f\u5e02\u5317\u65b9\u5916\u56fd\u8bed\u5b66\u9662"
    },
    {
        "id": 3540,
        "name": "\u7261\u4e39\u6c5f\u5e02\u7cbe\u82f1\u8ba1\u7b97\u673a\u5b66\u9662"
    },
    {
        "id": 3542,
        "name": "\u9ed1\u9f99\u6c5f\u4e2d\u533b\u836f\u5927\u5b66"
    },
    {
        "id": 3543,
        "name": "\u9f50\u9f50\u54c8\u5c14\u6797\u4e1a\u5b66\u6821"
    },
    {
        "id": 3544,
        "name": "\u9f50\u9f50\u54c8\u5c14\u6797\u4e1a\u5b66\u9662"
    },
    {
        "id": 3545,
        "name": "\u9ed1\u9f99\u6c5f\u751f\u6001\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 3546,
        "name": "\u54c8\u5c14\u6ee8\u9633\u5149\u8ba1\u7b97\u673a\u4e13\u4fee\u5b66\u6821"
    },
    {
        "id": 3547,
        "name": "\u9f50\u9f50\u54c8\u5c14\u5e02\u536b\u751f\u5b66\u6821"
    },
    {
        "id": 3548,
        "name": "\u4f1f\u5efa\u5de5\u5b66\u9662"
    },
    {
        "id": 3549,
        "name": "\u8bda\u5b9e\u5916\u8bed\u5b66\u9662\uff08\u8087\u5e86\u5206\u9662\uff09"
    },
    {
        "id": 3550,
        "name": "\u9ed1\u9f99\u6c5f\u7701\u755c\u7267\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 3551,
        "name": "\u54c8\u5c14\u6ee8\u5fb7\u5f3a\u5546\u52a1\u5b66\u9662"
    },
    {
        "id": 3552,
        "name": "\u9ed1\u9f99\u6c5f\u73b0\u4ee3\u827a\u672f\u5b66\u9662"
    },
    {
        "id": 3553,
        "name": "\u9ed1\u9f99\u6c5f\u7701\u5bf9\u5916\u8d38\u6613\u5b66\u6821"
    },
    {
        "id": 3554,
        "name": "\u54c8\u5c14\u6ee8\u5e02\u5e7c\u513f\u5e08\u8303\u5b66\u6821"
    },
    {
        "id": 3555,
        "name": "\u9ed1\u9f99\u6c5f\u7701\u793e\u4f1a\u79d1\u5b66\u9662\u7814\u7a76\u751f\u90e8"
    }],
    "name": "\u9ed1\u9f99\u6c5f"
},
{
    "id": 4,
    "school": [{
        "id": 4001,
        "name": "\u5409\u6797\u5927\u5b66"
    },
    {
        "id": 4002,
        "name": "\u4e1c\u5317\u5e08\u8303\u5927\u5b66"
    },
    {
        "id": 4003,
        "name": "\u957f\u6625\u5927\u5b66"
    },
    {
        "id": 4004,
        "name": "\u5409\u6797\u519c\u5927"
    },
    {
        "id": 4005,
        "name": "\u957f\u6625\u4e2d\u533b\u836f"
    },
    {
        "id": 4006,
        "name": "\u4e1c\u5317\u7535\u529b\u5927\u5b66"
    },
    {
        "id": 4007,
        "name": "\u5409\u6797\u5316\u5de5"
    },
    {
        "id": 4008,
        "name": "\u5409\u6797\u5efa\u5de5"
    },
    {
        "id": 4009,
        "name": "\u957f\u6625\u5de5\u7a0b\u5b66\u9662"
    },
    {
        "id": 4010,
        "name": "\u957f\u6625\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 4011,
        "name": "\u5409\u6797\u5de5\u7a0b\u5e08\u8303"
    },
    {
        "id": 4012,
        "name": "\u5409\u6797\u534e\u6865\u5916\u56fd\u8bed\u5b66\u9662"
    },
    {
        "id": 4013,
        "name": "\u5409\u6797\u8d22\u7ecf\u5927\u5b66"
    },
    {
        "id": 4014,
        "name": "\u5409\u6797\u4f53\u9662"
    },
    {
        "id": 4015,
        "name": "\u5409\u6797\u827a\u672f\u5b66\u9662"
    },
    {
        "id": 4016,
        "name": "\u957f\u6625\u5de5\u4e1a\u5927\u5b66"
    },
    {
        "id": 4017,
        "name": "\u957f\u6625\u7406\u5de5\u5927\u5b66"
    },
    {
        "id": 4018,
        "name": "\u5409\u6797\u4fc4\u8bed\u5b66\u9662"
    },
    {
        "id": 4051,
        "name": "\u5ef6\u8fb9\u5927\u5b66"
    },
    {
        "id": 4101,
        "name": "\u5317\u534e\u5927\u5b66"
    },
    {
        "id": 4102,
        "name": "\u5409\u6797\u519c\u4e1a\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 4103,
        "name": "\u5409\u6797\u533b\u836f\u5b66\u9662"
    },
    {
        "id": 4151,
        "name": "\u5409\u6797\u5e08\u8303"
    },
    {
        "id": 4201,
        "name": "\u767d\u57ce\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 4251,
        "name": "\u901a\u5316\u5e08\u8303"
    },
    {
        "id": 4301,
        "name": "\u767d\u57ce\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 4302,
        "name": "\u957f\u6625\u4e1c\u65b9\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 4303,
        "name": "\u957f\u6625\u91d1\u878d\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 4304,
        "name": "\u957f\u6625\u6c7d\u8f66\u5de5\u4e1a\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 4305,
        "name": "\u957f\u6625\u4fe1\u606f\u6280\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 4306,
        "name": "\u957f\u6625\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 4307,
        "name": "\u957f\u6625\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 4308,
        "name": "\u4e1c\u5317\u5e08\u8303\u5927\u5b66\u4eba\u6587\u5b66\u9662"
    },
    {
        "id": 4309,
        "name": "\u5409\u6797\u5de5\u5546\u5b66\u9662\u2014\u8d22\u7ecf\u6821\u533a"
    },
    {
        "id": 4310,
        "name": "\u5409\u6797\u5927\u5b66\u2014\u83b1\u59c6\u987f\u5b66\u9662"
    },
    {
        "id": 4311,
        "name": "\u5409\u6797\u7535\u5b50\u4fe1\u606f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 4312,
        "name": "\u5409\u6797\u5bf9\u5916\u7ecf\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 4313,
        "name": "\u5409\u6797\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 4315,
        "name": "\u5409\u6797\u4ea4\u901a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 4316,
        "name": "\u5409\u6797\u5de5\u5546\u5b66\u9662\u2014\u5de5\u7a0b\u6821\u533a"
    },
    {
        "id": 4317,
        "name": "\u5409\u6797\u519c\u4e1a\u5de5\u7a0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 4318,
        "name": "\u5409\u6797\u5de5\u5546\u5b66\u9662\u2014\u5546\u8d38\u6821\u533a"
    },
    {
        "id": 4319,
        "name": "\u5409\u6797\u53f8\u6cd5\u8b66\u5b98\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 4320,
        "name": "\u8fbd\u6e90\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 4321,
        "name": "\u56db\u5e73\u804c\u4e1a\u5927\u5b66"
    },
    {
        "id": 4322,
        "name": "\u677e\u539f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 4323,
        "name": "\u5409\u6797\u7701\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 4324,
        "name": "\u5409\u6797\u7ecf\u6d4e\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 4325,
        "name": "\u957f\u6625\u5927\u5b66\u5149\u534e\u5b66\u9662"
    },
    {
        "id": 4326,
        "name": "\u957f\u6625\u5927\u5b66\u65c5\u6e38\u5b66\u9662"
    },
    {
        "id": 4327,
        "name": "\u957f\u6625\u5de5\u4e1a\u5927\u5b66\u4eba\u6587\u4fe1\u606f\u5b66\u9662"
    },
    {
        "id": 4328,
        "name": "\u5409\u6797\u52a8\u753b\u5b66\u9662"
    },
    {
        "id": 4329,
        "name": "\u957f\u6625\u7406\u5de5\u5927\u5b66\u5149\u7535\u4fe1\u606f\u5b66\u9662"
    },
    {
        "id": 4331,
        "name": "\u5409\u6797\u519c\u4e1a\u5927\u5b66\u53d1\u5c55\u5b66\u9662"
    },
    {
        "id": 4332,
        "name": "\u5409\u6797\u5e08\u8303\u5927\u5b66\u535a\u8fbe\u5b66\u9662"
    },
    {
        "id": 4333,
        "name": "\u5409\u6797\u94c1\u9053\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 4334,
        "name": "\u767d\u57ce\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 4335,
        "name": "\u957f\u6625\u5efa\u7b51\u5b66\u9662"
    },
    {
        "id": 4336,
        "name": "\u5409\u6797\u5efa\u7b51\u5de5\u7a0b\u5b66\u9662\u57ce\u5efa\u5b66\u9662"
    },
    {
        "id": 4337,
        "name": "\u901a\u5316\u5e02\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 4338,
        "name": "\u901a\u5316\u94a2\u94c1\u516c\u53f8\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 4339,
        "name": "\u5409\u6797\u7701\u7ecf\u6d4e\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 4340,
        "name": "\u5409\u6797\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 4341,
        "name": "\u957f\u6625\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 4343,
        "name": "\u68a8\u6811\u519c\u6751\u6210\u4eba\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 4344,
        "name": "\u5ef6\u8fb9\u9ece\u660e\u519c\u6c11\u5927\u5b66"
    },
    {
        "id": 4345,
        "name": "\u5409\u6797\u804c\u5de5\u533b\u79d1\u5927\u5b66"
    },
    {
        "id": 4346,
        "name": "\u5409\u6797\u7701\u884c\u653f\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 4347,
        "name": "\u5409\u6797\u5316\u5b66\u5de5\u4e1a\u516c\u53f8\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 4348,
        "name": "\u5ef6\u8fb9\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 4349,
        "name": "\u957f\u6625\u804c\u5de5\u533b\u79d1\u5927\u5b66"
    },
    {
        "id": 4350,
        "name": "\u957f\u6625\u5e02\u76f4\u5c5e\u673a\u5173\u4e1a\u4f59\u5927\u5b66"
    },
    {
        "id": 4351,
        "name": "\u957f\u6625\u5e02\u5efa\u7b51\u804c\u5de5\u4e1a\u4f59\u5927\u5b66"
    },
    {
        "id": 4352,
        "name": "\u957f\u6625\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 4353,
        "name": "\u957f\u6625\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 4354,
        "name": "\u957f\u767d\u5c71\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 4355,
        "name": "\u957f\u6625\u90ae\u7535\u5b66\u9662"
    },
    {
        "id": 4357,
        "name": "\u5409\u6797\u79d1\u6280\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 4363,
        "name": "\u957f\u6625\u827a\u672f\u5b66\u6821"
    },
    {
        "id": 4364,
        "name": "\u5409\u6797\u8b66\u5bdf\u5b66\u9662"
    }],
    "name": "\u5409\u6797"
},
{
    "id": 5,
    "school": [{
        "id": 5001,
        "name": "\u5927\u8fde\u7406\u5de5"
    },
    {
        "id": 5002,
        "name": "\u4e1c\u5317\u5927\u5b66"
    },
    {
        "id": 5003,
        "name": "\u8fbd\u5b81\u5927\u5b66"
    },
    {
        "id": 5004,
        "name": "\u5927\u8fde\u6d77\u4e8b"
    },
    {
        "id": 5005,
        "name": "\u4e1c\u5317\u8d22\u7ecf"
    },
    {
        "id": 5006,
        "name": "\u5927\u8fde\u5927\u5b66"
    },
    {
        "id": 5007,
        "name": "\u5927\u8fde\u4ea4\u5927"
    },
    {
        "id": 5008,
        "name": "\u5927\u8fde\u533b\u5927"
    },
    {
        "id": 5009,
        "name": "\u8fbd\u5b81\u5e08\u5927"
    },
    {
        "id": 5010,
        "name": "\u5927\u8fde\u6c11\u65cf"
    },
    {
        "id": 5011,
        "name": "\u5927\u8fde\u5de5\u5927"
    },
    {
        "id": 5012,
        "name": "\u5927\u8fde\u6d77\u6d0b\u5927\u5b66"
    },
    {
        "id": 5013,
        "name": "\u5927\u8fde\u5916\u56fd\u8bed\u5b66\u9662"
    },
    {
        "id": 5014,
        "name": "\u8fbd\u5b81\u5916\u7ecf\u8d38"
    },
    {
        "id": 5015,
        "name": "\u8fbd\u5b81\u73b0\u4ee3\u670d\u52a1\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5016,
        "name": "\u94c1\u5cad\u536b\u751f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 5017,
        "name": "\u8fbd\u5b81\u5de5\u7a0b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 5051,
        "name": "\u6c88\u9633\u5927\u5b66"
    },
    {
        "id": 5052,
        "name": "\u6c88\u9633\u7406\u5de5"
    },
    {
        "id": 5053,
        "name": "\u6c88\u9633\u5de5\u5927"
    },
    {
        "id": 5054,
        "name": "\u6c88\u9633\u5efa\u7b51"
    },
    {
        "id": 5055,
        "name": "\u6c88\u9633\u519c\u5927"
    },
    {
        "id": 5056,
        "name": "\u8fbd\u5b81\u4e2d\u533b\u836f"
    },
    {
        "id": 5057,
        "name": "\u6c88\u9633\u836f\u79d1"
    },
    {
        "id": 5058,
        "name": "\u6c88\u9633\u5e08\u8303"
    },
    {
        "id": 5059,
        "name": "\u4e2d\u56fd\u5211\u8b66\u5b66\u9662"
    },
    {
        "id": 5060,
        "name": "\u6c88\u9633\u5316\u5de5"
    },
    {
        "id": 5061,
        "name": "\u6c88\u9633\u822a\u7a7a\u822a\u5929\u5927\u5b66"
    },
    {
        "id": 5062,
        "name": "\u6c88\u9633\u5de5\u7a0b"
    },
    {
        "id": 5063,
        "name": "\u6c88\u9633\u533b\u5b66\u9662"
    },
    {
        "id": 5064,
        "name": "\u6c88\u9633\u4f53\u9662"
    },
    {
        "id": 5065,
        "name": "\u9c81\u8fc5\u7f8e\u9662"
    },
    {
        "id": 5066,
        "name": "\u6c88\u9633\u97f3\u4e50\u5b66\u9662"
    },
    {
        "id": 5067,
        "name": "\u4e2d\u56fd\u533b\u79d1\u5927"
    },
    {
        "id": 5101,
        "name": "\u8fbd\u5b81\u5de5\u7a0b\u6280\u672f\u5927\u5b66"
    },
    {
        "id": 5151,
        "name": "\u8fbd\u5b81\u77f3\u5316"
    },
    {
        "id": 5201,
        "name": "\u8fbd\u5b81\u79d1\u5927"
    },
    {
        "id": 5202,
        "name": "\u978d\u5c71\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 5251,
        "name": "\u6e24\u6d77\u5927\u5b66"
    },
    {
        "id": 5252,
        "name": "\u8fbd\u5b81\u5de5\u4e1a\u5927\u5b66"
    },
    {
        "id": 5253,
        "name": "\u8fbd\u5b81\u533b\u5b66\u9662"
    },
    {
        "id": 5301,
        "name": "\u8fbd\u5b81\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 5351,
        "name": "\u8fbd\u4e1c\u5b66\u9662"
    },
    {
        "id": 5401,
        "name": "\u978d\u5c71\u5e02\u9ad8\u7b49\u804c\u4e1a\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 5402,
        "name": "\u6e24\u6d77\u8239\u8236\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 5403,
        "name": "\u6e24\u6d77\u5927\u5b66\u6587\u7406\u5b66\u9662"
    },
    {
        "id": 5404,
        "name": "\u671d\u9633\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 5405,
        "name": "\u5927\u8fde\u4e1c\u8f6f\u4fe1\u606f\u5b66\u9662"
    },
    {
        "id": 5406,
        "name": "\u5927\u8fde\u7ffb\u8bd1\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5407,
        "name": "\u5927\u8fde\u67ab\u53f6\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5408,
        "name": "\u5927\u8fde\u8f6f\u4ef6\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 5409,
        "name": "\u5927\u8fde\u5546\u52a1\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 5410,
        "name": "\u5927\u8fde\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 5411,
        "name": "\u5927\u8fde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5412,
        "name": "\u629a\u987a\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 5413,
        "name": "\u629a\u987a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5414,
        "name": "\u961c\u65b0\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 5415,
        "name": "\u8fbd\u5b81\u7406\u5de5\u5b66\u9662"
    },
    {
        "id": 5416,
        "name": "\u9526\u5dde\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 5418,
        "name": "\u8fbd\u5b81\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 5419,
        "name": "\u8fbd\u5b81\u5e7f\u544a\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 5420,
        "name": "\u8fbd\u5b81\u673a\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5421,
        "name": "\u8fbd\u5b81\u4ea4\u901a\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 5422,
        "name": "\u6c88\u9633\u5927\u5b66\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 5423,
        "name": "\u8fbd\u5b81\u91d1\u878d\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 5424,
        "name": "\u8fbd\u5b81\u7ecf\u6d4e\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5425,
        "name": "\u8fbd\u5b81\u8b66\u5b98\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 5426,
        "name": "\u8fbd\u5b81\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 5427,
        "name": "\u8fbd\u5b81\u6797\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5428,
        "name": "\u8fbd\u5b81\u7f8e\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 5429,
        "name": "\u8fbd\u5b81\u519c\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5430,
        "name": "\u8fbd\u5b81\u5546\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 5431,
        "name": "\u8fbd\u5b81\u77f3\u5316\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5432,
        "name": "\u5927\u8fde\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 5433,
        "name": "\u8fbd\u5b81\u4f53\u80b2\u8fd0\u52a8\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5434,
        "name": "\u8fbd\u5b81\u4fe1\u606f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5435,
        "name": "\u8fbd\u9633\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5436,
        "name": "\u76d8\u9526\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5437,
        "name": "\u6c88\u9633\u822a\u7a7a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5438,
        "name": "\u6c88\u9633\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5439,
        "name": "\u8fbd\u5b81\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 5440,
        "name": "\u94c1\u5cad\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 5441,
        "name": "\u8425\u53e3\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5442,
        "name": "\u6c88\u9633\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 5443,
        "name": "\u9752\u5c9b\u5cfb\u901a\u79d1\u6280\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 5444,
        "name": "\u8fbd\u6cb3\u77f3\u6cb9\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5445,
        "name": "\u9752\u5c9b\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 5446,
        "name": "\u6c88\u9633\u822a\u7a7a\u5de5\u4e1a\u5b66\u9662\u5317\u65b9\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 5447,
        "name": "\u6c88\u9633\u7406\u5de5\u5927\u5b66\u5e94\u7528\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5448,
        "name": "\u5927\u8fde\u5de5\u4e1a\u5927\u5b66\u827a\u672f\u4e0e\u4fe1\u606f\u5de5\u7a0b\u5b66\u9662"
    },
    {
        "id": 5449,
        "name": "\u5927\u8fde\u4ea4\u901a\u5927\u5b66\u4fe1\u606f\u5de5\u7a0b\u5b66\u9662"
    },
    {
        "id": 5450,
        "name": "\u6c88\u9633\u5efa\u7b51\u5927\u5b66\u57ce\u5e02\u5efa\u8bbe\u5b66\u9662"
    },
    {
        "id": 5451,
        "name": "\u8fbd\u5b81\u79d1\u6280\u5927\u5b66\u4fe1\u606f\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5452,
        "name": "\u8fbd\u5b81\u77f3\u6cb9\u5316\u5de5\u5927\u5b66\u987a\u534e\u80fd\u6e90\u5b66\u9662"
    },
    {
        "id": 5453,
        "name": "\u6c88\u9633\u5316\u5de5\u5b66\u9662\u79d1\u4e9a\u5b66\u9662"
    },
    {
        "id": 5454,
        "name": "\u6c88\u9633\u519c\u4e1a\u5927\u5b66\u79d1\u5b66\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5455,
        "name": "\u4e2d\u56fd\u533b\u79d1\u5927\u5b66\u4e34\u5e8a\u533b\u836f\u5b66\u9662"
    },
    {
        "id": 5456,
        "name": "\u5927\u8fde\u533b\u79d1\u5927\u5b66\u4e2d\u5c71\u5b66\u9662"
    },
    {
        "id": 5457,
        "name": "\u8fbd\u5b81\u533b\u5b66\u9662\u533b\u7597\u5b66\u9662"
    },
    {
        "id": 5458,
        "name": "\u8fbd\u5b81\u4e2d\u533b\u836f\u5927\u5b66\u674f\u6797\u5b66\u9662"
    },
    {
        "id": 5459,
        "name": "\u6c88\u9633\u533b\u5b66\u9662\u4f55\u6c0f\u89c6\u89c9\u79d1\u5b66\u5b66\u9662"
    },
    {
        "id": 5460,
        "name": "\u8fbd\u5b81\u5e08\u8303\u5927\u5b66\u6d77\u534e\u5b66\u9662"
    },
    {
        "id": 5461,
        "name": "\u4e1c\u5317\u8d22\u7ecf\u5927\u5b66\u6d25\u6865\u5546\u5b66\u9662"
    },
    {
        "id": 5462,
        "name": " \u8fbd\u5b81\u8d22\u8d38\u5b66\u9662"
    },
    {
        "id": 5463,
        "name": "\u5927\u8fde\u7406\u5de5\u5927\u5b66\u57ce\u5e02\u5b66\u9662"
    },
    {
        "id": 5464,
        "name": "\u6c88\u9633\u5927\u5b66\u79d1\u6280\u5de5\u7a0b\u5b66\u9662"
    },
    {
        "id": 5465,
        "name": "\u8fbd\u5b81\u88c5\u5907\u5236\u9020\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5466,
        "name": "\u8fbd\u5b81\u6587\u5316\u827a\u672f\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 5467,
        "name": "\u8fbd\u5b81\u516c\u5b89\u53f8\u6cd5\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 5468,
        "name": "\u6c88\u9633\u5de5\u4e1a\u5927\u5b66\u5de5\u7a0b\u5b66\u9662"
    },
    {
        "id": 5469,
        "name": "\u6d77\u519b\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 5471,
        "name": "\u961c\u65b0\u77ff\u52a1\u5c40\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 5472,
        "name": "\u6c88\u9633\u673a\u68b0\u5de5\u4e1a\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 5474,
        "name": "\u961c\u65b0\u7164\u70ad\u804c\u5de5\u533b\u5b66\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 5475,
        "name": "\u8fbd\u5b81\u8d22\u8d38\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 5476,
        "name": "\u5927\u8fde\u5e02\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 5477,
        "name": "\u671d\u9633\u804c\u5de5\u5de5\u5b66\u9662"
    },
    {
        "id": 5478,
        "name": "\u978d\u5c71\u94a2\u94c1\u96c6\u56e2\u516c\u53f8\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 5479,
        "name": "\u629a\u987a\u77f3\u6cb9\u5316\u5de5\u516c\u53f8\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 5480,
        "name": "\u8fbd\u5b81\u5175\u5668\u5de5\u4e1a\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 5481,
        "name": "\u672c\u6eaa\u94a2\u94c1\u516c\u53f8\u804c\u5de5\u5de5\u5b66\u9662"
    },
    {
        "id": 5482,
        "name": "\u5927\u8fde\u5de5\u4eba\u5927\u5b66"
    },
    {
        "id": 5483,
        "name": "\u5927\u8fde\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 5484,
        "name": "\u629a\u987a\u77ff\u52a1\u5c40\u804c\u5de5\u5de5\u5b66\u9662"
    },
    {
        "id": 5485,
        "name": "\u8fbd\u5b81\u5730\u8d28\u5de5\u7a0b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 5486,
        "name": "\u8fbd\u5b81\u4e2d\u533b\u836f\u5927\u5b66"
    },
    {
        "id": 5487,
        "name": "\u8fbd\u5b81\u5efa\u7b51\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5488,
        "name": "\u6c88\u9633\u56fd\u9645\u79d1\u5b66\u6280\u672f\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 5489,
        "name": "\u8fbd\u5b81\u5546\u52a1\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 5490,
        "name": "\u8fbd\u5b81\u8d22\u8d38\u5b66\u9662"
    },
    {
        "id": 5491,
        "name": "\u5927\u8fde\u5e02\u5de5\u4eba\u5927\u5b66"
    },
    {
        "id": 5492,
        "name": "\u8fbd\u5b81\u5e7f\u544a\u8bbe\u8ba1\u5b66\u9662"
    },
    {
        "id": 5493,
        "name": "\u6c88\u9633\u5de5\u4e1a\u5927\u5b66\uff08\u8fbd\u9633\u6821\u533a\uff09"
    },
    {
        "id": 5494,
        "name": "\u6c88\u9633\u5de5\u4e1a\u5927\u5b66(\u8fbd\u9633\u6821\u533a)"
    },
    {
        "id": 5495,
        "name": "\u8fbd\u5b81\u7701\u4ea4\u901a\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 5496,
        "name": "\u8fbd\u5b81\u7701\u57ce\u5e02\u5efa\u8bbe\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5497,
        "name": "\u8fbd\u5b81\u5bf9\u5916\u7ecf\u8d38\u5b66\u9662"
    },
    {
        "id": 5498,
        "name": "\u9c81\u8fc5\u7f8e\u672f\u5b66\u9662"
    },
    {
        "id": 5499,
        "name": "\u5927\u8fde\u827a\u672f\u5b66\u9662"
    },
    {
        "id": 5500,
        "name": "\u8fbd\u5b81\u7a0e\u52a1\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 5501,
        "name": "\u6c88\u9633\u804c\u4e1a\u6280\u672f\u5b66\u9662\u8ba1\u7b97\u673a\u5b66\u9662"
    },
    {
        "id": 5502,
        "name": "\u5409\u6797\u5927\u5b66\u5de5\u5546\u7ba1\u7406\u5b66\u9662"
    },
    {
        "id": 5503,
        "name": "\u8fbd\u5b81\u536b\u751f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5505,
        "name": "\u5927\u8fde\u822a\u8fd0\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5506,
        "name": "\u8fbd\u5b81\u4e1c\u65b9\u4e2d\u533b\u5b66\u9662"
    },
    {
        "id": 5508,
        "name": "\u8fbd\u5b81\u516c\u5b89\u53f8\u6cd5\u5e72\u90e8\u7ba1\u7406\u5b66\u9662"
    },
    {
        "id": 5509,
        "name": "\u4e2d\u56fd\u4eba\u6c11\u89e3\u653e\u519b\u5927\u8fde\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 5511,
        "name": "\u5927\u8fde\u88c5\u5907\u5236\u9020\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 5512,
        "name": "\u6c88\u9633\u5efa\u7b51\u5927\u5b66"
    },
    {
        "id": 5513,
        "name": "\u5927\u8fde\u4e1c\u65b9\u5916\u56fd\u8bed\u5b66\u9662"
    },
    {
        "id": 5514,
        "name": "\u9526\u5dde\u533b\u5b66\u9662\u755c\u7267\u517d\u533b\u5b66\u9662"
    },
    {
        "id": 5515,
        "name": "\u8fbd\u5b81\u94c1\u9053\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    }],
    "name": "\u8fbd\u5b81"
},
{
    "id": 6,
    "school": [{
        "id": 6001,
        "name": "\u5357\u5f00\u5927\u5b66"
    },
    {
        "id": 6002,
        "name": "\u5929\u6d25\u5927\u5b66"
    },
    {
        "id": 6003,
        "name": "\u6cb3\u5317\u5de5\u5927"
    },
    {
        "id": 6004,
        "name": "\u5929\u6d25\u5e08\u5927"
    },
    {
        "id": 6005,
        "name": "\u5929\u6d25\u5de5\u5927"
    },
    {
        "id": 6006,
        "name": "\u5929\u6d25\u79d1\u5927"
    },
    {
        "id": 6007,
        "name": "\u5929\u6d25\u7406\u5de5"
    },
    {
        "id": 6008,
        "name": "\u5929\u6d25\u533b\u5927"
    },
    {
        "id": 6009,
        "name": "\u5929\u6d25\u4e2d\u533b\u836f"
    },
    {
        "id": 6010,
        "name": "\u5929\u6d25\u8d22\u7ecf"
    },
    {
        "id": 6011,
        "name": "\u4e2d\u56fd\u6c11\u822a\u5927\u5b66"
    },
    {
        "id": 6012,
        "name": "\u5929\u6d25\u57ce\u5e02\u5efa\u8bbe\u5b66\u9662"
    },
    {
        "id": 6013,
        "name": "\u5929\u6d25\u519c\u9662"
    },
    {
        "id": 6015,
        "name": "\u5929\u6d25\u5916\u56fd\u8bed"
    },
    {
        "id": 6016,
        "name": "\u5929\u6d25\u5546\u4e1a\u5927\u5b66"
    },
    {
        "id": 6017,
        "name": "\u5929\u6d25\u4f53\u9662"
    },
    {
        "id": 6018,
        "name": "\u5929\u6d25\u97f3\u4e50\u5b66\u9662"
    },
    {
        "id": 6019,
        "name": "\u5929\u6d25\u7f8e\u9662"
    },
    {
        "id": 6020,
        "name": "\u5929\u6d25\u5e02\u56fd\u9645\u5546\u52a1\u5b66\u9662"
    },
    {
        "id": 6101,
        "name": "\u5929\u6d25\u5929\u72ee\u5b66\u9662"
    },
    {
        "id": 6102,
        "name": "\u5929\u6d25\u6ee8\u6d77\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 6103,
        "name": "\u5929\u6d25\u6e24\u6d77\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 6104,
        "name": "\u5929\u6d25\u57ce\u5e02\u5efa\u8bbe\u7ba1\u7406\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 6105,
        "name": "\u5929\u6d25\u57ce\u5e02\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 6106,
        "name": "\u5929\u6d25\u7535\u5b50\u4fe1\u606f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 6107,
        "name": "\u5929\u6d25\u5bf9\u5916\u7ecf\u6d4e\u8d38\u6613\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 6108,
        "name": "\u5929\u6d25\u5de5\u7a0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 6109,
        "name": "\u5929\u6d25\u5de5\u5546\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 6110,
        "name": "\u5929\u6d25\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 6111,
        "name": "\u5929\u6d25\u5de5\u827a\u7f8e\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 6112,
        "name": "\u5929\u6d25\u516c\u5b89\u8b66\u5b98\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 6113,
        "name": "\u5929\u6d25\u6d77\u8fd0\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 6114,
        "name": "\u5929\u6d25\u673a\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 6115,
        "name": "\u5929\u6d25\u4ea4\u901a\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 6116,
        "name": "\u5929\u6d25\u5f00\u53d1\u533a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 6117,
        "name": "\u5929\u6d25\u9752\u5e74\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 6118,
        "name": "\u5929\u6d25\u8f7b\u5de5\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 6119,
        "name": "\u5929\u6d25\u751f\u7269\u5de5\u7a0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 6120,
        "name": "\u5929\u6d25\u77f3\u6cb9\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 6121,
        "name": "\u5929\u6d25\u94c1\u9053\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 6122,
        "name": "\u5929\u6d25\u73b0\u4ee3\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 6123,
        "name": "\u5929\u6d25\u51b6\u91d1\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 6124,
        "name": "\u5929\u6d25\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 6125,
        "name": "\u5929\u6d25\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 6126,
        "name": "\u5929\u6d25\u804c\u4e1a\u5927\u5b66"
    },
    {
        "id": 6127,
        "name": "\u5929\u6d25\u4e2d\u5fb7\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 6128,
        "name": "\u5929\u6d25\u5e02\u5de5\u4f1a\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 6129,
        "name": "\u5929\u6d25\u5916\u56fd\u8bed\u5b66\u9662\u6ee8\u6d77\u5916\u4e8b\u5b66\u9662"
    },
    {
        "id": 6130,
        "name": "\u5929\u6d25\u4f53\u80b2\u5b66\u9662\u8fd0\u52a8\u4e0e\u6587\u5316\u827a\u672f\u5b66\u9662"
    },
    {
        "id": 6131,
        "name": "\u5929\u6d25\u5546\u5b66\u9662\u5b9d\u5fb7\u5b66\u9662"
    },
    {
        "id": 6132,
        "name": "\u5929\u6d25\u533b\u79d1\u5927\u5b66\u4e34\u5e8a\u533b\u5b66\u9662"
    },
    {
        "id": 6133,
        "name": "\u5317\u4eac\u79d1\u6280\u5927\u5b66\u5929\u6d25\u5b66\u9662"
    },
    {
        "id": 6134,
        "name": "\u5929\u6d25\u5e08\u8303\u5927\u5b66\u6d25\u6cbd\u5b66\u9662"
    },
    {
        "id": 6135,
        "name": "\u5929\u6d25\u7406\u5de5\u5927\u5b66\u4e2d\u73af\u4fe1\u606f\u5b66\u9662"
    },
    {
        "id": 6136,
        "name": "\u5929\u6d25\u5927\u5b66\u4ec1\u7231\u5b66\u9662"
    },
    {
        "id": 6137,
        "name": "\u5929\u6d25\u8d22\u7ecf\u5927\u5b66\u73e0\u6c5f\u5b66\u9662"
    },
    {
        "id": 6138,
        "name": "\u5357\u5f00\u5927\u5b66\u6ee8\u6d77\u5b66\u9662"
    },
    {
        "id": 6140,
        "name": "\u5929\u6d25\u5e02\u804c\u5de5\u7ecf\u6d4e\u6280\u672f\u5927\u5b66"
    },
    {
        "id": 6141,
        "name": "\u5929\u6d25\u5e02\u623f\u5730\u4ea7\u5c40\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 6142,
        "name": "\u5929\u6d25\u5e02\u653f\u6cd5\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 6143,
        "name": "\u5929\u6d25\u5e02\u8d22\u8d38\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 6144,
        "name": "\u5929\u6d25\u5e02\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 6145,
        "name": "\u5929\u6d25\u5e02\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 6146,
        "name": "\u5929\u6d25\u5e02\u6e24\u6d77\u5316\u5de5\u804c\u5de5\u5b66\u9662"
    },
    {
        "id": 6147,
        "name": "\u5929\u6d25\u5e02\u5357\u5f00\u533a\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 6148,
        "name": "\u5929\u6d25\u5e02\u7ea2\u6865\u533a\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 6149,
        "name": "\u5929\u6d25\u5e02\u5efa\u7b51\u5de5\u7a0b\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 6150,
        "name": "\u5929\u6d25\u5e02\u6cb3\u4e1c\u533a\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 6151,
        "name": "\u5929\u6d25\u5e02\u6cb3\u897f\u533a\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 6152,
        "name": "\u5929\u6d25\u5e02\u548c\u5e73\u533a\u65b0\u534e\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 6153,
        "name": "\u5929\u6d25\u7269\u8d44\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 6154,
        "name": "\u5929\u6d25\u5e02\u6d77\u519b\u5de5\u7a0b\u5927\u5b66"
    },
    {
        "id": 6155,
        "name": "\u4e2d\u56fd\u65c5\u6e38\u5e72\u90e8\u7ba1\u7406\u5b66\u9662"
    },
    {
        "id": 6159,
        "name": "\u5929\u6d25\u6d77\u8fd0\u804c\u4e1a\u5b66\u6821"
    },
    {
        "id": 6160,
        "name": "\u5929\u6d25\u5e02\u56fd\u571f\u8d44\u6e90\u548c\u623f\u5c4b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 6161,
        "name": "\u5929\u6d25\u8f7b\u5de5\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 6162,
        "name": "\u65af\u6ce2\u6cf0\u514b\u9ad8\u7ea7\u6280\u5de5\u5b66\u9662"
    },
    {
        "id": 6163,
        "name": "\u5929\u6d25\u5e7f\u64ad\u5f71\u89c6\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 6164,
        "name": "\u5929\u6d25\u804c\u4e1a\u6280\u672f\u5e08\u8303\u5927\u5b66"
    }],
    "name": "\u5929\u6d25"
},
{
    "id": 7,
    "school": [{
        "id": 7001,
        "name": "\u4e2d\u56fd\u79d1\u5927"
    },
    {
        "id": 7002,
        "name": "\u5b89\u5fbd\u5927\u5b66"
    },
    {
        "id": 7003,
        "name": "\u5408\u80a5\u5de5\u5927"
    },
    {
        "id": 7004,
        "name": "\u5b89\u5fbd\u533b\u79d1\u5927"
    },
    {
        "id": 7005,
        "name": "\u5b89\u5fbd\u5efa\u5de5"
    },
    {
        "id": 7006,
        "name": "\u5b89\u5fbd\u4e2d\u533b"
    },
    {
        "id": 7007,
        "name": "\u5408\u80a5\u5b66\u9662"
    },
    {
        "id": 7008,
        "name": "\u5b89\u5fbd\u6c7d\u8f66\u804c\u4e1a\u6280\u672f\u5b66\u9662 "
    },
    {
        "id": 7009,
        "name": "\u5b89\u5fbd\u519c\u5927"
    },
    {
        "id": 7010,
        "name": "\u5b89\u5fbd\u957f\u6c5f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 7011,
        "name": "\u6850\u57ce\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 7051,
        "name": "\u5b89\u5fbd\u5de5\u4e1a\u5927\u5b66"
    },
    {
        "id": 7101,
        "name": "\u5b89\u5fbd\u79d1\u6280"
    },
    {
        "id": 7102,
        "name": "\u7696\u5357\u533b\u5b66\u9662"
    },
    {
        "id": 7103,
        "name": "\u5b89\u5fbd\u5e08\u5927"
    },
    {
        "id": 7109,
        "name": "\u5b89\u5fbd\u5de5\u7a0b\u5927\u5b66"
    },
    {
        "id": 7151,
        "name": "\u868c\u57e0\u533b\u5b66\u9662"
    },
    {
        "id": 7152,
        "name": "\u5b89\u5fbd\u8d22\u7ecf"
    },
    {
        "id": 7201,
        "name": "\u961c\u9633\u5e08\u8303"
    },
    {
        "id": 7251,
        "name": "\u6dee\u5357\u5e08\u8303"
    },
    {
        "id": 7252,
        "name": "\u5b89\u5fbd\u7406\u5de5"
    },
    {
        "id": 7301,
        "name": "\u6dee\u5317\u5e08\u8303\u5927\u5b66"
    },
    {
        "id": 7351,
        "name": "\u5b89\u5e86\u5e08\u8303"
    },
    {
        "id": 7401,
        "name": "\u94dc\u9675\u5b66\u9662"
    },
    {
        "id": 7402,
        "name": "\u7696\u897f\u5b66\u9662"
    },
    {
        "id": 7451,
        "name": "\u5de2\u6e56\u5b66\u9662"
    },
    {
        "id": 7501,
        "name": "\u6ec1\u5dde\u5b66\u9662"
    },
    {
        "id": 7551,
        "name": "\u5bbf\u5dde\u5b66\u9662"
    },
    {
        "id": 7601,
        "name": "\u9ec4\u5c71\u5b66\u9662"
    },
    {
        "id": 7602,
        "name": "\u65b0\u534e\u5b66\u9662"
    },
    {
        "id": 7701,
        "name": "\u868c\u57e0\u5b66\u9662"
    },
    {
        "id": 7702,
        "name": "\u5b89\u5fbd\u8d22\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 7703,
        "name": "\u5b89\u5fbd\u57ce\u5e02\u7ba1\u7406\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 7704,
        "name": "\u5b89\u5fbd\u7535\u6c14\u5de5\u7a0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7705,
        "name": "\u5b89\u5fbd\u7535\u5b50\u4fe1\u606f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7706,
        "name": "\u5b89\u5fbd\u5de5\u8d38\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7707,
        "name": "\u5b89\u5fbd\u5de5\u5546\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 7708,
        "name": "\u5b89\u5fbd\u5de5\u4e1a\u7ecf\u6d4e\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7709,
        "name": "\u5b89\u5fbd\u516c\u5b89\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 7710,
        "name": "\u5b89\u5fbd\u5e7f\u64ad\u5f71\u89c6\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7711,
        "name": "\u5b89\u5fbd\u56fd\u9632\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 7712,
        "name": "\u5b89\u5fbd\u56fd\u9645\u5546\u52a1\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 7713,
        "name": "\u5b89\u5fbd\u673a\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7714,
        "name": "\u5b89\u5fbd\u4ea4\u901a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7715,
        "name": "\u5b89\u5fbd\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 7716,
        "name": "\u5b89\u5fbd\u7ecf\u6d4e\u7ba1\u7406\u5b66\u9662"
    },
    {
        "id": 7717,
        "name": "\u5b89\u5fbd\u8b66\u5b98\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 7718,
        "name": "\u5b89\u5fbd\u6797\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7719,
        "name": "\u5b89\u5fbd\u660e\u661f\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 7720,
        "name": "\u5b89\u5fbd\u5546\u8d38\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7721,
        "name": "\u5b89\u5fbd\u5ba1\u8ba1\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 7722,
        "name": "\u5b89\u5fbd\u4e09\u8054\u5b66\u9662"
    },
    {
        "id": 7724,
        "name": "\u5b89\u5fbd\u6c34\u5229\u6c34\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7725,
        "name": "\u5b89\u5fbd\u4f53\u80b2\u8fd0\u52a8\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7726,
        "name": "\u5b89\u5fbd\u6587\u8fbe\u4fe1\u606f\u6280\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 7727,
        "name": "\u5b89\u5fbd\u65b0\u95fb\u51fa\u7248\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7728,
        "name": "\u5b89\u5fbd\u51b6\u91d1\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 7729,
        "name": "\u5b89\u5fbd\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 7730,
        "name": "\u5b89\u5fbd\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 7731,
        "name": "\u5b89\u5fbd\u90ae\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7732,
        "name": "\u5b89\u5fbd\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7733,
        "name": "\u5b89\u5fbd\u4e2d\u6fb3\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 7734,
        "name": "\u5b89\u5fbd\u4e2d\u533b\u836f\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 7735,
        "name": "\u5b89\u5e86\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7736,
        "name": "\u868c\u57e0\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 7737,
        "name": "\u868c\u57e0\u804c\u4e1a\u6559\u80b2\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 7738,
        "name": "\u4eb3\u5dde\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 7739,
        "name": "\u4eb3\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7740,
        "name": "\u5de2\u6e56\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7741,
        "name": "\u6c60\u5dde\u5b66\u9662"
    },
    {
        "id": 7742,
        "name": "\u6c60\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7743,
        "name": "\u6ec1\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7744,
        "name": "\u961c\u9633\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 7745,
        "name": "\u961c\u9633\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7746,
        "name": "\u5408\u80a5\u5e02\u4e07\u535a\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 7747,
        "name": "\u5408\u80a5\u901a\u7528\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7748,
        "name": "\u6dee\u5317\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7749,
        "name": "\u6dee\u5357\u8054\u5408\u5927\u5b66"
    },
    {
        "id": 7750,
        "name": "\u6dee\u5357\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7752,
        "name": "\u516d\u5b89\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7753,
        "name": "\u9a6c\u978d\u5c71\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 7754,
        "name": "\u5b89\u5fbd\u5916\u56fd\u8bed\u5b66\u9662"
    },
    {
        "id": 7755,
        "name": "\u6c11\u529e\u5408\u80a5\u7ecf\u6d4e\u6280\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 7756,
        "name": "\u5bbf\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7757,
        "name": "\u94dc\u9675\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7758,
        "name": "\u829c\u6e56\u4fe1\u606f\u6280\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 7759,
        "name": "\u829c\u6e56\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7760,
        "name": "\u5ba3\u57ce\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7761,
        "name": "\u5b89\u5fbd\u5e08\u8303\u5927\u5b66\u7696\u6c5f\u5b66\u9662"
    },
    {
        "id": 7762,
        "name": "\u5408\u80a5\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 7763,
        "name": "\u51e4\u9633\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 7764,
        "name": "\u5b89\u5e86\u533b\u836f\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 7765,
        "name": "\u5b89\u5fbd\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 7766,
        "name": "\u5408\u80a5\u5e7c\u513f\u5e08\u8303\u5b66\u6821"
    },
    {
        "id": 7767,
        "name": "\u868c\u57e0\u7ecf\u6d4e\u6280\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 7768,
        "name": "\u5408\u80a5\u8d22\u7ecf\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 7769,
        "name": "\u5fbd\u5546\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 7770,
        "name": "\u6c11\u529e\u5b89\u5fbd\u65c5\u6e38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 7771,
        "name": "\u5bbf\u53bf\u5730\u533a\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 7772,
        "name": "\u6dee\u5357\u5e02\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 7773,
        "name": "\u5408\u80a5\u804c\u5de5\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 7774,
        "name": "\u5408\u80a5\u5e02\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 7775,
        "name": "\u5b89\u5fbd\u7701\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 7776,
        "name": "\u5b89\u5fbd\u7eff\u6d77\u5546\u52a1\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 7777,
        "name": "\u6cb3\u6d77\u5927\u5b66\u6587\u5929\u5b66\u9662"
    },
    {
        "id": 7778,
        "name": "\u5b89\u5fbd\u6d89\u5916\u7ecf\u6d4e\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 7780,
        "name": "\u7696\u5357\u519c\u5b66\u9662"
    },
    {
        "id": 7781,
        "name": "\u5b89\u5fbd\u868c\u57e0\u6c7d\u8f66\u7ba1\u7406\u5b66\u9662"
    },
    {
        "id": 7785,
        "name": "\u5b89\u5fbd\u65b0\u534e\u7535\u8111\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 7787,
        "name": "\u868c\u57e0\u5766\u514b\u5b66\u9662"
    },
    {
        "id": 7789,
        "name": "\u5b89\u5fbd\u5408\u80a5\u673a\u7535\u6280\u5e08\u5b66\u9662"
    },
    {
        "id": 7790,
        "name": "\u5b89\u5fbd\u6280\u672f\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 7791,
        "name": "\u5bbf\u5dde\u5e02\u8054\u5408\u5927\u5b66"
    },
    {
        "id": 7792,
        "name": "\u5b89\u5fbd\u7ecf\u6d4e\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    }],
    "name": "\u5b89\u5fbd"
},
{
    "id": 8,
    "school": [{
        "id": 8001,
        "name": "\u5357\u4eac\u5927\u5b66"
    },
    {
        "id": 8002,
        "name": "\u6cb3\u6d77\u5927\u5b66"
    },
    {
        "id": 8003,
        "name": "\u5357\u4eac\u5e08\u5927"
    },
    {
        "id": 8004,
        "name": "\u5357\u4eac\u7406\u5de5"
    },
    {
        "id": 8005,
        "name": "\u4e1c\u5357\u5927\u5b66"
    },
    {
        "id": 8006,
        "name": "\u5357\u4eac\u822a\u7a7a\u822a\u5929\u5927\u5b66"
    },
    {
        "id": 8007,
        "name": "\u5357\u4eac\u8d22\u7ecf"
    },
    {
        "id": 8008,
        "name": "\u5357\u4eac\u533b\u79d1\u5927"
    },
    {
        "id": 8009,
        "name": "\u5357\u4eac\u5de5\u5927"
    },
    {
        "id": 8010,
        "name": "\u5357\u4eac\u519c\u5927"
    },
    {
        "id": 8011,
        "name": "\u5357\u4eac\u6797\u5927"
    },
    {
        "id": 8012,
        "name": "\u5357\u90ae"
    },
    {
        "id": 8013,
        "name": "\u5357\u4eac\u4fe1\u606f\u5de5\u7a0b\u5927\u5b66"
    },
    {
        "id": 8014,
        "name": "\u5357\u4eac\u4e2d\u533b\u836f"
    },
    {
        "id": 8015,
        "name": "\u5357\u4eac\u5de5\u7a0b"
    },
    {
        "id": 8016,
        "name": "\u91d1\u9675\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 8017,
        "name": "\u5357\u4eac\u6653\u5e84\u5b66\u9662"
    },
    {
        "id": 8018,
        "name": "\u5357\u4eac\u5ba1\u8ba1\u5b66\u9662"
    },
    {
        "id": 8019,
        "name": "\u6c5f\u82cf\u8b66\u5b98\u5b66\u9662"
    },
    {
        "id": 8020,
        "name": "\u5357\u4eac\u4f53\u9662"
    },
    {
        "id": 8021,
        "name": "\u5357\u4eac\u827a\u672f\u5b66\u9662"
    },
    {
        "id": 8022,
        "name": "\u4e09\u6c5f\u5b66\u9662"
    },
    {
        "id": 8023,
        "name": "\u4e2d\u56fd\u836f\u79d1\u5927"
    },
    {
        "id": 8024,
        "name": "\u82cf\u5dde\u5de5\u4e1a\u56ed\u533a\u670d\u52a1\u5916\u5305\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 8025,
        "name": "\u65e0\u9521\u9ad8\u7b49\u5e08\u8303\u5b66\u6821"
    },
    {
        "id": 8026,
        "name": "\u5357\u4eac\u5de5\u7a0b\u9ad8\u7b49\u804c\u4e1a\u5b66\u6821"
    },
    {
        "id": 8027,
        "name": "\u5357\u4eac\u653f\u6cbb\u5b66\u9662"
    },
    {
        "id": 8028,
        "name": "\u6c5f\u82cf\u6280\u672f\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 8029,
        "name": "\u6dee\u5b89\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 8030,
        "name": "\u5f90\u5dde\u7ecf\u8d38\u9ad8\u7b49\u804c\u4e1a\u5b66\u6821"
    },
    {
        "id": 8031,
        "name": "\u6c5f\u82cf\u5e08\u8303\u5927\u5b66"
    },
    {
        "id": 8051,
        "name": "\u82cf\u5dde\u5927\u5b66"
    },
    {
        "id": 8101,
        "name": "\u6c5f\u5357\u5927\u5b66"
    },
    {
        "id": 8151,
        "name": "\u4e2d\u56fd\u77ff\u4e1a\u5927\u5b66"
    },
    {
        "id": 8152,
        "name": "\u5f90\u5dde\u5e08\u5927"
    },
    {
        "id": 8154,
        "name": "\u5f90\u5dde\u533b\u5b66\u9662"
    },
    {
        "id": 8201,
        "name": "\u626c\u5dde\u5927\u5b66"
    },
    {
        "id": 8251,
        "name": "\u6c5f\u82cf\u5927\u5b66"
    },
    {
        "id": 8252,
        "name": "\u6c5f\u82cf\u79d1\u5927"
    },
    {
        "id": 8301,
        "name": "\u5357\u901a\u5927\u5b66"
    },
    {
        "id": 8351,
        "name": "\u5e38\u5dde\u5927\u5b66"
    },
    {
        "id": 8352,
        "name": "\u5e38\u5dde\u5de5\u5b66\u9662"
    },
    {
        "id": 8353,
        "name": "\u6c5f\u82cf\u6280\u672f\u5e08\u8303"
    },
    {
        "id": 8401,
        "name": "\u6dee\u9634\u5de5\u5b66\u9662"
    },
    {
        "id": 8402,
        "name": "\u6dee\u9634\u5e08\u8303"
    },
    {
        "id": 8451,
        "name": "\u6dee\u6d77\u5de5\u5b66\u9662"
    },
    {
        "id": 8501,
        "name": "\u76d0\u57ce\u5de5\u5b66\u9662"
    },
    {
        "id": 8502,
        "name": "\u76d0\u57ce\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 8551,
        "name": "\u5e38\u719f\u7406\u5de5"
    },
    {
        "id": 8601,
        "name": "\u5e38\u5dde\u7eba\u7ec7\u670d\u88c5\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8602,
        "name": "\u5e38\u5dde\u5de5\u7a0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8603,
        "name": "\u5e38\u5dde\u673a\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8605,
        "name": "\u5e38\u5dde\u8f7b\u5de5\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8606,
        "name": "\u5e38\u5dde\u4fe1\u606f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8607,
        "name": "\u7845\u6e56\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8608,
        "name": "\u6cb3\u6d77\u5927\u5b66\u5e38\u5dde\u6821\u533a"
    },
    {
        "id": 8609,
        "name": "\u6dee\u5b89\u4fe1\u606f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8610,
        "name": "\u5efa\u4e1c\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8611,
        "name": "\u5065\u96c4\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8612,
        "name": "\u6c5f\u6d77\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8613,
        "name": "\u6c5f\u5357\u5f71\u89c6\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 8614,
        "name": "\u6c5f\u82cf\u8d22\u7ecf\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8615,
        "name": "\u6c5f\u82cf\u6d77\u4e8b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8616,
        "name": "\u6c5f\u82cf\u7ecf\u8d38\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8617,
        "name": "\u6c5f\u82cf\u8054\u5408\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8618,
        "name": "\u6c5f\u82cf\u519c\u6797\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8619,
        "name": "\u6c5f\u82cf\u98df\u54c1\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8620,
        "name": "\u6c5f\u82cf\u4fe1\u606f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8621,
        "name": "\u6c5f\u82cf\u755c\u7267\u517d\u533b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8622,
        "name": "\u6c5f\u9634\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8623,
        "name": "\u91d1\u80af\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8624,
        "name": "\u91d1\u5c71\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8625,
        "name": "\u4e5d\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8626,
        "name": "\u6606\u5c71\u767b\u4e91\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 8627,
        "name": "\u8fde\u4e91\u6e2f\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 8628,
        "name": "\u8fde\u4e91\u6e2f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8629,
        "name": "\u6c11\u529e\u660e\u8fbe\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8630,
        "name": "\u5357\u4eac\u52a8\u529b\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 8631,
        "name": "\u5357\u4eac\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8632,
        "name": "\u5357\u4eac\u5316\u5de5\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8633,
        "name": "\u5357\u4eac\u4ea4\u901a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8634,
        "name": "\u5357\u4eac\u4eba\u53e3\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 8635,
        "name": "\u5357\u4eac\u68ee\u6797\u8b66\u5bdf\u5b66\u9662"
    },
    {
        "id": 8636,
        "name": "\u5357\u4eac\u89c6\u89c9\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 8637,
        "name": "\u5357\u4eac\u7279\u6b8a\u6559\u80b2\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8638,
        "name": "\u5357\u4eac\u94c1\u9053\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8639,
        "name": "\u5357\u4eac\u4fe1\u606f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8640,
        "name": "\u5357\u901a\u7eba\u7ec7\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8641,
        "name": "\u5357\u901a\u822a\u8fd0\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8642,
        "name": "\u5357\u901a\u519c\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8643,
        "name": "\u5357\u901a\u804c\u4e1a\u5927\u5b66"
    },
    {
        "id": 8644,
        "name": "\u57f9\u5c14\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8645,
        "name": "\u6c99\u6d32\u804c\u4e1a\u5de5\u5b66\u9662"
    },
    {
        "id": 8646,
        "name": "\u82cf\u5dde\u6e2f\u5927\u601d\u57f9\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 8647,
        "name": "\u82cf\u5dde\u5de5\u4e1a\u56ed\u533a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8648,
        "name": "\u82cf\u5dde\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8649,
        "name": "\u82cf\u5dde\u5de5\u827a\u7f8e\u672f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8650,
        "name": "\u82cf\u5dde\u7ecf\u8d38\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8651,
        "name": "\u82cf\u5dde\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 8654,
        "name": "\u82cf\u5dde\u519c\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8655,
        "name": "\u82cf\u5dde\u6258\u666e\u4fe1\u606f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8656,
        "name": "\u82cf\u5dde\u804c\u4e1a\u5927\u5b66"
    },
    {
        "id": 8657,
        "name": "\u5bbf\u8fc1\u5b66\u9662"
    },
    {
        "id": 8658,
        "name": "\u6cf0\u5dde\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 8659,
        "name": "\u6cf0\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8660,
        "name": "\u65e0\u9521\u57ce\u5e02\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8661,
        "name": "\u65e0\u9521\u5de5\u827a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8662,
        "name": "\u65e0\u9521\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 8663,
        "name": "\u65e0\u9521\u5357\u6d0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8664,
        "name": "\u65e0\u9521\u8f7b\u5de5\u5927\u5b66"
    },
    {
        "id": 8665,
        "name": "\u65e0\u9521\u5546\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8666,
        "name": "\u65e0\u9521\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8667,
        "name": "\u5f90\u5dde\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8668,
        "name": "\u5f90\u5dde\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 8669,
        "name": "\u5f90\u5dde\u5efa\u7b51\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8670,
        "name": "\u5f90\u5dde\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 8671,
        "name": "\u5f90\u5dde\u5de5\u7a0b\u5b66\u9662"
    },
    {
        "id": 8672,
        "name": "\u708e\u9ec4\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8673,
        "name": "\u76d0\u57ce\u7eba\u7ec7\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8674,
        "name": "\u626c\u5dde\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8675,
        "name": "\u626c\u5dde\u73af\u5883\u8d44\u6e90\u7ba1\u7406\u5b66\u9662"
    },
    {
        "id": 8676,
        "name": "\u626c\u5dde\u73af\u5883\u8d44\u6e90\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8677,
        "name": "\u626c\u5dde\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 8678,
        "name": "\u626c\u5dde\u804c\u4e1a\u5927\u5b66"
    },
    {
        "id": 8679,
        "name": "\u5e94\u5929\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8680,
        "name": "\u9547\u6c5f\u5e02\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 8681,
        "name": "\u6b63\u5fb7\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8682,
        "name": "\u4e2d\u56fd\u4f20\u5a92\u5927\u5b66\u5357\u5e7f\u5b66\u9662"
    },
    {
        "id": 8683,
        "name": "\u949f\u5c71\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8684,
        "name": "\u7d2b\u7405\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8685,
        "name": "\u6c5f\u82cf\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 8686,
        "name": "\u6c5f\u82cf\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 8687,
        "name": "\u5f90\u5dde\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 8688,
        "name": "\u6c5f\u82cf\u7701\u7701\u7ea7\u673a\u5173\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 8689,
        "name": "\u6c5f\u82cf\u804c\u5de5\u533b\u79d1\u5927\u5b66"
    },
    {
        "id": 8690,
        "name": "\u82cf\u5dde\u536b\u751f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8691,
        "name": "\u76d0\u57ce\u536b\u751f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8692,
        "name": "\u91d1\u9675\u65c5\u9986\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 8693,
        "name": "\u5357\u4eac\u5e02\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 8694,
        "name": "\u5357\u4eac\u673a\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8695,
        "name": "\u6c5f\u82cf\u57ce\u5e02\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 8697,
        "name": "\u82cf\u5dde\u9ad8\u535a\u8f6f\u4ef6\u6280\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 8698,
        "name": "\u5357\u4eac\u65c5\u6e38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 8699,
        "name": "\u7a7a\u519b\u7b2c\u4e00\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 8700,
        "name": "\u6c5f\u82cf\u7701\u9752\u5e74\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 8701,
        "name": "\u6c5f\u82cf\u7701\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 8702,
        "name": "\u5357\u4eac\u91d1\u9675\u65c5\u9986\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 8703,
        "name": "\u5357\u901a\u5e02\u5de5\u4eba\u4e1a\u4f59\u5927\u5b66"
    },
    {
        "id": 8704,
        "name": "\u5e38\u5dde\u5e02\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 8705,
        "name": "\u5357\u4eac\u5e02\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 8706,
        "name": "\u5357\u4eac\u8054\u5408\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 8707,
        "name": "\u6c5f\u82cf\u7535\u529b\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 8710,
        "name": "\u5bbf\u8fc1\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8711,
        "name": "\u5357\u4eac\u5de5\u7a0b\u5175\u5de5\u7a0b\u5b66\u6821"
    },
    {
        "id": 8712,
        "name": "\u5357\u4eac\u7406\u5de5\u5927\u5b66\u6cf0\u5dde\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 8713,
        "name": "\u4e1c\u5357\u5927\u5b66\u6210\u8d24\u5b66\u9662"
    },
    {
        "id": 8714,
        "name": "\u5357\u4eac\u7406\u5de5\u5927\u5b66\u7d2b\u91d1\u5b66\u9662"
    },
    {
        "id": 8715,
        "name": "\u5357\u4eac\u822a\u7a7a\u822a\u5929\u5927\u5b66\u91d1\u57ce\u5b66\u9662"
    },
    {
        "id": 8716,
        "name": "\u5357\u4eac\u8d22\u7ecf\u5927\u5b66\u7ea2\u5c71\u5b66\u9662"
    },
    {
        "id": 8717,
        "name": "\u5357\u4eac\u5e08\u8303\u5927\u5b66\u6cf0\u5dde\u5b66\u9662"
    },
    {
        "id": 8718,
        "name": "\u5357\u4eac\u5ba1\u8ba1\u5b66\u9662\u91d1\u5ba1\u5b66\u9662"
    },
    {
        "id": 8719,
        "name": "\u5357\u901a\u5927\u5b66\u674f\u6797\u5b66\u9662"
    },
    {
        "id": 8720,
        "name": "\u6c5f\u82cf\u5de5\u4e1a\u5b66\u9662\u6000\u5fb7\u5b66\u9662"
    },
    {
        "id": 8721,
        "name": "\u6c5f\u82cf\u79d1\u6280\u5927\u5b66\u5357\u5f90\u5b66\u9662"
    },
    {
        "id": 8722,
        "name": "\u5357\u4eac\u90ae\u7535\u5927\u5b66\u901a\u8fbe\u5b66\u9662"
    },
    {
        "id": 8723,
        "name": "\u5f90\u5dde\u5e08\u8303\u5927\u5b66\u79d1\u6587\u5b66\u9662"
    },
    {
        "id": 8724,
        "name": "\u626c\u5dde\u5927\u5b66\u5e7f\u9675\u5b66\u9662"
    },
    {
        "id": 8725,
        "name": "\u6c5f\u82cf\u5927\u5b66\u4eac\u6c5f\u5b66\u9662"
    },
    {
        "id": 8726,
        "name": "\u82cf\u5dde\u79d1\u6280\u5b66\u9662\u5929\u5e73\u5b66\u9662"
    },
    {
        "id": 8727,
        "name": "\u82cf\u5dde\u5927\u5b66\u5e94\u7528\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8728,
        "name": "\u82cf\u5dde\u5927\u5b66\u6587\u6b63\u5b66\u9662"
    },
    {
        "id": 8729,
        "name": "\u5357\u4eac\u4fe1\u606f\u5de5\u7a0b\u5927\u5b66\u6ee8\u6c5f\u5b66\u9662"
    },
    {
        "id": 8730,
        "name": "\u5357\u4eac\u4e2d\u533b\u836f\u5927\u5b66\u7ff0\u6797\u5b66\u9662"
    },
    {
        "id": 8731,
        "name": "\u5357\u4eac\u533b\u79d1\u5927\u5b66\u5eb7\u8fbe\u5b66\u9662"
    },
    {
        "id": 8732,
        "name": "\u5357\u4eac\u5e08\u8303\u5927\u5b66\u4e2d\u5317\u5b66\u9662"
    },
    {
        "id": 8733,
        "name": "\u5357\u4eac\u5de5\u4e1a\u5927\u5b66\u6d66\u6c5f\u5b66\u9662"
    },
    {
        "id": 8734,
        "name": "\u6c5f\u5357\u5927\u5b66\u592a\u6e56\u5b66\u9662"
    },
    {
        "id": 8735,
        "name": "\u5357\u901a\u4f53\u81e3\u536b\u751f\u5b66\u6821"
    },
    {
        "id": 8736,
        "name": "\u5357\u901a\u5e02\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 8737,
        "name": "\u6c5f\u82cf\u7701\u5357\u901a\u5546\u8d38\u9ad8\u7b49\u804c\u4e1a\u5b66\u6821"
    },
    {
        "id": 8738,
        "name": "\u5357\u901a\u9ad8\u7b49\u5e08\u8303\u5b66\u6821"
    },
    {
        "id": 8739,
        "name": "\u5982\u768b\u9ad8\u7b49\u5e08\u8303\u5b66\u6821"
    },
    {
        "id": 8740,
        "name": "\u5357\u901a\u5e02\u4e2d\u7b49\u4e13\u4e1a\u5b66\u6821"
    },
    {
        "id": 8741,
        "name": "\u592a\u6e56\u521b\u610f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8742,
        "name": "\u897f\u4ea4\u5229\u7269\u6d66\u5927\u5b66"
    },
    {
        "id": 8743,
        "name": "\u5357\u4eac\u4e2d\u5929\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 8744,
        "name": "\u6c5f\u82cf\u82cf\u5dde\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 8745,
        "name": "\u5357\u4eac\u91d1\u9675\u79d1\u6280\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 8746,
        "name": "\u6c5f\u82cf\u79d1\u6280\u7ecf\u8d38\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 8747,
        "name": "\u6c5f\u82cf\u7701\u65e0\u9521\u4ea4\u901a\u9ad8\u7b49\u804c\u4e1a\u6280\u672f\u5b66\u6821"
    },
    {
        "id": 8748,
        "name": "\u5357\u4eac\u65b0\u534e\u7535\u8111\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 8749,
        "name": "\u6cb3\u6d77\u5927\u5b66\u7ee7\u7eed\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 8750,
        "name": "\u5357\u4eac\u6280\u5e08\u5b66\u9662"
    },
    {
        "id": 8751,
        "name": "\u6c5f\u82cf\u5efa\u5eb7\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 8752,
        "name": "\u6c5f\u82cf\u57ce\u9547\u5efa\u8bbe\u5b66\u6821"
    },
    {
        "id": 8753,
        "name": "\u5357\u4eac\u822a\u5929\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 8754,
        "name": "\u4e2d\u56fd\u4eba\u6c11\u5927\u5b66\u56fd\u9645\u5b66\u9662"
    },
    {
        "id": 8755,
        "name": "\u5f90\u5dde\u5e7c\u513f\u9ad8\u7b49\u5e08\u8303\u5b66\u6821"
    },
    {
        "id": 8756,
        "name": "\u6c5f\u82cf\u6280\u672f\u5e08\u8303\u4e1c\u65b9\u5b66\u9662"
    },
    {
        "id": 8757,
        "name": "\u5357\u4eac\u9ad8\u7b49\u804c\u4e1a\u6280\u672f\u5b66\u6821"
    },
    {
        "id": 8758,
        "name": "\u6c5f\u82cf\u7701\u53f8\u6cd5\u8b66\u5b98\u9ad8\u7b49\u804c\u4e1a\u5b66\u6821"
    },
    {
        "id": 8760,
        "name": "\u82cf\u5dde\u5927\u5b66\u5bbf\u8fc1\u5b66\u9662"
    },
    {
        "id": 8761,
        "name": "\u5f90\u5dde\u533b\u5b66\u9662\u534e\u65b9\u5b66\u9662"
    },
    {
        "id": 8762,
        "name": "\u4e2d\u56fd\u77ff\u4e1a\u5927\u5b66\u5f90\u6d77\u5b66\u9662"
    },
    {
        "id": 8764,
        "name": "\u82cf\u5dde\u4fe1\u606f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8765,
        "name": "\u6c5f\u82cf\u4e1c\u5357\u79d1\u6280\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 8766,
        "name": "\u76d0\u57ce\u751f\u7269\u5de5\u7a0b\u9ad8\u7b49\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 8767,
        "name": "\u6c5f\u82cf\u7535\u5927\u901a\u5dde\u5b66\u9662"
    },
    {
        "id": 8768,
        "name": "\u5357\u4eac\u5927\u5b66\u91d1\u9675\u5b66\u9662"
    },
    {
        "id": 8769,
        "name": "\u8fd0\u6cb3\u9ad8\u7b49\u5e08\u8303\u5b66\u6821"
    },
    {
        "id": 8770,
        "name": "\u5357\u4eac\u4e1c\u65b9\u6587\u7406\u7814\u4fee\u5b66\u9662"
    },
    {
        "id": 8771,
        "name": "\u5f90\u5dde\u8d22\u7ecf\u9ad8\u7b49\u804c\u4e1a\u6280\u672f\u5b66\u6821"
    }],
    "name": "\u6c5f\u82cf"
},
{
    "id": 9,
    "school": [{
        "id": 9001,
        "name": "\u6d59\u6c5f\u5927\u5b66"
    },
    {
        "id": 9002,
        "name": "\u6d59\u6c5f\u7406\u5de5"
    },
    {
        "id": 9003,
        "name": "\u6d59\u6c5f\u5de5\u5927"
    },
    {
        "id": 9004,
        "name": "\u676d\u5dde\u7535\u5b50\u79d1\u5927"
    },
    {
        "id": 9005,
        "name": "\u6d59\u6c5f\u4e2d\u533b\u836f"
    },
    {
        "id": 9006,
        "name": "\u6d59\u6c5f\u5de5\u5546"
    },
    {
        "id": 9007,
        "name": "\u4e2d\u56fd\u8ba1\u91cf"
    },
    {
        "id": 9008,
        "name": "\u6d59\u6c5f\u79d1\u6280"
    },
    {
        "id": 9009,
        "name": "\u6d59\u6c5f\u519c\u6797\u5927\u5b66"
    },
    {
        "id": 9010,
        "name": "\u676d\u5dde\u5e08\u8303"
    },
    {
        "id": 9011,
        "name": "\u6d59\u6c5f\u4f20\u5a92"
    },
    {
        "id": 9012,
        "name": "\u6d59\u6c5f\u8d22\u7ecf"
    },
    {
        "id": 9013,
        "name": "\u4e2d\u56fd\u7f8e\u672f\u5b66\u9662"
    },
    {
        "id": 9014,
        "name": "\u6811\u4eba\u5927\u5b66"
    },
    {
        "id": 9015,
        "name": "\u6d59\u6c5f\u519c\u4e1a\u5546\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 9016,
        "name": "\u6d59\u6c5f\u4e09\u8054\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 9017,
        "name": "\u676d\u5dde\u4e4b\u6c5f\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 9018,
        "name": "\u4e0a\u6d77\u6749\u8fbe\u5b66\u9662\u5609\u5584\u5149\u5f6a\u5b66\u9662"
    },
    {
        "id": 9051,
        "name": "\u5b81\u6ce2\u5927\u5b66"
    },
    {
        "id": 9052,
        "name": "\u5b81\u6ce2\u5de5\u7a0b"
    },
    {
        "id": 9053,
        "name": "\u4e07\u91cc\u5b66\u9662"
    },
    {
        "id": 9054,
        "name": "\u8bfa\u4e01\u6c49\u5927\u5b66"
    },
    {
        "id": 9101,
        "name": "\u5609\u5174\u5b66\u9662"
    },
    {
        "id": 9151,
        "name": "\u6d59\u6c5f\u6d77\u6d0b"
    },
    {
        "id": 9201,
        "name": "\u6e29\u5dde\u5927\u5b66"
    },
    {
        "id": 9202,
        "name": "\u6e29\u5dde\u533b\u5b66\u9662"
    },
    {
        "id": 9251,
        "name": "\u6e56\u5dde\u5e08\u8303"
    },
    {
        "id": 9301,
        "name": "\u53f0\u5dde\u5b66\u9662"
    },
    {
        "id": 9351,
        "name": "\u7ecd\u5174\u6587\u7406\u5b66\u9662"
    },
    {
        "id": 9401,
        "name": "\u4e3d\u6c34\u5b66\u9662"
    },
    {
        "id": 9451,
        "name": "\u6d59\u6c5f\u5e08\u5927"
    },
    {
        "id": 9501,
        "name": "\u957f\u5f81\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9502,
        "name": "\u516c\u5b89\u6d77\u8b66\u5b66\u9662"
    },
    {
        "id": 9503,
        "name": "\u676d\u5dde\u4e07\u5411\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9504,
        "name": "\u676d\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9505,
        "name": "\u6e56\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9507,
        "name": "\u5609\u5174\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9508,
        "name": "\u91d1\u534e\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9509,
        "name": "\u79d1\u6280\u6c42\u662f\u5b66\u9662"
    },
    {
        "id": 9510,
        "name": "\u4e3d\u6c34\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9511,
        "name": "\u5b81\u6ce2\u57ce\u5e02\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9512,
        "name": "\u5b81\u6ce2\u5927\u7ea2\u9e70\u5b66\u9662"
    },
    {
        "id": 9513,
        "name": "\u5b81\u6ce2\u5927\u5b66\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 9514,
        "name": "\u5b81\u6ce2\u5929\u4e00\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9515,
        "name": "\u5b81\u6ce2\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9516,
        "name": "\u8862\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9517,
        "name": "\u7ecd\u5174\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9518,
        "name": "\u6d59\u6c5f\u8d8a\u79c0\u5916\u56fd\u8bed\u5b66\u9662"
    },
    {
        "id": 9519,
        "name": "\u53f0\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9520,
        "name": "\u6e29\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9521,
        "name": "\u4e49\u4e4c\u5de5\u5546\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9522,
        "name": "\u6d59\u6c5f\u5927\u5b66\u57ce\u5e02\u5b66\u9662"
    },
    {
        "id": 9523,
        "name": "\u6d59\u6c5f\u5927\u5b66\u5b81\u6ce2\u7406\u5de5\u5b66\u9662"
    },
    {
        "id": 9524,
        "name": "\u6d59\u6c5f\u4e1c\u65b9\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9525,
        "name": "\u6d59\u6c5f\u7eba\u7ec7\u670d\u88c5\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9526,
        "name": "\u6d59\u6c5f\u5de5\u8d38\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9527,
        "name": "\u6d59\u6c5f\u5de5\u5546\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9528,
        "name": "\u6d59\u6c5f\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9529,
        "name": "\u6d59\u6c5f\u8b66\u5bdf\u5b66\u9662"
    },
    {
        "id": 9530,
        "name": "\u6d59\u6c5f\u5e7f\u53a6\u5efa\u8bbe\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9531,
        "name": "\u6d59\u6c5f\u673a\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9532,
        "name": "\u6d59\u6c5f\u5efa\u8bbe\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9533,
        "name": "\u6d59\u6c5f\u4ea4\u901a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9534,
        "name": "\u6d59\u6c5f\u5916\u56fd\u8bed\u5b66\u9662"
    },
    {
        "id": 9535,
        "name": "\u6d59\u6c5f\u91d1\u878d\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 9536,
        "name": "\u6d59\u6c5f\u7ecf\u6d4e\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9537,
        "name": "\u6d59\u6c5f\u7ecf\u8d38\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9538,
        "name": "\u6d59\u6c5f\u8b66\u5b98\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 9539,
        "name": "\u6d59\u6c5f\u65c5\u6e38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 9540,
        "name": "\u6d59\u6c5f\u5546\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9541,
        "name": "\u6d59\u6c5f\u6c34\u5229\u6c34\u7535\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 9542,
        "name": "\u6d59\u6c5f\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 9543,
        "name": "\u6d59\u6c5f\u533b\u836f\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 9544,
        "name": "\u6d59\u6c5f\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 9545,
        "name": "\u6d59\u6c5f\u80b2\u82f1\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9546,
        "name": "\u6d59\u6c5f\u7535\u529b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9547,
        "name": "\u5609\u5174\u5357\u6d0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9548,
        "name": "\u6d59\u6c5f\u56fd\u9645\u6d77\u8fd0\u804c\u4e1a\u6280\u672f\u5b66\u6821"
    },
    {
        "id": 9549,
        "name": "\u8862\u5dde\u5b66\u9662"
    },
    {
        "id": 9552,
        "name": "\u6d59\u6c5f\u56fd\u9645\u6d77\u8fd0\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9553,
        "name": "\u6e29\u5dde\u5e02\u5de5\u4eba\u4e1a\u4f59\u5927\u5b66"
    },
    {
        "id": 9554,
        "name": "\u5b81\u6ce2\u5e02\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 9555,
        "name": "\u6d59\u6c5f\u5609\u5174\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 9556,
        "name": "\u6d59\u6c5f\u7ecf\u6d4e\u7ba1\u7406\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 9557,
        "name": "\u6d59\u6c5f\u7701\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 9558,
        "name": "\u91d1\u534e\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 9559,
        "name": "\u5b81\u6ce2\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 9561,
        "name": "\u676d\u5dde\u6210\u4eba\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 9562,
        "name": "\u676d\u5dde\u5e02\u5de5\u4eba\u4e1a\u4f59\u5927\u5b66"
    },
    {
        "id": 9563,
        "name": "\u6d59\u6c5f\u7701\u7701\u7ea7\u673a\u5173\u804c\u5de5\u4e1a\u4f59\u5927\u5b66"
    },
    {
        "id": 9564,
        "name": "\u6d59\u6c5f\u540c\u6d4e\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 9565,
        "name": "\u6d59\u6c5f\u90ae\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9566,
        "name": "\u6d59\u6c5f\u4f53\u80b2\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9567,
        "name": "\u53f0\u5dde\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 9568,
        "name": "\u6e29\u5dde\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 9569,
        "name": "\u6d59\u6c5f\u7406\u5de5\u5927\u5b66\u6210\u6559\u5b66\u9662\u5317\u666f\u56ed\u5206\u9662"
    },
    {
        "id": 9570,
        "name": "\u540c\u6d4e\u5927\u5b66\u6d59\u6c5f\u5b66\u9662"
    },
    {
        "id": 9572,
        "name": "\u6d59\u6c5f\u6a2a\u5e97\u5f71\u89c6\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 9573,
        "name": "\u676d\u5dde\u79d1\u6280\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9577,
        "name": "\u6d59\u6c5f\u6c7d\u8f66\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9578,
        "name": "\u4e2d\u56fd\u7f8e\u672f\u5b66\u9662\u827a\u672f\u8bbe\u8ba1\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9579,
        "name": "\u91d1\u534e\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 9580,
        "name": "\u676d\u5dde\u8001\u548c\u5c71\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 9581,
        "name": "\u676d\u5dde\u5e08\u8303\u5927\u5b66\u94b1\u6c5f\u5b66\u9662"
    },
    {
        "id": 9582,
        "name": "\u6d59\u6c5f\u65b0\u4e16\u7eaa\u7ecf\u8d38\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 9584,
        "name": "\u676d\u5dde\u4eba\u6587\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 9585,
        "name": "\u4e0a\u6d77\u8d22\u7ecf\u5927\u5b66\u6d59\u6c5f\u5b66\u9662"
    },
    {
        "id": 9586,
        "name": "\u6d59\u6c5f\u5e08\u8303\u5927\u5b66\u884c\u77e5\u5b66\u9662"
    },
    {
        "id": 9587,
        "name": "\u6d59\u6c5f\u7406\u5de5\u5927\u5b66\u79d1\u6280\u4e0e\u827a\u672f\u5b66\u9662"
    },
    {
        "id": 9589,
        "name": "\u6d59\u6c5f\u5de5\u4e1a\u5927\u5b66\u4e4b\u6c5f\u5b66\u9662"
    },
    {
        "id": 9591,
        "name": "\u676d\u5dde\u6c5f\u5357\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 9592,
        "name": "\u6c5f\u5357\u4e13\u4fee\u5b66\u9662"
    }],
    "name": "\u6d59\u6c5f"
},
{
    "id": 10,
    "school": [{
        "id": 10001,
        "name": "\u897f\u5b89\u4ea4\u5927"
    },
    {
        "id": 10002,
        "name": "\u957f\u5b89\u5927\u5b66"
    },
    {
        "id": 10003,
        "name": "\u897f\u5317\u5de5\u5927"
    },
    {
        "id": 10004,
        "name": "\u897f\u5317\u5927\u5b66"
    },
    {
        "id": 10005,
        "name": "\u9655\u897f\u5e08\u5927"
    },
    {
        "id": 10006,
        "name": "\u897f\u5b89\u7535\u5b50\u79d1\u5927"
    },
    {
        "id": 10007,
        "name": "\u897f\u5b89\u7406\u5de5"
    },
    {
        "id": 10008,
        "name": "\u897f\u5b89\u79d1\u5927"
    },
    {
        "id": 10009,
        "name": "\u897f\u5b89\u5de5\u5927"
    },
    {
        "id": 10010,
        "name": "\u897f\u5916"
    },
    {
        "id": 10011,
        "name": "\u897f\u90ae"
    },
    {
        "id": 10012,
        "name": "\u897f\u5b89\u533b\u5b66\u9662"
    },
    {
        "id": 10013,
        "name": "\u897f\u5b89\u8d22\u7ecf"
    },
    {
        "id": 10014,
        "name": "\u897f\u5317\u653f\u6cd5"
    },
    {
        "id": 10015,
        "name": "\u897f\u5b89\u4f53\u9662"
    },
    {
        "id": 10016,
        "name": "\u897f\u5b89\u7f8e\u9662"
    },
    {
        "id": 10017,
        "name": "\u897f\u5b89\u97f3\u4e50\u5b66\u9662"
    },
    {
        "id": 10018,
        "name": "\u897f\u5b89\u6587\u7406\u5b66\u9662"
    },
    {
        "id": 10019,
        "name": "\u897f\u4eac\u5b66\u9662"
    },
    {
        "id": 10020,
        "name": "\u897f\u5b89\u7ffb\u8bd1\u5b66\u9662"
    },
    {
        "id": 10021,
        "name": "\u57f9\u534e\u5b66\u9662"
    },
    {
        "id": 10022,
        "name": "\u6b27\u4e9a\u5b66\u9662"
    },
    {
        "id": 10023,
        "name": "\u897f\u5b89\u5916\u4e8b"
    },
    {
        "id": 10024,
        "name": "\u9655\u897f\u5de5\u5546\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 10025,
        "name": "\u897f\u5b89\u77f3\u6cb9"
    },
    {
        "id": 10026,
        "name": "\u897f\u5b89\u5efa\u7b51\u79d1\u5927"
    },
    {
        "id": 10027,
        "name": "\u7b2c\u56db\u519b\u533b\u5927\u5b66"
    },
    {
        "id": 10028,
        "name": "\u897f\u5b89\u7535\u5b50\u79d1\u6280\u5927\u5b66\u9ad8\u7b49\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10029,
        "name": "\u897f\u5317\u5de5\u4e1a\u5927\u5b66\u660e\u5fb7\u5b66\u9662"
    },
    {
        "id": 10030,
        "name": "\u9655\u897f\u5e08\u8303\u5927\u5b66\u9ad8\u7b49\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10031,
        "name": "\u957f\u5b89\u5927\u5b66\u5174\u534e\u5b66\u9662"
    },
    {
        "id": 10032,
        "name": "\u897f\u5b89\u6570\u5b57\u6280\u672f\u5b66\u9662 "
    },
    {
        "id": 10033,
        "name": "\u9655\u897f\u5de5\u5546\u804c\u4e1a\u5b66\u9662\u00a0"
    },
    {
        "id": 10051,
        "name": "\u5ef6\u5b89\u5927\u5b66"
    },
    {
        "id": 10101,
        "name": "\u9655\u897f\u4e2d\u533b\u5b66\u9662"
    },
    {
        "id": 10102,
        "name": "\u54b8\u9633\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 10103,
        "name": "\u9655\u897f\u79d1\u5927"
    },
    {
        "id": 10151,
        "name": "\u5b9d\u9e21\u6587\u7406\u5b66\u9662"
    },
    {
        "id": 10201,
        "name": "\u6e2d\u5357\u5e08\u8303"
    },
    {
        "id": 10251,
        "name": "\u9655\u897f\u7406\u5de5"
    },
    {
        "id": 10301,
        "name": "\u6986\u6797\u5b66\u9662"
    },
    {
        "id": 10351,
        "name": "\u5546\u6d1b\u5b66\u9662"
    },
    {
        "id": 10401,
        "name": "\u5b89\u5eb7\u5b66\u9662"
    },
    {
        "id": 10451,
        "name": "\u897f\u5317\u519c\u6797\u79d1\u5927"
    },
    {
        "id": 10501,
        "name": "\u5b89\u5eb7\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10502,
        "name": "\u5b9d\u9e21\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10503,
        "name": "\u6c49\u4e2d\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10504,
        "name": "\u9655\u897f\u8d22\u7ecf\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10505,
        "name": "\u9655\u897f\u7535\u5b50\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 10506,
        "name": "\u9655\u897f\u7535\u5b50\u4fe1\u606f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10507,
        "name": "\u9655\u897f\u7eba\u7ec7\u670d\u88c5\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10508,
        "name": "\u9655\u897f\u670d\u88c5\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 10509,
        "name": "\u9655\u897f\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10510,
        "name": "\u9655\u897f\u56fd\u9632\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10511,
        "name": "\u9655\u897f\u56fd\u9645\u5546\u8d38\u5b66\u9662"
    },
    {
        "id": 10512,
        "name": "\u9655\u897f\u822a\u7a7a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10513,
        "name": "\u9655\u897f\u4ea4\u901a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10514,
        "name": "\u9655\u897f\u7ecf\u6d4e\u7ba1\u7406\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10515,
        "name": "\u9655\u897f\u8b66\u5b98\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 10516,
        "name": "\u9655\u897f\u65c5\u6e38\u70f9\u996a\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 10517,
        "name": "\u9655\u897f\u80fd\u6e90\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10518,
        "name": "\u9655\u897f\u9752\u5e74\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 10519,
        "name": "\u9655\u897f\u94c1\u8def\u5de5\u7a0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10520,
        "name": "\u9655\u897f\u90ae\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10521,
        "name": "\u9655\u897f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10522,
        "name": "\u5546\u6d1b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10523,
        "name": "\u94dc\u5ddd\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10524,
        "name": "\u6e2d\u5357\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10525,
        "name": "\u897f\u5b89\u7535\u529b\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 10526,
        "name": "\u897f\u5b89\u4e1c\u65b9\u4e9a\u592a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10527,
        "name": "\u897f\u5b89\u9ad8\u65b0\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 10529,
        "name": "\u897f\u5b89\u6d77\u68e0\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 10530,
        "name": "\u897f\u5b89\u822a\u7a7a\u6280\u672f\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 10531,
        "name": "\u897f\u5b89\u822a\u7a7a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10532,
        "name": "\u897f\u5b89\u79d1\u6280\u5546\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 10533,
        "name": "\u897f\u5b89\u6c7d\u8f66\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 10534,
        "name": "\u897f\u5b89\u4e09\u8d44\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 10535,
        "name": "\u897f\u5b89\u601d\u6e90\u5b66\u9662"
    },
    {
        "id": 10536,
        "name": "\u897f\u5b89\u94c1\u8def\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10537,
        "name": "\u897f\u5b89\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10538,
        "name": "\u54b8\u9633\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10540,
        "name": "\u5ef6\u5b89\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10541,
        "name": "\u6768\u51cc\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10542,
        "name": "\u9655\u897f\u94f6\u884c\u5b66\u6821"
    },
    {
        "id": 10543,
        "name": "\u897f\u5b89\u673a\u7535\u4fe1\u606f\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10544,
        "name": "\u9655\u897f\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 10546,
        "name": "\u9655\u897f\u7701\u65c5\u6e38\u5b66\u6821"
    },
    {
        "id": 10547,
        "name": "\u897f\u5b89\u94c1\u8def\u5de5\u7a0b\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 10549,
        "name": "\u897f\u5b89\u534e\u897f\u4e13\u4fee\u5927\u5b66"
    },
    {
        "id": 10550,
        "name": "\u897f\u5b89\u822a\u7a7a\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 10551,
        "name": "\u897f\u5b89\u5efa\u7b51\u79d1\u6280\u5927\u5b66\u534e\u6e05\u5b66\u9662"
    },
    {
        "id": 10552,
        "name": "\u897f\u5b89\u8d22\u7ecf\u5b66\u9662\u884c\u77e5\u5b66\u9662"
    },
    {
        "id": 10553,
        "name": "\u9655\u897f\u79d1\u6280\u5927\u5b66\u9550\u4eac\u5b66\u9662"
    },
    {
        "id": 10554,
        "name": "\u897f\u5b89\u5de5\u4e1a\u5927\u5b66\u5317\u65b9\u4fe1\u606f\u5de5\u7a0b\u5b66\u9662"
    },
    {
        "id": 10555,
        "name": "\u5ef6\u5b89\u5927\u5b66\u897f\u5b89\u521b\u65b0\u5b66\u9662"
    },
    {
        "id": 10556,
        "name": "\u897f\u5b89\u7535\u5b50\u79d1\u6280\u5927\u5b66\u957f\u5b89\u5b66\u9662"
    },
    {
        "id": 10559,
        "name": "\u897f\u5b89\u7406\u5de5\u5927\u5b66\u9ad8\u79d1\u5b66\u9662"
    },
    {
        "id": 10560,
        "name": "\u897f\u5b89\u79d1\u6280\u5927\u5b66\u9ad8\u65b0\u5b66\u9662"
    },
    {
        "id": 10563,
        "name": "\u897f\u5b89\u4ea4\u901a\u5927\u5b66\u57ce\u5e02\u5b66\u9662"
    },
    {
        "id": 10564,
        "name": "\u897f\u5317\u5927\u5b66\u73b0\u4ee3\u5b66\u9662"
    },
    {
        "id": 10565,
        "name": "\u897f\u5b89\u5de5\u7a0b\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10566,
        "name": "\u9655\u897f\u822a\u5929\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 10568,
        "name": "\u9655\u897f\u5de5\u8fd0\u5b66\u9662"
    },
    {
        "id": 10569,
        "name": "\u9655\u897f\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 10570,
        "name": "\u9655\u897f\u7701\u5efa\u7b51\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 10571,
        "name": "\u9655\u897f\u901a\u4fe1\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10575,
        "name": "\u897f\u5b89\u822a\u7a7a\u65c5\u6e38\u5b66\u9662"
    },
    {
        "id": 10579,
        "name": "\u897f\u5b89\u5de5\u7a0b\u673a\u68b0\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 10581,
        "name": "\u9655\u897f\u5efa\u8bbe\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 10582,
        "name": "\u897f\u5b89\u51b6\u91d1\u5efa\u7b51\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 10583,
        "name": "\u00a0\u897f\u5b89\u8054\u5408\u804c\u4e1a\u57f9\u8bad\u5b66\u9662"
    },
    {
        "id": 10584,
        "name": "\u897f\u5b89\u5916\u8d38\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 10585,
        "name": "\u897f\u5b89\u91d1\u878d\u8d22\u8d38\u5b66\u9662"
    },
    {
        "id": 10586,
        "name": "\u897f\u5b89\u6280\u5e08\u5b66\u9662"
    }],
    "name": "\u9655\u897f"
},
{
    "id": 11,
    "school": [{
        "id": 11001,
        "name": "\u6b66\u6c49\u5927\u5b66"
    },
    {
        "id": 11002,
        "name": "\u534e\u4e2d\u79d1\u5927"
    },
    {
        "id": 11003,
        "name": "\u534e\u4e2d\u519c\u5927"
    },
    {
        "id": 11004,
        "name": "\u6b66\u6c49\u7406\u5de5"
    },
    {
        "id": 11005,
        "name": "\u4e2d\u56fd\u5730\u8d28\u5927\u5b66\uff08\u6b66\u6c49\uff09"
    },
    {
        "id": 11006,
        "name": "\u4e2d\u5357\u8d22\u7ecf\u653f\u6cd5\u5927\u5b66"
    },
    {
        "id": 11007,
        "name": "\u4e2d\u5357\u6c11\u65cf\u5927\u5b66"
    },
    {
        "id": 11008,
        "name": "\u534e\u4e2d\u5e08\u5927"
    },
    {
        "id": 11009,
        "name": "\u6b66\u6c49\u5de5\u4e1a\u5b66\u9662"
    },
    {
        "id": 11010,
        "name": "\u6b66\u6c49\u7eba\u7ec7\u5927\u5b66"
    },
    {
        "id": 11011,
        "name": "\u6e56\u5317\u4e2d\u533b\u836f\u5927\u5b66"
    },
    {
        "id": 11012,
        "name": "\u6e56\u5317\u7ecf\u6d4e\u5b66\u9662"
    },
    {
        "id": 11013,
        "name": "\u6e56\u5317\u8b66\u5b98\u5b66\u9662"
    },
    {
        "id": 11014,
        "name": "\u6b66\u6c49\u4f53\u9662"
    },
    {
        "id": 11015,
        "name": "\u6e56\u5317\u7f8e\u9662"
    },
    {
        "id": 11016,
        "name": "\u6b66\u6c49\u97f3\u4e50\u5b66\u9662"
    },
    {
        "id": 11017,
        "name": "\u6b66\u6c49\u751f\u7269\u5de5\u7a0b\u5b66\u9662"
    },
    {
        "id": 11018,
        "name": "\u6e56\u5317\u5de5\u4e1a\u5927\u5b66"
    },
    {
        "id": 11019,
        "name": "\u6e56\u5317\u5927\u5b66"
    },
    {
        "id": 11020,
        "name": "\u6c5f\u6c49\u5927\u5b66"
    },
    {
        "id": 11021,
        "name": "\u6b66\u6c49\u5de5\u5927"
    },
    {
        "id": 11022,
        "name": "\u6b66\u6c49\u79d1\u5927"
    },
    {
        "id": 11023,
        "name": "\u6e56\u5317\u7f8e\u672f\u5b66\u9662\u7ee7\u7eed\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 11024,
        "name": "\u534e\u4e2d\u519c\u4e1a\u5927\u5b66\u695a\u5929\u5b66\u9662"
    },
    {
        "id": 11025,
        "name": "\u6e56\u5317\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 11026,
        "name": "\u4e09\u5ce1\u65c5\u6e38\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11051,
        "name": "\u957f\u6c5f\u5927\u5b66"
    },
    {
        "id": 11101,
        "name": "\u4e09\u5ce1\u5927\u5b66"
    },
    {
        "id": 11151,
        "name": "\u6e56\u5317\u6c7d\u9662"
    },
    {
        "id": 11152,
        "name": "\u6e56\u5317\u533b\u836f\u5b66\u9662"
    },
    {
        "id": 11201,
        "name": "\u6e56\u5317\u5de5\u7a0b\u5b66\u9662"
    },
    {
        "id": 11251,
        "name": "\u6e56\u5317\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 11252,
        "name": "\u6e56\u5317\u7406\u5de5\u5b66\u9662"
    },
    {
        "id": 11301,
        "name": "\u9ec4\u5188\u5e08\u9662"
    },
    {
        "id": 11351,
        "name": "\u6e56\u5317\u6c11\u65cf\u5b66\u9662"
    },
    {
        "id": 11401,
        "name": "\u6e56\u5317\u6587\u7406\u5b66\u9662"
    },
    {
        "id": 11451,
        "name": "\u6e56\u5317\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 11501,
        "name": "\u957f\u6c5f\u5de5\u7a0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11502,
        "name": "\u957f\u6c5f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 11504,
        "name": "\u9102\u4e1c\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11505,
        "name": "\u9102\u5dde\u5927\u5b66"
    },
    {
        "id": 11507,
        "name": "\u6069\u65bd\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11508,
        "name": "\u6e56\u5317\u8d22\u7ecf\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 11509,
        "name": "\u6e56\u5317\u8d22\u7a0e\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 11510,
        "name": "\u6e56\u5317\u57ce\u5e02\u5efa\u8bbe\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11511,
        "name": "\u6e56\u5317\u5de5\u4e1a\u5927\u5b66\u5546\u8d38\u5b66\u9662"
    },
    {
        "id": 11512,
        "name": "\u6e56\u5317\u56fd\u571f\u8d44\u6e90\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 11513,
        "name": "\u6e56\u5317\u9ec4\u77f3\u673a\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11514,
        "name": "\u6e56\u5317\u4ea4\u901a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11515,
        "name": "\u6e56\u5317\u7b2c\u4e8c\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 11516,
        "name": "\u6e56\u5317\u7ecf\u6d4e\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 11517,
        "name": "\u6e56\u5317\u5f00\u653e\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 11518,
        "name": "\u6e56\u5317\u6c11\u65cf\u5b66\u9662\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 11519,
        "name": "\u6e56\u5317\u8f7b\u5de5\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11520,
        "name": "\u6e56\u5317\u4e09\u5ce1\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11521,
        "name": "\u6e56\u5317\u751f\u6001\u5de5\u7a0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11522,
        "name": "\u6e56\u5317\u751f\u7269\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 11523,
        "name": "\u6e56\u5317\u7701\u6c34\u5229\u6c34\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11524,
        "name": "\u6e56\u5317\u5e08\u8303\u5b66\u9662\u6587\u7406\u5b66\u9662"
    },
    {
        "id": 11525,
        "name": "\u6e56\u5317\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 11526,
        "name": "\u6e56\u5317\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11527,
        "name": "\u6e56\u5317\u4e2d\u533b\u836f\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 11528,
        "name": "\u9ec4\u5188\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 11529,
        "name": "\u9ec4\u5188\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11530,
        "name": "\u6c5f\u6c49\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 11531,
        "name": "\u8346\u695a\u7406\u5de5\u5b66\u9662"
    },
    {
        "id": 11532,
        "name": "\u8346\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11533,
        "name": "\u8346\u5dde\u7406\u5de5\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 11534,
        "name": "\u6c99\u6d0b\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 11535,
        "name": "\u5341\u5830\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11536,
        "name": "\u968f\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11537,
        "name": "\u6b66\u6c49\u8239\u8236\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11538,
        "name": "\u6b66\u6c49\u7535\u529b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11539,
        "name": "\u6b66\u6c49\u5de5\u7a0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11541,
        "name": "\u6b66\u6c49\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11542,
        "name": "\u6b66\u6c49\u822a\u6d77\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11543,
        "name": "\u6b66\u6c49\u4ea4\u901a\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 11544,
        "name": "\u6b66\u6c49\u8b66\u5b98\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 11545,
        "name": "\u6b66\u6c49\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 11546,
        "name": "\u6b66\u6c49\u7406\u5de5\u5927\u5b66\u534e\u590f\u5b66\u9662"
    },
    {
        "id": 11547,
        "name": "\u6b66\u6c49\u6c11\u653f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 11548,
        "name": "\u6b66\u6c49\u8f6f\u4ef6\u5de5\u7a0b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 11549,
        "name": "\u6b66\u6c49\u5546\u8d38\u5b66\u9662"
    },
    {
        "id": 11550,
        "name": "\u6b66\u6c49\u5546\u4e1a\u670d\u52a1\u5b66\u9662"
    },
    {
        "id": 11551,
        "name": "\u6b66\u6c49\u65f6\u4ee3\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 11552,
        "name": "\u6b66\u6c49\u94c1\u8def\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11553,
        "name": "\u6b66\u6c49\u5916\u8bed\u5916\u4e8b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 11554,
        "name": "\u6b66\u6c49\u4fe1\u606f\u4f20\u64ad\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11556,
        "name": "\u6b66\u6c49\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11557,
        "name": "\u4ed9\u6843\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 11558,
        "name": "\u54b8\u5b81\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11559,
        "name": "\u8944\u6a0a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11560,
        "name": "\u6e56\u5317\u5de5\u7a0b\u5b66\u9662\u65b0\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11561,
        "name": "\u90e7\u9633\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 11562,
        "name": "\u6b66\u6c49\u957f\u6c5f\u5de5\u5546\u5b66\u9662"
    },
    {
        "id": 11563,
        "name": "\u9ec4\u5188\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 11564,
        "name": "\u534e\u4e2d\u79d1\u6280\u5927\u5b66\u6587\u534e\u5b66\u9662"
    },
    {
        "id": 11566,
        "name": "\u6c49\u53e3\u5b66\u9662"
    },
    {
        "id": 11567,
        "name": "\u6e56\u5317\u5927\u5b66\u77e5\u884c\u5b66\u9662"
    },
    {
        "id": 11568,
        "name": "\u4e09\u5ce1\u5927\u5b66\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 11570,
        "name": "\u6e56\u5317\u5de5\u4e1a\u5927\u5b66\u5de5\u7a0b\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11571,
        "name": "\u6b66\u6c49\u5de5\u4e1a\u5b66\u9662\u5de5\u5546\u5b66\u9662"
    },
    {
        "id": 11572,
        "name": "\u6b66\u6c49\u5de5\u7a0b\u5927\u5b66\u90ae\u7535\u4e0e\u4fe1\u606f\u5de5\u7a0b\u5b66\u9662\u3000"
    },
    {
        "id": 11573,
        "name": "\u6b66\u6c49\u79d1\u6280\u5b66\u9662\u5916\u7ecf\u8d38\u5b66\u9662"
    },
    {
        "id": 11574,
        "name": "\u6c5f\u6c49\u5927\u5b66\u6587\u7406\u5b66\u9662"
    },
    {
        "id": 11575,
        "name": "\u6e56\u5317\u6c7d\u8f66\u5de5\u4e1a\u5b66\u9662\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 11576,
        "name": "\u6e56\u5317\u7ecf\u6d4e\u5b66\u9662\u6cd5\u5546\u5b66\u9662\u3000"
    },
    {
        "id": 11577,
        "name": "\u6b66\u6c49\u4f53\u80b2\u5b66\u9662\u4f53\u80b2\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 11579,
        "name": "\u6e56\u5317\u533b\u836f\u5b66\u9662\u836f\u62a4\u5b66\u9662"
    },
    {
        "id": 11580,
        "name": "\u6e56\u5317\u6587\u7406\u5b66\u9662\u7406\u5de5\u5b66\u9662"
    },
    {
        "id": 11581,
        "name": "\u4e2d\u56fd\u5730\u8d28\u5927\u5b66\u6c5f\u57ce\u5b66\u9662"
    },
    {
        "id": 11582,
        "name": "\u957f\u6c5f\u5927\u5b66\u6587\u7406\u5b66\u9662"
    },
    {
        "id": 11583,
        "name": "\u957f\u6c5f\u5927\u5b66\u5de5\u7a0b\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11584,
        "name": "\u534e\u4e2d\u5e08\u8303\u5927\u5b66\u6b66\u6c49\u4f20\u5a92\u5b66\u9662"
    },
    {
        "id": 11585,
        "name": "\u6b66\u6c49\u4e1c\u6e56\u5b66\u9662"
    },
    {
        "id": 11587,
        "name": "\u4e2d\u5357\u8d22\u7ecf\u653f\u6cd5\u6b66\u6c49\u5b66\u9662"
    },
    {
        "id": 11588,
        "name": "\u534e\u4e2d\u79d1\u6280\u5927\u5b66\u6b66\u660c\u5206\u6821\u3000"
    },
    {
        "id": 11589,
        "name": "\u6b66\u6c49\u5de5\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 11590,
        "name": "\u4e09\u5ce1\u7535\u529b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 11591,
        "name": "\u6e56\u5317\u9752\u5e74\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 11595,
        "name": "\u6b66\u6c49\u8bed\u8a00\u6587\u5316\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 11596,
        "name": "\u9ec4\u77f3\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11597,
        "name": "\u6d77\u519b\u5de5\u7a0b\u5927\u5b66"
    },
    {
        "id": 11598,
        "name": "\u6e56\u5317\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 11599,
        "name": "\u6b66\u6c49\u79d1\u6280\u5927\u5b66\u57ce\u5e02\u5b66\u9662"
    },
    {
        "id": 11600,
        "name": "\u5b9c\u660c\u5e02\u5546\u4e1a\u5b66\u6821"
    },
    {
        "id": 11603,
        "name": "\u6e56\u5317\u6fb3\u65b0\u6559\u80b2\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 11604,
        "name": "\u6b66\u6c49\u57ce\u5e02\u5efa\u8bbe\u5b66\u9662"
    },
    {
        "id": 11605,
        "name": "\u6e56\u5317\u5b5d\u611f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 11606,
        "name": "\u845b\u6d32\u575d\u6c34\u7535\u5de5\u7a0b\u5b66\u9662"
    },
    {
        "id": 11608,
        "name": "\u6b66\u6c49\u51b6\u91d1\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 11609,
        "name": "\u6b66\u6c49\u57ce\u5e02\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 11610,
        "name": "\u6b66\u6c49\u5927\u5b66\u73de\u73c8\u5b66\u9662"
    }],
    "name": "\u6e56\u5317"
},
{
    "id": 12,
    "school": [{
        "id": 12001,
        "name": "\u534e\u5357\u7406\u5de5\u5927\u5b66"
    },
    {
        "id": 12002,
        "name": "\u4e2d\u5c71\u5927\u5b66"
    },
    {
        "id": 12003,
        "name": "\u66a8\u5357\u5927\u5b66"
    },
    {
        "id": 12004,
        "name": "\u534e\u5357\u5e08\u8303\u5927\u5b66"
    },
    {
        "id": 12005,
        "name": "\u5e7f\u4e1c\u5de5\u4e1a\u5927\u5b66"
    },
    {
        "id": 12006,
        "name": "\u534e\u5357\u519c\u4e1a\u5927\u5b66"
    },
    {
        "id": 12007,
        "name": "\u5e7f\u5dde\u5927\u5b66"
    },
    {
        "id": 12008,
        "name": "\u5e7f\u4e1c\u5916\u8bed\u5916\u8d38\u5927\u5b66"
    },
    {
        "id": 12009,
        "name": "\u5e7f\u5dde\u4e2d\u533b\u836f\u5927\u5b66"
    },
    {
        "id": 12010,
        "name": "\u5357\u65b9\u533b\u79d1\u5927\u5b66"
    },
    {
        "id": 12011,
        "name": "\u5357\u65b9\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 12012,
        "name": "\u4ef2\u607a\u519c\u4e1a\u5de5\u7a0b\u5b66\u9662"
    },
    {
        "id": 12013,
        "name": "\u5e7f\u5dde\u533b\u5b66\u9662"
    },
    {
        "id": 12014,
        "name": "\u5e7f\u4e1c\u836f\u5b66\u9662"
    },
    {
        "id": 12015,
        "name": "\u5e7f\u4e1c\u91d1\u878d\u5b66\u9662"
    },
    {
        "id": 12016,
        "name": "\u5e7f\u4e1c\u5546\u5b66\u9662"
    },
    {
        "id": 12017,
        "name": "\u5e7f\u4e1c\u8b66\u5b98\u5b66\u9662"
    },
    {
        "id": 12018,
        "name": "\u5e7f\u5dde\u4f53\u80b2\u5b66\u9662"
    },
    {
        "id": 12019,
        "name": "\u5e7f\u5dde\u7f8e\u672f\u5b66\u9662"
    },
    {
        "id": 12020,
        "name": "\u661f\u6d77\u97f3\u4e50\u5b66\u9662"
    },
    {
        "id": 12021,
        "name": "\u5e7f\u4e1c\u6280\u672f\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 12022,
        "name": "\u5e7f\u4e1c\u57f9\u6b63\u5b66\u9662"
    },
    {
        "id": 12023,
        "name": "\u5e7f\u4e1c\u767d\u4e91\u5b66\u9662"
    },
    {
        "id": 12024,
        "name": "\u6e05\u534e\u5927\u5b66\u6df1\u5733\u7814\u7a76\u751f\u9662"
    },
    {
        "id": 12051,
        "name": "\u6df1\u5733\u5927\u5b66"
    },
    {
        "id": 12101,
        "name": "\u6c55\u5934\u5927\u5b66"
    },
    {
        "id": 12151,
        "name": "\u4e94\u9091\u5927\u5b66"
    },
    {
        "id": 12201,
        "name": "\u8087\u5e86\u5b66\u9662"
    },
    {
        "id": 12251,
        "name": "\u5e7f\u4e1c\u77f3\u6cb9\u5316\u5de5\u5b66\u9662"
    },
    {
        "id": 12301,
        "name": "\u4e1c\u839e\u7406\u5de5"
    },
    {
        "id": 12351,
        "name": "\u5e7f\u4e1c\u533b\u5b66\u9662"
    },
    {
        "id": 12352,
        "name": "\u6e5b\u6c5f\u5e08\u8303"
    },
    {
        "id": 12353,
        "name": "\u5e7f\u4e1c\u6d77\u6d0b"
    },
    {
        "id": 12401,
        "name": "\u97f6\u5173\u5b66\u9662"
    },
    {
        "id": 12451,
        "name": "\u97e9\u5c71\u5e08\u8303"
    },
    {
        "id": 12501,
        "name": "\u5609\u5e94\u5b66\u9662"
    },
    {
        "id": 12551,
        "name": "\u60e0\u5dde\u5b66\u9662"
    },
    {
        "id": 12601,
        "name": "\u4f5b\u5c71\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 12602,
        "name": "\u4e2d\u5c71\u5927\u5b66\u5357\u65b9\u5b66\u9662"
    },
    {
        "id": 12603,
        "name": "\u5e7f\u4e1c\u5916\u8bed\u5916\u8d38\u5927\u5b66\u5357\u56fd\u5546\u5b66\u9662"
    },
    {
        "id": 12604,
        "name": "\u534e\u5357\u7406\u5de5\u5927\u5b66\u5e7f\u5dde\u6c7d\u8f66\u5b66\u9662"
    },
    {
        "id": 12701,
        "name": "\u5317\u4eac\u7406\u5de5\u5927\u5b66\u73e0\u6d77\u5b66\u9662"
    },
    {
        "id": 12702,
        "name": "\u5317\u4eac\u5e08\u8303\u5927\u5b66\u73e0\u6d77\u5206\u6821"
    },
    {
        "id": 12703,
        "name": "\u7535\u5b50\u79d1\u6280\u5927\u5b66\u4e2d\u5c71\u5b66\u9662"
    },
    {
        "id": 12704,
        "name": "\u4e1c\u839e\u7406\u5de5\u5b66\u9662\u57ce\u5e02\u5b66\u9662"
    },
    {
        "id": 12705,
        "name": "\u5e7f\u4e1c\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 12706,
        "name": "\u756a\u79ba\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12707,
        "name": "\u4f5b\u5c71\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12708,
        "name": "\u5e7f\u4e1c\u8d22\u7ecf\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 12709,
        "name": "\u5e7f\u4e1c\u6f6e\u6c55\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12710,
        "name": "\u5e7f\u4e1c\u7eba\u7ec7\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12711,
        "name": "\u5e7f\u4e1c\u5de5\u7a0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12712,
        "name": "\u5e7f\u4e1c\u5de5\u8d38\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12713,
        "name": "\u5e7f\u4e1c\u5de5\u4e1a\u5927\u5b66\u534e\u7acb\u5b66\u9662"
    },
    {
        "id": 12714,
        "name": "\u5e7f\u4e1c\u6d77\u6d0b\u5927\u5b66\u5bf8\u91d1\u5b66\u9662"
    },
    {
        "id": 12715,
        "name": "\u5e7f\u4e1c\u6d77\u6d0b\u5927\u5b66\u6d77\u6ee8\u5b66\u9662"
    },
    {
        "id": 12717,
        "name": "\u5e7f\u4e1c\u673a\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12719,
        "name": "\u5e7f\u4e1c\u5efa\u534e\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 12720,
        "name": "\u5e7f\u4e1c\u5efa\u8bbe\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12721,
        "name": "\u5e7f\u4e1c\u4ea4\u901a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12722,
        "name": "\u5e7f\u4e1c\u7b2c\u4e8c\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 12723,
        "name": "\u5e7f\u4e1c\u79d1\u5b66\u6280\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 12724,
        "name": "\u5e7f\u4e1c\u7406\u5de5\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 12725,
        "name": "\u5e7f\u4e1c\u5cad\u5357\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12726,
        "name": "\u5e7f\u4e1c\u519c\u5de5\u5546\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12727,
        "name": "\u5e7f\u4e1c\u5973\u5b50\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12728,
        "name": "\u5e7f\u4e1c\u8f7b\u5de5\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12729,
        "name": "\u5e7f\u4e1c\u7701\u65b0\u95fb\u51fa\u7248\u6280\u5e08\u5b66\u9662"
    },
    {
        "id": 12730,
        "name": "\u5e7f\u4e1c\u6c34\u5229\u7535\u529b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12731,
        "name": "\u5e7f\u4e1c\u53f8\u6cd5\u8b66\u5b98\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 12732,
        "name": "\u5e7f\u4e1c\u677e\u5c71\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12733,
        "name": "\u5e7f\u4e1c\u4f53\u80b2\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12734,
        "name": "\u5e7f\u4e1c\u5916\u8bed\u5916\u8d38\u5927\u5b66\u516c\u5f00\u5b66\u9662"
    },
    {
        "id": 12735,
        "name": "\u5e7f\u4e1c\u5916\u8bed\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 12736,
        "name": "\u5e7f\u4e1c\u6587\u827a\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 12737,
        "name": "\u5e7f\u4e1c\u65b0\u5b89\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12738,
        "name": "\u5e7f\u4e1c\u884c\u653f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 12739,
        "name": "\u5e7f\u4e1c\u4e9a\u89c6\u6f14\u827a\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 12740,
        "name": "\u5e7f\u4e1c\u90ae\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12741,
        "name": "\u5e7f\u5dde\u57ce\u5e02\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 12743,
        "name": "\u5e7f\u5dde\u5927\u5b66\u534e\u8f6f\u8f6f\u4ef6\u5b66\u9662"
    },
    {
        "id": 12744,
        "name": "\u5e7f\u5dde\u5927\u5b66\u5e02\u653f\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12745,
        "name": "\u5e7f\u5dde\u5927\u5b66\u677e\u7530\u5b66\u9662"
    },
    {
        "id": 12746,
        "name": "\u5e7f\u5dde\u5de5\u7a0b\u6280\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 12747,
        "name": "\u5e7f\u5dde\u5de5\u5546\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12748,
        "name": "\u5e7f\u5dde\u822a\u6d77\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 12749,
        "name": "\u5e7f\u5dde\u534e\u7acb\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 12750,
        "name": "\u5e7f\u5dde\u534e\u5357\u5546\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 12751,
        "name": "\u5e7f\u5dde\u5eb7\u5927\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12752,
        "name": "\u5e7f\u5dde\u79d1\u6280\u8d38\u6613\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 12753,
        "name": "\u5e7f\u5dde\u79d1\u6280\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12754,
        "name": "\u5e7f\u5dde\u6c11\u822a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12755,
        "name": "\u5e7f\u5dde\u5357\u6d0b\u7406\u5de5\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 12756,
        "name": "\u5e7f\u5dde\u6d89\u5916\u7ecf\u6d4e\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12757,
        "name": "\u5e7f\u5dde\u4f53\u80b2\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12758,
        "name": "\u5e7f\u5dde\u94c1\u8def\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12759,
        "name": "\u5e7f\u5dde\u73b0\u4ee3\u4fe1\u606f\u5de5\u7a0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12760,
        "name": "\u6cb3\u6e90\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12761,
        "name": "\u534e\u6fb3\u56fd\u9645\u4f1a\u8ba1\u5b66\u9662"
    },
    {
        "id": 12762,
        "name": "\u534e\u5357\u519c\u4e1a\u5927\u5b66\u73e0\u6c5f\u5b66\u9662"
    },
    {
        "id": 12763,
        "name": "\u534e\u5357\u5e08\u8303\u5927\u5b66\u589e\u57ce\u5b66\u9662"
    },
    {
        "id": 12764,
        "name": "\u60e0\u5dde\u7ecf\u6d4e\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12765,
        "name": "\u5409\u6797\u5927\u5b66\u73e0\u6d77\u5b66\u9662"
    },
    {
        "id": 12766,
        "name": "\u6c5f\u95e8\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12767,
        "name": "\u63ed\u9633\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12768,
        "name": "\u7f57\u5b9a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12769,
        "name": "\u8302\u540d\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12770,
        "name": "\u5357\u534e\u5de5\u5546\u5b66\u9662"
    },
    {
        "id": 12771,
        "name": "\u5357\u6d77\u4e1c\u8f6f\u4fe1\u606f\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12772,
        "name": "\u6e05\u8fdc\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12773,
        "name": "\u6c55\u5934\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12774,
        "name": "\u6c55\u5c3e\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12775,
        "name": "\u6df1\u5733\u4fe1\u606f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12776,
        "name": "\u6df1\u5733\u632f\u897f\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 12777,
        "name": "\u6df1\u5733\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12778,
        "name": "\u987a\u5fb7\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12779,
        "name": "\u79c1\u7acb\u534e\u8054\u5b66\u9662"
    },
    {
        "id": 12780,
        "name": "\u9633\u6c5f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12781,
        "name": "\u6e5b\u6c5f\u6280\u5e08\u5b66\u9662"
    },
    {
        "id": 12783,
        "name": "\u6e5b\u5e08\u57fa\u7840\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 12784,
        "name": "\u8087\u5e86\u5de5\u5546\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12785,
        "name": "\u8087\u5e86\u79d1\u6280\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12786,
        "name": "\u8087\u5e86\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 12787,
        "name": "\u4e2d\u5c71\u706b\u70ac\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12788,
        "name": "\u73e0\u6d77\u57ce\u5e02\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12789,
        "name": "\u73e0\u6d77\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 12790,
        "name": "\u9075\u4e49\u533b\u5b66\u9662\u73e0\u6d77\u6821\u533a"
    },
    {
        "id": 12791,
        "name": "\u5e7f\u4e1c\u6280\u672f\u5e08\u8303\u5b66\u9662\u5929\u6cb3\u5206\u6821"
    },
    {
        "id": 12792,
        "name": "\u5e7f\u4e1c\u79d1\u5b66\u6280\u672f\u804c\u4e1a\u5b66\u9662\u56fd\u9632\u5de5\u5927"
    },
    {
        "id": 12793,
        "name": "\u8302\u540d\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 12794,
        "name": "\u5e7f\u4e1c\u77f3\u6cb9\u5316\u5de5\u804c\u4e1a\u6280\u672f\u5b66\u6821"
    },
    {
        "id": 12795,
        "name": "\u4e2d\u5c71\u5927\u5b66\u65b0\u534e\u5b66\u9662"
    },
    {
        "id": 12796,
        "name": "\u5e7f\u4e1c\u5546\u5b66\u9662\u534e\u5546\u5b66\u9662"
    },
    {
        "id": 12797,
        "name": "\u5357\u5f00\u5927\u5b66\u6df1\u5733\u91d1\u878d\u5de5\u7a0b\u5b66\u9662"
    },
    {
        "id": 12798,
        "name": "\u5317\u4eac\u5e08\u8303\u5927\u5b66\uff0d\u9999\u6e2f\u6d78\u4f1a\u5927\u5b66\u8054\u5408\u56fd\u9645\u5b66\u9662"
    },
    {
        "id": 12799,
        "name": "\u5e7f\u5dde\u91d1\u6865\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 12800,
        "name": "\u5e7f\u5dde\u5927\u5b66\u7eba\u7ec7\u670d\u88c5\u5b66\u9662"
    },
    {
        "id": 12801,
        "name": "\u534e\u5357\u5e08\u8303\u5927\u5b66\u5357\u6d77\u6821\u533a"
    },
    {
        "id": 12802,
        "name": "\u66a8\u5357\u5927\u5b66\u6df1\u5733\u65c5\u6e38\u5b66\u9662"
    },
    {
        "id": 12803,
        "name": "\u66a8\u5357\u5927\u5b66\u73e0\u6d77\u5b66\u9662"
    },
    {
        "id": 12804,
        "name": "\u6f6e\u6c55\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12805,
        "name": "\u5e7f\u4e1c\u79d1\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 12806,
        "name": "\u4e2d\u5c71\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12807,
        "name": "\u5e7f\u4e1c\u7701\u5916\u8bed\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 12809,
        "name": "\u5e7f\u4e1c\u98df\u54c1\u836f\u54c1\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 12810,
        "name": "\u5e7f\u5dde\u57ce\u5efa\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 12811,
        "name": "\u6e5b\u6c5f\u73b0\u4ee3\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 12812,
        "name": "\u5e7f\u5dde\u677e\u7530\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 12813,
        "name": "\u5e7f\u5dde\u73e0\u6c5f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 12814,
        "name": "\u5e7f\u4e1c\u65b0\u534e\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 12815,
        "name": "\u5e7f\u4e1c\u7701\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 12816,
        "name": "\u5e7f\u4e1c\u793e\u4f1a\u79d1\u5b66\u5927\u5b66"
    },
    {
        "id": 12817,
        "name": "\u5e7f\u4e1c\u9752\u5e74\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 12818,
        "name": "\u6df1\u5733\u5e02\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 12819,
        "name": "\u5e7f\u5dde\u5e02\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 12820,
        "name": "\u97f6\u5173\u5e02\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 12821,
        "name": "\u6c55\u5934\u5e02\u4e1a\u4f59\u5927\u5b66"
    },
    {
        "id": 12822,
        "name": "\u5e7f\u4e1c\u7701\u56fd\u9632\u5de5\u4e1a\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 12823,
        "name": "\u5357\u6d77\u6210\u4eba\u5b66\u9662"
    },
    {
        "id": 12824,
        "name": "\u5e7f\u4e1c\u7701\u7535\u5b50\u5546\u52a1\u6280\u5e08\u5b66\u9662"
    },
    {
        "id": 12825,
        "name": "\u6df1\u5733\u4fe1\u606f\u5b66\u9662"
    },
    {
        "id": 12827,
        "name": "\u6c5f\u95e8\u5e02\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 12828,
        "name": "\u5317\u4eac\u5927\u5b66\u6df1\u5733\u7814\u7a76\u751f\u9662"
    },
    {
        "id": 12829,
        "name": "\u6c5f\u95e8\u827a\u534e\u65c5\u6e38\u804c\u4e1a\u5b66\u9662"
    }],
    "name": "\u5e7f\u4e1c"
},
{
    "id": 13,
    "school": [{
        "id": 13001,
        "name": "\u6e56\u5357\u5e08\u5927"
    },
    {
        "id": 13002,
        "name": "\u4e2d\u5357\u5927\u5b66"
    },
    {
        "id": 13003,
        "name": "\u6e56\u5357\u5927\u5b66"
    },
    {
        "id": 13004,
        "name": "\u957f\u6c99\u7406\u5de5"
    },
    {
        "id": 13005,
        "name": "\u6e56\u5357\u519c\u5927"
    },
    {
        "id": 13006,
        "name": "\u6e56\u5357\u4e2d\u533b\u836f"
    },
    {
        "id": 13007,
        "name": "\u4e2d\u5357\u6797\u4e1a\u79d1\u5927"
    },
    {
        "id": 13008,
        "name": "\u957f\u6c99\u5b66\u9662"
    },
    {
        "id": 13009,
        "name": "\u957f\u6c99\u533b\u5b66\u9662"
    },
    {
        "id": 13010,
        "name": "\u6e56\u5357\u6d89\u5916\u7ecf\u6d4e"
    },
    {
        "id": 13011,
        "name": "\u6e56\u5357\u5546\u5b66\u9662"
    },
    {
        "id": 13012,
        "name": "\u6e56\u5357\u4eba\u6587\u79d1\u6280"
    },
    {
        "id": 13013,
        "name": "\u6e56\u5357\u4e09\u4e00\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13014,
        "name": "\u6e56\u5357\u98df\u54c1\u836f\u54c1\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 13051,
        "name": "\u6e58\u6f6d\u5927\u5b66"
    },
    {
        "id": 13052,
        "name": "\u6e56\u5357\u79d1\u5927"
    },
    {
        "id": 13053,
        "name": "\u6e56\u5357\u5de5\u7a0b\u5b66\u9662"
    },
    {
        "id": 13101,
        "name": "\u5357\u534e\u5927\u5b66"
    },
    {
        "id": 13151,
        "name": "\u5409\u9996\u5927\u5b66"
    },
    {
        "id": 13201,
        "name": "\u6e56\u5357\u5de5\u5927"
    },
    {
        "id": 13251,
        "name": "\u6e56\u5357\u57ce\u5e02\u5b66\u9662"
    },
    {
        "id": 13301,
        "name": "\u6e56\u5357\u7406\u5de5\u5b66\u9662"
    },
    {
        "id": 13351,
        "name": "\u6e58\u5357\u5b66\u9662"
    },
    {
        "id": 13401,
        "name": "\u8861\u9633\u5e08\u9662"
    },
    {
        "id": 13451,
        "name": "\u6e56\u5357\u6587\u7406"
    },
    {
        "id": 13501,
        "name": "\u6000\u5316\u5b66\u9662"
    },
    {
        "id": 13551,
        "name": "\u6e56\u5357\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 13601,
        "name": "\u90b5\u9633\u5b66\u9662"
    },
    {
        "id": 13701,
        "name": "\u4fdd\u9669\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 13702,
        "name": "\u957f\u6c99\u7535\u529b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13703,
        "name": "\u957f\u6c99\u822a\u7a7a\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 13704,
        "name": "\u957f\u6c99\u73af\u5883\u4fdd\u62a4\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13705,
        "name": "\u957f\u6c99\u6c11\u653f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13706,
        "name": "\u957f\u6c99\u5357\u65b9\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 13707,
        "name": "\u957f\u6c99\u5546\u8d38\u65c5\u6e38\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13710,
        "name": "\u957f\u6c99\u5e08\u8303\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 13711,
        "name": "\u957f\u6c99\u901a\u4fe1\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13712,
        "name": "\u957f\u6c99\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 13713,
        "name": "\u957f\u6c99\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13714,
        "name": "\u5e38\u5fb7\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13715,
        "name": "\u90f4\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13716,
        "name": "\u8861\u9633\u8d22\u7ecf\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13717,
        "name": "\u6e56\u5357\u5b89\u5168\u6280\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 13718,
        "name": "\u6e56\u5357\u8d22\u653f\u7ecf\u6d4e\u5b66\u9662"
    },
    {
        "id": 13719,
        "name": "\u6e56\u5357\u57ce\u5efa\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13720,
        "name": "\u6e56\u5357\u5927\u4f17\u4f20\u5a92\u5b66\u9662"
    },
    {
        "id": 13721,
        "name": "\u6e56\u5357\u7b2c\u4e00\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 13722,
        "name": "\u6e56\u5357\u5bf9\u5916\u7ecf\u6d4e\u8d38\u6613\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 13723,
        "name": "\u6e56\u5357\u5de5\u7a0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13724,
        "name": "\u6e56\u5357\u5de5\u5b66\u9662"
    },
    {
        "id": 13725,
        "name": "\u6e56\u5357\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13726,
        "name": "\u6e56\u5357\u5de5\u827a\u7f8e\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 13727,
        "name": "\u6e56\u5357\u8b66\u5bdf\u5b66\u9662"
    },
    {
        "id": 13728,
        "name": "\u6e56\u5357\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 13729,
        "name": "\u6e56\u5357\u5316\u5de5\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13730,
        "name": "\u6e56\u5357\u73af\u5883\u751f\u7269\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13731,
        "name": "\u6e56\u5357\u673a\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13732,
        "name": "\u6e56\u5357\u5efa\u6750\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 13733,
        "name": "\u6e56\u5357\u9ad8\u901f\u94c1\u8def\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13734,
        "name": "\u6e56\u5357\u4ea4\u901a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13735,
        "name": "\u6e56\u5357\u7ecf\u6d4e\u5e72\u90e8\u7ba1\u7406\u5b66\u9662"
    },
    {
        "id": 13736,
        "name": "\u6e56\u5357\u4e5d\u5db7\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13737,
        "name": "\u6e56\u5357\u79d1\u6280\u7ecf\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 13738,
        "name": "\u6e56\u5357\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 13739,
        "name": "\u6e56\u5357\u7406\u5de5\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13740,
        "name": "\u6e56\u5357\u5a04\u5e95\u8fdc\u4e1c\u804c\u4e1a\u5b66\u6821"
    },
    {
        "id": 13741,
        "name": "\u6e56\u5357\u6c11\u65cf\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 13742,
        "name": "\u6e56\u5357\u519c\u4e1a\u5927\u5b66\u56fd\u9645\u5b66\u9662"
    },
    {
        "id": 13743,
        "name": "\u6e56\u5357\u5973\u5b50\u5b66\u9662"
    },
    {
        "id": 13744,
        "name": "\u6e56\u5357\u8f6f\u4ef6\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13745,
        "name": "\u6e56\u5357\u5546\u52a1\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13747,
        "name": "\u6e56\u5357\u751f\u7269\u673a\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13749,
        "name": "\u6e56\u5357\u7701\u6c34\u5229\u6c34\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13750,
        "name": "\u6e56\u5357\u77f3\u6cb9\u5316\u5de5\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13751,
        "name": "\u6e56\u5357\u7a0e\u52a1\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 13752,
        "name": "\u6e56\u5357\u53f8\u6cd5\u8b66\u5b98\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13753,
        "name": "\u6e56\u5357\u4f53\u80b2\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 13754,
        "name": "\u6e56\u5357\u94c1\u9053\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13755,
        "name": "\u6e56\u5357\u94c1\u8def\u79d1\u6280\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13756,
        "name": "\u6e56\u5357\u540c\u5fb7\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 13757,
        "name": "\u6e56\u5357\u7f51\u7edc\u5de5\u7a0b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 13758,
        "name": "\u6e56\u5357\u73b0\u4ee3\u7269\u6d41\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13759,
        "name": "\u6e56\u5357\u4fe1\u606f\u79d1\u5b66\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 13760,
        "name": "\u6e56\u5357\u4fe1\u606f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13761,
        "name": "\u6e56\u5357\u884c\u653f\u5b66\u9662"
    },
    {
        "id": 13762,
        "name": "\u6e56\u5357\u51b6\u91d1\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13763,
        "name": "\u6e56\u5357\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 13764,
        "name": "\u6e56\u5357\u4e2d\u533b\u836f\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 13765,
        "name": "\u6000\u5316\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 13766,
        "name": "\u6000\u5316\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13768,
        "name": "\u5a04\u5e95\u7406\u5de5\u5b66\u9662"
    },
    {
        "id": 13769,
        "name": "\u5a04\u5e95\u5e02\u536b\u751f\u5b66\u6821"
    },
    {
        "id": 13770,
        "name": "\u5a04\u5e95\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13771,
        "name": "\u90b5\u9633\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 13772,
        "name": "\u90b5\u9633\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13773,
        "name": "\u6e58\u6f6d\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13774,
        "name": "\u6e58\u897f\u6c11\u65cf\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13775,
        "name": "\u6f47\u6e58\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 13776,
        "name": "\u76ca\u9633\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13777,
        "name": "\u6c38\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13778,
        "name": "\u5cb3\u9633\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13779,
        "name": "\u5f20\u5bb6\u754c\u822a\u7a7a\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13780,
        "name": "\u682a\u6d32\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 13781,
        "name": "\u682a\u6d32\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13782,
        "name": "\u6e58\u6f6d\u5927\u5b66\u5174\u6e58\u5b66\u9662"
    },
    {
        "id": 13783,
        "name": "\u6e56\u5357\u5de5\u4e1a\u5927\u5b66\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 13784,
        "name": "\u6e56\u5357\u79d1\u6280\u5927\u5b66\u6f47\u6e58\u5b66\u9662"
    },
    {
        "id": 13785,
        "name": "\u5357\u534e\u5927\u5b66\u8239\u5c71\u5b66\u9662"
    },
    {
        "id": 13786,
        "name": "\u6e56\u5357\u5546\u5b66\u9662\u5317\u6d25\u5b66\u9662"
    },
    {
        "id": 13787,
        "name": "\u6e56\u5357\u5e08\u8303\u5927\u5b66\u6811\u8fbe\u5b66\u9662"
    },
    {
        "id": 13788,
        "name": "\u6e56\u5357\u519c\u4e1a\u5927\u5b66\u4e1c\u65b9\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 13789,
        "name": "\u4e2d\u5357\u6797\u4e1a\u79d1\u6280\u5927\u5b66\u6d89\u5916\u5b66\u9662"
    },
    {
        "id": 13790,
        "name": "\u6e56\u5357\u6587\u7406\u5b66\u9662\u8299\u84c9\u5b66\u9662"
    },
    {
        "id": 13791,
        "name": "\u6e56\u5357\u7406\u5de5\u5b66\u9662\u5357\u6e56\u5b66\u9662"
    },
    {
        "id": 13792,
        "name": "\u8861\u9633\u5e08\u8303\u5b66\u9662\u5357\u5cb3\u5b66\u9662"
    },
    {
        "id": 13793,
        "name": "\u6e56\u5357\u5de5\u7a0b\u5b66\u9662\u5e94\u7528\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13794,
        "name": "\u6e56\u5357\u4e2d\u533b\u836f\u5927\u5b66\u6e58\u674f\u5b66\u9662"
    },
    {
        "id": 13795,
        "name": "\u5409\u9996\u5927\u5b66\u5f20\u5bb6\u754c\u5b66\u9662"
    },
    {
        "id": 13796,
        "name": "\u957f\u6c99\u7406\u5de5\u5927\u5b66\u57ce\u5357\u5b66\u9662"
    },
    {
        "id": 13797,
        "name": "\u6e56\u5357\u90fd\u5e02\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 13798,
        "name": "\u6e56\u5357\u7535\u5b50\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 13799,
        "name": "\u6e56\u5357\u5916\u56fd\u8bed\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 13805,
        "name": "\u6e56\u5357\u751f\u7269\u4e0e\u673a\u7535\u5de5\u7a0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13806,
        "name": "\u6e56\u5357\u79d1\u6280\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 13807,
        "name": "\u8861\u9633\u5de5\u4e1a\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 13808,
        "name": "\u6e58\u897f\u6c11\u65cf\u6559\u5e08\u8fdb\u4fee\u5b66\u9662"
    },
    {
        "id": 13809,
        "name": "\u6e56\u5357\u6709\u8272\u91d1\u5c5e\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 13810,
        "name": "\u6e56\u5357\u7eba\u7ec7\u804c\u5de5\u5927\u5b66\u6e56"
    },
    {
        "id": 13811,
        "name": "\u6e56\u5357\u91d1\u878d\u6280\u672f\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 13812,
        "name": "\u76ca\u9633\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 13813,
        "name": "\u957f\u6c99\u5de5\u4e1a\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 13814,
        "name": "\u6e56\u5357\u5175\u5668\u5de5\u4e1a\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 13815,
        "name": "\u957f\u6c99\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 13816,
        "name": "\u8861\u9633\u6709\u8272\u51b6\u91d1\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 13817,
        "name": "\u682a\u6d32\u5e02\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 13819,
        "name": "\u6e56\u5357\u5de5\u4e1a\u79d1\u6280\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 13820,
        "name": "\u5357\u65b9\u52a8\u529b\u673a\u68b0\u516c\u53f8\u804c\u5de5\u5de5\u5b66\u9662"
    },
    {
        "id": 13821,
        "name": "\u76ca\u9633\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 13822,
        "name": "\u6e56\u5357\u5e08\u8303\u5927\u5b66\u6cd5\u5b66\u9662"
    }],
    "name": "\u6e56\u5357"
},
{
    "id": 14,
    "school": [{
        "id": 14001,
        "name": "\u5170\u5dde\u5927\u5b66"
    },
    {
        "id": 14002,
        "name": "\u897f\u5317\u6c11\u5927"
    },
    {
        "id": 14003,
        "name": "\u897f\u5317\u5e08\u8303\u5927\u5b66"
    },
    {
        "id": 14004,
        "name": "\u7518\u8083\u4e2d\u533b\u5b66\u9662"
    },
    {
        "id": 14005,
        "name": "\u5170\u5dde\u5546\u5b66\u9662"
    },
    {
        "id": 14006,
        "name": "\u7518\u8083\u653f\u6cd5\u5b66\u9662"
    },
    {
        "id": 14007,
        "name": "\u5170\u5dde\u57ce\u5e02\u5b66\u9662"
    },
    {
        "id": 14008,
        "name": "\u7518\u8083\u519c\u5927"
    },
    {
        "id": 14009,
        "name": "\u5170\u5dde\u7406\u5de5"
    },
    {
        "id": 14010,
        "name": "\u5170\u5dde\u4ea4\u5927"
    },
    {
        "id": 14011,
        "name": "\u897f\u5317\u5e08\u8303\u5927\u5b66\u77e5\u884c\u5b66\u9662"
    },
    {
        "id": 14051,
        "name": "\u5929\u6c34\u5e08\u9662"
    },
    {
        "id": 14101,
        "name": "\u9647\u4e1c\u5b66\u9662"
    },
    {
        "id": 14151,
        "name": "\u6cb3\u897f\u5b66\u9662"
    },
    {
        "id": 14201,
        "name": "\u5170\u5dde\u77f3\u5316\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 14202,
        "name": "\u7518\u8083\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 14203,
        "name": "\u7518\u8083\u8b66\u5bdf\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 14204,
        "name": "\u5170\u5dde\u7406\u5de5\u5927\u5b66\u6280\u672f\u5de5\u7a0b\u5b66\u9662"
    },
    {
        "id": 14205,
        "name": "\u5170\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 14206,
        "name": "\u6b66\u5a01\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 14207,
        "name": "\u5f20\u6396\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 14208,
        "name": "\u7518\u8083\u755c\u7267\u5de5\u7a0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 14209,
        "name": "\u9647\u5357\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 14210,
        "name": "\u5408\u4f5c\u6c11\u65cf\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 14211,
        "name": "\u7518\u8083\u8054\u5408\u5927\u5b66"
    },
    {
        "id": 14212,
        "name": "\u7518\u8083\u6797\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 14213,
        "name": "\u7518\u8083\u5efa\u7b51\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 14214,
        "name": "\u9152\u6cc9\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 14216,
        "name": "\u7518\u8083\u519c\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 14217,
        "name": "\u5e73\u51c9\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 14218,
        "name": "\u5170\u5dde\u8d44\u6e90\u73af\u5883\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 14219,
        "name": "\u5b9a\u897f\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 14220,
        "name": "\u5170\u5dde\u4ea4\u901a\u5927\u5b66\u535a\u6587\u5b66\u9662"
    },
    {
        "id": 14221,
        "name": "\u5170\u5dde\u5de5\u4e1a\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 14222,
        "name": "\u5170\u5dde\u5916\u8bed\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 14223,
        "name": "\u5170\u5dde\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 14224,
        "name": "\u7518\u8083\u94a2\u94c1\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 14225,
        "name": "\u7518\u8083\u4ea4\u901a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 14226,
        "name": "\u5170\u5dde\u5546\u5b66\u9662\u957f\u9752\u5b66\u9662"
    },
    {
        "id": 14227,
        "name": "\u5170\u5dde\u5546\u5b66\u9662\u9647\u6865\u5b66\u9662"
    }],
    "name": "\u7518\u8083"
},
{
    "id": 15,
    "school": [{
        "id": 15001,
        "name": "\u56db\u5ddd\u5927\u5b66"
    },
    {
        "id": 15002,
        "name": "\u56db\u5ddd\u519c\u5927"
    },
    {
        "id": 15003,
        "name": "\u7535\u5b50\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 15004,
        "name": "\u897f\u5357\u4ea4\u5927"
    },
    {
        "id": 15005,
        "name": "\u6210\u90fd\u7406\u5de5"
    },
    {
        "id": 15006,
        "name": "\u56db\u5ddd\u5e08\u5927"
    },
    {
        "id": 15007,
        "name": "\u897f\u5357\u6c11\u65cf\u5927\u5b66"
    },
    {
        "id": 15008,
        "name": "\u6210\u90fd\u5927\u5b66"
    },
    {
        "id": 15009,
        "name": "\u897f\u5357\u8d22\u7ecf"
    },
    {
        "id": 15010,
        "name": "\u897f\u534e\u5927\u5b66"
    },
    {
        "id": 15011,
        "name": "\u6210\u90fd\u4e2d\u533b\u836f"
    },
    {
        "id": 15012,
        "name": "\u6210\u90fd\u4fe1\u606f\u5de5\u7a0b\u5927\u5b66"
    },
    {
        "id": 15013,
        "name": "\u6210\u90fd\u533b\u5b66\u9662"
    },
    {
        "id": 15014,
        "name": "\u56db\u5ddd\u6587\u7406\u5b66\u9662"
    },
    {
        "id": 15015,
        "name": "\u6210\u90fd\u4f53\u9662"
    },
    {
        "id": 15016,
        "name": "\u56db\u5ddd\u97f3\u4e50\u5b66\u9662"
    },
    {
        "id": 15017,
        "name": "\u897f\u5357\u77f3\u6cb9"
    },
    {
        "id": 15018,
        "name": "\u897f\u5357\u4ea4\u901a\u5927\u5b66\u5e0c\u671b\u5b66\u9662"
    },
    {
        "id": 15019,
        "name": "\u56db\u5ddd\u957f\u6c5f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 15020,
        "name": "\u56db\u5ddd\u4f20\u5a92\u5b66\u9662"
    },
    {
        "id": 15051,
        "name": "\u4e2d\u56fd\u6c11\u822a\u98de\u884c\u5b66\u9662"
    },
    {
        "id": 15101,
        "name": "\u56db\u5ddd\u7406\u5de5"
    },
    {
        "id": 15151,
        "name": "\u6cf8\u5dde\u533b\u5b66\u9662"
    },
    {
        "id": 15152,
        "name": "\u56db\u5ddd\u8b66\u5bdf\u5b66\u9662"
    },
    {
        "id": 15201,
        "name": "\u5ddd\u5317\u533b\u5b66\u9662"
    },
    {
        "id": 15202,
        "name": "\u897f\u534e\u5e08\u5927"
    },
    {
        "id": 15251,
        "name": "\u5185\u6c5f\u5e08\u8303"
    },
    {
        "id": 15301,
        "name": "\u4e50\u5c71\u5e08\u9662"
    },
    {
        "id": 15351,
        "name": "\u7ef5\u9633\u5e08\u8303"
    },
    {
        "id": 15352,
        "name": "\u897f\u5357\u79d1\u5927"
    },
    {
        "id": 15401,
        "name": "\u897f\u660c\u5b66\u9662"
    },
    {
        "id": 15451,
        "name": "\u5b9c\u5bbe\u5b66\u9662"
    },
    {
        "id": 15501,
        "name": "\u6500\u679d\u82b1\u5b66\u9662"
    },
    {
        "id": 15601,
        "name": "\u963f\u575d\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 15602,
        "name": "\u6210\u90fd\u7535\u5b50\u673a\u68b0\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 15603,
        "name": "\u6210\u90fd\u4e1c\u8f6f\u5b66\u9662"
    },
    {
        "id": 15604,
        "name": "\u6210\u90fd\u7eba\u7ec7\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 15605,
        "name": "\u6210\u90fd\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 15606,
        "name": "\u6210\u90fd\u822a\u7a7a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15607,
        "name": "\u6210\u90fd\u7406\u5de5\u5927\u5b66\u5e7f\u64ad\u5f71\u89c6\u5b66\u9662"
    },
    {
        "id": 15608,
        "name": "\u6210\u90fd\u519c\u4e1a\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 15610,
        "name": "\u6210\u90fd\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 15611,
        "name": "\u6210\u90fd\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15612,
        "name": "\u8fbe\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15613,
        "name": "\u7535\u5b50\u79d1\u6280\u5927\u5b66\u6210\u90fd\u5b66\u9662"
    },
    {
        "id": 15614,
        "name": "\u5e7f\u5b89\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15616,
        "name": "\u4e50\u5c71\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15617,
        "name": "\u6cf8\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15618,
        "name": "\u7709\u5c71\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15619,
        "name": "\u7ef5\u9633\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15620,
        "name": "\u6c11\u529e\u56db\u5ddd\u5929\u4e00\u5b66\u9662"
    },
    {
        "id": 15621,
        "name": "\u5185\u6c5f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15622,
        "name": "\u5357\u5145\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15623,
        "name": "\u56db\u5ddd\u5927\u5b66\u9f99\u6cc9\u6821\u533a"
    },
    {
        "id": 15624,
        "name": "\u56db\u5ddd\u7535\u529b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15625,
        "name": "\u56db\u5ddd\u5de5\u7a0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15626,
        "name": "\u56db\u5ddd\u5de5\u5546\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15627,
        "name": "\u56db\u5ddd\u7ba1\u7406\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 15628,
        "name": "\u56db\u5ddd\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 15629,
        "name": "\u56db\u5ddd\u56fd\u9645\u6807\u699c\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 15630,
        "name": "\u56db\u5ddd\u822a\u5929\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15631,
        "name": "\u56db\u5ddd\u534e\u65b0\u73b0\u4ee3\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 15632,
        "name": "\u56db\u5ddd\u5316\u5de5\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15633,
        "name": "\u56db\u5ddd\u673a\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15634,
        "name": "\u56db\u5ddd\u5efa\u7b51\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15635,
        "name": "\u56db\u5ddd\u4ea4\u901a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15636,
        "name": "\u56db\u5ddd\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 15637,
        "name": "\u56db\u5ddd\u8b66\u5b89\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 15638,
        "name": "\u56db\u5ddd\u70f9\u996a\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 15639,
        "name": "\u56db\u5ddd\u5546\u52a1\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 15640,
        "name": "\u56db\u5ddd\u5e08\u8303\u5927\u5b66\u7ef5\u9633\u521d\u7b49\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 15641,
        "name": "\u56db\u5ddd\u6c34\u5229\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15642,
        "name": "\u56db\u5ddd\u53f8\u6cd5\u8b66\u5b98\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 15643,
        "name": "\u56db\u5ddd\u6258\u666e\u4fe1\u606f\u6280\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 15644,
        "name": "\u56db\u5ddd\u5916\u8bed\u5b66\u9662\u6210\u90fd\u5b66\u9662"
    },
    {
        "id": 15645,
        "name": "\u56db\u5ddd\u6587\u5316\u4f20\u5a92\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 15646,
        "name": "\u56db\u5ddd\u4fe1\u606f\u5de5\u7a0b\u5b66\u6821"
    },
    {
        "id": 15647,
        "name": "\u56db\u5ddd\u90ae\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15648,
        "name": "\u56db\u5ddd\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15649,
        "name": "\u56db\u5ddd\u4e2d\u533b\u836f\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 15650,
        "name": "\u96c5\u5b89\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15651,
        "name": "\u5b9c\u5bbe\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15652,
        "name": "\u56db\u5ddd\u5927\u5b66\u9526\u57ce\u5b66\u9662"
    },
    {
        "id": 15653,
        "name": "\u56db\u5ddd\u5927\u5b66\u9526\u6c5f\u5b66\u9662"
    },
    {
        "id": 15654,
        "name": "\u5fb7\u9633\u804c\u4e1a\u6280\u672f\u5b66\u6821"
    },
    {
        "id": 15655,
        "name": "\u56db\u5ddd\u4fe1\u606f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15656,
        "name": "\u56db\u5ddd\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 15657,
        "name": "\u56db\u5ddd\u5e08\u8303\u5927\u5b66\u6210\u90fd\u5b66\u9662"
    },
    {
        "id": 15658,
        "name": "\u56db\u5ddd\u5e08\u8303\u5927\u5b66\u6587\u7406\u5b66\u9662"
    },
    {
        "id": 15659,
        "name": "\u6210\u90fd\u4fe1\u606f\u5de5\u7a0b\u5b66\u9662\u94f6\u674f\u9152\u5e97\u7ba1\u7406\u5b66\u9662"
    },
    {
        "id": 15660,
        "name": "\u6210\u90fd\u7406\u5de5\u5927\u5b66\u5de5\u7a0b\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15661,
        "name": "\u56db\u5ddd\u6587\u5316\u4ea7\u4e1a\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 15662,
        "name": "\u56db\u5ddd\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 15664,
        "name": "\u897f\u5357\u79d1\u6280\u5927\u5b66\u57ce\u5e02\u5b66\u9662"
    },
    {
        "id": 15665,
        "name": "\u56db\u5ddd\u97f3\u4e50\u5b66\u9662\u7ef5\u9633\u827a\u672f\u5b66\u9662"
    },
    {
        "id": 15667,
        "name": "\u897f\u5357\u8d22\u7ecf\u5927\u5b66\u5929\u5e9c\u5b66\u9662"
    },
    {
        "id": 15677,
        "name": "\u56db\u5ddd\u4e2d\u533b\u836f\u9ad8\u7b49\u4e13\u79d1\u5b66"
    },
    {
        "id": 15681,
        "name": "\u4e2d\u56fd\u79d1\u5b66\u9662\u6210\u90fd\u5206\u9662\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 15682,
        "name": "\u6210\u90fd\u5e02\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 15683,
        "name": "\u5357\u5145\u5e02\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 15684,
        "name": "\u56db\u5ddd\u7701\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 15685,
        "name": "\u56db\u5ddd\u7ecf\u6d4e\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 15686,
        "name": "\u56db\u5ddd\u519c\u4e1a\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 15687,
        "name": "\u5e7f\u5143\u804c\u5de5\u533b\u5b66\u9662"
    },
    {
        "id": 15688,
        "name": "\u56db\u5ddd\u7701\u804c\u5de5\u8fd0\u52a8\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 15689,
        "name": "\u56db\u5ddd\u7701\u4e1c\u65b9\u52a8\u529b\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 15690,
        "name": "\u6210\u90fd\u7535\u529b\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 15691,
        "name": "\u6210\u90fd\u5e02\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 15692,
        "name": "\u4e2d\u56fd\u5de5\u7a0b\u7269\u7406\u7814\u7a76\u9662\u804c\u5de5\u5de5\u5b66\u9662"
    },
    {
        "id": 15693,
        "name": "\u6210\u90fd\u53d1\u52a8\u673a\u516c\u53f8\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 15694,
        "name": "\u56db\u5ddd\u6838\u5de5\u4e1a\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 15695,
        "name": "\u56db\u5ddd\u79d1\u6280\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 15696,
        "name": "\u56db\u5ddd\u7701\u5316\u5de5\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 15697,
        "name": "\u6210\u90fd\u7535\u5b50\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 15698,
        "name": "\u56fd\u8425\u6daa\u6c5f\u673a\u5668\u5382\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 15699,
        "name": "\u6210\u90fd\u51b6\u91d1\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 15700,
        "name": "\u7b2c\u4e94\u51b6\u91d1\u5efa\u8bbe\u516c\u53f8\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 15701,
        "name": "\u6210\u90fd\u5de5\u4e1a\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 15702,
        "name": "\u6210\u90fd\u98de\u673a\u5de5\u4e1a\u516c\u53f8\u804c\u5de5\u5de5\u5b66\u9662"
    },
    {
        "id": 15704,
        "name": "\u56db\u5ddd\u8d22\u7ecf\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 15706,
        "name": "\u56db\u5ddd\u57ce\u5e02\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 15707,
        "name": "\u4e2d\u56fd\u4e94\u51b6\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 15708,
        "name": "\u56db\u5ddd\u5e08\u8303\u5927\u5b66\u7ecf\u6d4e\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 15711,
        "name": "\u56db\u5ddd\u7701\u536b\u751f\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 15714,
        "name": "\u56db\u5ddd\u6c11\u65cf\u5b66\u9662"
    },
    {
        "id": 15715,
        "name": "\u56db\u5ddd\u5e08\u8303\u5927\u5b66\u7535\u5f71\u7535\u89c6\u5b66\u9662"
    }],
    "name": "\u56db\u5ddd"
},
{
    "id": 16,
    "school": [{
        "id": 16001,
        "name": "\u5c71\u4e1c\u5927\u5b66"
    },
    {
        "id": 16002,
        "name": "\u4e2d\u56fd\u6d77\u6d0b\u5927\u5b66"
    },
    {
        "id": 16003,
        "name": "\u6d4e\u5357\u5927\u5b66"
    },
    {
        "id": 16004,
        "name": "\u5c71\u4e1c\u5efa\u7b51\u5927\u5b66"
    },
    {
        "id": 16005,
        "name": "\u5c71\u4e1c\u5e08\u5927"
    },
    {
        "id": 16006,
        "name": "\u5c71\u4e1c\u8d22\u7ecf\u5927\u5b66"
    },
    {
        "id": 16007,
        "name": "\u5c71\u4e1c\u4e2d\u533b\u836f"
    },
    {
        "id": 16008,
        "name": "\u5c71\u4e1c\u8f7b\u5de5"
    },
    {
        "id": 16009,
        "name": "\u5c71\u4e1c\u4ea4\u901a\u5b66\u9662"
    },
    {
        "id": 16010,
        "name": "\u5c71\u4e1c\u8b66\u9662"
    },
    {
        "id": 16011,
        "name": "\u5c71\u4e1c\u4f53\u9662"
    },
    {
        "id": 16012,
        "name": "\u5c71\u4e1c\u827a\u672f\u5b66\u9662"
    },
    {
        "id": 16013,
        "name": "\u5c71\u4e1c\u5de5\u7f8e"
    },
    {
        "id": 16014,
        "name": "\u5c71\u4e1c\u6d77\u4e8b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16015,
        "name": "\u66f2\u961c\u5e08\u5927"
    },
    {
        "id": 16016,
        "name": "\u5317\u4eac\u7535\u5f71\u5b66\u9662\u73b0\u4ee3\u521b\u610f\u5a92\u4f53\u5b66\u9662"
    },
    {
        "id": 16051,
        "name": "\u70df\u53f0\u5927\u5b66"
    },
    {
        "id": 16052,
        "name": "\u9c81\u4e1c\u5927\u5b66"
    },
    {
        "id": 16053,
        "name": "\u5c71\u4e1c\u5de5\u5546"
    },
    {
        "id": 16054,
        "name": "\u70df\u53f0\u5357\u5c71\u5b66\u9662"
    },
    {
        "id": 16101,
        "name": "\u9752\u5c9b\u5927\u5b66"
    },
    {
        "id": 16102,
        "name": "\u5c71\u4e1c\u79d1\u5927"
    },
    {
        "id": 16103,
        "name": "\u9752\u5c9b\u79d1\u5927"
    },
    {
        "id": 16104,
        "name": "\u9752\u5c9b\u7406\u5de5"
    },
    {
        "id": 16105,
        "name": "\u9752\u5c9b\u519c\u4e1a\u5927\u5b66"
    },
    {
        "id": 16106,
        "name": "\u6ee8\u6d77\u5b66\u9662"
    },
    {
        "id": 16151,
        "name": "\u4e2d\u56fd\u77f3\u6cb9\u5927\u5b66\uff08\u534e\u4e1c\uff09"
    },
    {
        "id": 16201,
        "name": "\u804a\u57ce\u5927\u5b66"
    },
    {
        "id": 16251,
        "name": "\u5c71\u4e1c\u7406\u5de5"
    },
    {
        "id": 16301,
        "name": "\u6f4d\u574a\u533b\u5b66\u9662"
    },
    {
        "id": 16302,
        "name": "\u6f4d\u574a\u5b66\u9662"
    },
    {
        "id": 16351,
        "name": "\u6cf0\u5c71\u533b\u5b66\u9662"
    },
    {
        "id": 16352,
        "name": "\u5c71\u4e1c\u8d22\u653f"
    },
    {
        "id": 16353,
        "name": "\u6cf0\u5c71\u5b66\u9662"
    },
    {
        "id": 16354,
        "name": "\u5c71\u4e1c\u519c\u5927"
    },
    {
        "id": 16401,
        "name": "\u6ee8\u5dde\u533b\u5b66\u9662"
    },
    {
        "id": 16402,
        "name": "\u6ee8\u5dde\u5b66\u9662"
    },
    {
        "id": 16451,
        "name": "\u6d4e\u5b81\u533b\u5b66\u9662"
    },
    {
        "id": 16501,
        "name": "\u4e34\u6c82\u5927\u5b66"
    },
    {
        "id": 16551,
        "name": "\u5fb7\u5dde\u5b66\u9662"
    },
    {
        "id": 16601,
        "name": "\u67a3\u5e84\u5b66\u9662"
    },
    {
        "id": 16651,
        "name": "\u83cf\u6cfd\u5b66\u9662"
    },
    {
        "id": 16701,
        "name": "\u6ee8\u5dde\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16702,
        "name": "\u5fb7\u5dde\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 16703,
        "name": "\u5fb7\u5dde\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16704,
        "name": "\u4e1c\u8425\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16705,
        "name": "\u54c8\u5de5\u5927(\u5a01\u6d77)"
    },
    {
        "id": 16706,
        "name": "\u83cf\u6cfd\u533b\u5b66\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 16707,
        "name": "\u6d4e\u5357\u5de5\u7a0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16708,
        "name": "\u5c71\u4e1c\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16709,
        "name": "\u6d4e\u5357\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16710,
        "name": "\u6d4e\u5b81\u5b66\u9662"
    },
    {
        "id": 16711,
        "name": "\u6d4e\u5b81\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16712,
        "name": "\u83b1\u829c\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16713,
        "name": "\u804a\u57ce\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16714,
        "name": "\u9752\u5c9b\u98de\u6d0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16715,
        "name": "\u9752\u5c9b\u6e2f\u6e7e\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16716,
        "name": "\u9752\u5c9b\u6052\u661f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16717,
        "name": "\u9752\u5c9b\u9ec4\u6d77\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16719,
        "name": "\u9752\u5c9b\u6c42\u5b9e\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16720,
        "name": "\u9752\u5c9b\u8fdc\u6d0b\u8239\u5458\u5b66\u9662"
    },
    {
        "id": 16721,
        "name": "\u9752\u5c9b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16722,
        "name": "\u66f2\u961c\u8fdc\u4e1c\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16723,
        "name": "\u65e5\u7167\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16724,
        "name": "\u5c71\u4e1c\u5927\u738b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16725,
        "name": "\u5c71\u4e1c\u5927\u5b66\u5a01\u6d77\u5206\u6821"
    },
    {
        "id": 16726,
        "name": "\u5c71\u4e1c\u7535\u529b\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 16727,
        "name": "\u5c71\u4e1c\u7535\u5b50\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16729,
        "name": "\u5c71\u4e1c\u670d\u88c5\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16730,
        "name": "\u5c71\u4e1c\u5de5\u4e1a\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16731,
        "name": "\u5c71\u4e1c\u534e\u5b87\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16732,
        "name": "\u5c71\u4e1c\u5316\u5de5\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16733,
        "name": "\u5c71\u4e1c\u4ea4\u901a\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16734,
        "name": "\u5c71\u4e1c\u7ecf\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16736,
        "name": "\u5c71\u4e1c\u51ef\u6587\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16737,
        "name": "\u5c71\u4e1c\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16738,
        "name": "\u5c71\u4e1c\u52b3\u52a8\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16739,
        "name": "\u5c71\u4e1c\u529b\u660e\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16740,
        "name": "\u5c71\u4e1c\u65c5\u6e38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16741,
        "name": "\u5c71\u4e1c\u94dd\u4e1a\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16742,
        "name": "\u5c71\u4e1c\u5546\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16743,
        "name": "\u5c71\u4e1c\u80dc\u5229\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16744,
        "name": "\u5c71\u4e1c\u7701\u9752\u5c9b\u9152\u5e97\u7ba1\u7406\u5b66\u9662"
    },
    {
        "id": 16745,
        "name": "\u5c71\u4e1c\u6c34\u5229\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16746,
        "name": "\u5c71\u4e1c\u7701\u6f4d\u574a\u827a\u672f\u5b66\u6821"
    },
    {
        "id": 16747,
        "name": "\u5c71\u4e1c\u5723\u7ff0\u8d22\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16748,
        "name": "\u5c71\u4e1c\u6c34\u5229\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16749,
        "name": "\u5c71\u4e1c\u6c34\u5229\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 16750,
        "name": "\u5c71\u4e1c\u4e1d\u7ef8\u7eba\u7ec7\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16751,
        "name": "\u5c71\u4e1c\u5916\u56fd\u8bed\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16752,
        "name": "\u5c71\u4e1c\u5916\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16753,
        "name": "\u5c71\u4e1c\u5916\u4e8b\u7ffb\u8bd1\u5b66\u9662\u5a01\u6d77\u5206\u6821"
    },
    {
        "id": 16754,
        "name": "\u5c71\u4e1c\u5916\u4e8b\u7ffb\u8bd1\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16755,
        "name": "\u5c71\u4e1c\u4e07\u6770\u533b\u5b66\u9662"
    },
    {
        "id": 16756,
        "name": "\u5c71\u4e1c\u5a01\u6d77\u8d22\u7ecf\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 16757,
        "name": "\u5c71\u4e1c\u5a01\u6d77\u5916\u56fd\u8bed\u8fdb\u4fee\u5b66\u9662"
    },
    {
        "id": 16758,
        "name": "\u5c71\u4e1c\u73b0\u4ee3\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16759,
        "name": "\u5c71\u4e1c\u534f\u548c\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16760,
        "name": "\u5c71\u4e1c\u4fe1\u606f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16761,
        "name": "\u5c71\u4e1c\u884c\u653f\u5b66\u9662(\u5c71\u4e1c\u7ecf\u6d4e\u7ba1\u7406\u5e72\u90e8\u5b66\u9662)"
    },
    {
        "id": 16762,
        "name": "\u5c71\u4e1c\u674f\u6797\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16763,
        "name": "\u5c71\u4e1c\u755c\u7267\u517d\u533b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16764,
        "name": "\u5c71\u4e1c\u836f\u54c1\u98df\u54c1\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16765,
        "name": "\u5c71\u4e1c\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 16766,
        "name": "\u5c71\u4e1c\u82f1\u624d\u5b66\u9662"
    },
    {
        "id": 16767,
        "name": "\u5c71\u4e1c\u653f\u6cd5\u5b66\u9662"
    },
    {
        "id": 16768,
        "name": "\u5c71\u4e1c\u4e2d\u533b\u836f\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 16769,
        "name": "\u6cf0\u5c71\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16770,
        "name": "\u4e07\u6770\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 16771,
        "name": "\u5a01\u6d77\u5e02\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 16772,
        "name": "\u5a01\u6d77\u5e02\u4ea4\u901a\u5b66\u6821"
    },
    {
        "id": 16773,
        "name": "\u5a01\u6d77\u804c\u4e1a(\u6280\u672f)\u5b66\u9662"
    },
    {
        "id": 16774,
        "name": "\u5a01\u6d77\u4e2d\u52a0\u56fd\u9645\u5de5\u5546\u5b66\u9662"
    },
    {
        "id": 16775,
        "name": "\u6f4d\u574a\u5de5\u5546\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16776,
        "name": "\u6f4d\u574a\u5de5\u7a0b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16777,
        "name": "\u6f4d\u574a\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16778,
        "name": "\u6f4d\u574a\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16779,
        "name": "\u6587\u767b\u5e08\u8303"
    },
    {
        "id": 16780,
        "name": "\u70df\u53f0\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16781,
        "name": "\u67a3\u5e84\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16782,
        "name": "\u4e2d\u56fd\u77f3\u6cb9\u5927\u5b66(\u534e\u4e1c)\u4e1c\u8425\u6821\u533a"
    },
    {
        "id": 16783,
        "name": "\u6dc4\u535a\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 16784,
        "name": "\u6dc4\u535a\u6052\u661f\u5916\u56fd\u8bed\u5b66\u9662"
    },
    {
        "id": 16785,
        "name": "\u6dc4\u535a\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16786,
        "name": "\u6dc4\u535a\u5e08\u4e13"
    },
    {
        "id": 16787,
        "name": "\u6dc4\u535a\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16788,
        "name": "\u9f50\u9c81\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 16789,
        "name": "\u5c71\u4e1c\u6280\u5e08\u5b66\u9662"
    },
    {
        "id": 16790,
        "name": "\u65e5\u7167\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 16792,
        "name": "\u5c71\u4e1c\u57ce\u5e02\u5efa\u8bbe\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16793,
        "name": "\u70df\u53f0\u5de5\u7a0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16794,
        "name": "\u5c71\u4e1c\u5546\u52a1\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16795,
        "name": "\u70df\u53f0\u6c7d\u8f66\u5de5\u7a0b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16796,
        "name": "\u5c71\u4e1c\u7701\u519c\u4e1a\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 16797,
        "name": "\u5c71\u4e1c\u9752\u5e74\u653f\u6cbb\u5b66\u9662"
    },
    {
        "id": 16798,
        "name": "\u5c71\u4e1c\u7701\u5de5\u4f1a\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 16800,
        "name": "\u5c71\u4e1c\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 16812,
        "name": "\u5fb7\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16820,
        "name": "\u4e2d\u56fd\u77f3\u6cb9\u5927\u5b66\u80dc\u5229\u5b66\u9662"
    },
    {
        "id": 16821,
        "name": "\u70df\u53f0\u5927\u5b66\u6587\u7ecf\u5b66\u9662"
    },
    {
        "id": 16822,
        "name": "\u9752\u5c9b\u7406\u5de5\u5927\u5b66\u7434\u5c9b\u5b66\u9662"
    },
    {
        "id": 16823,
        "name": "\u5c71\u4e1c\u79d1\u6280\u5927\u5b66\u6cf0\u5c71\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 16824,
        "name": "\u9752\u5c9b\u5de5\u5b66\u9662"
    },
    {
        "id": 16825,
        "name": "\u5c71\u4e1c\u8d22\u7ecf\u5927\u5b66\u71d5\u5c71\u5b66\u9662"
    },
    {
        "id": 16826,
        "name": "\u9752\u5c9b\u519c\u4e1a\u5927\u5b66\u6d77\u90fd\u5b66\u9662"
    },
    {
        "id": 16827,
        "name": "\u66f2\u961c\u5e08\u8303\u5927\u5b66\u674f\u575b\u5b66\u9662"
    },
    {
        "id": 16828,
        "name": "\u5c71\u4e1c\u8d22\u653f\u5b66\u9662\u4e1c\u65b9\u5b66\u9662"
    },
    {
        "id": 16829,
        "name": "\u5c71\u4e1c\u5e08\u8303\u5927\u5b66\u5386\u5c71\u5b66\u9662"
    },
    {
        "id": 16830,
        "name": "\u804a\u57ce\u5927\u5b66\u4e1c\u660c\u5b66\u9662"
    },
    {
        "id": 16831,
        "name": "\u6d4e\u5357\u5927\u5b66\u6cc9\u57ce\u5b66\u9662"
    },
    {
        "id": 16832,
        "name": "\u4e2d\u56fd\u519c\u4e1a\u5927\u5b66\uff08\u70df\u53f0\u6821\u533a\uff09"
    },
    {
        "id": 16833,
        "name": "\u65e5\u7167\u5e08\u8303\u5b66\u6821"
    },
    {
        "id": 16834,
        "name": "\u5c71\u4e1c\u827a\u672f\u8bbe\u8ba1\u5b66\u9662"
    },
    {
        "id": 16835,
        "name": "\u6ee8\u5dde\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16837,
        "name": "\u5c71\u4e1c\u51b6\u91d1\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16838,
        "name": "\u5c71\u4e1c\u7701\u6d4e\u5b81\u5e02\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16839,
        "name": "\u6d4e\u5357\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 16840,
        "name": "\u5c71\u4e1c\u7701\u804a\u57ce\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 16842,
        "name": "\u5c71\u4e1c\u8d22\u653f\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 16843,
        "name": "\u9752\u5c9b\u5e02\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 16844,
        "name": "\u9752\u5c9b\u7406\u5de5\u5927\u5b66\u8d39\u53bf\u6821\u533a"
    },
    {
        "id": 16845,
        "name": "\u5c71\u4e1c\u5175\u5668\u5de5\u4e1a\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 16846,
        "name": "\u65b0\u6c76\u77ff\u52a1\u5c40\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 16848,
        "name": "\u5c71\u4e1c\u5de5\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16849,
        "name": "\u5c71\u4e1c\u65b0\u534e\u5b66\u9662"
    },
    {
        "id": 16850,
        "name": "\u5c71\u4e1c\u7701\u533b\u5b66\u79d1\u5b66\u9662"
    },
    {
        "id": 16851,
        "name": "\u5c71\u4e1c\u5316\u5de5\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16852,
        "name": "\u5c71\u4e1c\u6d77\u5929\u8f6f\u4ef6\u5de5\u7a0b\u5b66\u9662"
    },
    {
        "id": 16853,
        "name": "\u9752\u5c9b\u519b\u653f\u4eba\u6587\u5927\u5b66\u4fe1\u606f\u5de5\u7a0b\u7ba1\u7406\u5b66\u9662"
    },
    {
        "id": 16854,
        "name": "\u54c8\u7406\u5de5\u8363\u6210\u5b66\u9662"
    },
    {
        "id": 16855,
        "name": "\u5c71\u4e1c\u7701\u7b2c\u4e8c\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16856,
        "name": "\u4e34\u6c82\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16857,
        "name": "\u9752\u5c9b\u5317\u6e2f\u5b66\u9662"
    },
    {
        "id": 16858,
        "name": "\u5c71\u4e1c\u53f8\u6cd5\u8b66\u5b98\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16860,
        "name": "\u5c71\u4e1c\u6587\u5316\u804c\u4e1a\u4ea7\u4e1a\u5b66\u9662"
    },
    {
        "id": 16861,
        "name": "\u83cf\u6cfd\u5bb6\u653f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16866,
        "name": "\u5c71\u4e1c\u7406\u5de5\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16867,
        "name": "\u5a01\u6d77\u5de5\u4e1a\u6280\u672f\u5b66\u6821(\u539f\u4e8c\u804c)"
    },
    {
        "id": 16868,
        "name": "\u5c71\u4e1c\u4f20\u5a92\u5b66\u9662"
    },
    {
        "id": 16869,
        "name": "\u8425\u53e3\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 16870,
        "name": "\u5c71\u4e1c\u4f20\u5a92\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16871,
        "name": "\u5c71\u4e1c\u7701\u76ca\u90fd\u536b\u751f\u5b66\u6821"
    },
    {
        "id": 16873,
        "name": "\u5c71\u4e1c\u70df\u53f0\u5efa\u6587\u5b66\u9662"
    },
    {
        "id": 16874,
        "name": "\u5c71\u4e1c\u7701\u6f4d\u574a\u536b\u751f\u5b66\u6821"
    },
    {
        "id": 16875,
        "name": "\u5bff\u5149\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 16878,
        "name": "\u5c71\u4e1c\u7701\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 16881,
        "name": "\u70df\u53f0\u57ce\u4e61\u5efa\u8bbe\u5b66\u6821"
    },
    {
        "id": 16882,
        "name": "\u9752\u5c9b\u5e02\u7535\u5b50\u4fe1\u606f\u6280\u672f\u5b66\u6821"
    },
    {
        "id": 16884,
        "name": "\u9752\u5c9b\u6ee8\u6d77\u5b66\u9662"
    },
    {
        "id": 16885,
        "name": "\u5c71\u4e1c\u533b\u836f\u6280\u5e08\u5b66\u9662"
    },
    {
        "id": 16887,
        "name": "\u6d4e\u5357\u534f\u548c\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 16889,
        "name": "\u5c71\u4e1c\u9ec4\u91d1\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 16890,
        "name": "\u5c71\u4e1c\u4ea4\u901a\u5b66\u9662\u6d77\u8fd0\u5b66\u9662"
    },
    {
        "id": 16892,
        "name": "\u5c71\u4e1c\u5de5\u4e1a\u6280\u5e08\u5b66\u9662"
    },
    {
        "id": 16893,
        "name": "\u5c71\u4e1c\u5973\u5b50\u5b66\u9662"
    }],
    "name": "\u5c71\u4e1c"
},
{
    "id": 17,
    "school": [{
        "id": 17001,
        "name": "\u53a6\u95e8\u5927\u5b66"
    },
    {
        "id": 17002,
        "name": "\u96c6\u7f8e\u5927\u5b66"
    },
    {
        "id": 17003,
        "name": "\u53a6\u95e8\u7406\u5de5\u5b66\u9662"
    },
    {
        "id": 17004,
        "name": "\u6cc9\u5dde\u8f7b\u5de5\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 17005,
        "name": "\u6cc9\u5dde\u5e08\u8303\u5b66\u9662\u8f6f\u4ef6\u5b66\u9662"
    },
    {
        "id": 17051,
        "name": "\u798f\u5dde\u5927\u5b66"
    },
    {
        "id": 17052,
        "name": "\u798f\u5efa\u5e08\u8303\u5927\u5b66"
    },
    {
        "id": 17053,
        "name": "\u798f\u5efa\u519c\u6797\u5927\u5b66"
    },
    {
        "id": 17054,
        "name": "\u798f\u5efa\u533b\u79d1\u5927\u5b66"
    },
    {
        "id": 17055,
        "name": "\u798f\u5efa\u5de5\u7a0b\u5b66\u9662"
    },
    {
        "id": 17056,
        "name": "\u798f\u5efa\u4e2d\u533b\u836f\u5927\u5b66"
    },
    {
        "id": 17057,
        "name": "\u95fd\u6c5f\u5b66\u9662"
    },
    {
        "id": 17101,
        "name": "\u534e\u4fa8\u5927\u5b66"
    },
    {
        "id": 17102,
        "name": "\u4ef0\u6069\u5927\u5b66"
    },
    {
        "id": 17103,
        "name": "\u6cc9\u5dde\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 17151,
        "name": "\u6f33\u5dde\u5e08\u8303"
    },
    {
        "id": 17201,
        "name": "\u8386\u7530\u5b66\u9662"
    },
    {
        "id": 17251,
        "name": "\u4e09\u660e\u5b66\u9662"
    },
    {
        "id": 17301,
        "name": "\u9f99\u5ca9\u5b66\u9662"
    },
    {
        "id": 17303,
        "name": "\u53a6\u95e8\u5927\u5b66\u5609\u5e9a\u5b66\u9662"
    },
    {
        "id": 17304,
        "name": "\u96c6\u7f8e\u5927\u5b66\u8bda\u6bc5\u5b66\u9662"
    },
    {
        "id": 17305,
        "name": "\u798f\u5dde\u5927\u5b66\u9633\u5149\u5b66\u9662"
    },
    {
        "id": 17306,
        "name": "\u798f\u5dde\u5927\u5b66\u81f3\u8bda\u5b66\u9662"
    },
    {
        "id": 17307,
        "name": "\u798f\u5efa\u5e08\u8303\u5927\u5b66\u534f\u548c\u5b66\u9662"
    },
    {
        "id": 17308,
        "name": "\u798f\u5efa\u5e08\u8303\u5927\u5b66\u95fd\u5357\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 17309,
        "name": "\u798f\u5efa\u519c\u6797\u5927\u5b66\u4e1c\u65b9\u5b66\u9662"
    },
    {
        "id": 17310,
        "name": "\u798f\u5efa\u519c\u6797\u5927\u5b66\u91d1\u5c71\u5b66\u9662"
    },
    {
        "id": 17401,
        "name": "\u798f\u5efa\u7535\u529b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17402,
        "name": "\u798f\u5efa\u5bf9\u5916\u7ecf\u6d4e\u8d38\u6613\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17403,
        "name": "\u798f\u5efa\u8b66\u5bdf\u5b66\u9662"
    },
    {
        "id": 17404,
        "name": "\u798f\u5efa\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 17405,
        "name": "\u798f\u5efa\u534e\u5357\u5973\u5b50\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 17406,
        "name": "\u798f\u5efa\u8239\u653f\u4ea4\u901a\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 17407,
        "name": "\u798f\u5efa\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 17409,
        "name": "\u798f\u5efa\u8b66\u5b98\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 17410,
        "name": "\u798f\u5efa\u6797\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17411,
        "name": "\u798f\u5efa\u519c\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17412,
        "name": "\u798f\u5efa\u5546\u4e1a\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 17413,
        "name": "\u798f\u5efa\u751f\u7269\u5de5\u7a0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17414,
        "name": "\u798f\u5efa\u6c34\u5229\u7535\u529b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17415,
        "name": "\u798f\u5efa\u536b\u751f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17416,
        "name": "\u798f\u5efa\u4fe1\u606f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17418,
        "name": "\u798f\u5efa\u4e2d\u533b\u5b66\u9662\u4e94\u6d32\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 17419,
        "name": "\u798f\u5dde\u6d77\u5ce1\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17420,
        "name": "\u798f\u5dde\u79d1\u6280\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17421,
        "name": "\u798f\u5dde\u9ece\u660e\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17422,
        "name": "\u798f\u5dde\u8f6f\u4ef6\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17424,
        "name": "\u798f\u5dde\u82f1\u534e\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 17425,
        "name": "\u798f\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17426,
        "name": "\u9ece\u660e\u804c\u4e1a\u5927\u5b66"
    },
    {
        "id": 17427,
        "name": "\u6e44\u6d32\u6e7e\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17428,
        "name": "\u95fd\u5317\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17429,
        "name": "\u95fd\u897f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17430,
        "name": "\u6b66\u5937\u5b66\u9662"
    },
    {
        "id": 17432,
        "name": "\u6cc9\u5dde\u7eba\u7ec7\u670d\u88c5\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 17433,
        "name": "\u95fd\u5357\u7406\u5de5\u5b66\u9662"
    },
    {
        "id": 17434,
        "name": "\u6cc9\u5dde\u534e\u5149\u6444\u5f71\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 17435,
        "name": "\u6cc9\u5dde\u7ecf\u8d38\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17436,
        "name": "\u6cc9\u5dde\u4fe1\u606f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17437,
        "name": "\u6cc9\u5dde\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 17438,
        "name": "\u6cc9\u5dde\u7406\u5de5\u5b66\u9662"
    },
    {
        "id": 17439,
        "name": "\u4e09\u660e\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17440,
        "name": "\u53a6\u95e8\u6d77\u6d0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17441,
        "name": "\u53a6\u95e8\u534e\u5929\u6d89\u5916\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17442,
        "name": "\u53a6\u95e8\u534e\u53a6\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 17443,
        "name": "\u53a6\u95e8\u5357\u6d0b\u5b66\u9662"
    },
    {
        "id": 17444,
        "name": "\u53a6\u95e8\u5174\u624d\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17445,
        "name": "\u53a6\u95e8\u6f14\u827a\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 17446,
        "name": "\u6f33\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17447,
        "name": "\u798f\u5efa\u653f\u6cd5\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 17449,
        "name": "\u53a6\u95e8\u57ce\u5e02\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 17550,
        "name": "\u6f33\u5dde\u536b\u751f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 17551,
        "name": "\u6c5f\u590f\u5b66\u9662"
    },
    {
        "id": 17552,
        "name": "\u798f\u5dde\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 17553,
        "name": "\u53a6\u95e8\u5e02\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 17554,
        "name": "\u798f\u5efa\u8d22\u4f1a\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 17555,
        "name": "\u798f\u5efa\u7ecf\u6d4e\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 17556,
        "name": "\u798f\u5efa\u7701\u6f33\u5dde\u4e1a\u4f59\u5927\u5b66"
    },
    {
        "id": 17557,
        "name": "\u9f99\u5ca9\u6280\u5e08\u5b66\u9662"
    },
    {
        "id": 17558,
        "name": "\u5fb7\u5316\u9676\u74f7\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17559,
        "name": "\u53a6\u95e8\u4e1c\u6d77\u5b66\u9662"
    },
    {
        "id": 17560,
        "name": "\u6cc9\u5dde\u513f\u7ae5\u53d1\u5c55\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 17561,
        "name": "\u53a6\u95e8\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 17562,
        "name": "\u798f\u5efa\u513f\u7ae5\u53d1\u5c55\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 17563,
        "name": "\u53a6\u95e8\u8f6f\u4ef6\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17565,
        "name": "\u798f\u5efa\u827a\u672f\u804c\u4e1a\u5b66\u6821"
    },
    {
        "id": 17566,
        "name": "\u6f33\u5dde\u57ce\u5e02\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 17567,
        "name": "\u6f33\u5dde\u5929\u798f\u8336\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17568,
        "name": "\u798f\u5dde\u5916\u8bed\u5916\u8d38\u5b66\u9662"
    },
    {
        "id": 17569,
        "name": "\u798f\u5efa\u7701\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 17570,
        "name": "\u53a6\u95e8\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 17571,
        "name": "\u53a6\u95e8\u5b89\u9632\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 17573,
        "name": "\u6f33\u5dde\u5409\u9a6c\u804c\u4e1a\u5370\u5237\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17574,
        "name": "\u798f\u5efa\u4f53\u80b2\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 17575,
        "name": "\u53a6\u95e8\u5b89\u9632\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 17576,
        "name": "\u53a6\u95e8\u6280\u5e08\u5b66\u9662"
    },
    {
        "id": 17577,
        "name": "\u534e\u4fa8\u5927\u5b66\u53a6\u95e8\u5de5\u5b66\u9662"
    },
    {
        "id": 17578,
        "name": "\u6b66\u5937\u5c71\u804c\u4e1a\u5b66\u9662"
    }],
    "name": "\u798f\u5efa"
},
{
    "id": 18,
    "school": [{
        "id": 18001,
        "name": "\u90d1\u5dde\u5927\u5b66"
    },
    {
        "id": 18002,
        "name": "\u6cb3\u5357\u5de5\u4e1a"
    },
    {
        "id": 18003,
        "name": "\u6cb3\u5357\u519c\u5927"
    },
    {
        "id": 18004,
        "name": "\u534e\u5317\u6c34\u5229\u6c34\u7535"
    },
    {
        "id": 18005,
        "name": "\u90d1\u5dde\u8f7b\u5de5"
    },
    {
        "id": 18006,
        "name": "\u90d1\u5dde\u822a\u7a7a\u5de5\u4e1a"
    },
    {
        "id": 18007,
        "name": "\u9ec4\u6cb3\u79d1\u6280"
    },
    {
        "id": 18008,
        "name": "\u4e2d\u539f\u5de5\u5b66\u9662"
    },
    {
        "id": 18009,
        "name": "\u6cb3\u5357\u4e2d\u533b\u5b66\u9662"
    },
    {
        "id": 18010,
        "name": "\u6cb3\u5357\u8d22\u7ecf\u653f\u6cd5"
    },
    {
        "id": 18011,
        "name": "\u90d1\u5dde\u57ce\u5e02\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 18012,
        "name": "\u65b0\u4e61\u5b66\u9662"
    },
    {
        "id": 18013,
        "name": "\u5f00\u5c01\u6587\u5316\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 18051,
        "name": "\u6cb3\u5357\u5927\u5b66"
    },
    {
        "id": 18101,
        "name": "\u6cb3\u5357\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 18102,
        "name": "\u6d1b\u9633\u5e08\u9662"
    },
    {
        "id": 18151,
        "name": "\u5b89\u9633\u5de5\u5b66\u9662"
    },
    {
        "id": 18152,
        "name": "\u5b89\u9633\u5e08\u9662"
    },
    {
        "id": 18201,
        "name": "\u5357\u9633\u7406\u5de5"
    },
    {
        "id": 18202,
        "name": "\u5357\u9633\u5e08\u9662"
    },
    {
        "id": 18251,
        "name": "\u6cb3\u5357\u57ce\u5efa\u5b66\u9662"
    },
    {
        "id": 18252,
        "name": "\u5e73\u9876\u5c71\u5b66\u9662"
    },
    {
        "id": 18301,
        "name": "\u65b0\u4e61\u533b\u5b66\u9662"
    },
    {
        "id": 18302,
        "name": "\u6cb3\u5357\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 18303,
        "name": "\u6cb3\u5357\u5e08\u5927"
    },
    {
        "id": 18351,
        "name": "\u4fe1\u9633\u5e08\u9662"
    },
    {
        "id": 18401,
        "name": "\u5546\u4e18\u5e08\u9662"
    },
    {
        "id": 18451,
        "name": "\u5468\u53e3\u5e08\u9662"
    },
    {
        "id": 18501,
        "name": "\u9ec4\u6dee\u5b66\u9662"
    },
    {
        "id": 18551,
        "name": "\u8bb8\u660c\u5b66\u9662"
    },
    {
        "id": 18601,
        "name": "\u6cb3\u5357\u7406\u5de5"
    },
    {
        "id": 18701,
        "name": "\u6cb3\u5357\u8d22\u653f\u7a0e\u52a1\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 18702,
        "name": "\u6cb3\u5357\u5de5\u7a0b\u5b66\u9662"
    },
    {
        "id": 18703,
        "name": "\u6cb3\u5357\u5de5\u4e1a\u8d38\u6613\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 18704,
        "name": "\u6cb3\u5357\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 18705,
        "name": "\u6cb3\u5357\u8b66\u5bdf\u5b66\u9662"
    },
    {
        "id": 18706,
        "name": "\u6cb3\u5357\u5e7f\u64ad\u5f71\u89c6\u5b66\u9662"
    },
    {
        "id": 18707,
        "name": "\u6cb3\u5357\u673a\u7535\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 18708,
        "name": "\u6cb3\u5357\u68c0\u5bdf\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 18709,
        "name": "\u6cb3\u5357\u4ea4\u901a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 18710,
        "name": "\u6cb3\u5357\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 18711,
        "name": "\u6cb3\u5357\u7ecf\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 18712,
        "name": "\u6cb3\u5357\u519c\u4e1a\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 18713,
        "name": "\u6cb3\u5357\u5546\u4e1a\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 18714,
        "name": "\u6cb3\u5357\u7701\u5de5\u5546\u884c\u653f\u7ba1\u7406\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 18716,
        "name": "\u6cb3\u5357\u53f8\u6cd5\u8b66\u5b98\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 18717,
        "name": "\u6cb3\u5357\u65b0\u534e\u7535\u8111\u5b66\u9662"
    },
    {
        "id": 18718,
        "name": "\u6cb3\u5357\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 18719,
        "name": "\u6cb3\u5357\u8d28\u91cf\u5de5\u7a0b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 18720,
        "name": "\u9e64\u58c1\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 18721,
        "name": "\u9ec4\u6cb3\u6c34\u5229\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 18722,
        "name": "\u6d4e\u6e90\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 18723,
        "name": "\u7126\u4f5c\u5927\u5b66"
    },
    {
        "id": 18724,
        "name": "\u7126\u4f5c\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 18725,
        "name": "\u5f00\u5c01\u5927\u5b66"
    },
    {
        "id": 18726,
        "name": "\u5f00\u5c01\u5e02\u7535\u5b50\u79d1\u6280\u4e13\u4fee\u5b66\u6821"
    },
    {
        "id": 18727,
        "name": "\u6d1b\u9633\u5927\u5b66"
    },
    {
        "id": 18728,
        "name": "\u6d1b\u9633\u7406\u5de5\u5b66\u9662"
    },
    {
        "id": 18729,
        "name": "\u6f2f\u6cb3\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 18730,
        "name": "\u6f2f\u6cb3\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 18731,
        "name": "\u5357\u9633\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 18732,
        "name": "\u5e73\u9876\u5c71\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 18733,
        "name": "\u5e73\u9876\u5c71\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 18735,
        "name": "\u6fee\u9633\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 18736,
        "name": "\u4e09\u95e8\u5ce1\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 18737,
        "name": "\u5546\u4e18\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 18738,
        "name": "\u5546\u4e18\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 18739,
        "name": "\u5546\u4e18\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 18740,
        "name": "\u5d69\u5c71\u5c11\u6797\u6b66\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 18741,
        "name": "\u94c1\u9053\u8b66\u5b98\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 18744,
        "name": "\u4fe1\u9633\u519c\u4e1a\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 18745,
        "name": "\u4fe1\u9633\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 18746,
        "name": "\u8bb8\u660c\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 18747,
        "name": "\u6c38\u57ce\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 18748,
        "name": "\u90d1\u5dde\u5927\u5b66\u897f\u4e9a\u65af\u56fd\u9645\u5b66\u9662"
    },
    {
        "id": 18749,
        "name": "\u90d1\u5dde\u7535\u529b\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 18750,
        "name": "\u90d1\u5dde\u7535\u5b50\u4fe1\u606f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 18751,
        "name": "\u90d1\u5dde\u5de5\u4e1a\u5b89\u5168\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 18752,
        "name": "\u90d1\u5dde\u534e\u4fe1\u5b66\u9662"
    },
    {
        "id": 18753,
        "name": "\u90d1\u5dde\u4ea4\u901a\u5b66\u9662"
    },
    {
        "id": 18754,
        "name": "\u90d1\u5dde\u7ecf\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 18755,
        "name": "\u90d1\u5dde\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 18756,
        "name": "\u90d1\u5dde\u65c5\u6e38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 18757,
        "name": "\u90d1\u5dde\u7267\u4e1a\u5de5\u7a0b\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 18758,
        "name": "\u90d1\u5dde\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 18759,
        "name": "\u90d1\u5dde\u6f8d\u9752\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 18760,
        "name": "\u90d1\u5dde\u94c1\u8def\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 18761,
        "name": "\u90d1\u5dde\u4fe1\u606f\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 18762,
        "name": "\u90d1\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 18763,
        "name": "\u4e2d\u5dde\u5927\u5b66"
    },
    {
        "id": 18764,
        "name": "\u5468\u53e3\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 18765,
        "name": "\u90d1\u5dde\u5347\u8fbe\u7ecf\u8d38\u7ba1\u7406\u5b66\u9662"
    },
    {
        "id": 18767,
        "name": "\u6d1b\u9633\u5de5\u4e1a\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 18768,
        "name": "\u6cb3\u5357\u804c\u5de5\u533b\u5b66\u9662"
    },
    {
        "id": 18769,
        "name": "\u6cb3\u5357\u79d1\u6280\u5b66\u9662\u65b0\u79d1\u5b66\u9662"
    },
    {
        "id": 18770,
        "name": "\u6cb3\u5357\u7406\u5de5\u5927\u5b66\u4e07\u65b9\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 18771,
        "name": "\u4e2d\u539f\u5de5\u5b66\u9662\u4fe1\u606f\u5546\u52a1\u5b66\u9662"
    },
    {
        "id": 18772,
        "name": "\u5b89\u9633\u5e08\u8303\u5b66\u9662\u4eba\u6587\u7ba1\u7406\u5b66\u9662"
    },
    {
        "id": 18773,
        "name": "\u5546\u4e18\u5b66\u9662"
    },
    {
        "id": 18774,
        "name": "\u6cb3\u5357\u8d22\u7ecf\u5b66\u9662\u6210\u529f\u5b66\u9662"
    },
    {
        "id": 18775,
        "name": "\u5f00\u5c01\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 18777,
        "name": "\u6cb3\u5357\u536b\u751f\u804c\u5de5\u5b66\u9662"
    },
    {
        "id": 18778,
        "name": "\u6cb3\u5357\u5efa\u7b51\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 18779,
        "name": "\u6cb3\u5357\u5927\u5b66\u6c11\u751f\u5b66\u9662"
    },
    {
        "id": 18780,
        "name": "\u6cb3\u5357\u5e08\u8303\u5927\u5b66\u65b0\u8054\u5b66\u9662"
    },
    {
        "id": 18781,
        "name": "\u65b0\u4e61\u533b\u5b66\u9662\u4e09\u5168\u5b66\u9662"
    },
    {
        "id": 18782,
        "name": "\u4fe1\u9633\u5e08\u8303\u5b66\u9662\u534e\u9510\u5b66\u9662"
    },
    {
        "id": 18793,
        "name": "\u90d1\u5dde\u7535\u529b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 18798,
        "name": "\u5468\u53e3\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 18979,
        "name": "\u6cb3\u5357\u7701\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 18980,
        "name": "\u4e2d\u56fd\u4eba\u6c11\u89e3\u653e\u519b\u5916\u56fd\u8bed\u5b66\u9662"
    },
    {
        "id": 18981,
        "name": "\u6e05\u534eIT\u6cb3\u5357\u6821\u533a"
    },
    {
        "id": 18983,
        "name": "\u7b2c\u4e00\u62d6\u62c9\u673a\u5236\u9020\u5382\u62d6\u62c9\u673a\u5b66\u9662"
    },
    {
        "id": 18984,
        "name": "\u90d1\u5dde\u7164\u70ad\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 18985,
        "name": "\u6cb3\u5357\u5de5\u4e1a\u5927\u5b66\u5316\u5b66\u5de5\u4e1a\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 18991,
        "name": "\u6cb3\u5357\u7701\u8f7b\u5de5\u4e1a\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 18994,
        "name": "\u90d1\u5dde\u4ea4\u901a\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 18995,
        "name": "\u90d1\u5dde\u7267\u4e1a\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 18996,
        "name": "\u90d1\u5dde\u5e03\u745e\u8fbe\u7406\u5de5\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 18997,
        "name": "\u6cb3\u5357\u533b\u79d1\u5927\u5b66\u6559\u80b2\u4e2d\u5fc3"
    },
    {
        "id": 18999,
        "name": "\u90d1\u5dde\u8f7b\u5de5\u4e1a\u8f7b\u5de5\u804c\u4e1a\u5b66\u9662"
    }],
    "name": "\u6cb3\u5357"
},
{
    "id": 19,
    "school": [{
        "id": 19001,
        "name": "\u91cd\u5e86\u5927\u5b66"
    },
    {
        "id": 19002,
        "name": "\u897f\u5357\u5927\u5b66"
    },
    {
        "id": 19003,
        "name": "\u91cd\u5e86\u5e08\u5927"
    },
    {
        "id": 19004,
        "name": "\u897f\u5357\u653f\u6cd5"
    },
    {
        "id": 19005,
        "name": "\u91cd\u5e86\u4ea4\u5927"
    },
    {
        "id": 19006,
        "name": "\u91cd\u5e86\u90ae\u7535\u5927\u5b66"
    },
    {
        "id": 19007,
        "name": "\u91cd\u5e86\u533b\u5927"
    },
    {
        "id": 19008,
        "name": "\u91cd\u5e86\u5de5\u5546"
    },
    {
        "id": 19009,
        "name": "\u91cd\u5e86\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 19010,
        "name": "\u91cd\u5e86\u7406\u5de5\u5927\u5b66"
    },
    {
        "id": 19011,
        "name": "\u957f\u6c5f\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 19012,
        "name": "\u56db\u5ddd\u5916\u56fd\u8bed"
    },
    {
        "id": 19013,
        "name": "\u56db\u5ddd\u7f8e\u9662"
    },
    {
        "id": 19014,
        "name": "\u91cd\u5e86\u4e09\u5ce1\u5b66\u9662"
    },
    {
        "id": 19015,
        "name": "\u91cd\u5e86\u6587\u7406"
    },
    {
        "id": 19016,
        "name": "\u91cd\u5e86\u5316\u5de5\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 19017,
        "name": "\u540e\u52e4\u5de5\u7a0b\u5b66\u9662"
    },
    {
        "id": 19018,
        "name": "\u91cd\u5e86\u80fd\u6e90\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 19019,
        "name": "\u91cd\u5e86\u5546\u52a1\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 19020,
        "name": "\u91cd\u5e86\u65c5\u6e38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 19021,
        "name": "\u91cd\u5e86\u4e94\u4e00\u9ad8\u7ea7\u6280\u5e08\u5b66\u9662"
    },
    {
        "id": 19022,
        "name": "\u91cd\u5e86\u901a\u4fe1\u5b66\u9662\u00a0"
    },
    {
        "id": 19023,
        "name": "\u91cd\u5e86\u8f7b\u5de5\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 19024,
        "name": "\u91cd\u5e86\u7ecf\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 19025,
        "name": "\u91cd\u5e86\u516c\u5171\u8fd0\u8f93\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 19102,
        "name": "\u91cd\u5e86\u623f\u5730\u4ea7\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 19103,
        "name": "\u91cd\u5e86\u57ce\u5e02\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 19104,
        "name": "\u91cd\u5e86\u7535\u529b\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 19105,
        "name": "\u91cd\u5e86\u7535\u5b50\u5de5\u7a0b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 19106,
        "name": "\u91cd\u5e86\u822a\u5929\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 19107,
        "name": "\u91cd\u5e86\u5de5\u7a0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 19108,
        "name": "\u91cd\u5e86\u5de5\u5546\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 19109,
        "name": "\u91cd\u5e86\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 19110,
        "name": "\u91cd\u5e86\u4f20\u5a92\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 19111,
        "name": "\u91cd\u5e86\u6d77\u8054\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 19112,
        "name": "\u91cd\u5e86\u673a\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 19113,
        "name": "\u91cd\u5e86\u8b66\u5b98\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 19114,
        "name": "\u91cd\u5e86\u6c11\u751f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 19115,
        "name": "\u91cd\u5e86\u4e09\u5ce1\u533b\u836f\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 19116,
        "name": "\u91cd\u5e86\u4e09\u5ce1\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 19118,
        "name": "\u91cd\u5e86\u6c34\u5229\u7535\u529b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 19119,
        "name": "\u91cd\u5e86\u4fe1\u606f\u6280\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 19120,
        "name": "\u91cd\u5e86\u533b\u836f\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 19121,
        "name": "\u91cd\u5e86\u6b63\u5927\u8f6f\u4ef6\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 19122,
        "name": "\u91cd\u5e86\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 19123,
        "name": "\u91cd\u5e86\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 19124,
        "name": "\u91cd\u5e86\u5e94\u7528\u5916\u56fd\u8bed\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 19126,
        "name": "\u91cd\u5e86\u5927\u5b66\u57ce\u5e02\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 19128,
        "name": "\u897f\u5357\u5927\u5b66\u80b2\u624d\u5b66\u9662"
    },
    {
        "id": 19130,
        "name": "\u56db\u5ddd\u5916\u8bed\u5b66\u9662\u91cd\u5e86\u5357\u65b9\u7ffb\u8bd1\u5b66\u9662"
    },
    {
        "id": 19132,
        "name": "\u91cd\u5e86\u5e08\u8303\u5927\u5b66\u6d89\u5916\u5546\u8d38\u5b66\u9662"
    },
    {
        "id": 19134,
        "name": "\u91cd\u5e86\u5de5\u5546\u5927\u5b66\u878d\u667a\u5b66\u9662"
    },
    {
        "id": 19136,
        "name": "\u91cd\u5e86\u5de5\u5546\u5927\u5b66\u6d3e\u65af\u5b66\u9662"
    },
    {
        "id": 19138,
        "name": "\u91cd\u5e86\u90ae\u7535\u5927\u5b66\u79fb\u901a\u5b66\u9662"
    },
    {
        "id": 19140,
        "name": "\u91cd\u5e86\u5de5\u8d38\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 19142,
        "name": "\u91cd\u5e86\u9752\u5e74\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 19144,
        "name": "\u91cd\u5e86\u57ce\u5e02\u7ba1\u7406\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 19145,
        "name": "\u91cd\u5e86\u8d22\u7ecf\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 19146,
        "name": "\u91cd\u5e86\u79d1\u521b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 19147,
        "name": "\u91cd\u5e86\u5efa\u7b51\u5de5\u7a0b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 19148,
        "name": "\u91cd\u5e86\u4e94\u4e00\u6280\u5e08\u5b66\u9662"
    },
    {
        "id": 19150,
        "name": "\u91cd\u5e86\u901a\u4fe1\u5b66\u9662"
    },
    {
        "id": 19151,
        "name": "\u897f\u5357\u5927\u5b66\u5e94\u7528\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 19152,
        "name": "\u91cd\u5e86\u4ea4\u901a\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 19153,
        "name": "\u91cd\u5e86\u7535\u8baf\u804c\u4e1a\u5b66\u9662"
    }],
    "name": "\u91cd\u5e86"
},
{
    "id": 20,
    "school": [{
        "id": 20001,
        "name": "\u6606\u660e\u7406\u5de5"
    },
    {
        "id": 20002,
        "name": "\u4e91\u5357\u519c\u5927"
    },
    {
        "id": 20003,
        "name": "\u4e91\u5357\u5e08\u5927"
    },
    {
        "id": 20004,
        "name": "\u4e91\u5357\u8d22\u7ecf"
    },
    {
        "id": 20005,
        "name": "\u4e91\u5357\u6c11\u5927"
    },
    {
        "id": 20006,
        "name": "\u897f\u5357\u6797\u4e1a\u5927\u5b66"
    },
    {
        "id": 20007,
        "name": "\u6606\u660e\u533b\u5b66\u9662"
    },
    {
        "id": 20008,
        "name": "\u4e91\u5357\u4e2d\u533b\u5b66\u9662"
    },
    {
        "id": 20009,
        "name": "\u7ea2\u6cb3\u5b66\u9662"
    },
    {
        "id": 20010,
        "name": "\u4e91\u5357\u8b66\u5b98\u5b66\u9662"
    },
    {
        "id": 20011,
        "name": "\u4e91\u5357\u827a\u672f"
    },
    {
        "id": 20012,
        "name": "\u4e91\u5357\u5927\u5b66"
    },
    {
        "id": 20013,
        "name": "\u4e91\u5357\u5de5\u5546\u5b66\u9662"
    },
    {
        "id": 20014,
        "name": "\u4e91\u5357\u8d22\u8d38\u5916\u4e8b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 20015,
        "name": "\u4e91\u5357\u7ecf\u8d38\u5916\u4e8b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 20016,
        "name": "\u5927\u7406\u533b\u5b66\u9662"
    },
    {
        "id": 20017,
        "name": "\u4e91\u5357\u6c11\u65cf\u5927\u5b66\u6587\u5316\u5b66\u9662"
    },
    {
        "id": 20051,
        "name": "\u66f2\u9756\u5e08\u8303"
    },
    {
        "id": 20101,
        "name": "\u7389\u6eaa\u5e08\u9662"
    },
    {
        "id": 20151,
        "name": "\u695a\u96c4\u5e08\u9662"
    },
    {
        "id": 20201,
        "name": "\u5927\u7406\u5b66\u9662"
    },
    {
        "id": 20301,
        "name": "\u4fdd\u5c71\u5b66\u9662"
    },
    {
        "id": 20302,
        "name": "\u4fdd\u5c71\u4e2d\u533b\u836f\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 20303,
        "name": "\u695a\u96c4\u533b\u836f\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 20304,
        "name": "\u5fb7\u5b8f\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 20305,
        "name": "\u4e91\u5357\u5927\u5b66\u6ec7\u6c60\u5b66\u9662"
    },
    {
        "id": 20306,
        "name": "\u6606\u660e\u5927\u5b66"
    },
    {
        "id": 20307,
        "name": "\u6606\u660e\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 20308,
        "name": "\u6606\u660e\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 20309,
        "name": "\u4e91\u5357\u5de5\u7a0b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 20310,
        "name": "\u6606\u660e\u51b6\u91d1\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 20311,
        "name": "\u6606\u660e\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 20312,
        "name": "\u4e3d\u6c5f\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 20313,
        "name": "\u66f2\u9756\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 20314,
        "name": "\u601d\u8305\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 20315,
        "name": "\u6587\u5c71\u5b66\u9662"
    },
    {
        "id": 20316,
        "name": "\u897f\u53cc\u7248\u7eb3\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 20317,
        "name": "\u7389\u6eaa\u519c\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 20318,
        "name": "\u4e91\u5357\u7231\u56e0\u68ee\u8f6f\u4ef6\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 20319,
        "name": "\u4e91\u5357\u5317\u7f8e\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 20321,
        "name": "\u4e91\u5357\u56fd\u9632\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 20322,
        "name": "\u4e91\u5357\u56fd\u571f\u8d44\u6e90\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 20323,
        "name": "\u4e91\u5357\u673a\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 20324,
        "name": "\u4e91\u5357\u4ea4\u901a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 20326,
        "name": "\u4e91\u5357\u7ecf\u6d4e\u7ba1\u7406\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 20327,
        "name": "\u4e91\u5357\u79d1\u6280\u4fe1\u606f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 20328,
        "name": "\u4e91\u5357\u6797\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 20329,
        "name": "\u4e91\u5357\u80fd\u6e90\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 20330,
        "name": "\u4e91\u5357\u519c\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 20331,
        "name": "\u4e91\u5357\u70ed\u5e26\u4f5c\u7269\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 20332,
        "name": "\u4e91\u5357\u7701\u6797\u4e1a\u79d1\u5b66\u9662"
    },
    {
        "id": 20333,
        "name": "\u4e91\u5357\u5e08\u8303\u5927\u5b66\u5546\u5b66\u9662"
    },
    {
        "id": 20334,
        "name": "\u4e91\u5357\u53f8\u6cd5\u8b66\u5b98\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 20335,
        "name": "\u4e91\u5357\u4f53\u80b2\u8fd0\u52a8\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 20336,
        "name": "\u4e91\u5357\u6587\u5316\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 20337,
        "name": "\u4e91\u5357\u65b0\u5174\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 20338,
        "name": "\u4e91\u5357\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 20339,
        "name": "\u4e91\u5357\u662d\u901a\u5e08\u8303\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 20342,
        "name": "\u6606\u660e\u7406\u5de5\u5927\u5b66\u6d25\u6865\u5b66\u9662"
    },
    {
        "id": 20343,
        "name": "\u4e91\u5357\u5e08\u8303\u5927\u5b66\u6587\u7406\u5b66\u9662"
    },
    {
        "id": 20344,
        "name": "\u6606\u660e\u533b\u5b66\u9662\u6d77\u6e90\u5b66\u9662"
    },
    {
        "id": 20345,
        "name": "\u4e91\u5357\u827a\u672f\u5b66\u9662\u6587\u534e\u5b66\u9662"
    },
    {
        "id": 20346,
        "name": "\u4e91\u5357\u5927\u5b66\u65c5\u6e38\u6587\u5316\u5b66\u9662"
    },
    {
        "id": 20347,
        "name": "\u4e34\u6ca7\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 20348,
        "name": "\u4e91\u5357\u9521\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 20349,
        "name": "\u4e91\u5357\u79d1\u6280\u4fe1\u606f\u804c\u4e1a\u5b66\u9662"
    }],
    "name": "\u4e91\u5357"
},
{
    "id": 21,
    "school": [{
        "id": 21001,
        "name": "\u6cb3\u5317\u5927\u5b66"
    },
    {
        "id": 21002,
        "name": "\u6cb3\u5317\u519c\u5927"
    },
    {
        "id": 21003,
        "name": "\u4e2d\u592e\u53f8\u6cd5\u8b66\u5b98\u5b66\u9662"
    },
    {
        "id": 21004,
        "name": "\u77f3\u5bb6\u5e84\u94c1\u9053\u5927\u5b66"
    },
    {
        "id": 21005,
        "name": "\u77f3\u5bb6\u5e84\u7ecf\u9662"
    },
    {
        "id": 21006,
        "name": "\u6cb3\u5317\u4eac\u5b89\u5b66\u9662"
    },
    {
        "id": 21007,
        "name": "\u6cca\u5934\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21008,
        "name": "\u6cb3\u5317\u8d22\u7ecf\u5b66\u9662"
    },
    {
        "id": 21009,
        "name": "\u77f3\u5bb6\u5e84\u5e7c\u513f\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 21051,
        "name": "\u6cb3\u5317\u5e08\u5927"
    },
    {
        "id": 21052,
        "name": "\u6cb3\u5317\u79d1\u6280"
    },
    {
        "id": 21053,
        "name": "\u6cb3\u5317\u533b\u79d1"
    },
    {
        "id": 21054,
        "name": "\u6cb3\u5317\u7ecf\u8d38"
    },
    {
        "id": 21055,
        "name": "\u6cb3\u5317\u4f53\u9662"
    },
    {
        "id": 21056,
        "name": "\u77f3\u5bb6\u5e84\u5b66\u9662"
    },
    {
        "id": 21101,
        "name": "\u71d5\u5c71\u5927\u5b66"
    },
    {
        "id": 21102,
        "name": "\u6cb3\u5317\u79d1\u5e08"
    },
    {
        "id": 21151,
        "name": "\u6cb3\u5317\u8054\u5408\u5927\u5b66"
    },
    {
        "id": 21152,
        "name": "\u534e\u5317\u7164\u70ad\u533b\u5b66"
    },
    {
        "id": 21153,
        "name": "\u5510\u5c71\u5b66\u9662"
    },
    {
        "id": 21159,
        "name": "\u5510\u5c71\u5e08\u8303"
    },
    {
        "id": 21202,
        "name": "\u5317\u534e\u822a\u5929\u5de5\u4e1a\u5b66\u9662"
    },
    {
        "id": 21203,
        "name": "\u5eca\u574a\u5e08\u8303"
    },
    {
        "id": 21251,
        "name": "\u9632\u707e\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 21252,
        "name": "\u534e\u5317\u79d1\u6280"
    },
    {
        "id": 21301,
        "name": "\u6cb3\u5317\u5efa\u5de5"
    },
    {
        "id": 21302,
        "name": "\u5317\u65b9\u5b66\u9662"
    },
    {
        "id": 21351,
        "name": "\u627f\u5fb7\u533b\u5b66\u9662"
    },
    {
        "id": 21401,
        "name": "\u90a2\u53f0\u5b66\u9662"
    },
    {
        "id": 21451,
        "name": "\u6cb3\u5317\u5de5\u7a0b"
    },
    {
        "id": 21452,
        "name": "\u90af\u90f8\u5b66\u9662"
    },
    {
        "id": 21501,
        "name": "\u8861\u6c34\u5b66\u9662"
    },
    {
        "id": 21601,
        "name": "\u4fdd\u5b9a\u7535\u529b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21602,
        "name": "\u4fdd\u5b9a\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21603,
        "name": "\u6cb3\u5317\u91d1\u878d\u5b66\u9662"
    },
    {
        "id": 21604,
        "name": "\u4fdd\u5b9a\u5b66\u9662"
    },
    {
        "id": 21605,
        "name": "\u4fdd\u5b9a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21606,
        "name": "\u6e24\u6d77\u77f3\u6cb9\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21607,
        "name": "\u6ca7\u5dde\u5e08\u8303\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 21608,
        "name": "\u6ca7\u5dde\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 21609,
        "name": "\u6ca7\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21610,
        "name": "\u6cb3\u5317\u65c5\u6e38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21611,
        "name": "\u6cb3\u5317\u6c11\u65cf\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 21612,
        "name": "\u627f\u5fb7\u77f3\u6cb9\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 21615,
        "name": "\u4e1c\u5317\u5927\u5b66\u79e6\u7687\u5c9b\u6821\u533a"
    },
    {
        "id": 21616,
        "name": "\u90af\u90f8\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21617,
        "name": "\u6cb3\u5317\u5927\u5b66\u533b\u5b66\u90e8"
    },
    {
        "id": 21618,
        "name": "\u6cb3\u5317\u5de5\u7a0b\u6280\u672f\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 21619,
        "name": "\u6cb3\u5317\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21620,
        "name": "\u6cb3\u5317\u516c\u5b89\u8b66\u5bdf\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21621,
        "name": "\u6cb3\u5317\u5316\u5de5\u533b\u836f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21622,
        "name": "\u6cb3\u5317\u673a\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21623,
        "name": "\u6cb3\u5317\u5efa\u6750\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21624,
        "name": "\u6cb3\u5317\u4ea4\u901a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21625,
        "name": "\u6cb3\u5317\u4eac\u90fd\u9ad8\u5c14\u592b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21626,
        "name": "\u6cb3\u5317\u519c\u4e1a\u5927\u5b66"
    },
    {
        "id": 21627,
        "name": "\u6cb3\u5317\u519c\u4e1a\u5927\u5b66\u6d77\u6d0b\u5b66\u9662"
    },
    {
        "id": 21628,
        "name": "\u6cb3\u5317\u5973\u5b50\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21629,
        "name": "\u6cb3\u5317\u8f6f\u4ef6\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21630,
        "name": "\u6cb3\u5317\u7701\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21631,
        "name": "\u6cb3\u5317\u77f3\u6cb9\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21632,
        "name": "\u6cb3\u5317\u53f8\u6cd5\u8b66\u5b98\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21633,
        "name": "\u6cb3\u5317\u901a\u4fe1\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21634,
        "name": "\u6cb3\u5317\u8fdc\u4e1c\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21635,
        "name": "\u6cb3\u5317\u653f\u6cd5\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 21636,
        "name": "\u6cb3\u5317\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21637,
        "name": "\u8861\u6c34\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21638,
        "name": "\u534e\u5317\u7535\u529b\u5927\u5b66\uff08\u4fdd\u5b9a\uff09"
    },
    {
        "id": 21639,
        "name": "\u76d1\u7763\u7ba1\u7406\u5c40"
    },
    {
        "id": 21642,
        "name": "\u5eca\u574a\u5927\u5b66\u57ce\u5317\u5927\u65b9\u6b63\u8f6f\u4ef6\u5b66\u9662"
    },
    {
        "id": 21643,
        "name": "\u5eca\u574a\u4e1c\u65b9\u5927\u5b66\u57ce\u5317\u4eac\u6fb3\u9645\u8054\u90a6\u82f1\u8bed\u5b66\u6821"
    },
    {
        "id": 21644,
        "name": "\u5eca\u574a\u4e1c\u65b9\u5927\u5b66\u57ce\u5317\u4eac\u8d22\u7ecf\u5b66\u9662"
    },
    {
        "id": 21645,
        "name": "\u5eca\u574a\u4e1c\u65b9\u5927\u5b66\u57ce\u5317\u4eac\u57ce\u5e02\u5b66\u9662"
    },
    {
        "id": 21646,
        "name": "\u5eca\u574a\u4e1c\u65b9\u5927\u5b66\u57ce\u5317\u4eac\u4f20\u5a92\u5b66\u9662"
    },
    {
        "id": 21647,
        "name": "\u5eca\u574a\u4e1c\u65b9\u5927\u5b66\u57ce\u5317\u4eac\u7ecf\u6d4e\u6280\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21648,
        "name": "\u5eca\u574a\u4e1c\u65b9\u5927\u5b66\u57ce\u5317\u4eac\u7ecf\u8d38\u804c\u4e1a\u5b66\u9662\u822a\u7a7a\u670d\u52a1\u5b66\u9662"
    },
    {
        "id": 21649,
        "name": "\u5eca\u574a\u4e1c\u65b9\u5927\u5b66\u57ce\u5317\u4eac\u8054\u5408\u5927\u5b66"
    },
    {
        "id": 21651,
        "name": "\u5eca\u574a\u4e1c\u65b9\u5927\u5b66\u57ce\u6cb3\u5317\u4f53\u80b2\u5b66\u9662"
    },
    {
        "id": 21652,
        "name": "\u5eca\u574a\u4e1c\u65b9\u5927\u5b66\u57ce\u5eca\u574a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21653,
        "name": "\u79e6\u7687\u5c9b\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 21654,
        "name": "\u6cb3\u5317\u5916\u56fd\u8bed\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21655,
        "name": "\u79e6\u7687\u5c9b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21656,
        "name": "\u6cb3\u5317\u7f8e\u672f\u5b66\u9662"
    },
    {
        "id": 21657,
        "name": "\u77f3\u5bb6\u5e84\u6cd5\u5546\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21658,
        "name": "\u77f3\u5bb6\u5e84\u5de5\u5546\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21659,
        "name": "\u77f3\u5bb6\u5e84\u8ba1\u7b97\u673a\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21660,
        "name": "\u77f3\u5bb6\u5e84\u79d1\u6280\u4fe1\u606f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21661,
        "name": "\u77f3\u5bb6\u5e84\u7406\u5de5\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21662,
        "name": "\u77f3\u5bb6\u5e84\u94c1\u8def\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21663,
        "name": "\u77f3\u5bb6\u5e84\u5916\u56fd\u8bed\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21664,
        "name": "\u77f3\u5bb6\u5e84\u5916\u7ecf\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21665,
        "name": "\u77f3\u5bb6\u5e84\u5916\u8bed\u7ffb\u8bd1\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21666,
        "name": "\u77f3\u5bb6\u5e84\u4fe1\u606f\u5de5\u7a0b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21667,
        "name": "\u77f3\u5bb6\u5e84\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 21668,
        "name": "\u6cb3\u5317\u4f20\u5a92\u5b66\u9662"
    },
    {
        "id": 21669,
        "name": "\u77f3\u5bb6\u5e84\u90ae\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21670,
        "name": "\u77f3\u5bb6\u5e84\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21671,
        "name": "\u5510\u5c71\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21672,
        "name": "\u5510\u5c71\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 21673,
        "name": "\u5510\u5c71\u79d1\u6280\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21674,
        "name": "\u5510\u5c71\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21675,
        "name": "\u90a2\u53f0\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 21676,
        "name": "\u90a2\u53f0\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21677,
        "name": "\u5f20\u5bb6\u53e3\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21678,
        "name": "\u4e2d\u56fd\u5730\u8d28\u5927\u5b66\u957f\u57ce\u5b66\u9662"
    },
    {
        "id": 21679,
        "name": "\u4e2d\u56fd\u73af\u5883\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 21680,
        "name": "\u4e2d\u56fd\u6c11\u822a\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 21681,
        "name": "\u5f20\u5bb6\u53e3\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 21682,
        "name": "\u6cb3\u5317\u80fd\u6e90\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21683,
        "name": "\u627f\u5fb7\u536b\u751f\u5b66\u6821"
    },
    {
        "id": 21684,
        "name": "\u90af\u90f8\u4e2d\u539f\u5916\u56fd\u8bed\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21685,
        "name": "\u6cb3\u5317\u5e08\u8303\u5927\u5b66\u6c47\u534e\u5b66\u9662"
    },
    {
        "id": 21686,
        "name": "\u6cb3\u5317\u8054\u5408\u5927\u5b66\u5180\u5510\u5b66\u9662"
    },
    {
        "id": 21687,
        "name": "\u6cb3\u5317\u533b\u79d1\u5927\u5b66\u4e34\u5e8a\u5b66\u9662"
    },
    {
        "id": 21688,
        "name": "\u6cb3\u5317\u519c\u4e1a\u5927\u5b66\u73b0\u4ee3\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 21689,
        "name": "\u77f3\u5bb6\u5e84\u94c1\u9053\u5b66\u9662\u56db\u65b9\u5b66\u9662"
    },
    {
        "id": 21690,
        "name": "\u71d5\u5c71\u5927\u5b66\u91cc\u4ec1\u5b66\u9662"
    },
    {
        "id": 21691,
        "name": "\u6cb3\u5317\u5de5\u7a0b\u5927\u5b66\u79d1\u4fe1\u5b66\u9662"
    },
    {
        "id": 21692,
        "name": "\u6cb3\u5317\u8054\u5408\u5927\u5b66\u8f7b\u5de5\u5b66\u9662"
    },
    {
        "id": 21693,
        "name": "\u6cb3\u5317\u5927\u5b66\u5de5\u5546\u5b66\u9662"
    },
    {
        "id": 21694,
        "name": "\u6cb3\u5317\u7ecf\u8d38\u5927\u5b66\u7ecf\u6d4e\u7ba1\u7406\u5b66\u9662"
    },
    {
        "id": 21695,
        "name": "\u5eca\u574a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21696,
        "name": "\u77f3\u5bb6\u5e84\u5916\u4e8b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21697,
        "name": "\u6cb3\u5317\u653f\u6cd5\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21698,
        "name": "\u5180\u4e2d\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21699,
        "name": "\u77f3\u5bb6\u5e84\u79d1\u6280\u5de5\u7a0b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21701,
        "name": "\u6cb3\u5317\u52b3\u52a8\u5173\u7cfb\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21704,
        "name": "\u6cb3\u5317\u884c\u653f\u5b66\u9662"
    },
    {
        "id": 21705,
        "name": "\u534e\u5317\u7164\u70ad\u533b\u5b66\u9662"
    },
    {
        "id": 21706,
        "name": "\u8d63\u5dde\u4e1c\u65b9\u5b66\u6821"
    },
    {
        "id": 21707,
        "name": "\u5317\u4eac\u4e2d\u533b\u836f\u5927\u5b66\u4e1c\u65b9\u5b66\u9662"
    },
    {
        "id": 21708,
        "name": "\u627f\u5fb7\u71d5\u5317\u804c\u4e1a\u6280\u672f\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 21709,
        "name": "\u6cb3\u5317\u5de5\u4e1a\u5927\u5b66\u5eca\u574a\u5206\u9662"
    },
    {
        "id": 21712,
        "name": "\u77f3\u5bb6\u5e84\u94c1\u8def\u5de5\u7a0b\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21713,
        "name": "\u77f3\u5bb6\u5e84\u7ecf\u6d4e\u5b66\u9662"
    },
    {
        "id": 21714,
        "name": "\u77f3\u5bb6\u5e84\u7ecf\u6d4e\u5b66\u9662\u534e\u4fe1\u5b66\u9662"
    },
    {
        "id": 21716,
        "name": "\u4e2d\u56fd\u8f7b\u5de5\u4e1a\u5e72\u90e8\u7ba1\u7406\u5b66\u9662"
    },
    {
        "id": 21717,
        "name": "\u6cb3\u5317\u5ba3\u5316\u901a\u4fe1\u58eb\u5b98\u5b66\u6821"
    },
    {
        "id": 21718,
        "name": "\u627f\u5fb7\u6c11\u65cf\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 21719,
        "name": "\u6cb3\u5317\u7701\u4fdd\u5b9a\u5e02\u8d22\u8d38\u5b66\u6821"
    },
    {
        "id": 21720,
        "name": "\u5ba3\u5316\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 21721,
        "name": "\u6cb3\u5317\u9752\u5e74\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 21722,
        "name": "\u5317\u4eac\u4ea4\u901a\u5927\u5b66\u6d77\u6ee8\u5b66\u9662"
    },
    {
        "id": 21723,
        "name": "\u77f3\u5bb6\u5e84\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 21725,
        "name": "\u6cb3\u5317\u5730\u8d28\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 21726,
        "name": "\u6cb3\u5317\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 21727,
        "name": "\u4fdd\u5b9a\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 21728,
        "name": "\u6cb3\u5317\u5e7f\u64ad\u7535\u89c6\u5927\u5b66\u9ad8\u7b49\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    }],
    "name": "\u6cb3\u5317"
},
{
    "id": 22,
    "school": [{
        "id": 22001,
        "name": "\u6c5f\u897f\u8d22\u7ecf"
    },
    {
        "id": 22002,
        "name": "\u5357\u660c\u5927\u5b66"
    },
    {
        "id": 22003,
        "name": "\u534e\u4e1c\u4ea4\u901a\u5927\u5b66"
    },
    {
        "id": 22004,
        "name": "\u5357\u660c\u822a\u7a7a\u5927\u5b66"
    },
    {
        "id": 22005,
        "name": "\u5357\u660c\u5de5\u7a0b"
    },
    {
        "id": 22006,
        "name": "\u5357\u660c\u7406\u5de5"
    },
    {
        "id": 22007,
        "name": "\u6c5f\u897f\u4e2d\u533b\u5b66\u9662"
    },
    {
        "id": 22008,
        "name": "\u6c5f\u897f\u79d1\u6280\u5e08\u8303\u5927\u5b66"
    },
    {
        "id": 22009,
        "name": "\u84dd\u5929\u5b66\u9662"
    },
    {
        "id": 22010,
        "name": "\u6c5f\u897f\u519c\u5927"
    },
    {
        "id": 22011,
        "name": "\u6c5f\u897f\u5e08\u5927"
    },
    {
        "id": 22012,
        "name": "\u6c5f\u897f\u592a\u9633\u80fd\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 22051,
        "name": "\u4e1c\u534e\u7406\u5de5\u5927\u5b66"
    },
    {
        "id": 22101,
        "name": "\u666f\u5fb7\u9547\u9676\u74f7\u5b66\u9662"
    },
    {
        "id": 22151,
        "name": "\u8d63\u5357\u533b\u5b66\u9662"
    },
    {
        "id": 22152,
        "name": "\u8d63\u5357\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 22153,
        "name": "\u6c5f\u897f\u7406\u5de5"
    },
    {
        "id": 22201,
        "name": "\u4e0a\u9976\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 22251,
        "name": "\u4e95\u5188\u5c71\u5927\u5b66"
    },
    {
        "id": 22301,
        "name": "\u5b9c\u6625\u5b66\u9662"
    },
    {
        "id": 22351,
        "name": "\u4e5d\u6c5f\u5b66\u9662"
    },
    {
        "id": 22401,
        "name": "\u629a\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 22402,
        "name": "\u8d63\u5357\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 22403,
        "name": "\u8d63\u897f\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 22404,
        "name": "\u6c5f\u897f\u8d22\u7ecf\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 22405,
        "name": "\u6c5f\u897f\u57ce\u5e02\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 22406,
        "name": "\u6c5f\u897f\u5927\u5b87\u5b66\u9662"
    },
    {
        "id": 22407,
        "name": "\u6c5f\u897f\u7535\u529b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 22408,
        "name": "\u6c5f\u897f\u670d\u88c5\u5b66\u9662"
    },
    {
        "id": 22409,
        "name": "\u5357\u660c\u5de5\u5b66\u9662"
    },
    {
        "id": 22410,
        "name": "\u6c5f\u897f\u5de5\u7a0b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 22411,
        "name": "\u6c5f\u897f\u5de5\u4e1a\u5de5\u7a0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 22412,
        "name": "\u6c5f\u897f\u5de5\u4e1a\u8d38\u6613\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 22413,
        "name": "\u6c5f\u897f\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 22414,
        "name": "\u6c5f\u897f\u8b66\u5bdf\u5b66\u9662"
    },
    {
        "id": 22415,
        "name": "\u6c5f\u897f\u822a\u7a7a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 22416,
        "name": "\u6c5f\u897f\u62a4\u7406\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 22417,
        "name": "\u6c5f\u897f\u73af\u5883\u5de5\u7a0b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 22418,
        "name": "\u6c5f\u897f\u673a\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 22419,
        "name": "\u6c5f\u897f\u5efa\u8bbe\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 22420,
        "name": "\u6c5f\u897f\u4ea4\u901a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 22421,
        "name": "\u6c5f\u897f\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 22422,
        "name": "\u6c5f\u897f\u7ecf\u6d4e\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 22423,
        "name": "\u6c5f\u897f\u7ecf\u6d4e\u7ba1\u7406\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 22424,
        "name": "\u6c5f\u897f\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 22425,
        "name": "\u6c5f\u897f\u65c5\u6e38\u5546\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 22426,
        "name": "\u6c5f\u897f\u5357\u660c\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 22427,
        "name": "\u6c5f\u897f\u519c\u4e1a\u5de5\u7a0b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 22428,
        "name": "\u6c5f\u897f\u9752\u5e74\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 22429,
        "name": "\u6c5f\u897f\u8f7b\u5de5\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 22430,
        "name": "\u6c5f\u897f\u751f\u7269\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 22431,
        "name": "\u6c5f\u897f\u7701\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 22432,
        "name": "\u6c5f\u897f\u53f8\u6cd5\u8b66\u5b98\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 22433,
        "name": "\u6c5f\u897f\u9676\u74f7\u5de5\u827a\u7f8e\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 22434,
        "name": "\u6c5f\u897f\u5916\u8bed\u5916\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 22435,
        "name": "\u6c5f\u897f\u5148\u950b\u8f6f\u4ef6\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 22436,
        "name": "\u6c5f\u897f\u73b0\u4ee3\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 22437,
        "name": "\u6c5f\u897f\u4fe1\u606f\u5e94\u7528\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 22438,
        "name": "\u6c5f\u897f\u884c\u653f\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 22439,
        "name": "\u6c5f\u897f\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 22440,
        "name": "\u6c5f\u897f\u5e94\u7528\u6280\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 22441,
        "name": "\u6c5f\u897f\u6e1d\u5dde\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 22442,
        "name": "\u6c5f\u897f\u5236\u9020\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 22443,
        "name": "\u6c5f\u897f\u4e2d\u533b\u836f\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 22444,
        "name": "\u666f\u5fb7\u9547\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 22445,
        "name": "\u4e5d\u6c5f\u804c\u4e1a\u5927\u5b66"
    },
    {
        "id": 22446,
        "name": "\u4e5d\u6c5f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 22447,
        "name": "\u5357\u660c\u94a2\u94c1\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 22448,
        "name": "\u5357\u660c\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 22449,
        "name": "\u5357\u660c\u5e02\u4e1a\u4f59\u5927\u5b66"
    },
    {
        "id": 22450,
        "name": "\u5357\u660c\u5e02\u804c\u5de5\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 22451,
        "name": "\u840d\u4e61\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 22452,
        "name": "\u4e0a\u9976\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 22453,
        "name": "\u65b0\u4f59\u94a2\u94c1\u6709\u9650\u8d23\u4efb\u516c\u53f8\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 22454,
        "name": "\u65b0\u4f59\u5b66\u9662"
    },
    {
        "id": 22455,
        "name": "\u5b9c\u6625\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 22456,
        "name": "\u9e70\u6f6d\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 22457,
        "name": "\u6c5f\u897f\u5e94\u7528\u5de5\u7a0b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 22458,
        "name": "\u6c5f\u897f\u519c\u4e1a\u5927\u5b66\u5357\u660c\u5546\u5b66\u9662"
    },
    {
        "id": 22459,
        "name": "\u6c5f\u897f\u5e08\u8303\u5927\u5b66\u79d1\u5b66\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 22460,
        "name": "\u534e\u4e1c\u4ea4\u901a\u5927\u5b66\u7406\u5de5\u5b66\u9662"
    },
    {
        "id": 22461,
        "name": "\u6c5f\u897f\u7406\u5de5\u5927\u5b66\u5e94\u7528\u79d1\u5b66\u5b66\u9662"
    },
    {
        "id": 22462,
        "name": "\u4e1c\u534e\u7406\u5de5\u5927\u5b66\u957f\u6c5f\u5b66\u9662"
    },
    {
        "id": 22463,
        "name": "\u5357\u660c\u822a\u7a7a\u5927\u5b66\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 22464,
        "name": "\u6c5f\u897f\u4e2d\u533b\u5b66\u9662\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 22465,
        "name": "\u6c5f\u897f\u8d22\u7ecf\u5927\u5b66\u73b0\u4ee3\u7ecf\u6d4e\u7ba1\u7406\u5b66\u9662"
    },
    {
        "id": 22466,
        "name": "\u8d63\u5357\u5e08\u8303\u5b66\u9662\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 22467,
        "name": "\u666f\u5fb7\u9547\u9676\u74f7\u5b66\u9662\u79d1\u6280\u827a\u672f\u5b66\u9662"
    },
    {
        "id": 22468,
        "name": "\u6c5f\u897f\u79d1\u6280\u5e08\u8303\u5b66\u9662\u7406\u5de5\u5b66\u9662"
    },
    {
        "id": 22469,
        "name": "\u5357\u660c\u5927\u5b66\u5171\u9752\u5b66\u9662"
    },
    {
        "id": 22470,
        "name": "\u5357\u660c\u5927\u5b66\u79d1\u5b66\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 22471,
        "name": "\u6c5f\u897f\u6cf0\u8c6a\u52a8\u6f2b\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 22472,
        "name": "\u6c5f\u897f\u67ab\u6797\u6d89\u5916\u7ecf\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 22473,
        "name": "\u6c5f\u897f\u4e2d\u5c71\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 22475,
        "name": "\u6c5f\u897f\u827a\u672f\u8bbe\u8ba1\u5b66\u9662"
    },
    {
        "id": 22477,
        "name": "\u6c5f\u897f\u5e08\u5927\u9e70\u6f6d\u5b66\u9662"
    }],
    "name": "\u6c5f\u897f"
},
{
    "id": 23,
    "school": [{
        "id": 23001,
        "name": "\u5c71\u897f\u5927\u5b66"
    },
    {
        "id": 23002,
        "name": "\u592a\u539f\u7406\u5de5"
    },
    {
        "id": 23003,
        "name": "\u4e2d\u5317\u5927\u5b66"
    },
    {
        "id": 23004,
        "name": "\u5c71\u897f\u533b\u5927"
    },
    {
        "id": 23005,
        "name": "\u5c71\u897f\u4e2d\u533b\u5b66\u9662"
    },
    {
        "id": 23006,
        "name": "\u592a\u539f\u5e08\u8303"
    },
    {
        "id": 23007,
        "name": "\u592a\u539f\u79d1\u5927"
    },
    {
        "id": 23008,
        "name": "\u5c71\u897f\u8d22\u7ecf"
    },
    {
        "id": 23009,
        "name": "\u8fd0\u57ce\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23010,
        "name": "\u9633\u6cc9\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 23051,
        "name": "\u5c71\u897f\u5e08\u5927"
    },
    {
        "id": 23101,
        "name": "\u5c71\u897f\u519c\u5927"
    },
    {
        "id": 23151,
        "name": "\u5927\u540c\u5927\u5b66"
    },
    {
        "id": 23201,
        "name": "\u957f\u6cbb\u533b\u5b66\u9662"
    },
    {
        "id": 23202,
        "name": "\u957f\u6cbb\u5b66\u9662"
    },
    {
        "id": 23251,
        "name": "\u8fd0\u57ce\u5b66\u9662"
    },
    {
        "id": 23301,
        "name": "\u664b\u4e2d\u5b66\u9662"
    },
    {
        "id": 23351,
        "name": "\u5ffb\u5dde\u5e08\u8303"
    },
    {
        "id": 23401,
        "name": "\u5317\u5cb3\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23402,
        "name": "\u957f\u6cbb\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23403,
        "name": "\u664b\u57ce\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23404,
        "name": "\u664b\u4e2d\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23405,
        "name": "\u4e34\u6c7e\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23406,
        "name": "\u6f5e\u5b89\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23407,
        "name": "\u5415\u6881\u5b66\u9662"
    },
    {
        "id": 23408,
        "name": "\u5c71\u897f\u8d22\u8d38\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23409,
        "name": "\u5c71\u897f\u8d22\u653f\u7a0e\u52a1\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 23410,
        "name": "\u5c71\u897f\u7535\u529b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23411,
        "name": "\u5c71\u897f\u5de5\u7a0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23412,
        "name": "\u5c71\u897f\u5de5\u5546\u5b66\u9662"
    },
    {
        "id": 23413,
        "name": "\u5c71\u897f\u7ba1\u7406\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 23414,
        "name": "\u5c71\u897f\u56fd\u9645\u5546\u52a1\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 23415,
        "name": "\u5c71\u897f\u534e\u6fb3\u5546\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 23416,
        "name": "\u5c71\u897f\u673a\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23417,
        "name": "\u5c71\u897f\u5efa\u7b51\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23418,
        "name": "\u5c71\u897f\u4ea4\u901a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23419,
        "name": "\u5c71\u897f\u91d1\u878d\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23420,
        "name": "\u5c71\u897f\u8b66\u5b98\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 23421,
        "name": "\u5c71\u897f\u8b66\u5b98\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 23422,
        "name": "\u5c71\u897f\u6797\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23423,
        "name": "\u5c71\u897f\u65c5\u6e38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 23424,
        "name": "\u5c71\u897f\u7164\u70ad\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23425,
        "name": "\u5c71\u897f\u751f\u7269\u5e94\u7528\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23426,
        "name": "\u5c71\u897f\u6c34\u5229\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23427,
        "name": "\u5c71\u897f\u4f53\u80b2\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 23428,
        "name": "\u5c71\u897f\u540c\u6587\u5916\u8bed\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 23429,
        "name": "\u5c71\u897f\u620f\u5267\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 23430,
        "name": "\u5c71\u897f\u4fe1\u606f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23431,
        "name": "\u5c71\u897f\u5174\u534e\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 23432,
        "name": "\u5c71\u897f\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 23433,
        "name": "\u5c71\u897f\u8fd0\u57ce\u519c\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23435,
        "name": "\u5c71\u897f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23436,
        "name": "\u592a\u539f\u57ce\u5e02\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23437,
        "name": "\u592a\u539f\u5927\u5b66"
    },
    {
        "id": 23438,
        "name": "\u592a\u539f\u7535\u529b\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 23439,
        "name": "\u592a\u539f\u65c5\u6e38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 23440,
        "name": "\u5ffb\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23441,
        "name": "\u9633\u6cc9\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23443,
        "name": "\u5c71\u897f\u57ce\u5e02\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23444,
        "name": "\u8fd0\u57ce\u519c\u4e1a\u5b66\u9662"
    },
    {
        "id": 23445,
        "name": "\u5c71\u897f\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 23446,
        "name": "\u664b\u4e2d\u5b66\u9662\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 23448,
        "name": "\u6714\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23449,
        "name": "\u5c71\u897f\u519c\u4e1a\u5927\u5b66\u5e73\u9065\u673a\u7535\u5b66\u9662"
    },
    {
        "id": 23450,
        "name": "\u5c71\u897f\u519c\u4e1a\u5927\u5b66\u4fe1\u606f\u5b66\u9662"
    },
    {
        "id": 23451,
        "name": "\u5c71\u897f\u519c\u4e1a\u5927\u5b66\u592a\u539f\u755c\u7267\u517d\u533b\u5b66\u9662"
    },
    {
        "id": 23452,
        "name": "\u5c71\u897f\u519c\u4e1a\u5927\u5b66\u592a\u539f\u56ed\u827a\u5b66\u9662\uff08\u592a\u539f\u519c\u4e1a\u5b66\u6821\uff09"
    },
    {
        "id": 23453,
        "name": "\u5c71\u897f\u519c\u4e1a\u5927\u5b66\u539f\u5e73\u519c\u5b66\u9662\uff08\u539f\u5e73\u519c\u4e1a\u5b66\u6821\uff09"
    },
    {
        "id": 23454,
        "name": "\u592a\u539f\u79d1\u6280\u5927\u5b66\u8fd0\u57ce\u5de5\u5b66\u9662\uff08\u8fd0\u57ce\u5de5\u5b66\u9662\uff09"
    },
    {
        "id": 23455,
        "name": "\u5c71\u897f\u8d22\u7ecf\u5927\u5b66\u8fd0\u57ce\u5b66\u9662"
    },
    {
        "id": 23456,
        "name": "\u5c71\u897f\u533b\u79d1\u5927\u5b66\u6c7e\u9633\u5206\u9662"
    },
    {
        "id": 23457,
        "name": "\u5c71\u897f\u533b\u79d1\u5927\u5b66\u664b\u7960\u5b66\u9662"
    },
    {
        "id": 23458,
        "name": "\u592a\u539f\u79d1\u6280\u5927\u5b66\u534e\u79d1\u5b66\u9662"
    },
    {
        "id": 23459,
        "name": "\u5c71\u897f\u8d22\u7ecf\u5927\u5b66\u534e\u5546\u5b66\u9662"
    },
    {
        "id": 23460,
        "name": "\u4e2d\u5317\u5927\u5b66\u4fe1\u606f\u5546\u52a1\u5b66\u9662"
    },
    {
        "id": 23461,
        "name": "\u5c71\u897f\u5e08\u8303\u5927\u5b66\u73b0\u4ee3\u6587\u7406\u5b66\u9662"
    },
    {
        "id": 23462,
        "name": "\u5ffb\u5dde\u5e08\u8303\u5b66\u9662\u4e94\u5be8\u5206\u9662\uff08\u4e94\u5be8\u5e08\u8303\u5b66\u9662\uff09"
    },
    {
        "id": 23463,
        "name": "\u5c71\u897f\u5927\u5b66\u5546\u52a1\u5b66\u9662"
    },
    {
        "id": 23500,
        "name": "\u592a\u539f\u5de5\u4e1a\u5b66\u9662"
    },
    {
        "id": 23501,
        "name": "\u5c71\u897f\u7ecf\u6d4e\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 23502,
        "name": "\u5c71\u897f\u9752\u5e74\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23503,
        "name": "\u5c71\u897f\u7701\u804c\u5de5\u5de5\u827a\u7f8e\u672f\u5b66\u9662"
    },
    {
        "id": 23504,
        "name": "\u5c71\u897f\u7701\u5415\u6881\u5e02\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 23506,
        "name": "\u957f\u6cbb\u5e02\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 23507,
        "name": "\u5c71\u897f\u7164\u70ad\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 23508,
        "name": "\u5c71\u897f\u653f\u6cd5\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 23509,
        "name": "\u9633\u6cc9\u5e02\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 23510,
        "name": "\u5c71\u897f\u7164\u70ad\u804c\u5de5\u8054\u5408\u5927\u5b66"
    },
    {
        "id": 23512,
        "name": "\u592a\u539f\u94a2\u94c1(\u96c6\u56e2)\u6709\u9650\u516c\u53f8\u804c\u5de5\u94a2\u94c1\u5b66\u9662"
    },
    {
        "id": 23513,
        "name": "\u5c71\u897f\u673a\u7535\u804c\u5de5\u5b66\u9662"
    },
    {
        "id": 23514,
        "name": "\u592a\u539f\u5316\u5b66\u5de5\u4e1a\u96c6\u56e2\u6709\u9650\u516c\u53f8\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 23515,
        "name": "\u5c71\u897f\u5175\u5668\u5de5\u4e1a\u804c\u5de5\u5927\u5b66"
    },
    {
        "id": 23516,
        "name": "\u5e7f\u64ad\u7535\u5f71\u7535\u89c6\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 23517,
        "name": "\u5c71\u897f\u804c\u5de5\u533b\u5b66\u9662"
    },
    {
        "id": 23518,
        "name": "\u5c71\u897f\u7701\u4e34\u6c7e\u7535\u529b\u6280\u5e08\u5b66\u9662"
    },
    {
        "id": 23519,
        "name": "\u592a\u539f\u5927\u5b66\u5916\u8bed\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 23521,
        "name": "\u8fd0\u57ce\u5e7c\u513f\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 23522,
        "name": "\u4e2d\u56fd\u8f90\u5c04\u9632\u62a4\u7814\u7a76\u9662"
    },
    {
        "id": 23524,
        "name": "\u5c71\u897f\u5e08\u5927\u4e34\u6c7e\u5b66\u9662"
    },
    {
        "id": 23525,
        "name": "\u5c71\u897f\u8d22\u7ecf\u5927\u5b66\u7ecf\u6d4e\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23526,
        "name": "\u5c71\u897f\u8001\u533a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 23527,
        "name": "\u4e2d\u56fd\u65e5\u7528\u5316\u5b66\u5de5\u4e1a\u7814\u7a76\u9662"
    }],
    "name": "\u5c71\u897f"
},
{
    "id": 24,
    "school": [{
        "id": 24001,
        "name": "\u8d35\u5dde\u5927\u5b66"
    },
    {
        "id": 24002,
        "name": "\u8d35\u9633\u533b\u5b66\u9662"
    },
    {
        "id": 24003,
        "name": "\u8d35\u9633\u4e2d\u533b\u5b66\u9662"
    },
    {
        "id": 24004,
        "name": "\u8d35\u5dde\u8d22\u7ecf"
    },
    {
        "id": 24005,
        "name": "\u8d35\u5dde\u6c11\u65cf"
    },
    {
        "id": 24006,
        "name": "\u8d35\u9633\u5b66\u9662"
    },
    {
        "id": 24007,
        "name": "\u8d35\u5dde\u5e08\u5927"
    },
    {
        "id": 24008,
        "name": "\u94dc\u4ec1\u5b66\u9662"
    },
    {
        "id": 24009,
        "name": "\u8d35\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662 "
    },
    {
        "id": 24010,
        "name": "\u8d35\u5dde\u7701\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 24051,
        "name": "\u9075\u4e49\u533b\u5b66\u9662"
    },
    {
        "id": 24052,
        "name": "\u9075\u4e49\u5e08\u8303"
    },
    {
        "id": 24101,
        "name": "\u6bd5\u8282\u5b66\u9662"
    },
    {
        "id": 24151,
        "name": "\u9ed4\u5357\u5e08\u9662"
    },
    {
        "id": 24201,
        "name": "\u5b89\u987a\u5b66\u9662"
    },
    {
        "id": 24251,
        "name": "\u51ef\u91cc\u5b66\u9662"
    },
    {
        "id": 24301,
        "name": "\u5b89\u987a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 24302,
        "name": "\u8d35\u5dde\u7535\u529b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 24303,
        "name": "\u8d35\u5dde\u7535\u5b50\u4fe1\u606f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 24304,
        "name": "\u8d35\u5dde\u822a\u5929\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 24306,
        "name": "\u8d35\u5dde\u4ea4\u901a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 24307,
        "name": "\u8d35\u5dde\u8b66\u5b98\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 24308,
        "name": "\u8d35\u5dde\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 24310,
        "name": "\u8d35\u5dde\u8f7b\u5de5\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 24311,
        "name": "\u8d35\u5dde\u5546\u4e1a\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 24312,
        "name": "\u516d\u76d8\u6c34\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 24313,
        "name": "\u516d\u76d8\u6c34\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 24314,
        "name": "\u9ed4\u4e1c\u5357\u6c11\u65cf\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 24315,
        "name": "\u9ed4\u5357\u6c11\u65cf\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 24316,
        "name": "\u9ed4\u5357\u6c11\u65cf\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 24318,
        "name": "\u9ed4\u897f\u5357\u6c11\u65cf\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 24319,
        "name": "\u94dc\u4ec1\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 24320,
        "name": "\u9075\u4e49\u533b\u836f\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 24321,
        "name": "\u9075\u4e49\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 24322,
        "name": "\u8d35\u5dde\u8d22\u7ecf\u5b66\u9662\u5546\u52a1\u5b66\u9662"
    },
    {
        "id": 24323,
        "name": "\u8d35\u5dde\u6c11\u65cf\u5b66\u9662\u4eba\u6587\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 24324,
        "name": "\u8d35\u5dde\u5e08\u8303\u5927\u5b66\u6c42\u662f\u5b66\u9662"
    },
    {
        "id": 24325,
        "name": "\u8d35\u9633\u533b\u5b66\u9662\u795e\u5947\u6c11\u65cf\u533b\u836f\u5b66\u9662"
    },
    {
        "id": 24326,
        "name": "\u9075\u4e49\u533b\u5b66\u9662\u533b\u5b66\u4e0e\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 24327,
        "name": "\u8d35\u9633\u4e2d\u533b\u5b66\u9662\u65f6\u73cd\u5b66\u9662"
    },
    {
        "id": 24328,
        "name": "\u8d35\u5dde\u5927\u5b66\u660e\u5fb7\u5b66\u9662"
    },
    {
        "id": 24329,
        "name": "\u8d35\u5dde\u5927\u5b66\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 24330,
        "name": "\u8d35\u9633\u62a4\u7406\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 24331,
        "name": "\u8d35\u5dde\u4e9a\u6cf0\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 24333,
        "name": "\u8d35\u5dde\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 24334,
        "name": "\u8d35\u9633\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 24335,
        "name": "\u6bd5\u8282\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 24336,
        "name": "\u8d35\u5dde\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    },
    {
        "id": 24337,
        "name": "\u8d35\u5dde\u7701\u8d22\u7ecf\u5b66\u9662\u5546\u52a1\u5b66\u9662"
    }],
    "name": "\u8d35\u5dde"
},
{
    "id": 25,
    "school": [{
        "id": 25001,
        "name": "\u5e7f\u897f\u5927\u5b66"
    },
    {
        "id": 25002,
        "name": "\u5e7f\u897f\u533b\u79d1\u5927\u5b66"
    },
    {
        "id": 25003,
        "name": "\u5e7f\u897f\u6c11\u65cf\u5927\u5b66"
    },
    {
        "id": 25004,
        "name": "\u5e7f\u897f\u4e2d\u533b\u5b66\u9662"
    },
    {
        "id": 25005,
        "name": "\u5e7f\u897f\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 25006,
        "name": "\u5e7f\u897f\u8d22\u7ecf\u5b66\u9662"
    },
    {
        "id": 25007,
        "name": "\u5e7f\u897f\u827a\u672f\u5b66\u9662"
    },
    {
        "id": 25008,
        "name": "\u5e7f\u897f\u5916\u56fd\u8bed\u5b66\u9662"
    },
    {
        "id": 25051,
        "name": "\u6842\u6797\u7535\u5b50\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 25052,
        "name": "\u5e7f\u897f\u5e08\u8303\u5927\u5b66"
    },
    {
        "id": 25053,
        "name": "\u6842\u6797\u7406\u5de5\u5927\u5b66"
    },
    {
        "id": 25054,
        "name": "\u6842\u6797\u533b\u5b66\u9662"
    },
    {
        "id": 25101,
        "name": "\u5e7f\u897f\u5de5\u5b66\u9662"
    },
    {
        "id": 25151,
        "name": "\u53f3\u6c5f\u6c11\u65cf\u533b\u5b66\u9662"
    },
    {
        "id": 25152,
        "name": "\u767e\u8272\u5b66\u9662"
    },
    {
        "id": 25201,
        "name": "\u6cb3\u6c60\u5b66\u9662"
    },
    {
        "id": 25251,
        "name": "\u7389\u6797\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 25301,
        "name": "\u94a6\u5dde\u5b66\u9662"
    },
    {
        "id": 25351,
        "name": "\u8d3a\u5dde\u5b66\u9662"
    },
    {
        "id": 25401,
        "name": "\u68a7\u5dde\u5b66\u9662"
    },
    {
        "id": 25404,
        "name": "\u5e7f\u897f\u5927\u5b66\u884c\u5065\u6587\u7406\u5b66\u9662"
    },
    {
        "id": 25405,
        "name": "\u5e7f\u897f\u5e08\u8303\u5927\u5b66\u6f13\u6c5f\u5b66\u9662"
    },
    {
        "id": 25406,
        "name": "\u6842\u6797\u7535\u5b50\u79d1\u6280\u5927\u5b66\u4fe1\u606f\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 25407,
        "name": "\u6842\u6797\u5de5\u5b66\u9662\u535a\u6587\u7ba1\u7406\u5b66\u9662"
    },
    {
        "id": 25408,
        "name": "\u5e7f\u897f\u5de5\u5b66\u9662\u9e7f\u5c71\u5b66\u9662"
    },
    {
        "id": 25409,
        "name": "\u5e7f\u897f\u5e08\u8303\u5b66\u9662\u5e08\u56ed\u5b66\u9662"
    },
    {
        "id": 25410,
        "name": "\u5e7f\u897f\u6c11\u65cf\u5927\u5b66\u76f8\u601d\u6e56\u5b66\u9662"
    },
    {
        "id": 25411,
        "name": "\u5e7f\u897f\u4e2d\u533b\u5b66\u9662\u8d5b\u6069\u65af\u65b0\u533b\u836f\u5b66\u9662"
    },
    {
        "id": 25501,
        "name": "\u5317\u6d77\u5b8f\u6e90\u8db3\u7403\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 25502,
        "name": "\u5317\u6d77\u827a\u672f\u8bbe\u8ba1\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 25503,
        "name": "\u5317\u6d77\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 25504,
        "name": "\u5e7f\u897f\u57ce\u5e02\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 25505,
        "name": "\u5e7f\u897f\u7535\u529b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 25506,
        "name": "\u5e7f\u897f\u4e1c\u65b9\u5916\u8bed\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 25507,
        "name": "\u5e7f\u897f\u5de5\u5546\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 25508,
        "name": "\u5e7f\u897f\u5de5\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 25509,
        "name": "\u5e7f\u897f\u56fd\u9645\u5546\u52a1\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 25510,
        "name": "\u5e7f\u897f\u673a\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 25511,
        "name": "\u5e7f\u897f\u5efa\u8bbe\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 25512,
        "name": "\u5e7f\u897f\u4ea4\u901a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 25513,
        "name": "\u5e7f\u897f\u7ecf\u6d4e\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 25514,
        "name": "\u5e7f\u897f\u7ecf\u8d38\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 25515,
        "name": "\u5e7f\u897f\u8b66\u7ba1\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 25516,
        "name": "\u5e7f\u897f\u519c\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 25517,
        "name": "\u5e7f\u897f\u8f7b\u5de5\u9ad8\u7ea7\u6280\u5de5\u5b66\u6821"
    },
    {
        "id": 25518,
        "name": "\u5e7f\u897f\u751f\u6001\u5de5\u7a0b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 25519,
        "name": "\u5e7f\u897f\u6c34\u5229\u7535\u529b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 25520,
        "name": "\u5e7f\u897f\u4f53\u80b2\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 25521,
        "name": "\u5e7f\u897f\u6f14\u827a\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 25522,
        "name": "\u5e7f\u897f\u82f1\u534e\u56fd\u9645\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 25523,
        "name": "\u5e7f\u897f\u9095\u6c5f\u5927\u5b66"
    },
    {
        "id": 25524,
        "name": "\u5e7f\u897f\u5e7c\u513f\u5e08\u8303\u5b66\u6821"
    },
    {
        "id": 25525,
        "name": "\u5e7f\u897f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 25526,
        "name": "\u8d35\u6e2f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 25527,
        "name": "\u6842\u6797\u822a\u5929\u5de5\u4e1a\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 25528,
        "name": "\u6842\u6797\u65c5\u6e38\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 25529,
        "name": "\u6842\u6797\u5c71\u6c34\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 25530,
        "name": "\u6842\u6797\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 25531,
        "name": "\u6cb3\u6c60\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 25532,
        "name": "\u67f3\u5dde\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 25533,
        "name": "\u67f3\u5dde\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 25534,
        "name": "\u67f3\u5dde\u94c1\u9053\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 25535,
        "name": "\u67f3\u5dde\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 25536,
        "name": "\u5e7f\u897f\u6c11\u65cf\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 25537,
        "name": "\u5357\u5b81\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 25538,
        "name": "\u5357\u5b81\u5730\u533a\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 25539,
        "name": "\u5317\u4eac\u822a\u7a7a\u822a\u5929\u5927\u5b66\u5317\u6d77\u5b66\u9662"
    },
    {
        "id": 25540,
        "name": "\u6842\u6797\u5de5\u5b66\u9662\u5357\u5b81\u5206\u9662"
    },
    {
        "id": 25541,
        "name": "\u767e\u8272\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 25542,
        "name": "\u5e7f\u897f\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 25543,
        "name": "\u68a7\u5dde\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 25544,
        "name": "\u5e7f\u897f\u536b\u751f\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 25547,
        "name": "\u5e7f\u897f\u653f\u6cd5\u7ba1\u7406\u5e72\u90e8\u5b66\u9662"
    },
    {
        "id": 25548,
        "name": "\u67f3\u5dde\u57ce\u5e02\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    }],
    "name": "\u5e7f\u897f"
},
{
    "id": 26,
    "school": [{
        "id": 26001,
        "name": "\u5185\u8499\u53e4\u5927\u5b66"
    },
    {
        "id": 26002,
        "name": "\u5185\u8499\u53e4\u5de5\u4e1a\u5927\u5b66"
    },
    {
        "id": 26003,
        "name": "\u5185\u8499\u53e4\u519c\u5927"
    },
    {
        "id": 26004,
        "name": "\u5185\u8499\u53e4\u5e08\u5927"
    },
    {
        "id": 26005,
        "name": "\u5185\u8499\u53e4\u533b\u5b66\u9662"
    },
    {
        "id": 26006,
        "name": "\u5185\u8499\u53e4\u8d22\u7ecf"
    },
    {
        "id": 26007,
        "name": "\u4e2d\u592e\u515a\u6821\u51fd\u6388\u5b66\u9662\u5185\u8499\u53e4\u5206\u9662"
    },
    {
        "id": 26008,
        "name": "\u4e2d\u5171\u5185\u8499\u53e4\u81ea\u6cbb\u533a\u59d4\u5458\u4f1a\u515a\u6821"
    },
    {
        "id": 26009,
        "name": "\u5185\u8499\u53e4\u81ea\u6cbb\u533a\u884c\u653f\u5b66\u9662"
    },
    {
        "id": 26051,
        "name": "\u5185\u8499\u53e4\u79d1\u5927"
    },
    {
        "id": 26101,
        "name": "\u5185\u8499\u53e4\u6c11\u65cf\u5927\u5b66"
    },
    {
        "id": 26151,
        "name": "\u8d64\u5cf0\u5b66\u9662"
    },
    {
        "id": 26201,
        "name": "\u547c\u4f26\u8d1d\u5c14\u5b66\u9662"
    },
    {
        "id": 26301,
        "name": "\u5305\u5934\u94a2\u94c1\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 26302,
        "name": "\u5305\u5934\u8f7b\u5de5\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 26303,
        "name": "\u5305\u5934\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 26304,
        "name": "\u6cb3\u5957\u5927\u5b66"
    },
    {
        "id": 26305,
        "name": "\u547c\u548c\u6d69\u7279\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 26307,
        "name": "\u79d1\u5c14\u6c81\u827a\u672f\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 26308,
        "name": "\u5185\u8499\u53e4\u8d22\u7a0e\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 26309,
        "name": "\u5185\u8499\u53e4\u7535\u5b50\u4fe1\u606f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 26310,
        "name": "\u5185\u8499\u53e4\u9752\u57ce\u5927\u5b66"
    },
    {
        "id": 26311,
        "name": "\u5185\u8499\u53e4\u5316\u5de5\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 26312,
        "name": "\u5185\u8499\u53e4\u673a\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 26313,
        "name": "\u5185\u8499\u53e4\u5efa\u7b51\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 26314,
        "name": "\u5185\u8499\u53e4\u4ea4\u901a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 26315,
        "name": "\u547c\u548c\u6d69\u7279\u6c11\u65cf\u5b66\u9662"
    },
    {
        "id": 26316,
        "name": "\u5185\u8499\u53e4\u5546\u8d38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 26317,
        "name": "\u5185\u8499\u53e4\u4f53\u80b2\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 26318,
        "name": "\u901a\u8fbd\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 26319,
        "name": "\u4e4c\u6d77\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 26320,
        "name": "\u4e4c\u5170\u5bdf\u5e03\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 26321,
        "name": "\u9521\u6797\u90ed\u52d2\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 26322,
        "name": "\u5174\u5b89\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 26323,
        "name": "\u5185\u8499\u53e4\u8b66\u5bdf\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 26325,
        "name": "\u5185\u8499\u53e4\u5317\u65b9\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 26326,
        "name": "\u5185\u8499\u53e4\u4e30\u5dde\u5b66\u9662"
    },
    {
        "id": 26327,
        "name": "\u5185\u8499\u53e4\u7ecf\u8d38\u5916\u8bed\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 26328,
        "name": "\u5185\u8499\u53e4\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 26329,
        "name": "\u8d64\u5cf0\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 26330,
        "name": "\u5305\u5934\u94c1\u9053\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 26331,
        "name": "\u5185\u8499\u53e4\u5e7f\u64ad\u7535\u89c6\u5927\u5b66\u76f4\u5c5e\u5b66\u9662"
    },
    {
        "id": 26332,
        "name": "\u96c6\u5b81\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 26333,
        "name": "\u5185\u8499\u53e4\u8d22\u7ecf\u5b66\u9662"
    },
    {
        "id": 26334,
        "name": "\u5305\u5934\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 26335,
        "name": "\u5185\u8499\u53e4\u79d1\u6280\u5927\u5b66\u5305\u5934\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 26336,
        "name": "\u5185\u8499\u53e4\u533b\u836f\u4e13\u4fee\u5b66\u9662"
    },
    {
        "id": 26337,
        "name": "\u9102\u5c14\u591a\u65af\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 26338,
        "name": "\u4e4c\u5170\u5bdf\u5e03\u533b\u5b66\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    }],
    "name": "\u5185\u8499\u53e4"
},
{
    "id": 27,
    "school": [{
        "id": 27001,
        "name": "\u5b81\u590f\u5927\u5b66"
    },
    {
        "id": 27002,
        "name": "\u5317\u65b9\u6c11\u5927"
    },
    {
        "id": 27003,
        "name": "\u5b81\u590f\u533b\u79d1\u5927\u5b66"
    },
    {
        "id": 27051,
        "name": "\u5b81\u590f\u7406\u5de5"
    },
    {
        "id": 27201,
        "name": "\u5434\u5fe0\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 27202,
        "name": "\u5b81\u590f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 27203,
        "name": "\u5b81\u590f\u8d22\u7ecf\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 27204,
        "name": "\u5b81\u590f\u53f8\u6cd5\u8b66\u5b98\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 27205,
        "name": "\u5b81\u590f\u5e08\u8303\u5b66\u9662"
    },
    {
        "id": 27206,
        "name": "\u5b81\u590f\u5de5\u4e1a\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 27207,
        "name": "\u5b81\u590f\u5de5\u5546\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 27208,
        "name": "\u5b81\u590f\u5efa\u8bbe\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 27209,
        "name": "\u94f6\u5ddd\u79d1\u6280\u804c\u4e1a\u5b66\u9662\uff08\u94f6\u5ddd\u5927\u5b66\uff09"
    },
    {
        "id": 27210,
        "name": "\u5b81\u590f\u5927\u5b66\u65b0\u534e\u5b66\u9662"
    },
    {
        "id": 27211,
        "name": "\u4e2d\u56fd\u77ff\u4e1a\u5927\u5b66\u94f6\u5ddd\u5b66\u9662"
    }],
    "name": "\u5b81\u590f"
},
{
    "id": 28,
    "school": [{
        "id": 28001,
        "name": "\u9752\u6d77\u5927\u5b66"
    },
    {
        "id": 28002,
        "name": "\u9752\u6d77\u5e08\u5927"
    },
    {
        "id": 28003,
        "name": "\u9752\u6d77\u6c11\u5927"
    },
    {
        "id": 28101,
        "name": "\u9752\u6d77\u6c11\u65cf\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 28102,
        "name": "\u9752\u6d77\u8d22\u7ecf\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 28103,
        "name": "\u9752\u6d77\u755c\u7267\u517d\u533b\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 28104,
        "name": "\u9752\u6d77\u5efa\u7b51\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 28105,
        "name": "\u9752\u6d77\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 28106,
        "name": "\u9752\u6d77\u8b66\u5b98\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 28107,
        "name": "\u9752\u6d77\u4ea4\u901a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 28108,
        "name": "\u9752\u6d77\u536b\u751f\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    }],
    "name": "\u9752\u6d77"
},
{
    "id": 29,
    "school": [{
        "id": 29001,
        "name": "\u65b0\u7586\u5927\u5b66"
    },
    {
        "id": 29002,
        "name": "\u65b0\u7586\u519c\u5927"
    },
    {
        "id": 29003,
        "name": "\u65b0\u7586\u533b\u79d1\u5927"
    },
    {
        "id": 29004,
        "name": "\u65b0\u7586\u5e08\u5927"
    },
    {
        "id": 29005,
        "name": "\u65b0\u7586\u8d22\u7ecf"
    },
    {
        "id": 29006,
        "name": "\u65b0\u7586\u827a\u672f\u5b66\u9662"
    },
    {
        "id": 29007,
        "name": "\u4f0a\u7281\u5e08\u8303\u594e\u5c6f\u6821\u533a"
    },
    {
        "id": 29051,
        "name": "\u77f3\u6cb3\u5b50\u5927\u5b66"
    },
    {
        "id": 29101,
        "name": "\u5854\u91cc\u6728\u5927\u5b66"
    },
    {
        "id": 29151,
        "name": "\u5580\u4ec0\u5e08\u9662"
    },
    {
        "id": 29201,
        "name": "\u4f0a\u7281\u5e08\u9662"
    },
    {
        "id": 29251,
        "name": "\u660c\u5409\u5b66\u9662"
    },
    {
        "id": 29301,
        "name": "\u963f\u514b\u82cf\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 29302,
        "name": "\u5df4\u97f3\u90ed\u695e\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 29303,
        "name": "\u660c\u5409\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 29304,
        "name": "\u548c\u7530\u5e08\u8303\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 29305,
        "name": "\u514b\u62c9\u739b\u4f9d\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 29306,
        "name": "\u4e4c\u9c81\u6728\u9f50\u804c\u4e1a\u5927\u5b66"
    },
    {
        "id": 29307,
        "name": "\u65b0\u7586\u5175\u56e2\u8b66\u5b98\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 29308,
        "name": "\u65b0\u7586\u5de5\u4e1a\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 29309,
        "name": "\u65b0\u7586\u673a\u7535\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 29310,
        "name": "\u65b0\u7586\u5efa\u8bbe\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 29311,
        "name": "\u65b0\u7586\u4ea4\u901a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 29312,
        "name": "\u65b0\u7586\u8b66\u5b98\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 29313,
        "name": "\u65b0\u7586\u80fd\u6e90\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 29314,
        "name": "\u65b0\u7586\u519c\u4e1a\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 29315,
        "name": "\u65b0\u7586\u8f7b\u5de5\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 29316,
        "name": "\u65b0\u7586\u77f3\u6cb3\u5b50\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 29317,
        "name": "\u65b0\u7586\u5929\u5c71\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 29318,
        "name": "\u65b0\u7586\u7ef4\u543e\u5c14\u533b\u5b66\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 29319,
        "name": "\u65b0\u7586\u73b0\u4ee3\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 29320,
        "name": "\u4f0a\u7281\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 29321,
        "name": "\u65b0\u7586\u79d1\u4fe1\u5b66\u9662"
    },
    {
        "id": 29322,
        "name": "\u65b0\u7586\u804c\u4e1a\u5927\u5b66"
    },
    {
        "id": 29323,
        "name": "\u65b0\u7586\u77f3\u6cb9\u5b66\u9662"
    },
    {
        "id": 29324,
        "name": "\u65b0\u7586\u5927\u5b66\u79d1\u5b66\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 29325,
        "name": "\u65b0\u7586\u519c\u4e1a\u5927\u5b66\u79d1\u5b66\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 29326,
        "name": "\u65b0\u7586\u8d22\u7ecf\u5927\u5b66\u5546\u52a1\u5b66\u9662"
    },
    {
        "id": 29327,
        "name": "\u65b0\u7586\u533b\u79d1\u5927\u5b66\u539a\u535a\u5b66\u9662"
    },
    {
        "id": 29328,
        "name": "\u77f3\u6cb3\u5b50\u5927\u5b66\u79d1\u6280\u5b66\u9662"
    },
    {
        "id": 29329,
        "name": "\u65b0\u7586\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 29331,
        "name": "\u65b0\u7586\u653f\u6cd5\u5b66\u9662"
    },
    {
        "id": 29332,
        "name": "\u65b0\u7586\u5e7f\u64ad\u7535\u89c6\u5927\u5b66"
    }],
    "name": "\u65b0\u7586"
},
{
    "id": 30,
    "school": [{
        "id": 30001,
        "name": "\u6d77\u5357\u5927\u5b66"
    },
    {
        "id": 30002,
        "name": "\u6d77\u5357\u533b\u5b66\u9662"
    },
    {
        "id": 30005,
        "name": "\u6d77\u5357\u5e08\u8303\u5927\u5b66"
    },
    {
        "id": 30051,
        "name": "\u534e\u5357\u70ed\u5e26\u519c\u5927"
    },
    {
        "id": 30101,
        "name": "\u743c\u5dde\u5927\u5b66"
    },
    {
        "id": 30201,
        "name": "\u6d77\u53e3\u7ecf\u6d4e\u5b66\u9662"
    },
    {
        "id": 30202,
        "name": "\u6d77\u5357\u7ecf\u8d38\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 30203,
        "name": "\u6d77\u5357\u8f6f\u4ef6\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 30204,
        "name": "\u6d77\u5357\u5916\u56fd\u8bed\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 30205,
        "name": "\u6d77\u5357\u5de5\u5546\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 30206,
        "name": "\u6d77\u5357\u653f\u6cd5\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 30207,
        "name": "\u6d77\u5357\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 30208,
        "name": "\u743c\u53f0\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 30209,
        "name": "\u4e09\u4e9a\u822a\u7a7a\u65c5\u6e38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 30210,
        "name": "\u4e09\u4e9a\u5353\u8fbe\u65c5\u6e38\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 30211,
        "name": "\u6d77\u5357\u5927\u5b66\u4e09\u4e9a\u5b66\u9662"
    },
    {
        "id": 30212,
        "name": "\u4e09\u4e9a\u57ce\u5e02\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 30213,
        "name": "\u6d77\u5357\u79d1\u6280\u804c\u4e1a\u5b66\u9662"
    },
    {
        "id": 30214,
        "name": "\u4e09\u4e9a\u7406\u5de5\u804c\u4e1a\u5b66\u9662"
    }],
    "name": "\u6d77\u5357"
},
{
    "id": 31,
    "school": [{
        "id": 31001,
        "name": "\u897f\u85cf\u5927\u5b66"
    },
    {
        "id": 31002,
        "name": "\u897f\u85cf\u85cf\u533b\u5b66\u9662"
    },
    {
        "id": 31003,
        "name": "\u897f\u85cf\u6c11\u9662"
    },
    {
        "id": 31101,
        "name": "\u62c9\u8428\u5e08\u8303\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 31102,
        "name": "\u897f\u85cf\u8b66\u5b98\u9ad8\u7b49\u4e13\u79d1\u5b66\u6821"
    },
    {
        "id": 31103,
        "name": "\u897f\u85cf\u804c\u4e1a\u6280\u672f\u5b66\u9662"
    },
    {
        "id": 31104,
        "name": "\u897f\u85cf\u6c11\u65cf\u5b66\u9662"
    }],
    "name": "\u897f\u85cf"
},
{
    "id": 32,
    "school": [{
        "id": 32001,
        "name": "\u9999\u6e2f\u5927\u5b66"
    },
    {
        "id": 32002,
        "name": "\u9999\u6e2f\u4e2d\u6587\u5927\u5b66"
    },
    {
        "id": 32003,
        "name": "\u9999\u6e2f\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 32004,
        "name": "\u9999\u6e2f\u7406\u5de5\u5927\u5b66"
    },
    {
        "id": 32005,
        "name": "\u9999\u6e2f\u57ce\u5e02\u5927\u5b66"
    },
    {
        "id": 32006,
        "name": "\u9999\u6e2f\u6d78\u4f1a\u5927\u5b66"
    },
    {
        "id": 32007,
        "name": "\u9999\u6e2f\u5cad\u5357\u5927\u5b66"
    },
    {
        "id": 32008,
        "name": "\u9999\u6e2f\u56fd\u9645\u5de5\u5546\u7ba1\u7406\u5b66\u9662"
    },
    {
        "id": 32009,
        "name": "\u9999\u6e2f\u73e0\u6d77\u5b66\u9662"
    },
    {
        "id": 32010,
        "name": "\u9999\u6e2f\u6559\u80b2\u5b66\u9662"
    },
    {
        "id": 32011,
        "name": "\u9999\u6e2f\u6811\u4ec1\u5927\u5b66"
    },
    {
        "id": 32012,
        "name": "\u9999\u6e2f\u6f14\u827a\u5b66\u9662"
    },
    {
        "id": 32013,
        "name": "The Open University of HongKong"
    }],
    "name": "\u9999\u6e2f"
},
{
    "id": 33,
    "school": [{
        "id": 33001,
        "name": "\u6fb3\u95e8\u5927\u5b66"
    },
    {
        "id": 33002,
        "name": "\u6fb3\u95e8\u7406\u5de5\u5b66\u9662"
    },
    {
        "id": 33003,
        "name": "\u6fb3\u95e8\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 33004,
        "name": "\u6fb3\u95e8\u65c5\u6e38\u5b66\u9662"
    },
    {
        "id": 33005,
        "name": "\u6fb3\u95e8\u955c\u6e56\u62a4\u7406\u5b66\u9662"
    },
    {
        "id": 33006,
        "name": "\u6fb3\u95e8\u57ce\u5e02\u5927\u5b66"
    },
    {
        "id": 33007,
        "name": "\u5929\u4e3b\u6559\u8f85\u4ec1\u5927\u5b66"
    },
    {
        "id": 33008,
        "name": "\u957f\u5e9a\u5927\u5b66"
    },
    {
        "id": 33009,
        "name": "\u79c1\u7acb\u4e49\u5b88\u5927\u5b66"
    },
    {
        "id": 33010,
        "name": "\u4e1c\u6d77\u5927\u5b66"
    }],
    "name": "\u6fb3\u95e8"
},
{
    "id": 34,
    "school": [{
        "id": 34001,
        "name": "\u56fd\u7acb\u53f0\u6e7e\u5927\u5b66"
    },
    {
        "id": 34002,
        "name": "\u56fd\u7acb\u4e2d\u6b63\u5927\u5b66"
    },
    {
        "id": 34003,
        "name": "\u56fd\u7acb\u53f0\u6e7e\u827a\u672f\u5927\u5b66"
    },
    {
        "id": 34004,
        "name": "\u56fd\u7acb\u6e05\u534e\u5927\u5b66"
    },
    {
        "id": 34005,
        "name": "\u56fd\u7acb\u4e2d\u5c71\u5927\u5b66"
    },
    {
        "id": 34006,
        "name": "\u56fd\u7acb\u653f\u6cbb\u5927\u5b66"
    },
    {
        "id": 34007,
        "name": "\u56fd\u7acb\u6210\u529f\u5927\u5b66"
    },
    {
        "id": 34008,
        "name": "\u56fd\u7acb\u4e2d\u592e\u5927\u5b66"
    },
    {
        "id": 34009,
        "name": "\u56fd\u7acb\u9633\u660e\u5927\u5b66"
    },
    {
        "id": 34010,
        "name": "\u56fd\u7acb\u4e1c\u534e\u5927\u5b66"
    },
    {
        "id": 34011,
        "name": "\u56fd\u7acb\u4e2d\u5174\u5927\u5b66"
    },
    {
        "id": 34012,
        "name": "\u56fd\u7acb\u53f0\u6e7e\u5e08\u8303\u5927\u5b66"
    },
    {
        "id": 34013,
        "name": "\u56fd\u7acb\u53f0\u6e7e\u6d77\u6d0b\u5927\u5b66"
    },
    {
        "id": 34014,
        "name": "\u56fd\u7acb\u9ad8\u96c4\u5927\u5b66"
    },
    {
        "id": 34015,
        "name": "\u56fd\u7acb\u5609\u4e49\u5927\u5b66"
    },
    {
        "id": 34016,
        "name": "\u56fd\u7acb\u53f0\u6e7e\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 34017,
        "name": "\u56fd\u7acb\u7a7a\u4e2d\u5927\u5b66"
    },
    {
        "id": 34018,
        "name": "\u56fd\u7acb\u53f0\u5317\u5927\u5b66"
    },
    {
        "id": 34019,
        "name": "\u56fd\u7acb\u9ad8\u96c4\u5e08\u8303\u5927\u5b66"
    },
    {
        "id": 34020,
        "name": "\u56fd\u7acb\u8054\u5408\u5927\u5b66"
    },
    {
        "id": 34021,
        "name": "\u56fd\u7acb\u66a8\u5357\u56fd\u9645\u5927\u5b66"
    },
    {
        "id": 34022,
        "name": "\u56fd\u7acb\u5c4f\u4e1c\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 34023,
        "name": "\u56fd\u7acb\u5f70\u5316\u5e08\u8303\u5927\u5b66"
    },
    {
        "id": 34024,
        "name": "\u56fd\u7acb\u53f0\u5317\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 34025,
        "name": "\u56fd\u7acb\u53f0\u4e1c\u5927\u5b66"
    },
    {
        "id": 34026,
        "name": "\u56fd\u7acb\u5b9c\u84dd\u5927\u5b66"
    },
    {
        "id": 34027,
        "name": "\u56fd\u7acb\u53f0\u5317\u827a\u672f\u5927\u5b66"
    },
    {
        "id": 34028,
        "name": "\u56fd\u7acb\u4e91\u6797\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 34029,
        "name": "\u56fd\u7acb\u53f0\u5357\u5927\u5b66"
    },
    {
        "id": 34030,
        "name": "\u56fd\u7acb\u864e\u5c3e\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 34031,
        "name": "\u56fd\u7acb\u82b1\u83b2\u6559\u80b2\u5927\u5b66"
    },
    {
        "id": 34032,
        "name": "\u56fd\u7acb\u9ad8\u96c4\u7b2c\u4e00\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 34033,
        "name": "\u56fd\u7acb\u5c4f\u4e1c\u6559\u80b2\u5927\u5b66"
    },
    {
        "id": 34034,
        "name": "\u56fd\u7acb\u65b0\u7af9\u6559\u80b2\u5927\u5b66"
    },
    {
        "id": 34035,
        "name": "\u56fd\u7acb\u6f8e\u6e56\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 34036,
        "name": "\u5efa\u56fd\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 34037,
        "name": "\u56fd\u7acb\u53f0\u5357\u827a\u672f\u5927\u5b66"
    },
    {
        "id": 34038,
        "name": "\u56fd\u7acb\u9ad8\u96c4\u5e94\u7528\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 34039,
        "name": "\u56fd\u7acb\u9ad8\u96c4\u6d77\u6d0b\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 34040,
        "name": "\u56fd\u7acb\u52e4\u76ca\u79d1\u6280\u5927\u5b66"
    },
    {
        "id": 34041,
        "name": "\u4e1c\u5434\u5927\u5b66"
    },
    {
        "id": 34042,
        "name": "\u56fd\u7acb\u4ea4\u901a\u5927\u5b66"
    },
    {
        "id": 34043,
        "name": "\u9022\u7532\u5927\u5b66"
    },
    {
        "id": 34044,
        "name": "\u9ad8\u96c4\u91ab\u5b78\u5927\u5b78"
    },
    {
        "id": 34045,
        "name": "\u6de1\u6c5f\u5927\u5b78"
    },
    {
        "id": 34046,
        "name": "\u8f85\u4ec1\u5927\u5b66"
    },
    {
        "id": 34047,
        "name": "\u53f0\u6e7e\u9759\u5b9c\u5927\u5b66"
    },
    {
        "id": 34048,
        "name": "\u4e2d\u56fd\u6587\u5316\u5927\u5b66"
    },
    {
        "id": 34049,
        "name": "\u80b2\u9054\u79d1\u6280\u5927\u5b78"
    },
    {
        "id": 34050,
        "name": "\u4e16\u65b0\u5927\u5b66"
    },
    {
        "id": 34051,
        "name": "\u94ed\u4f20\u5927\u5b66"
    },
    {
        "id": 34052,
        "name": "\u56fd\u7acb\u53f0\u5317\u6559\u80b2\u5927\u5b66"
    },
    {
        "id": 34053,
        "name": "\u53f0\u6e7e\u4e9a\u6d32\u5927\u5b66"
    },
    {
        "id": 34054,
        "name": "\u4e2d\u539f\u5927\u5b66"
    },
    {
        "id": 34055,
        "name": "\u94ed\u4f20\u5927\u5b66\u00a0"
    }],
    "name": "\u53f0\u6e7e"
}]

export default dataList;