// 表单验证规则

import JSEncrypt from 'jsencrypt';


const realName = value => (value && value.length > 0) ? [true, ''] : [false, '真实姓名不能为空']

const username = value => (value && value.length > 3 && value.length < 17) ? [true, ''] : [false, '用户名长度为4~16位']

const sex = value => value ? [true, ''] : [false, '性别不能为空']

const phone = value => {
    if (value && value.length === 11 && value[0] == 1) {
        return [true, '']
    } else {
        return [false, '请输入正确的手机号码。']
    }
}

const email = value => {
    //const re = /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/
    const re = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
    if (re.test(value)) {
        return [true, '']
    } else {
        return [false, '请输入正确的邮箱账号。']
    }
}

const occupation = value => (value && value.length > 0) ? [true, ''] : [false, '单位不能为空']


const encodeURI = value => {
    return encodeURIComponent(JSON.stringify(value));
}

const decodeURI = value => {
    return JSON.parse(decodeURIComponent(value) || '{}');
}


// 指定长度和基数
const uuid = (len, radix) => {
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    let uuid = [],
        i;
    radix = radix || chars.length;
    if (len) {
        // Compact form
        for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
    } else {
        // rfc4122, version 4 form
        let r;
        // rfc4122 requires these characters
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
        uuid[14] = '4';
        // Fill in random data.  At i==19 set the high bits of clock sequence as
        // per rfc4122, sec. 4.1.5
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | Math.random() * 16;
                uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
            }
        }
    }
    return uuid.join('');
}

const objTurnParams = params => {
    if (!params) return '';
    let cache = [];
    for (const param in params) {
        cache.push(param + '=' + params[param]);
    }
    if (cache.length) {
        return '?' + cache.join('&');
    } else {
        return '';
    }
}

function getClass(o) { //判断数据类型
    return Object.prototype.toString.call(o).slice(8, -1);
}

function cloneObj(obj) {
    var result, oClass = getClass(obj);
    if (oClass == "Object") result = {}; //判断传入的如果是对象，继续遍历
    else if (oClass == "Array") result = []; //判断传入的如果是数组，继续遍历
    else return obj; //如果是基本数据类型就直接返回
    for (var i in obj) {
        var copy = obj[i];
        if (getClass(copy) == "Object") result[i] = cloneObj(copy); //递归方法 ，如果对象继续变量obj[i],下一级还是对象，就obj[i][i]
        else if (getClass(copy) == "Array") result[i] = cloneObj(copy); //递归方法 ，如果对象继续数组obj[i],下一级还是数组，就obj[i][i]
        else result[i] = copy; //基本数据类型则赋值给属性
    }

    return result;
}

function isFunction(obj) {
    var object, type = getClass(obj);
    if (type == "Function") return true;
    return false;
}

function isDate(obj) {
    var object, type = getClass(obj);
    if (type == "Date") return true;
    return false;
}

const formatTree = (source, func) => {
    let cache = source;
    while (true) {
        let data = [];
        cache.map(item => {
            if (item.coztask_data && Array.isArray(item.coztask_data.result)) {
                item.children = item.coztask_data.result;
                if (func) {
                    data.push(...func(item.children));
                } else {
                    data.push(...item.children);
                }
            }
        })
        if (data.length === 0) break;
        cache = data;
    }
};

function TODOAfterPageWrapperRendered() {
    let $body = window.document.body,
        mainUi = $($body).find('#main-ui')[0];
    if (!mainUi) return;
    window.parent.postMessage({ iframeHeight: mainUi.clientHeight, type: 'iframe' }, '*');
}

const updateRouteTitle = (title) => {
    window.postMessage({ type: 'route', title })
}

const echartsOption = (params) => {
    const { xData, data, yName } = params;
    const option = {
        xAxis: {
            type: 'category',
            //data: ['A', 'B', 'C']
            data: xData,
        },
        yAxis: {
            type: 'value',
            //name:'日访问量',
            name: yName,
            nameTextStyle: {
                fontWeight: 'bold',
                color: '#333333',
                left: '1%',
                fontSize: '14px'
            },
            axisLabel: {
                margin: 0,
            }
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross', //指示类型
                label: {
                    // 横纵坐标指示区块颜色
                    backgroundColor: '#6a7985'
                }
            }
        },
        series: [{
            //data: [120, 200, 150],
            data: data,
            type: 'line'
        }],
        grid: {
            top: '9%',
            left: '2%',
            right: '2%',
            bottom: '1%',
            containLabel: true
        }
    }
    return option;
}


/**
 * jsencrypt加密
 */
const encryption = function(data) {
    var publicKey = `-----BEGIN PUBLIC KEY-----
      MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0pwujIMmZ28QGtgTCIEv
      qsyMy5RG3C6pYJ9AX+eX4DQkBRvjQ/1nNMWyp8T9VMFKrXlpIhQWBaeiJ6OoQ9ue
      5FfBOmN2vQyD/z1APrPCOxNVt80UkPXY4TGRcEC/3npys1HRBaizENL8jVX1unDA
      h99fAhxPvbqoZ9XvgOFdquTRz5sHHZXbsiJ9XrFJDV5M3sE5Ret4ipnXMUkeqewJ
      KUErTqMA8AF27xsUrj2lMWvVR/UC+H9JaOxgtFy0QVQxnQ+v7S7loXgGTeM2PezQ
      3H/UUpSL2moWk3eE3MhBVuKuDBiZstR1LWY5lnVjt6ZURCw/QaiQaQ99exiATfCD
      XwIDAQAB
      -----END PUBLIC KEY-----`
    var encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    var encrypted = encrypt.encrypt(data);
    return encrypted;
}

const doubleNum = function(num) {
    if (num < 10)
        return "0" + num.toString();
    else
        return num.toString();
};


const analysisRouter = (routes) => {
    let data = routes,
        routeMap = {};
    while (true) {
        let cache = [];
        data.map(route => {
            if (route.children && route.children.length) {
                cache.push(...route.children);
            }
            routeMap[route.name] = route;
        })
        if (cache.length === 0) break;
        data = cache;
    }
    return routeMap;
}

const getQueryParams = function(url) {
    let str = url,
        data = {};
    if (!str) str = window.location.href.split('?')[1];
    if (!str) return {};
    str = str.split('&');
    str.map(item => {
        let map = item.split('=');
        data[map[0]] = map[1];
    })
    return data;
};

// get root folder
function getPrePathname(href) {
    var reg = /http[s]?:\/\/[^\/]*\/([^\/\#\?]*).*/g;
    var result = reg.exec(href);
    if (result) {
        return '/' + result[1];
    }
    return '';
}

//时间戳转日期
function timestampToDate(timestamp, format = 'YYYY-MM-DD hh:mm:ss') {
    let date = new Date(parseInt(timestamp));
    let Y = date.getFullYear();
    let M = doubleNum(date.getMonth() + 1);
    let D = doubleNum(date.getDate());
    let h = doubleNum(date.getHours());
    let m = doubleNum(date.getMinutes());
    let s = doubleNum(date.getSeconds());
    format = format.replace('YYYY', Y).
    replace('MM', M).
    replace('DD', D).
    replace('hh', h).
    replace('mm', m).
    replace('ss', s);
    return format;
};

function getDateStr(date) {
    return timestampToDate(+date, 'YYYY-MM-DD');
}

function getDate(time) {
    return new Date(timestampToDate(time, 'YYYY-MM-DD'))
}



const checker = {
    realName,
    username,
    sex,
    phone,
    email,
    occupation,
    encodeURI,
    decodeURI,
    uuid,
    objTurnParams,
    isFunction,
    isDate,
    formatTree,
    TODOAfterPageWrapperRendered,
    encryption,
    echartsOption,
    updateRouteTitle,
    doubleNum,
    analysisRouter,
    getQueryParams,
    getPrePathname,
    timestampToDate,
    cloneObj,
    getDateStr,
    getDate,
}

export default checker