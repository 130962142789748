<template>
    <div class="answer-ui">
        <div class="answer-item" v-for="itemData in data" :key="itemData.key">
            <AnswerLoopItem :data="itemData"
                @handleRelease='(data,parent)=>$emit("handleRelease",data,parent)'
                @handleDelete='(data,parent)=>$emit("handleDelete",data,parent)'
                @handleUpdate='(data,parent)=>$emit("handleUpdate",data,parent)'
                @loadData='(data,back)=>$emit("loadData",data,back)' 
                @toClick="handleDetail"
                @handleClose="(isClose)=>itemData.isClose = isClose"
                :showChildBelow="showChildBelow"
                :isDelete='isDelete'
                :isUpdate='isUpdate'
                :isHidden='true' 
                :isShowReply='isShowReply'
                :showReplyCount='showReplyCount'
                :isReply='isReply'
                :parentid="itemData.key"
                :resource="uploadData"
                :floor="floor" :uploadFloorData="uploadFloorData">
                </AnswerLoopItem>
            <div class="answer-children" v-if="!itemData.isClose && floor > 1">
                <AnswerLoop 
                    @handleRelease='(data,parent)=>$emit("handleRelease",data,parent)'
                    @handleDelete='(data,parent)=>$emit("handleDelete",data,parent)'
                    @handleUpdate='(data,parent)=>$emit("handleUpdate",data,parent)'
                    @loadData='(data,back)=>$emit("loadData",data,back)' 
                    @toClick="data=>$emit('toClick',data)"
                    :isDelete='isUpdate'
                    :isUpdate='isUpdate'
                    :isHidden='true' 
                    :resource = 'uploadAction'
                    :isShowReply='isShowReply'
                    :pageSmall="true"
                    :parentid="itemData.key"
                    :uploadData="uploadData"
                    :floor="floor-1" :getFloorData="getFloorData" :getFloorCount="getFloorCount" :uploadFloorData="uploadFloorData"></AnswerLoop>
            </div>
        </div>
    </div>
    <div class="answer-page">
        <Pagination v-if="showPaging" :total='paging.total || 0'
                :pageSize='paging.pageSize || 10' :currentPage='paging.currentPage || 1'
                :small="pageSmall"
                @pageChange='pageChange' />
        <div class="answer-more" v-else-if="!showPaging && showMoreAnswerBth">
            <span class="more-btn" @click="getMoreData">{{ moreBtnMsg }}</span>
        </div>
    </div>
</template>

<script>

import AnswerLoopItem from './AnswerLoopItem.vue';
import Pagination from '@/components/course/Pagination.vue';
export default {
    name : 'answer_loop',
    data (){
        return {
            paging: {
                total: 0,
                pageSize: 10,
                currentPage: 1,
            },
            data: [],
            showMoreAnswerBth: true,
            moreBtnMsg: "查看更多数据",
            disableMoreBtn: false
        }
    },
    emits : ['toReply','handleRelease','handleDelete','handleUpdate','loadData','toClick'],
    props : {
        pageSmall: {
            type: Boolean,
            default: false
        },
        resource : {
            type : Object,
            default : ()=>{return {}}
        },
        isReply : {
            type : Boolean,
            default : false,
        },
        isShowReply : {
            type : Boolean,
            default : true,
        },
        showReplyCount : {
            type : Boolean,
            default : false,
        },
        parent : {//存储上一级数据
            type : Object,
            default : ()=>{return {}}
        },
        isHidden : {//是否支持隐藏
            type : Boolean,
            default : false,
        },
        isDelete : {
            type : Boolean,
            default : true,
        },
        isUpdate : {
            type : Boolean,
            default : true,
        },
        floor: {
            type: Number,
            default: 1,
        },
        getFloorData: {
            type: Function,
            default() {
                return () => {};
            },
        },
        getFloorCount: {
            type: Function,
            default() {
                return () => {};
            },
        },
        uploadFloorData: {
            type: Function,
            default() {
                return () => {};
            },
        },
        parentid: {
            type: [Number,String],
            default: 0,
        },
        // 是否在下方展开显示子级内容
        showChildBelow: {
            type: Boolean,
            default: false
        },
        // 是否显示分页
        showPaging: {
            type: Boolean,
            default: false
        },
        uploadData : {//文件上传地址
            type : Object,
            default : ()=>{return {
                url : '',//地址
                data : {},//参数
            }},
        }
    },
    mounted (){
        this.getAnswer();
    },
    computed : {
    },
    watch : {
        
    },
    components : {
        AnswerLoopItem,
        Pagination,
    },
    methods : {
        setAnswer(data) {
            this.data = data;
        },
        setMoreAnswer(data) {
            if(data.length) this.data.push(...data);
            else {
                this.moreBtnMsg = "没有更多数据了";
                this.disableMoreBtn = true;
                setTimeout(() => {
                    this.showMoreAnswerBth = false; 
                }, 2000);
            }
        },
        setPage(count) {
            this.paging.total = Number(count) || 0;
        },
        pageChange (currentPage){
            this.paging.currentPage = currentPage;
            let {floor, paging, parentid, setAnswer} = this;
            this.getFloorData(floor, paging, parentid, setAnswer);
        },
        getAnswer () {
            let {floor, paging, parentid, setAnswer, setPage} = this;
            this.getFloorData(floor, paging, parentid, setAnswer);
            this.getFloorCount(floor, parentid, setPage);
        },
        getMoreData() {
            if(this.disableMoreBtn) return false;
            this.paging.currentPage++;
            let {floor, paging, parentid, setMoreAnswer} = this;
            this.getFloorData(floor, paging, parentid, setMoreAnswer);
        },
        handleDetail(data) {
             console.log(data)
            this.$emit('toClick',data, () => {
               
                let {floor, paging, parentid, setAnswer} = this;
                this.getFloorData(floor, paging, parentid, setAnswer);
            });
        }
    },
}
</script>

<style scoped lang='less'>
    .answer-ui{
        .answer-item{        
            .answer-children{
                padding-left:20px;
                .answer-ui{
                    //padding: 5px 10px 8px 10px;
                    //background-color: #F5F5F5;
                    border-radius: 10px;
                    //margin: 10px 0;
                    .answer-item-li{
                        padding-right: 0;
                    }
                    .answer-item{
                        background-color: #F5F5F5;
                        padding: 0 10px;
                        border-radius: 4px;
                    }
                }
                .answer-page {
                    background-color: #F5F5F5;
                }
            }
        }
    }
    .answer-page {
        width: 100%;
        text-align: center;
        padding: 20px 0 10px;
        .answer-more {
            .more-btn {
                color: #999;
                cursor: pointer;
                &:hover {
                    color: #2974EA;
                }
            }
        }
    }
</style>