<template>
  <div class="echars-ui">
      <div class="echars-main" ref="echars" style="width:100%;height:100%"></div>
  </div>
</template>

<script>
import options from './index';

const mousewheelevt = (/Firefox/i.test(navigator.userAgent)) ? "DOMMouseScroll" : "mousewheel"; //FF doesn't recognize mousewheel as of FF3.x

export default {
    data() {
        return {
            updateFlag: false,
        }
    },
    mounted (){
        this.$nextTick(()=>{
            this.init(this.data);
        })
    },
    
    watch : {
        'data' : 'monitorData'
    },
    methods : {
        monitorData (data){
            this.init(data);
        },
        init(data) {
            const {$refs:refs,option,type} = this;
            let mychart = this._echarts.init(refs.echars),
            ops = {
                name : '综合成绩评定',
                size : '150px',
                formatter : this.formatter,
                ...option,
                data : data,
            }
            mychart.setOption(options(type)(ops),true);
            if(this.updateInit) this.updateData(-150, mychart);
            if(this.updateOptions.scroll) this.scrollData(mychart);
        },
        scrollData(mychart) {
            const {$refs:refs} = this;
            let timer = new Date();
            refs.echars.addEventListener(mousewheelevt, (event) => {
                event.preventDefault();

                if(this.updateFlag || new Date() - timer < 1000) return;
                this.updateFlag = true;
                timer = new Date();
                this.updateData(event.wheelDelta, mychart);
            }, { passive: false });
        },
        updateData(delta, mychart) {
            mychart.showLoading();
            this.updateMethod(delta, (series) => {
                mychart.hideLoading();
                this.updateFlag = false;
                if(series) mychart.setOption({ "series": series });
            });
        }
    },
    props : {
        updateInit: {
            type: Boolean,
            default: false
        },
        option : {
            type : Object,
            default : ()=>{
                return {
                    name : '综合成绩评定',
                    size : '150px',
                    formatter : ()=>'{d}%',
                }
            },
        },
        data : {
            type : Array,
            default : ()=>{
                return []
            }
        },
        type : {
            type : String,
            default : 'pie',
        },
        updateOptions: {
            type : Object,
            default: {
                "scroll": false
            }
        },
        updateMethod: {
            type: Function,
            default: () => {},
        },
        formatter: {
            type: Function,
            default: () => {},
        },
    },
}
</script>

<style lang="less" scoped>
    .echars-ui{
        width:100%;
        height:100%;
        .echars-main{
            width:100%;
            height:100%;
        }
    }
</style>