<template>
  <!-- 海报统计 -->
  <div class="mainn">
    <img :src="require('@/assets/images/xxl_gw/postcard.png')" alt="">
    <div class="mainn-box common-width">
      <div class="content_blockk" v-for="item in listData" :key="item.key">
        <!-- <span class="block_title">{{item.data}}</span> -->
        <span class="block_title">
          <count_up @init="count_up_init" ref="countUp" :end-val="item.data"></count_up>+
        </span>
        <span class="block_title">

        </span>
        <span class="block_content">{{ item.content }}</span>
      </div>
    </div>


  </div>
</template>

<script>	
import count_up from 'vue-countup-v3';	
import axiosTool from '@/utils/axios_tool';
import server from "@/service/server"
import common from '@/utils/common';
export default{	
  components:{ count_up,},	
  created(){
     this.axiosTool = new axiosTool(this,[
       {
        key:'get_aboutValue',type:'get',title:'获取资质数据',url:server.honor
       },
     ])
  },
  mounted(){
    this.axiosTool._axios().then(data => {
    const [get_aboutValue] = data;
    this.handle_aboutValue(get_aboutValue)
    })
  },
  props : {
    refresh : {
      type : String,
      default : '',
    }
  },
  watch : {
    refresh : 'monitorRefresh',
  },
  data(){	
    return{	
      listData:[]	,
      listDataCopy : [],
      countUp : {},
    }	
  },
  methods:{
    count_up_init (ctx){
      this.countUp = ctx;
    },
    monitorRefresh (refresh){
      const {countUp} = this;
      const cache = common.cloneObj(this.listDataCopy);
      if(countUp && countUp.reset && refresh == 'show'){
        this.$nextTick (()=>{
          this.listData = cache;
          countUp.reset();
          countUp.start()
        })
      }else {
        this.listData = [...this.listData.map(o=>{
          o.data = 0;
          return o;
        })];
      }
      
    },
    handle_aboutValue(res){
      let array = [],listData ={}
      if(res.status && res.data && res.data.data && res.data.data.result){
         listData  =  res.data.data.result[0];
         array=[
           {
             content:listData.name1,
             data:listData.name1Count
           },
           {
             content:listData.name2,
             data:listData.name2Count
           },
           {
             content:listData.name3,
             data:listData.name3Count
           },
           {
             content:listData.name4,
             data:listData.name4Count
           },

         ]
         this.listData = [...array]
         this.listDataCopy = [...array];
        }
    }
  }
}	

</script>	



<style scoped lang="less">
@import url('@/assets/less/theme.less');
@import url('@/styles/common.less');
.media();
.mainn {
  display: flex;
  position: relative;
  // height:171px;
  margin: 0 auto;
  justify-content: space-around;
  // padding: 0 380px;

}

.mainn img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.mainn .mainn-box{
  width:100%;
  display: flex;
  justify-content: inherit;
  align-items: center;
  height: 171px;
}

.content_blockk {
  position: relative;
  z-index: 1;
  color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 37px;
}

.block_title {
  font-weight: bolder;
  font-size: 40px;
  display: flex;
  justify-content: center;

}

.block_content {
  /* font-weight: bolder; */
  font-size: 18px;
}
</style>