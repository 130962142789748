<template>
    <div class='child-node'>
        <div class='node-item' v-for='item in source' :key='item.key'>
            <div class='item-top'>
                <img :src="item.userIcon" alt="">
                <span class='node-name'>{{item.realname}}</span>

                <span class='node-reply'>回复</span>

                <img :src="item.toIcon" alt="">
                <span class='node-name'>{{item.to || ''}}</span>
            </div>
            <span class='node-content'>{{item.content}}</span>
            <div class='node-media'>
                <div @click="handleClick('选中媒体',{...media,key:item.key})" class='media-item' v-for='media in item.medias' :key='media.key'>
                    <img v-if="media.type==='picture'" :src="media.url" alt="">
                    <Videos v-if="media.type==='video'" :videoUrl="media.url"></Videos>
                </div>
            </div>
            <template v-if='pitchMedia[item.key]'>
                <div  class='evaluate-show' :title='pitchMedia[item.key].url'>
                    <img v-if="pitchMedia[item.key].type==='picture'" :src="pitchMedia[item.key].url" alt="">
                    <Videos v-if="pitchMedia[item.key].type==='video'" :videoUrl="pitchMedia[item.key].url"></Videos>
                </div>
            </template>
            
            <div class='item-bottom'>
                <span class='node-time'>{{item.createtime}}</span>
                <div class='node-option'>
                    <!-- <span v-for="(name,idx) in option" :key='idx' @click="handleClick(name,item)"> -->
                      <span v-for="(name,idx) in optionData" :key='idx' @click="handleClick(name,item)">
                        <span class='option-icon' v-if="name==='评论'">
                            <i class='xxl-comment'></i>
                             {{item.evaluate_size || 0}}
                        </span>
                        <template v-else>{{name}}</template>
                    </span>
                </div>
            </div>
        </div>
        <div class='node-loading' v-if="paging.total!='0'">
            还有<span class='node-count'>{{paging.total}}</span>
            条评论，
            <span class='node-btn' @click="handleClick('查看更多')">点击查看</span> 
        </div>

        <div class='node-loading' v-else>
            <span class='node-btn no'>已加载全部数据</span>
        </div>
        <div :class="['node-input',!isEdit?'hidden' : '']">
            <div class='node-upload'>
                <Uploads 
                    v-model:data='uploadData'
                    v-model:result='uploadResult'
                    modules="btn" 
                    btnPosition="bottom" 
                    class="resources-upload"
                    :accepts='["jpg","png","mp4"]' 
                    :uploadData='requestData'
                    @uploadBefore='uploadBefore'  
                    >
                    <template #btnName>上传图片/视频</template>
                    <template #上传提示>你只可上传9张图片和视频，图片大小不得超过5M，视频大小不得超过50M哦~</template>
                </Uploads>
            </div>
            <el-input size='small' resize='none' :autosize='{minRows:6,maxRows:10}' v-model="formData.value" maxlength="100" placeholder="请输入评论" show-word-limit type="textarea" />
            <div class='upload-btn'>
                <div class='upload-msg'>
                    <img :src="userData.face" alt="">
                    <span class='node-name'>{{userData.realname}}</span>
                    <span class='node-reply'>回复</span>
                    <img src="" alt="">
                    <span class='node-name'> </span>
                </div>
                <Buttons type='info' @click="handleClick('取消')">取消</Buttons>
                <Buttons type='blue' @click="handleClick('发布')">发布</Buttons>
            </div>
        </div>
    </div>
</template>

<script>
import Uploads from '@/components/common/Uploads/Uploads.vue';
import Buttons from '@/components/course/Buttons.vue';
import Videos from '@/components/course/Videos.vue';
import axiosTool from '@/utils/axios_tool';

export default {
    name: 'evaluateNode',

    data() {
        return {
            uploadData:[],
            uploadResult : [],
            requestData : {},
            value : '',
            isEdit : false,//编辑状态
            source : [],//数据源
            editor:{},//编辑的数据对象
            paging : {//评论回复的分页
                total : 0,
                pageSize : 5,
                currentPage : 1,
            },
            formData : {},
            pitchMedia : {},//选中的媒体
            optionData:[],
        };
    },
    props : {
        data : {
            type : Array,
            default : ()=>{return []}
        },
        option : {
            type : Array,
            default : ()=>{return []}
        },
        evalData : {
            type : Object,
            default : ()=>{return {}}
        },
        userData : {
            type : Object,
            default : ()=>{return {}},
        },
    },
    watch : {
        data : 'dataMonitor',
        evalData : 'evalDataMonitor',
        uploadResult : 'uploadResultMonitor',
        optionMonitor:'optionMonitor'
    },
    mounted() {
        this.dataMonitor();
        this.evalDataMonitor();
        this.optionMonitor();
    },
    emits : ['requestBefore','toClick'],
    methods: {
        optionMonitor(){
          this.optionData = this.option.concat();
          let length = this.optionData.length;
          var test2;
          if(length<=2){
              test2=this.optionData.filter((item,index)=>(index!=0));
          }else{
              test2=this.optionData.filter((item,index)=>(index!=0)&&(index!=1));
          }
         
          this.optionData = test2;   


        },
        uploadResultMonitor (uploadResult){
            let medias = [];
            if(Array.isArray(uploadResult)){
                uploadResult.map(item=>medias.push(item.url));
                this.formData.medias = medias;
            }
        },
        evalDataMonitor (evalData=this.evalData){
            this.handleCount();
        },
        dataMonitor (data=this.data){
            let media = [],type;
            data = data.map(item=>{
                if(item.evalresource_data && item.evalresource_data.filepath){
                    item.medias = [];
                    media = item.evalresource_data.filepath.split(',');
                    media.map((url,idx)=>{
                        if(/\.jpg$|\.png$|.jpeg$/.test(url))type='picture';
                        else type='video';
                        item.medias.push({url, type,key:idx});
                    })
                }
                return item;
            })
          
            this.source = data;
            this.$emit('requestBefore',{type:'上传',callback:data=>{
                //设置上传参数
                this.requestData = {...data,};
            }});
            this.handleCount();
        },
        uploadBefore (){

        },
        handleCount (){
            const {data,evalData} = this;
            this.paging.total = evalData.answer_size - data.length;
            this.paging.total = this.paging.total < 0 ? 0 : this.paging.total;
        },
        handleClick (type,data){
            const {evaluate,pitchMedia,formData,evalData,editor,value,paging} = this;
            let pageSize,cache={};
            switch (type){
                case '回复' :
                    if(editor.key && data.key && editor.key === data.key){
                        this.isEdit = !this.isEdit;
                    }else {
                        this.isEdit = true;
                        this.editor = data;
                    }
                    break;
                case '取消' : 
                    this.isEdit = false;
                    break;
                case '发布' : 
                    
                    pageSize = paging.currentPage * paging.pageSize;
                    this.$emit('requestBefore',{type,data:{
                        ...editor,pageSize,parent : evalData, _value:formData.value, _medias : formData.medias || [],
                        },callback:state=>{
                        //请求发布操作
                        
                    }});
                    this.formData = {};
                    this.uploadData = [];
                    this.uploadResult = [];
                    break;
                case '点赞' : 
                    this.$emit('requestBefore',{type,data:{...editor},callback:result=>{
                        //请求点赞操作
                    }});
                    break;
                case '选中媒体' : 
                    cache[data.key]=data;
                    this.pitchMedia = {...pitchMedia,...cache};
                    break;
                case '删除' :
                    
                    this.$confirm('此操作不可逆，是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$emit('requestBefore',{type,data:{...data},callback:state=>{
                            //请求删除操作
                            if(state){
                                pageSize = paging.currentPage * paging.pageSize;
                                this.$emit('requestBefore',{type:'刷新',data:{...evalData,pageSize}});
                            }
                        }});
                    }).catch(() => {});
                    break;
                case '查看更多' : 
                    paging.currentPage ++;
                    pageSize = paging.currentPage * paging.pageSize;
                    this.$emit('requestBefore',{type:'刷新',data:{...evalData,pageSize},callback:result=>{
                        //请求删除操作
                        this.handleCount();
                    }});
                    
                    break;
                case '评论' : 
                    this.$emit('requestBefore',{type,data:data});
                    break;
                default : 
                    
                    break;
            }
        },
        requestDelete (){//请求删除操作

        },
        requestFabulous (){//请求点赞操作

        },
        requestRelease (){//请求发布操作

        },
    },
    components : {
        Uploads,
        Buttons,
        Videos,
    },
};
</script>

<style lang="less" scoped>
    .node_public {
        >img{
            width:24px;
            height:24px;
            margin-right:10px;
            flex:0 0 auto;
        }
        .node-name{
            color:#6d757a;
            margin-right:10px;
            font-size:12px;
            flex:0 0 auto;
        }
        .node-reply{
            font-size:16px;
            padding-right: 10px;
            flex:0 0 auto;
        }
    }
    .option-btn{
        display:flex;
        align-items: center;
    }
    .option-icon{
        font-size: 12px;
        display:flex;
        align-items:center;
        >i{
            font-size: 18px;
            //margin-right:0;
            &.true{
                color:#00A1D6;
            }
        }
        
    }
    .node-media{
        margin-top:10px;
        flex-wrap: wrap;
        display:flex;
        .media-item{
            width: 130px;
            height: 78px;
            margin-bottom: 10px;
            margin-right:10px;
            >img{
                width: 100%;
                height: 100%;
                cursor:pointer;
            }
        }
    }
    .evaluate-show{
        width:100%;
        height:300px;
        padding-bottom:10px;
        >img{
            height:100%;
        }
    }
    .child-node{
        .node-item{
            margin-bottom: 20px;
            .item-top{
                display: flex;
                align-items: center;
                //padding-bottom:20px;
                .node_public;
                
            }
            .node-content{
                color: #222;
                font-size: 14px;
                margin: 10px 0 10px 34px;
                display: inline-block;
                line-height: 20px;
            }
            .item-bottom{
                display: flex;
                align-items: center;
                color:#99a2aa;
                padding-left:34px;
                font-size:12px;
                .node-time{
                    margin-right:20px;
                }
                .node-option{
                    display:flex;
                    align-items:center;
                    >span{
                        cursor:pointer;
                        margin-right:10px;
                    }
                }
            }
        }

        .node-loading{
            color:#6d757a;
            font-size:12px;
            .node-count{
                color: #6d757a;
                font-weight: bold;
            }
            .node-btn{
                padding:6px 10px;
                display:inline-block;
                cursor:pointer;
                border-radius:4px;
                &:hover{
                    background-color:rgb(229,233,239);
                }
                
            }
            .node-btn.no{
                cursor:default;
                &:hover{
                    background-color: transparent;
                }
            }
        }
    }

    .node-input{
        transition:height .2s ease;
        height:auto;
        overflow:hidden;
        padding-left: 34px;
        margin-top: 10px;
        .node-upload{
            padding-bottom:10px;
        }
        .upload-btn{
            padding: 10px 0;
            text-align: right;
            display: flex;
            align-items: center;
            .upload-msg{
                display: flex;
                align-items: center;
                margin-right: auto;
                color:#99a2aa;
                .node_public;
            }
        }
    }
    .node-input.hidden{
        height:0px;
    }
</style>