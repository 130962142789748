<template>
    <div class="expdata-details">
        <PageHeader :goMethod='goMethod'>
            <template #header>
                <div class="expdata-header">
                   <div class="record-detail" >
                      <span><b>姓名：</b>{{recordData.realname}}</span>
                       <span><b>学号：</b>{{recordData.username}}</span>
                        <span><b>班级：</b>{{recordData.iclass}}</span>
                         <span><b>记录生成时间：</b>{{recordData.createtime}}</span>
                          <span><b>实验时长（min）：</b>{{recordData.duration}}</span>
                           <span><b>综合评价：</b>{{recordData.remark || ''}} </span>
                            <span><b>实验操作成绩：</b>{{recordData.param1}}</span>
                             <span><b>实验报告成绩：</b>{{recordData.param2}}</span>
                              <span><b>综合成绩：</b>{{recordData.param3}}</span>

                   </div>
                </div>
            </template>
            <template #content>
              <div class='details-result'>
                    <template v-if="stateObj.remarktype ==='展示评语'">
                        <PageItems>
                            <template #title>报告评语：</template>
                            <span class='result-comment' v-html='stateObj.remark'></span>
                        </PageItems>
                    </template>
                    <template v-if="stateObj.type =='展示HTML字符串'">
                        <PageItems>
                            <template #title>实验报告：</template>
                            <span class='result-report' v-html='stateObj.data'></span>
                        </PageItems>
                    </template>
                    <template v-if="stateObj.type =='展示PDF文件'">
                        <PageItems>
                            <template #title>实验报告：</template>
                            <span class='result-report pdf-report'>
                                <PDFJS v-if="stateObj.filepath" :filepath="stateObj.filepath"></PDFJS>
                            </span>
                        </PageItems>
                    </template>
                   
                    <template v-if="stateObj.type ==='无法展示'">
                        <div class="tip">
                             <span>这份实验报告还没有公开哟~</span>
                        </div>
                           
                        
                    </template>
                </div>
            </template>
        </PageHeader>
    </div>
</template>
<script>
import PageHeader from '@/components/course/PageHeader.vue';
import common from '@/utils/common';
import axios from '@/utils/axios';
import server from '@/service/server';
import checker from '@/utils/checker';
import PageItems from '@/components/course/PageItems.vue';
import WangEditor from '@/components/course/wangEditor/index.vue';
import PDFJS from '@/components/common/PDF/index.vue';


export default{
      name: 'redetail',
    data(){
        return{
          reportTemplate:null,
          reportData:null,
          stateObj:{
            type:'',
            remarktype:"",
            content:'',
            filepath:'',
          },
          datarec:{
            name:"",
            studyid:"",
            iclass:"",
            createTime:"",
            life:"",
            remark:"",
            controllerGrades:"",
            reportGrades:"",
            allGrades:""
          }

        }
    },
    mounted(){
         this.getReport();
        //  this.getMyReport();
        //   this.getReportTemplate();
        },
    methods:{
      
        goMethod (){
            this.$emit('toBack');
        },
        // getReportTemplate() {
        //     // console.log(data)
        //     const {expautoid} = this;
        //     let request = {expautoid: expautoid};
        //     axios.get(server.exprecordset + checker.objTurnParams(request)).then(result => {
        //         console.log(result)
        //         if(result.status && result.data && result.data.result) {
        //             let data =  result.data.result[0] || {};
        //             this.reportTemplate = data;
        //             // console.log(data)
        //             if(data.exprecordtypeid=='3' || data.exprecordtypeid=='1'){

        //             }
        //             this.getReport(data);
        //         } else {
        //             // this.setReportState('报告未提交')
        //         }
        //     })
        //     .catch(() => {
        //         // this.setReportState('报告未提交')
        //     });
        // },

        getReport() {
            const {stateConfig,recordAutoid,expInfo,stateObj} = this;
            // let recordAutoid = this.dataval.key;
            let request = {uiexprecordautoid: recordAutoid};
            axios.get(server.datafile + checker.objTurnParams(request)).then(result => {
                if(result.status && result.data && result.data.result && result.data.result.length) {
                  
                    var srouce = result.data.result,
                    data = srouce[srouce.length-1];
     
                    if(data.approve_data) {
                         var approve_data = data.approve_data.approvetype_name
                         if(approve_data== '待审核' || approve_data== '未提交'){
                            stateObj.type='无法展示';
                         }else if(approve_data=='通过'){
                            stateObj.remarktype = '展示评语'
                            stateObj.remark = data.approve_data.content;
                            if(!!data.content){
                                stateObj.type ='展示HTML字符串';
                                stateObj.data = data.content;
                                
                                
                            }else if(!!data.filepath){
                                stateObj.type ="展示PDF文件";
                                stateObj.filepath = data.filepath;
                            }
                         }
                    
                        
                    } else {
                        this.setReportState('报告未提交')
                    }




                } else {
                    stateObj.type='无法展示';
                }
            })
            // .catch(() => this.setReportState('报告未提交'));
        },

        getMyReport(){
            const {stateConfig,recordAutoid,expInfo,stateObj} = this;
            let request = {expautoid: recordAutoid, get_study_record_by_user: true};
            axios.get(server.dataanalysis + checker.objTurnParams(request)).then(result => {
            })
        }
  
    },
    components:{
        PageHeader,
        common,
        axios,
        server,
        checker,
        PageItems,
        WangEditor,
        PDFJS
    },
    props:{
        dataval : {
            type : Object,
            default : ()=>{return {}}
        },
        expautoid : {
            type : [String,Number],
            default : ()=>{return ''}
        },
        
        recordAutoid: {
            type : [String, Number],
            default : 0
        },
        recordData : {
            type : Object,
            default : ()=>{return {}}
        },
    }
}
</script>

<style scoped>
    .result-report{
        display: inline-block;
        word-break: normal;
        width: 914px;
        overflow: scroll;
        height: 800px;
    }
    
    .result-comment{
        /* padding-left: 35px; */
        width: 96%;
        display: inline-block;
        height: 200px;
        overflow: auto;
        word-break: break-all;
    }
    .tip{
        display: inline-block;
        margin: 0 auto;
        text-align: center;
        color: darkgray;
        font-size: 25px;
        position: absolute;
        top: 200px;
        left: 250px;
    }
    .record-detail{
          display: flex;
        flex-wrap: wrap;
        /* paddiing-left: 12px; */
        margin-left: 45px;
        margin-top: 30px;
        line-height: 25px;
        font-size: 14px;
        color: gray;
}
    
    
    .record-detail>span{
        width: 33%;
    }
    .expdata-header{
        border-bottom: 1px solid black;
        padding-bottom: 14px;
        border-color: gray;
    }
</style>
