import checker from '@/utils/checker.js';
const slotData = [
    { key: checker.uuid(8), slot: 'heads', className: 'head-ui',theme : "black", hidden: false, describe: '登录导航展示' ,floating:true},
    { key: checker.uuid(8),adaptive:true, slot: 'carousel', hidden: false, describe: '轮播图' },
    { key: checker.uuid(8),adaptive:true, slot: 'children', hidden: false, describe: '内容布局部分' },
    // { key: checker.uuid(8), slot: 'news', hidden: false, describe: '新闻中心' },
    // { key: checker.uuid(8), slot: 'solution', hidden: false, describe: '解决方案' },
    // { key: checker.uuid(8), slot: 'ctechnic', hidden: false, describe: '核心技术' },
    // { key: checker.uuid(8), slot: 'goods', hidden: false, describe: '产品中心' },
    // { key: checker.uuid(8), slot: 'school', hidden: false, describe: '合作院校' },
    // { key: checker.uuid(8), slot: 'aboutus', hidden: false, describe: '关于我们' },
    // { key: '008', slot: 'postcard', hidden: false, describe: '海报统计' },
    // { key: checker.uuid(8), slot: 'sprocess', hidden: false, describe: '合作流程' },
    { key: checker.uuid(8), slot: 'footers', className: 'footer-ui', hidden: false, describe: '底部友情展示' },
]

console.log(globalThis.$base_url);
//目前只负责某一个界面的布局渲染 目的是让程序看起来不乱 便于维护 尝试用数据来动态管理布局
export default {
    common: {
        title: '公共的部分',
        layout: 'layout_20230209_2847',//布局模块
        slots: slotData,
        head : [
            {
                key : checker.uuid(8),
                title: '头部导航栏',
                component: 'heads',//vue文件名或者vue内部name 的值
                slot : 'heads',
                exclude : [],//排除的模块，例如不在新闻中心展示头部导航栏
                width:'auto',
                
            },
            {
                key : checker.uuid(8),
                title: '轮播图',
                component: 'carousel',//vue文件名或者vue内部name 的值
                slot : 'carousel',
                exclude : ['goods','expcore'],//排除的模块，例如不在新闻中心展示轮播图
                width:'auto',
            }
        ],
        footer : [
            {
                key : checker.uuid(8),
                title: '底部导航栏',
                component: 'footers',//vue文件名或者vue内部name 的值
                slot : 'footers',
                exclude : [],//排除的模块，例如不在新闻中心底部导航栏
            },
        ],
    },
    home : {
        title : '首页',
        children: [
            // {
            //     key : checker.uuid(8),
            //     title: '轮播图',
            //     component: 'carousel',//vue文件名或者vue内部name 的值
            //     slot : 'carousel',
            // },
            {
                key : checker.uuid(8),
                title: '新闻中心',
                component: 'news',//vue文件名或者vue内部name 的值
                slot : 'news',
            },
            {
                key : checker.uuid(8),
                title: '解决方案',
                component: 'solution',//vue文件名或者vue内部name 的值
                slot : 'solution',
                style : {
                    // backgroundColor : '#F7F8FA',
                    background : `url("/pizdhp/images/home/solution_bg.png") 0 0/100% 100% no-repeat`,
                    padding: '40px 0'
                }
            },
            {
                key : checker.uuid(8),
                title: '核心技术',
                component: 'ctechnic',//vue文件名或者vue内部name 的值
                slot : 'ctechnic',
            },
            {
                key : checker.uuid(8),
                title: '产品中心',
                component: 'goods',//vue文件名或者vue内部name 的值
                slot : 'goods',
                style : {
                    background : `url("/pizdhp/images/home/goods_bg.png") 0 0/100% 100% no-repeat`,
                    padding: '25px 0'
                }
            },
            {
                key : checker.uuid(8),
                title: '合作院校',
                component: 'school',//vue文件名或者vue内部name 的值
                slot : 'school',
            },

            {
                key : checker.uuid(8),
                title: '海报统计',
                component: 'post_card',//vue文件名或者vue内部name 的值
                slot : 'post_card',
            },
           
            {
                key : checker.uuid(8),
                title: '关于我们',
                component: 'aboutus',//vue文件名或者vue内部name 的值
                slot : 'aboutus',
            },
            // {
            //     key : checker.uuid(8),
            //     title: '宣传海报',
            //     component: 'Postcard',//vue文件名或者vue内部name 的值
            //     slot : 'Postcard',
            // },
           
            // {
            //     key : checker.uuid(8),
            //     title: '合作流程',
            //     component: 'sprocess',//vue文件名或者vue内部name 的值
            //     slot : 'sprocess',
            // },
        ],
    },
    // carousel : {
    //     title : '轮播图',
    //     children : [

    //     ],
    // },
    news : {
        title : '新闻中心',
        children : [
            {
                key : checker.uuid(8),
                title: '新闻模块',
                component: 'news_main',//vue文件名或者vue内部name 的值
                slot : 'news_main',
            },
            // {
            //     key : checker.uuid(8),
            //     title: '新闻详情模块',
            //     component: 'GwNoticeText',//vue文件名或者vue内部name 的值
            //     slot : 'GwNoticeText',

            // }
        ],
    },

    solution : {
        title : '解决方案',
        children : [
            {
                key : checker.uuid(8),
                title: '解决方案详情',
                component: 'panel',//vue文件名或者vue内部name 的值
                slot : 'panel',
            }
        ],
    },
    goods : {
        title : '产品中心',
        children : [
            {
                key : checker.uuid(8),
                title: '产品中心模块',
                component: 'goodMain',//vue文件名或者vue内部name 的值
                slot : 'goodMain',
            }

        ],
    },
    download : {
        title : '资源下载',
        children : [
            {
                key : checker.uuid(8),
                title: '资料下载模块',
                component: 'resouce_download',//vue文件名或者vue内部name 的值
                slot : 'resouce_download',
            },
        ],
    },
    aboutus : {
        title : '关于我们',
        children : [
            {
                key : checker.uuid(8),
                title: '关于我们模块',
                component: 'About',//vue文件名或者vue内部name 的值
                slot : 'About',
            },
        ],
    },
    GwNoticeText:{
        title:'新闻详情',
        children:[
            {
                key:checker.uuid(8),
                title:"新闻内容详情模块",
                component:'GwNoticeText',
                slot:'GwNoticeText'
            }
        ]
    }

}