<template>
    <!-- 关于我们 -->
    <layout_20230210_47e9>
        <template #title>关于我们</template>
        <template #describe>
            {{programa.remark}}
        </template>
        <div class="main_us">
            <div class="main_content">
                <img class="content-bg" :src="backgroundImage" alt="">
                <!-- <div class="LineData_top">
                    <div class="LineData_top_1" v-for="item in LineData_top" :key="item.key">
                        <span class="LineData_top_1_title">{{ item.title }} </span>
                        <span class="LineData_top_1_content">{{ item.content }} </span>
                    </div>
                </div>
                <div class="LineData_bottom">
                    <div class="LineData_bottom_1" v-for="item in LineData_bottom" :key="item.key">
                        <span class="LineData_bottom_1_title">{{ item.title }}</span>
                        <span class="LineData_bottom_1_content">{{ item.content }}</span>
                    </div>
                </div> -->
            </div>
        </div>
    </layout_20230210_47e9>
</template>
<script>
import layout_20230210_47e9 from '@/components/layouts/layout_20230210_47e9.vue';
import server from "@/service/server";
import axiosTool from '@/utils/axios_tool'
//海报数据
export default {
    name: "aboutus",
    components: {
        layout_20230210_47e9,
    },
    data() {
        return {
            backgroundImage: require('@/assets/images/xxl_gw/about_us_bkg.png'),
            LineData_top: [
                {
                    title: "2000年，创始团队成员",
                    content: "开始从事企业级仿真数值模拟研发"
                },
                {
                    title: "2016年，构建创新、活力、联动、包容的世界经济",
                    content: '自主研发的"先进能源虚拟仿真实验"亮相杭州G20峰会'
                },
                {
                    title: "2018年，获得能动类、机械类国家级虚拟仿真实验教学项目",
                    content: "与清华大学,西安交通大学,上海交通大学等高校合作"
                },
                {
                    title: "2020年，推出“抗疫情”免费线上课程",
                    content: "帮助150余所高校进行在线课程和在线实验服务"
                },
            ],
            LineData_bottom: [
                {
                    title: "2015年,公司成立",
                    content: "业务从北京迅速辐射至全国，设立了武汉和南昌分公司"
                },
                {
                    title: "2017年,获得国家级虚拟仿真实验中心项目",
                    content: '与天津商业大学合作的"制冷虚拟仿真实验室"'
                },
                {
                    title: "2019年，中国慕课大会的主推展商",
                    content: "推动了教育理念，教学内容，教育教学模式与方法的深刻变革"
                }
            ],
            programa: {},
        }

    },
    mounted() {
        this.axiosTool = new axiosTool(this, [
            {
                key: 'programa', type: 'get', title: '获取概述', url: server.programa, params: {
                    getreq: { name: '关于我们' }
                }
            },
        ])
        this.axiosTool.programa().then(data => {
            if (data.status && Array.isArray(data.result)) {
                this.programa = data.result[0];
            }
        })
    }
}
</script>


<style lang="less" scoped>
:deep(.head-describe) {
    text-indent: 2em;
}

.main_us {
    height: 650px;
}
.main_content {
    height: 630px;
}

.content-bg {
    width: 100%;
    position: relative;
    top: 15px;
}

.LineData_top {
    display: flex;
    position: relative;
    top: -450px;
    justify-content: space-around;
    text-align: center;
}

.LineData_top_1 {
    display: flex;
    flex-direction: column;
    line-height: 30px;
    width: 20%;
    word-wrap: break-word;
}

.LineData_top_1_title {
    font-weight: bolder;
    height: 64px;
}

.LineData_top_1_content {
    color: #686969;
}

.LineData_bottom {
    display: flex;
    width: 68%;
    margin: 0 auto;
    justify-content: space-between;
    position: relative;
    top: -267px;
}

.LineData_bottom_1 {
    display: flex;
    flex-direction: column;
    width: 21%;
    text-align: center;
    line-height: 30px;
}

.LineData_bottom_1_title {
    font-weight: bolder;
    font-weight: bolder;
    line-height: 32px;
    height: 65px;
}

.LineData_bottom_1_content {
    color: #686969;
}
</style>