<template>
<div class="Search_ui">
   <div class="type1">
        <template  v-if="BoxType ==='box_border'">
            <div :class="['box_item',active ==item.key ? 'active':'' ]" @click="HandleClick('box_border',item)" v-for="item in boxData" :key="item.key"  >
                <span >{{item.value}}</span>
            </div>
        </template>
    </div>
       
    <div class="type2">
    <template v-if="ShadowType ==='shadowItem'"> 
        <div class="shadow_item">
            <span>{{shadowData.label}}</span>
            <div class="content_outter">
                <!-- <div class="content_inner" v-for="item_inner in shadowData.data" :key="item_inner.key"> -->
                    <div :class="['content_inner',active === item_inner.key ? 'activee' : '']" v-for="item_inner in shadowData.data" :key="item_inner.key" @click="HandleClick('box_border',item_inner)" >
                    <span>{{item_inner.value}}</span>
                </div>
            </div>
           
        </div>
    </template>
    </div>
  

</div>
</template>
<script>
export default {
    name:'searchTemplate',
    props:{
        BoxType:{
            type:String,
            default:''
        },
         ShadowType:{
            type:String,
            default:''
        },

        boxData:{
            type:Array,
            default:[],
        },
        shadowData:{
            type:Object,
            default:{},
        },
        data : {
            type : Object,
            default : function (){
                return {}
            }
        },
    },
    emits : ['update:data'],
    data(){
        return{
            active:1,
            formData:{},          
        }
    },
    methods:{
        HandleClick(type,data){
            let cache;
            switch(type){
                case 'box_border':
                    cache = this.data;
                    cache[data.type] = data.key;
                    console.log();
                    this.$emit('update:data',{...cache});
                    this.active = data.key;
                    break;
            }
               console.log(type,data)
        }
    }
}
</script>
<style lang='less' scoped>
.Search_ui{
    // display: flex;
    // justify-content: space-between;
    .type1{
        display: flex;
         color: #808080;
        justify-content: space-between;
    .box_item.active{
            color: white;
            background: #F18D00;
            border: 0px ;
           
    }
    .box_item{
        height: 29px;
        text-align: center;
        border: 1px solid #808080;
        line-height: 29px;
        width: 14%;
        cursor: pointer;
        border-radius: 3px;
    }
    }
    .type2{
        .shadow_item{
            display: flex;
            line-height: 30px;
            padding: 10px 0px;
            border-bottom: 1px solid #E8E8E8;
            >span{
                margin-right: 10px;
            }
            .content_outter{
                display: flex;
                height: 30px;
                line-height: 30px;
                width: 33%;
                justify-content: space-between;
            .content_inner.activee{
                     background: #EDF2F5;
                     color: #2B210C ;
                 }
            .content_inner{
                 cursor: pointer;
                 width: 80px;
                 text-align: center;
                 color: #5C5F5F;
             }   
            }   
        }
       
            
    }
   
}

</style>

