<template>
    <div class="resource_main">
        <!-- <div class="main_header">
            <span><img :src="bannerImg" alt=""></span>
        </div> -->
        <div class="main_content">
            <div class="content_header">
                <div class="header_inner" >
                     <div class="header_inner_item" v-for="item in typeList" :key="item.key">
                         <span :class="['item', active == item.key ? 'active' : '']" @click="handleClick('切换',item)">{{item.value}}</span>
                     </div>
                </div>
            </div>
           <queryView :pageType ='paType'></queryView>
        </div>
    </div>
</template>

<script>
import axiosTool from '@/utils/axios_tool';
import server from "@/service/server";
import queryView from './Query_view.vue'
export default {
    name:'resouce_download',
    components:{
        queryView,
        axiosTool,
        server
    },
    data(){
        return{
            paType:3,
            bannerImg:require('@/assets/images/xxl_gw/banner.png'),
            active:3,
            typeList:[
                {
                    key:3,
                    value:'全部'
                },
                {
                    key:1,
                    value:'标准规范'
                },
                {
                    key:2,
                    value:'计算软件'
                }
            ]

        }
    },
    methods:{
     handleClick(type,data){
         switch(type){
            case '切换':
                this.active = data.key;
                this.paType = data.key;
            break;
         }
     }
    }

}
</script>

<style lang="less" scoped>
    .resource_main{
         height: 900px;
         background-color: rgb(247,249,250);

        .main_header{
            height: 249px;
            background-color: rgb(6,100,240);
         >span{
            padding-top: 125px;
            display: inline-block;
            >img{
                display: inline-block;
                width: 1905px;
            }
         }
        }
        .main_content{
            width:80%;
            height: 800px;
            background-color: white;
            //  background-color: aliceblue;
            // background-color: antiquewhite;
            margin: 0 auto;
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            position: relative;
            .content_header{
                height: 60px;
                // background-color: aliceblue;
                width: 100%;
                border-bottom: 1px solid #E8E8E8;
                .header_inner{
                    line-height: 60px;
                    width:375px;
                    text-align: center;
                    display: flex;
                    justify-content: space-between;
                    .header_inner_item{
                        cursor: pointer;
                    }
                    .header_inner_item .active{
                        border-bottom: 3px solid #F18D00;
                        color:#F18D00 ;
                    }
                }
            }
        }
    }
</style>