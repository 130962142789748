<template>
  <div class="footer-ui">
      <div class="footer-main common-width">
   
          <!-- <div class="footer-li">
            
          </div> -->
        
          <!-- <div class="footer-li">
              <span class="footer-links">友情链接</span>
          </div>
          <div class="footer-li">
              <span class="footer-link" v-for="link in linkData" :key="link.key">
                  <a :href="link.path">{{link.title}}</a>
              </span>
          </div>
          <div class="footer-li copyright">
               技术支持：北京象新力科技有限公司
          </div> -->
         <!-- <div class="footer-Con">
            <div class="Con-left">
                <span class="title1">技术支持</span>
                 <div class="left-Img">
                    <span> <img :src="footImg.img2" alt=""></span>
                    <span> <img :src="footImg.img1" alt=""></span>
               
                 </div>
            </div>
            <div class="Con-right">
                <span class="title1">友情连接</span>
                <div class="link-con">
                    <span class="footer-link" v-for="link in linkData2" :key="link.key">
                      <a :href="link.path">{{link.title}}</a>
                    </span>
                </div>
            </div>

         </div> -->
      </div>
  </div>
</template>

<script>

export default {
    data (){
        return {
            linkData : [
                // {key : '0001',title : '南昌航空大学官网',path:'https://www.nchu.edu.cn/',},
                // {key : '0002',title : '实验空间',path:'http://www.ilab-x.com/',},
                // {key : '0003',title : '北京象新力科技有限公司官网',path:'http://www.xiangxinlitech.com/',},
                // {key : '0004',title : '象课堂',path:'http://xiangschool.com/',},
            ],

        // 培黎信息
         footImg:{
            img1:require('@/assets/images/XXLlogo.png'),
            img2:require('@/assets/images/sdlogo.png'),
         },
         linkData2 : [
                {key : '0001',title : '中华人民共和国教育部',path:'http://www.moe.gov.cn/',},
                {key : '0002',title : '甘肃省教育厅',path:'http://jyt.gansu.gov.cn/',},
                {key : '0003',title : '培黎职业学院官网 ',path:'http://www.plzyxy.com',},
                {key : '0004',title : '顺德职业技术学院',path:'https://www.sdpt.edu.cn/',},
                {key : '0005',title : '北京象新力科技',path:'http://www.xiangxinlitech.com/',},
                {key : '0006',title : '象课堂虚拟仿真实验平台',path:'http://xiangschool.com/',},
              
            ],

        }
    },
    components : {
    }
}
</script>

<style scoped lang="less">
    @import url('@/styles/common.less');//公共样式
    .media;//媒体查询
    .footer-ui{
        width:100%;
        height:180px;
        display: flex;
        justify-content: center;
        background-color: #333333;
        .footer-main{
            text-align: left;
            display: flex;
            flex-direction: column;
            .footer-li{
                color:#FFFFFF;
                flex: 1 1 auto;
                display: flex;
                align-items: center;
                padding:0 10px;
                .footer-links{
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    font-weight: bold;
                    &::before{
                        content:'';
                        display: inline-block;
                        width:3px;
                        height:18px;
                        background-color: #FFFFFF;
                        margin-right:10px;
                    }
                }
                .footer-link{
                    margin-left:20px;
                    margin-right:20px;
                    >a{
                      color:#FFFFFF;
                    }
                }
                &.copyright{
                    width: 100%;
                    justify-content: center;
                    display: flex;
                    font-size: 14px;
                    color: #808080;
                    padding:15px 0;
                    flex: 0 0 auto;
                }
            }
            .footer-Con{
                color:#FFFFFF;
                flex: 1 1 auto;
                display: flex;
                align-items: center;
                padding:0 10px;
                .Con-left{
                    height: 77%;
                    font-size: 20px;
                    font-weight: bolder;
                   .left-Img{
                     display: flex;
                     flex-direction: row;
                     margin-top: 20px;
                     
                     >span:nth-child(2){
                        margin-left: 20px;
                        >img{
                            width: 200px;
                        }
                    }
                     >span:nth-child(1){
                        height: 53px;
                        margin-top: 5px;
                       
                        >img{
                            width: 235px;
                        }
                        }
                      
                    
                     
                   }
                }
                .Con-right{
                    height: 77%;
                    margin-left: 100px;
                  .title1{
                    font-size: 20px;
                    font-weight: bolder;
                  }
                  .link-con{
                    margin-top: 31px;
                    >span{
                        width: 190px;
                        display: inline-block;
                        color: white;
                        margin-bottom: 20px;
                        >a{
                            color: white;
                        }
                    }
                  }
                }
            }
        }


        
    }

    



</style>