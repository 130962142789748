<template>
    <div class="report-con common-width">
        <template v-if="reportState === '展示PDF'">
            <PDFJS v-if="filepath" :filepath="filepath"></PDFJS>
        </template>
        <template v-else-if="reportState === '展示HTML'">
            <div class="content" v-html="content"></div>
        </template>  
        <template v-else>
            <span class="report-img" > </span>
            
        </template>  
    </div>
</template>

<script>
import common from "@/utils/common";
import axios from "@/utils/axios";
import server from "@/service/server";
import checker from '@/utils/checker';
import PDFJS from '@/components/common/PDF/index.vue';
export default {
    data() {
      return{
          reportId: '',
          content: '',
          reportState :  false,
          datafiletype : 0,
          filepath : '',
      }  
    },
    components : {
        PDFJS,
    },
    mounted(){
        let params = checker.getQueryParams();
        this.reportId = params.pid;
        this.datafiletype = params.datafiletype;
        this.getExpReport(this.reportId);
    },
    methods:{
        getExpReport(data){
            const {datafiletype} = this;
            let request = {
                uiexprecordautoid: data,
                comm_center: true
            },reportState = false;
            axios.get(server.datafile + checker.objTurnParams(request)).then(result=>{
                if(result && result.status && result.data && result.data.result){
                    let reportData = result.data.result[result.data.result.length - 1];
                    if(datafiletype == '3' && reportData.filepath){
                        reportState = '展示PDF';
                        this.filepath = reportData.filepath;
                    }else if(reportData.content){
                        reportState = '展示HTML';
                        this.content = reportData.content;
                    }
                    this.reportState = reportState
                }else{
                    this.reportState = false;
                }
            })
        }
    },
}
</script>

<style lang="less" scoped>
@import url("@/styles/common.less"); //公共样式
.media; //媒体查询
.report-con{
    width: 100%;
    margin: auto;
    height: 100%;

    .crumbs{
        margin: 10px 0;
    }
    .content{
        margin: 20px 0;
    }
}
.report-img{
    display: inline-block;
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/images/no_report.png');
    background-repeat: no-repeat;
    background-position: center;
}
</style>
