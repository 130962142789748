import { createApp } from 'vue'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuex from 'vuex'
import 'default-passive-events';
import checker from '@/utils/checker';

import xss from './utils/xss';
import 'dayjs/locale/zh-cn';
import less from 'less';
import locale from 'element-plus/lib/locale/lang/zh-cn';
import wangEditor from 'wangeditor';
//import './assets/iconfont/index.css'
//import directives from './directives/index.js'
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts';
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart } from 'echarts/charts';
import 'echarts-gl'

import * as ElIcons from '@element-plus/icons'
import installIcon from '@/utils/icon.js';

import sortable from 'sortablejs';
import countup from 'vue-countup-v3';
import '@/utils/css_variable';

const globalJson = require("../global.config.json");

// 标签自动布局，全局过渡动画等特性
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    //DatasetComponentOption,
    TransformComponent
  } from 'echarts/components';
import { LabelLayout, UniversalTransition } from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';


// 注册必须的组件
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    BarChart,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer
  ]);

let Vue = createApp(App);
for (const name in ElIcons){
	Vue.component(name,(ElIcons)[name])
}
Vue.use(ElementPlus, {locale})
    .use(Vuex)
    .use(store)
    .use(router)
    .use(installIcon)
    .use(less)
    .use(countup)
    .mount('#app');
    
    // directives(app)
globalThis.$base_url = process.env.BASE_URL;
Vue.config.globalProperties.wangEditor = wangEditor
Vue.config.globalProperties._echarts = echarts;
Vue.config.globalProperties._sortable = sortable;
Vue.config.globalProperties._xss = xss;
Vue.config.globalProperties.base_url = process.env.BASE_URL;
Vue.config.globalProperties.APIORIGIN = window.location.origin;
Vue.config.globalProperties.GLOBALPATH = window.location.origin + checker.getPrePathname(window.location.origin + window.location.pathname);
window.__GLOBALPATH = Vue.config.globalProperties.GLOBALPATH;
window._SIGN_ = true;
Vue.config.globalProperties._globalJson = globalJson;
Vue.config.globalProperties.ADMINGLOBALPATH = window.location.protocol +'//'+window.location.hostname + ':8010' + checker.getPrePathname(window.location.origin + window.location.pathname);

